import React from 'react';
import { Text3, Text4 } from '@appclose/ui';
import { Amount } from '@appclose/core';

import ContactMatter from 'components/common/ContactMatter';
import { I18n } from 'i18n';

import {
  getUnclearedBalances,
  getEndingBankStatementBalance,
} from '../../../../TrustSetupBalancesStepModal.utils';
import { TrustDepositBalancesTablePropsType } from './TrustDepositBalancesTable.types';
import styles from './TrustDepositBalancesTable.module.scss';

export default function TrustDepositBalancesTable({
  bankStatementEndingBalance,
  ledgerBalances,
  totalLedgersBalance,
}: TrustDepositBalancesTablePropsType) {
  const hasLedgerBalances = !!ledgerBalances?.length;
  const {
    unclearedDepositsBalance,
    unclearedDisbursementsBalance,
  } = getUnclearedBalances(ledgerBalances);
  const endingBankStatementBalance = getEndingBankStatementBalance(
    ledgerBalances
  );

  return (
    <div className={styles.table}>
      {hasLedgerBalances && (
        <div className={styles.tableSection}>
          {ledgerBalances?.map(({ contact, matter, balance }) => (
            <React.Fragment key={`${contact?.id}-${matter?.id}`}>
              <ContactMatter
                contact={contact}
                matter={matter}
                className={styles.ledgerTitle}
              />
              <Amount value={balance} className={styles.ledgerBalance} />
            </React.Fragment>
          ))}
        </div>
      )}
      <div className={styles.tableSection}>
        <Text3>
          <I18n id="modal.trustSetupBalancesStep.trustDepositBalancesTable.totalTrustDepositBalances" />
        </Text3>
        <Amount value={totalLedgersBalance} className={styles.totalBalance} />
      </div>
      <div className={styles.tableSection}>
        <Text4>
          <I18n id="modal.trustSetupBalancesStep.trustDepositBalancesTable.unclearedDeposits" />
        </Text4>
        <Amount
          value={unclearedDepositsBalance}
          className={styles.unclearedBalance}
        />
        <Text4>
          <I18n id="modal.trustSetupBalancesStep.trustDepositBalancesTable.unclearedDisbursements" />
        </Text4>
        <Amount
          showPlusSign
          value={unclearedDisbursementsBalance}
          className={styles.unclearedBalance}
        />
        <Text3>
          <I18n id="modal.trustSetupBalancesStep.trustDepositBalancesTable.endingBankStatementBalance" />
        </Text3>
        <Amount
          value={endingBankStatementBalance}
          className={styles.totalBalance}
          color={
            endingBankStatementBalance === bankStatementEndingBalance
              ? 'success'
              : 'warning'
          }
        />
      </div>
    </div>
  );
}
