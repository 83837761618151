import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { useQuery } from '@apollo/client';
import {
  openConfirmAction,
  permissionProvider,
  SelectFormField,
  useFormContext,
  usePopupModal,
} from '@appclose/core';

import {
  OrderTypes,
  SubExpenseCategoryCostTypes,
  SubExpenseCategoryStatus,
} from '__generated__/globalTypes';
import { track } from 'controllers/analytics';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import { EntryTypes, EventNames, SourceTypes } from 'constants/analytics';
import ExpenseCategoryModal from 'components/modals/popups/ExpenseCategoryModal';
import useLastUsage from 'hooks/useLastUsage';
import { useIntl } from 'i18n';

import {
  FetchExpenseCategoriesQuery,
  FetchExpenseCategoriesQueryVariables,
} from './__generated__/ExpenseCategorySelectFormField.gql';
import { FETCH_EXPENSE_CATEGORIES } from './ExpenseCategorySelectFormField.gql';
import {
  ExpenseCategorySelectFormFieldPropsType,
  ExpenseCategorySelectType,
} from './ExpenseCategorySelectFormField.types';

export default function ExpenseCategorySelectFormField({
  costType = SubExpenseCategoryCostTypes.SOFT,
  label,
  placeholder,
  name = 'category',
  allowAddNew,
  onChange,
  ...props
}: ExpenseCategorySelectFormFieldPropsType) {
  const { t } = useIntl();
  const dispatch = useDispatch();
  const { lastUsages: lastUsagesOfSoftCategory } = useLastUsage(
    'expenseSoftCategory'
  );
  const { lastUsages: lastUsagesOfHardCategory } = useLastUsage(
    'expenseHardCategory'
  );
  const [search, setSearch] = useState('');
  const { setFieldValue } = useFormContext<any>();
  const { loading, data, fetchMore, refetch } = useQuery<
    FetchExpenseCategoriesQuery,
    FetchExpenseCategoriesQueryVariables
  >(FETCH_EXPENSE_CATEGORIES, {
    variables: {
      filter: {
        search,
        statuses: [SubExpenseCategoryStatus.ACTIVE],
        costTypes: costType ? [costType] : undefined,
      },
      order: {
        forceTopIds:
          costType === SubExpenseCategoryCostTypes.SOFT
            ? lastUsagesOfSoftCategory
            : lastUsagesOfHardCategory,
        name: OrderTypes.ASC,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });
  const categories = data?.listExpenseCategories.items || [];

  const hasPermission = permissionProvider().hasPermission(
    PermissionResources.SUB_EXPENSE_CATEGORY,
    PermissionActions.CREATE
  );

  const onShowHasNoPermission = useCallback(() => {
    dispatch(
      openConfirmAction({
        content: t('field.expenseCategorySelect.hasNoAccess.content'),
        okButtonTitle: t('field.expenseCategorySelect.hasNoAccess.okButton'),
        okButtonSkin: 'primary',
      })
    );
  }, [dispatch, t]);

  const { openPopupModal } = usePopupModal(ExpenseCategoryModal, (category) => {
    setFieldValue(name, category);
    refetch();
    onChange?.(category);
  });
  const openAddNewModal = hasPermission
    ? () => {
        track(EventNames.ADD_NEW_ENTRY, {
          entry_type: EntryTypes.EXPENSE_CATEGORY,
          source_type: SourceTypes.POPUP_MODAL,
        });

        openPopupModal({ costType });
      }
    : onShowHasNoPermission;

  const onLoadMore = useCallback(async () => {
    const { data } = await fetchMore({
      variables: {
        skip: categories.length,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => ({
        listExpenseCategories: {
          ...previousResult.listExpenseCategories,
          items: [
            ...previousResult.listExpenseCategories.items,
            ...(fetchMoreResult?.listExpenseCategories.items || []),
          ],
        },
      }),
    });

    return categories.length !== data.listExpenseCategories.total;
  }, [categories.length, fetchMore]);
  const onSearchChange = useMemo(
    () => debounce((value: string) => setSearch(value), 500),
    [setSearch]
  );
  const optionValueResolver = useCallback(
    (category: ExpenseCategorySelectType) => category,
    []
  );
  const optionLabelResolver = useCallback(
    ({ name }: ExpenseCategorySelectType) => name,
    []
  );
  const selectedOptionsResolver = useCallback(
    (categories: ExpenseCategorySelectType[]) =>
      categories.map(({ name }) => name).join(', '),
    []
  );

  return (
    <SelectFormField
      {...props}
      onChange={onChange}
      name={name}
      label={label || t('field.expenseCategorySelect.label')}
      placeholder={placeholder || t('field.expenseCategorySelect.placeholder')}
      isLoading={loading}
      options={categories}
      onSearchChange={onSearchChange}
      optionValueResolver={optionValueResolver}
      optionLabelResolver={optionLabelResolver}
      selectedOptionsResolver={selectedOptionsResolver}
      onLoadOptions={onLoadMore}
      onAdd={allowAddNew ? openAddNewModal : undefined}
    />
  );
}
