import { gql } from '@apollo/client';

export const DISBURSEMENT_TO_CONTACT = gql`
  mutation DisbursementToContact($input: CreateDisbursementToContactInput!) {
    disbursement: createDisbursementToContact(createDisbursementInput: $input) {
      id
      type
    }
  }
`;
