import { CalendarSelectItemType } from './components/CalendarSelectFormField';

export enum CalendarType {
  CUSTOM = 'CUSTOM',
  EXIST = 'EXIST',
}

export interface CalendarSyncFormValuesType {
  type: CalendarType;
  calendarName?: string;
  calendarId?: string;
}

export interface CalendarSyncModalPropsType {
  calendarName: string;
  loading?: boolean;
  calendars?: CalendarSelectItemType[];
  onSubmit(data: Partial<CalendarSyncFormValuesType>): Promise<void>;
  onClose(): void;
}
