import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { track } from 'controllers/analytics';
import { DemoEventNames, EntryTypes } from 'constants/analytics';
import notification from 'controllers/notification';
import { openModal } from 'controllers/modal';
import { TRUST_TRANSFER_DETAILS_MODAL } from 'constants/modals';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { I18n, useIntl } from 'i18n';
import useUpdateFiles from 'hooks/useUpdateFiles';
import { Entities } from 'constants/entities';

import TransferFundsForm, {
  TransferFundsFormValuesType,
} from './components/TransferFundsForm';
import {
  CreateTransferFundsMutation,
  CreateTransferFundsMutationVariables,
} from './__generated__/TransferFundsModal.gql';
import { CREATE_TRANSFER_FUNDS } from './TransferFundsModal.gql';
import { TransferFundsModalPropsType } from './TransferFundsModal.types';

export default function TransferFundsModal({
  onClose,
}: TransferFundsModalPropsType) {
  const { t } = useIntl();
  const { onUpdateFiles } = useUpdateFiles(Entities.TRUST_TRANSFER);
  const [createTransferFunds] = useMutation<
    CreateTransferFundsMutation,
    CreateTransferFundsMutationVariables
  >(CREATE_TRANSFER_FUNDS);

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });

  const handleOnSubmit = useCallback(
    async ({
      fromContact,
      fromMatter,
      toContact,
      toMatter,
      description,
      amount,
      files,
    }: TransferFundsFormValuesType) => {
      const { data } = await createTransferFunds({
        variables: {
          input: {
            fromContactId: fromContact?.id as string,
            fromMatterId: fromMatter ? fromMatter.id : undefined,
            toContactId: toContact?.id as string,
            toMatterId: toMatter ? toMatter.id : undefined,
            description,
            amount,
          },
        },
      });
      const id = data?.transfer.id;

      track(DemoEventNames.DEMO_ENTRY_CREATED, {
        entry_type: EntryTypes.TRANSFER_FUNDS,
      });

      notification().entityCreated(
        t('modal.transferFunds.notification.create.success')
      );

      if (id && files) {
        await onUpdateFiles({ entityId: id, ...files });
      }

      onClose();

      openModal(TRUST_TRANSFER_DETAILS_MODAL, { id: data?.transfer.id });
    },
    [createTransferFunds, onClose, t, onUpdateFiles]
  );

  const initialValues = {
    description: '',
    amount: 0,
  } as TransferFundsFormValuesType;

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.transferFunds.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        <TransferFundsForm
          initialValues={initialValues}
          onCancel={onConfirmClose}
          onSubmit={handleOnSubmit}
          onChange={onFormChange}
        />
      </ModalPageContent>
    </ModalPage>
  );
}
