import { gql } from '@apollo/client';

import { AVATAR_FRAGMENT } from 'components/common/Avatar';

export const FETCH_PROFILE = gql`
  query FetchProfile {
    profile: getProfile {
      id
      firstName
      lastName
      middleName
      picture {
        ...Avatar
      }
      email
      additionalEmails
      phoneNumbers {
        type
        number
      }
      addresses {
        type
        city
        address1
        address2
        state
        zipCode
      }
      title
      website
      licenseNumber
      licensedInStates
      licensedSince
      practiceAreas
      gender
    }
  }
  ${AVATAR_FRAGMENT}
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile(
    $profile: UpdateProfileInput!
    $profileAvatar: Upload
  ) {
    updateProfile(updateProfileInput: $profile, picture: $profileAvatar) {
      id
      firstName
      lastName
      middleName
      picture {
        ...Avatar
      }
      email
      additionalEmails
      phoneNumbers {
        type
        number
      }
      title
      website
      licenseNumber
      licensedInStates
      licensedSince
      practiceAreas
      addresses {
        type
        city
        address1
        address2
        state
        zipCode
      }
      gender
    }
  }
  ${AVATAR_FRAGMENT}
`;
