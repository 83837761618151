import { FieldProps, FiledPropsType } from '@appclose/core';
import { Ref } from 'react';

export type DurationFormFieldType = FiledPropsType<{
  name?: string;
  onChange?(value: number): void;
  type?: TimeType;
}>;

export type DurationType = FieldProps &
  DurationFormFieldType & {
    hasError?: boolean;
    ref?: Ref<HTMLDivElement>;
};

export enum TimeType {
  HOURS = 'HOURS',
  MINUTES = 'MINUTES',
  SECONDS = 'SECONDS',
}
