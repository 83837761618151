import gqlClient from 'gql';
import { openConfirmAction, traceError } from '@appclose/core';

import notification from 'controllers/notification';
import { i18nMessage } from 'i18n';

import { DELETE_TIME_ENTRY } from './timeEntry.gql';
import {
  DeleteTimeEntryMutation,
  DeleteTimeEntryMutationVariables,
} from './__generated__/timeEntry.gql';

export const openDeleteTimeEntryConfirmAction = ({
  id,
  onSuccess,
}: {
  id: string;
  onSuccess?(): void;
}) =>
  openConfirmAction({
    name: i18nMessage('store.timeEntry.deleteTimeEntryConfirm.name'),
    content: i18nMessage('store.timeEntry.deleteTimeEntryConfirm.content'),
    okButtonTitle: i18nMessage(
      'store.timeEntry.deleteTimeEntryConfirm.okButton'
    ),
    cancelButtonTitle: i18nMessage(
      'store.timeEntry.deleteTimeEntryConfirm.cancelButton'
    ),
    okButtonSkin: 'primary',
    async onConfirm() {
      try {
        await gqlClient.mutate<
          DeleteTimeEntryMutation,
          DeleteTimeEntryMutationVariables
        >({
          mutation: DELETE_TIME_ENTRY,
          variables: { id },
        });

        notification().entityDeleted(
          i18nMessage('store.timeEntry.deleteTimeEntryConfirm.notify')
        );

        onSuccess && onSuccess();
      } catch (e) {
        traceError(e);
      }
    },
  });
