import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Flex } from '@appclose/ui';
import {
  Amount,
  FormGroup,
  Loader,
  StaticField,
  Fieldset,
} from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import Actions, { Action } from 'components/common/Actions';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import { FLAT_FEE_MODAL, INVOICE_DETAILS_MODAL } from 'constants/modals';
import { openDeleteFlatFeeConfirmAction } from 'store/actions';
import Date from 'components/common/Date';
import { ExpenseStatuses } from '__generated__/globalTypes';
import Person from 'components/common/Person/Person';
import FormGrid from 'components/common/FormGrid';
import InvoiceInfo from 'components/common/InvoiceInfo';
import { openModal } from 'controllers/modal';
import AdditionalInfoDetailsFieldset from 'components/common/AdditionalInfoDetailsFieldset';
import FlatFeeStatus from 'components/common/FlatFeeStatus';
import { PermissionGuard } from 'components/common/PermissionGuard';
import ContactMatterDetailsFieldset from 'components/common/ContactMatterDetailsFieldset';
import { I18n, useIntl } from 'i18n';
import useListFiles from 'hooks/useListFiles';
import { Entities } from 'constants/entities';

import { ExpenseDetailsModalPropsType } from './FlatFeeDetailsModal.types';
import { FETCH_FLAT_FEE } from './FlatFeeDetailsModal.gql';
import {
  FetchFlatFeeQuery,
  FetchFlatFeeQueryVariables,
} from './__generated__/FlatFeeDetailsModal.gql';
import styles from './FlatFeeDetailsModal.module.scss';

export default function FlatFeeDetailsModal({
  id,
  onClose,
}: ExpenseDetailsModalPropsType) {
  const { t } = useIntl();
  const dispatch = useDispatch();
  const { loading, data } = useQuery<
    FetchFlatFeeQuery,
    FetchFlatFeeQueryVariables
  >(FETCH_FLAT_FEE, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
  const flatFee = data?.flatFee;
  const { filesLoading, files } = useListFiles({
    entityType: Entities.EXPENSE,
    input: { filter: { entityIds: [id] } },
    skip: !flatFee,
  });
  const actionsAllowed =
    !filesLoading && flatFee
      ? [ExpenseStatuses.BILLABLE, ExpenseStatuses.NOT_BILLABLE].includes(
          flatFee.status
        )
      : false;

  const handleOnOpenExpenseModal = useCallback(
    () => openModal(FLAT_FEE_MODAL, { id }),
    [id]
  );

  const handleOnOpenDeleteExpenseConfirm = useCallback(
    () =>
      dispatch(
        openDeleteFlatFeeConfirmAction({
          id,
          onSuccess() {
            onClose();
          },
        })
      ),
    [dispatch, id, onClose]
  );

  return (
    <ModalPage onClose={onClose}>
      <ModalPageHeader>
        <Flex alignItems="center" justify="space-between">
          <ModalPageTitle>
            <I18n id="modal.flatFeeDetails.title" />
          </ModalPageTitle>
          {actionsAllowed && (
            <Actions>
              <PermissionGuard
                resource={PermissionResources.FLAT_FEE}
                action={PermissionActions.UPDATE}
              >
                <Action type="edit" onClick={handleOnOpenExpenseModal} />
              </PermissionGuard>
              <PermissionGuard
                resource={PermissionResources.FLAT_FEE}
                action={PermissionActions.DELETE}
              >
                <Action
                  type="delete"
                  onClick={handleOnOpenDeleteExpenseConfirm}
                />
              </PermissionGuard>
            </Actions>
          )}
        </Flex>
      </ModalPageHeader>
      <ModalPageContent>
        {loading || filesLoading ? (
          <Loader />
        ) : (
          flatFee && (
            <>
              {flatFee.invoice && (
                <FormGroup className={styles.invoice}>
                  <StaticField
                    title={t('modal.flatFeeDetails.field.includedToInvoice')}
                  >
                    <InvoiceInfo
                      invoice={flatFee.invoice}
                      onViewButtonClick={() => {
                        openModal(INVOICE_DETAILS_MODAL, {
                          id: flatFee.invoice?.id,
                        });
                      }}
                    />
                  </StaticField>
                </FormGroup>
              )}
              <Fieldset
                title={t('modal.flatFeeDetails.fieldset.billingDetails')}
              >
                <FormGroup>
                  <StaticField
                    title={t('modal.flatFeeDetails.field.description')}
                  >
                    {flatFee.description}
                  </StaticField>
                </FormGroup>
                <FormGroup>
                  <FormGrid>
                    <StaticField title={t('modal.flatFeeDetails.field.amount')}>
                      <Amount value={flatFee.amount} />
                    </StaticField>
                    <StaticField title={t('modal.flatFeeDetails.field.date')}>
                      <Date value={flatFee.date} />
                    </StaticField>
                    <StaticField title={t('modal.flatFeeDetails.field.status')}>
                      <FlatFeeStatus status={flatFee.status} />
                    </StaticField>
                    <StaticField
                      title={t('modal.flatFeeDetails.field.billedBy')}
                    >
                      {flatFee.billedBy && (
                        <Person
                          firstName={flatFee.billedBy.firstName}
                          lastName={flatFee.billedBy.lastName}
                          icon={flatFee.billedBy.picture}
                          className={styles.billedBy}
                        />
                      )}
                    </StaticField>
                  </FormGrid>
                </FormGroup>
              </Fieldset>
              <ContactMatterDetailsFieldset
                matter={flatFee.matter}
                contact={flatFee.contact}
              />
              <AdditionalInfoDetailsFieldset
                notes={flatFee.notes}
                files={files?.items}
              />
            </>
          )
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
