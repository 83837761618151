import * as Yup from 'yup';
import {
  EMAIL_VALIDATION_SCHEMA,
  PROFILE_FIRST_NAME_VALIDATION_SCHEMA,
  PROFILE_LAST_NAME_VALIDATION_SCHEMA,
  URL_VALIDATION_SCHEMA,
} from '@appclose/core';

import { getI18nErrorMessage } from 'i18n';
import { GenderTypes, UsaStates } from '__generated__/globalTypes';

import { ADDRESSES_FIELDSET_SCHEMA } from '../AddressesFieldset';
import { PHONE_FIELDSET_SCHEMA } from '../PhoneFieldset';
import { ProfileFieldsetValuesType } from './ProfileFieldset.types';

export const ProfileFieldsetSchema = Yup.object().shape<
  ProfileFieldsetValuesType
>({
  firstName: PROFILE_FIRST_NAME_VALIDATION_SCHEMA,
  lastName: PROFILE_LAST_NAME_VALIDATION_SCHEMA,
  middleName: Yup.string().nullable(),
  email: EMAIL_VALIDATION_SCHEMA,
  additionalEmails: Yup.array(EMAIL_VALIDATION_SCHEMA).nullable(),
  phoneNumbers: PHONE_FIELDSET_SCHEMA,
  title: Yup.string()
    .max(30, ({ max }) =>
      getI18nErrorMessage('form.profileFieldset.error.title.maxLength', { max })
    )
    .nullable(),
  website: URL_VALIDATION_SCHEMA.nullable(),
  picture: Yup.mixed().nullable(),
  licenseNumber: Yup.string().nullable(),
  licensedSince: Yup.string().nullable(),
  licensedInStates: Yup.array<UsaStates>().nullable(),
  practiceAreas: Yup.string().nullable(),
  addresses: ADDRESSES_FIELDSET_SCHEMA,
  gender: Yup.mixed<GenderTypes>(),
});
