import React, { useMemo } from 'react';
import { Button, Flex } from '@appclose/ui';
import { Form, Modal, Fieldset, traceError } from '@appclose/core';

import FilesFormField from 'components/fields/FilesFormField';
import { Entities } from 'constants/entities';
import useUpdateFiles from 'hooks/useUpdateFiles';
import { useIntl, I18n } from 'i18n';

import {
  AttachBankStatementModalPropsType,
  AttachBankStatementFormValuesType,
} from './AttachBankStatementModal.types';
import { AttachBankStatementModalSchema } from './AttachBankStatementModal.schema';

export default function AttachBankStatementModal({
  id,
  onCancel,
}: AttachBankStatementModalPropsType) {
  const { t } = useIntl();
  const { onUpdateFiles } = useUpdateFiles(Entities.RECONCILIATION);

  const handleSubmit = useMemo(
    () => async ({ files }: AttachBankStatementFormValuesType) => {
      if (files) {
        try {
          await onUpdateFiles({ entityId: id, ...files });
          onCancel();
        } catch (e) {
          traceError(e);
        }
      }
    },
    [id, onUpdateFiles, onCancel]
  );

  return (
    <Modal title={t('modal.attachBankStatement.title')} onClose={onCancel}>
      <Form
        initialValues={{}}
        validationSchema={AttachBankStatementModalSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <>
            <Fieldset title={t('modal.attachBankStatement.fieldset.title')}>
              <FilesFormField
                entityType={Entities.RECONCILIATION}
                entityId={id}
              />
            </Fieldset>
            <Flex justify="center">
              <Button type="submit" skin="primary" loading={isSubmitting}>
                <I18n id="modal.attachBankStatement.submit" />
              </Button>
            </Flex>
          </>
        )}
      </Form>
    </Modal>
  );
}
