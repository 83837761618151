import React, { ChangeEvent, useMemo } from 'react';
import { Checkbox, TableCell, TableHead, TableRow } from '@appclose/ui';
import { useFormContext, useIsMobileDevice } from '@appclose/core';

import { I18n } from 'i18n';

import { PrintCheckFormValuesType } from '../../../../PrintChecksForm.types';
import { NotPrintedDisbursementsFieldTableHeaderPropsType } from './NotPrintedDisbursementsFieldTableHeader.types';

export default function NotPrintedDisbursementsFieldTableHeader({
  notPrintedDisbursements,
}: NotPrintedDisbursementsFieldTableHeaderPropsType) {
  const isMobile = useIsMobileDevice();
  const { values, setFieldValue } = useFormContext<PrintCheckFormValuesType>();

  const selectedAll = useMemo(
    () =>
      (notPrintedDisbursements?.length &&
        notPrintedDisbursements.every(({ id }) =>
          values.selectedChecks?.some((selectedId) => selectedId === id)
        )) ||
      false,
    [notPrintedDisbursements, values.selectedChecks]
  );

  const onSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(
      'selectedChecks',
      e.target.checked ? notPrintedDisbursements?.map(({ id }) => id) : [],
      true
    );
  };

  return (
    <TableHead theme="medium">
      <TableRow>
        <TableCell>
          <Checkbox checked={selectedAll} onChange={onSelectAll} />
        </TableCell>
        {isMobile ? (
          <>
            <TableCell>
              <I18n id="modal.printChecks.form.checks.table.payee" />
            </TableCell>
            <TableCell>
              <I18n id="modal.printChecks.form.checks.table.date" />
            </TableCell>
            <TableCell>
              <I18n id="modal.printChecks.form.checks.table.amount" />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell>
              <I18n id="modal.printChecks.form.checks.table.type" />
            </TableCell>
            <TableCell>
              <I18n id="modal.printChecks.form.checks.table.date" />
            </TableCell>
            <TableCell>
              <I18n id="modal.printChecks.form.checks.table.contactMatter" />
            </TableCell>
            <TableCell>
              <I18n id="modal.printChecks.form.checks.table.payee" />
            </TableCell>
            <TableCell>
              <I18n id="modal.printChecks.form.checks.table.amount" />
            </TableCell>
          </>
        )}
      </TableRow>
    </TableHead>
  );
}
