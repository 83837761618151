import { gql } from '@apollo/client';

export const TIME_ENTRY_ACTIVITY_MODAL_FRAGMENT = gql`
  fragment TimeEntryActivity on TimerActivityType {
    id
    name
  }
`;

export const CREATE_TIME_ENTRY_ACTIVITY = gql`
  mutation CreateTimeEntryActivity($input: CreateTimerActivityInput!) {
    activity: createTimerActivity(createTimerActivityInput: $input) {
      ...TimeEntryActivity
    }
  }
  ${TIME_ENTRY_ACTIVITY_MODAL_FRAGMENT}
`;

export const FETCH_TIME_ENTRY_ACTIVITY = gql`
  query FetchTimeEntryActivity($id: ID!) {
    activity: getTimerActivity(id: $id) {
      ...TimeEntryActivity
    }
  }
  ${TIME_ENTRY_ACTIVITY_MODAL_FRAGMENT}
`;

export const EDIT_TIME_ENTRY_ACTIVITY = gql`
  mutation EditTimeEntryActivity($input: UpdateTimerActivityInput!) {
    activity: updateTimerActivity(updateTimerActivityInput: $input) {
      ...TimeEntryActivity
    }
  }
  ${TIME_ENTRY_ACTIVITY_MODAL_FRAGMENT}
`;
