import React from 'react';
import { Button, ButtonGroup, Flex, Offset, Note } from '@appclose/ui';
import {
  Form,
  FormGroup,
  FormSubmissionError,
  InputFormField,
  UpperFirstInputFormField,
  PhoneNumberFormField,
  USAStateSelectFormField,
  USAZipCodeFormField,
  Fieldset,
} from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import { I18n, useIntl } from 'i18n';

import { ThirdPartyPayeeFormSchema } from './ThirdPartyPayeeForm.schema';
import { ThirdPartyPayeeFormPropsType } from './ThirdPartyPayeeForm.types';
import styles from './ThirdPartyPayeeForm.module.scss';

export default function ThirdPartyPayeeForm({
  initialValues = {} as any,
  withNote,
  isEdit,
  onSubmit,
  onCancel,
}: ThirdPartyPayeeFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ThirdPartyPayeeFormSchema}
    >
      {({ isSubmitting }) => {
        return (
          <div className={styles.formContent}>
            <Fieldset title={t('modal.popup.thirdPartyPayee.form.payee.title')}>
              <FormGroup>
                <InputFormField
                  name="payeeName"
                  label={t('modal.popup.thirdPartyPayee.form.payee.payeeName')}
                />
              </FormGroup>
              <FormGroup>
                <FormGrid>
                  <UpperFirstInputFormField
                    name="firstName"
                    label={t(
                      'modal.popup.thirdPartyPayee.form.payee.firstName'
                    )}
                  />
                  <UpperFirstInputFormField
                    name="lastName"
                    label={t('modal.popup.thirdPartyPayee.form.payee.lastName')}
                  />
                  <UpperFirstInputFormField
                    name="middleName"
                    label={t(
                      'modal.popup.thirdPartyPayee.form.payee.middleName'
                    )}
                  />
                  <InputFormField
                    name="companyName"
                    label={t(
                      'modal.popup.thirdPartyPayee.form.payee.companyName'
                    )}
                  />
                </FormGrid>
              </FormGroup>
            </Fieldset>
            <Fieldset
              title={t('modal.popup.thirdPartyPayee.form.contactInfo.title')}
            >
              <FormGroup>
                <FormGrid>
                  <PhoneNumberFormField
                    name="primaryPhone"
                    label={t(
                      'modal.popup.thirdPartyPayee.form.contactInfo.primaryPhone'
                    )}
                  />
                  <InputFormField
                    name="email"
                    label={t(
                      'modal.popup.thirdPartyPayee.form.contactInfo.email'
                    )}
                  />
                </FormGrid>
              </FormGroup>
            </Fieldset>
            <Fieldset
              title={t('modal.popup.thirdPartyPayee.form.address.title')}
            >
              <FormGroup>
                <FormGrid>
                  <UpperFirstInputFormField
                    name="address.address1"
                    label={t(
                      'modal.popup.thirdPartyPayee.form.address.address1'
                    )}
                  />
                  <USAStateSelectFormField
                    name="address.state"
                    label={t('modal.popup.thirdPartyPayee.form.address.state')}
                  />
                  <InputFormField
                    name="address.address2"
                    label={t(
                      'modal.popup.thirdPartyPayee.form.address.address2'
                    )}
                  />
                  <USAZipCodeFormField
                    name="address.zipCode"
                    label={t(
                      'modal.popup.thirdPartyPayee.form.address.zipCode'
                    )}
                  />
                  <InputFormField
                    name="address.city"
                    label={t('modal.popup.thirdPartyPayee.form.address.city')}
                  />
                </FormGrid>
              </FormGroup>
            </Fieldset>
            {withNote && (
              <Offset top="xl">
                <Note theme="important">
                  <I18n id="modal.popup.thirdPartyPayee.form.note" />
                </Note>
              </Offset>
            )}
            <Offset top="xs">
              <FormSubmissionError />
            </Offset>
            <Offset top="xl">
              <Flex justify="center">
                <ButtonGroup>
                  <Button onClick={onCancel}>
                    <I18n id="modal.popup.thirdPartyPayee.form.cancel" />
                  </Button>
                  <Button loading={isSubmitting} type="submit" skin="primary">
                    <I18n
                      id={
                        isEdit
                          ? 'modal.popup.thirdPartyPayee.form.update'
                          : 'modal.popup.thirdPartyPayee.form.create'
                      }
                    />
                  </Button>
                </ButtonGroup>
              </Flex>
            </Offset>
          </div>
        );
      }}
    </Form>
  );
}
