import React from 'react';
import { Button, ButtonGroup, Note } from '@appclose/ui';
import {
  dateManager,
  DatePickerFormField,
  Form,
  FormGroup,
  InputFormField,
  TextAreaFormField,
  Fieldset,
} from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import ContactSelectFormField from 'components/fields/ContactSelectFormField';
import { SERVER_DATE_FORMAT } from 'constants/date';
import { I18n, useIntl } from 'i18n';
import FilesFormField from 'components/fields/FilesFormField';
import { Entities } from 'constants/entities';
import MatterBillingTypeFieldset from 'components/form/MatterBillingTypeFieldset';

import { MatterFormSchema } from './MatterForm.schema';
import { MatterFormPropsType } from './MatterForm.types';
import TeamMembersFieldset from './components/TeamMembersFieldset';

export default function MatterForm({
  isEdit,
  hasInvoices,
  onSubmit,
  initialValues,
  onCancel,
  onChange,
}: MatterFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      onChange={onChange}
      validationSchema={MatterFormSchema}
    >
      {({ values: { id, teamMembers, type }, isSubmitting }) => (
        <>
          <Fieldset title={t('modal.matter.form.fieldset.contactForMatter')}>
            <FormGrid>
              <ContactSelectFormField disabled={isEdit} />
            </FormGrid>
          </Fieldset>
          <Fieldset title={t('modal.matter.form.fieldset.info')}>
            <FormGroup>
              <FormGrid>
                <InputFormField
                  name="name"
                  label={t('modal.matter.form.field.name')}
                  placeholder={t('modal.matter.form.field.name.placeholder')}
                />
                <DatePickerFormField
                  name="dateOpened"
                  label={t('modal.matter.form.field.dateOpened')}
                  outputDateFormat={SERVER_DATE_FORMAT}
                  useHumanReadableFormat
                />
                <InputFormField
                  name="matterNumber"
                  label={t('modal.matter.form.field.matterNumber')}
                  placeholder={t('system.placeholder.matterNumber')}
                  disabled={isEdit}
                />
                <DatePickerFormField
                  name="statuteOfLimitations"
                  label={t('modal.matter.form.field.statuteOfLimitations')}
                  outputDateFormat={SERVER_DATE_FORMAT}
                  minDate={
                    isEdit &&
                    dateManager()
                      .parse()
                      .startOf('day')
                      .isAfter(initialValues.statuteOfLimitations)
                      ? dateManager()
                          .parse(initialValues.statuteOfLimitations)
                          .valueOf()
                      : dateManager().parse().startOf('day').valueOf()
                  }
                />
              </FormGrid>
            </FormGroup>
            <Note>
              <I18n id="modal.matter.form.note" />
            </Note>
          </Fieldset>
          <TeamMembersFieldset
            exclude={teamMembers
              ?.map((item) => item?.member?.id)
              .filter(Boolean)}
          />
          <Fieldset title={t('modal.matter.form.fieldset.billing')}>
            <MatterBillingTypeFieldset
              type={type}
              isEdit={isEdit}
              hasInvoices={hasInvoices}
            />
          </Fieldset>
          <Fieldset title={t('modal.matter.form.fieldset.other')}>
            <FormGroup>
              <TextAreaFormField
                name="notes"
                label={t('modal.matter.form.field.notes')}
              />
            </FormGroup>
            <FormGroup>
              <FilesFormField entityType={Entities.MATTER} entityId={id} />
            </FormGroup>
          </Fieldset>
          <ButtonGroup>
            <Button onClick={onCancel}>
              <I18n id="modal.matter.form.button.cancel" />
            </Button>
            <Button loading={isSubmitting} type="submit" skin="primary">
              <I18n
                id={
                  isEdit
                    ? 'modal.matter.form.button.save'
                    : 'modal.matter.form.button.create'
                }
              />
            </Button>
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}
