import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { downloadFileByBase64 } from '@appclose/lib';
import { traceError } from '@appclose/core';

import {
  FetchTrustTransferPdfQuery,
  FetchTrustTransferPdfQueryVariables,
} from './__generated__/useTrustTransferActions.gql';
import { useTrustTransferActionsPropsType } from './useTrustTransferActions.types';
import { FETCH_TRUST_TRANSFER_PDF } from './useTrustTransferActions.gql';

export default function useTrustTransferActions({
  id,
}: useTrustTransferActionsPropsType) {
  const [
    fetchTrustTransferPdf,
    { loading: downloadTrustTransferPdfLoading },
  ] = useLazyQuery<
    FetchTrustTransferPdfQuery,
    FetchTrustTransferPdfQueryVariables
  >(FETCH_TRUST_TRANSFER_PDF, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
    onCompleted({ pdf: { data, mimetype, name } }) {
      downloadFileByBase64(data, mimetype, name);
    },
  });

  const downloadTrustTransferPdf = useCallback(async () => {
    try {
      await fetchTrustTransferPdf();
    } catch (e) {
      traceError(e);
    }
  }, [fetchTrustTransferPdf]);

  return {
    downloadTrustTransferPdf,
    downloadTrustTransferPdfLoading,
  };
}
