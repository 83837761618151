import { gql } from '@apollo/client';

export const EXPENSE_CATEGORY_SELECT_FRAGMENT = gql`
  fragment ExpenseCategorySelect on SubExpenseCategoryType {
    id
    name
    costType
  }
`;

export const FETCH_EXPENSE_CATEGORIES = gql`
  query FetchExpenseCategories(
    $filter: FilterSubExpenseCategoryInput
    $order: OrderSubExpenseCategoryInput
    $skip: Int
    $take: Int
  ) {
    listExpenseCategories: listSubExpenseCategories(
      listSubExpenseCategories: {
        filter: $filter
        order: $order
        skip: $skip
        take: $take
      }
    ) {
      items {
        ...ExpenseCategorySelect
      }
      total
    }
  }
  ${EXPENSE_CATEGORY_SELECT_FRAGMENT}
`;
