import gqlClient from 'gql';
import Analytics, { AnalyticsInstance } from 'analytics';
import { getOperationName } from '@apollo/client/utilities';
import { parse } from 'bowser';
// @ts-ignore
import googleTagManager from '@analytics/google-tag-manager';
import { dateManager, traceError } from '@appclose/core';

import { PublicAnalyticEvent } from '__generated__/globalTypes';
import { GOOGLE_TAG_MANAGER_CONTAINER_ID } from 'constants/env';
import { DemoEventNames, EventNames } from 'constants/analytics';
import { isDemoBuild } from 'controllers/demo';

import {
  TRACK_ANALYTICS_EVENT,
  TRACK_PUBLIC_ANALYTICS_EVENT,
} from './analytics.gql';
import {
  TrackAnalyticsEventMutation,
  TrackAnalyticsEventMutationVariables,
  TrackPublicAnalyticsEventMutation,
  TrackPublicAnalyticsEventMutationVariables,
} from './__generated__/analytics.gql';
import { EventType, DataType, PublicDataType } from './analytics.types';

let _analytics: AnalyticsInstance | null = null;

export function analytics() {
  if (!_analytics) {
    const plugins = [];

    if (GOOGLE_TAG_MANAGER_CONTAINER_ID) {
      plugins.push(
        googleTagManager({
          containerId: GOOGLE_TAG_MANAGER_CONTAINER_ID,
        })
      );
    }

    _analytics = Analytics({
      plugins,
    });
  }

  return _analytics;
}

export function track(event: EventType, data?: DataType) {
  const isDemoEvent = Object.values(DemoEventNames).includes(
    event as DemoEventNames
  );

  if (isDemoEvent && !isDemoBuild()) {
    return;
  }

  try {
    gqlClient.mutate<
      TrackAnalyticsEventMutation,
      TrackAnalyticsEventMutationVariables
    >({
      mutation: TRACK_ANALYTICS_EVENT,
      variables: {
        event,
        timestamp: dateManager().parse().utc().unix(),
        data: {
          ...(data ? { data } : {}),
        },
      },
    });
  } catch (e) {
    traceError(e);
  }
}

export function trackPublicEvent(
  event: PublicAnalyticEvent,
  data: PublicDataType
) {
  try {
    gqlClient.mutate<
      TrackPublicAnalyticsEventMutation,
      TrackPublicAnalyticsEventMutationVariables
    >({
      mutation: TRACK_PUBLIC_ANALYTICS_EVENT,
      variables: {
        event,
        timestamp: dateManager().parse().utc().unix(),
        data: {
          ...renameUtmData(data),
          ...getAdditionalCharacteristics(),
        },
      },
    });
  } catch (e) {
    traceError(e);
  }
}

export function trackSessionStart() {
  try {
    gqlClient.mutate<
      TrackAnalyticsEventMutation,
      TrackAnalyticsEventMutationVariables
    >({
      mutation: TRACK_ANALYTICS_EVENT,
      variables: {
        event: EventNames.SESSION_START,
        timestamp: dateManager().parse().utc().unix(),
        data: getAdditionalCharacteristics(),
      },
    });
  } catch (e) {
    traceError(e);
  }
}

export const trackTabClose = (data: PublicDataType): void => {
  if (!navigator.sendBeacon) {
    return;
  }

  const blob = new Blob(
    [
      JSON.stringify({
        operationName: getOperationName(TRACK_PUBLIC_ANALYTICS_EVENT),
        query: TRACK_PUBLIC_ANALYTICS_EVENT.loc?.source.body,
        variables: {
          event: PublicAnalyticEvent.SIGN_UP_FORM_CLOSED,
          timestamp: dateManager().parse().utc().unix(),
          data: {
            ...renameUtmData(data),
            ...getAdditionalCharacteristics(),
          },
        },
      }),
    ],
    { type: 'application/json' }
  );

  navigator.sendBeacon('/graphql', blob);
};

const renameUtmData = (data: PublicDataType) => {
  const {
    promoCode: promocode,
    source: utm_source,
    medium: utm_medium,
    campaign: utm_campaign,
    content: utm_content,
    demoUserId: uuid_demo,
    ...rest
  } = data;

  return {
    promocode,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    uuid_demo,
    ...rest,
  };
};

const getAdditionalCharacteristics = () => {
  const { browser, os, platform } = parse(window.navigator.userAgent);
  const { width, height } = window.screen;

  return {
    browser_name: browser.name,
    browser_version: browser.version,
    os_name: os.name,
    os_version: os.version,
    platform_type: platform.type,
    platform_vendor: platform.vendor,
    platform_model: platform.model,
    screen: `${width}x${height}`,
  };
};
