import {
  BillingContactTypes,
  CreateContactDataInput,
  CreateEntityDataInput,
  GenderTypes,
} from '__generated__/globalTypes';

import { formatLabel } from '../controllers/utils';

export const INITIAL_BILLING_CONTACT = {
  firstName: '',
  lastName: '',
  middleName: '',
  email: '',
  type: BillingContactTypes.PRIMARY,
};

export const INITIAL_BASE_CONTACT: Omit<
  CreateContactDataInput,
  'originatingAttorney'
> = {
  firstName: '',
  lastName: '',
  email: '',
  conflictsCheckCompleted: false,
  engagementHasBeenSigned: false,
  w9FormSentToClient: false,
  taxable: false,
};

export const INITIAL_CONTACT: Omit<
  CreateContactDataInput,
  'originatingAttorney'
> = {
  ...INITIAL_BASE_CONTACT,
  notes: '',
  gender: GenderTypes.UNSPECIFIED,
};

export const INITIAL_BASE_ENTITY: Omit<
  CreateEntityDataInput,
  'originatingAttorney' | 'entityType'
> = {
  name: '',
  billingContacts: [{ ...INITIAL_BILLING_CONTACT }],
  conflictsCheckCompleted: false,
  engagementHasBeenSigned: false,
  w9FormSentToClient: false,
  taxable: false,
};

export const INITIAL_ENTITY: Omit<
  CreateEntityDataInput,
  'originatingAttorney' | 'entityType'
> = {
  ...INITIAL_BASE_ENTITY,
  website: '',
  notes: '',
};

export const CONTACT_TYPE_OPTIONS = Object.values(BillingContactTypes).map(
  (value) => ({
    value,
    label: formatLabel(value),
  })
);
export const CONTACT_TYPE_OPTIONS_WITHOUT_PRIMARY = CONTACT_TYPE_OPTIONS.filter(
  ({ value }) => value !== BillingContactTypes.PRIMARY
);

export enum ContactTypes {
  INDIVIDUAL = 'INDIVIDUAL',
  ENTITY = 'ENTITY',
}
