import React from 'react';
import classnames from 'classnames';
import {
  AmountFormField,
  FormGroup,
  TextAreaFormField,
  Fieldset,
  EnhancedFormArrayField,
} from '@appclose/core';

import { PaymentMethodsTypes } from '__generated__/globalTypes';
import FormGrid from 'components/common/FormGrid';
import ContactMatterSelectFormField from 'components/fields/ContactMatterSelectFormField';
import PaymentMethodSelectFormField from 'components/fields/PaymentMethodSelectFormField';
import { useIntl } from 'i18n';
import { MEMO_PURPOSE_FILED_LENGTH_LIMIT } from 'constants/fieldsLimitations';

import styles from './PaymentsFieldset.module.scss';

export default function PaymentsFieldset() {
  const { t } = useIntl();

  return (
    <EnhancedFormArrayField
      name="payments"
      addButtonLabel={t('modal.deposit.form.fieldset.payments.title')}
      allowRemoveLast={false}
      deleteButtonPosition="start"
      initialData={{ amount: 0 }}
    >
      {({ resolveName, index, values }) => (
        <Fieldset
          className={classnames(styles.fieldset, {
            [styles.lastFieldset]: values.length === index + 1,
          })}
          title={t('modal.deposit.form.fieldset.payments.payment', {
            number: index + 1,
          })}
        >
          <FormGroup>
            <ContactMatterSelectFormField
              contactFieldName={resolveName('contact')}
              matterFieldName={resolveName('matter')}
              showTrustLedgerBalance
              allowAddNew
            />
          </FormGroup>
          <FormGroup>
            <FormGrid>
              <AmountFormField
                name={resolveName('amount')}
                label={t('modal.deposit.form.fieldset.payments.amount')}
              />
              <PaymentMethodSelectFormField
                name={resolveName('paymentMethod')}
                unavailableMethods={[
                  PaymentMethodsTypes.CC,
                  PaymentMethodsTypes.ACH,
                  PaymentMethodsTypes.TRANSFER_FROM_TRUST,
                ]}
              />
            </FormGrid>
          </FormGroup>
          <FormGroup>
            <TextAreaFormField
              name={resolveName('memo')}
              placeholder={t('modal.deposit.form.fieldset.payments.memo')}
              maxLength={MEMO_PURPOSE_FILED_LENGTH_LIMIT}
            />
          </FormGroup>
        </Fieldset>
      )}
    </EnhancedFormArrayField>
  );
}
