import React from 'react';
import { openConfirmAction } from '@appclose/core';
import gqlClient from 'gql';

import EntityActionConfirmContent from 'components/common/EntityActionConfirmContent';
import notification from 'controllers/notification';
import { i18nMessage } from 'i18n';

import { CLOSE_MATTER } from './matter.gql';
import {
  CloseMatterMutation,
  CloseMatterMutationVariables,
} from './__generated__/matter.gql';

export const openCloseMatterConfirmAction = ({
  id,
  name,
}: {
  id: string;
  name: string;
}) =>
  openConfirmAction({
    name: i18nMessage('store.matter.closeMatterConfirm.name'),
    content: (
      <EntityActionConfirmContent
        title={i18nMessage('store.matter.closeMatterConfirm.title')}
        name={name}
        description={i18nMessage('store.matter.closeMatterConfirm.description')}
      />
    ),
    okButtonTitle: i18nMessage('store.matter.closeMatterConfirm.okButton'),
    cancelButtonTitle: i18nMessage(
      'store.matter.closeMatterConfirm.cancelButton'
    ),
    okButtonSkin: 'primary',
    async onConfirm() {
      await gqlClient.mutate<CloseMatterMutation, CloseMatterMutationVariables>(
        { mutation: CLOSE_MATTER, variables: { id } }
      );

      notification().matterClosed();
    },
  });
