import React from 'react';
import { Amount } from '@appclose/core';

import { TrustTransactionStatuses } from '__generated__/globalTypes';

import { DisbursementStatusPropsType } from './DisbursementAmount.types';

export default function DisbursementAmount({
  status,
  amount,
}: DisbursementStatusPropsType) {
  return (
    <Amount
      value={amount}
      color={status === TrustTransactionStatuses.UNSETTLED ? 'info' : undefined}
    />
  );
}
