import { gql } from '@apollo/client';

export const CREATE_CREDIT_MEMO = gql`
  mutation CreateCreditMemo($creditMemoInput: CreditMemoInput!) {
    createCreditMemo(creditMemoInput: $creditMemoInput) {
      id
      amount
    }
  }
`;

const COMMON_INVOICE_FIELDS = gql`
  fragment CommonInvoiceFields on InvoiceType {
    id
    invoiceNumber
    createdAt
    dueDate
    balanceDue
    paidAmount
    requestedAmount
    destinationAccount
    contact {
      id
      name
    }
    history {
      date
      status
    }
  }
`;

export const FETCH_MONESQ_FREE_MODE_INVOICE = gql`
  query FetchMonesqFreeModeInvoice($id: ID!) {
    invoice: getInvoice(id: $id) {
      ...CommonInvoiceFields
    }
  }
  ${COMMON_INVOICE_FIELDS}
`;

export const FETCH_MONESQ_PAID_MODE_INVOICE = gql`
  query FetchMonesqPaidModeInvoice($id: ID!) {
    invoice: getInvoice(id: $id) {
      ...CommonInvoiceFields
      allowedMemoDetalization {
        timeEntryAmount
        expenseAmount
        flatFeeAmount
      }
    }
  }
  ${COMMON_INVOICE_FIELDS}
`;
