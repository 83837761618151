import { gql } from '@apollo/client';

export const THIRD_PARTY_PAYEE_SELECT_FRAGMENT = gql`
  fragment ThirdPartyPayeeSelect on ThirdPartyPayeeType {
    id
    payeeName
  }
`;

export const FETCH_THIRD_PARTY_PAYEES = gql`
  query FetchThirdPartyPayees(
    $filter: FilterThirdPartyPayeeInput
    $order: OrderThirdPartyPayeeInput
    $skip: Int
    $take: Int
  ) {
    listThirdPartyPayees(
      listThirdPartyPayeeInput: {
        filter: $filter
        order: $order
        skip: $skip
        take: $take
      }
    ) {
      items {
        ...ThirdPartyPayeeSelect
      }
      total
    }
  }
  ${THIRD_PARTY_PAYEE_SELECT_FRAGMENT}
`;
