import * as Yup from 'yup';

import {
  MATTER_BILLING_TYPE_SCHEMA,
  MATTER_DATE_OPENED_SCHEMA,
  MATTER_NAME_SCHEMA,
} from 'schemas/validations/matter';

import { MatterFormValuesType } from '../../ContactMatterPopupForm.types';

export const MatterFieldsetSchema = Yup.object().shape<MatterFormValuesType>({
  name: MATTER_NAME_SCHEMA,
  dateOpened: MATTER_DATE_OPENED_SCHEMA,
  matterNumber: Yup.string().nullable(),
  billable: Yup.boolean().required(),
  ...MATTER_BILLING_TYPE_SCHEMA.fields,
});
