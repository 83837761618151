import { InboxRecordsType } from '__generated__/globalTypes';

export enum LeadModalTabs {
  INDIVIDUAL,
  ENTITY,
}

export type LeadModalPropsType = {
  id?: string;
  contactId?: string;
  onClose(): void;
  inboxRecord?: InboxRecordsType;
  defaultTab?: LeadModalTabs;
  shouldOpenNew?: boolean;
};
