import React, { useEffect, useMemo, useState } from 'react';
import {
  FormGroup,
  InputFormField,
  UpperFirstInputFormField,
  useFormContext,
} from '@appclose/core';

import FormGrid from 'components/common/FormGrid/FormGrid';
import FieldsetFormArray from 'components/form/FieldsetFormArray';
import { BillingContactTypes } from '__generated__/globalTypes';
import ContactTypeSelectFormField from 'components/fields/ContactTypeSelectFormField';
import {
  CONTACT_TYPE_OPTIONS,
  CONTACT_TYPE_OPTIONS_WITHOUT_PRIMARY,
  INITIAL_BILLING_CONTACT,
} from 'constants/contacts';
import { useIntl } from 'i18n';

import { ContactsFieldsetPropsType } from './ContactsFieldset.types';

export default function ContactsFieldset({
  name = 'billingContacts',
  title,
  allowRemoveLast = false,
}: ContactsFieldsetPropsType) {
  const { t } = useIntl();
  const fieldsetTitle = title || t('form.contactsFieldset.defaultTitle');
  const { getFieldMeta } = useFormContext();
  const { value } = getFieldMeta<typeof INITIAL_BILLING_CONTACT[]>(name);
  const primary = value?.find(
    ({ type }) => type === BillingContactTypes.PRIMARY
  );

  const [hasPrimary, setHasPrimary] = useState(!!primary);

  useEffect(() => {
    setHasPrimary(!!primary);
  }, [primary]);

  const initialData = useMemo(
    () =>
      !hasPrimary
        ? INITIAL_BILLING_CONTACT
        : { ...INITIAL_BILLING_CONTACT, type: BillingContactTypes.SECONDARY },
    [hasPrimary]
  );

  return (
    <FieldsetFormArray
      title={fieldsetTitle}
      name={name}
      addButtonLabel={t('form.contactsFieldset.addButtonLabel')}
      limit={5}
      allowRemoveLast={allowRemoveLast}
      initialData={initialData}
    >
      {({ value, resolveName }) => (
        <FormGroup>
          <FormGrid>
            <ContactTypeSelectFormField
              name={resolveName('type')}
              options={
                !primary || value === primary
                  ? CONTACT_TYPE_OPTIONS
                  : CONTACT_TYPE_OPTIONS_WITHOUT_PRIMARY
              }
            />
            <InputFormField
              name={resolveName('email')}
              label={t('form.contactsFieldset.email')}
            />
            <UpperFirstInputFormField
              name={resolveName('firstName')}
              label={t('form.contactsFieldset.firstName')}
            />
            <UpperFirstInputFormField
              name={resolveName('lastName')}
              label={t('form.contactsFieldset.lastName')}
            />
            <UpperFirstInputFormField
              name={resolveName('middleName')}
              label={t('form.contactsFieldset.middleName')}
            />
          </FormGrid>
        </FormGroup>
      )}
    </FieldsetFormArray>
  );
}
