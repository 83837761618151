import React, { useCallback } from 'react';
import omit from 'lodash/omit';
import { useMutation, useQuery } from '@apollo/client';
import { dateManager, Loader, usePopupModal, traceError } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import FlatFeeMatterAmountPopupModal from 'components/modals/popups/FlatFeeMatterAmountPopupModal';
import { DemoEventNames, EntryTypes } from 'constants/analytics';
import { SERVER_DATE_FORMAT } from 'constants/date';
import { FLAT_FEE_DETAILS_MODAL } from 'constants/modals';
import { track } from 'controllers/analytics';
import { hasFlatFeeOverflowError } from 'controllers/error';
import { openModal } from 'controllers/modal';
import notification from 'controllers/notification';
import { useIntl } from 'i18n';
import useCloseConfirm from 'hooks/useCloseConfirm';
import useUpdateFiles from 'hooks/useUpdateFiles';
import useProfile from 'hooks/useProfile';
import { Entities } from 'constants/entities';

import {
  CREATE_FLAT_FEE,
  FETCH_CONTACT,
  FETCH_FLAT_FEE,
  FETCH_MATTER,
  UPDATE_FLAT_FEE,
} from './FlatFeeModal.gql';
import {
  CreateFlatFeeMutation,
  CreateFlatFeeMutationVariables,
  FetchContactQuery,
  FetchContactQueryVariables,
  FetchFlatFeeQuery,
  FetchFlatFeeQueryVariables,
  FetchMatterQuery,
  FetchMatterQueryVariables,
  UpdateFlatFeeMutation,
  UpdateFlatFeeMutationVariables,
} from './__generated__/FlatFeeModal.gql';
import FlatFeeForm, { FlatFeeFormValuesType } from './components/FlatFeeForm';
import { FlatFeeModalPropsType } from './FlatFeeModal.types';

export default function FlatFeeModal({
  id,
  contactId,
  matterId,
  onClose,
}: FlatFeeModalPropsType) {
  const { t } = useIntl();
  const { onUpdateFiles } = useUpdateFiles(Entities.EXPENSE);
  const isEdit = !!id;
  const title = isEdit
    ? t('modal.flatFee.title.edit')
    : t('modal.flatFee.title.add');

  const { loading: loadingProfile, profile } = useProfile();
  const { loading: loadingContact, data: contactData } = useQuery<
    FetchContactQuery,
    FetchContactQueryVariables
  >(FETCH_CONTACT, {
    variables: { id: contactId as string },
    skip: !contactId,
  });

  const { loading: loadingMatter, data: matterData } = useQuery<
    FetchMatterQuery,
    FetchMatterQueryVariables
  >(FETCH_MATTER, {
    variables: { id: matterId as string },
    skip: !matterId,
  });

  const { loading, data } = useQuery<
    FetchFlatFeeQuery,
    FetchFlatFeeQueryVariables
  >(FETCH_FLAT_FEE, {
    variables: {
      id: id as string,
    },
    skip: !id,
  });

  const [updateFlatFee] = useMutation<
    UpdateFlatFeeMutation,
    UpdateFlatFeeMutationVariables
  >(UPDATE_FLAT_FEE);

  const [createFlatFee] = useMutation<
    CreateFlatFeeMutation,
    CreateFlatFeeMutationVariables
  >(CREATE_FLAT_FEE);

  const isLoading =
    loadingContact || loadingMatter || loadingProfile || loading;
  const profileId = profile?.id as string;
  const flatFee = data?.flatFee;

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });
  const { openPopupModal } = usePopupModal(FlatFeeMatterAmountPopupModal);

  const handleOnSubmit = useCallback(
    async ({
      contact,
      matter,
      billable,
      billedBy,
      files,
      ...values
    }: FlatFeeFormValuesType) => {
      let flatFeeId = id;

      try {
        if (isEdit) {
          await updateFlatFee({
            variables: {
              flatFee: {
                id: id as string,
                contactId: contact?.id as string,
                matterId: matter?.id as string,
                billedById: billedBy?.id as string,
                billable,
                ...values,
              },
            },
          });

          notification().entityUpdated(t('modal.flatFee.notification.updated'));
        } else {
          const result = await createFlatFee({
            variables: {
              flatFee: {
                contactId: contact?.id as string,
                matterId: matter?.id as string,
                billedById: billedBy?.id as string,
                billable,
                ...values,
              },
            },
          });

          notification().entityCreated(t('modal.flatFee.notification.created'));

          track(DemoEventNames.DEMO_ENTRY_CREATED, {
            entry_type: EntryTypes.FLAT_FEE,
          });

          flatFeeId = result.data?.createFlatFee?.id;
        }
      } catch (error) {
        if (hasFlatFeeOverflowError(error)) {
          openPopupModal({
            isEdit,
            matterId: matter?.id as string,
            becameBillable: Boolean(!flatFee?.billable && billable),
            currentFlatFeeAmount: flatFee?.amount,
            createdFlatFeeAmount: values.amount,
          });

          return;
        }

        traceError(error);
      }

      if (flatFeeId && files) {
        await onUpdateFiles({ entityId: flatFeeId, ...files });
      }

      onClose();

      flatFeeId && openModal(FLAT_FEE_DETAILS_MODAL, { id: flatFeeId });
    },
    [
      id,
      onClose,
      isEdit,
      updateFlatFee,
      t,
      createFlatFee,
      openPopupModal,
      flatFee?.billable,
      flatFee?.amount,
      onUpdateFiles,
    ]
  );

  const initialValues = {
    amount: 0,
    date: flatFee?.date
      ? dateManager().parse(flatFee.date).format(SERVER_DATE_FORMAT)
      : dateManager().parse().startOf('day').format(SERVER_DATE_FORMAT),
    billable: true,
    contact: contactData?.contact || matterData?.matter?.contact,
    matter: matterData?.matter,
    ...omit(flatFee || {}, ['status', 'date']),
  };

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>{title}</ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {isLoading ? (
          <Loader />
        ) : (
          <FlatFeeForm
            initialValues={initialValues}
            isEdit={isEdit}
            profileId={profileId}
            onSubmit={handleOnSubmit}
            onCancel={onConfirmClose}
            onChange={onFormChange}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
