import { gql } from '@apollo/client';

export const SET_INITIAL_LEDGER_BALANCES = gql`
  mutation SetInitialLedgerBalances(
    $initialBalances: SetInitialBalancesInput!
  ) {
    setInitialLedgerBalances(setInitialBalancesInput: $initialBalances) {
      success
    }
  }
`;
