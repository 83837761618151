import React, { useMemo } from 'react';
import {
  AmountFormField,
  DatePickerFormField,
  Fieldset,
  FormGroup,
  useFormContext,
  TextAreaFormField,
  EnhancedFormArrayField,
  dateManager,
} from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import ContactMatterSelectFormField from 'components/fields/ContactMatterSelectFormField';
import { useIntl, I18n } from 'i18n';

import { ContactsAndMattersStepFormValuesType } from '../../ContactsAndMattersStep.types';
import UnclearedTransactionTypeSelectFormField from './components/UnclearedTransactionTypeSelectFormField';
import styles from './LedgerBalancesFormArrayField.module.scss';
import { SERVER_DATE_FORMAT } from 'constants/date';
import { Grid } from '@appclose/ui';

export default function LedgerBalancesFormArrayField() {
  const { t } = useIntl();
  const {
    values: { ledgerBalances },
  } = useFormContext<ContactsAndMattersStepFormValuesType>();

  const matterIds = useMemo(
    () =>
      ledgerBalances
        ?.map<string>((balance) => balance?.matter?.id || '')
        .filter(Boolean),
    [ledgerBalances]
  );
  const contactIds = useMemo(
    () =>
      ledgerBalances
        ?.map<string>((balance) => balance?.contact?.id || '')
        .filter(Boolean),
    [ledgerBalances]
  );

  return (
    <EnhancedFormArrayField
      name="ledgerBalances"
      addButtonLabel={t(
        'modal.trustSetupBalancesStep.ledgerBalancesFormArrayField.add'
      )}
      deleteButtonPosition="start"
    >
      {({ resolveName, index, value }) => {
        const excludeContactIds = contactIds?.filter(
          (id) => value?.contact?.id !== id
        );
        const excludeMatterIds = matterIds?.filter(
          (id) => value?.matter?.id !== id
        );

        return (
          <Fieldset
            title={t(
              'modal.trustSetupBalancesStep.ledgerBalancesFormArrayField.fieldset.contactMatter',
              { index: index + 1 }
            )}
            className={styles.contactMatterFieldset}
          >
            <FormGroup>
              <FormGrid>
                <ContactMatterSelectFormField
                  contactFieldName={resolveName('contact')}
                  matterFieldName={resolveName('matter')}
                  excludeContactIds={excludeContactIds}
                  excludeMatterIds={excludeMatterIds}
                />
              </FormGrid>
            </FormGroup>
            <FormGroup>
              <FormGrid>
                <AmountFormField
                  label={t(
                    'modal.trustSetupBalancesStep.ledgerBalancesFormArrayField.field.trustBalance'
                  )}
                  name={resolveName('balance')}
                />
              </FormGrid>
            </FormGroup>
            <p className={styles.unclearedTransactionsTitle}>
              <I18n id="modal.trustSetupBalancesStep.ledgerBalancesFormArrayField.field.unclearedTransactions.label" />
            </p>
            <FormGroup>
              <EnhancedFormArrayField
                className={styles.unclearedTransactions}
                name={resolveName('unclearedTransactions')}
                addButtonLabel={t(
                  'modal.trustSetupBalancesStep.ledgerBalancesFormArrayField.field.amount.unclearedTransactions'
                )}
                deleteButtonPosition="center"
              >
                {({ resolveName }) => (
                  <FormGroup>
                    <Grid columns="auto" gap={[0, 12]}>
                      <Grid columns="160px auto 160px" gap={[40]}>
                        <UnclearedTransactionTypeSelectFormField
                          name={resolveName('type')}
                        />
                        <DatePickerFormField
                          name={resolveName('date')}
                          label={t(
                            'modal.trustSetupBalancesStep.ledgerBalancesFormArrayField.field.date'
                          )}
                          outputDateFormat={SERVER_DATE_FORMAT}
                          maxDate={dateManager()
                            .parse()
                            .endOf('day')
                            .valueOf()}
                        />
                        <AmountFormField
                          label={t(
                            'modal.trustSetupBalancesStep.ledgerBalancesFormArrayField.field.amount'
                          )}
                          name={resolveName('amount')}
                        />
                      </Grid>
                      <TextAreaFormField
                        name={resolveName('memo')}
                        label={t(
                          'modal.trustSetupBalancesStep.ledgerBalancesFormArrayField.field.memo'
                        )}
                      />
                    </Grid>
                  </FormGroup>
                )}
              </EnhancedFormArrayField>
            </FormGroup>
          </Fieldset>
        );
      }}
    </EnhancedFormArrayField>
  );
}
