import { BoundType, PreviewTypes } from './Preview.types';
import variables from './scss/_export.module.scss';

export const Bounds: Record<PreviewTypes, BoundType> = {
  [PreviewTypes.CHECK]: {
    top: Number(variables.checkBoundTop),
    right: Number(variables.checkBoundRight),
    bottom: Number(variables.checkBoundBottom),
    left: Number(variables.checkBoundLeft),
  },
  [PreviewTypes.DEPOSIT_SLIP]: {
    top: Number(variables.depositSlipBoundTop),
    right: Number(variables.depositSlipBoundRight),
    bottom: Number(variables.depositSlipBoundBottom),
    left: Number(variables.depositSlipBoundLeft),
  },
};
