import { useCallback } from 'react';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { SIGN_IN_ROUTE, useLocalStorage } from '@appclose/core';

import {
  USER_ID_PARAM_KEY,
  MODE_PARAM_KEY,
  PROMO_CODE_PARAM_KEY,
  UTM_SOURCE_PARAM_KEY,
  UTM_MEDIUM_PARAM_KEY,
  UTM_CAMPAIGN_PARAM_KEY,
  UTM_CONTENT_PARAM_KEY,
} from 'constants/queryParams';
import { PROMO_CODE_KEY, ANALYTICS_UTM_KEY } from 'constants/localStorage';
import { Modes, UtmParamsInput } from '__generated__/globalTypes';
import useUrlSearchParams from 'hooks/useUrlSearchParams';

import { deserializer, serializer } from './useEnvForwardingParams.utils';
import {
  DemoModesName,
  MODE_MAP,
  PROMO_CODE_TTL,
  UTM_PARAMS_TTL,
} from './useEnvForwardingParams.constants';
import { APP_PURCHASE_URL } from '../../constants/env';

export default function useEnvForwardingParams() {
  const { searchParams } = useUrlSearchParams<{
    [MODE_PARAM_KEY]?: DemoModesName;
    [USER_ID_PARAM_KEY]?: string;
    [PROMO_CODE_PARAM_KEY]?: string;
    [UTM_SOURCE_PARAM_KEY]?: string;
    [UTM_MEDIUM_PARAM_KEY]?: string;
    [UTM_CAMPAIGN_PARAM_KEY]?: string;
    [UTM_CONTENT_PARAM_KEY]?: string;
  }>({ parseNumbers: false });
  const modeName = searchParams[MODE_PARAM_KEY]?.toLowerCase() as
    | DemoModesName
    | undefined;
  const mode = modeName ? MODE_MAP[modeName] : undefined;
  const demoUserId = searchParams[USER_ID_PARAM_KEY];
  const searchPromoCodeParam = searchParams[PROMO_CODE_PARAM_KEY] || undefined;
  const rawSearchUtmPrams: UtmParamsInput = omitBy(
    {
      source: searchParams[UTM_SOURCE_PARAM_KEY],
      medium: searchParams[UTM_MEDIUM_PARAM_KEY],
      campaign: searchParams[UTM_CAMPAIGN_PARAM_KEY],
      content: searchParams[UTM_CONTENT_PARAM_KEY],
    },
    isNil
  );
  const searchUtmPrams = !isEmpty(rawSearchUtmPrams)
    ? rawSearchUtmPrams
    : undefined;

  const [storedPromoCode, setStoredPromoCode] = useLocalStorage<
    string | undefined
  >(PROMO_CODE_KEY, undefined, {
    raw: false,
    serializer,
    deserializer: deserializer(PROMO_CODE_TTL),
  });

  const [
    storedUtmParams,
    setStoredUtmParams,
    clearStoredUtmParams,
  ] = useLocalStorage<UtmParamsInput | undefined>(
    ANALYTICS_UTM_KEY,
    undefined,
    {
      raw: false,
      serializer,
      deserializer: deserializer(UTM_PARAMS_TTL),
    }
  );

  const promoCode = searchPromoCodeParam || storedPromoCode;
  const utmParams = searchUtmPrams || storedUtmParams;

  const savePrams = useCallback(() => {
    if (searchPromoCodeParam) {
      setStoredPromoCode(searchPromoCodeParam);
    }

    if (searchUtmPrams) {
      setStoredUtmParams(searchUtmPrams);
    }
  }, [
    searchPromoCodeParam,
    searchUtmPrams,
    setStoredPromoCode,
    setStoredUtmParams,
  ]);

  const clearParams = useCallback(() => {
    clearStoredUtmParams();
  }, [clearStoredUtmParams]);

  const getDemoSignInModeLink = useCallback((mode: Modes) => {
    const params = new URLSearchParams({
      [MODE_PARAM_KEY]:
        mode === Modes.FREE ? DemoModesName.FREE : DemoModesName.PLUS,
    });

    return `${SIGN_IN_ROUTE}?${params.toString()}`;
  }, []);

  const getDemoSubscribeLink = useCallback(
    (demoUserId?: string) => {
      if (!APP_PURCHASE_URL) {
        return;
      }

      const params = new URLSearchParams();

      if (demoUserId) {
        params.set(USER_ID_PARAM_KEY, demoUserId);
      }

      if (promoCode) {
        params.set(PROMO_CODE_PARAM_KEY, promoCode);
      }

      if (utmParams?.campaign) {
        params.set(UTM_CAMPAIGN_PARAM_KEY, utmParams.campaign);
      }

      if (utmParams?.content) {
        params.set(UTM_CONTENT_PARAM_KEY, utmParams.content);
      }

      if (utmParams?.medium) {
        params.set(UTM_MEDIUM_PARAM_KEY, utmParams.medium);
      }

      if (utmParams?.source) {
        params.set(UTM_SOURCE_PARAM_KEY, utmParams.source);
      }

      return `${APP_PURCHASE_URL}?${params.toString()}`;
    },
    [promoCode, utmParams]
  );

  return {
    params: {
      mode,
      demoUserId,
      promoCode,
      utmParams,
    },
    clearParams,
    savePrams,
    getDemoSignInModeLink,
    getDemoSubscribeLink,
  };
}
