import React from 'react';
import { SelectFormField } from '@appclose/core';

import useSubExpenseCategoryCostTypes from 'hooks/useSubExpenseCategoryCostTypes';
import { useIntl } from 'i18n';

import { ExpenseCategoryCostTypeSelectFormFieldPropsType } from './ExpenseCategoryCostTypeSelectFormField.types';

export default function ExpenseCategoryCostTypeSelectFormField({
  name = 'costType',
  label,
  placeholder,
  clearable = false,
  ...props
}: ExpenseCategoryCostTypeSelectFormFieldPropsType) {
  const { t } = useIntl();
  const {
    subExpenseCategoryCostTypesOptions,
  } = useSubExpenseCategoryCostTypes();

  return (
    <SelectFormField
      {...props}
      clearable={clearable}
      name={name}
      label={label || t('field.expenseCategoryCostTypeSelect.label')}
      placeholder={
        placeholder || t('field.expenseCategoryCostTypeSelect.placeholder')
      }
      options={subExpenseCategoryCostTypesOptions}
    />
  );
}
