import React, { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Loader } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { BankAccountClasses, CreditMemoInput } from '__generated__/globalTypes';
import {
  CREDIT_MEMO_DETAILS_MODAL,
  SEND_CREDIT_MEMO_MODAL,
} from 'constants/modals';
import { openModal } from 'controllers/modal';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { isFreePlan, isPlusPlan } from 'controllers/mode';
import { isDemoBuild } from 'controllers/demo';
import { I18n, useIntl } from 'i18n';
import useUpdateFiles from 'hooks/useUpdateFiles';
import { Entities } from 'constants/entities';

import { InvoiceCreditMemoFormValuesType } from './components/InvoiceCreditMemoForm/InvoiceCreditMemoForm.types';
import {
  CREATE_CREDIT_MEMO,
  FETCH_MONESQ_FREE_MODE_INVOICE,
  FETCH_MONESQ_PAID_MODE_INVOICE,
} from './InvoiceCreditMemoModal.gql';
import {
  CreateCreditMemoMutation,
  CreateCreditMemoMutationVariables,
  FetchMonesqFreeModeInvoiceQuery,
  FetchMonesqFreeModeInvoiceQueryVariables,
  FetchMonesqPaidModeInvoiceQuery,
  FetchMonesqPaidModeInvoiceQueryVariables,
} from './__generated__/InvoiceCreditMemoModal.gql';
import InvoiceCreditMemoForm from './components/InvoiceCreditMemoForm';
import { InvoiceCreditMemoModalPropsType } from './InvoiceCreditMemoModal.types';

export default function InvoiceCreditMemoModal({
  id,
  onClose,
}: InvoiceCreditMemoModalPropsType) {
  const { t } = useIntl();
  const { onUpdateFiles } = useUpdateFiles(Entities.PAYMENT);
  const [createCreditMemo] = useMutation<
    CreateCreditMemoMutation,
    CreateCreditMemoMutationVariables
  >(CREATE_CREDIT_MEMO);
  const { loading: freeInvoiceLoading, data: freeInvoiceData } = useQuery<
    FetchMonesqFreeModeInvoiceQuery,
    FetchMonesqFreeModeInvoiceQueryVariables
  >(FETCH_MONESQ_FREE_MODE_INVOICE, {
    variables: { id: id as string },
    skip: !id || isPlusPlan(),
  });

  const { loading: paidInvoiceLoading, data: paidInvoiceData } = useQuery<
    FetchMonesqPaidModeInvoiceQuery,
    FetchMonesqPaidModeInvoiceQueryVariables
  >(FETCH_MONESQ_PAID_MODE_INVOICE, {
    variables: { id: id as string },
    skip: !id || isFreePlan(),
  });

  const invoiceLoading = freeInvoiceLoading || paidInvoiceLoading;
  const invoiceData = freeInvoiceData || paidInvoiceData;

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });

  const handleOnSubmit = useCallback(
    async ({
      invoice,
      creditAmount,
      memoText,
      sendEmail,
      enableFlatFees,
      enableTimeEntries,
      enableExpenses,
      flatFeeAmount,
      timeEntryAmount,
      expenseAmount,
      files,
    }: InvoiceCreditMemoFormValuesType) => {
      const creditMemoInput: CreditMemoInput = {
        creditAmount,
        memoText,
        invoiceId: invoice?.id,
        ...(invoice.destinationAccount !== BankAccountClasses.TRUST &&
        isPlusPlan()
          ? {
              memoDetalization: {
                ...(enableFlatFees ? { flatFeeAmount } : {}),
                ...(enableTimeEntries ? { timeEntryAmount } : {}),
                ...(enableExpenses ? { expenseAmount } : {}),
              },
            }
          : {}),
      };

      const { data } = await createCreditMemo({
        variables: { creditMemoInput },
      });

      const id = data?.createCreditMemo.id;

      if (id && files) {
        await onUpdateFiles({ entityId: id, ...files });
      }

      onClose();

      notification().entityCreated(
        t('modal.creditMemo.notification.create.success')
      );

      if (!isDemoBuild() && sendEmail) {
        openModal(SEND_CREDIT_MEMO_MODAL, {
          id,
          invoiceId: invoice?.id,
        });
      } else {
        openModal(CREDIT_MEMO_DETAILS_MODAL, {
          id,
        });
      }
    },
    [createCreditMemo, onClose, t, onUpdateFiles]
  );

  let initialValues = {
    invoice: invoiceData?.invoice,
    creditAmount: 0,
    sendEmail: true,
    enableTimeEntries: false,
    enableExpenses: false,
    enableFlatFees: false,
    timeEntryAmount: 0,
    expenseAmount: 0,
    flatFeeAmount: 0,
  } as InvoiceCreditMemoFormValuesType;

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.creditMemo.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {invoiceLoading ? (
          <Loader />
        ) : (
          <InvoiceCreditMemoForm
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            onCancel={onConfirmClose}
            onChange={onFormChange}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
