import React, { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { Button, ButtonGroup } from '@appclose/ui';
import { Loader, Fieldset } from '@appclose/core';
import { downloadFileByBase64, getDataUri } from '@appclose/lib';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import PdfViewer from 'components/common/PdfViewer';
import notification from 'controllers/notification';
import { I18n, useIntl } from 'i18n';

import {
  FetchDepositSliSamplepPdfQuery,
  FetchDepositSliSamplepPdfQueryVariables,
} from './__generated__/PrintDepositsSamplePreviewModal.gql';
import { FETCH_DEPOSIT_SLIP_SAMPLE_PDF } from './PrintDepositsSamplePreviewModal.gql';
import { PrintDepositsPreviewModalPropsType } from './PrintDepositsSamplePreviewModal.types';
import styles from './PrintDepositsSamplePreviewModal.module.scss';

export default function PrintDepositsSamplePreviewModal({
  onClose,
  offset,
}: PrintDepositsPreviewModalPropsType) {
  const { t } = useIntl();
  const { loading, data } = useQuery<
    FetchDepositSliSamplepPdfQuery,
    FetchDepositSliSamplepPdfQueryVariables
  >(FETCH_DEPOSIT_SLIP_SAMPLE_PDF, {
    variables: {
      input: offset ? { offset } : undefined,
    },
    fetchPolicy: 'network-only',
  });

  const { data: base64data = '', mimetype = '', name } = data?.sample || {};

  const dataUri = getDataUri(base64data, mimetype);

  const handleOnPrint = useCallback(async () => {
    downloadFileByBase64(base64data, mimetype, name);

    notification().info(
      t('modal.printDepositsSamplePreview.notification.printed')
    );

    onClose();
  }, [base64data, mimetype, name, onClose, t]);

  return (
    <ModalPage onClose={onClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.printDepositsSamplePreview.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Fieldset
              title={t('modal.printDepositsSamplePreview.fieldset.title')}
              className={styles.fieldset}
            >
              <PdfViewer file={dataUri} />
            </Fieldset>
            <ButtonGroup>
              <Button onClick={onClose}>
                <I18n id="modal.printDepositsSamplePreview.cancel" />
              </Button>
              <Button skin="primary" onClick={handleOnPrint}>
                <I18n id="modal.printDepositsSamplePreview.submit" />
              </Button>
            </ButtonGroup>
          </>
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
