import * as Yup from 'yup';

import { getI18nErrorMessage } from 'i18n';

import { TrustAccountingStepValuesType } from './TrustAccountingStep.types';

export const TrustAccountingStepSchema = Yup.object().shape<
  TrustAccountingStepValuesType
>({
  isEsqash: Yup.boolean().required(),
  operatingAccount: Yup.string().required(),
  trustAccount: Yup.string()
    .nullable()
    .required(
      getI18nErrorMessage(
        'modal.qboIntegrationSetup.step.mapTrustAccounting.error.trustAccount.required'
      )
    ),
  trustDepositAccount: Yup.string()
    .nullable()
    .required(
      getI18nErrorMessage(
        'modal.qboIntegrationSetup.step.mapTrustAccounting.error.trustDepositAccount.required'
      )
    ),
  trustDepositItem: Yup.string()
    .nullable()
    .required(
      getI18nErrorMessage(
        'modal.qboIntegrationSetup.step.mapTrustAccounting.error.trustDepositItem.required'
      )
    ),
});
