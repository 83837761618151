import { ReactNode, ReactNodeArray } from 'react';
import { ShepherdOptionsWithType } from 'react-shepherd';

import { TFuncType } from 'i18n';

export enum TOUR_STEPS_NAME {
  ADD_NEW = 'add-new',
  MAIN_MENU = 'main-menu',
  DASHBOARD_STATISTIC = 'dashboard-statistic',
  TIME_TRACKER = 'time-tracker',
  PRIVACY = 'privacy',
  MATTER = 'matter',
  FILTER = 'filter',
  VIEW_MODE = 'view-mod',
  TRUST_ACCOUNT_STATISTIC = 'trust-account-statistic',
  MENU = 'menu',
  CONTEXT_MENU = 'context-menu',
  INVOICE_STATUS = 'invoice-status',
  MAIN_MENU_BUTTON = 'menu-button',
  DEMO_MODE_SWITCH = 'demo-mode-switch',
  RECEIVE_PAYMENT_BUTTON = 'receive-payment-button',
  MENU_SETTINGS = 'menu-settings',
}

export type StepsOptionsType = {
  t: TFuncType,
  isMobile: boolean,
  setMenuOpen: (open: boolean) => void,
}

export type StepsType = (options: StepsOptionsType) => ShepherdOptionsWithType[];

export type ButtonType = (
  t: TFuncType
) => NonNullable<NonNullable<ShepherdOptionsWithType[]>[0]['buttons']>[0];

export type TourType = { exact: boolean; steps: StepsType };

export type TourContextType = {
  hasTour: boolean;
};

export type TourPropsType = {
  children: ReactNode | ReactNodeArray;
};
