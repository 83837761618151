import * as Yup from 'yup';

import { SelectContactType } from 'components/fields/ContactSelectFormField';
import { SelectMatterType } from 'components/fields/MatterSelectFormField';
import { TimeEntryActivitySelectType } from 'components/fields/TimeEntryActivitySelectFormField';
import { SelectTeamMemberType } from 'components/fields/TeamMemberSelectFormField';
import { NOTES_VALIDATION_SCHEMA } from 'schemas/validations/notes';
import { getI18nErrorMessage } from 'i18n';
import { FILES_SCHEMA } from 'schemas/validations/files';

import { TimeEntryFormValuesType } from './TimeEntryForm.types';

export const TimeEntryFormSchema = Yup.object().shape<TimeEntryFormValuesType>({
  durationSec: Yup.number()
    .required(
      getI18nErrorMessage('modal.timeEntry.form.error.durationSec.required')
    )
    .moreThan(
      0,
      getI18nErrorMessage('modal.timeEntry.form.error.durationSec.moreThan0')
    ),
  billable: Yup.boolean().required(
    getI18nErrorMessage('modal.timeEntry.form.error.billable.required')
  ),
  billingRate: Yup.number()
    .moreThan(
      0,
      getI18nErrorMessage('modal.timeEntry.form.error.billingRate.moreThan0')
    )
    .required(
      getI18nErrorMessage('modal.timeEntry.form.error.billingRate.required')
    ),
  description: Yup.string().nullable(),
  notes: NOTES_VALIDATION_SCHEMA,
  contact: Yup.mixed<SelectContactType>().required(
    getI18nErrorMessage('modal.timeEntry.form.error.contact.required')
  ),
  matter: Yup.mixed<SelectMatterType>().required(
    getI18nErrorMessage('modal.timeEntry.form.error.matter.required')
  ),
  activity: Yup.mixed<TimeEntryActivitySelectType>().required(
    getI18nErrorMessage('modal.timeEntry.form.error.activity.required')
  ),
  billedBy: Yup.mixed<SelectTeamMemberType>().required(
    getI18nErrorMessage('modal.timeEntry.form.error.billedBy.required')
  ),
  amount: Yup.number(),
  date: Yup.string()
    .nullable()
    .required(getI18nErrorMessage('modal.timeEntry.form.error.date.required')),
  files: FILES_SCHEMA,
});
