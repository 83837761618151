import React from 'react';
import { Flex, Grid } from '@appclose/ui';
import { Loader, useFormContext, Fieldset } from '@appclose/core';

import AttachFilesFormField from 'components/fields/AttachFilesFormField';
import { useIntl, I18n } from 'i18n';
import { ReactComponent as CardIcon } from 'assets/card.svg';
import { CONTACTS_IMPORT_FILE_MIME_TYPES } from 'constants/file';

import { ContactAndEntityFormValuesType } from '../../ContactForm.types';
import styles from './MultipleImportFieldset.module.scss';

export default function MultipleImportFieldset() {
  const { t } = useIntl();
  const {
    isSubmitting,
    values: { importSummary },
  } = useFormContext<ContactAndEntityFormValuesType>();

  return (
    <Fieldset title={t('modal.contact.form.import.fieldset.import.title')}>
      {!importSummary ? (
        (!isSubmitting && (
          <>
            <p className={styles.text}>
              <I18n id="modal.contact.form.import.fieldset.import.attachNote" />
            </p>
            <AttachFilesFormField
              filesName="vCardFiles"
              accept={CONTACTS_IMPORT_FILE_MIME_TYPES}
            />
          </>
        )) || (
          <Flex direction="column" alignItems="center">
            <CardIcon className={styles.card} />
            <p className={styles.text}>
              <I18n id="modal.contact.form.import.fieldset.import.processingNote" />
            </p>
            <Loader />
          </Flex>
        )
      ) : (
        <>
          <p className={styles.text}>
            <I18n id="modal.contact.form.import.fieldset.import.completedNote" />
          </p>
          <Grid
            gap={[20, 20]}
            columns="1fr max-content"
            className={styles.list}
          >
            <p className={styles.item}>
              <I18n id="modal.contact.form.import.fieldset.import.processedContacts" />
            </p>
            <p className={styles.value}>{importSummary.processed}</p>
            <p className={styles.item}>
              <I18n id="modal.contact.form.import.fieldset.import.addedContacts" />
            </p>
            <p className={styles.value}>{importSummary.added}</p>
            <p className={styles.item}>
              <I18n id="modal.contact.form.import.fieldset.import.ignoredContacts" />
            </p>
            <p className={styles.value}>
              <span>{importSummary.ignored}</span>
            </p>
            <p className={styles.item}>
              <I18n id="modal.contact.form.import.fieldset.import.duplicatedContacts" />
            </p>
            <p className={styles.value}>
              <span>{importSummary.duplicated}</span>
            </p>
            <p className={styles.item}>
              <I18n id="modal.contact.form.import.fieldset.import.invalidContacts" />
            </p>
            <p className={styles.value}>
              <span>{importSummary.invalid}</span>
            </p>
          </Grid>
        </>
      )}
    </Fieldset>
  );
}
