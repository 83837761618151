import React from 'react';
import { Flex, Text5 } from '@appclose/ui';
import { ExternalLink, useIsMobileDevice } from '@appclose/core';

import { LANDING_URL } from 'constants/env';
import { ROOT_ROUTE } from 'constants/routes';
import { I18n } from 'i18n';
import { ReactComponent as EsqIcon } from 'assets/esq.svg';

import styles from './PoweredBy.module.scss';

export default function PoweredBy() {
  const isMobileDevice = useIsMobileDevice();

  return (
    <ExternalLink link={LANDING_URL || ROOT_ROUTE} className={styles.link}>
      <Flex alignItems="center" gap={[12]}>
        {!isMobileDevice && (
          <Text5 color="secondary">
            <I18n id="poweredBy" />
          </Text5>
        )}
        <EsqIcon width={32} height={32} />
      </Flex>
    </ExternalLink>
  );
}
