import { Modes } from '__generated__/globalTypes';

export const PROMO_CODE_TTL = 60;
export const UTM_PARAMS_TTL = 30;

export enum DemoModesName {
  FREE = 'free',
  PLUS = 'plus',
}

export const MODE_MAP: { [key in DemoModesName]: Modes } = {
  [DemoModesName.FREE]: Modes.FREE,
  [DemoModesName.PLUS]: Modes.PLUS,
};
