import gqlClient from 'gql';
import { openConfirmAction, traceError } from '@appclose/core';

import notification from 'controllers/notification';
import { i18nMessage } from 'i18n';

import { DELETE_FLAT_FEE } from './flatFee.gql';
import {
  DeleteFlatFeeMutation,
  DeleteFlatFeeMutationVariables,
} from './__generated__/flatFee.gql';

export const openDeleteFlatFeeConfirmAction = ({
  id,
  onSuccess,
}: {
  id: string;
  onSuccess?(): void;
}) =>
  openConfirmAction({
    name: i18nMessage('store.flatFee.deleteFlatFeeConfirm.name'),
    content: i18nMessage('store.flatFee.deleteFlatFeeConfirm.content'),
    okButtonTitle: i18nMessage('store.flatFee.deleteFlatFeeConfirm.okButton'),
    cancelButtonTitle: i18nMessage(
      'store.flatFee.deleteFlatFeeConfirm.cancelButton'
    ),
    okButtonSkin: 'primary',
    async onConfirm() {
      try {
        await gqlClient.mutate<
          DeleteFlatFeeMutation,
          DeleteFlatFeeMutationVariables
        >({
          mutation: DELETE_FLAT_FEE,
          variables: { id },
        });

        notification().entityDeleted(
          i18nMessage('store.flatFee.deleteFlatFeeConfirm.notify')
        );

        onSuccess && onSuccess();
      } catch (e) {
        traceError(e);
      }
    },
  });
