import { gql } from '@apollo/client';

export const ON_BANK_ACCOUNT_UPDATED = gql`
  subscription BankAccountUpdated {
    bankAccountUpdated {
      id
      class
    }
  }
`;
