import * as Yup from 'yup';

import { SignerRoleTemplateType } from '__generated__/globalTypes';
import { getI18nErrorMessage } from 'i18n';
import { SIGNER_ROLE_NAME_FIELD_LENGTH_LIMIT } from 'constants/fieldsLimitations';
import { AT_LEAST_ONE_FILE_SCHEMA } from 'schemas/validations/files';

import { SignatureTemplateFormValuesType } from './SignatureTemplateForm.types';

export const CreateSignatureTemplateFormSchema = Yup.object().shape<SignatureTemplateFormValuesType>(
  {
    title: Yup.string().required(
      getI18nErrorMessage('modal.signatureTemplate.form.error.title.required')
    ),
    completeInOrder: Yup.boolean().defined(),
    signerRoles: Yup.array(
      Yup.object()
        .shape<Pick<SignerRoleTemplateType, 'roleName'>>({
          roleName: Yup.string()
            .max(SIGNER_ROLE_NAME_FIELD_LENGTH_LIMIT, ({ max }) =>
              getI18nErrorMessage(
                'modal.signatureTemplate.form.error.signerRoles.roleName.maxLength',
                { max }
              )
            )
            .required(
              getI18nErrorMessage(
                'modal.signatureTemplate.form.error.signerRoles.roleName.required'
              )
            )
            .test(
              'signerRolesNotOnlyNumbers',
              getI18nErrorMessage(
                'modal.signatureTemplate.form.error.signerRoles.roleName.notOnlyNumbers'
              ),
              (value) => (value || '').replace(/\d+/, '').length > 0
            ),
        })
        .required(
          getI18nErrorMessage(
            'modal.signatureTemplate.form.error.signerRoles.required'
          )
        )
    )
      .min(
        1,
        getI18nErrorMessage(
          'modal.signatureTemplate.form.error.signerRoles.minLength'
        )
      )
      .required(
        getI18nErrorMessage(
          'modal.signatureTemplate.form.error.signerRoles.required'
        )
      ),
    files: AT_LEAST_ONE_FILE_SCHEMA,
  }
);
