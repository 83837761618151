import React, { useContext } from 'react';
import classnames from 'classnames';
import { Grid } from '@appclose/ui';
import { CheckBoldIcon } from '@appclose/icons';

import { WizardContext } from '../../Wizard.context';
import { WizardProgressPropsType } from './WizardProgress.types';
import styles from './WizardProgress.module.scss';

export default function WizardProgress({ steps }: WizardProgressPropsType) {
  const { step: currentStep, completed } = useContext(WizardContext);

  const columns = '1fr '.repeat(steps.length);

  return (
    <Grid columns={columns} className={styles.wizardProgress}>
      {steps.map(({ step, title }) => {
        const stepCompleted = step < currentStep || completed;

        return (
          <div key={step}>
            <div
              className={classnames(styles.progressContainer, {
                [styles.firstStep]: step === 0,
                [styles.lastStep]: step === steps.length - 1,
              })}
            >
              <div className={styles.progressBar} />
              <div
                className={classnames(styles.progressBarActive, {
                  [styles.progressBarActiveComplete]: stepCompleted,
                })}
              />
              <div
                className={classnames(styles.stepPoint, {
                  [styles.stepPointCurrent]: step <= currentStep || completed,
                  [styles.stepPointCompleted]: stepCompleted,
                })}
              >
                {stepCompleted && (
                  <CheckBoldIcon width={16} height={16} fill="#fff" />
                )}
              </div>
              {step === steps.length - 1 && (
                <div
                  className={classnames(styles.stepPointLast, {
                    [styles.stepPointCurrent]: completed,
                  })}
                />
              )}
            </div>
            <p className={styles.stepNumber}>Step {step + 1}</p>
            <p
              className={classnames(styles.stepTitle, {
                [styles.stepTitleActive]: step === currentStep,
              })}
            >
              {title}
            </p>
          </div>
        );
      })}
    </Grid>
  );
}
