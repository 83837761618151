import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Button } from '@appclose/ui';
import { dateManager, useInterval } from '@appclose/core';
import {
  ClearIcon,
  TimerPlayIcon,
  TimerPauseIcon,
  TimerSaveIcon,
} from '@appclose/icons';

import { formatTimer } from 'controllers/timer';

import { TimerPropsType } from './Timer.types';
import styles from './Timer.module.scss';

export default function Timer({
  seconds: timeInSeconds = 0,
  isPaused = false,
  loading = false,
  blocked = false,
  count,
  compact = false,
  className,
  onTick,
  onStart,
  onPause,
  onDone,
  onCancel,
  onClick,
}: TimerPropsType) {
  const formattedTimer = useMemo(() => formatTimer(timeInSeconds), [
    timeInSeconds,
  ]);

  useInterval(
    () => onTick && onTick(),
    isPaused || !onTick ? null : dateManager().getMilliseconds('second')
  );

  const showDoneButton = isPaused && !!timeInSeconds;

  return (
    <div
      className={classnames(
        styles.timer,
        {
          [styles.compact]: compact,
        },
        className
      )}
    >
      {onCancel && (
        <Button
          skin="shell"
          className={styles.action}
          onClick={onCancel}
          disabled={blocked}
        >
          <ClearIcon />
        </Button>
      )}
      <p
        className={classnames(styles.time, {
          [styles.pointer]: onClick,
          [styles.paused]: isPaused,
        })}
        onClick={onClick}
      >
        {formattedTimer}
        {!!count && <span className={styles.count}> / {count}</span>}
      </p>
      <div className={styles.controls}>
        {isPaused ? (
          <Button
            skin="success"
            className={classnames(styles.control, {
              [styles.loading]: loading,
            })}
            onClick={onStart}
            loading={loading}
            disabled={blocked}
          >
            <TimerPlayIcon fill="#fff" width={16} height={16} />
          </Button>
        ) : (
          <Button
            skin="info"
            className={classnames(styles.control, {
              [styles.loading]: loading,
            })}
            onClick={onPause}
            loading={loading}
            disabled={blocked}
          >
            <TimerPauseIcon fill="#fff" width={16} height={16} />
          </Button>
        )}
        {showDoneButton && (
          <Button
            skin="primary"
            className={styles.control}
            onClick={onDone}
            disabled={blocked}
          >
            <TimerSaveIcon fill="#fff" width={16} height={16} />
          </Button>
        )}
      </div>
    </div>
  );
}
