import { sortByDateField } from '@appclose/lib';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLazyQuery, useSubscription } from '@apollo/client';
import {
  Checkbox,
  Flex,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@appclose/ui';
import {
  Amount,
  FormArrayField,
  MediaQueryDesktop,
  MediaQueryMobile,
  OverlayLoader,
  useIsMobileDevice,
  Fieldset,
  Ellipsis,
} from '@appclose/core';
import { ExpenseIcon } from '@appclose/icons';

import { ExpenseStatuses, OrderTypes } from '__generated__/globalTypes';
import Date from 'components/common/Date';
import ExpenseStatus from 'components/common/ExpenseStatus';
import Person from 'components/common/Person';
import { getContactName } from 'controllers/contact';
import MobileTable from 'components/common/MobileTable/MobileTable';
import { I18n, useIntl } from 'i18n';

import ExpensesFieldTableHeader from './components/ExpensesFieldTableHeader';
import {
  FETCH_EXPENSES,
  ON_EXPENSE_CREATE,
  ON_EXPENSE_DELETE,
  ON_EXPENSE_UPDATE,
} from './ExpensesField.gql';
import {
  ExpenseCreatedSubscription,
  ExpenseCreatedSubscriptionVariables,
  ExpensesFieldFragment,
  ExpenseRemovedSubscription,
  ExpenseRemovedSubscriptionVariables,
  ExpenseUpdatedSubscription,
  ExpenseUpdatedSubscriptionVariables,
  FetchExpensesQuery,
  FetchExpensesQueryVariables,
} from './__generated__/ExpensesField.gql';
import { ExpensesFieldPropsType } from './ExpensesField.types';
import styles from './ExpensesField.module.scss';

export default function ExpensesField({
  mattersIds,
  defaultValues = [],
  values = [],
}: ExpensesFieldPropsType) {
  const { t } = useIntl();
  const isMobile = useIsMobileDevice();

  const [fetchExpenses, { loading, data }] = useLazyQuery<
    FetchExpensesQuery,
    FetchExpensesQueryVariables
  >(FETCH_EXPENSES, { fetchPolicy: 'network-only' });

  const expensesItems = data?.expenses?.items;
  const expenses = useMemo(() => {
    if (!mattersIds || !expensesItems) {
      return [];
    }

    const expenses: ExpensesFieldFragment[] = [
      ...(defaultValues || []),
      ...expensesItems,
    ];

    return sortByDateField(expenses, {
      fieldName: 'date',
      order: 'DESC',
    });
  }, [defaultValues, expensesItems, mattersIds]);

  const count = (mattersIds && expenses.length) || '';
  const selected = values || defaultValues || [];
  const total = mattersIds
    ? selected.reduce((sum, { amount }) => sum + amount, 0)
    : 0;
  const hasExpenses = !!expenses.length;
  const filter = useMemo(
    () => ({
      mattersIds,
      statuses: [ExpenseStatuses.BILLABLE],
    }),
    [mattersIds]
  );
  const expenseIds = expenses.map(({ id }) => id);

  const refetchExpenses = useCallback(() => {
    if (mattersIds) {
      fetchExpenses({
        variables: {
          input: {
            filter,
            order: { date: OrderTypes.DESC },
            take: null,
          },
        },
      });
    }
  }, [fetchExpenses, filter, mattersIds]);

  useEffect(() => {
    refetchExpenses();
  }, [refetchExpenses]);

  useSubscription<
    ExpenseCreatedSubscription,
    ExpenseCreatedSubscriptionVariables
  >(ON_EXPENSE_CREATE, {
    variables: {
      expenseSubscriptionInput: filter,
    },
    onSubscriptionData: () => refetchExpenses(),
  });

  useSubscription<
    ExpenseRemovedSubscription,
    ExpenseRemovedSubscriptionVariables
  >(ON_EXPENSE_DELETE, {
    variables: {
      expenseIds,
    },
    onSubscriptionData: () => refetchExpenses(),
  });

  useSubscription<
    ExpenseUpdatedSubscription,
    ExpenseUpdatedSubscriptionVariables
  >(ON_EXPENSE_UPDATE, {
    variables: {
      expenseIds,
    },
  });

  const TableComponent = isMobile ? MobileTable : Table;

  return (
    <FormArrayField name="expenses">
      {({ push, remove, values }) => (
        <Fieldset title={t('modal.invoice.form.expenses.title', { count })}>
          {hasExpenses || loading ? (
            <OverlayLoader loading={loading} className={styles.content}>
              {hasExpenses && (
                <TableComponent>
                  <ExpensesFieldTableHeader expenses={expenses} />
                  <TableBody>
                    <>
                      {expenses.map((expense) => {
                        const {
                          id,
                          date,
                          status,
                          amount,
                          billedBy,
                          category,
                          matter,
                        } = expense;

                        return (
                          <TableRow
                            key={id}
                            onClick={() => {
                              if (
                                !values?.some(
                                  ({ id: valueId }) => id === valueId
                                )
                              ) {
                                push(expense);
                              } else {
                                const index = selected.findIndex(
                                  (item) => item.id === id
                                );

                                if (index !== -1) {
                                  remove(index);
                                }
                              }
                            }}
                          >
                            <TableCell width={`${isMobile ? 30 : 50}px`}>
                              <Checkbox
                                readOnly
                                checked={
                                  !!selected.find((item) => item.id === id)
                                }
                              />
                            </TableCell>
                            <MediaQueryMobile>
                              <TableCell
                                noWordBreak
                                maxWidth="0"
                                width="30%"
                                theme="light"
                              >
                                <Ellipsis className={styles.activity}>
                                  {category.name}
                                </Ellipsis>
                                <Date value={date} />
                              </TableCell>
                              <TableCell>
                                <p>{getContactName(billedBy)}</p>
                              </TableCell>
                              <TableCell theme="strong" align="right">
                                <p>
                                  <Amount value={amount} />
                                </p>
                                <ExpenseStatus status={status} />
                              </TableCell>
                            </MediaQueryMobile>
                            <MediaQueryDesktop>
                              <TableCell width="50px">
                                <ExpenseIcon />
                              </TableCell>
                              <TableCell maxWidth="0" width="25%" theme="light">
                                <Ellipsis className={styles.activity}>
                                  {category.name}
                                </Ellipsis>
                                <Date value={date} />
                              </TableCell>
                              <TableCell maxWidth="0" width="20%">
                                <Ellipsis className={styles.activity}>
                                  {matter.name}
                                </Ellipsis>
                              </TableCell>
                              <TableCell noWordBreak>
                                {billedBy && (
                                  <Person
                                    firstName={billedBy.firstName}
                                    lastName={billedBy.lastName}
                                    icon={billedBy.picture}
                                  />
                                )}
                              </TableCell>
                              <TableCell>
                                <ExpenseStatus status={status} />
                              </TableCell>
                              <TableCell
                                noWordBreak
                                theme="strong"
                                align="right"
                              >
                                <Amount value={amount} />
                              </TableCell>
                            </MediaQueryDesktop>
                          </TableRow>
                        );
                      })}
                    </>
                  </TableBody>
                </TableComponent>
              )}
            </OverlayLoader>
          ) : (
            <p className={styles.emptyResult}>
              <I18n id="modal.invoice.form.expenses.empty" />
            </p>
          )}
          {hasExpenses && (
            <Flex
              alignItems="center"
              justify="space-between"
              className={styles.total}
            >
              <p className={styles.totalTitle}>
                <I18n
                  id="modal.invoice.form.expenses.total"
                  values={{ total: selected.length || 0 }}
                />
              </p>
              <Amount value={total} className={styles.totalAmount} />
            </Flex>
          )}
        </Fieldset>
      )}
    </FormArrayField>
  );
}
