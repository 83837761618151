import React, { useMemo } from 'react';
import { Amount, FormGroup, StaticField, Fieldset } from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import Address from 'components/common/Address';
import TrustTransactionStatus from 'components/common/TrustTransactionStatus';
import ContactMatter from 'components/common/ContactMatter';
import Date from 'components/common/Date';
import usePaymentMethodsTypes from 'hooks/usePaymentMethodsTypes';
import { useIntl, I18n } from 'i18n';

import AssociatedPayment from 'components/common/AssociatedPayment';
import EntityQboSyncStatus from 'components/common/EntityQboSyncStatus';
import { AutomaticDepositDetailsPropsType } from './AutomaticDepositDetails.types';
import styles from './AutomaticDepositDetails.module.scss';

export default function AutomaticDepositDetails({
  deposit,
  firm,
}: AutomaticDepositDetailsPropsType) {
  const { getPaymentMethodsTypeLabel } = usePaymentMethodsTypes();
  const { t } = useIntl();
  const contact = deposit.systemTransaction?.contact;
  const invoices = deposit.systemTransaction?.invoices;
  const matters = useMemo(
    () => ({
      name: (invoices || [])
        .map(({ matters }) =>
          (matters || []).map(({ name }) => name).join(', ')
        )
        .join(', '),
    }),
    [invoices]
  );

  return (
    <Fieldset title={t('modal.depositDetails.auto.fieldset.details.title')}>
      <FormGroup>
        <FormGrid>
          <StaticField
            title={t('modal.depositDetails.auto.fieldset.details.from')}
          >
            {contact?.name}
            {contact?.addresses && <Address addresses={contact.addresses} />}
          </StaticField>
          <StaticField
            title={t('modal.depositDetails.auto.fieldset.details.to')}
          >
            {firm.name}
            {firm.address && <Address addresses={[firm.address]} />}
          </StaticField>
        </FormGrid>
      </FormGroup>
      <FormGroup>
        <FormGrid adaptive={false}>
          <StaticField
            title={t('modal.depositDetails.auto.fieldset.details.amount')}
          >
            <Amount value={deposit.amount} />
          </StaticField>
          <StaticField
            title={t('modal.depositDetails.auto.fieldset.details.status')}
          >
            <TrustTransactionStatus status={deposit.status} />
          </StaticField>
        </FormGrid>
      </FormGroup>
      <FormGroup>
        <FormGrid>
          <StaticField
            title={t('modal.depositDetails.auto.fieldset.details.ledger')}
          >
            {contact && <ContactMatter contact={contact} matter={matters} />}
          </StaticField>
        </FormGrid>
      </FormGroup>
      <FormGroup>
        <FormGrid>
          <StaticField
            title={t(
              'modal.depositDetails.auto.fieldset.details.paymentMethod'
            )}
          >
            {getPaymentMethodsTypeLabel(
              deposit.systemTransaction?.paymentMethod
            )}
          </StaticField>
          <StaticField
            title={t('modal.depositDetails.auto.fieldset.details.date')}
          >
            <Date value={deposit.date} />
          </StaticField>
        </FormGrid>
      </FormGroup>
      {invoices && (
        <FormGroup>
          <StaticField
            title={t('modal.depositDetails.auto.fieldset.details.purpose')}
          >
            <I18n
              id="modal.depositDetails.auto.fieldset.details.purposeText"
              values={{
                invoiceNumbers: () =>
                  invoices
                    ?.map(({ invoiceNumber }) => ` #${invoiceNumber}`)
                    .join(','),
              }}
            />
          </StaticField>
        </FormGroup>
      )}
      <FormGroup>
        <StaticField title={t('modal.depositDetails.qboSyncStatus')}>
          <EntityQboSyncStatus
            entityId={deposit?.id}
            qboSyncState={deposit?.qboSyncState || undefined}
            entityType="deposit"
          />
        </StaticField>
      </FormGroup>
      <StaticField
        className={styles.associatedPayment}
        title={t(
          'modal.depositDetails.auto.fieldset.details.associatedPayment'
        )}
      >
        <AssociatedPayment payment={deposit.systemTransaction} />
      </StaticField>
    </Fieldset>
  );
}
