import React from 'react';
import { Hint } from '@appclose/core';

import { ReactComponent as QboIcon } from 'assets/quickbooks.svg';
import { ReactComponent as EsqIcon } from 'assets/esq.svg';

import { SystemLabelPropsType } from './SystemLabel.types';
import styles from './SystemLabel.module.scss';

export default function SystemLabel({
  text,
  tooltip,
  type,
}: SystemLabelPropsType) {
  return (
    <div className={styles.label}>
      {type === 'esq' ? (
        <EsqIcon width={20} height={20} />
      ) : (
        <QboIcon width={20} height={20} />
      )}
      <div className={styles.textContainer}>
        <span>{text}</span>
        {tooltip && <Hint content={tooltip} className={styles.tooltip} />}
      </div>
    </div>
  );
}
