import { gql } from '@apollo/client';

import { LEDGER_NAME_FRAGMENT } from 'components/common/LedgerName';

export const FETCH_PRINTABLE_DEPOSITS = gql`
  query FetchPrintableDeposits {
    deposits: listPrintableDeposits {
      id
      date
      ledger {
        ...LedgerName
      }
      source {
        ...LedgerName
      }
      paymentMethod
      amount
    }
  }
  ${LEDGER_NAME_FRAGMENT}
`;
