import * as Yup from 'yup';

import { getI18nErrorMessage } from 'i18n';
import { SelectTeamMemberType } from 'components/fields/TeamMemberSelectFormField';
import {
  MATTER_BILLING_TYPE_SCHEMA,
  MATTER_DATE_OPENED_SCHEMA,
  MATTER_NAME_SCHEMA,
} from 'schemas/validations/matter';

import { MatterPopupFormValuesType } from './MatterPopupForm.types';

export const MatterPopupFormSchema = Yup.object().shape<MatterPopupFormValuesType>(
  {
    name: MATTER_NAME_SCHEMA,
    dateOpened: MATTER_DATE_OPENED_SCHEMA,
    matterNumber: Yup.string().nullable(),
    statuteOfLimitations: Yup.mixed<string>(),
    notes: Yup.string().nullable(),
    leadAttorney: Yup.mixed<SelectTeamMemberType>().required(
      getI18nErrorMessage('modal.popup.matter.form.error.leadAttorney.required')
    ),
    ...MATTER_BILLING_TYPE_SCHEMA.fields,
  }
);
