import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import { Grid } from '@appclose/ui';
import {
  dateManager,
  Field,
  field,
  getFieldError,
  MaskedInput,
} from '@appclose/core';

import { getTimerDuration, normalizeTime } from 'controllers/timer';

import {
  DurationFormFieldType,
  DurationType,
  TimeType,
} from './DurationFormField.types';
import styles from './DurationFormField.module.scss';

const Duration = ({
  field: { name, value },
  form,
  onChange,
  ref,
  hasError,
  type = TimeType.HOURS,
}: DurationType) => {
  const [focusedField, setFocusedField] = useState<TimeType>();
  const [editingValue, setEditingValue] = useState('');
  const { hours, minutes, seconds } = useMemo(() => getTimerDuration(value), [
    value,
  ]);

  const updateEditingValue = useCallback(
    (value: number, timeType: TimeType, saveZero = true) => {
      const { hours, minutes, seconds } = getTimerDuration(value);

      switch (timeType) {
        case TimeType.HOURS:
          setEditingValue(hours || saveZero ? hours.toString() : '');
          break;
        case TimeType.MINUTES:
          setEditingValue(minutes || saveZero ? minutes.toString() : '');
          break;
        case TimeType.SECONDS:
          setEditingValue(seconds || saveZero ? seconds.toString() : '');
          break;
      }
    },
    []
  );

  const handleOnChange = (timeType: TimeType) => (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const { hours, minutes, seconds } = getTimerDuration(value);

    const secondsInHour = dateManager().getSeconds('hour');
    const secondsInMinute = dateManager().getSeconds('minute');

    let x = parseInt(e.target.value, 10) || 0;
    let duration;

    switch (timeType) {
      case TimeType.HOURS:
        duration = x * secondsInHour + minutes * secondsInMinute + seconds;
        break;
      case TimeType.MINUTES:
        duration = hours * secondsInHour + x * secondsInMinute + seconds;
        break;
      case TimeType.SECONDS:
        duration = hours * secondsInHour + minutes * secondsInMinute + x;
        break;
    }

    updateEditingValue(duration, timeType);

    form.setFieldValue(name, duration, true);

    if (onChange) {
      onChange(duration);
    }
  };

  const handleOnFocus = useCallback(
    (timeType: TimeType) => () => {
      setFocusedField(timeType);
      updateEditingValue(value, timeType, false);
    },
    [updateEditingValue, value]
  );

  const handleOnBlur = useCallback(() => setFocusedField(undefined), [
    setFocusedField,
  ]);

  const showSeconds = type === TimeType.SECONDS;
  const showMinutes = type === TimeType.HOURS || type === TimeType.MINUTES;

  return (
    <Grid
      columns={`60px 10px 60px${showSeconds ? ' 10px 60px' : ''}`}
      align="center"
      ref={ref}
    >
      <MaskedInput
        mask="99"
        placeholder="00"
        value={
          focusedField === TimeType.HOURS ? editingValue : normalizeTime(hours)
        }
        hasError={hasError}
        className={styles.field}
        onChange={handleOnChange(TimeType.HOURS)}
        onFocus={handleOnFocus(TimeType.HOURS)}
        onBlur={handleOnBlur}
      />
      {showMinutes && (
        <>
          <span className={styles.separator}>:</span>
          <MaskedInput
            mask="59"
            formatChars={{
              '5': '[0-5]',
              '9': '[0-9]',
            }}
            placeholder="00"
            value={
              focusedField === TimeType.MINUTES
                ? editingValue
                : normalizeTime(minutes)
            }
            hasError={hasError}
            className={styles.field}
            onChange={handleOnChange(TimeType.MINUTES)}
            onFocus={handleOnFocus(TimeType.MINUTES)}
            onBlur={handleOnBlur}
          />
          {showSeconds && (
            <>
              <span className={styles.separator}>:</span>
              <MaskedInput
                mask="59"
                formatChars={{
                  '5': '[0-5]',
                  '9': '[0-9]',
                }}
                placeholder="00"
                value={
                  focusedField === TimeType.SECONDS
                    ? editingValue
                    : normalizeTime(seconds)
                }
                hasError={hasError}
                className={styles.field}
                onChange={handleOnChange(TimeType.SECONDS)}
                onFocus={handleOnFocus(TimeType.SECONDS)}
                onBlur={handleOnBlur}
              />
            </>
          )}
        </>
      )}
    </Grid>
  );
};

const DurationField = React.forwardRef<
  HTMLDivElement,
  Omit<DurationType, 'name'> & { name: string }
>((props, ref) =>
  field<DurationType>({
    label: 'Duration (hh:mm)',
    ...props,
    showLabel: 'alwaysMinified',
    fieldClassName: classnames(props.fieldClassName, styles.durationField),
    error: getFieldError(props.form, props.field.name),
    ref,
  })(Duration)
);

export default function DurationFormField(props: DurationFormFieldType) {
  return <Field name="duration" {...props} component={DurationField} />;
}
