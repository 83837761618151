import * as Yup from 'yup';

import { MatterTeamMemberRoles } from '__generated__/globalTypes';
import { SelectContactType } from 'components/fields/ContactSelectFormField';
import { getI18nErrorMessage } from 'i18n';
import {
  MATTER_NAME_SCHEMA,
  MATTER_DATE_OPENED_SCHEMA,
  MATTER_BILLING_TYPE_SCHEMA,
} from 'schemas/validations/matter';
import { FILES_SCHEMA } from 'schemas/validations/files';

import { MatterFormValuesType } from './MatterForm.types';
import { FetchMatterQuery } from '../../__generated__/MatterModal.gql';

export const MatterFormSchema = Yup.object().shape<MatterFormValuesType>({
  contact: Yup.mixed<SelectContactType>().required(
    getI18nErrorMessage('modal.matter.form.error.contact.required')
  ),
  name: MATTER_NAME_SCHEMA,
  dateOpened: MATTER_DATE_OPENED_SCHEMA,
  matterNumber: Yup.string().nullable(),
  statuteOfLimitations: Yup.mixed<string>(),
  notes: Yup.string().nullable(),
  teamMembers: Yup.array()
    .of(
      Yup.object()
        .shape<FetchMatterQuery['matter']['teamMembers'][0]>({
          member: Yup.object<
            FetchMatterQuery['matter']['teamMembers'][0]['member']
          >().required(
            getI18nErrorMessage('modal.matter.form.error.member.required')
          ),
          role: Yup.mixed<MatterTeamMemberRoles>().required(
            getI18nErrorMessage('modal.matter.form.error.role.required')
          ),
          hourlyRate: Yup.number().required(
            getI18nErrorMessage('modal.matter.form.error.hourlyRate.required')
          ),
        })
        .required()
    )
    .required(
      getI18nErrorMessage('modal.matter.form.error.teamMembers.required')
    )
    .test(
      'hasLeadAttorney',
      getI18nErrorMessage(
        'modal.matter.form.error.teamMembers.hasLeadAttorney'
      ),
      (teamMembers) => {
        const leadAttorneys =
          teamMembers?.filter(
            (teamMember) =>
              teamMember.role === MatterTeamMemberRoles.LEAD_ATTORNEY
          ) || [];

        return leadAttorneys.length > 0;
      }
    )
    .test(
      'hasOneLeadAttorney',
      getI18nErrorMessage(
        'modal.matter.form.error.teamMembers.hasOneLeadAttorney'
      ),
      (teamMembers) => {
        const leadAttorneys =
          teamMembers?.filter(
            (teamMember) =>
              teamMember.role === MatterTeamMemberRoles.LEAD_ATTORNEY
          ) || [];

        return leadAttorneys.length === 1;
      }
    ),
  files: FILES_SCHEMA,
  ...MATTER_BILLING_TYPE_SCHEMA.fields,
});
