import { gql } from '@apollo/client';

import { AVATAR_FRAGMENT } from 'components/common/Avatar';

export const FLAT_FEES_FIELD_FRAGMENT = gql`
  fragment FlatFeesField on FlatFeeType {
    id
    description
    date
    billable
    status
    amount
    billedBy {
      id
      firstName
      lastName
      picture {
        ...Avatar
      }
    }
    matter {
      id
      name
    }
  }
  ${AVATAR_FRAGMENT}
`;

export const FETCH_FLAT_FEES = gql`
  query FetchFlatFees($input: ListFlatFeesInput!) {
    flatFees: listFlatFees(listFlatFeesInput: $input) {
      total
      items {
        ...FlatFeesField
      }
    }
  }
  ${FLAT_FEES_FIELD_FRAGMENT}
`;

export const ON_FLAT_FEE_CREATE = gql`
  subscription FlatFeeCreated(
    $flatFeeSubscriptionInput: FlatFeeSubscriptionInput!
  ) {
    flatFeeCreated(flatFeeSubscriptionInput: $flatFeeSubscriptionInput) {
      id
    }
  }
`;

export const ON_FLAT_FEE_DELETE = gql`
  subscription FlatFeeRemoved($flatFeeIds: [ID!]!) {
    flatFeeRemoved(
      removedEntitySubscriptionInput: { includeIds: $flatFeeIds }
    ) {
      id
    }
  }
`;

export const ON_FLAT_FEE_UPDATE = gql`
  subscription FlatFeeUpdated($flatFeeIds: [ID!]!) {
    flatFeeUpdated(flatFeeSubscriptionInput: { includeIds: $flatFeeIds }) {
      ...FlatFeesField
    }
  }
  ${FLAT_FEES_FIELD_FRAGMENT}
`;
