import * as Yup from 'yup';
import { getI18nErrorMessage } from 'i18n';

import {
  BankAccountClasses,
  PaymentMethodsTypes,
} from '__generated__/globalTypes';

import { SelectContactType } from 'components/fields/ContactSelectFormField';
import { isFreePlan, isPlusPlan } from 'controllers/mode';
import { SelectMatterType } from 'components/fields/MatterSelectFormField';
import {
  ContactMatterSelectContactType,
  ContactMatterSelectMatterType,
} from 'components/fields/ContactMatterSelectFormField';

import { ReceivePaymentFormValuesType } from './PaymentInfoFormsBlock.types';
import { InvoicesType } from './components//CommonFormContent/components/InvoicesField';

export const ReceivePaymentFormSchema = Yup.object().shape<
  Omit<
    ReceivePaymentFormValuesType,
    'destinationAccount' | 'paymentDate'
  > & { paymentDate?: string | null }
>({
  contact: Yup.mixed<SelectContactType | ContactMatterSelectContactType>()
    .when(['destinationAccount', 'paymentMethod'], {
      is: (destinationAccount, paymentMethod) =>
        paymentMethod !== PaymentMethodsTypes.TRANSFER_FROM_TRUST &&
        (destinationAccount === BankAccountClasses.OPERATING || isFreePlan()),
      then: Yup.mixed<SelectContactType>().required('Please select a contact'),
    })
    .when('destinationAccount', {
      is: (destinationAccount) =>
        isPlusPlan() && destinationAccount === BankAccountClasses.TRUST,
      then: Yup.mixed<ContactMatterSelectContactType>().required(
        'Please select a contact / matter'
      ),
    }),
  matter: Yup.mixed<SelectMatterType | ContactMatterSelectMatterType>(),
  invoices: Yup.array<InvoicesType[0]>()
    .min(1, 'Please select an invoice')
    .required('Please select an invoice'),
  amount: Yup.number().when(
    ['invoices', 'destinationAccount', 'paymentMethod', 'contact', 'matter'],
    (
      invoices: InvoicesType,
      destinationAccount: BankAccountClasses,
      paymentMethod: PaymentMethodsTypes,
      contact: SelectContactType | ContactMatterSelectContactType,
      matter: SelectMatterType | ContactMatterSelectMatterType
    ) => {
      const isFromTrustToOperating =
        destinationAccount === BankAccountClasses.OPERATING &&
        paymentMethod === PaymentMethodsTypes.TRANSFER_FROM_TRUST;
      const contactTrustBalance = (contact as any)?.trustBalance;
      const matterTrustBalance = (matter as any)?.trustBalance;
      const hasContactTrustBalance = Number.isFinite(contactTrustBalance);
      const hasMatterTrustBalance = Number.isFinite(matterTrustBalance);
      const totalDue = Boolean(invoices && invoices.length)
        ? invoices.reduce((sum, { balanceDue }) => sum + balanceDue, 0)
        : 0;

      const Schema = Yup.number()
        .required('Please enter amount to receive')
        .moreThan(0, 'The amount to receive should be more than 0')
        .max(
          totalDue,
          'The amount to receive should not exceed the total value of the invoices'
        );

      if (isFromTrustToOperating && hasMatterTrustBalance) {
        return totalDue <= matterTrustBalance
          ? Schema
          : Schema.max(
              matterTrustBalance,
              'The amount to receive should not exceed the matter trust account balance'
            );
      }

      if (isFromTrustToOperating && hasContactTrustBalance) {
        return totalDue <= contactTrustBalance
          ? Schema
          : Schema.max(
              contactTrustBalance,
              'The amount to receive should not exceed the contact trust account balance'
            );
      }

      return Schema;
    }
  ),
  paymentMethod: Yup.mixed<PaymentMethodsTypes>().required(
    'Please select payment method'
  ),
  paymentDate: Yup.string()
    .nullable()
    .when(
      'paymentMethod',
      (
        paymentMethod: PaymentMethodsTypes | undefined,
        schema: Yup.StringSchema
      ) => {
        if (!paymentMethod) {
          return schema;
        }

        if (
          [PaymentMethodsTypes.ACH, PaymentMethodsTypes.CC].includes(
            paymentMethod
          )
        ) {
          return schema;
        }

        return schema.required(
          getI18nErrorMessage(
            'modal.receivePayment.form.paymentDate.error.required'
          )
        );
      }
    ),
});
