import React, { useEffect, useMemo } from 'react';
import set from 'lodash/set';
import classnames from 'classnames';
import { useQuery } from '@apollo/client';
import {
  Checkbox,
  Flex,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@appclose/ui';
import {
  Amount,
  FieldError,
  FormArrayField,
  MediaQueryDesktop,
  MediaQueryMobile,
  OverlayLoader,
  useFormContext,
  useIsMobileDevice,
  Fieldset,
} from '@appclose/core';

import { I18n, useIntl } from 'i18n';
import Date from 'components/common/Date';
import MobileTable from 'components/common/MobileTable';
import LedgerName from 'components/common/LedgerName';
import PrivacyValue from 'components/common/PrivacyValue';
import TrustTransactionType from 'components/common/TrustTransactionType';

import NotPrintedDisbursementsFieldTableHeader from './components/NotPrintedDisbursementsFieldTableHeader';
import { PrintCheckFormValuesType } from '../../PrintChecksForm.types';
import { FETCH_NOT_PRINTED_DISBURSEMENTS } from './NotPrintedDisbursementsField.gql';
import {
  FetchNotPrintedDisbursementsQuery,
  FetchNotPrintedDisbursementsQueryVariables,
} from './__generated__/NotPrintedDisbursementsField.gql';
import { NotPrintedDisbursementsFieldPropsType } from './NotPrintedDisbursementsField.types';
import styles from './NotPrintedDisbursementsField.module.scss';

export default function NotPrintedDisbursementsField({
  name = 'selectedChecks',
  isQboMode,
}: NotPrintedDisbursementsFieldPropsType) {
  const { t } = useIntl();
  const isMobile = useIsMobileDevice();
  const { loading, data } = useQuery<
    FetchNotPrintedDisbursementsQuery,
    FetchNotPrintedDisbursementsQueryVariables
  >(FETCH_NOT_PRINTED_DISBURSEMENTS, {
    fetchPolicy: 'network-only',
  });

  const notPrintedDisbursements = useMemo(
    () => data?.notPrintedDisbursements?.items || [],
    [data?.notPrintedDisbursements?.items]
  );

  const form = useFormContext<PrintCheckFormValuesType>();
  const {
    values: { selectedChecks },
    setValues,
  } = form;

  const total = notPrintedDisbursements.reduce((sum, { id, amount }) => {
    if (selectedChecks.some((check) => check === id)) {
      sum = sum + amount;
    }

    return sum;
  }, 0);
  const hasNotPrintedDisbursements = !!notPrintedDisbursements.length;

  useEffect(() => {
    if (
      notPrintedDisbursements.length === 1 &&
      !selectedChecks.find((id) => id === notPrintedDisbursements[0].id)
    ) {
      setValues(
        (values) =>
          set(
            values,
            name,
            notPrintedDisbursements.map(({ id }) => id)
          ),
        true
      );
    }
  }, [name, notPrintedDisbursements, selectedChecks, setValues]);

  const TableComponent = isMobile ? MobileTable : Table;

  return (
    <FormArrayField name={name}>
      {({ remove, values, insert }) => (
        <Fieldset title={t('modal.printChecks.form.checks.title')}>
          {hasNotPrintedDisbursements || loading ? (
            <OverlayLoader
              loading={loading}
              className={classnames(styles.content, {
                [styles.contentQbo]: isQboMode,
              })}
            >
              {hasNotPrintedDisbursements && (
                <TableComponent className={styles.table}>
                  <NotPrintedDisbursementsFieldTableHeader
                    notPrintedDisbursements={notPrintedDisbursements}
                  />
                  <TableBody>
                    <>
                      {notPrintedDisbursements.map(
                        ({ id, date, amount, payee, ledger, type }) => (
                          <TableRow
                            key={id}
                            onClick={() => {
                              if (!values?.some((value) => id === value)) {
                                insert(values.length, id);
                              } else {
                                const index = values.findIndex(
                                  (value) => value === id
                                );

                                if (index !== -1) {
                                  remove(index);
                                }
                              }
                            }}
                          >
                            <TableCell width={`${isMobile ? 30 : 50}px`}>
                              <Checkbox
                                readOnly
                                checked={!!values.find((value) => value === id)}
                              />
                            </TableCell>

                            <MediaQueryMobile>
                              <TableCell>
                                <div>
                                  <p>
                                    <TrustTransactionType type={type} />
                                  </p>
                                  <PrivacyValue>
                                    <LedgerName value={ledger} />
                                  </PrivacyValue>
                                </div>
                              </TableCell>
                              <TableCell theme="light">
                                <Date value={date} />
                              </TableCell>
                              <TableCell>
                                <p>
                                  <PrivacyValue>
                                    <Amount value={amount} />
                                  </PrivacyValue>
                                </p>
                              </TableCell>
                            </MediaQueryMobile>

                            <MediaQueryDesktop>
                              <TableCell noWordBreak>
                                <TrustTransactionType type={type} />
                              </TableCell>
                              <TableCell noWordBreak theme="light">
                                <Date value={date} />
                              </TableCell>
                              <TableCell>
                                <PrivacyValue>
                                  <LedgerName value={ledger} />
                                </PrivacyValue>
                              </TableCell>
                              <TableCell>
                                <PrivacyValue>
                                  {payee && <LedgerName value={payee} />}
                                </PrivacyValue>
                              </TableCell>
                              <TableCell
                                noWordBreak
                                theme="strong"
                                align="right"
                              >
                                <PrivacyValue>
                                  <Amount value={amount} />
                                </PrivacyValue>
                              </TableCell>
                            </MediaQueryDesktop>
                          </TableRow>
                        )
                      )}
                    </>
                  </TableBody>
                </TableComponent>
              )}
            </OverlayLoader>
          ) : (
            <p className={styles.emptyResult}>
              <I18n id="modal.printChecks.form.checks.empty" />
            </p>
          )}
          {hasNotPrintedDisbursements && (
            <Flex
              alignItems="center"
              justify="space-between"
              className={styles.total}
            >
              <p className={styles.totalTitle}>
                <I18n
                  id="modal.printChecks.form.checks.total"
                  values={{ total: values.length || 0 }}
                />
              </p>
              <Amount value={total} className={styles.totalAmount} />
            </Flex>
          )}
          <FieldError name={name} />
        </Fieldset>
      )}
    </FormArrayField>
  );
}
