import React from 'react';
import { Button, ButtonGroup } from '@appclose/ui';
import {
  dateManager,
  DatePickerFormField,
  Form,
  FormGroup,
  InputFormField,
  UpperFirstInputFormField,
  PhoneNumberFormField,
  TextAreaFormField,
  USAStateSelectFormField,
  USAZipCodeFormField,
  MediaQueryDesktop,
  MediaQueryMobile,
  Fieldset,
} from '@appclose/core';

import AvatarAttach from 'components/common/AvatarAttach/AvatarAttach';
import FormGrid from 'components/common/FormGrid/FormGrid';
import OwnershipTypeSelectFormField from 'components/fields/OwnershipTypeSelectFormField';
import AttorneysQtySelectFormField from 'components/fields/AttorneysQtySelectFormField';
import { SERVER_DATE_FORMAT } from 'constants/date';
import { useIntl, I18n } from 'i18n';

import { FirmFormPropsType } from './FirmForm.types';
import { FirmFormSchema } from './FirmForm.schema';
import styles from './FirmForm.module.scss';

export default function FirmForm({
  initialValues,
  onSubmit,
  onClose,
  onChange,
}: FirmFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      validationSchema={FirmFormSchema}
      onSubmit={onSubmit}
      onChange={onChange}
    >
      {({ values: { firmLogo, logo }, setFieldValue, isSubmitting }) => (
        <>
          <Fieldset
            title={t('modal.firm.form.fieldset.info.title')}
            className={styles.businessInfo}
          >
            <FormGroup>
              <FormGrid>
                <div>
                  <FormGroup>
                    <UpperFirstInputFormField
                      name="name"
                      label={t('modal.firm.form.fieldset.info.name')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <UpperFirstInputFormField
                      name="legalName"
                      label={t('modal.firm.form.fieldset.info.legalName')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <OwnershipTypeSelectFormField name="entityType" />
                  </FormGroup>
                </div>
                <AvatarAttach
                  type="logo"
                  avatarFile={firmLogo}
                  avatar={logo}
                  onChange={(file, logo) => {
                    setFieldValue('firmLogo', file);
                    setFieldValue('logo', logo);
                  }}
                  placeholder="Logo"
                />
              </FormGrid>
            </FormGroup>
            <FormGroup>
              <FormGrid>
                <DatePickerFormField
                  name="businessStartedSince"
                  label={t(
                    'modal.firm.form.fieldset.info.businessStartedSince'
                  )}
                  outputDateFormat={SERVER_DATE_FORMAT}
                  maxDate={dateManager()
                    .parse()
                    .endOf('day')
                    .subtract(1, 'day')
                    .valueOf()}
                />
                <PhoneNumberFormField name="phoneNumber.number" />
              </FormGrid>
            </FormGroup>
            <FormGroup>
              <FormGrid>
                <InputFormField
                  name="website"
                  label={t('modal.firm.form.fieldset.info.website')}
                />
                <AttorneysQtySelectFormField name="size" />
              </FormGrid>
            </FormGroup>
            <FormGroup>
              <TextAreaFormField
                name="description"
                label={t('modal.firm.form.fieldset.info.description')}
              />
            </FormGroup>
          </Fieldset>
          <Fieldset title={t('modal.firm.form.fieldset.address.title')}>
            <FormGroup>
              <MediaQueryDesktop>
                <FormGrid>
                  <UpperFirstInputFormField
                    name={'address.address1'}
                    label={t('modal.firm.form.fieldset.address.address1')}
                  />
                  <USAStateSelectFormField
                    showPlaceholder="onFocus"
                    name={'address.state'}
                  />
                  <UpperFirstInputFormField
                    name={'address.address2'}
                    label={t('modal.firm.form.fieldset.address.address2')}
                  />
                  <USAZipCodeFormField name={'address.zipCode'} />
                  <InputFormField
                    name={'address.city'}
                    label={t('modal.firm.form.fieldset.address.city')}
                  />
                </FormGrid>
              </MediaQueryDesktop>
              <MediaQueryMobile>
                <FormGrid>
                  <UpperFirstInputFormField
                    name={'address.address1'}
                    label={t('modal.firm.form.fieldset.address.address1')}
                  />
                  <InputFormField
                    name={'address.address2'}
                    label={t('modal.firm.form.fieldset.address.address2')}
                  />
                  <InputFormField
                    name={'address.city'}
                    label={t('modal.firm.form.fieldset.address.city')}
                  />
                  <USAStateSelectFormField
                    showPlaceholder="onFocus"
                    name={'address.state'}
                  />
                  <USAZipCodeFormField name={'address.zipCode'} />
                </FormGrid>
              </MediaQueryMobile>
            </FormGroup>
          </Fieldset>
          <ButtonGroup>
            <Button onClick={onClose} disabled={isSubmitting}>
              <I18n id="modal.firm.form.cancel" />
            </Button>
            <Button type="submit" skin="primary" loading={isSubmitting}>
              <I18n id="modal.firm.form.submit" />
            </Button>
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}
