import * as Yup from 'yup';
import { PHONE_NUMBER_VALIDATION_SCHEMA } from '@appclose/core';

import { FirmSizes, PhoneNumberType } from '__generated__/globalTypes';
import {
  FIRM_BUSINESS_START_DATE_VALIDATION_SCHEMA,
  FIRM_DESCRIPTION_VALIDATION_SCHEMA,
  FIRM_LEGAL_NAME_VALIDATION_SCHEMA,
  FIRM_OWNERSHIP_TYPE_VALIDATION_SCHEMA,
  FIRM_WEBSITE_VALIDATION_SCHEMA,
} from 'schemas/validations/firm';
import { ADDRESS_INPUT_SCHEMA } from 'schemas/validations/address';
import { getI18nErrorMessage } from 'i18n';

import { FirmFormValuesType } from './FirmForm.types';

export const FirmFormSchema = Yup.object<FirmFormValuesType>({
  name: Yup.string().required(
    getI18nErrorMessage('modal.firm.form.error.name.required')
  ),
  legalName: FIRM_LEGAL_NAME_VALIDATION_SCHEMA.nullable(),
  entityType: FIRM_OWNERSHIP_TYPE_VALIDATION_SCHEMA.nullable(),
  phoneNumber: Yup.object<Omit<PhoneNumberType, '__typename'>>({
    number: PHONE_NUMBER_VALIDATION_SCHEMA.required(
      getI18nErrorMessage('modal.firm.form.error.phoneNumber.number.required')
    ),
  }),
  businessStartedSince: FIRM_BUSINESS_START_DATE_VALIDATION_SCHEMA,
  size: Yup.mixed<FirmSizes>().required(
    getI18nErrorMessage('modal.firm.form.error.size.required')
  ),
  website: FIRM_WEBSITE_VALIDATION_SCHEMA.optional().nullable(),
  description: FIRM_DESCRIPTION_VALIDATION_SCHEMA.optional().nullable(),
  address: ADDRESS_INPUT_SCHEMA.nullable(),
});
