import React from 'react';
import {
  permissionProvider,
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { I18n } from 'i18n';

import { DisbursementModalPropsType } from './DisbursementModal.types';
import DisbursementToContact from './components/DisbursementToContact';
import DisbursementToOperating from './components/DisbursementToOperating';
import DisbursementToThirdParty from './components/DisbursementToThirdParty';
import styles from './DisbursementModal.module.scss';

export default function DisbursementModal({
  onClose,
}: DisbursementModalPropsType) {
  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });

  const hasPermissionToOperating = permissionProvider().hasPermission(
    PermissionResources.DISBURSEMENT_OPERATING,
    PermissionActions.CREATE
  );
  const hasPermissionToContact = permissionProvider().hasPermission(
    PermissionResources.DISBURSEMENT_CONTACT,
    PermissionActions.CREATE
  );
  const hasPermissionToContact3rd = permissionProvider().hasPermission(
    PermissionResources.DISBURSEMENT_3RD_PARTY,
    PermissionActions.CREATE
  );

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.disbursement.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        <Tabs>
          <TabList className={styles.tabList}>
            {hasPermissionToOperating && (
              <Tab>
                <I18n id="modal.disbursement.tab.operating" />
              </Tab>
            )}
            {hasPermissionToContact && (
              <Tab>
                <I18n id="modal.disbursement.tab.contact" />
              </Tab>
            )}
            {hasPermissionToContact3rd && (
              <Tab>
                <I18n id="modal.disbursement.tab.thirdParty" />
              </Tab>
            )}
          </TabList>
          {hasPermissionToOperating && (
            <TabPanel>
              <DisbursementToOperating
                onCancel={onConfirmClose}
                onChange={onFormChange}
                onClose={onClose}
              />
            </TabPanel>
          )}
          {hasPermissionToContact && (
            <TabPanel>
              <DisbursementToContact
                onCancel={onConfirmClose}
                onChange={onFormChange}
                onClose={onClose}
              />
            </TabPanel>
          )}
          {hasPermissionToContact3rd && (
            <TabPanel>
              <DisbursementToThirdParty
                onCancel={onConfirmClose}
                onChange={onFormChange}
                onClose={onClose}
              />
            </TabPanel>
          )}
        </Tabs>
      </ModalPageContent>
    </ModalPage>
  );
}
