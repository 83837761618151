import React, { useCallback } from 'react';
import { Grid } from '@appclose/ui';
import { PaymentIcon } from '@appclose/icons';
import { Amount, MediaQueryMobile, MediaQueryDesktop } from '@appclose/core';

import Block from 'components/common/Block';
import Date from 'components/common/Date';
import { PAYMENT_DETAILS_MODAL } from 'constants/modals';
import { openModal } from 'controllers/modal';
import usePaymentMethodsTypes from 'hooks/usePaymentMethodsTypes';
import { I18n } from 'i18n';

import { AssociatedPaymentPropsType } from './AssociatedPayment.types';
import styles from './AssociatedPayment.module.scss';

export default function AssociatedPayment({
  payment,
}: AssociatedPaymentPropsType) {
  const { getPaymentMethodsTypeLabel } = usePaymentMethodsTypes();
  const handleOnClick = useCallback(
    () => openModal(PAYMENT_DETAILS_MODAL, { id: payment?.id }),
    [payment]
  );

  if (!payment) {
    return null;
  }

  const { id, paymentDate, contact, paymentMethod, amount } = payment;

  return (
    <Block key={id} className={styles.block} onClick={handleOnClick}>
      <MediaQueryDesktop>
        <Grid gap={[24, 16]} align="center" columns="24px 70px repeat(4, 1fr)">
          <PaymentIcon />
          <span>
            <I18n id="associatedPayments.payment.title" />
          </span>
          <Date className={styles.paymentDate} value={paymentDate} />
          <span>{contact.name}</span>
          <span>{getPaymentMethodsTypeLabel(paymentMethod)}</span>
          <Amount strong value={amount} />
        </Grid>
      </MediaQueryDesktop>
      <MediaQueryMobile>
        <Grid gap={[20]} align="center" columns="24px repeat(2, 1fr)">
          <PaymentIcon />
          <div>
            <p>
              <span>
                <I18n id="associatedPayments.payment.title" />
              </span>
            </p>
            <Date className={styles.paymentDate} value={paymentDate} />
          </div>
          <div>
            <p>
              <Amount strong value={amount} />
            </p>
            <span>{contact.name}</span>
          </div>
        </Grid>
      </MediaQueryMobile>
    </Block>
  );
}
