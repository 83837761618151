import { gql } from '@apollo/client';

export const FETCH_OUTLOOK_CALENDARS = gql`
  query FetchOutlookCalendars {
    calendars: listOutlookCalendars {
      calendarId
      calendarName
    }
  }
`;

export const SYNC_OUTLOOK_CALENDAR = gql`
  mutation SyncOutlookCalendar($input: CalendarOutlookSynchronizeInput!) {
    synchronizeOutlookCalendar(calendarOutlookSynchronizeInput: $input) {
      outlook {
        calendarName
        active
      }
    }
  }
`;
