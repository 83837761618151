import * as Yup from 'yup';

import { PrintableDepositsType } from './components/PrintableDepositsField';
import { PrintDepositsFormValuesType } from './PrintDepositsForm.types';

const MAC_CHECK_DEPOSITS_ALLOWED = 18;

export const PrintChecksFormSchema = Yup.object().shape<
  PrintDepositsFormValuesType
>({
  deposits: Yup.array<PrintableDepositsType[0]>()
    .required('Please select at least one deposit')
    .test(
      'hasLessThen19CheckDeposits',
      'Please select 18 or fewer deposits',
      (deposits) => (deposits?.length || 0) <= MAC_CHECK_DEPOSITS_ALLOWED
    ),
  date: Yup.string().nullable().required('Please select a date'),
});
