import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import omit from 'lodash/omit';
import { Loader, Modal, dateManager } from '@appclose/core';

import { MatterTeamMemberRoles, MatterTypes } from '__generated__/globalTypes';

import notification from 'controllers/notification';
import { track } from 'controllers/analytics';
import {
  INITIAL_BASE_CONTACT,
  INITIAL_BASE_ENTITY,
  ContactTypes,
} from 'constants/contacts';
import { SERVER_DATE_FORMAT } from 'constants/date';
import { EntryTypes, EventNames, SourceTypes } from 'constants/analytics';
import useProfile from 'hooks/useProfile';
import { useIntl } from 'i18n';

import {
  CreateContactMutation,
  CreateContactMutationVariables,
  CreateMatterMutation,
  CreateMatterMutationVariables,
} from './__generated__/ContactMatterPopupModal.gql';
import ContactMatterPopupForm, {
  ContactMatterPopupFormValuesType,
  MatterFormValuesType,
  ContactFieldsetValuesType,
  EntityFieldsetValuesType,
} from './components/ContactMatterPopupForm';
import { CREATE_CONTACT, CREATE_MATTER } from './ContactMatterPopupModal.gql';
import {
  ContactMatterPopupModalPropsType,
  ContactMatterPopupModalTabs,
} from './ContactMatterPopupModal.types';

export default function ContactMatterPopupModal({
  onCancel,
  onComplete,
}: ContactMatterPopupModalPropsType) {
  const { t } = useIntl();
  const { profile, loading } = useProfile();

  const [createContact] = useMutation<
    CreateContactMutation,
    CreateContactMutationVariables
  >(CREATE_CONTACT);

  const [createMatter] = useMutation<
    CreateMatterMutation,
    CreateMatterMutationVariables
  >(CREATE_MATTER);

  const handleOnCancel = useCallback(() => {
    track(EventNames.CANCEL_ENTRY, {
      entry_type: EntryTypes.CONTACT_MATTER,
      source_type: SourceTypes.POPUP_MODAL,
    });

    onCancel();
  }, [onCancel]);

  const saveMatter = useCallback(
    async (matter: MatterFormValuesType, contactId?: string) => {
      if (!matter) {
        return null;
      }

      const variables = {
        matter: {
          ...omit(matter, 'contact', 'leadAttorney'),
          contactId: contactId,
          teamMembers: [
            {
              userId: profile?.id,
              userRole: MatterTeamMemberRoles.LEAD_ATTORNEY,
              hourlyRate: profile?.hourlyRate || 0,
            },
          ],
        },
      };

      return await createMatter({
        variables: variables as CreateMatterMutationVariables,
      });
    },
    [createMatter, profile]
  );

  const saveContact = useCallback(
    async (contact: {
      contactType: ContactTypes;
      contact: ContactFieldsetValuesType;
      entity: EntityFieldsetValuesType;
    }) => {
      const variables =
        contact.contactType === ContactTypes.ENTITY
          ? {
              input: {
                entity: {
                  ...contact.entity,
                  originatingAttorney: profile?.id,
                },
              },
            }
          : {
              input: {
                contact: {
                  ...contact.contact,
                  originatingAttorney: profile?.id,
                },
              },
            };

      return await createContact({
        variables: variables as CreateContactMutationVariables,
      });
    },
    [createContact, profile]
  );

  const handleOnSubmit = useCallback(
    async ({
      selectedTab,
      contact,
      matter,
    }: ContactMatterPopupFormValuesType) => {
      let saveContactResult = null;
      let saveMatterResult = null;

      if (selectedTab === ContactMatterPopupModalTabs.CONTACT) {
        saveContactResult = await saveContact(contact);

        if (matter) {
          saveMatterResult = await saveMatter(
            matter,
            saveContactResult.data?.createContact.id
          );
        }
      } else if (matter && contact.existingContact) {
        saveMatterResult = await saveMatter(matter, contact.existingContact.id);
      }

      const resultContactData =
        saveContactResult?.data?.createContact ||
        saveMatterResult?.data?.createMatter.contact;

      if (!resultContactData) {
        return;
      }

      onComplete({
        contact: {
          ...resultContactData,
          __typename: 'StrippedContactWithMattersType',
        },
        matter: saveMatterResult?.data?.createMatter && {
          ...saveMatterResult?.data?.createMatter,
          __typename: 'StrippedMatterType',
        },
      });

      notification().entityCreated(
        t('modal.popup.contactMatter.notification.create.success')
      );
    },
    [saveContact, saveMatter, onComplete, t]
  );

  const INITIAL_MATTER = {
    name: '',
    dateOpened: dateManager()
      .parse()
      .startOf('day')
      .format(SERVER_DATE_FORMAT),
    type: MatterTypes.HYBRID,
    agreedFlatFee: 1,
    billable: true,
  };

  let initialValues = {
    selectedTab: ContactMatterPopupModalTabs.CONTACT,
    contact: {
      contactType: ContactTypes.INDIVIDUAL,
      entity: { ...INITIAL_BASE_ENTITY },
      contact: { ...INITIAL_BASE_CONTACT },
      existingContact: null,
    },
    matter: null,
  } as ContactMatterPopupFormValuesType;

  return (
    <Modal
      onClose={handleOnCancel}
      title={t('modal.popup.contactMatter.title')}
    >
      {loading ? (
        <Loader />
      ) : (
        <ContactMatterPopupForm
          initialValues={initialValues}
          defaultMatter={{ ...INITIAL_MATTER }}
          onSubmit={handleOnSubmit}
          onCancel={handleOnCancel}
        />
      )}
    </Modal>
  );
}
