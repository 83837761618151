import { gql } from '@apollo/client';

export const FETCH_PDF_CHECK = gql`
  query FetchPdfCheck($checks: GetPDFCheckInput!) {
    pdf: getPdfCheck(getPDFCheckInput: $checks) {
      url
      base64 {
        data
        mimetype
      }
    }
  }
`;

export const PRINT_CHECKS = gql`
  mutation PrintChecks($input: PrintChecksInput!) {
    printChecks(printChecksInput: $input) {
      success
    }
  }
`;
