import React from 'react';
import { Grid } from '@appclose/ui';

import Block from 'components/common/Block';

import styles from './ActionsPanel.module.scss';
import { ActionsPanelPropsType } from './ActionsPanel.types';
import { useIsMobileDevice } from '@appclose/core';

export default function ActionsPanel({
  title,
  children,
}: ActionsPanelPropsType) {
  const isMobileDevice = useIsMobileDevice();
  const hasNoChildren = !React.Children.toArray(children).length;

  if (hasNoChildren) {
    return null;
  }

  return (
    <Block className={styles.actionsPanel}>
      {title && <h4 className={styles.header}>{title}</h4>}
      <Grid
        gap={!isMobileDevice ? [64] : [24, 24]}
        autoFlow={!isMobileDevice ? 'column' : undefined}
        align="baseline"
        justifyContent="space-evenly"
        columns={
          isMobileDevice ? 'repeat(auto-fit, minmax(52px, 1fr))' : undefined
        }
      >
        {children}
      </Grid>
    </Block>
  );
}
