import React, { useCallback, useEffect, useMemo } from 'react';
import {
  FormGroup,
  PhoneNumberFormField,
  SelectFormField,
  useFormContext,
  useIsDesktopDevice
} from '@appclose/core';
import { Grid, Radio } from '@appclose/ui';
import { FieldLabel } from '@appclose/core/src/components/hoc/field/components/FieldLabel';
import set from 'lodash/set';
import get from 'lodash/get';

import { useIntl } from 'i18n';
import usePhoneTypes from 'hooks/usePhoneTypes';
import FieldsetFormArray from 'components/form/FieldsetFormArray';
import { INITIAL_PHONE, INITIAL_PRIMARY_PHONE } from 'constants/phone';
import { ContactAndEntityFormValuesType } from 'components/modals/pages/ContactModal/components/ContactForm';
import { PhoneNumberInput } from '__generated__/globalTypes';

import { PhoneFieldsetPropsType } from './PhoneFieldset.types';

import styles from './PhoneFieldset.module.scss';

export default function PhoneFieldset({
  name = 'phoneNumbers',
  title,
  allowRemoveLast = false,
  limit = 5,
}: PhoneFieldsetPropsType) {
  const { t } = useIntl();
  const fieldsetTitle = title || t('form.phoneFieldset.defaultTitle');
  const { phoneTypesOptions } = usePhoneTypes();
  const isDesktopDevice = useIsDesktopDevice();
  const { setValues, values } = useFormContext<ContactAndEntityFormValuesType>();
  const phoneNumbers: PhoneNumberInput[] = useMemo(() => get(values, name), [values, name]);

  const setPrimaryByIndex = useCallback((primaryIndex: string) => {
    setValues((values) => {
      return set(values, name, phoneNumbers?.map((phoneNumber, index) => ({
        ...phoneNumber,
        isPrimary: index === Number(primaryIndex),
      })));
    });
  }, [phoneNumbers, name, setValues]);

  useEffect(() => {
    if (!phoneNumbers?.some(({ isPrimary }) => isPrimary)) {
      setPrimaryByIndex('0');
    }
  }, [phoneNumbers, setPrimaryByIndex]);

  const handleRadioChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      setPrimaryByIndex(event.target.value);
  }, [setPrimaryByIndex]);

  return (
    <FieldsetFormArray
      allowRemoveLast={allowRemoveLast}
      title={fieldsetTitle}
      name={name}
      addButtonLabel={t('form.phoneFieldset.addButtonLabel')}
      initialData={phoneNumbers?.length > 0 ? INITIAL_PHONE : INITIAL_PRIMARY_PHONE}
      limit={limit}
    >
      {({ resolveName, index, value }) => (
        <FormGroup>
          <Grid
            gap={isDesktopDevice ? [48, 20] : [0, 20]}
            columns={isDesktopDevice ? '50px repeat(2, minmax(0, 1fr))' : '100%'}
          >
            <FieldLabel title={t('form.phoneFieldset.primaryLabel')} minimize={true}>
              <Radio
                name="primaryNumberIndex"
                value={String(index)}
                checked={value.isPrimary}
                onChange={handleRadioChange}
                className={styles.radioContainer}
              />
            </FieldLabel>
            <SelectFormField
              name={resolveName('type')}
              label={t('form.phoneFieldset.type')}
              options={phoneTypesOptions}
            />
            <PhoneNumberFormField name={resolveName('number')} />
          </Grid>
        </FormGroup>
      )}
    </FieldsetFormArray>
  );
}
