import React, { useEffect, useMemo } from 'react';
import set from 'lodash/set';
import { useQuery } from '@apollo/client';
import {
  Checkbox,
  Flex,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Caption2,
} from '@appclose/ui';
import {
  Amount,
  FormArrayField,
  MediaQueryDesktop,
  MediaQueryMobile,
  OverlayLoader,
  useIsMobileDevice,
  useFormContext,
  FieldError,
} from '@appclose/core';

import { I18n } from 'i18n';
import DateComponent from 'components/common/Date';
import MobileTable from 'components/common/MobileTable';
import TrustTransactionType from 'components/common/TrustTransactionType';
import LedgerName from 'components/common/LedgerName';

import OtosFieldTableHeader from './components/OtosFieldTableHeader';
import {
  FetchOtosQuery,
  FetchOtosQueryVariables,
} from './__generated__/OtosField.gql';
import { FETCH_OTOS } from './OtosField.gql';
import { DisbursementToOperatingFormValuesType } from '../../DisbursementToOperatingForm.types';
import { OtosFieldPropsType } from './OtosField.types';
import styles from './OtosField.module.scss';

export default function OtosField({
  name = 'otos',
  defaultValues = [],
  values = [],
}: OtosFieldPropsType) {
  const isMobile = useIsMobileDevice();
  const { setValues } = useFormContext<DisbursementToOperatingFormValuesType>();
  const { loading, data } = useQuery<FetchOtosQuery, FetchOtosQueryVariables>(
    FETCH_OTOS,
    { fetchPolicy: 'network-only' }
  );

  const otos = useMemo(() => data?.otos.items || [], [data?.otos.items]);
  const selected = useMemo(() => values || defaultValues || [], [
    defaultValues,
    values,
  ]);
  const total = selected.reduce((sum, { amount }) => sum + amount, 0);
  const hasOtos = !!otos.length;

  const TableComponent = isMobile ? MobileTable : Table;

  useEffect(() => {
    if (otos?.length === 1 && !selected.find(({ id }) => id === otos[0].id)) {
      setValues((values) => set(values, name, otos), true);
    }
  }, [name, otos, selected, setValues]);

  useEffect(() => {
    setValues((values) => set(values, 'amount', total));
  }, [setValues, total]);

  return (
    <FormArrayField name={name}>
      {({ push, remove, values }) => (
        <div className={styles.field}>
          <Caption2 color="secondary" offset={{ bottom: 10 }}>
            <I18n id="modal.disbursement.form.otos.title" />
          </Caption2>
          {hasOtos || loading ? (
            <OverlayLoader loading={loading} className={styles.content}>
              {hasOtos && (
                <TableComponent className={styles.table}>
                  <OtosFieldTableHeader otos={otos} />
                  <TableBody>
                    <>
                      {otos.map((oto) => {
                        const { id, type, date, amount, ledger } = oto;

                        return (
                          <TableRow
                            key={id}
                            onClick={() => {
                              if (
                                !values?.some(
                                  ({ id: valueId }) => id === valueId
                                )
                              ) {
                                push(oto);
                              } else {
                                const index = selected.findIndex(
                                  (item) => item.id === id
                                );

                                if (index !== -1) {
                                  remove(index);
                                }
                              }
                            }}
                          >
                            <TableCell width={`${isMobile ? 30 : 50}px`}>
                              <Checkbox
                                readOnly
                                checked={
                                  !!selected.find((item) => item.id === id)
                                }
                              />
                            </TableCell>
                            <MediaQueryMobile>
                              <TableCell width="30px">
                                <TrustTransactionType type={type} asIcon />
                              </TableCell>
                              <TableCell theme="light">
                                <p className={styles.activity}>{type}</p>
                                <DateComponent value={date} shortYear />
                              </TableCell>
                              <TableCell align="right">
                                <p>
                                  <Amount strong value={amount} />
                                </p>
                                <LedgerName value={ledger} />
                              </TableCell>
                            </MediaQueryMobile>
                            <MediaQueryDesktop>
                              <TableCell width="50px">
                                <TrustTransactionType type={type} asIcon />
                              </TableCell>
                              <TableCell>
                                <p className={styles.activity}>{type}</p>
                              </TableCell>
                              <TableCell theme="light">
                                <DateComponent value={date} shortYear />
                              </TableCell>
                              <TableCell>
                                <LedgerName value={ledger} />
                              </TableCell>
                              <TableCell theme="strong" align="right">
                                <Amount value={amount} />
                              </TableCell>
                            </MediaQueryDesktop>
                          </TableRow>
                        );
                      })}
                    </>
                  </TableBody>
                </TableComponent>
              )}
            </OverlayLoader>
          ) : (
            <p className={styles.emptyResult}>
              <I18n id="modal.disbursement.form.otos.empty" />
            </p>
          )}
          {hasOtos && (
            <Flex
              alignItems="center"
              justify="space-between"
              className={styles.total}
            >
              <p className={styles.totalTitle}>
                <I18n
                  id="modal.disbursement.form.otos.total"
                  values={{ total: values.length || 0 }}
                />
              </p>
              <Amount value={total} className={styles.totalAmount} />
            </Flex>
          )}
          <FieldError name={name} />
        </div>
      )}
    </FormArrayField>
  );
}
