import { LeadFragment } from 'gql/shared/__generated__/lead.gql';

export interface LeadTitleData {
  individual?: {
    firstName: string;
    lastName: string;
    middleName: string;
  };
  entity?: {
    entityName: string;
  };
}

export function getLeadTitle(lead: LeadTitleData) {
  if (lead.individual) {
    const { firstName, lastName, middleName } = lead.individual;

    return `${firstName}${middleName ? ' ' + middleName : ''} ${lastName}`;
  }

  if (lead.entity?.entityName) {
    return lead.entity.entityName;
  }

  return '';
}

export function getLeadContactData(
  lead: LeadFragment
): { fullName: string; email: string } {
  const leadContactData = lead.isEntity
    ? lead.entity?.contacts.find((c) => c.type === 'PRIMARY')
    : lead.individual;

  return {
    fullName: `${leadContactData?.firstName} ${leadContactData?.lastName}`,
    email: leadContactData?.email || '',
  };
}

export function getSplittedInboxName(name: string) {
  const splittedName = name.split(' ');

  return {
    firstName: splittedName[0],
    lastName: splittedName[splittedName.length - 1],
  };
}
