import * as Yup from 'yup';
import { URL_VALIDATION_SCHEMA } from '@appclose/core';

import { getI18nErrorMessage } from 'i18n';
import { LeadSources } from '__generated__/globalTypes';
import { PHONE_FIELDSET_SCHEMA } from 'components/form/PhoneFieldset';
import { NOTES_VALIDATION_SCHEMA } from 'schemas/validations/notes';
import { CONTACT_ENTITY_NAME_VALIDATION_SCHEMA } from 'schemas/validations/contact';
import { LEAD_ENTITY_CONTACTS_VALIDATION_SCHEMA } from 'schemas/validations/leads';

import { FetchProfileQuery } from '../../../../__generated__/LeadModal.gql';
import { LeadEntityFieldsetValuesType } from './EntityFieldset.types';

export const EntityFieldsetSchema = Yup.object().shape<LeadEntityFieldsetValuesType>(
  {
    entityName: CONTACT_ENTITY_NAME_VALIDATION_SCHEMA,
    website: URL_VALIDATION_SCHEMA.nullable(),
    leadSource: Yup.mixed<LeadSources>().required(
      getI18nErrorMessage('error.leads.create.leadSource')
    ),
    isImportant: Yup.boolean().required(),
    contacts: LEAD_ENTITY_CONTACTS_VALIDATION_SCHEMA.required(),
    notes: NOTES_VALIDATION_SCHEMA,
    phoneNumbers: PHONE_FIELDSET_SCHEMA.required(
      getI18nErrorMessage('error.leads.type.required')
    ),
    originatingAttorney: Yup.object<FetchProfileQuery['getProfile']>().required(
      getI18nErrorMessage('modal.contact.form.entity.error.originatingAttorney')
    ),
  }
);
