import React from 'react';
import { EM_DASH } from '@appclose/lib';
import { Grid } from '@appclose/ui';
import { Fieldset } from '@appclose/core';
import { TickIcon } from '@appclose/icons';

import { FeatureListPropsType } from './FeatureList.types';
import styles from './FeatureList.module.scss';

export default function FeatureList({ title, features }: FeatureListPropsType) {
  return (
    <Fieldset className={styles.fieldset} title={title}>
      {features.length ? (
        <ul className={styles.list}>
          {features.map((feature, i) => (
            <li key={i}>
              <Grid gap={[24]} columns="15px 1fr">
                <TickIcon className={styles.tick} /> {feature}
              </Grid>
            </li>
          ))}
        </ul>
      ) : (
        EM_DASH
      )}
    </Fieldset>
  );
}
