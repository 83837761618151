import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ShepherdTourContext } from 'react-shepherd';
import { useLocation } from 'react-router-dom';
import { Button, ButtonGroup, Caption1, Flex, Title3 } from '@appclose/ui';
import { Modal } from '@appclose/core';

import { isFreePlan } from 'controllers/mode';
import useAsideState from 'hooks/useAsideState';

import { I18n, useIntl } from 'i18n';

import { getTourShownStatus, setTourShownStatus } from './TourModal.utils';
import { TourContext } from '../../Tour.context';
import styles from './TourModal.module.scss';

export default function TourModal() {
  const { t } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const { hasTour } = useContext(TourContext);
  const tour = useContext(ShepherdTourContext);
  const { setIsOpen: setMenuOpen } = useAsideState();

  useEffect(() => {
    tour?.complete();
  
    if (hasTour && !getTourShownStatus()) {
      setMenuOpen(true);
      setTimeout(() => setIsOpen(true), 0)
    }
  }, [tour, hasTour, pathname, setMenuOpen]);

  useEffect(() => {
    const mouseUpHandler = (e: MouseEvent) => {
      e.stopImmediatePropagation();
    };

    if (isOpen) {
      document.addEventListener('mouseup', mouseUpHandler, true);
    }

    return () => {
      if (isOpen) {
        document.removeEventListener('mouseup', mouseUpHandler, true);
      }
    }
  }, [isOpen])

  const handleOnClose = useCallback(() => {
    setIsOpen(false);
    setTourShownStatus(true);
  }, []);
  const handleOnTakeTour = useCallback(() => {
    setMenuOpen(false);
    handleOnClose();
    tour?.start();
  }, [tour, handleOnClose, setMenuOpen]);

  const mode = isFreePlan() ? 'Free' : 'Plus';

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      title={t('tour.tourModal.guidedTour')}
      className={styles.modal}
      onClose={handleOnClose}
    >
      <Title3 offset={{ bottom: 18 }}>
        <I18n id="tour.tourModal.financialTools" values={{ mode }} />
      </Title3>
      <Caption1 offset={{ bottom: 32 }}>
        <I18n id="tour.tourModal.accountUnlocksPowerfulPlatform" />
      </Caption1>
      <Flex justify="center">
        <ButtonGroup>
          <Button onClick={handleOnClose}>
            <I18n id="tour.tourModal.skip" />
          </Button>
          <Button skin="primary" onClick={handleOnTakeTour}>
            <I18n id="tour.tourModal.takeGuidedTour" />
          </Button>
        </ButtonGroup>
      </Flex>
    </Modal>
  );
}
