import React from 'react';
import { Grid } from '@appclose/ui';
import { Loader, Amount, Fieldset } from '@appclose/core';
import { I18n, useIntl } from 'i18n';

import { sanitizeAmount } from 'controllers/amount';

import { ReconciliationSummaryPropsType } from './ReconciliationSummary.types';
import styles from './ReconciliationSummary.module.scss';

export default function ReconciliationSummary({
  loading,
  summary,
  readonly,
}: ReconciliationSummaryPropsType) {
  const { t } = useIntl();

  const {
    beginningBalance = 0,
    clearedDeposits = 0,
    clearedDisbursements = 0,
    clearedBalance = 0,
    endingBalance = 0,
    clearedDifference,
    unclearedDeposits = 0,
    unclearedDisbursements = 0,
    adjustedBalance = 0,
    transactionLedgerBalance = 0,
    allContactLedgersBalance = 0,
    balanceDifference,
  } = summary || {};

  return (
    <Fieldset
      className={styles.fieldset}
      title={t('reconciliationSummary.title')}
    >
      <div className={styles.summary}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Grid align="baseline" columns="auto 30%" gap={[20, 15]}>
              <span className={styles.smallText}>
                <I18n id="reconciliationSummary.balance.bank.beginning" />
              </span>
              <Amount className={styles.mediumText} value={beginningBalance} />
              <span className={styles.smallText}>
                <I18n id="reconciliationSummary.deposits.cleared" />
              </span>
              <Amount className={styles.mediumText} value={clearedDeposits} />
              <span className={styles.smallText}>
                <I18n id="reconciliationSummary.disbursements.cleared" />
              </span>
              <Amount
                className={styles.mediumText}
                value={clearedDisbursements}
              />
            </Grid>
            <Grid align="baseline" columns="auto 30%" gap={[20, 15]}>
              <b>
                <I18n id="reconciliationSummary.balance.cleared" />
              </b>
              <Amount className={styles.bigText} value={clearedBalance} />
              <b>
                <I18n id="reconciliationSummary.balance.ending.statement" />
              </b>
              <Amount className={styles.bigText} value={endingBalance} />
              <span className={styles.difference}>
                <I18n id="reconciliationSummary.difference" />
              </span>
              <Amount
                className={styles.mediumText}
                color={
                  readonly
                    ? 'info'
                    : Boolean(clearedBalance - endingBalance)
                    ? 'warning'
                    : undefined
                }
                value={sanitizeAmount(clearedDifference)}
              />
            </Grid>
            <Grid align="baseline" columns="auto 30%" gap={[20, 15]}>
              <span className={styles.smallText}>
                <I18n id="reconciliationSummary.deposits.uncleared" />
              </span>
              <Amount className={styles.mediumText} value={unclearedDeposits} />
              <span className={styles.smallText}>
                <I18n id="reconciliationSummary.disbursements.uncleared" />
              </span>
              <Amount
                className={styles.mediumText}
                value={unclearedDisbursements}
              />
            </Grid>
            <Grid align="baseline" columns="auto 30%" gap={[20, 15]}>
              <b>
                <I18n id="reconciliationSummary.balance.adjusted" />
              </b>
              <Amount className={styles.bigText} value={adjustedBalance} />
              <b>
                <I18n id="reconciliationSummary.balance.transactionLedger" />
              </b>
              <Amount
                className={styles.bigText}
                color={
                  adjustedBalance !== transactionLedgerBalance
                    ? 'warning'
                    : adjustedBalance === transactionLedgerBalance
                    ? 'success'
                    : undefined
                }
                value={transactionLedgerBalance}
              />
              <b>
                <I18n id="reconciliationSummary.ledgers.total" />
              </b>
              <Amount
                className={styles.bigText}
                color={
                  adjustedBalance !== allContactLedgersBalance
                    ? 'warning'
                    : adjustedBalance === allContactLedgersBalance
                    ? 'success'
                    : undefined
                }
                value={allContactLedgersBalance}
              />
              <span className={styles.difference}>
                <I18n id="reconciliationSummary.difference" />
              </span>
              <Amount
                className={styles.mediumText}
                value={sanitizeAmount(balanceDifference)}
                color={
                  readonly
                    ? 'info'
                    : sanitizeAmount(balanceDifference) !== 0
                    ? 'warning'
                    : sanitizeAmount(balanceDifference) === 0
                    ? 'success'
                    : undefined
                }
              />
            </Grid>
          </>
        )}
      </div>
    </Fieldset>
  );
}
