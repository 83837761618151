import React, { forwardRef, ReactNode } from 'react';

import { TourStepTargetPropsType } from './TourStepTarget.types';
import { getTourStepAttribute } from '../../Tour.utils';

const TourStepTarget = forwardRef<ReactNode, TourStepTargetPropsType>(
  ({ as: Component = 'div', step, className, children }, ref) => {
    return (
      <Component
        {...getTourStepAttribute(step)}
        className={className}
        ref={ref}
      >
        {children}
      </Component>
    );
  }
);

export default TourStepTarget;
