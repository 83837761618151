import * as Yup from 'yup';

import { ContactTypes } from 'constants/contacts';

import { getI18nErrorMessage } from 'i18n';

import {
  ContactMatterPopupFormValuesType,
  ContactFormValuesType,
  ContactFieldsetValuesType,
  EntityFieldsetValuesType,
} from './ContactMatterPopupForm.types';
import { ContactFieldsetSchema } from './components/ContactFieldset';
import { EntityFieldsetSchema } from './components/EntityFieldset';
import { MatterFieldsetSchema } from './components/MatterFieldset';
import { ContactMatterPopupModalTabs } from '../../ContactMatterPopupModal.types';

export const ContactMatterPopupFormSchema = Yup.object().shape<
  Partial<ContactMatterPopupFormValuesType>
>({
  matter: MatterFieldsetSchema.nullable(),
  contact: Yup.object<ContactFormValuesType>()
    .when('selectedTab', {
      is: ContactMatterPopupModalTabs.CONTACT,
      then: Yup.object().shape({
        contact: Yup.object<ContactFieldsetValuesType>()
          .when('contactType', {
            is: ContactTypes.INDIVIDUAL,
            then: ContactFieldsetSchema,
          })
          .required(),

        entity: Yup.object<EntityFieldsetValuesType>()
          .when('contactType', {
            is: ContactTypes.ENTITY,
            then: EntityFieldsetSchema,
          })
          .required(),
      }),
    })
    .when('selectedTab', {
      is: ContactMatterPopupModalTabs.MATTER,
      then: Yup.object().shape({
        existingContact: Yup.object()
          .nullable()
          .required(
            getI18nErrorMessage(
              'modal.popup.contactMatter.form.matter.error.contact.required'
            )
          ),
      }),
    }),
});
