import * as Yup from 'yup';
import { getI18nErrorMessage } from 'i18n';

import { SubExpenseCategoryCostTypes } from '__generated__/globalTypes';

import { ExpenseCategoryFormValuesType } from './ExpenseCategoryForm.types';

export const ExpenseCategoryFormSchema = Yup.object().shape<ExpenseCategoryFormValuesType>(
  {
    name: Yup.string()
      .min(2, ({ min }) =>
        getI18nErrorMessage(
          'modal.popup.expenseCategory.form.error.name.minLength',
          { min }
        )
      )
      .max(255, ({ max }) =>
        getI18nErrorMessage(
          'modal.popup.expenseCategory.form.error.name.maxLength',
          { max }
        )
      )
      .required(
        getI18nErrorMessage(
          'modal.popup.expenseCategory.form.error.name.required'
        )
      ),
    costType: Yup.mixed<SubExpenseCategoryCostTypes>().required(
      getI18nErrorMessage(
        'modal.popup.expenseCategory.form.error.costType.required'
      )
    ),
  }
);
