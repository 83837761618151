import React from 'react';
import { Grid } from '@appclose/ui';

import { ActionsPropsType } from './Actions.types';

export default function Actions({ children }: ActionsPropsType) {
  return (
    <Grid gap={[12, 12]} columns="repeat(auto-fill, 24px)" autoFlow="column">
      {children}
    </Grid>
  );
}
