import { EntitiesType } from '../EntityQboSyncStatus.types';
import { useCallback, useMemo } from 'react';

import { PermissionResources } from 'constants/permissions';
import {
  FETCH_QBO_SYNC_CONTACT_STATUS,
  QBO_RESYNC_CONTACT,
  FETCH_QBO_SYNC_CREDIT_MEMO_STATUS,
  QBO_RESYNC_CREDIT_MEMO,
  FETCH_QBO_SYNC_DEPOSIT_STATUS,
  QBO_RESYNC_DEPOSIT,
  FETCH_QBO_SYNC_DISBURSEMENT_STATUS,
  QBO_RESYNC_DISBURSEMENT,
  FETCH_QBO_SYNC_INVOICE_STATUS,
  QBO_RESYNC_INVOICE,
  FETCH_QBO_SYNC_PAYMENT_STATUS,
  QBO_RESYNC_PAYMENT,
  FETCH_QBO_SYNC_REFUND_STATUS,
  QBO_RESYNC_REFUND,
} from '../EntityQboSyncStatus.gql';
import { useIntl } from '../../../../i18n';

const qboSyncStatusMap: Record<EntitiesType, { fetch: any; resync: any }> = {
  contact: { fetch: FETCH_QBO_SYNC_CONTACT_STATUS, resync: QBO_RESYNC_CONTACT },
  creditMemo: {
    fetch: FETCH_QBO_SYNC_CREDIT_MEMO_STATUS,
    resync: QBO_RESYNC_CREDIT_MEMO,
  },
  deposit: { fetch: FETCH_QBO_SYNC_DEPOSIT_STATUS, resync: QBO_RESYNC_DEPOSIT },
  disbursement: {
    fetch: FETCH_QBO_SYNC_DISBURSEMENT_STATUS,
    resync: QBO_RESYNC_DISBURSEMENT,
  },
  invoice: { fetch: FETCH_QBO_SYNC_INVOICE_STATUS, resync: QBO_RESYNC_INVOICE },
  payment: { fetch: FETCH_QBO_SYNC_PAYMENT_STATUS, resync: QBO_RESYNC_PAYMENT },
  refund: { fetch: FETCH_QBO_SYNC_REFUND_STATUS, resync: QBO_RESYNC_REFUND },
};

const qboSyncStatusResourceMap: Record<EntitiesType, PermissionResources> = {
  contact: PermissionResources.CONTACT,
  creditMemo: PermissionResources.CREDIT_MEMO,
  deposit: PermissionResources.DEPOSIT,
  disbursement: PermissionResources.DISBURSEMENT,
  invoice: PermissionResources.INVOICE,
  payment: PermissionResources.PAYMENT,
  refund: PermissionResources.REFUND_PAYMENT,
};

export default function useQboEntitySyncStatus() {
  const { t } = useIntl();
  const entityTypes: Record<EntitiesType, string> = useMemo(
    () => ({
      contact: t('enum.qboEntityTypes.customer'),
      creditMemo: t('enum.qboEntityTypes.creditMemo'),
      deposit: t('enum.qboEntityTypes.deposit'),
      disbursement: t('enum.qboEntityTypes.disbursement'),
      invoice: t('enum.qboEntityTypes.invoice'),
      payment: t('enum.qboEntityTypes.payment'),
      refund: t('enum.qboEntityTypes.paymentRefund'),
    }),
    [t]
  );

  const getEntityType = useCallback(
    (type: EntitiesType) => {
      return entityTypes[type];
    },
    [entityTypes]
  );

  return { qboSyncStatusMap, qboSyncStatusResourceMap, getEntityType };
}
