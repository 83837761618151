import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import {
  openConfirmAction,
  permissionProvider,
  SelectFormField,
  useFormContext,
  usePopupModal,
} from '@appclose/core';

import { OrderTypes, TimerActivityStatuses } from '__generated__/globalTypes';
import { track } from 'controllers/analytics';
import TimeEntryActivityModal from 'components/modals/popups/TimeEntryActivityModal';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import { EntryTypes, EventNames, SourceTypes } from 'constants/analytics';
import useLastUsage from 'hooks/useLastUsage';
import { useIntl } from 'i18n';

import {
  FetchTimeEntryActivitiesQuery,
  FetchTimeEntryActivitiesQueryVariables,
} from './__generated__/TimeEntryActivitySelectFormField.gql';
import { FETCH_TIME_ENTRY_ACTIVITIES } from './TimeEntryActivitySelectFormField.gql';
import {
  TimeEntryActivitySelectFormFieldPropsType,
  TimeEntryActivitySelectType,
} from './TimeEntryActivitySelectFormField.types';

export default function TimeEntryActivitySelectFormField({
  name = 'activity',
  label,
  placeholder,
  withSearch = true,
  allowAddNew,
  ...props
}: TimeEntryActivitySelectFormFieldPropsType) {
  const { t } = useIntl();
  const dispatch = useDispatch();
  const { lastUsages } = useLastUsage('timeEntryActivity');
  const [search, setSearch] = useState('');
  const { setFieldValue } = useFormContext<any>();
  const { loading, data, fetchMore, refetch } = useQuery<
    FetchTimeEntryActivitiesQuery,
    FetchTimeEntryActivitiesQueryVariables
  >(FETCH_TIME_ENTRY_ACTIVITIES, {
    variables: {
      filter: {
        search,
        statuses: [TimerActivityStatuses.ACTIVE],
      },
      order: {
        forceTopIds: lastUsages,
        name: OrderTypes.ASC,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });
  const activities = data?.listTimeEntryActivities.items || [];

  const hasPermission = permissionProvider().hasPermission(
    PermissionResources.TIMER_ACTIVITY,
    PermissionActions.CREATE
  );

  const { openPopupModal } = usePopupModal(
    TimeEntryActivityModal,
    (activity) => {
      setFieldValue(name, activity);
      refetch();
    }
  );

  const onShowHasNoPermission = useCallback(() => {
    dispatch(
      openConfirmAction({
        content: t('field.timeEntryActivitySelect.hasNoAccess.content'),
        okButtonTitle: t('field.timeEntryActivitySelect.hasNoAccess.okButton'),
        okButtonSkin: 'primary',
      })
    );
  }, [dispatch, t]);

  const openAddNewModal = hasPermission
    ? () => {
        track(EventNames.ADD_NEW_ENTRY, {
          entry_type: EntryTypes.TIME_ENTRY_ACTIVITY,
          source_type: SourceTypes.POPUP_MODAL,
        });

        openPopupModal();
      }
    : onShowHasNoPermission;

  const onLoadMore = useCallback(async () => {
    const { data } = await fetchMore({
      variables: {
        skip: activities.length,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => ({
        listTimeEntryActivities: {
          ...previousResult.listTimeEntryActivities,
          items: [
            ...previousResult.listTimeEntryActivities.items,
            ...(fetchMoreResult?.listTimeEntryActivities.items || []),
          ],
        },
      }),
    });

    return activities.length !== data.listTimeEntryActivities.total;
  }, [activities.length, fetchMore]);
  const onSearchChange = useMemo(
    () => debounce((value: string) => setSearch(value), 500),
    [setSearch]
  );
  const optionValueResolver = useCallback(
    (activity: TimeEntryActivitySelectType) => activity,
    []
  );
  const optionLabelResolver = useCallback(
    ({ name }: TimeEntryActivitySelectType) => name,
    []
  );
  const selectedOptionsResolver = useCallback(
    (activities: TimeEntryActivitySelectType[]) =>
      activities.map(({ name }) => name).join(', '),
    []
  );

  return (
    <SelectFormField
      {...props}
      name={name}
      label={label || t('field.timeEntryActivitySelect.label')}
      withSearch={withSearch}
      placeholder={
        placeholder || t('field.timeEntryActivitySelect.placeholder')
      }
      isLoading={loading}
      options={activities}
      onSearchChange={onSearchChange}
      optionValueResolver={optionValueResolver}
      optionLabelResolver={optionLabelResolver}
      selectedOptionsResolver={selectedOptionsResolver}
      onLoadOptions={onLoadMore}
      onAdd={allowAddNew ? openAddNewModal : undefined}
    />
  );
}
