import { useState, useCallback } from 'react';
import { createGlobalState } from 'react-use';

import { useIsMobileDevice } from '@appclose/core';

import {
  getAsideMinifyStatus,
  setAsideMinifyStatus,
} from 'controllers/asideMinifyStatus';

const useOpenState = createGlobalState<boolean>(false);

export default function useAsideState() {
  const isMobile = useIsMobileDevice();

  const [isExpanded, setIsExpanded] = useState(!getAsideMinifyStatus());
  const [isOpen, setIsOpen] = useOpenState();

  const updateIsOpen = useCallback((value: boolean) => {
    if (isMobile) {
      setIsOpen(value);
    } else {
      setIsExpanded(value)
      setAsideMinifyStatus(!value)
    }
  }, [setIsOpen, setIsExpanded, isMobile]);

  return {
    isOpen: isMobile ? isOpen : isExpanded,
    setIsOpen: updateIsOpen
  };
}
