import { useCallback } from 'react';

import { openModal } from 'controllers/modal';
import { INVOICE_MODAL } from 'constants/modals';

import useAccessCheck from '../useAccessCheck/useAccessCheck';

const useOpenCreateInvoiceModalFlow = () => {

  const { withLimitedAccessCheck } = useAccessCheck();

  const runFlow = useCallback(async (data: any) => {

    openModal(INVOICE_MODAL, data);
  }, []);

  return withLimitedAccessCheck(runFlow, { checks: ['limitedAccess'] });
};

export default useOpenCreateInvoiceModalFlow;
