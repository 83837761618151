import { useIntl } from 'i18n';
import { LeadSources } from '__generated__/globalTypes';
import { useCallback, useMemo } from 'react';

export default function useLeadSources() {
  const { t } = useIntl();
  const leadSources: Record<LeadSources, string> = useMemo(
    () => ({
      [LeadSources.FACEBOOK]: t('enum.leadSources.facebook'),
      [LeadSources.GOOGLE_ADS]: t('enum.leadSources.googleAds'),
      [LeadSources.GOOGLE_MAPS]: t('enum.leadSources.googleMaps'),
      [LeadSources.LAWYER_DIRECTORY]: t('enum.leadSources.lawyerDirectory'),
      [LeadSources.LINKEDIN]: t('enum.leadSources.linkedin'),
      [LeadSources.NONE]: t('enum.leadSources.none'),
      [LeadSources.OFFLINE_EVENT]: t('enum.leadSources.offlineEvent'),
      [LeadSources.ONLINE_EVENT]: t('enum.leadSources.onlineEvent'),
      [LeadSources.PERSONAL_ADVICE]: t('enum.leadSources.personalAdvice'),
      [LeadSources.PRESS]: t('enum.leadSources.press'),
      [LeadSources.WEBSITE]: t('enum.leadSources.website'),
    }),
    [t]
  );

  const leadSourcesOptions: {
    label: string;
    value: LeadSources;
  }[] = useMemo(
    () =>
      Object.entries(leadSources).map(([value, label]) => ({
        value: value as LeadSources,
        label,
      })),
    [leadSources]
  );

  const getLeadSourceLabel = useCallback(
    (type?: LeadSources | null) => {
      if (!type) {
        return '';
      }

      return leadSources[type];
    },
    [leadSources]
  );

  return { leadSourcesOptions, getLeadSourceLabel };
}
