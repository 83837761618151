import { gql } from '@apollo/client';

import { CONTACT_SELECT_FRAGMENT } from 'components/fields/ContactSelectFormField';
import { MATTER_SELECT_FRAGMENT } from 'components/fields/MatterSelectFormField';

export const CREATE_CONTACT = gql`
  mutation CreateContact($input: CreateContactInput!) {
    createContact(createContactInput: $input) {
      ...ContactSelect
    }
  }
  ${CONTACT_SELECT_FRAGMENT}
`;

export const CREATE_MATTER = gql`
  mutation CreateMatter($matter: CreateMatterInput!) {
    createMatter(createMatterInput: $matter) {
      ...MatterSelect
    }
  }
  ${MATTER_SELECT_FRAGMENT}
`;
