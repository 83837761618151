import React from 'react';
import { Amount, FormGroup, StaticField, Fieldset } from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import TrustTransactionStatus from 'components/common/TrustTransactionStatus';
import Date from 'components/common/Date';
import EntityQboSyncStatus from 'components/common/EntityQboSyncStatus';
import Address from 'components/common/Address';
import LedgerName from 'components/common/LedgerName';
import usePaymentMethodsTypes from 'hooks/usePaymentMethodsTypes';
import { useIntl } from 'i18n';

import { CustomDepositDetailsPropsType } from './CustomDepositDetails.types';

export default function CustomDepositDetails({
  deposit,
  firm,
}: CustomDepositDetailsPropsType) {
  const { getPaymentMethodsTypeLabel } = usePaymentMethodsTypes();
  const { t } = useIntl();

  return (
    <>
      <Fieldset title={t('modal.depositDetails.custom.fieldset.details.title')}>
        <FormGroup>
          <FormGrid adaptive={false}>
            <StaticField
              title={t('modal.depositDetails.custom.fieldset.details.amount')}
            >
              <Amount value={deposit.amount} />
            </StaticField>
            <StaticField
              title={t('modal.depositDetails.custom.fieldset.details.status')}
            >
              <TrustTransactionStatus status={deposit.status} />
            </StaticField>
          </FormGrid>
        </FormGroup>
        <FormGroup>
          <FormGrid>
            <StaticField
              title={t('modal.depositDetails.custom.fieldset.details.date')}
            >
              <Date value={deposit.date} />
            </StaticField>
          </FormGrid>
        </FormGroup>
      </Fieldset>
      {deposit.depositTransactions
        ?.reverse()
        .map(({ id, source, ledger, amount, paymentMethod, memo }, i) => {
          return (
            <Fieldset
              title={t('modal.depositDetails.custom.fieldset.payment.title', {
                number: i + 1,
              })}
              key={id}
            >
              <FormGroup>
                <FormGrid>
                  <StaticField
                    title={t(
                      'modal.depositDetails.custom.fieldset.payment.from'
                    )}
                  >
                    {source?.name}
                    {source?.addresses && (
                      <Address addresses={source.addresses} />
                    )}
                  </StaticField>
                  <StaticField
                    title={t('modal.depositDetails.custom.fieldset.payment.to')}
                  >
                    {firm.name}
                    {firm.address && <Address addresses={[firm.address]} />}
                  </StaticField>
                </FormGrid>
              </FormGroup>
              <FormGroup>
                <FormGrid>
                  <StaticField
                    title={t(
                      'modal.depositDetails.custom.fieldset.payment.amount'
                    )}
                  >
                    <Amount value={amount} />
                  </StaticField>
                  <StaticField
                    title={t(
                      'modal.depositDetails.custom.fieldset.payment.paymentMethod'
                    )}
                  >
                    {getPaymentMethodsTypeLabel(paymentMethod)}
                  </StaticField>
                </FormGrid>
              </FormGroup>
              <FormGroup>
                <FormGrid>
                  <StaticField
                    title={t(
                      'modal.depositDetails.custom.fieldset.payment.ledger'
                    )}
                  >
                    <LedgerName value={ledger} />
                  </StaticField>
                </FormGrid>
              </FormGroup>
              <FormGroup>
                <FormGrid>
                  <StaticField
                    title={t(
                      'modal.depositDetails.custom.fieldset.payment.memo'
                    )}
                  >
                    {memo}
                  </StaticField>
                </FormGrid>
              </FormGroup>
              <FormGroup>
                <FormGrid>
                  <StaticField title={t('modal.depositDetails.qboSyncStatus')}>
                    <EntityQboSyncStatus
                      entityId={deposit?.id}
                      qboSyncState={deposit?.qboSyncState || undefined}
                      entityType="deposit"
                    />
                  </StaticField>
                </FormGrid>
              </FormGroup>
            </Fieldset>
          );
        })}
    </>
  );
}
