import { gql } from '@apollo/client';

export const FETCH_CHECK_INFO = gql`
  query FetchCheckInfo {
    firm: getFirm {
      id
      name
      address {
        address1
        address2
        city
        state
        type
        zipCode
      }
    }
    accounts: listBankAccounts {
      id
      class
      holderName
      routingNumber
      accountNumberLast4
    }
  }
`;
