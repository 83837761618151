import React from 'react';
import classnames from 'classnames';
import { Button, ButtonGroup, Flex, RadioGroup } from '@appclose/ui';
import {
  FieldError,
  Form,
  FormGroup,
  InputFormField,
  RadioFormField,
  TextAreaFormField,
  ToggleFormField,
} from '@appclose/core';

import { I18n, useIntl } from 'i18n';

import { InvoiceRemindersFormSchema } from './InvoiceRemindersForm.schema';
import {
  InvoiceRemindersFormPropsType,
  InvoiceStopToRemind,
} from './InvoiceRemindersForm.types';
import styles from './InvoiceRemindersForm.module.scss';

export default function InvoiceRemindersForm({
  initialValues,
  onSubmit,
  onCancel,
  onChange,
}: InvoiceRemindersFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      onChange={onChange}
      validationSchema={InvoiceRemindersFormSchema}
    >
      {({
        values: {
          overdueReminderType,
          prepaymentReminderActive,
          overdueReminderActive,
        },
        isSubmitting,
      }) => {
        const isDaysLimit =
          overdueReminderType === InvoiceStopToRemind.DAYS_PASSED;
        const isRemindersLimit =
          overdueReminderType === InvoiceStopToRemind.REMINDERS_SENT;

        return (
          <>
            <p className={styles.remaindersNote}>
              <I18n id="modal.invoiceReminders.form.remindersNote" />
            </p>
            <FormGroup>
              <ToggleFormField
                name="prepaymentReminderActive"
                label={t(
                  'modal.invoiceReminders.form.prepaymentReminder.toggle'
                )}
              />
            </FormGroup>
            {prepaymentReminderActive && (
              <>
                <FormGroup>
                  <p className={styles.limitsLabel}>
                    <I18n id="modal.invoiceReminders.form.prepaymentReminder.label" />
                  </p>
                  <Flex className={styles.limits}>
                    <InputFormField
                      fieldClassName={styles.limitInput}
                      className={styles.limitInputText}
                      type="number"
                      name="prepaymentReminder.dayQtyBefore"
                      hideErrorMsg={true}
                    />
                    <p className={styles.limitDescription}>
                      <I18n id="modal.invoiceReminders.form.prepaymentReminder.description" />
                    </p>
                  </Flex>
                  <FieldError name="prepaymentReminder.dayQtyBefore" />
                </FormGroup>
                <FormGroup>
                  <TextAreaFormField
                    name="prepaymentReminder.emailText"
                    label={t('modal.invoiceReminders.form.emailText')}
                    className={styles.emailField}
                  />
                </FormGroup>
              </>
            )}
            <FormGroup>
              <ToggleFormField
                name="overdueReminderActive"
                label={t('modal.invoiceReminders.form.overdueReminder.toggle')}
              />
            </FormGroup>
            {overdueReminderActive && (
              <>
                <FormGroup>
                  <p className={styles.limitsLabel}>
                    <I18n id="modal.invoiceReminders.form.overdueReminder.start.label" />
                  </p>
                  <Flex className={styles.limits}>
                    <div>
                      <Flex>
                        <InputFormField
                          fieldClassName={styles.limitInput}
                          className={styles.limitInputText}
                          type="number"
                          name="overdueReminder.overdueDayQty"
                          hideErrorMsg={true}
                        />
                        <p className={styles.limitDescription}>
                          <I18n id="modal.invoiceReminders.form.overdueReminder.start.description.p1" />
                        </p>
                      </Flex>
                      <FieldError name="overdueReminder.overdueDayQty" />
                    </div>
                    <div>
                      <Flex>
                        <InputFormField
                          fieldClassName={styles.limitInput}
                          className={styles.limitInputText}
                          type="number"
                          name="overdueReminder.ongoingOverdueDayQty"
                          hideErrorMsg={true}
                        />
                        <p className={styles.limitDescription}>
                          <I18n id="modal.invoiceReminders.form.overdueReminder.start.description.p2" />
                        </p>
                      </Flex>
                      <FieldError name="overdueReminder.ongoingOverdueDayQty" />
                    </div>
                  </Flex>
                </FormGroup>
                <FormGroup>
                  <p className={styles.limitsLabel}>
                    <I18n id="modal.invoiceReminders.form.overdueReminder.stop.label" />
                  </p>
                  <RadioGroup>
                    <Flex className={styles.limits}>
                      <RadioFormField
                        name="overdueReminderType"
                        value={InvoiceStopToRemind.DAYS_PASSED}
                      />
                      <div>
                        <Flex>
                          <InputFormField
                            fieldClassName={styles.limitInput}
                            className={styles.limitInputText}
                            type="number"
                            name="overdueReminder.stopToRemindAfterDayQty"
                            disabled={!isDaysLimit}
                            hideErrorMsg={true}
                          />
                          <label
                            htmlFor="limit-type-days"
                            className={classnames(styles.limitDescription, {
                              [styles.disabled]: !isDaysLimit,
                            })}
                          >
                            <I18n id="modal.invoiceReminders.form.overdueReminder.stop.days" />
                          </label>
                        </Flex>
                        <FieldError name="overdueReminder.stopToRemindAfterDayQty" />
                      </div>
                    </Flex>
                    <Flex className={styles.limits}>
                      <RadioFormField
                        name="overdueReminderType"
                        value={InvoiceStopToRemind.REMINDERS_SENT}
                      />
                      <div>
                        <Flex>
                          <InputFormField
                            fieldClassName={styles.limitInput}
                            className={styles.limitInputText}
                            type="number"
                            name="overdueReminder.stopToRemindAfterSentQty"
                            disabled={!isRemindersLimit}
                            hideErrorMsg={true}
                          />
                          <label
                            htmlFor="limit-type-reminders"
                            className={classnames(styles.limitDescription, {
                              [styles.disabled]: !isRemindersLimit,
                            })}
                          >
                            <I18n id="modal.invoiceReminders.form.overdueReminder.stop.reminders" />
                          </label>
                        </Flex>
                        <FieldError name="overdueReminder.stopToRemindAfterSentQty" />
                      </div>
                    </Flex>
                  </RadioGroup>
                </FormGroup>
                <FormGroup>
                  <TextAreaFormField
                    name="overdueReminder.emailText"
                    label={t('modal.invoiceReminders.form.emailText')}
                    className={styles.emailField}
                  />
                </FormGroup>
              </>
            )}
            <ButtonGroup className={styles.buttons}>
              <Button onClick={onCancel}>
                <I18n id="modal.invoiceReminders.form.cancel" />
              </Button>
              <Button type="submit" skin="primary" loading={isSubmitting}>
                <I18n id="modal.invoiceReminders.form.submit" />
              </Button>
            </ButtonGroup>
          </>
        );
      }}
    </Form>
  );
}
