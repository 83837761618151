import gqlClient from 'gql';
import { openConfirmAction, traceError } from '@appclose/core';

import notification from 'controllers/notification';
import { i18nMessage } from 'i18n';

import { DELETE_EXPENSE } from './expense.gql';
import {
  DeleteExpenseMutation,
  DeleteExpenseMutationVariables,
} from './__generated__/expense.gql';

export const openDeleteExpenseConfirmAction = ({
  id,
  onSuccess,
}: {
  id: string;
  onSuccess?(): void;
}) =>
  openConfirmAction({
    name: i18nMessage('store.expense.deleteExpenseConfirm.name'),
    content: i18nMessage('store.expense.deleteExpenseConfirm.content'),
    okButtonTitle: i18nMessage('store.expense.deleteExpenseConfirm.okButton'),
    cancelButtonTitle: i18nMessage(
      'store.expense.deleteExpenseConfirm.cancelButton'
    ),
    okButtonSkin: 'primary',
    async onConfirm() {
      try {
        await gqlClient.mutate<
          DeleteExpenseMutation,
          DeleteExpenseMutationVariables
        >({
          mutation: DELETE_EXPENSE,
          variables: { id },
        });

        notification().entityDeleted(
          i18nMessage('store.expense.deleteExpenseConfirm.notify')
        );

        onSuccess && onSuccess();
      } catch (e) {
        traceError(e);
      }
    },
  });
