import React, { useCallback } from 'react';
import { getQueryName } from 'gql/utils';
import omit from 'lodash/omit';
import { useMutation, useQuery } from '@apollo/client';
import { dateManager, Loader } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { FETCH_TIMERS } from 'components/common/TimeTracker';
import { DemoEventNames, EntryTypes } from 'constants/analytics';
import { TIME_ENTRY_DETAILS_MODAL } from 'constants/modals';
import { SERVER_DATE_FORMAT } from 'constants/date';
import { sanitizeAmount } from 'controllers/amount';
import { openModal } from 'controllers/modal';
import { track } from 'controllers/analytics';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import useProfile from 'hooks/useProfile';
import useLastUsage from 'hooks/useLastUsage';
import { useIntl } from 'i18n';
import useUpdateFiles from 'hooks/useUpdateFiles';
import { Entities } from 'constants/entities';

import {
  CreateTimeEntryMutation,
  CreateTimeEntryMutationVariables,
  FetchContactQuery,
  FetchContactQueryVariables,
  FetchMatterQuery,
  FetchMatterQueryVariables,
  FetchTimeEntryQuery,
  FetchTimeEntryQueryVariables,
  FetchTimerQuery,
  FetchTimerQueryVariables,
  UpdateTimeEntryMutation,
  UpdateTimeEntryMutationVariables,
} from './__generated__/TimeEntryModal.gql';
import TimeEntryForm from './components/TimeEntryForm';
import { TimeEntryFormValuesType } from './components/TimeEntryForm/TimeEntryForm.types';
import {
  CREATE_TIME_ENTRY,
  FETCH_CONTACT,
  FETCH_MATTER,
  FETCH_TIME_ENTRY,
  FETCH_TIMER,
  UPDATE_TIME_ENTRY,
} from './TimeEntryModal.gql';
import { TimeEntryModalPropsType } from './TimeEntryModal.types';

export default function TimeEntryModal({
  matterId,
  contactId,
  timerId,
  timeEntryId,
  onClose,
}: TimeEntryModalPropsType) {
  const { t } = useIntl();
  const { setLastUsage } = useLastUsage('timeEntryActivity');
  const { onUpdateFiles } = useUpdateFiles(Entities.EXPENSE);

  const { loading: profileLoading, profile } = useProfile();
  const { loading: matterLoading, data: matterData } = useQuery<
    FetchMatterQuery,
    FetchMatterQueryVariables
  >(FETCH_MATTER, {
    variables: { id: matterId as string },
    skip: !matterId || !!timerId,
  });
  const { loading: contactLoading, data: contactData } = useQuery<
    FetchContactQuery,
    FetchContactQueryVariables
  >(FETCH_CONTACT, {
    variables: { id: contactId as string },
    skip: !contactId || !!matterId || !!timerId,
  });
  const { loading: timerLoading, data: timerData } = useQuery<
    FetchTimerQuery,
    FetchTimerQueryVariables
  >(FETCH_TIMER, {
    variables: { id: timerId as string },
    skip: !timerId,
    fetchPolicy: 'network-only',
  });
  const { loading: timeEntryLoading, data: timeEntryData } = useQuery<
    FetchTimeEntryQuery,
    FetchTimeEntryQueryVariables
  >(FETCH_TIME_ENTRY, {
    variables: { id: timeEntryId as string },
    skip: !timeEntryId,
  });
  const [createTimeEntry] = useMutation<
    CreateTimeEntryMutation,
    CreateTimeEntryMutationVariables
  >(CREATE_TIME_ENTRY, {
    refetchQueries: [getQueryName(FETCH_TIMERS)],
    awaitRefetchQueries: true,
  });
  const [updateTimeEntry] = useMutation<
    UpdateTimeEntryMutation,
    UpdateTimeEntryMutationVariables
  >(UPDATE_TIME_ENTRY);

  const isLoading =
    profileLoading ||
    matterLoading ||
    timerLoading ||
    timeEntryLoading ||
    contactLoading;
  const isEdit = !!timeEntryId;
  const profileId = profile?.id || '';
  const timeEntry =
    timeEntryData?.timeEntry || ({} as FetchTimeEntryQuery['timeEntry']);

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });

  const handleOnSubmit = useCallback(
    async ({
      contact,
      activity,
      matter,
      amount,
      billedBy,
      files,
      ...values
    }: TimeEntryFormValuesType) => {
      const activityId = activity?.id || '';
      const contactId = contact?.id || '';
      const matterId = matter?.id || '';
      const billedById = billedBy?.id || '';
      const billable = values.billable || false;
      const date = values.date || '';

      let id = timeEntryId;

      if (!isEdit) {
        const result = await createTimeEntry({
          variables: {
            timeEntry: {
              ...omit(values, 'roundedDuration'),
              durationSec: values.roundedDuration || values.durationSec || 0,
              contactId,
              matterId,
              billedById,
              billable,
              activityId,
              timerId,
              date,
            },
          },
        });

        notification().entityCreated(t('modal.timeEntry.notification.created'));

        track(DemoEventNames.DEMO_ENTRY_CREATED, {
          entry_type: EntryTypes.TIME_ENTRY,
        });

        id = result.data?.createTimeEntry.id;
      } else {
        await updateTimeEntry({
          variables: {
            timeEntry: {
              ...omit(values, 'roundedDuration'),
              durationSec: values.roundedDuration || values.durationSec,
              id: timeEntryId as string,
              timerId: timeEntry.timer?.id,
              contactId,
              matterId,
              billedById,
              activityId,
              billable,
              date,
            },
          },
        });

        notification().entityUpdated(t('modal.timeEntry.notification.updated'));
      }

      if (id && files) {
        await onUpdateFiles({ entityId: id, ...files });
      }

      onClose();

      setLastUsage(activityId);

      id && openModal(TIME_ENTRY_DETAILS_MODAL, { id });
    },
    [
      timeEntryId,
      isEdit,
      onClose,
      setLastUsage,
      createTimeEntry,
      timerId,
      t,
      updateTimeEntry,
      timeEntry.timer?.id,
      onUpdateFiles,
    ]
  );

  const title = !isEdit
    ? t('modal.timeEntry.create.title')
    : t('modal.timeEntry.edit.title');

  let initialValues: TimeEntryFormValuesType = {};

  if (!isLoading) {
    if (!isEdit) {
      const { totalSeconds = 0, matter = matterData?.matter, description } =
        timerData?.timer || {};
      const teamMember = matter?.teamMembers.find(
        ({ member: { id } }) => id === profile?.id
      );

      const contact =
        matter?.contact ||
        contactData?.contact ||
        timerData?.timer?.contact ||
        undefined;

      initialValues = {
        matter,
        contact,
        durationSec: dateManager().roundDuration(
          totalSeconds,
          dateManager().getSeconds('minute')
        ),
        description: description || '',
        billable: true,
        date: dateManager().parse().startOf('day').format(SERVER_DATE_FORMAT),
        billingRate: sanitizeAmount(teamMember?.hourlyRate),
        billedBy: teamMember?.member,
        amount: 0,
      };
    } else {
      const { date, billedBy, amount, durationSec } = timeEntry;

      initialValues = {
        ...omit(timeEntry, ['timer', 'status', 'files']),
        date: dateManager().parse(date).format(SERVER_DATE_FORMAT),
        billedBy: billedBy || undefined,
        amount: sanitizeAmount(amount),
        durationSec: durationSec || 0,
      };
    }
  }

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>{title}</ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {isLoading ? (
          <Loader />
        ) : (
          <TimeEntryForm
            initialValues={initialValues}
            isEdit={isEdit}
            profileId={profileId}
            onSubmit={handleOnSubmit}
            onCancel={onConfirmClose}
            onChange={onFormChange}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
