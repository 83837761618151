import { useQuery } from '@apollo/client';
import { isFreePlan } from 'controllers/mode';
import {
  FetchLeadStatusesQuery,
  FetchLeadStatusesQueryVariables,
} from 'gql/shared/__generated__/lead.gql';
import { FETCH_LEAD_STATUSES } from 'gql/shared/lead.gql';

export default function useLeadStatuses() {
  const { data } = useQuery<
    FetchLeadStatusesQuery,
    FetchLeadStatusesQueryVariables
  >(FETCH_LEAD_STATUSES, {
    fetchPolicy: 'cache-first',
    skip: isFreePlan()
  });

  return data?.listLeadStatuses || [];
}
