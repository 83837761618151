import { gql } from '@apollo/client';

export const FETCH_CHECK_SAMPLE_PDF = gql`
  query FetchCheckSamplePdf($input: GetSamplePDFCheckInput!) {
    sample: getSampleCheckPDF(getSampleCheckPDFInput: $input) {
      data
      mimetype
      name
    }
  }
`;
