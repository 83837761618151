import React from 'react';
import {
  Button,
  ButtonGroup,
  RadioGroup,
  Note,
  Text3,
  Text4,
} from '@appclose/ui';
import { Form, FormGroup, RadioFormField, Fieldset } from '@appclose/core';

import { PrintingChecksModes } from '__generated__/globalTypes';
import useQboIntegration from 'hooks/useQboIntegration';
import { I18n, useIntl } from 'i18n';

import { PrintingOptionsFormPropsType } from './PrintingOptionsForm.types';

export default function PrintingOptionsForm({
  initialValues,
  onSubmit,
  onCancel,
  onChange,
}: PrintingOptionsFormPropsType) {
  const { t } = useIntl();
  const { connected } = useQboIntegration();

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} onChange={onChange}>
      {({ isSubmitting, values: { mode } }) => (
        <>
          <Fieldset title={t('modal.printingOptions.form.howPrint.title')}>
            <Text4 offset={{ top: 10, bottom: 30 }}>
              <I18n id="modal.printingOptions.form.howPrint.selectMode" />
            </Text4>
            <FormGroup>
              <RadioGroup>
                <RadioFormField
                  name="mode"
                  disabled={!connected}
                  value={PrintingChecksModes.QBO}
                  label={
                    <div>
                      <Text3 weight="semibold">
                        <I18n id="modal.printingOptions.form.mode.qbo.title" />
                      </Text3>
                      <Text4>
                        <I18n id="modal.printingOptions.form.mode.qbo.info" />
                      </Text4>
                    </div>
                  }
                />
                <RadioFormField
                  name="mode"
                  value={PrintingChecksModes.VOUCHER}
                  label={
                    <div>
                      <Text3 weight="semibold">
                        <I18n id="modal.printingOptions.form.mode.monesq.title" />
                      </Text3>
                      <Text4>
                        <I18n id="modal.printingOptions.form.mode.monesq.info" />
                      </Text4>
                    </div>
                  }
                />
              </RadioGroup>
            </FormGroup>
            <Note theme="important-small">
              <I18n id="modal.printingOptions.form.note" />
            </Note>
          </Fieldset>
          <ButtonGroup>
            <Button onClick={onCancel} disabled={isSubmitting}>
              <I18n id="modal.printingOptions.form.cancel" />
            </Button>
            <Button
              skin="primary"
              type="submit"
              loading={isSubmitting}
              disabled={!mode}
            >
              <I18n id="modal.printingOptions.form.submit" />
            </Button>
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}
