import React, { useEffect } from 'react';
import {
  ConfirmProvider,
  GqlErrorContext,
  NotificationProvider,
  PopupModalProvider,
} from '@appclose/core';

import { getGqlErrorResolvers } from './AppContent.constants';
import Router from 'components/common/Router';
import Zendesk from 'components/common/Zendesk';
import { isDemoBuild } from 'controllers/demo';
import { session } from 'controllers/session';
import useEnvForwardingParams from 'hooks/useEnvForwardingParams';
import { useIntl } from 'i18n';

export default function AppContent() {
  const { t } = useIntl();
  const {
    savePrams,
    params: { mode },
  } = useEnvForwardingParams();

  useEffect(() => {
    savePrams();
    // Save query params only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isDemoBuild()) {
    mode && session.deleteTokens();
  }

  return (
    <GqlErrorContext.Provider
      value={{
        errorResolver: getGqlErrorResolvers(t),
      }}
    >
      <PopupModalProvider>
        <Router />
      </PopupModalProvider>
      <ConfirmProvider />
      <NotificationProvider />
      <Zendesk />
    </GqlErrorContext.Provider>
  );
}
