import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  openConfirmAction,
  Fieldset,
  Form,
  Loader,
  RadioFormField,
} from '@appclose/core';
import { Button, ButtonGroup, Note, RadioGroup } from '@appclose/ui';
import { useMutation, useQuery } from '@apollo/client';

import { I18n, useIntl } from 'i18n';
import useCloseConfirm from 'hooks/useCloseConfirm';
import useGoogleDriveSync from 'hooks/useGoogleDriveSync';
import useImperativeQuery from 'hooks/useImperativeQuery';
import { ModalPage, ModalPageContent, ModalPageHeader, ModalPageTitle } from 'components/common/ModalPage';
import notification from 'controllers/notification';

import { FileStorageSyncTypes, UpdateSyncOptionsInput } from '__generated__/globalTypes';
import {
  GoogleDriveSyncOptionsFormSubmitHandler,
  GoogleDriveSyncOptionsFormValuesType,
  GoogleDriveSyncOptionsModalPropsType,
  SyncFolderType
} from './GoogleDriveSyncOptionsModal.types';
import {
  CHECK_GOOGLE_FOLDER_NAME,
  FETCH_GOOGLE_DRIVE_SYNC_OPTIONS,
  UPDATE_GOOGLE_DRIVE_SYNC_OPTIONS
} from './GoogleDriveSyncOptionsModal.gql';
import {
  CheckGoogleFolderNameQuery,
  CheckGoogleFolderNameQueryVariables,
  FetchGoogleDriveSyncOptionsQuery,
  FetchGoogleDriveSyncOptionsQueryVariables,
  UpdateGoogleDriveSyncOptionsMutation,
  UpdateGoogleDriveSyncOptionsMutationVariables
} from './__generated__/GoogleDriveSyncOptionsModal.gql';
import { GoogleDriveSyncOptionsModalSchema } from './GoogleDriveSyncOptionsModal.schema';
import GoogleDriveFieldset from './components/GoogleDriveFieldset';
import GoogleFolderFieldset from './components/GoogleFolderFieldset';

const DEFAULT_FOLDER_NAME = 'Monesq';

export default function GoogleDriveSyncOptionsModal({
  onClose,
}: GoogleDriveSyncOptionsModalPropsType) {
  const { t } = useIntl();
  const dispatch = useDispatch();
  const { onConfirmClose, onFormChange } = useCloseConfirm({ onClose });
  const { myDrive, normalizeDriveId } = useGoogleDriveSync();
  const { loading, data } = useQuery<
    FetchGoogleDriveSyncOptionsQuery,
    FetchGoogleDriveSyncOptionsQueryVariables
  >(FETCH_GOOGLE_DRIVE_SYNC_OPTIONS, {
    fetchPolicy: 'network-only'
  });

  const { options } = data || {};
  const initialValues = useMemo<GoogleDriveSyncOptionsFormValuesType>(
    () => {
      const isExistFolder = !!options?.folderId;

      return {
        drive: {
          id: options?.driveId || myDrive.id,
          name: options?.driveName || myDrive.name
        },
        folderId: isExistFolder ? options?.folderId : null,
        folderName: isExistFolder ? options?.folderName : null,
        newFolderName: DEFAULT_FOLDER_NAME,
        syncFolderType: isExistFolder ? SyncFolderType.EXISTING : SyncFolderType.NEW,
        syncType: options?.syncType || FileStorageSyncTypes.TO_EXTERNAL_STORAGE,
        breadcrumbs: options?.breadcrumbs || [],
        fileSyncActive: true
      };
    },
    [myDrive, options]
  );

  const [checkGoogleFolderName] = useImperativeQuery<
    CheckGoogleFolderNameQuery,
    CheckGoogleFolderNameQueryVariables
  >(CHECK_GOOGLE_FOLDER_NAME);

  const [updateGoogleDriveSyncOptions] = useMutation<
    UpdateGoogleDriveSyncOptionsMutation,
    UpdateGoogleDriveSyncOptionsMutationVariables
  >(UPDATE_GOOGLE_DRIVE_SYNC_OPTIONS);

  const openFolderExistWarning = useCallback(() => {
    dispatch(
      openConfirmAction({
        name: t('modal.googleDriveSyncOptions.submit.folderExist.title'),
        content: t('modal.googleDriveSyncOptions.submit.folderExist.content'),
        cancelButtonTitle: t('modal.googleDriveSyncOptions.submit.folderExist.cancel'),
      })
    );
  }, [t, dispatch]);

  const openConfirmSubmit = useCallback((input: UpdateSyncOptionsInput) => {
    dispatch(
      openConfirmAction({
        name: t('modal.googleDriveSyncOptions.submit.confirm.title'),
        content: (
          <I18n
            id="modal.googleDriveSyncOptions.submit.confirm.text"
            values={{
              driveName: input.driveName,
              folderName: input.folderName,
              note: (string: string) => (
                <Note>
                  {string}
                </Note>
              ),
            }}
          />
        ),
        okButtonSkin: 'primary',
        okButtonTitle: t('modal.googleDriveSyncOptions.submit.confirm.ok'),
        cancelButtonTitle: t(
          'modal.googleDriveSyncOptions.submit.confirm.cancel'
        ),
        async onConfirm() {
          await updateGoogleDriveSyncOptions({
            variables: {
              input,
            },
          });

          notification().successAction(t('modal.googleDriveSyncOptions.success'));
          onClose();
        },
      })
    );
  }, [t, dispatch, onClose, updateGoogleDriveSyncOptions]);

  const handleOnSubmit: GoogleDriveSyncOptionsFormSubmitHandler = useCallback(
    async ({ syncFolderType, drive, folderId, folderName: existFolderName, breadcrumbs, newFolderName, ...input }) => {
      const isExistFolder = syncFolderType === SyncFolderType.EXISTING;
      const driveId = normalizeDriveId(drive.id);
      const driveName = drive.name;
      const folderName = isExistFolder ? existFolderName : newFolderName;

      if (folderName && !isExistFolder) {
        const { data: { checkResult } } = await checkGoogleFolderName({ driveId, folderName });

        if (!!checkResult?.exists) {
          return openFolderExistWarning();
        }
      }

      openConfirmSubmit({
        ...input,
        driveId,
        driveName,
        folderName,
        folderId: isExistFolder ? folderId : null,
        breadcrumbs: isExistFolder ? breadcrumbs : [],
      });
    },
    [openConfirmSubmit, openFolderExistWarning, checkGoogleFolderName, normalizeDriveId]
  );

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.googleDriveSyncOptions.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading ? (
          <Loader />
        ) : (
          <Form<GoogleDriveSyncOptionsFormValuesType>
            initialValues={initialValues}
            validationSchema={GoogleDriveSyncOptionsModalSchema}
            onChange={onFormChange}
            onSubmit={handleOnSubmit}
          >
            {({ isSubmitting }) => (
              <>
                <GoogleDriveFieldset />
                <GoogleFolderFieldset />
                <Fieldset
                  title={
                    <I18n id='modal.googleDriveSyncOptions.fieldset.options' />
                  }
                >
                  <RadioGroup>
                    <RadioFormField
                      name='syncType'
                      value={FileStorageSyncTypes.TO_EXTERNAL_STORAGE}
                      label={t('modal.googleDriveSyncOptions.field.1way')}
                    />
                    <RadioFormField
                      name='syncType'
                      value={FileStorageSyncTypes.TWO_WAY_SYNC}
                      label={t('modal.googleDriveSyncOptions.field.2way')}
                    />
                  </RadioGroup>
                </Fieldset>
                <ButtonGroup>
                  <Button onClick={onConfirmClose}>
                    <I18n id='modal.googleDriveSyncOptions.button.cancel' />
                  </Button>
                  <Button skin='primary' type='submit' loading={isSubmitting}>
                    <I18n id='modal.googleDriveSyncOptions.button.sync' />
                  </Button>
                </ButtonGroup>
              </>
            )}
          </Form>
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
