import React from 'react';

import { AddressTypes } from '__generated__/globalTypes';
import { getContactAddress } from 'controllers/contact';

import { AddressPropsType } from './Address.types';
import styles from './Address.module.scss';

export default function Address({
  type = AddressTypes.PRIMARY,
  addresses,
}: AddressPropsType) {
  const address = addresses.find((address) => {
    return typeof address === 'string' || address.type === type;
  });

  return address ? (
    <p className={styles.address}>
      {typeof address === 'string' ? address : getContactAddress(address)}
    </p>
  ) : null;
}
