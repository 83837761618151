import { gql } from '@apollo/client';

import { ADDRESS_FRAGMENT } from 'components/common/Address';
import { LEDGER_NAME_FRAGMENT } from 'components/common/LedgerName';

export const FETCH_FIRM = gql`
  query FetchFirm {
    firm: getFirm {
      id
      name
      address {
        ...Address
      }
    }
  }
  ${ADDRESS_FRAGMENT}
`;

const DISBURSEMENT_CONTACT = gql`
  fragment DisbursementContact on ContactType {
    id
    name
    addresses {
      ...Address
    }
  }
  ${ADDRESS_FRAGMENT}
`;

const PAYMENT = gql`
  fragment DisbursementPayment on PaymentType {
    id
    paymentDate
    amount
    paymentMethod
    contact {
      ...DisbursementContact
    }
  }
  ${DISBURSEMENT_CONTACT}
`;

const DISBURSEMENT_CONTACT_MATTER = gql`
  fragment DisbursementContactMatter on ContactMatterType {
    ...LedgerName
    ... on ContactType {
      ...DisbursementContact
    }
    ... on MatterType {
      contact {
        ...DisbursementContact
      }
    }
    ... on ThirdPartyPayeeType {
      address {
        ...Address
      }
    }
  }
  ${DISBURSEMENT_CONTACT}
  ${LEDGER_NAME_FRAGMENT}
  ${ADDRESS_FRAGMENT}
`;

export const FETCH_DISBURSEMENT = gql`
  query FetchDisbursement($id: ID!) {
    disbursement: getDisbursement(id: $id) {
      id
      date
      type
      amount
      status
      paymentMethod
      memo
      skipped
      qboSyncState
      ledger {
        ...DisbursementContactMatter
      }
      payee {
        ...DisbursementContactMatter
      }
      check {
        number
        status
        createdAt
      }
      payment {
        ...DisbursementPayment
      }
      otoTransactions {
        id
        date
        type
        amount
        ledger {
          ...DisbursementContactMatter
        }
        payment {
          ...DisbursementPayment
        }
      }
    }
  }
  ${DISBURSEMENT_CONTACT_MATTER}
  ${PAYMENT}
`;
