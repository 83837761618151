import { Flex } from '@appclose/ui';
import { history, OverlayLoader } from '@appclose/core';
import { Modes } from '__generated__/globalTypes';

import { I18n } from 'i18n';
import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { ModeInfo } from 'components/common/Modes';
import useUpgradeMode from 'hooks/useUpgradeMode';
import { SETTINGS_PLAN_ROUTE } from 'constants/routes';
import { ModeUpgradeModalPropsType } from './ModeUpgradeModal.types';

export default function ModeUpgradeModal({
  onClose,
}: ModeUpgradeModalPropsType) {
  const [upgradeMode, { loading }] = useUpgradeMode({
    onCompleted: () => {
      history.push(SETTINGS_PLAN_ROUTE, { openMappingConfirmPopup: true });
      onClose();
    },
  });

  return (
    <ModalPage onClose={onClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.modeUpgrade.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        <OverlayLoader position="sticky" loading={loading}>
          <Flex direction="column" alignItems="center" justify="center">
            <ModeInfo mode={Modes.PLUS} onSelect={upgradeMode} />
          </Flex>
        </OverlayLoader>
      </ModalPageContent>
    </ModalPage>
  );
}
