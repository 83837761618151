import * as Yup from 'yup';

import { ContactMatterSelectContactType } from 'components/fields/ContactMatterSelectFormField';
import { SelectThirdPartyPayeeType } from 'components/fields/ThirdPartyPayeeSelectFormField';
import { getI18nErrorMessage } from 'i18n';
import { MEMO_PURPOSE_FILED_LENGTH_LIMIT } from 'constants/fieldsLimitations';

import { DisbursementToThirdPartyFormValuesType } from './DisbursementToThirdPartyForm.types';

export const DisbursementToThirdPartyFormSchema = Yup.object().shape<DisbursementToThirdPartyFormValuesType>(
  {
    contact: Yup.object<ContactMatterSelectContactType>().required(
      'Please select a contact / matter'
    ),
    thirdPartyPayee: Yup.object<SelectThirdPartyPayeeType>().required(
      getI18nErrorMessage(
        'modal.disbursement.form.thirdParty.error.thirdParty.required'
      )
    ),
    amount: Yup.number()
      .moreThan(
        0,
        getI18nErrorMessage(
          'modal.disbursement.form.thirdParty.error.amount.moreThan0'
        )
      )
      .required(
        getI18nErrorMessage(
          'modal.disbursement.form.thirdParty.error.amount.required'
        )
      ),
    date: Yup.string()
      .nullable()
      .required(
        getI18nErrorMessage(
          'modal.disbursement.form.thirdParty.error.date.required'
        )
      ),
    memo: Yup.string()
      .max(MEMO_PURPOSE_FILED_LENGTH_LIMIT, ({ max }) =>
        getI18nErrorMessage(
          'modal.disbursement.form.contact.error.memo.maxLength',
          { max }
        )
      )
      .required(
        getI18nErrorMessage(
          'modal.disbursement.form.thirdParty.error.memo.required'
        )
      ),
  }
);
