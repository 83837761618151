import React from 'react';
import { Button, ButtonGroup, Note, Text4 } from '@appclose/ui';
import { Form, InputFormField, FormGroup, Fieldset } from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import { PrintingChecksModes } from '__generated__/globalTypes';
import { PRINT_CHECKS_SAMPLE_PREVIEW_MODAL } from 'constants/modals';
import { openModal } from 'controllers/modal';
import { I18n, useIntl } from 'i18n';

import NotPrintedDisbursementsField from './components/NotPrintedDisbursementsField';
import PrintSettings from './components/PrintSettings';
import { PrintChecksFormSchema } from './PrintChecksForm.schema';
import { PrintCheckFormPropsType } from './PrintChecksForm.types';
import styles from './PrintChecksForm.module.scss';

export default function PrintChecksForm({
  initialValues,
  onSubmit,
  onCancel,
  onChange,
}: PrintCheckFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      onChange={onChange}
      validationSchema={PrintChecksFormSchema}
    >
      {({ isSubmitting, values }) => {
        const { mode } = values;
        const isQboMode = mode === PrintingChecksModes.QBO;

        return (
          <>
            <NotPrintedDisbursementsField isQboMode={isQboMode} />
            {!isQboMode && (
              <>
                <Fieldset title={t('modal.printChecks.form.title')}>
                  <FormGroup>
                    <FormGrid>
                      <InputFormField
                        type="number"
                        name="startCheckNumber"
                        label={t('modal.printChecks.form.startCheckNumber')}
                      />
                    </FormGrid>
                  </FormGroup>
                  <FormGroup>
                    <PrintSettings />
                  </FormGroup>
                </Fieldset>
                <Note theme="important" offset={{ bottom: 20 }}>
                  <I18n id="modal.printChecks.form.note.first.main" />
                  <Text4 opacity={0.6} offset={{ top: 8 }}>
                    <I18n id="modal.printChecks.form.note.first.additional" />
                  </Text4>
                </Note>
                <Note theme="important" offset={{ bottom: 60 }}>
                  <I18n
                    id="modal.printChecks.form.note.second"
                    values={{
                      printSample: (text: string) => (
                        <span
                          className={styles.sampleLink}
                          onClick={() => {
                            openModal(
                              PRINT_CHECKS_SAMPLE_PREVIEW_MODAL,
                              {
                                mode,
                              },
                              values
                            );
                          }}
                        >
                          {text}
                        </span>
                      ),
                    }}
                  />
                </Note>
              </>
            )}
            <ButtonGroup>
              <Button onClick={onCancel} disabled={isSubmitting}>
                <I18n id="modal.printChecks.form.cancel" />
              </Button>
              <Button skin="primary" type="submit" loading={isSubmitting}>
                {isQboMode
                  ? t('modal.printChecks.form.submit.qbo')
                  : t('modal.printChecks.form.submit.esq')}
              </Button>
            </ButtonGroup>
          </>
        );
      }}
    </Form>
  );
}
