import * as Yup from 'yup';
import { currency } from '@appclose/core';

import { getI18nErrorMessage } from 'i18n';
import { CreateMatterInput, MatterTypes } from '__generated__/globalTypes';
import {
  MATTER_AGREED_FLAT_FEE_MAX_AMOUNT,
  MATTER_AGREED_FLAT_FEE_MIN_AMOUNT,
} from 'constants/matter';

export const MATTER_NAME_SCHEMA = Yup.string()
  .required(getI18nErrorMessage('error.matter.name.required'))
  .max(128, ({ max }) => getI18nErrorMessage('error.matter.name.max', { max }));

export const MATTER_DATE_OPENED_SCHEMA = Yup.mixed<string>().required(
  getI18nErrorMessage('error.matter.dateOpened.required')
);

export const MATTER_BILLING_TYPE_SCHEMA = Yup.object().shape<
  Pick<CreateMatterInput, 'type' | 'agreedFlatFee' | 'billable'>
>({
  type: Yup.mixed<MatterTypes>().required(),
  billable: Yup.boolean().when('type', {
    is: MatterTypes.HYBRID,
    then: Yup.boolean().required(),
  }),
  agreedFlatFee: Yup.number().when('type', {
    is: MatterTypes.FLAT_FEE,
    then: Yup.number()
      .min(MATTER_AGREED_FLAT_FEE_MIN_AMOUNT, ({ min }) =>
        getI18nErrorMessage('error.matter.agreedFlatFee.min', {
          min: currency().format(min),
        })
      )
      .max(MATTER_AGREED_FLAT_FEE_MAX_AMOUNT, ({ max }) =>
        getI18nErrorMessage('error.matter.agreedFlatFee.max', {
          max: currency().format(max),
        })
      )
      .required(getI18nErrorMessage('error.matter.agreedFlatFee.required')),
    otherwise: Yup.number().nullable(),
  }),
});
