import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Avatar as UiAvatar } from '@appclose/ui';

import { AvatarPropsType } from './Avatar.types';
import { getContactInitials } from 'controllers/contact';

export default function Avatar({
  icon,
  firstName,
  lastName,
  ...rest
}: AvatarPropsType) {
  const [avatarIcon, setAvatarIcon] = useState(
    typeof icon === 'string' ? icon : icon?.thumbnail
  );

  useEffect(() => {
    setAvatarIcon(typeof icon === 'string' ? icon : icon?.thumbnail);
  }, [icon]);

  const onShowAvatarError = useCallback(() => {
    setAvatarIcon(typeof icon === 'string' ? icon : icon?.original);
  }, [icon]);

  const avatarData = useMemo(
    () => ({
      initials: getContactInitials({ firstName, lastName }),
      color: typeof icon !== 'string' ? icon?.color : undefined,
    }),
    [firstName, icon, lastName]
  );

  return (
    <UiAvatar
      {...rest}
      icon={avatarIcon}
      initials={avatarData.initials}
      color={avatarData.color}
      onShowIconError={onShowAvatarError}
    />
  );
}
