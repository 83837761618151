import React, { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { downloadFileByUrl } from '@appclose/lib';
import { traceError } from '@appclose/core';
import { DownloadIcon, EmailIcon, LinkIcon } from '@appclose/icons';

import { InvoiceStatusesTypes } from '__generated__/globalTypes';
import ActionsPanel, {
  ActionsPanelButton,
} from 'components/common/ActionsPanel';
import { PermissionsGuard } from 'components/common/PermissionsGuard';
import { PermissionGuard } from 'components/common/PermissionGuard';
import {
  SEND_INVOICE_MODAL,
  SEND_INVOICE_VOID_MODAL,
  INVOICE_LINK_MODAL,
} from 'constants/modals';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import { EventNames } from 'constants/analytics';
import { track } from 'controllers/analytics';
import { openModal } from 'controllers/modal';
import { useIntl } from 'i18n';

import {
  GetInvoicePdfQuery,
  GetInvoicePdfQueryVariables,
} from './__generated__/InvoiceActions.gql';
import { FETCH_INVOICE_PDF } from './InvoiceActions.gql';
import { INVOICE_ACTIONS_PERMISSIONS } from './InvoiceActions.constants';
import { InvoiceActionsPropsType } from './InvoiceActions.types';

export default function InvoiceActions({
  invoice: { id, status },
}: InvoiceActionsPropsType) {
  const { t } = useIntl();
  const [fetchInvoicePdf, { loading: loadingPdf }] = useLazyQuery<
    GetInvoicePdfQuery,
    GetInvoicePdfQueryVariables
  >(FETCH_INVOICE_PDF, {
    fetchPolicy: 'network-only',
    onCompleted({ pdf: { url } }) {
      downloadFileByUrl(url);
    },
  });

  const openInvoiceSendModal = useCallback(() => {
    if (status !== InvoiceStatusesTypes.VOIDED) {
      openModal(SEND_INVOICE_MODAL, { id });
    } else {
      openModal(SEND_INVOICE_VOID_MODAL, { id });
    }
  }, [id, status]);
  const openInvoiceLinkModal = useCallback(() => {
    openModal(INVOICE_LINK_MODAL, { id, openInvoice: true });
  }, [id]);
  const handleOnDownloadPdf = useCallback(async () => {
    try {
      track(EventNames.INVOICE_MANUALLY_SENT, {
        uuid_invoice: id,
        method: 'pdf',
      });

      await fetchInvoicePdf({
        variables: { id },
      });
    } catch (e) {
      traceError(e);
    }
  }, [fetchInvoicePdf, id]);

  return (
    <PermissionsGuard allow={INVOICE_ACTIONS_PERMISSIONS}>
      <ActionsPanel title={t('modal.invoiceDetails.actions.title')}>
        <PermissionGuard
          resource={PermissionResources.INVOICE}
          action={PermissionActions.SEND}
        >
          <>
            <ActionsPanelButton
              onClick={openInvoiceSendModal}
              skin="primary"
              icon={<EmailIcon />}
              title={t('modal.invoiceDetails.actions.sendByEmail')}
            />
            <ActionsPanelButton
              onClick={openInvoiceLinkModal}
              skin="success"
              icon={<LinkIcon />}
              title={t('modal.invoiceDetails.actions.generateLink')}
            />
          </>
        </PermissionGuard>
        <PermissionGuard
          resource={PermissionResources.INVOICE}
          action={PermissionActions.READ}
        >
          <ActionsPanelButton
            onClick={handleOnDownloadPdf}
            skin="secondary"
            icon={<DownloadIcon />}
            title={t('modal.invoiceDetails.actions.downloadPdf')}
            loading={loadingPdf}
          />
        </PermissionGuard>
      </ActionsPanel>
    </PermissionsGuard>
  );
}
