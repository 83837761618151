import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import classnames from 'classnames';
import { Tab, TabList, TabPanel, Tabs, traceError } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import hellosign from 'controllers/hellosign';
import { HelloSignEvents } from 'classes/HelloSign';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { I18n, useIntl } from 'i18n';

import { SignatureDocumentModalPropsType } from './SignatureDocumentModal.types';
import SignatureDocumentFromFile from './components/SignatureDocumentFromFile';
import {
  CANCEL_SIGNATURE,
  FINISH_SIGNATURE,
} from './SignatureDocumentModal.gql';
import styles from './SignatureDocumentModal.module.scss';
import {
  CancelSignatureMutation,
  CancelSignatureMutationVariables,
  FinishSignatureMutation,
  FinishSignatureMutationVariables,
} from './__generated__/SignatureDocumentModal.gql';
import { openCancelConfirmAction } from 'store/modules/common/actions';

export default function SignatureDocumentModal({
  initialValuesFromFile,
  templateId,
  onClose,
}: SignatureDocumentModalPropsType) {
  const { t } = useIntl();
  const dispatch = useDispatch();
  const [signatureId, setSignatureId] = useState<string | null>(null);
  const [cancelSignature] = useMutation<
    CancelSignatureMutation,
    CancelSignatureMutationVariables
  >(CANCEL_SIGNATURE);
  const [finishSignature] = useMutation<
    FinishSignatureMutation,
    FinishSignatureMutationVariables
  >(FINISH_SIGNATURE);

  const {
    showConfirm,
    setShowConfirm,
    onConfirmClose,
    onFormChange,
  } = useCloseConfirm({
    onClose,
  });

  const onSignatureCreate = useCallback((id: string) => {
    setSignatureId(id);
  }, []);

  const onCancelSignature = useCallback(async () => {
    onClose();

    notification().error(
      new Error(t('modal.signatureDocument.notification.cancelled'))
    );

    if (signatureId) {
      try {
        await cancelSignature({ variables: { id: signatureId } });
      } catch (e) {
        traceError(e);
      }
    }
  }, [onClose, t, signatureId, cancelSignature]);

  const onFinishSignature = useCallback(async () => {
    onClose();

    notification().entityCreated(
      t('modal.signatureDocument.notification.created')
    );

    if (signatureId) {
      try {
        await finishSignature({ variables: { id: signatureId } });
      } catch (e) {
        traceError(e);
      }
    }
  }, [onClose, t, signatureId, finishSignature]);

  const beforeTabIndexChange = useCallback(
    (changeTabIndex) => {
      if (showConfirm) {
        dispatch(
          openCancelConfirmAction({
            onClose: () => {
              changeTabIndex();
              setShowConfirm(false);
            },
          })
        );
      } else {
        changeTabIndex();
      }
    },
    [dispatch, showConfirm, setShowConfirm]
  );

  useEffect(() => {
    hellosign().on(HelloSignEvents.CLOSE, onCancelSignature);
    hellosign().on(HelloSignEvents.FINISH, onFinishSignature);

    return () => {
      hellosign().off(HelloSignEvents.CLOSE, onCancelSignature);
      hellosign().off(HelloSignEvents.FINISH, onFinishSignature);
    };
  }, [onCancelSignature, onFinishSignature]);

  return (
    <ModalPage
      className={classnames({
        [styles.hidden]: signatureId,
      })}
      onClose={onConfirmClose}
    >
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.signatureDocument.create.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        <Tabs
          defaultIndex={templateId ? 1 : 0}
          beforeIndexChange={beforeTabIndexChange}
        >
          <TabList>
            <Tab>
              <I18n id="modal.signatureDocument.tabs.fromFile" />
            </Tab>
          </TabList>
          <TabPanel>
            <SignatureDocumentFromFile
              initialValues={initialValuesFromFile}
              onCancel={onConfirmClose}
              onCreate={onSignatureCreate}
              onChange={onFormChange}
            />
          </TabPanel>
        </Tabs>
      </ModalPageContent>
    </ModalPage>
  );
}
