export enum PreviewTypes {
  CHECK,
  DEPOSIT_SLIP,
}

export type OffsetType = [number, number];

export type BoundType = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

export interface PreviewPropsType {
  type: PreviewTypes;
  offset: OffsetType;
  onChange(offset: OffsetType): void;
}
