import { useLocalStorage } from '@appclose/core';
import snakeCase from 'lodash/snakeCase';
import uniq from 'lodash/uniq';

import { LAST_USAGE_KEY } from 'constants/localStorage';
import { useCallback } from 'react';

const MAX_SAVED_ENTRIES_COUNT = 3;

export default function useLastUsage(
  entity:
    | 'timeEntryActivity'
    | 'expenseSoftCategory'
    | 'expenseHardCategory'
    | 'thirdPartyPayee'
) {
  const [lastUsages, setValue] = useLocalStorage<string[]>(
    `${LAST_USAGE_KEY}_${snakeCase(entity).toUpperCase()}`,
    []
  );

  const setLastUsage = useCallback(
    (id: string | null | undefined) => {
      if (!id) {
        return;
      }

      setValue((current) => {
        return uniq([id, ...(current || [])]).slice(0, MAX_SAVED_ENTRIES_COUNT);
      });
    },
    [setValue]
  );

  return { lastUsages, setLastUsage };
}
