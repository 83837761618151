import noop from 'lodash/noop';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { StripeKybStatus } from '__generated__/globalTypes';

import useFirm from 'hooks/useFirm';
import useProfile from 'hooks/useProfile';
import { openLimitedAccessConfirm, openWaitForKybConfirm } from 'store/actions';

export default function useAccessCheck() {
  const dispatch = useDispatch();
  const { isOwner } = useProfile();
  const { data: firmData } = useFirm();

  const withLimitedAccessCheck = useCallback((
    callback: undefined | ((...args: any) => void),
    options?: {
      checks?: string[]
    }
  ) => {
    const { checks = ['limitedAccess', 'kybPending'] } = options || {};
  
    if (checks.includes('limitedAccess') && !firmData.stripeKybStatus) {
      return () => dispatch(openLimitedAccessConfirm(isOwner));
    } else if (
      checks.includes('kybPending') && (
        firmData.stripeKybStatus === StripeKybStatus.SENT
        || firmData.stripeKybStatus === StripeKybStatus.REJECTED
      )
    ) {
      return () => dispatch(openWaitForKybConfirm());
    }

    return callback ? callback : noop;
  }, [dispatch, firmData, isOwner]);

  return {
    withLimitedAccessCheck,
  };
}
