import { gql } from '@apollo/client';

export const CALCULATE_SUMMARY = gql`
  query CalculateSummary($input: CalculateReconciliationInput!) {
    summary: calculateReconciliation(calculateReconciliationInput: $input) {
      beginningBalance
      clearedDeposits
      clearedDisbursements
      clearedBalance
      endingBalance
      clearedDifference
      unclearedDeposits
      unclearedDisbursements
      adjustedBalance
      transactionLedgerBalance
      allContactLedgersBalance
      balanceDifference
      canBeCompleted
    }
  }
`;
