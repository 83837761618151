import { Tour, Step, ShepherdOptionsWithType } from 'react-shepherd';
import { scrollIntoView } from '@appclose/lib';

import { Modes } from '__generated__/globalTypes';
import {
  BILLING_INVOICES_ROUTE,
  DASHBOARD_ROUTE,
  MATTERS_ROUTE,
  SETTINGS_ROUTE,
  TRUST_TRANSACTION_LEDGER_ROUTE,
} from 'constants/routes';

import { ButtonType, StepsType, TOUR_STEPS_NAME, TourType } from './Tour.types';
import { getTourStepSelector } from './Tour.utils';

const BACK_BUTTON: ButtonType = (t) => ({
  text: t('tour.button.back'),
  type: 'back',
});

const NEXT_BUTTON: ButtonType = (t) => ({
  text: t('tour.button.next'),
  type: 'next',
  secondary: true,
});

const FINISH_BUTTON: ButtonType = (t) => ({
  text: t('tour.button.finish'),
  type: 'next',
  secondary: true,
});

const scrollToElement = async (elementSelector: string) => {
  const element: HTMLElement | null = document.querySelector(elementSelector);

  if (!element) {
    return;
  }

  scrollIntoView(element);
};

function appendProgressToStep(step: Step) {
  const { steps } = step.getTour();
  const stepElement = step.getElement();
  const currentStepIndex = steps.indexOf(step) + 1;
  const progressElement = document.createElement('p');

  progressElement.className = 'shepherd-progress';
  progressElement.textContent = `${currentStepIndex} of ${steps.length}`;

  stepElement?.prepend(progressElement);
}

const mouseUpHandler = (e: MouseEvent) => {
  e.stopImmediatePropagation();
};

export const TOUR_OPTIONS: Tour.TourOptions = {
  useModalOverlay: true,
  defaultStepOptions: {
    when: {
      show() {
        appendProgressToStep(this);
      },
    },
    cancelIcon: { enabled: true },
    canClickTarget: false,
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 25],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            padding: 10,
          },
        },
        {
          name: 'arrow',
          options: {
            padding: 20,
          },
        },
      ],
    },
  },
};

const BILLING_INVOICES_ROUTE_STEPS: StepsType = ({ t, isMobile }) => [
  {
    title: t('tour.billingInvoices.menu.title'),
    text: t('tour.billingInvoices.menu.text'),
    attachTo: {
      element: getTourStepSelector(TOUR_STEPS_NAME.MENU),
      on: 'bottom',
    },
    buttons: [NEXT_BUTTON(t)],
  },
  ...(isMobile
    ? []
    : [
        {
          title: t('tour.billingInvoices.contextMenu.title'),
          text: t('tour.billingInvoices.contextMenu.text'),
          attachTo: {
            element: `${getTourStepSelector(TOUR_STEPS_NAME.CONTEXT_MENU)}`,
            on: 'left',
          },
          buttons: [BACK_BUTTON(t), NEXT_BUTTON(t)],
        } as ShepherdOptionsWithType,
      ]),
  {
    title: t('tour.billingInvoices.invoiceStatus.title'),
    text: t('tour.billingInvoices.invoiceStatus.text'),
    attachTo: {
      element: getTourStepSelector(TOUR_STEPS_NAME.INVOICE_STATUS),
      on: isMobile ? 'top' : 'left',
    },
    buttons: [BACK_BUTTON(t), NEXT_BUTTON(t)],
  },
  {
    title: t('tour.billingInvoices.filter.title'),
    text: t('tour.billingInvoices.filter.text'),
    attachTo: {
      element: getTourStepSelector(TOUR_STEPS_NAME.FILTER),
      on: 'top',
    },
    buttons: [BACK_BUTTON(t), FINISH_BUTTON(t)],
  },
];

export const TOURS: Record<Modes, Record<string, TourType>> = {
  [Modes.FREE]: {
    [DASHBOARD_ROUTE]: {
      exact: true,
      steps: ({ t, setMenuOpen, isMobile }) => [
        {
          title: t('tour.dashboard.switchMode.title'),
          text: t('tour.dashboard.switchMode.text'),
          attachTo: {
            element: getTourStepSelector(TOUR_STEPS_NAME.DEMO_MODE_SWITCH),
            on: 'bottom',
          },
          buttons: [NEXT_BUTTON(t)],
        },
        {
          title: t('tour.dashboard.receivePayment.title'),
          text: t('tour.dashboard.receivePayment.text'),
          attachTo: {
            element: getTourStepSelector(
              TOUR_STEPS_NAME.RECEIVE_PAYMENT_BUTTON
            ),
            on: 'bottom',
          },
          buttons: [BACK_BUTTON(t), NEXT_BUTTON(t)],
        },
        {
          title: t('tour.dashboard.addNew.free.title'),
          text: t('tour.dashboard.addNew.free.text'),
          attachTo: {
            element: getTourStepSelector(TOUR_STEPS_NAME.ADD_NEW),
            on: 'bottom',
          },
          buttons: [BACK_BUTTON(t), NEXT_BUTTON(t)],
        },
        {
          title: t('tour.dashboard.mainMenu.title'),
          text: t('tour.dashboard.mainMenu.text'),
          attachTo: {
            element: isMobile
              ? getTourStepSelector(TOUR_STEPS_NAME.MAIN_MENU_BUTTON)
              : getTourStepSelector(TOUR_STEPS_NAME.MAIN_MENU),
            on: isMobile ? 'bottom' : 'left',
          },
          buttons: [BACK_BUTTON(t), NEXT_BUTTON(t)],
        },
        {
          title: t('tour.dashboard.dashboardStatistic.title'),
          text: t('tour.dashboard.dashboardStatistic.free.text'),
          attachTo: {
            element: getTourStepSelector(TOUR_STEPS_NAME.DASHBOARD_STATISTIC),
            on: 'top',
          },
          buttons: [BACK_BUTTON(t), NEXT_BUTTON(t)],
        },
        {
          title: t('tour.dashboard.mainMenu.settings.free.title'),
          text: t('tour.dashboard.mainMenu.settings.free.text'),
          attachTo: {
            element: getTourStepSelector(TOUR_STEPS_NAME.MENU_SETTINGS),
            on: 'top',
          },
          buttons: [BACK_BUTTON(t), FINISH_BUTTON(t)],
          beforeShowPromise: async () => {
            setMenuOpen(true);
          },
          when: {
            show() {
              appendProgressToStep(this);
              document.addEventListener('mouseup', mouseUpHandler, true);
            },
            destroy() {
              document.removeEventListener('mouseup', mouseUpHandler, true);
            },
          },
        },
      ],
    },
    [BILLING_INVOICES_ROUTE]: {
      exact: true,
      steps: BILLING_INVOICES_ROUTE_STEPS,
    },
    [SETTINGS_ROUTE]: {
      exact: false,
      steps: ({ t, isMobile }) => [
        {
          title: t('tour.settings.menu.teamMembers.title'),
          text: t('tour.settings.menu.teamMembers.text'),
          attachTo: {
            element: `${getTourStepSelector(
              TOUR_STEPS_NAME.MENU
            )} li:nth-of-type(2)`,
            on: 'top',
          },
          buttons: [NEXT_BUTTON(t)],
          beforeShowPromise: async () =>
            scrollToElement(
              `${getTourStepSelector(TOUR_STEPS_NAME.MENU)} li:nth-of-type(2)`
            ),
        },
        {
          title: t('tour.settings.menu.quickBook.title'),
          text: t('tour.settings.menu.quickBook.text'),
          attachTo: {
            element: `${getTourStepSelector(
              TOUR_STEPS_NAME.MENU
            )} li:nth-of-type(4)`,
            on: isMobile ? 'top' : 'right',
          },
          buttons: [BACK_BUTTON(t), NEXT_BUTTON(t)],
          beforeShowPromise: async () =>
            scrollToElement(
              `${getTourStepSelector(TOUR_STEPS_NAME.MENU)} li:nth-of-type(4)`
            ),
        },
        {
          title: t('tour.settings.menu.accounts.title'),
          text: t('tour.settings.menu.accounts.free.text'),
          attachTo: {
            element: `${getTourStepSelector(
              TOUR_STEPS_NAME.MENU
            )} li:nth-of-type(3)`,
            on: 'bottom',
          },
          buttons: [BACK_BUTTON(t), FINISH_BUTTON(t)],
          beforeShowPromise: async () =>
            scrollToElement(
              `${getTourStepSelector(TOUR_STEPS_NAME.MENU)} li:nth-of-type(3)`
            ),
        },
      ],
    },
  },
  [Modes.PLUS]: {
    [DASHBOARD_ROUTE]: {
      exact: true,
      steps: ({ t, isMobile, setMenuOpen }) => [
        {
          title: t('tour.dashboard.switchMode.title'),
          text: t('tour.dashboard.switchMode.text'),
          attachTo: {
            element: getTourStepSelector(TOUR_STEPS_NAME.DEMO_MODE_SWITCH),
            on: 'bottom',
          },
          buttons: [NEXT_BUTTON(t)],
        },
        {
          title: t('tour.dashboard.receivePayment.title'),
          text: t('tour.dashboard.receivePayment.text'),
          attachTo: {
            element: getTourStepSelector(
              TOUR_STEPS_NAME.RECEIVE_PAYMENT_BUTTON
            ),
            on: 'bottom',
          },
          buttons: [BACK_BUTTON(t), NEXT_BUTTON(t)],
        },
        {
          title: t('tour.dashboard.addNew.plus.title'),
          text: t('tour.dashboard.addNew.plus.text'),
          attachTo: {
            element: getTourStepSelector(TOUR_STEPS_NAME.ADD_NEW),
            on: 'bottom',
          },
          buttons: [BACK_BUTTON(t), NEXT_BUTTON(t)],
        },
        {
          title: t('tour.dashboard.mainMenu.title'),
          text: t('tour.dashboard.mainMenu.text'),
          attachTo: {
            element: isMobile
              ? getTourStepSelector(TOUR_STEPS_NAME.MAIN_MENU_BUTTON)
              : getTourStepSelector(TOUR_STEPS_NAME.MAIN_MENU),
            on: isMobile ? 'bottom' : 'left',
          },
          buttons: [BACK_BUTTON(t), NEXT_BUTTON(t)],
        },
        {
          title: t('tour.dashboard.dashboardStatistic.title'),
          text: t('tour.dashboard.dashboardStatistic.plus.text'),
          attachTo: {
            element: getTourStepSelector(TOUR_STEPS_NAME.DASHBOARD_STATISTIC),
            on: 'top',
          },
          buttons: [BACK_BUTTON(t), NEXT_BUTTON(t)],
        },
        {
          title: t('tour.dashboard.timeTracker.title'),
          text: t('tour.dashboard.timeTracker.text'),
          attachTo: {
            element: getTourStepSelector(TOUR_STEPS_NAME.TIME_TRACKER),
            on: 'bottom',
          },
          buttons: [BACK_BUTTON(t), NEXT_BUTTON(t)],
        },
        {
          title: t('tour.dashboard.privacy.title'),
          text: t('tour.dashboard.privacy.text'),
          attachTo: {
            element: getTourStepSelector(TOUR_STEPS_NAME.PRIVACY),
            on: isMobile ? 'bottom' : 'left',
          },
          buttons: [BACK_BUTTON(t), NEXT_BUTTON(t)],
          beforeShowPromise: async () => {
            setMenuOpen(true);
          },
          when: {
            show() {
              appendProgressToStep(this);
              document.addEventListener('mouseup', mouseUpHandler, true);
            },
            destroy() {
              document.removeEventListener('mouseup', mouseUpHandler, true);
            },
          },
        },
        {
          title: t('tour.dashboard.mainMenu.settings.plus.title'),
          text: t('tour.dashboard.mainMenu.settings.plus.text'),
          attachTo: {
            element: getTourStepSelector(TOUR_STEPS_NAME.MENU_SETTINGS),
            on: 'top',
          },
          buttons: [BACK_BUTTON(t), FINISH_BUTTON(t)],
          beforeShowPromise: async () => {
            setMenuOpen(true);

            await scrollToElement(
              getTourStepSelector(TOUR_STEPS_NAME.MENU_SETTINGS)
            );
          },
          when: {
            show() {
              appendProgressToStep(this);
              document.addEventListener('mouseup', mouseUpHandler, true);
            },
            destroy() {
              document.removeEventListener('mouseup', mouseUpHandler, true);
            },
          },
        },
      ],
    },
    [MATTERS_ROUTE]: {
      exact: true,
      steps: ({ t, isMobile }) => [
        {
          title: t('tour.matters.matter.title'),
          text: t('tour.matters.matter.text'),
          attachTo: {
            element: `${getTourStepSelector(
              TOUR_STEPS_NAME.MATTER
            )}:first-child`,
            on: isMobile ? 'bottom' : 'right',
          },
          buttons: [NEXT_BUTTON(t)],
        },
        {
          title: t('tour.matters.filter.title'),
          text: t('tour.matters.filter.text'),
          attachTo: {
            element: getTourStepSelector(TOUR_STEPS_NAME.FILTER),
            on: 'bottom',
          },
          buttons: [BACK_BUTTON(t), NEXT_BUTTON(t)],
        },
        {
          title: t('tour.matters.viewMode.title'),
          text: t('tour.matters.viewMode.text'),
          attachTo: {
            element: getTourStepSelector(TOUR_STEPS_NAME.VIEW_MODE),
            on: 'bottom',
          },
          buttons: [BACK_BUTTON(t), FINISH_BUTTON(t)],
          modalOverlayOpeningPadding: 5,
        },
      ],
    },
    [TRUST_TRANSACTION_LEDGER_ROUTE]: {
      exact: true,
      steps: ({ t, isMobile }) => [
        {
          title: t('tour.trustTransactionLedger.trustAccountStatistic.title'),
          text: t('tour.trustTransactionLedger.trustAccountStatistic.text'),
          attachTo: {
            element: getTourStepSelector(
              TOUR_STEPS_NAME.TRUST_ACCOUNT_STATISTIC
            ),
            on: 'bottom',
          },
          buttons: [NEXT_BUTTON(t)],
        },
        {
          title: t('tour.trustTransactionLedger.menu.title'),
          text: t('tour.trustTransactionLedger.menu.text'),
          attachTo: {
            element: getTourStepSelector(TOUR_STEPS_NAME.MENU),
            on: 'top',
          },
          buttons: [BACK_BUTTON(t), NEXT_BUTTON(t)],
        },
        {
          title: t('tour.trustTransactionLedger.menu.contactLedgers.title'),
          text: t('tour.trustTransactionLedger.menu.contactLedgers.text'),
          attachTo: {
            element: `${getTourStepSelector(
              TOUR_STEPS_NAME.MENU
            )} li:last-child`,
            on: 'bottom',
          },
          buttons: [
            BACK_BUTTON(t),
            isMobile ? FINISH_BUTTON(t) : NEXT_BUTTON(t),
          ],
          beforeShowPromise: async () =>
            scrollToElement(
              `${getTourStepSelector(TOUR_STEPS_NAME.MENU)} li:last-child`
            ),
        },
        ...(isMobile
          ? []
          : [
              {
                title: t('tour.trustTransactionLedger.contextMenu.title'),
                text: t('tour.trustTransactionLedger.contextMenu.text'),
                attachTo: {
                  element: `${getTourStepSelector(
                    TOUR_STEPS_NAME.CONTEXT_MENU
                  )}`,
                  on: 'left',
                },
                buttons: [BACK_BUTTON(t), FINISH_BUTTON(t)],
              } as ShepherdOptionsWithType,
            ]),
      ],
    },
    [BILLING_INVOICES_ROUTE]: {
      exact: true,
      steps: BILLING_INVOICES_ROUTE_STEPS,
    },
    [SETTINGS_ROUTE]: {
      exact: false,
      steps: ({ t, isMobile }) => [
        {
          title: t('tour.settings.menu.teamMembers.title'),
          text: t('tour.settings.menu.teamMembers.text'),
          attachTo: {
            element: `${getTourStepSelector(
              TOUR_STEPS_NAME.MENU
            )} li:nth-of-type(2)`,
            on: 'top',
          },
          buttons: [NEXT_BUTTON(t)],
          beforeShowPromise: async () =>
            scrollToElement(
              `${getTourStepSelector(TOUR_STEPS_NAME.MENU)} li:nth-of-type(2)`
            ),
        },
        {
          title: t('tour.settings.menu.quickBook.title'),
          text: t('tour.settings.menu.quickBook.text'),
          attachTo: {
            element: `${getTourStepSelector(
              TOUR_STEPS_NAME.MENU
            )} li:nth-of-type(4)`,
            on: isMobile ? 'top' : 'right',
          },
          buttons: [BACK_BUTTON(t), NEXT_BUTTON(t)],
          beforeShowPromise: async () =>
            scrollToElement(
              `${getTourStepSelector(TOUR_STEPS_NAME.MENU)} li:nth-of-type(4)`
            ),
        },
        {
          title: t('tour.settings.menu.accounts.title'),
          text: t('tour.settings.menu.accounts.plus.text'),
          attachTo: {
            element: `${getTourStepSelector(
              TOUR_STEPS_NAME.MENU
            )} li:nth-of-type(3)`,
            on: 'bottom',
          },
          buttons: [BACK_BUTTON(t), FINISH_BUTTON(t)],

          beforeShowPromise: async () =>
            scrollToElement(
              `${getTourStepSelector(TOUR_STEPS_NAME.MENU)} li:nth-of-type(3)`
            ),
        },
      ],
    },
  },
};
