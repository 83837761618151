import React, { ChangeEvent, useMemo } from 'react';
import { Checkbox, TableCell, TableHead, TableRow } from '@appclose/ui';
import { useFormContext, useIsMobileDevice } from '@appclose/core';

import { I18n } from 'i18n';

import { InvoiceFormValuesType } from '../../../../../../InvoiceForm.types';
import { ExpensesFieldTableHeaderPropsType } from './ExpensesFieldTableHeader.types';

export default function ExpensesFieldTableHeader({
  expenses,
}: ExpensesFieldTableHeaderPropsType) {
  const isMobile = useIsMobileDevice();
  const { values, setFieldValue } = useFormContext<InvoiceFormValuesType>();

  const selectedAll = useMemo(
    () =>
      (expenses?.length &&
        expenses.every(({ id }) =>
          values.expenses?.some(({ id: valuesId }) => valuesId === id)
        )) ||
      false,
    [expenses, values.expenses]
  );

  const onSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('expenses', e.target.checked ? expenses : [], true);
  };

  return (
    <TableHead theme="medium">
      <TableRow>
        <TableCell>
          <Checkbox checked={selectedAll} onChange={onSelectAll} />
        </TableCell>
        {!isMobile && <TableCell />}
        <TableCell>
          <I18n id="modal.invoice.form.expenses.table.expense" />
        </TableCell>
        {!isMobile && (
          <TableCell>
            <I18n id="modal.invoice.form.expenses.table.matter" />
          </TableCell>
        )}
        <TableCell>
          <I18n id="modal.invoice.form.expenses.table.billedBy" />
        </TableCell>
        {!isMobile && (
          <TableCell>
            <I18n id="modal.invoice.form.expenses.table.status" />
          </TableCell>
        )}
        <TableCell align="right">
          <I18n id="modal.invoice.form.expenses.table.amount" />
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
