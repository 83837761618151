import React, { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { Loader } from '@appclose/core';
import { DownloadIcon, PrintIcon } from '@appclose/icons';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import ActionsPanel, {
  ActionsPanelButton,
} from 'components/common/ActionsPanel';
import { PermissionsGuard } from 'components/common/PermissionsGuard';
import { PermissionGuard } from 'components/common/PermissionGuard';
import useDepositActions from 'hooks/useDepositActions';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import { openModal } from 'controllers/modal';
import { isDepositPrintable } from 'controllers/deposit';
import { PRINT_DEPOSITS_MODAL } from 'constants/modals';
import { I18n, useIntl } from 'i18n';

import { DepositDetailsModalPropsType } from './DepositDetailsModal.types';
import { DEPOSIT_ACTIONS_PERMISSIONS } from './DepositDetailsModal.constants';
import { FETCH_DEPOSIT, FETCH_FIRM } from './DepositDetailsModal.gql';
import {
  FetchDepositQuery,
  FetchDepositQueryVariables,
  FetchFirmQuery,
  FetchFirmQueryVariables,
} from './__generated__/DepositDetailsModal.gql';
import CustomDepositDetails from './components/CustomDepositDetails';
import AutomaticDepositDetails from './components/AutomaticDepositDetails';

export default function DepositDetailsModal({
  id,
  onClose,
}: DepositDetailsModalPropsType) {
  const { t } = useIntl();
  const { downloadDepositPdf, downloadDepositPdfLoading } = useDepositActions({
    id,
  });
  const { loading: loadingFirm, data: firmData } = useQuery<
    FetchFirmQuery,
    FetchFirmQueryVariables
  >(FETCH_FIRM);
  const { loading: loadingDeposit, data: depositData } = useQuery<
    FetchDepositQuery,
    FetchDepositQueryVariables
  >(FETCH_DEPOSIT, { variables: { id } });
  const loading = loadingDeposit || loadingFirm;
  const firm = firmData?.firm as FetchFirmQuery['firm'];
  const deposit = depositData?.deposit as FetchDepositQuery['deposit'];

  const openPrintDepositSlip = useCallback(
    () =>
      openModal(PRINT_DEPOSITS_MODAL, {
        id: deposit?.depositTransactions
          ? deposit?.depositTransactions.map(({ id }) => id)
          : id,
      }),
    [deposit?.depositTransactions, id]
  );

  return (
    <ModalPage onClose={onClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.depositDetails.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading ? (
          <Loader />
        ) : (
          <>
            <PermissionsGuard allow={DEPOSIT_ACTIONS_PERMISSIONS}>
              <ActionsPanel>
                <PermissionGuard
                  resource={PermissionResources.DEPOSIT}
                  action={PermissionActions.READ}
                >
                  <ActionsPanelButton
                    skin="secondary"
                    icon={<DownloadIcon />}
                    title={t('modal.depositDetails.actions.downloadPdf')}
                    onClick={downloadDepositPdf}
                    loading={downloadDepositPdfLoading}
                  />
                </PermissionGuard>
                {isDepositPrintable(deposit) && (
                  <PermissionGuard
                    resource={PermissionResources.DEPOSIT}
                    action={PermissionActions.REPORT}
                  >
                    <ActionsPanelButton
                      skin="primary"
                      icon={<PrintIcon />}
                      title={t('modal.depositDetails.actions.depositSlip')}
                      onClick={openPrintDepositSlip}
                    />
                  </PermissionGuard>
                )}
              </ActionsPanel>
            </PermissionsGuard>
            {deposit.systemTransaction ? (
              <AutomaticDepositDetails deposit={deposit} firm={firm} />
            ) : (
              <CustomDepositDetails deposit={deposit} firm={firm} />
            )}
          </>
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
