import { useIntl } from 'i18n';
import { MatterTeamMemberRoles } from '__generated__/globalTypes';
import { useCallback, useMemo } from 'react';

export default function useMatterTeamMemberRoles() {
  const { t } = useIntl();
  const matterTeamMemberRoles: Record<MatterTeamMemberRoles, string> = useMemo(
    () => ({
      [MatterTeamMemberRoles.ATTORNEY]: t(
        'enum.matterTeamMemberRoles.attorney'
      ),
      [MatterTeamMemberRoles.BILLABLE_ADMIN]: t(
        'enum.matterTeamMemberRoles.billableAdmin'
      ),
      [MatterTeamMemberRoles.LEAD_ATTORNEY]: t(
        'enum.matterTeamMemberRoles.leadAttorney'
      ),
      [MatterTeamMemberRoles.PARALEGAL]: t(
        'enum.matterTeamMemberRoles.paralegal'
      ),
    }),
    [t]
  );

  const matterTeamMemberRolesOptions: {
    label: string;
    value: MatterTeamMemberRoles;
  }[] = useMemo(
    () =>
      Object.entries(matterTeamMemberRoles).map(([value, label]) => ({
        value: value as MatterTeamMemberRoles,
        label,
      })),
    [matterTeamMemberRoles]
  );

  const getMatterTeamMemberRoleLabel = useCallback(
    (status?: MatterTeamMemberRoles | null) => {
      if (!status) {
        return '';
      }

      return matterTeamMemberRoles[status];
    },
    [matterTeamMemberRoles]
  );

  return { matterTeamMemberRolesOptions, getMatterTeamMemberRoleLabel };
}
