import { gql } from '@apollo/client';

export const FETCH_PROFILE = gql`
  query FetchProfile {
    profile: getProfile {
      id
      mode
    }
  }
`;
