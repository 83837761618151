export enum Entities {
  CONTACT = 'CONTACT',
  LEAD = 'LEAD',
  MATTER = 'MATTER',
  INVOICE = 'INVOICE',
  EXPENSE = 'EXPENSE',
  PAYMENT = 'PAYMENT',
  VOID_INVOICE = 'VOID_INVOICE',
  TRUST_TRANSFER = 'TRUST_TRANSFER',
  RECONCILIATION = 'RECONCILIATION',
  SIGNATURE = 'SIGNATURE',
  SIGNATURE_TEMPLATE = 'SIGNATURE_TEMPLATE',
}
