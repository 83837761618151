import React, { useMemo } from 'react';
import { SelectFormField } from '@appclose/core';

import useMatterTeamMemberRoles from 'hooks/useMatterTeamMemberRoles';
import { useIntl } from 'i18n';

import { MatterTeamMemberRoles } from '__generated__/globalTypes';
import { RoleSelectFormFieldPropsType } from './RoleSelectFormField.types';

export default function RoleSelectFormField({
  name = 'role',
  label,
  forMatter = false,
  allowLeadAttorney = true,
  ...props
}: RoleSelectFormFieldPropsType) {
  const { t } = useIntl();
  const { matterTeamMemberRolesOptions } = useMatterTeamMemberRoles();

  const options = useMemo(() => {
    if (!forMatter || !allowLeadAttorney) {
      return matterTeamMemberRolesOptions.filter(
        ({ value }) => value !== MatterTeamMemberRoles.LEAD_ATTORNEY
      );
    }

    return matterTeamMemberRolesOptions;
  }, [forMatter, allowLeadAttorney, matterTeamMemberRolesOptions]);

  return (
    <SelectFormField
      name={name}
      label={label || t('field.roleSelect.label')}
      {...props}
      options={options}
    />
  );
}
