import { ContactMatterSelectFormFieldOptionValueType } from 'components/fields/ContactMatterSelectFormField';

export enum ContactMatterPopupModalTabs {
  CONTACT,
  MATTER,
}

export type ContactMatterPopupModalPropsType = {
  onComplete(contact: ContactMatterSelectFormFieldOptionValueType): void;
  onCancel(): void;
};
