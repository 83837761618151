import React from 'react';
import { Button, ButtonGroup } from '@appclose/ui';
import { Form } from '@appclose/core';

import ProfileFieldset from 'components/form/ProfileFieldset';
import { I18n } from 'i18n';

import { ProfileFormPropsType } from './ProfileForm.types';
import { ProfileFormSchema } from './ProfileForm.schema';

export default function ProfileForm({
  initialValues,
  onSubmit,
  onCancel,
  onChange,
}: ProfileFormPropsType) {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      onChange={onChange}
      validationSchema={ProfileFormSchema}
    >
      {(form) => (
        <>
          <ProfileFieldset {...form} />
          <ButtonGroup>
            {onCancel && (
              <Button onClick={onCancel}>
                <I18n id="form.profileForm.cancel" />
              </Button>
            )}
            <Button loading={form.isSubmitting} type="submit" skin="primary">
              <I18n id="form.profileForm.submit" />
            </Button>
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}
