import React, { memo } from 'react';
import { Fieldset } from '@appclose/core';

import { useIntl } from 'i18n';
import FilesFormField from 'components/fields/FilesFormField';
import { Entities } from 'constants/entities';

import { BankStatementsFieldsetPropsType } from './BankStatementsFieldset.types';

const BankStatementsFieldset = memo(function BankStatementsFieldset({
  id,
  onChange,
}: BankStatementsFieldsetPropsType) {
  const { t } = useIntl();

  return (
    <Fieldset title={t('form.bankStatementsFieldset.title')}>
      <FilesFormField
        entityType={Entities.RECONCILIATION}
        entityId={id}
        onChange={onChange}
      />
    </Fieldset>
  );
});

export default BankStatementsFieldset;
