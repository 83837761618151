import React from 'react';
import { useQuery } from '@apollo/client';
import { Amount } from '@appclose/core';
import { OperatingIcon } from '@appclose/icons';
import variables from '@appclose/ui/src/scss/_export.module.scss';

import FinancialBlock from 'components/common/FinancialBlock';
import { sanitizeAmount } from 'controllers/amount';
import useOnFinancialTilesUpdated from 'hooks/useOnFinancialTilesUpdated';
import { useIntl } from 'i18n';

import {
  FetchOwedAmountQuery,
  FetchOwedAmountQueryVariables,
} from './__generated__/OwedToOperating.gql';
import { FETCH_OWED_AMOUNT } from './OwedToOperating.gql';
import styles from './OwedToOperating.module.scss';

export default function OwedToOperating() {
  const { t } = useIntl();
  const { data, refetch } = useQuery<
    FetchOwedAmountQuery,
    FetchOwedAmountQueryVariables
  >(FETCH_OWED_AMOUNT, { fetchPolicy: 'network-only' });
  const value = sanitizeAmount(data?.statistic.otoAmount);

  useOnFinancialTilesUpdated(refetch);

  return (
    <FinancialBlock
      className={styles.balance}
      theme={{ value: styles.value }}
      title={t('modal.disbursement.owedToOperating.title')}
      value={<Amount value={value} />}
      icon={{
        bgColor: variables.colorSystemGray,
        content: <OperatingIcon width={28} height={28} fill="#fff" />,
      }}
    />
  );
}
