import React from 'react';
import { Button, ButtonGroup } from '@appclose/ui';
import { Form } from '@appclose/core';

import { useIntl } from 'i18n';

import EmailInfoFieldset from './components/EmailInfoFieldset';
import { SendEmailFormSchema } from './SendEmailForm.schema';
import { SendEmailFormPropsType } from './SendEmailForm.types';

export default function SendEmailForm({
  initialValues,
  onCancel,
  onSubmit,
  onChange,
  sendButtonText,
  cancelButtonText,
  children,
}: SendEmailFormPropsType) {
  const { t } = useIntl();

  sendButtonText = sendButtonText || t('form.sendEmail.submitDefault');
  cancelButtonText = cancelButtonText || t('form.sendEmail.cancelDefault');

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      onChange={onChange}
      validationSchema={SendEmailFormSchema}
    >
      {({ isSubmitting, values: { from, to } }) => (
        <>
          <EmailInfoFieldset from={from} to={to} files={initialValues?.files} />
          {children}
          <ButtonGroup>
            <Button onClick={onCancel} disabled={isSubmitting}>
              {cancelButtonText}
            </Button>
            <Button loading={isSubmitting} type="submit" skin="primary">
              {sendButtonText}
            </Button>
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}
