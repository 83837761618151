import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { traceError } from '@appclose/core';

import { track } from 'controllers/analytics';
import { openModal } from 'controllers/modal';
import { EntryTypes, DemoEventNames } from 'constants/analytics';
import { DISBURSEMENT_DETAILS_MODAL } from 'constants/modals';
import usePrintChecks from 'hooks/usePrintChecks';
import notification from 'controllers/notification';
import { useIntl } from 'i18n';

import {
  DisbursementToOperatingMutation,
  DisbursementToOperatingMutationVariables,
} from './__generated__/DisbursementToOperating.gql';
import { DISBURSEMENT_TO_OPERATING } from './DisbursementToOperating.gql';
import DisbursementToOperatingForm, {
  DisbursementToOperatingFormActionsType,
  DisbursementToOperatingFormValuesType,
} from './components/DisbursementToOperatingForm';
import { DisbursementToOperatingPropsType } from './DisbursementToOperating.types';

export default function DisbursementToOperating({
  onCancel,
  onClose,
  onChange,
}: DisbursementToOperatingPropsType) {
  const { t } = useIntl();
  const { openPrintChecks } = usePrintChecks();

  const [disbursementToContact] = useMutation<
    DisbursementToOperatingMutation,
    DisbursementToOperatingMutationVariables
  >(DISBURSEMENT_TO_OPERATING);

  const handleOnSubmit = useCallback(
    async (
      {
        printMode,
        otos,
        amount,
        ...input
      }: DisbursementToOperatingFormValuesType,
      { setSubmitting, setFieldValue }: DisbursementToOperatingFormActionsType
    ) => {
      try {
        const { data } = await disbursementToContact({
          variables: {
            input: {
              otosIds: otos.map(({ id }) => id),
              ...input,
            },
          },
        });

        notification().entityCreated(
          t('modal.disbursement.notification.operating.created')
        );

        track(DemoEventNames.DEMO_ENTRY_CREATED, {
          entry_type: EntryTypes.TRUST_DISBURSEMENT,
        });

        onClose();

        if (printMode) {
          openPrintChecks(data?.disbursement.id);
        } else {
          openModal(DISBURSEMENT_DETAILS_MODAL, data?.disbursement);
        }
      } catch (e) {
        setSubmitting(false);
        setFieldValue('printMode', false, true);
        traceError(e);
      }
    },
    [disbursementToContact, onClose, openPrintChecks, t]
  );

  const initialValues = {
    otos: [],
    memo: '',
  };

  return (
    <DisbursementToOperatingForm
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      onCancel={onCancel}
      onChange={onChange}
    />
  );
}
