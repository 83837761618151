import * as Yup from 'yup';
import {
  EMAIL_VALIDATION_SCHEMA,
  PHONE_NUMBER_VALIDATION_SCHEMA,
} from '@appclose/core';

import {
  CONTACT_ENTITY_NAME_VALIDATION_SCHEMA,
  CONTACT_FIRST_NAME_VALIDATION_SCHEMA,
  CONTACT_LAST_NAME_VALIDATION_SCHEMA,
} from 'schemas/validations/contact';
import { getI18nErrorMessage } from 'i18n';

import { ThirdPartyPayeeFormValuesType } from './ThirdPartyPayeeForm.types';
import { AddressInput } from '__generated__/globalTypes';
import { ADDRESS_INPUT_SCHEMA } from 'schemas/validations/address';

export const ThirdPartyPayeeFormSchema = Yup.object().shape<
  ThirdPartyPayeeFormValuesType
>({
  payeeName: Yup.string().required(
    getI18nErrorMessage(
      'modal.popup.thirdPartyPayee.form.error.payeeName.required'
    )
  ),
  firstName: CONTACT_FIRST_NAME_VALIDATION_SCHEMA.nullable().notRequired(),
  lastName: CONTACT_LAST_NAME_VALIDATION_SCHEMA.nullable().notRequired(),
  middleName: Yup.string().nullable(),
  companyName: CONTACT_ENTITY_NAME_VALIDATION_SCHEMA.nullable().notRequired(),
  primaryPhone: PHONE_NUMBER_VALIDATION_SCHEMA.nullable().notRequired(),
  email: EMAIL_VALIDATION_SCHEMA.nullable().notRequired(),
  address: Yup.object<AddressInput>()
    .test(
      'hasCorrectAddressInput',
      getI18nErrorMessage(
        'modal.popup.thirdPartyPayee.form.error.address.format'
      ),
      function (address) {
        if (!address) {
          return true;
        }

        if (
          address.address1 ||
          address.address2 ||
          address.city ||
          address.state ||
          address.zipCode
        ) {
          Yup.object()
            .shape({
              address: ADDRESS_INPUT_SCHEMA,
            })
            .validateSync({ address }, { abortEarly: false });
        }

        return true;
      }
    )
    .nullable(),
});
