import { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { SavePrintingDepositSlipSettingsInput } from '__generated__/globalTypes';

import {
  FETCH_FIRM_PRINTING_DEPOSIT_SLIP_SETTINGS,
  UPDATE_FIRM_PRINTING_DEPOSIT_SLIP_SETTINGS,
} from './usePrintDepositSlip.gql';
import {
  FetchFirmPrintingDepositSlipSettingsQuery,
  FetchFirmPrintingDepositSlipSettingsQueryVariables,
  UpdateFirmPrintingDepositSlipSettingsMutation,
  UpdateFirmPrintingDepositSlipSettingsMutationVariables,
} from './__generated__/usePrintDepositSlip.gql';
import { UsePrintDepositSlipPropsType } from './usePrintDepositSlip.types';

export default function usePrintDepositSlip({
  skipLoading = false,
}: UsePrintDepositSlipPropsType = {}) {
  const { loading, data } = useQuery<
    FetchFirmPrintingDepositSlipSettingsQuery,
    FetchFirmPrintingDepositSlipSettingsQueryVariables
  >(FETCH_FIRM_PRINTING_DEPOSIT_SLIP_SETTINGS, {
    skip: skipLoading,
  });
  const [
    updatePrintingDepositSlipSettings,
    { loading: savePrintingDepositSlipSettingsLoading },
  ] = useMutation<
    UpdateFirmPrintingDepositSlipSettingsMutation,
    UpdateFirmPrintingDepositSlipSettingsMutationVariables
  >(UPDATE_FIRM_PRINTING_DEPOSIT_SLIP_SETTINGS);

  const printDepositSlipOffset = data?.firm?.printingDepositSlipSettings
    ?.offset as [number, number] | undefined;

  const savePrintingDepositSlipSettings = useCallback(
    async (input: SavePrintingDepositSlipSettingsInput) => {
      await updatePrintingDepositSlipSettings({ variables: { input } });
    },
    [updatePrintingDepositSlipSettings]
  );

  return {
    loadingPrintDepositSlipSettings: loading,
    printDepositSlipOffset,
    savePrintingDepositSlipSettings,
    savePrintingDepositSlipSettingsLoading,
  };
}
