import React, { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { dateManager, Loader, Modal } from '@appclose/core';

import { MatterTeamMemberRoles, MatterTypes } from '__generated__/globalTypes';
import { SelectTeamMemberType } from 'components/fields/TeamMemberSelectFormField';
import notification from 'controllers/notification';
import { track } from 'controllers/analytics';
import { EntryTypes, EventNames, SourceTypes } from 'constants/analytics';
import useProfile from 'hooks/useProfile';
import { useIntl } from 'i18n';

import {
  CreateMatterMutation,
  CreateMatterMutationVariables,
} from './__generated__/MatterPopupModal.gql';
import { SERVER_DATE_FORMAT } from 'constants/date';
import { sanitizeAmount } from 'controllers/amount';

import MatterPopupForm from './components/MatterPopupForm';
import { MatterPopupFormValuesType } from './components/MatterPopupForm/MatterPopupForm.types';
import { CREATE_MATTER } from './MatterPopupModal.gql';
import { MatterModalPropsType } from './MatterPopupModal.types';

export default function MatterPopupModal({
  onComplete,
  onCancel,
  contactId,
}: MatterModalPropsType) {
  const { t } = useIntl();
  const [createMatter] = useMutation<
    CreateMatterMutation,
    CreateMatterMutationVariables
  >(CREATE_MATTER);
  const { profile, loading } = useProfile();
  const initialValues = useMemo<MatterPopupFormValuesType>(
    () => ({
      name: '',
      dateOpened: dateManager()
        .parse()
        .startOf('day')
        .format(SERVER_DATE_FORMAT),
      type: MatterTypes.HYBRID,
      agreedFlatFee: 1,
      billable: true,
      leadAttorney: profile as SelectTeamMemberType,
    }),
    [profile]
  );

  const handleOnCancel = useCallback(() => {
    track(EventNames.CANCEL_ENTRY, {
      entry_type: EntryTypes.MATTER,
      source_type: SourceTypes.POPUP_MODAL,
    });

    onCancel();
  }, [onCancel]);

  const handleOnSubmit = useCallback(
    async ({ leadAttorney, ...matter }: MatterPopupFormValuesType) => {
      const teamMembers = [
        {
          userId: leadAttorney?.id || '',
          userRole: MatterTeamMemberRoles.LEAD_ATTORNEY,
          hourlyRate: sanitizeAmount(leadAttorney?.hourlyRate),
        },
      ];

      const result = await createMatter({
        variables: {
          matter: {
            ...matter,
            contactId: contactId,
            teamMembers,
          },
        },
      });

      notification().entityCreated(
        t('modal.popup.matter.notification.created')
      );

      result.data?.createMatter && onComplete(result.data?.createMatter);
    },
    [contactId, createMatter, onComplete, t]
  );

  return (
    <Modal title={t('modal.popup.matter.title.add')} onClose={handleOnCancel}>
      {loading ? (
        <Loader />
      ) : (
        <MatterPopupForm
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          onCancel={handleOnCancel}
        />
      )}
    </Modal>
  );
}
