import React, { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { Text3 } from '@appclose/ui';
import { Amount, Loader, openConfirmAction, traceError } from '@appclose/core';

import { PrintingChecksModes } from '__generated__/globalTypes';
import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import usePrintChecks from 'hooks/usePrintChecks';
import { PRINT_CHECKS_PREVIEW_MODAL } from 'constants/modals';
import { QBO_PRINT_CHECKS_URL } from 'constants/env';
import { getSavedModalData, openModal } from 'controllers/modal';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { I18n, useIntl } from 'i18n';

import {
  FetchLastCheckNumberQuery,
  FetchLastCheckNumberQueryVariables,
  PrintChecksWithQboMutation,
  PrintChecksWithQboMutationVariables,
} from './__generated__/PrintChecksModal.gql';
import PrintChecksForm, {
  PrintCheckFormActionsType,
  PrintCheckFormValuesType,
} from './components/PrintChecksForm';
import {
  FETCH_LAST_CHECK_NUMBER,
  PRINT_CHECKS_WITH_QBO,
} from './PrintChecksModal.gql';
import { PrintChecksModalPropsType } from './PrintChecksModal.types';

export default function PrintChecksModal({
  onClose,
  id,
}: PrintChecksModalPropsType) {
  const { t } = useIntl();
  let initialValues = getSavedModalData<PrintCheckFormValuesType>();

  const dispatch = useDispatch();
  const { loading: loadingLastCheckNumber, data } = useQuery<
    FetchLastCheckNumberQuery,
    FetchLastCheckNumberQueryVariables
  >(FETCH_LAST_CHECK_NUMBER, {
    fetchPolicy: 'network-only',
    skip: !!initialValues,
  });

  const { loadingPrintChecksSettings, mode } = usePrintChecks();
  const [printChecksWithQbo] = useMutation<
    PrintChecksWithQboMutation,
    PrintChecksWithQboMutationVariables
  >(PRINT_CHECKS_WITH_QBO);

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });

  const handleOnSubmit = useCallback(
    async (
      {
        selectedChecks,
        startCheckNumber,
        lastCheckNumber,
      }: PrintCheckFormValuesType,
      { setSubmitting }: PrintCheckFormActionsType
    ) => {
      try {
        if (mode === PrintingChecksModes.QBO) {
          await printChecksWithQbo({
            variables: { disbursementsIds: selectedChecks },
          });

          dispatch(
            openConfirmAction({
              name: t('modal.printChecks.qbo.confirm.name'),
              content: t('modal.printChecks.qbo.confirm.content'),
              okButtonTitle: t('modal.printChecks.qbo.confirm.okButtonTitle'),
              cancelButtonTitle: t(
                'modal.printChecks.qbo.confirm.cancelButtonTitle'
              ),
              okButtonSkin: 'primary',
              onConfirm: () => {
                window.open(QBO_PRINT_CHECKS_URL);
              },
              onClose: () => {
                onClose();
              },
            })
          );

          return;
        }

        const handleOpenPrintPreview = () => {
          onClose();

          openModal(PRINT_CHECKS_PREVIEW_MODAL, {
            startCheckNumber,
            selectedChecks,
          });
        };

        if (
          startCheckNumber &&
          lastCheckNumber &&
          lastCheckNumber + 1 !== startCheckNumber
        ) {
          dispatch(
            openConfirmAction({
              name: t('modal.printChecks.esqNotSequential.confirm.name'),
              content: (
                <Text3>
                  <I18n
                    id="modal.printChecks.esqNotSequential.confirm.content"
                    values={{ zeroAmount: <Amount value={0} /> }}
                  />
                </Text3>
              ),
              okButtonTitle: t(
                'modal.printChecks.esqNotSequential.confirm.okButtonTitle',
                { startCheckNumber }
              ),
              cancelButtonTitle: t(
                'modal.printChecks.esqNotSequential.confirm.cancelButtonTitle'
              ),
              okButtonSkin: 'primary',
              onConfirm: handleOpenPrintPreview,
            })
          );
        } else {
          handleOpenPrintPreview();
        }
      } catch (e) {
        traceError(e);
        setSubmitting(false);
      }
    },
    [dispatch, mode, onClose, printChecksWithQbo, t]
  );

  const lastCheckNumber = data?.lastCheck.number;

  if (!initialValues) {
    initialValues = {
      selectedChecks: id ? [id] : [],
      mode: mode as PrintingChecksModes,
      lastCheckNumber,
      startCheckNumber: lastCheckNumber ? lastCheckNumber + 1 : undefined,
    };
  }

  const isLoading = loadingLastCheckNumber || loadingPrintChecksSettings;

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.printChecks.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {isLoading ? (
          <Loader />
        ) : (
          <PrintChecksForm
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            onCancel={onConfirmClose}
            onChange={onFormChange}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
