import React from 'react';
import { Button, ButtonGroup, Offset } from '@appclose/ui';
import {
  AmountFormField,
  dateManager,
  DatePickerFormField,
  Fieldset,
  Form,
  FormGroup,
  FormSubmissionError,
  TextAreaFormField,
  ToggleFormField,
} from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import DurationFormField from 'components/fields/DurationFormField';
import TimeEntryActivitySelectFormField from 'components/fields/TimeEntryActivitySelectFormField';
import BilledByField from 'components/fields/BilledByField';
import ContactMatterFieldset from 'components/form/ContactMatterFieldset';
import AdditionalInfoFieldset from 'components/form/AdditionalInfoFieldset';
import { SERVER_DATE_FORMAT } from 'constants/date';
import { I18n, useIntl } from 'i18n';
import { Entities } from 'constants/entities';
import { MatterTypes } from '__generated__/globalTypes';

import { TimeEntryFormPropsType } from './TimeEntryForm.types';
import { TimeEntryFormSchema } from './TimeEntryForm.schema';
import TotalField from './components/TotalField/TotalField';
import RoundedDurationField from './components/RoundedDurationField';

import styles from './TimeEntryForm.module.scss';

export default function TimeEntryForm({
  initialValues,
  isEdit,
  profileId,
  onSubmit,
  onCancel,
  onChange,
}: TimeEntryFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      validationSchema={TimeEntryFormSchema}
      onSubmit={onSubmit}
      onChange={onChange}
    >
      {({
        values: {
          id,
          amount,
          billingRate,
          billable,
          durationSec,
          matter,
          roundedDuration,
        },
        isSubmitting,
        setFieldValue,
      }) => (
        <>
          <ContactMatterFieldset
            allowAddNewMatter
            onChange={(_, matter) => {
              if (matter?.type === MatterTypes.FLAT_FEE && billable) {
                setFieldValue('billable', false, true);
              }
            }}
          />
          <Fieldset title={t('modal.timeEntry.form.fieldset.info.title')}>
            <FormGroup>
              <FormGrid>
                <TimeEntryActivitySelectFormField allowAddNew />
              </FormGrid>
            </FormGroup>
            <FormGroup>
              <TextAreaFormField
                name="description"
                label={t('modal.timeEntry.form.fieldset.info.description')}
              />
            </FormGroup>
            <FormGroup>
              <FormGrid align="start">
                <DurationFormField name="durationSec" />
                <DatePickerFormField
                  name="date"
                  label={t('modal.timeEntry.form.fieldset.info.date')}
                  outputDateFormat={SERVER_DATE_FORMAT}
                  maxDate={dateManager().parse().endOf('day').valueOf()}
                  useHumanReadableFormat
                />
                <div className={styles.billableToggleWrapper}>
                  <ToggleFormField
                    name="billable"
                    label={t('modal.timeEntry.form.fieldset.info.billable')}
                    disabled={matter?.type === MatterTypes.FLAT_FEE}
                  />
                </div>
                <BilledByField
                  profileId={profileId}
                  onChange={(billedBy, hourlyRate) => {
                    setFieldValue('billingRate', hourlyRate, true);
                  }}
                />
                <AmountFormField
                  name="billingRate"
                  label={t('modal.timeEntry.form.fieldset.info.billingRate')}
                />
                <RoundedDurationField />
                <div className={styles.totalFieldWrapper}>
                  <TotalField
                    amount={amount}
                    billingRate={billingRate}
                    durationSec={roundedDuration ?? durationSec}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </FormGrid>
            </FormGroup>
          </Fieldset>
          <AdditionalInfoFieldset entityType={Entities.EXPENSE} entityId={id} />
          <Offset bottom="m">
            <FormSubmissionError />
          </Offset>
          <ButtonGroup>
            <Button onClick={onCancel}>
              <I18n id="modal.timeEntry.form.cancel" />
            </Button>
            <Button loading={isSubmitting} type="submit" skin="primary">
              {isEdit ? (
                <I18n id="modal.timeEntry.form.update.submit" />
              ) : (
                <I18n id="modal.timeEntry.form.create.submit" />
              )}
            </Button>
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}
