import React, { ReactElement } from 'react';
import { DispatchProp } from 'react-redux';
import { ButtonTypes } from '@appclose/ui';
import { history, usePopupModal } from '@appclose/core';

import { BankAccountClasses } from '__generated__/globalTypes';
import { isDemoBuild } from 'controllers/demo';
import { openModal } from 'controllers/modal';
import { isFreePlan } from 'controllers/mode';
import { BANK_ACCOUNT_MODAL, KYB_MODAL } from 'constants/modals';
import { TRUST_ROUTE, SETTINGS_ACCOUNTS_ROUTE } from 'constants/routes';
import { I18n, i18nMessage } from 'i18n';
import { openDemoUnavailableConfirmAction } from 'store/actions';

import ConnectFeeBankAccountModal from 'components/modals/popups/ConnectFeeBankAccountModal';

import {
  BankAccountNotLinkedType,
  BankAccountNotLinkedTypes,
} from '../BankAccountNotLinked.types';

export const useConfig = (): Record<
  BankAccountNotLinkedType,
  {
    message: () => ReactElement;
    button?: Omit<ButtonTypes, 'children' | 'onClick'> & {
      message: () => ReactElement;
      onClick?(dispatch: DispatchProp['dispatch']): () => void;
    };
  }
> => {
  const { openPopupModal: openFeeAccountConnectPopupModal } = usePopupModal(ConnectFeeBankAccountModal);

  return {
    [BankAccountClasses.OPERATING]: {
      message: () => <I18n id="bankAccountNotLinked.operating" />,
    },
    [BankAccountClasses.TRUST]: {
      message: () => (
        <I18n
          id={
            isFreePlan()
              ? 'bankAccountNotLinked.trust.free'
              : 'bankAccountNotLinked.trust.plus'
          }
        />
      ),
      button: {
        skin: 'primary',
        message: () => (
          <I18n
            id={
              isFreePlan()
                ? 'bankAccountNotLinked.linkTrustAccount'
                : 'bankAccountNotLinked.trustAccountSetup'
            }
          />
        ),
        onClick: (dispatch: DispatchProp['dispatch']) => () => {
          if (isDemoBuild()) {
            dispatch(
              openDemoUnavailableConfirmAction(
                i18nMessage(
                  'bankAccountNotLinked.trust.linkTrustAccount'
                ) as string
              )
            );

            return;
          }

          isFreePlan()
            ? openModal(BANK_ACCOUNT_MODAL, {
                accountClass: BankAccountClasses.TRUST,
              })
            : history.push(TRUST_ROUTE);
        },
      },
    },
    [BankAccountNotLinkedTypes.TRUST_ACCOUNT_NEED_RECONNECTION]: {
      message: () => <I18n id="bankAccountNotLinked.trustAccountNeedReconnection" />,
      button: {
        skin: 'primary',
        message: () => <I18n id="bankAccountNotLinked.openSettings" />,
        onClick: () => () => history.push(SETTINGS_ACCOUNTS_ROUTE),
      }
    },
    [BankAccountNotLinkedTypes.TRUST_FEE_ACCOUNT_REQUIRED]: {
      message: () => <I18n id="bankAccountNotLinked.trustFeeAccountIsRequired" />,
      button: {
        skin: 'primary',
        message: () => <I18n id="bankAccountNotLinked.trust.linkTrustFeeAccount" />,
        onClick: () => openFeeAccountConnectPopupModal,
      }
    },
    [BankAccountNotLinkedTypes.TRUST_FEE_ACCOUNT_PROCESSING]: {
      message: () => <I18n id="bankAccountNotLinked.trustFeeAccountIsProcessing" />,
    },
    [BankAccountNotLinkedTypes.TRUST_ACCOUNT_NOT_COMPLETED]: {
      message: () => <I18n id="bankAccountNotLinked.trust" />,
    },
    [BankAccountNotLinkedTypes.TRUST_ACCOUNTING]: {
      message: () => <I18n id="bankAccountNotLinked.trustAccounting" />,
      button: {
        skin: 'primary',
        message: () => <I18n id="bankAccountNotLinked.completeTrustAccounting" />,
        onClick: () => () => history.push(TRUST_ROUTE),
      },
    },
    [BankAccountNotLinkedTypes.LIMITED_ACCESS_OWNER]: {
      message: () => (
        <I18n id="store.profile.limitedAccessConfirm.owner.content" />
      ),
      button: {
        skin: 'primary',
        message: () => (
          <I18n id="store.profile.limitedAccessConfirm.owner.okButton" />
        ),
        onClick: () => () =>
          openModal(KYB_MODAL),
      },
    },
    [BankAccountNotLinkedTypes.LIMITED_ACCESS_TEAM_MEMBER]: {
      message: () => (
        <I18n id="store.profile.limitedAccessConfirm.teamMember.content" />
      ),
      button: {
        skin: 'primary',
        message: () => (
          <I18n id="store.profile.limitedAccessConfirm.teamMember.okButton" />
        ),
      },
    }
  }
};
