import React, { useCallback, useMemo, useState } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { Flex } from '@appclose/ui';
import { ExternalLink } from '@appclose/core';

import { EXPERT_SUPPORT_ROUTE } from 'constants/routes';
import { ReactComponent as AnchorIcon } from 'assets/anchor.svg';
import { I18n } from 'i18n';

import styles from './Disclaimer.module.scss';

export default function Disclaimer() {
  const [animate, setAnimate] = useState(false);
  const duration = 500;
  const style = useMemo(
    () => ({
      transition: `opacity ${duration}ms ease-out`,
    }),
    [duration]
  );
  const transitions = useMemo<Record<TransitionStatus, Object>>(
    () => ({
      entering: { opacity: 0 },
      entered: { opacity: 1 },
      exiting: { opacity: 0 },
      exited: { opacity: 0 },
      unmounted: { opacity: 0 },
    }),
    []
  );

  const onClick = useCallback(() => setAnimate(!animate), [animate]);

  return (
    <div className={styles.disclaimer}>
      <Flex
        as="a"
        alignItems="center"
        justify="center"
        className={styles.link}
        onClick={onClick}
      >
        <AnchorIcon width={15} className={styles.icon} />
        <I18n id="modes.disclaimer.title" />
      </Flex>
      <Transition in={animate} timeout={duration} mountOnEnter unmountOnExit>
        {(state) => (
          <ol
            style={{
              ...style,
              ...transitions[state],
            }}
          >
            <li>
              <I18n id="modes.disclaimer.01" />
            </li>
            <li>
              <I18n id="modes.disclaimer.02" />
            </li>
            <li>
              <I18n id="modes.disclaimer.03" />
            </li>
            <li>
              <I18n id="modes.disclaimer.04" />
            </li>
            <li>
              <I18n id="modes.disclaimer.05" />
            </li>
            <li>
              <I18n id="modes.disclaimer.06" />
            </li>
            <li>
              <I18n id="modes.disclaimer.07" />
            </li>
            <li>
              <I18n id="modes.disclaimer.08" />
            </li>
            <li>
              <I18n
                id="modes.disclaimer.09"
                values={{
                  a: (string: string) => (
                    <ExternalLink link={EXPERT_SUPPORT_ROUTE}>
                      {string}
                    </ExternalLink>
                  ),
                }}
              />
            </li>
            <li>
              <I18n id="modes.disclaimer.10" />
              <ul>
                <li>
                  <I18n id="modes.disclaimer.10.01" />
                </li>
                <li>
                  <I18n id="modes.disclaimer.10.02" />
                </li>
              </ul>
            </li>
            <li>
              <I18n id="modes.disclaimer.11" />
            </li>
            <li>
              <I18n id="modes.disclaimer.12" />
            </li>
            <li>
              <I18n id="modes.disclaimer.13" />
            </li>
            <li>
              <I18n id="modes.disclaimer.14" />
            </li>
          </ol>
        )}
      </Transition>
    </div>
  );
}
