import React from 'react';
import { SelectFormField } from '@appclose/core';

import { useIntl } from 'i18n';

import { useOptions } from './UnclearedTransactionTypeSelectFormField.utils';
import { UnclearedTransactionSelectFormFieldPropsType } from './UnclearedTransactionTypeSelectFormField.types';

export default function UnclearedTransactionTypeSelectFormField({
  name = 'type',
  label,
  ...props
}: UnclearedTransactionSelectFormFieldPropsType) {
  const { t } = useIntl();
  const options = useOptions();

  return (
    <SelectFormField
      name={name}
      label={
        label ||
        t('modal.trustSetupBalancesStep.unclearedTransactionTypeSelect.label')
      }
      options={options}
      {...props}
    />
  );
}
