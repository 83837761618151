import { gql } from '@apollo/client';

import { ADDRESS_FRAGMENT } from 'components/common/Address';

export const THIRD_PARTY_PAYEE_MODAL_FRAGMENT = gql`
  fragment ThirdPartyPayeeModal on ThirdPartyPayeeType {
    id
    payeeName
    firstName
    lastName
    middleName
    companyName
    primaryPhone
    email
    status
    address {
      ...Address
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export const CREATE_THIRD_PARTY_PAYEE = gql`
  mutation CreateThirdPartyPayee($input: CreateThirdPartyPayeesInput!) {
    payee: createThirdPartyPayee(thirdPartyPayeeInput: $input) {
      ...ThirdPartyPayeeModal
    }
  }
  ${THIRD_PARTY_PAYEE_MODAL_FRAGMENT}
`;

export const FETCH_THIRD_PARTY_PAYEE = gql`
  query FetchThirdPartyPayee($id: ID!) {
    payee: getThirdPartyPayee(id: $id) {
      ...ThirdPartyPayeeModal
    }
  }
  ${THIRD_PARTY_PAYEE_MODAL_FRAGMENT}
`;

export const EDIT_THIRD_PARTY_PAYEE = gql`
  mutation EditThirdPartyPayee($input: UpdateThirdPartyPayeesInput!) {
    payee: updateThirdPartyPayee(thirdPartyPayeeInput: $input) {
      ...ThirdPartyPayeeModal
    }
  }
  ${THIRD_PARTY_PAYEE_MODAL_FRAGMENT}
`;
