import React from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import {
  Amount,
  FormGroup,
  StaticField,
  openConfirmAction,
  permissionProvider,
  Fieldset,
  traceError,
} from '@appclose/core';
import { Checkbox, Button } from '@appclose/ui';

import {
  BankAccountClasses,
  InvoiceStatusesTypes,
  Modes,
} from '__generated__/globalTypes';
import FormGrid from 'components/common/FormGrid';
import Date from 'components/common/Date';
import ModeGuard from 'components/common/ModeGuard';
import InvoiceStatus from 'components/common/InvoiceStatus';
import SchedulePreview from 'components/common/SchedulePreview';
import StaticFilesField from 'components/common/StaticFilesField';
import EntityQboSyncStatus from 'components/common/EntityQboSyncStatus';
import { sanitizeAmount } from 'controllers/amount';
import { getContactName } from 'controllers/contact';
import notification from 'controllers/notification';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import useBankAccountClasses from 'hooks/useBankAccountClasses';
import { useIntl, I18n } from 'i18n';

import { CANCEL_PAYMENT_PLAN } from './Info.gql';
import {
  CancelPaymentPlanMutation,
  CancelPaymentPlanMutationVariables,
} from './__generated__/Info.gql';
import InvoiceActivitiesTable from './components/InvoiceActivitiesTable';
import { InfoPropsType } from './Info.types';
import styles from './Info.module.scss';

export default function Info({
  invoice: {
    id,
    contact,
    invoiceNumber,
    destinationAccount,
    dueDate,
    message,
    internalMemo,
    matters,
    requestedAmount,
    originalAmount,
    discount: rawDiscount,
    percentageDiscount,
    status,
    scheduledPayments,
    timeEntries,
    expenses,
    flatFees,
    qboSyncState,
  },
  files,
}: InfoPropsType) {
  const { getBankAccountClassLabel } = useBankAccountClasses();
  const { t } = useIntl();
  const dispatch = useDispatch();
  const isOperatingAccount =
    destinationAccount === BankAccountClasses.OPERATING;

  const [cancelPaymentPlan] = useMutation<
    CancelPaymentPlanMutation,
    CancelPaymentPlanMutationVariables
  >(CANCEL_PAYMENT_PLAN);

  const onCancelPaymentPlanClick = () =>
    dispatch(
      openConfirmAction({
        name: t('modal.invoiceDetails.info.confirm.cancel.name'),
        content: t('modal.invoiceDetails.info.confirm.cancel.content'),
        okButtonTitle: t('modal.invoiceDetails.info.confirm.cancel.button.ok'),
        okButtonSkin: 'success',
        cancelButtonTitle: t(
          'modal.invoiceDetails.info.confirm.cancel.button.cancel'
        ),
        async onConfirm() {
          try {
            await cancelPaymentPlan({ variables: { id } });

            notification().info(
              t(
                'modal.invoiceDetails.info.notification.scheduledPaymentPlanCanceled'
              )
            );
          } catch (e) {
            traceError(e);
          }
        },
      })
    );

  const discount = sanitizeAmount(rawDiscount);
  const hasPaymentSchedule = !!scheduledPayments;
  const canCancelPaymentSchedule =
    hasPaymentSchedule &&
    !!scheduledPayments?.plan?.length &&
    ![InvoiceStatusesTypes.VOIDED, InvoiceStatusesTypes.PAID].includes(
      status
    ) &&
    permissionProvider().hasPermission(
      PermissionResources.INVOICE,
      PermissionActions.UPDATE
    );

  return (
    <>
      <Fieldset title={t('modal.invoiceDetails.info.fieldset.details')}>
        <FormGroup>
          <FormGrid>
            <StaticField title={t('modal.invoiceDetails.info.field.contact')}>
              {getContactName(contact)}
            </StaticField>
            <StaticField
              title={t('modal.invoiceDetails.info.field.referenceId')}
            >
              {invoiceNumber}
            </StaticField>
            <StaticField
              title={t('modal.invoiceDetails.info.field.destinationAccount')}
            >
              {getBankAccountClassLabel(destinationAccount)}
            </StaticField>
            <StaticField title={t('modal.invoiceDetails.info.field.dueDate')}>
              <Date value={dueDate} />
            </StaticField>
          </FormGrid>
        </FormGroup>
        <ModeGuard mode={Modes.PLUS}>
          <FormGroup>
            <StaticField title={t('modal.invoiceDetails.info.field.matter')}>
              {(matters || []).map(({ name }) => name).join(', ')}
            </StaticField>
          </FormGroup>
        </ModeGuard>
        <FormGroup>
          <StaticField
            title={t('modal.invoiceDetails.info.fieldset.qboSyncStatus')}
          >
            <EntityQboSyncStatus
              entityId={id}
              entityType="invoice"
              qboSyncState={qboSyncState || undefined}
            />
          </StaticField>
        </FormGroup>
      </Fieldset>
      <Fieldset title={t('modal.invoiceDetails.info.fieldset.amount')}>
        <FormGrid>
          <ModeGuard mode={Modes.PLUS}>
            {isOperatingAccount && (
              <>
                <StaticField
                  title={t('modal.invoiceDetails.info.field.expenses')}
                >
                  <Amount value={originalAmount} />
                </StaticField>
                <StaticField
                  title={t('modal.invoiceDetails.info.field.discount')}
                >
                  <Amount
                    value={
                      percentageDiscount
                        ? sanitizeAmount((originalAmount / 100) * discount)
                        : discount
                    }
                  />
                  {percentageDiscount && ` (${discount}%)`}
                </StaticField>
              </>
            )}
          </ModeGuard>
          <StaticField
            title={t('modal.invoiceDetails.info.field.requestedAmount')}
          >
            <Amount value={requestedAmount} />
          </StaticField>
          <StaticField title={t('modal.invoiceDetails.info.field.status')}>
            <InvoiceStatus status={status} />
          </StaticField>
        </FormGrid>
      </Fieldset>
      <ModeGuard mode={Modes.PLUS}>
        {isOperatingAccount && (
          <InvoiceActivitiesTable
            expenses={expenses}
            timeEntries={timeEntries}
            flatFees={flatFees}
          />
        )}
      </ModeGuard>
      <Fieldset
        title={t('modal.invoiceDetails.info.fieldset.additionalInformation')}
      >
        <FormGroup>
          <Checkbox
            checked={hasPaymentSchedule}
            label={t('modal.invoiceDetails.info.field.scheduledPayments')}
            readOnly
          />
        </FormGroup>
        <SchedulePreview plan={scheduledPayments?.plan} />
        {canCancelPaymentSchedule && (
          <Button
            className={styles.cancelPaymentPlanButton}
            onClick={onCancelPaymentPlanClick}
          >
            <I18n id="modal.invoiceDetails.info.field.cancelPaymentPlan" />
          </Button>
        )}
      </Fieldset>
      {(message || internalMemo || !!files?.length) && (
        <Fieldset
          title={t('modal.invoiceDetails.info.fieldset.additionalInfo')}
        >
          {message && (
            <FormGroup>
              <StaticField
                title={t('modal.invoiceDetails.info.field.messageToContact')}
              >
                {message}
              </StaticField>
            </FormGroup>
          )}
          {internalMemo && (
            <FormGroup>
              <StaticField
                title={t('modal.invoiceDetails.info.field.internalNotes')}
              >
                {internalMemo}
              </StaticField>
            </FormGroup>
          )}
          {!!files?.length && (
            <FormGroup>
              <StaticFilesField files={files} />
            </FormGroup>
          )}
        </Fieldset>
      )}
    </>
  );
}
