import { Flex, Text4, Text5, Number4, Number7 } from '@appclose/ui';
import { Amount, useIsMobileDevice } from '@appclose/core';

import { FeePropsType } from './Fee.types';
import styles from './Fee.module.scss';

export default function Fee({ title, fee, fixed, inPercentFee, inPercentFixed, note }: FeePropsType) {
  const isMobileDevice = useIsMobileDevice(440);
  const Title = isMobileDevice ? Text5 : Text4;
  const Number = isMobileDevice ? Number7 : Number4;
  const Fixed = isMobileDevice ? Text5 : Text5;

  return (
    <Flex alignItems="baseline" wrap="wrap" className={styles.fee}>
      <Title>{title}</Title>
      <Flex alignItems="baseline">
        <Number>{!inPercentFee ? <Amount value={fee} /> : `${fee}%`}</Number>
        {fixed && (
          <Fixed weight="semibold" className={styles.fixed}>
            + <Amount value={fixed} /> 
          </Fixed>
        )}
        <Fixed weight="semibold" className={styles.fixed}>
          ({!inPercentFixed ? <Amount value={5} /> : '+' + `${0.5}%`} { note})
        </Fixed>
      </Flex>
    </Flex>
  );
}
