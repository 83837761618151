import React from 'react';
import {
  FileIcon,
  FolderIcon,
  FolderContactIcon,
  FolderMatterIcon,
} from '@appclose/icons';

import { NodeIconPropsType } from './NodeIcon.types';

export default function NodeIcon({
  isFolder,
  isSystemFolder,
  attach,
  className,
}: NodeIconPropsType) {
  if (!isFolder) {
    return <FileIcon className={className} />;
  } else {
    switch (true) {
      case isSystemFolder && Boolean(attach?.contact?.id):
        return <FolderContactIcon className={className} />;
      case isSystemFolder && Boolean(attach?.matter?.id):
        return <FolderMatterIcon className={className} />;
      default:
        return <FolderIcon className={className} />;
    }
  }
}
