import React from 'react';
import {
  Button,
  ButtonGroup,
  Flex,
  Offset,
  Note,
  Text4,
  Text3,
} from '@appclose/ui';
import {
  Form,
  FormGroup,
  FormSubmissionError,
  AmountFormField,
  currency,
} from '@appclose/core';

import { I18n, useIntl } from 'i18n';
import useProfile from 'hooks/useProfile';

import { FlatFeeMatterAmountFormSchema } from './FlatFeeMatterAmountForm.schema';
import { FlatFeeMatterAmountFormPropsType } from './FlatFeeMatterAmountForm.types';
import styles from './FlatFeeMatterAmountForm.module.scss';

export default function FlatFeeMatterAmountForm({
  initialValues,
  totalFlatFeesAmount,
  onSubmit,
  onCancel,
}: FlatFeeMatterAmountFormPropsType) {
  const { t } = useIntl();
  const { isLimitedUser } = useProfile();

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={FlatFeeMatterAmountFormSchema}
    >
      {({ isSubmitting }) => {
        return (
          <div className={styles.formContent}>
            <Note theme="important">
              <Text4 align="justify">
                {isLimitedUser ? (
                  <I18n id="modal.popup.flatFeeMatterAmount.form.noteLimited" />
                ) : (
                  <I18n id="modal.popup.flatFeeMatterAmount.form.note" />
                )}
              </Text4>
            </Note>
            <Text3 offset={{ top: 20 }}>
              <I18n
                id="modal.popup.flatFeeMatterAmount.form.matterAgreedFlatFee"
                values={{
                  matterAgreedFlatFee: currency().format(
                    initialValues.originalAgreedFlatFee
                  ),
                }}
              />
            </Text3>
            <Text3 offset={{ top: 10, bottom: isLimitedUser ? 0 : 30 }}>
              <I18n
                id="modal.popup.flatFeeMatterAmount.form.totalFlatFeesAmount"
                values={{
                  totalFlatFeesAmount: currency().format(totalFlatFeesAmount),
                }}
              />
            </Text3>
            {!isLimitedUser && (
              <>
                <FormGroup>
                  <AmountFormField
                    name="agreedFlatFee"
                    label={t(
                      'modal.popup.flatFeeMatterAmount.form.agreedFlatFee'
                    )}
                    tooltip={t(
                      'modal.popup.flatFeeMatterAmount.form.agreedFlatFee.tooltip'
                    )}
                  />
                </FormGroup>
                <Offset top="xs">
                  <FormSubmissionError />
                </Offset>
                <Offset top="xl">
                  <Flex justify="center">
                    <ButtonGroup>
                      <Button onClick={onCancel}>
                        <I18n id="modal.popup.flatFeeMatterAmount.form.cancel" />
                      </Button>
                      <Button
                        loading={isSubmitting}
                        type="submit"
                        skin="primary"
                      >
                        <I18n id={'modal.popup.flatFeeMatterAmount.form.ok'} />
                      </Button>
                    </ButtonGroup>
                  </Flex>
                </Offset>
              </>
            )}
          </div>
        );
      }}
    </Form>
  );
}
