import * as Yup from 'yup';
import { currency } from '@appclose/core';

import { SelectContactType } from 'components/fields/ContactSelectFormField';
import {
  ContactMatterSelectContactType,
  ContactMatterSelectMatterType,
} from 'components/fields/ContactMatterSelectFormField';
import { getI18nErrorMessage } from 'i18n';
import { FILES_SCHEMA } from 'schemas/validations/files';

import { TransferFundsFormValuesType } from './TransferFundsForm.types';

export const TransferFundsFormSchema = Yup.object().shape<TransferFundsFormValuesType>(
  {
    contact: Yup.mixed<SelectContactType>().required(
      getI18nErrorMessage('modal.transferFunds.form.error.contact.required')
    ),
    fromContact: Yup.mixed<ContactMatterSelectContactType>().required(
      getI18nErrorMessage('modal.transferFunds.form.error.fromContact.required')
    ),
    fromMatter: Yup.mixed<ContactMatterSelectMatterType>(),
    toContact: Yup.mixed<ContactMatterSelectContactType>().required(
      getI18nErrorMessage('modal.transferFunds.form.error.toContact.required')
    ),
    toMatter: Yup.mixed<ContactMatterSelectMatterType>(),
    description: Yup.string().required(
      getI18nErrorMessage('modal.transferFunds.form.error.description.required')
    ),
    amount: Yup.number()
      .required(
        getI18nErrorMessage('modal.transferFunds.form.error.amount.required')
      )
      .moreThan(
        0,
        getI18nErrorMessage('modal.transferFunds.form.error.amount.moreThan0')
      )
      .when(
        ['fromContact', 'fromMatter'],
        (
          fromContact: ContactMatterSelectContactType,
          fromMatter: ContactMatterSelectMatterType
        ) => {
          if (fromMatter?.trustBalance) {
            return Yup.number().max(fromMatter.trustBalance, ({ max }) =>
              getI18nErrorMessage('modal.transferFunds.form.error.amount.max', {
                max: currency().format(max),
              })
            );
          }

          if (fromContact?.trustBalance) {
            return Yup.number().max(fromContact.trustBalance, ({ max }) =>
              getI18nErrorMessage('modal.transferFunds.form.error.amount.max', {
                max: currency().format(max),
              })
            );
          }
        }
      ),
    files: FILES_SCHEMA,
  }
);
