import React from 'react';
import classnames from 'classnames';
import { Table, TableHead, TableBody, TableCell, TableRow } from '@appclose/ui';
import { Amount, Fieldset } from '@appclose/core';

import { TrustTransactionTypes } from '__generated__/globalTypes';
import TrustTransactionType from 'components/common/TrustTransactionType';
import Date from 'components/common/Date';
import LedgerName from 'components/common/LedgerName';
import DisbursementAmount from 'components/common/DisbursementAmount';
import { I18n } from 'i18n';

import { TransactionsTablePropsType } from './TransactionsTable.types';
import styles from './TransactionsTable.module.scss';

function TransactionsTable({
  title,
  transactions,
  isDisbursement,
  isUncleared,
}: TransactionsTablePropsType) {
  const count = transactions?.length || 0;

  return (
    <Fieldset className={styles.fieldset} title={`${title} ${count}`}>
      {transactions?.length ? (
        <Table>
          <TableHead className={styles.head}>
            <TableRow>
              <TableCell>
                <I18n id="modal.reconciliationDetails.table.type" />
              </TableCell>
              <TableCell>
                <I18n id="modal.reconciliationDetails.table.date" />
              </TableCell>
              <TableCell>
                <I18n id="modal.reconciliationDetails.table.contactMatter" />
              </TableCell>
              <TableCell>
                {isDisbursement ? (
                  <I18n id="modal.reconciliationDetails.table.payee" />
                ) : (
                  <I18n id="modal.reconciliationDetails.table.source" />
                )}
              </TableCell>
              <TableCell>
                <I18n id="modal.reconciliationDetails.table.amount" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            className={classnames(styles.body, {
              [styles.uncleared]: isUncleared,
            })}
          >
            {transactions.map(
              ({ id, type, date, ledger, payeeOrSource, amount, status }) => (
                <TableRow key={id}>
                  <TableCell noWordBreak>
                    <TrustTransactionType type={type} />
                  </TableCell>
                  <TableCell noWordBreak theme="light">
                    <Date value={date} />
                  </TableCell>
                  <TableCell>
                    <LedgerName value={ledger} />
                  </TableCell>
                  <TableCell>
                    <LedgerName value={payeeOrSource} />
                  </TableCell>
                  <TableCell noWordBreak theme="strong">
                    {type === TrustTransactionTypes.DEPOSIT ? (
                      <Amount value={amount} />
                    ) : (
                      <DisbursementAmount status={status} amount={amount} />
                    )}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      ) : (
        <p className={styles.empty}>
          You have no {title.toLowerCase()} in this reconciliation
        </p>
      )}
    </Fieldset>
  );
}

export default TransactionsTable;
