import React, { useCallback, useRef } from 'react';
import classnames from 'classnames';
import { APP_ROOT_ELEMENT_ID, useClickAway } from '@appclose/core';
import { Button, Flex, Title4 } from '@appclose/ui';
import { ClearIcon } from '@appclose/icons';

import { TimerStatusTypes } from '__generated__/globalTypes';
import AddNewButton from 'components/common/AddNewButton';
import { track } from 'controllers/analytics';
import {
  EventNames,
  SectionTypes,
  TimerActionTypes,
} from 'constants/analytics';
import { I18n } from 'i18n';

import Timer from '../Timer';
import TimerForm from './components/TimerForm';
import { TimersPropsType } from './Timers.types';
import styles from './Timers.module.scss';

export default function Timers({
  timers,
  loadingTimer,
  onTimerTick,
  onTimerCancel,
  onTimerDone,
  onTimerPause,
  onTimerStart,
  onTimerUpdate,
  className,
  onClose,
  onAdd,
}: TimersPropsType) {
  const ref = useRef<HTMLElement>(null);

  useClickAway(ref, (e: MouseEvent) => {
    const { pageX, pageY } = e;
    const {
      bottom,
      left,
      right,
      top,
    } = (ref.current?.getBoundingClientRect() || {}) as DOMRect;
    const isItAppRootElement = (e.target as Element | null)?.closest(
      `#${APP_ROOT_ELEMENT_ID}`
    );
    const clickOutsideTimersPanel =
      pageY > bottom || pageY < top || pageX < left || pageX > right;

    if (isItAppRootElement && clickOutsideTimersPanel) {
      onClose();
    }
  });

  const handleOnAdd = useCallback(() => onAdd(), [onAdd]);

  const onStart = useCallback(
    (id: string) => () => {
      onTimerStart(id);

      track(EventNames.ACTION_WITH_TIMER, {
        action_type: TimerActionTypes.START_TIMER,
        section_type: SectionTypes.TIMER_LIST,
      });
    },
    [onTimerStart]
  );

  const onPause = useCallback(
    (id: string) => () => {
      onTimerPause(id);

      track(EventNames.ACTION_WITH_TIMER, {
        action_type: TimerActionTypes.PAUSE_TIMER,
        section_type: SectionTypes.TIMER_LIST,
      });
    },
    [onTimerPause]
  );

  const onDone = useCallback(
    (id: string) => () => {
      onTimerDone(id);

      track(EventNames.ACTION_WITH_TIMER, {
        action_type: TimerActionTypes.COMPLETE_TIMER,
        section_type: SectionTypes.TIMER_LIST,
      });
    },
    [onTimerDone]
  );

  const onCancel = useCallback(
    (id: string) => () => {
      onTimerCancel(id);

      track(EventNames.ACTION_WITH_TIMER, {
        action_type: TimerActionTypes.DELETE_TIMER,
        section_type: SectionTypes.TIMER_LIST,
      });
    },
    [onTimerCancel]
  );

  return (
    <aside ref={ref} className={classnames(styles.timers, className)}>
      <header className={styles.header}>
        <Title4>
          <I18n id="timeTracker.timers" />
        </Title4>
        <AddNewButton onClick={handleOnAdd} />
        <Flex fluid justify="flex-end">
          <Button skin="shell" onClick={onClose}>
            <ClearIcon />
          </Button>
        </Flex>
      </header>
      <ul className={styles.ul}>
        {timers.map((timer) => {
          const {
            id,
            totalSeconds,
            status,
            matter,
            contact,
            description,
          } = timer;

          return (
            <li className={styles.li} key={id}>
              <Timer
                seconds={totalSeconds}
                isPaused={status === TimerStatusTypes.PAUSED}
                loading={loadingTimer === id}
                blocked={!!loadingTimer}
                onTick={() => onTimerTick(id)}
                onPause={onPause(id)}
                onStart={onStart(id)}
                onDone={onDone(id)}
                onCancel={onCancel(id)}
              />
              <TimerForm
                timer={{
                  id,
                  description: description || '',
                  matter,
                  contact,
                }}
                onChange={onTimerUpdate}
              />
            </li>
          );
        })}
      </ul>
    </aside>
  );
}
