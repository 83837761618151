import { gql } from '@apollo/client';

export const CONTACT_WITH_MATTERS_FRAGMENT = gql`
  fragment ContactWithMatters on StrippedContactWithMattersType {
    id
    name
    isEntity
    email
    trustBalance
    billingContacts {
      id
      email
      type
    }
    matters {
      id
      matterNumber
      name
      trustBalance
      type
    }
  }
`;

export const FETCH_CONTACTS_WITH_MATTERS = gql`
  query FetchContactsWithMatters(
    $skip: Int
    $take: Int
    $filter: FilterContactWithMattersInput
    $order: OrderContactInput
  ) {
    contactsWithMatters: listContactsWithMatters(
      listContactsWithMattersInput: {
        skip: $skip
        take: $take
        filter: $filter
        order: $order
      }
    ) {
      items {
        ...ContactWithMatters
      }
      total
    }
  }
  ${CONTACT_WITH_MATTERS_FRAGMENT}
`;
