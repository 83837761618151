import {
  TrustTransactionTypes,
  TrustTransactionStatuses,
} from '__generated__/globalTypes';
import {
  DISBURSEMENT_MODAL,
  RECONCILIATION_WARNING_MODAL,
} from 'constants/modals';
import { openModal } from '../modal';

import { RECONCILIATION_WARNING_HIDE_KEY } from 'constants/localStorage';

type TrustTransactionTypeAndStatus = {
  type: TrustTransactionTypes;
  status: TrustTransactionStatuses;
};

export function isDeposit(type: TrustTransactionTypes) {
  return type === TrustTransactionTypes.DEPOSIT;
}

export function isDisbursement(type: TrustTransactionTypes) {
  return [
    TrustTransactionTypes.DISBURSEMENT_CONTACT,
    TrustTransactionTypes.DISBURSEMENT_OPERATING,
    TrustTransactionTypes.DISBURSEMENT_THIRD_PARTY,
  ].includes(type);
}

export function isCleared(status: TrustTransactionStatuses) {
  return status === TrustTransactionStatuses.CLEARED;
}

export function isUncleared(status: TrustTransactionStatuses) {
  return status === TrustTransactionStatuses.UNCLEARED;
}

export function isClearedDeposit({
  type,
  status,
}: TrustTransactionTypeAndStatus) {
  return isDeposit(type) && isCleared(status);
}

export function isUnclearedDeposit({
  type,
  status,
}: TrustTransactionTypeAndStatus) {
  return isDeposit(type) && isUncleared(status);
}

export function isClearedDisbursement({
  type,
  status,
}: TrustTransactionTypeAndStatus) {
  return isDisbursement(type) && isCleared(status);
}

export function isUnclearedDisbursement({
  type,
  status,
}: TrustTransactionTypeAndStatus) {
  return isDisbursement(type) && isUncleared(status);
}

export function getReconciliationWarningStatus() {
  return localStorage.getItem(RECONCILIATION_WARNING_HIDE_KEY) === 'true';
}

export function setReconciliationWarningStatus(status: boolean) {
  return localStorage.setItem(RECONCILIATION_WARNING_HIDE_KEY, String(status));
}

export function openDisbursementOrReconciliationWarningModal() {
  if (getReconciliationWarningStatus()) {
    openModal(DISBURSEMENT_MODAL);
  } else {
    openModal(RECONCILIATION_WARNING_MODAL);
  }
}
