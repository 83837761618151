import React, { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Loader, Modal } from '@appclose/core';
import classnames from 'classnames';

import { useIntl } from 'i18n';
import notification from 'controllers/notification';
import { track } from 'controllers/analytics';
import { sanitizeAmount } from 'controllers/amount';
import { EntryTypes, EventNames, SourceTypes } from 'constants/analytics';
import useProfile from 'hooks/useProfile';

import {
  FetchFlatFeeMatterQuery,
  FetchFlatFeeMatterQueryVariables,
  FetchMatterFlatFeesQuery,
  FetchMatterFlatFeesQueryVariables,
  UpdateMatterFlatFeeAmountMutation,
  UpdateMatterFlatFeeAmountMutationVariables,
} from './__generated__/FlatFeeMatterAmountPopupModal.gql';
import FlatFeeMatterAmountForm, {
  FlatFeeMatterAmountFormValuesType,
} from './components/FlatFeeMatterAmountForm';
import {
  FETCH_FLAT_FEE_MATTER,
  FETCH_MATTER_FLAT_FEES,
  UPDATE_MATTER_FLAT_FEE_AMOUNT,
} from './FlatFeeMatterAmountPopupModal.gql';
import { FlatFeeMatterAmountPopupModalPropsType } from './FlatFeeMatterAmountPopupModal.types';

import styles from './FlatFeeMatterAmountPopupModal.module.scss';

export default function FlatFeeMatterAmountPopupModal({
  onCancel,
  onComplete,
  isEdit,
  becameBillable,
  currentFlatFeeAmount = 0,
  matterId,
  createdFlatFeeAmount,
}: FlatFeeMatterAmountPopupModalPropsType) {
  const { t } = useIntl();
  const { isLimitedUser } = useProfile();
  const [updateMatterFlatFeeAmount] = useMutation<
    UpdateMatterFlatFeeAmountMutation,
    UpdateMatterFlatFeeAmountMutationVariables
  >(UPDATE_MATTER_FLAT_FEE_AMOUNT);
  const { data: matterData, loading: matterLoading } = useQuery<
    FetchFlatFeeMatterQuery,
    FetchFlatFeeMatterQueryVariables
  >(FETCH_FLAT_FEE_MATTER, {
    fetchPolicy: 'network-only',
    variables: { matterId },
  });
  const { data: flatFeesData, loading: flatFeesLoading } = useQuery<
    FetchMatterFlatFeesQuery,
    FetchMatterFlatFeesQueryVariables
  >(FETCH_MATTER_FLAT_FEES, {
    fetchPolicy: 'network-only',
    variables: { matterId },
  });

  const handleOnCancel = useCallback(() => {
    track(EventNames.CANCEL_ENTRY, {
      entry_type: EntryTypes.FLAT_FEE,
      source_type: SourceTypes.POPUP_MODAL,
    });

    onCancel();
  }, [onCancel]);

  const handleOnSubmit = useCallback(
    async ({ agreedFlatFee }: FlatFeeMatterAmountFormValuesType) => {
      await updateMatterFlatFeeAmount({
        variables: {
          id: matterId,
          agreedFlatFee,
        },
      });

      onComplete();

      notification().entityUpdated(
        t('modal.popup.flatFeeMatterAmount.notification.update.success')
      );
    },
    [updateMatterFlatFeeAmount, matterId, onComplete, t]
  );

  const title = isLimitedUser
    ? t('modal.popup.flatFeeMatterAmount.titleExceeds')
    : t('modal.popup.flatFeeMatterAmount.title');
  const originalAgreedFlatFee = matterData?.matter.agreedFlatFee || 0;
  const totalFlatFeesAmount =
    flatFeesData?.getLimitedFlatFeeMatterStat.flatFeesSum || 0;

  const initialValues: FlatFeeMatterAmountFormValuesType = useMemo(
    () => ({
      originalAgreedFlatFee,
      agreedFlatFee: isEdit
        ? sanitizeAmount(
            totalFlatFeesAmount -
              (becameBillable ? 0 : currentFlatFeeAmount) +
              createdFlatFeeAmount
          )
        : sanitizeAmount(totalFlatFeesAmount + createdFlatFeeAmount),
    }),
    [
      originalAgreedFlatFee,
      isEdit,
      totalFlatFeesAmount,
      becameBillable,
      currentFlatFeeAmount,
      createdFlatFeeAmount,
    ]
  );

  return (
    <Modal
      className={classnames({ [styles.isLimitedUser]: isLimitedUser })}
      title={title}
      onClose={handleOnCancel}
    >
      {matterLoading || flatFeesLoading ? (
        <Loader />
      ) : (
        <FlatFeeMatterAmountForm
          initialValues={initialValues}
          totalFlatFeesAmount={totalFlatFeesAmount}
          onSubmit={handleOnSubmit}
          onCancel={handleOnCancel}
        />
      )}
    </Modal>
  );
}
