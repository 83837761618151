import React from 'react';
import classnames from 'classnames';
import { Text3 } from '@appclose/ui';

import Avatar from 'components/common/Avatar';
import { getContactName } from 'controllers/contact';

import { PersonPropsType } from './Person.types';
import styles from './Person.module.scss';

export default function Person({
  icon,
  firstName,
  lastName,
  showIcon = true,
  iconSize = 24,
  className,
}: PersonPropsType) {
  return (
    <article className={classnames(styles.user, className)}>
      {showIcon && (
        <Avatar
          icon={icon}
          size={iconSize}
          firstName={firstName}
          lastName={lastName}
          className={styles.avatar}
        />
      )}
      <Text3 weight="semibold">{getContactName({ firstName, lastName })}</Text3>
    </article>
  );
}
