import * as Yup from 'yup';

import { UnclearedTransactionTypes } from '__generated__/globalTypes';
import {
  ContactMatterSelectContactType,
  ContactMatterSelectMatterType,
} from 'components/fields/ContactMatterSelectFormField';
import { sanitizeAmount } from 'controllers/amount';
import { MEMO_PURPOSE_FILED_LENGTH_LIMIT } from 'constants/fieldsLimitations';

import { ContactsAndMattersStepFormValuesType } from './ContactsAndMattersStep.types';

export const ContactsAndMattersStepFormSchema = Yup.object().shape<
  Omit<ContactsAndMattersStepFormValuesType, 'statementDate'>
>({
  ledgerBalances: Yup.array().of(
    Yup.object()
      .shape<
        NonNullable<ContactsAndMattersStepFormValuesType['ledgerBalances']>[0]
      >({
        contact: Yup.mixed<ContactMatterSelectContactType>().required(
          'Please select a contact / matter'
        ),
        matter: Yup.mixed<ContactMatterSelectMatterType>().nullable(),
        balance: Yup.number().required('Please enter a trust balance'),
        unclearedTransactions: Yup.array<
          NonNullable<
            NonNullable<
              ContactsAndMattersStepFormValuesType['ledgerBalances']
            >[0]['unclearedTransactions']
          >[0]
        >().when('balance', (balance: number) => {
          return Yup.array<
            NonNullable<
              NonNullable<
                ContactsAndMattersStepFormValuesType['ledgerBalances']
              >[0]['unclearedTransactions']
            >[0]
          >()
            .of(
              Yup.object()
                .shape({
                  amount: Yup.number()
                    .required('Please enter an amount')
                    .moreThan(0, 'Amount should be more than 0'),
                  type: Yup.mixed<UnclearedTransactionTypes>().required(
                    'Please select a type'
                  ),
                  date: Yup.string().required('Please select a date'),
                  memo: Yup.string()
                    .max(
                      MEMO_PURPOSE_FILED_LENGTH_LIMIT,
                      ({ max }) => `Memo maximum length is ${max} symbols`
                    )
                    .required('Please enter a memo'),
                })
                .required()
            )
            .test(
              'unclearedTransactionsLessThenBalance',
              "Current trust balance shouldn't be less than the total uncleared transactions amount",
              (unclearedTransactions) => {
                const unclearedTransactionsAmount = sanitizeAmount(
                  unclearedTransactions?.reduce(
                    (acc, transaction) =>
                      acc +
                      sanitizeAmount(
                        transaction?.type === UnclearedTransactionTypes.DEPOSIT
                          ? -transaction?.amount
                          : transaction?.amount
                      ),
                    0
                  )
                );

                return balance + unclearedTransactionsAmount >= 0;
              }
            );
        }),
      })
      .required()
  ),
});
