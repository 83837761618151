import React from 'react';
import { Fieldset, EnhancedFormArrayField } from '@appclose/core';

import { FieldsetFormArrayPropsType } from './FieldsetFormArray.types';
import styles from './FieldsetFormArray.module.scss';

export default function FieldsetFormArray({
  title,
  ...rest
}: FieldsetFormArrayPropsType) {
  return (
    <Fieldset title={title}>
      <EnhancedFormArrayField {...rest} className={styles.formArrayField} />
    </Fieldset>
  );
}
