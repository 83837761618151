import { gql } from '@apollo/client';

export const FETCH_TRUST_TRANSFER_PDF = gql`
  query FetchTrustTransferPdf($id: ID!) {
    pdf: getTrustTransferPDF(id: $id) {
      data
      mimetype
      name
    }
  }
`;
