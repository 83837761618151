import * as Yup from 'yup';
import {
  EMAIL_VALIDATION_SCHEMA,
  PROFILE_FIRST_NAME_VALIDATION_SCHEMA,
  PROFILE_LAST_NAME_VALIDATION_SCHEMA,
} from '@appclose/core';

import { TeamMemberRoles, AccessLevels } from '__generated__/globalTypes';
import { isPlusPlan } from 'controllers/mode';

import { ProfileFieldsetSchema } from 'components/form/ProfileFieldset';
import { getI18nErrorMessage } from 'i18n';

import { TeamMemberBaseFormValuesType } from './TeamMemberForm.types';

export const TeamMemberBaseFormSchema = Yup.object().shape<
  TeamMemberBaseFormValuesType
>({
  firstName: PROFILE_FIRST_NAME_VALIDATION_SCHEMA,
  lastName: PROFILE_LAST_NAME_VALIDATION_SCHEMA,
  email: EMAIL_VALIDATION_SCHEMA,
  role: Yup.mixed<TeamMemberRoles>().required(
    getI18nErrorMessage('modal.teamMember.form.error.role.required')
  ),
  accessLevel: Yup.mixed<AccessLevels>().test(
    'accessLevelRequiredInEsqoffice',
    getI18nErrorMessage('modal.teamMember.form.error.accessLevel.required'),
    (accessLevel) => !isPlusPlan() || accessLevel !== undefined
  ),
  hourlyRate: Yup.number().nullable(),
});

export const TeamMemberExtendedFormSchema = TeamMemberBaseFormSchema.concat(
  ProfileFieldsetSchema
);
