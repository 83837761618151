import React from 'react';
import { Button, ButtonGroup, Flex, Offset } from '@appclose/ui';
import {
  DatePickerFormField,
  Form,
  FormGroup,
  InputFormField,
} from '@appclose/core';

import { AccessLevels } from '__generated__/globalTypes';
import { SERVER_DATE_FORMAT } from 'constants/date';
import TeamMemberSelectFormField from 'components/fields/TeamMemberSelectFormField';
import { I18n, useIntl } from 'i18n';
import MatterBillingTypeFieldset from 'components/form/MatterBillingTypeFieldset';

import { MatterPopupFormSchema } from './MatterPopupForm.schema';
import { MatterPopupFormPropsType } from './MatterPopupForm.types';

export default function MatterPopupForm({
  onSubmit,
  initialValues,
  onCancel,
}: MatterPopupFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={MatterPopupFormSchema}
    >
      {({ values: { type }, isSubmitting }) => (
        <>
          <Offset bottom="l">
            <FormGroup>
              <InputFormField
                name="name"
                label={t('modal.popup.matter.form.field.name')}
              />
            </FormGroup>
            <FormGroup>
              <DatePickerFormField
                name="dateOpened"
                label={t('modal.popup.matter.form.field.dateOpened')}
                outputDateFormat={SERVER_DATE_FORMAT}
                useHumanReadableFormat
              />
            </FormGroup>
            <FormGroup>
              <InputFormField
                name="matterNumber"
                label={t('modal.popup.matter.form.field.matterNumber')}
                placeholder={t('system.placeholder.matterNumber')}
              />
            </FormGroup>
            <FormGroup>
              <DatePickerFormField
                name="statuteOfLimitations"
                label={t('modal.popup.matter.form.field.statuteOfLimitations')}
                outputDateFormat={SERVER_DATE_FORMAT}
              />
            </FormGroup>
            <FormGroup>
              <TeamMemberSelectFormField
                name="leadAttorney"
                accessLevels={[AccessLevels.SUPER_ADMIN, AccessLevels.ADMIN]}
                label={t('modal.popup.matter.form.field.leadAttorney.label')}
              />
            </FormGroup>
            <MatterBillingTypeFieldset type={type} />
          </Offset>
          <Flex justify="center">
            <ButtonGroup>
              <Button onClick={onCancel}>
                <I18n id="modal.popup.matter.form.button.cancel" />
              </Button>
              <Button loading={isSubmitting} type="submit" skin="primary">
                <I18n id="modal.popup.matter.form.button.create" />
              </Button>
            </ButtonGroup>
          </Flex>
        </>
      )}
    </Form>
  );
}
