import React, { useMemo } from 'react';
import capitalize from 'lodash/capitalize';
import converter from 'number-to-words';
import { useQuery } from '@apollo/client';
import { MICR_TRANSIT, MICR_ON_US } from '@appclose/lib';
import { Flex, Grid, Note } from '@appclose/ui';
import { Loader, Ellipsis } from '@appclose/core';
import { BillingIcon } from '@appclose/icons';

import { BankAccountClasses } from '__generated__/globalTypes';
import Date from 'components/common/Date';
import { I18n } from 'i18n';

import {
  FetchCheckInfoQuery,
  FetchCheckInfoQueryVariables,
} from './__generated__/Check.gql';
import { FETCH_CHECK_INFO } from './Check.gql';
import { CheckPropsType } from './Check.types';
import styles from './Check.module.scss';

export default function Check({
  isPreview = false,
  isToOperating = false,
  payee,
  date,
  memo,
  checkNumber,
  amount = 0,
}: CheckPropsType) {
  const { loading, data } = useQuery<
    FetchCheckInfoQuery,
    FetchCheckInfoQueryVariables
  >(FETCH_CHECK_INFO, { fetchPolicy: 'network-only' });

  const accounts = data?.accounts;
  const operatingAccount = useMemo(
    () =>
      accounts?.find(
        (account) => account.class === BankAccountClasses.OPERATING
      ),
    [accounts]
  );

  const { holderName = '', routingNumber = '', accountNumberLast4 = '' } =
    operatingAccount || {};
  const { address1 = '', address2 = '', city = '', state = '', zipCode = '' } =
    data?.firm.address || {};
  const name = data?.firm.name;
  const dollarsText = capitalize(converter.toWords(amount));
  const centsText = ((amount % 1) * 100).toFixed(0).padStart(2, '0');

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className={styles.check}>
        <Flex alignItems="flex-start" justify="space-between">
          <div className={styles.firmAddress}>
            <p>
              <I18n id="check.trustAccount" values={{ name }} />
            </p>
            <p>
              {address1} {address2 && `, ${address2}`}
            </p>
            <p>
              {city}, {state}, {zipCode}
            </p>
          </div>
          <div>{checkNumber}</div>
        </Flex>
        <Flex justify="flex-end">
          <Flex alignItems="flex-end">
            <span className={styles.label}>
              <I18n id="check.date" />
            </span>
            <Date className={styles.date} value={date} />
          </Flex>
        </Flex>
        <Grid columns="80px auto 24px 120px" rows="60px">
          <div className={styles.underlined}>
            <span className={styles.label} />
          </div>
          <Flex className={styles.underlined} alignItems="center">
            <Ellipsis lines={2} className={styles.text}>
              {isToOperating ? holderName : payee}
            </Ellipsis>
          </Flex>
          <Flex alignItems="flex-end">
            <span className={styles.borderLeft}>
              <BillingIcon className={styles.dollar} height={28} width={28} />
            </span>
          </Flex>
          <Flex alignItems="flex-end">
            <p className={styles.amount}>{amount?.toFixed(2)}</p>
          </Flex>
        </Grid>
        <Grid className={styles.amountText} columns="auto 144px" rows="40px">
          <Flex className={styles.underlined} alignItems="flex-end">
            <p className={styles.dollarText}>
              {dollarsText} & {centsText}/100
            </p>
          </Flex>
          <Flex alignItems="flex-end">
            <span className={styles.label}>
              <I18n id="check.amount" />
            </span>
          </Flex>
        </Grid>
        <Grid columns="1.1fr 0.9fr" rows="80px" gap={[30]}>
          <Flex alignItems="flex-end">
            <span className={styles.label}>
              <I18n id="check.memo" />
            </span>
            <p className={styles.memo}>
              <Ellipsis lines={2} as="span">
                {memo}
              </Ellipsis>
            </p>
          </Flex>
          <div className={styles.underlined} />
        </Grid>
        <p className={styles.mirc}>
          {`${MICR_TRANSIT}${routingNumber}${MICR_TRANSIT}`}
          {`${accountNumberLast4} ${MICR_ON_US}`}
          {isPreview ? '0001' : `${checkNumber}`.padStart(4, '0')}
        </p>
      </div>
      {isPreview && (
        <Note theme="important" offset={{ top: 20 }}>
          <I18n id="check.note" />
        </Note>
      )}
    </>
  );
}
