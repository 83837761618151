import * as Yup from 'yup';

import { getI18nErrorMessage } from 'i18n';
import { CreateReconciliationInput } from '__generated__/globalTypes';
import { FilesFormFieldFilesType } from 'components/fields/FilesFormField';
import { FILES_SCHEMA } from 'schemas/validations/files';

export const ReconciliationFormSchema = Yup.object().shape<
  CreateReconciliationInput & { files?: FilesFormFieldFilesType }
>({
  transactions: Yup.array<string>().required(
    getI18nErrorMessage('modal.reconciliation.form.error.transactions.required')
  ),
  statementDate: Yup.mixed<string | null>().required(
    getI18nErrorMessage(
      'modal.reconciliation.form.error.statementDate.required'
    )
  ),
  statementBalance: Yup.number().required(
    getI18nErrorMessage(
      'modal.reconciliation.form.error.statementBalance.required'
    )
  ),
  files: FILES_SCHEMA,
});
