import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { downloadFileByUrl } from '@appclose/lib';
import { traceError } from '@appclose/core';

import {
  openCancelSignatureConfirmAction,
  openEditSignatureConfirmAction,
  openSendSignatureReminderConfirmAction,
} from 'store/actions';
import { isDemoBuild } from 'controllers/demo';
import { SignatureStatuses } from '__generated__/globalTypes';

import { UseSignatureDocumentActionsPropsType } from './useSignatureDocumentActions.types';
import {
  DownloadSignatureDocumentWithAuditTrailQuery,
  DownloadSignatureDocumentWithAuditTrailQueryVariables,
  DownloadSignatureDocumentWithoutAuditTrailQuery,
  DownloadSignatureDocumentWithoutAuditTrailQueryVariables,
  DownloadSignatureDocumentAuditTrailOnlyQuery,
  DownloadSignatureDocumentAuditTrailOnlyQueryVariables,
} from './__generated__/useSignatureDocumentActions.gql';
import {
  DOWNLOAD_SIGNATURE_DOCUMENT_WITH_AUDIT_TRAIL,
  DOWNLOAD_SIGNATURE_DOCUMENT_WITHOUT_AUDIT_TRAIL,
  DOWNLOAD_SIGNATURE_DOCUMENT_AUDIT_TRAIL_ONLY,
} from './useSignatureDocumentActions.gql';
import { permissionProvider } from '@appclose/core';
import {
  PermissionActions,
  PermissionResources,
} from '../../constants/permissions';

export default function useSignatureDocumentActions({
  id,
  status,
  hasWithoutServiceInfoVersion,
  onCancelSuccess,
}: UseSignatureDocumentActionsPropsType) {
  const dispatch = useDispatch();
  const isDemoMode = isDemoBuild();
  const signatureDocumentAllowedActions = useMemo(
    () => ({
      canBeDownloaded:
        [SignatureStatuses.SIGNED, SignatureStatuses.PARTIALLY_SIGNED].includes(
          status
        ) &&
        permissionProvider().hasPermission(
          PermissionResources.SIGNATURE,
          PermissionActions.READ
        ),
      canBeDownloadedWithoutTrail:
        !!hasWithoutServiceInfoVersion &&
        [SignatureStatuses.SIGNED, SignatureStatuses.PARTIALLY_SIGNED].includes(
          status
        ) &&
        permissionProvider().hasPermission(
          PermissionResources.SIGNATURE,
          PermissionActions.READ
        ),
      canBeDownloadedTrailOnly:
        !!hasWithoutServiceInfoVersion &&
        [SignatureStatuses.SIGNED, SignatureStatuses.PARTIALLY_SIGNED].includes(
          status
        ) &&
        permissionProvider().hasPermission(
          PermissionResources.SIGNATURE,
          PermissionActions.READ
        ),
      canReminderBeSent:
        !isDemoMode &&
        [SignatureStatuses.SENT, SignatureStatuses.PARTIALLY_SIGNED].includes(
          status
        ) &&
        permissionProvider().hasPermission(
          PermissionResources.SIGNATURE,
          PermissionActions.SEND
        ),
      canBeCanceled:
        !isDemoMode &&
        status !== SignatureStatuses.SIGNED &&
        permissionProvider().hasPermission(
          PermissionResources.SIGNATURE,
          PermissionActions.DELETE
        ),
      canBeEdited:
        !isDemoMode &&
        [SignatureStatuses.NOT_PREPARED, SignatureStatuses.SENT].includes(
          status
        ) &&
        permissionProvider().hasPermission(
          PermissionResources.SIGNATURE,
          PermissionActions.UPDATE
        ),
    }),
    [status, hasWithoutServiceInfoVersion, isDemoMode]
  );

  const [downloadSignatureDocumentWithAuditTrail] = useLazyQuery<
    DownloadSignatureDocumentWithAuditTrailQuery,
    DownloadSignatureDocumentWithAuditTrailQueryVariables
  >(DOWNLOAD_SIGNATURE_DOCUMENT_WITH_AUDIT_TRAIL, {
    fetchPolicy: 'network-only',
    onCompleted({ file: { url } }) {
      downloadFileByUrl(url, { inNewTab: true });
    },
  });

  const [downloadSignatureDocumentWithoutAuditTrail] = useLazyQuery<
    DownloadSignatureDocumentWithoutAuditTrailQuery,
    DownloadSignatureDocumentWithoutAuditTrailQueryVariables
  >(DOWNLOAD_SIGNATURE_DOCUMENT_WITHOUT_AUDIT_TRAIL, {
    fetchPolicy: 'network-only',
    onCompleted({ file: { url } }) {
      downloadFileByUrl(url, { inNewTab: true });
    },
  });

  const [downloadSignatureDocumentAuditTrailOnly] = useLazyQuery<
    DownloadSignatureDocumentAuditTrailOnlyQuery,
    DownloadSignatureDocumentAuditTrailOnlyQueryVariables
  >(DOWNLOAD_SIGNATURE_DOCUMENT_AUDIT_TRAIL_ONLY, {
    fetchPolicy: 'network-only',
    onCompleted({ file: { url } }) {
      downloadFileByUrl(url, { inNewTab: true });
    },
  });

  const onCancelSignatureDocument = useCallback(() => {
    dispatch(openCancelSignatureConfirmAction(id, onCancelSuccess));
  }, [dispatch, id, onCancelSuccess]);

  const onEditSignatureDocument = useCallback(() => {
    dispatch(openEditSignatureConfirmAction(id));
  }, [dispatch, id]);

  const onSendSignatureDocumentReminder = useCallback(() => {
    dispatch(openSendSignatureReminderConfirmAction(id));
  }, [dispatch, id]);

  const onDownloadSignatureDocumentWithAuditTrail = useCallback(async () => {
    try {
      await downloadSignatureDocumentWithAuditTrail({
        variables: {
          id,
        },
      });
    } catch (e) {
      traceError(e);
    }
  }, [downloadSignatureDocumentWithAuditTrail, id]);

  const onDownloadSignatureDocumentWithoutAuditTrail = useCallback(async () => {
    try {
      await downloadSignatureDocumentWithoutAuditTrail({
        variables: {
          id,
        },
      });
    } catch (e) {
      traceError(e);
    }
  }, [downloadSignatureDocumentWithoutAuditTrail, id]);

  const onDownloadSignatureDocumentAuditTrailOnly = useCallback(async () => {
    try {
      await downloadSignatureDocumentAuditTrailOnly({
        variables: {
          id,
        },
      });
    } catch (e) {
      traceError(e);
    }
  }, [downloadSignatureDocumentAuditTrailOnly, id]);

  return {
    onCancelSignatureDocument,
    onEditSignatureDocument,
    onSendSignatureDocumentReminder,
    onDownloadSignatureDocumentWithAuditTrail,
    onDownloadSignatureDocumentWithoutAuditTrail,
    onDownloadSignatureDocumentAuditTrailOnly,
    signatureDocumentAllowedActions,
  };
}
