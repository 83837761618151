import React, { useContext } from 'react';
import { Button, ButtonGroup, Title3 } from '@appclose/ui';
import { Form, InputField, Fieldset, traceError } from '@appclose/core';

import { WizardContext } from 'components/common/Wizard';
import { QboAccountTypes } from '__generated__/globalTypes';
import { I18n, useIntl } from 'i18n';

import IntegrationMappingGrid from '../IntegrationMappingGrid';
import IntegrationMappingInfo from '../IntegrationMappingInfo';
import QboAccountSelectFormField from '../QboAccountSelectFormField';
import QboMappingTargetField from '../QboMappingTargetField';
import { TrustAccountingStepSchema } from './TrustAccountingStep.schema';
import {
  TrustAccountingStepPropsType,
  TrustAccountingStepValuesType,
} from './TrustAccountingStep.types';
import styles from './TrustAccountingStep.module.scss';
import QboServiceItemSelectFormField from '../QboServiceItemSelectFormField';

export default function TrustAccountingStep({
  initialValues,
  onStepComplete,
  onPreviousStep,
  onChange,
}: TrustAccountingStepPropsType) {
  const { t } = useIntl();
  const { goToPrevStep, goToNextStep, isLastStep } = useContext(WizardContext);

  const handleOnSubmit = async (values: TrustAccountingStepValuesType) => {
    try {
      await onStepComplete(values, isLastStep);
      goToNextStep();
    } catch (e) {
      traceError(e);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      validationSchema={TrustAccountingStepSchema}
    >
      {({ isSubmitting, values, submitCount }) => {
        const { operatingAccount, isEsqash } = values;

        if (!submitCount) {
          onChange(values);
        }

        return (
          <>
            <Fieldset
              title={t(
                'modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.title'
              )}
            >
              <IntegrationMappingInfo>
                <I18n id="modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.info" />
              </IntegrationMappingInfo>
              <IntegrationMappingGrid
                esqTitle={t(
                  'modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.esqTitle'
                )}
                qboTitle={t(
                  'modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.qboTitle'
                )}
              >
                <QboMappingTargetField
                  title={t(
                    'modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.trustAccount'
                  )}
                  tooltip={t(
                    'modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.trustAccount.tooltip'
                  )}
                />
                <QboAccountSelectFormField
                  name="trustAccount"
                  accountType={QboAccountTypes.BANK}
                  exclude={[operatingAccount]}
                />
              </IntegrationMappingGrid>
              {!isEsqash && (
                <>
                  <Title3
                    className={styles.title}
                    offset={{ top: 20, bottom: 16 }}
                  >
                    <I18n id="modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.owedToOperating.title" />
                  </Title3>
                  <IntegrationMappingInfo>
                    <I18n id="modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.owedToOperating.info" />
                  </IntegrationMappingInfo>
                  <IntegrationMappingGrid
                    esqTitle={t(
                      'modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.owedToOperating.esqTitle'
                    )}
                    qboTitle={t(
                      'modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.owedToOperating.qboTitle'
                    )}
                  >
                    <QboMappingTargetField
                      title={t(
                        'modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.owedToOperating.owedToOperatingAccount'
                      )}
                    />
                    <InputField
                      readOnly
                      value={t(
                        'modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.owedToOperating.owedToOperatingAccount.value'
                      )}
                    />
                  </IntegrationMappingGrid>
                </>
              )}
              <Title3 className={styles.title} offset={{ top: 20, bottom: 16 }}>
                <I18n id="modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.trustDepositServiceItem.title" />
              </Title3>
              <IntegrationMappingInfo>
                <I18n id="modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.trustDepositServiceItem.info" />
              </IntegrationMappingInfo>
              <IntegrationMappingGrid
                esqTitle={t(
                  'modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.trustDepositServiceItem.esqTitle'
                )}
                qboTitle={t(
                  'modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.trustDepositServiceItem.qboTitle'
                )}
              >
                <QboMappingTargetField
                  title={t(
                    'modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.trustDepositServiceItem.trustDepositItem'
                  )}
                  tooltip={t(
                    'modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.trustDepositServiceItem.trustDepositItem.tooltip'
                  )}
                />
                <QboServiceItemSelectFormField name="trustDepositItem" />
              </IntegrationMappingGrid>
              <Title3 className={styles.title} offset={{ top: 20, bottom: 16 }}>
                <I18n id="modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.liabilityAccount.title" />
              </Title3>
              <IntegrationMappingInfo>
                <I18n id="modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.liabilityAccount.info" />
              </IntegrationMappingInfo>
              <IntegrationMappingGrid
                esqTitle={t(
                  'modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.liabilityAccount.esqTitle'
                )}
                qboTitle={t(
                  'modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.liabilityAccount.qboTitle'
                )}
              >
                <QboMappingTargetField
                  title={t(
                    'modal.qboIntegrationSetup.step.trustAccounting.mapTrustAccounting.liabilityAccount.trustDepositAccount'
                  )}
                />
                <QboAccountSelectFormField
                  name="trustDepositAccount"
                  accountType={QboAccountTypes.OTHER_CURRENT_LIABILITIES}
                />
              </IntegrationMappingGrid>
            </Fieldset>
            <ButtonGroup>
              <Button
                onClick={() => {
                  onPreviousStep(values);
                  goToPrevStep();
                }}
                disabled={isSubmitting}
              >
                <I18n id="modal.qboIntegrationSetup.step.mapTrustAccounting.back" />
              </Button>
              <Button type="submit" skin="primary" loading={isSubmitting}>
                <I18n id="modal.qboIntegrationSetup.step.mapTrustAccounting.finish" />
              </Button>
            </ButtonGroup>
          </>
        );
      }}
    </Form>
  );
}
