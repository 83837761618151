import React from 'react';
import { Flex, Button } from '@appclose/ui';
import { Form } from '@appclose/core';

import BankStatementsFieldset from 'components/form/BankStatementsFieldset';
import { I18n, useIntl } from 'i18n';

import ReconciliationTransactionsTable from './components/ReconciliationTransactionsTable';
import ReconciliationInfoFieldset from './components/ReconciliationInfoFieldset';
import Summary from './components/Summary';
import { ReconciliationFormSchema } from './ReconciliationForm.schema';
import { ReconciliationFormPropsType } from './ReconciliationForm.types';
import styles from './ReconciliationForm.module.scss';

export default function ReconciliationForm({
  initialValues,
  onSubmit,
  onClose,
}: ReconciliationFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      validationSchema={ReconciliationFormSchema}
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting }) => {
        const submitTitle = values.canCompleteReconciliation
          ? t('modal.reconciliation.form.submit.saveReconcile')
          : t('modal.reconciliation.form.submit.finishLater');

        return (
          <div>
            <Flex>
              <ReconciliationTransactionsTable />
              <div className={styles.sidebar}>
                <ReconciliationInfoFieldset />
                <Summary />
                <BankStatementsFieldset id={initialValues.reconciliationId} />
              </div>
            </Flex>
            <Flex className={styles.buttons}>
              <Button onClick={onClose}>
                <I18n id="modal.reconciliation.form.cancel" />
              </Button>
              <Button loading={isSubmitting} type="submit" skin="primary">
                {submitTitle}
              </Button>
            </Flex>
          </div>
        );
      }}
    </Form>
  );
}
