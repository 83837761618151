import { gql } from '@apollo/client';

export const FETCH_GOOGLE_CALENDARS = gql`
  query FetchGoogleCalendars {
    calendars: listGoogleCalendars {
      calendarId
      calendarName
    }
  }
`;

export const SYNC_GOOGLE_CALENDAR = gql`
  mutation SyncGoogleCalendar($input: CalendarGoogleSynchronizeInput!) {
    synchronizeGoogleCalendar(calendarGoogleSynchronizeInput: $input) {
      google {
        calendarName
        active
      }
    }
  }
`;
