import { gql } from '@apollo/client';

import { MATTER_SELECT_FRAGMENT } from 'components/fields/MatterSelectFormField';

export const CREATE_MATTER = gql`
  mutation CreateMatter($matter: CreateMatterInput!) {
    createMatter(createMatterInput: $matter) {
      ...MatterSelect
    }
  }
  ${MATTER_SELECT_FRAGMENT}
`;
