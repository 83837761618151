import React, { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import omit from 'lodash/omit';
import { dateManager, Loader, traceError } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { UpdateProfileInput } from '__generated__/globalTypes';
import { INITIAL_PHONE } from 'constants/phone';
import ProfileForm, {
  ProfileFormActionsType,
  ProfileFormValuesType,
} from 'components/form/ProfileForm';
import { INITIAL_ADDRESS } from 'constants/address';
import { SERVER_DATE_FORMAT } from 'constants/date';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { I18n, useIntl } from 'i18n';

import {
  FetchProfileQuery,
  FetchProfileQueryVariables,
  UpdateProfileMutation,
  UpdateProfileMutationVariables,
} from './__generated__/ProfileModal.gql';
import { FETCH_PROFILE, UPDATE_PROFILE } from './ProfileModal.gql';
import { ProfileModalPropsType } from './ProfileModal.types';

export default function ProfileModal({ onClose }: ProfileModalPropsType) {
  const { t } = useIntl();
  const { loading, data } = useQuery<
    FetchProfileQuery,
    FetchProfileQueryVariables
  >(FETCH_PROFILE, {
    fetchPolicy: 'network-only',
  });
  const [updateProfile] = useMutation<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >(UPDATE_PROFILE);

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });

  const handleOnSubmit = useCallback(
    async (
      values: ProfileFormValuesType,
      { setSubmitting }: ProfileFormActionsType
    ) => {
      const { profileAvatar, practiceAreas, ...restProfileData } = values;

      const profile: UpdateProfileInput = {
        practiceAreas: practiceAreas
          ?.split(',')
          .map((practiceArea) => practiceArea.trim()),
        ...restProfileData,
      };

      try {
        await updateProfile({
          variables: { profile, profileAvatar },
        });

        notification().entityUpdated(t('modal.profile.notification.updated'));
        onClose();
      } catch (e) {
        traceError(e);
        setSubmitting(false);
      }
    },
    [onClose, updateProfile, t]
  );

  let initialValues: ProfileFormValuesType = {
    ...omit(data?.profile, 'id'),
    addresses: data?.profile?.addresses || [INITIAL_ADDRESS],
    phoneNumbers: data?.profile?.phoneNumbers || [INITIAL_PHONE],
    practiceAreas: (data?.profile?.practiceAreas || []).join(', '),
    licensedSince: data?.profile?.licensedSince
      ? dateManager()
          .parse(data?.profile?.licensedSince)
          .format(SERVER_DATE_FORMAT)
      : null,
  };

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.profile.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading ? (
          <Loader />
        ) : (
          <ProfileForm
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            onCancel={onConfirmClose}
            onChange={onFormChange}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
