import { gql } from '@apollo/client';

export const TIME_ENTRY_ACTIVITY_SELECT_FRAGMENT = gql`
  fragment TimeEntryActivitySelect on TimerActivityType {
    id
    name
  }
`;

export const FETCH_TIME_ENTRY_ACTIVITIES = gql`
  query FetchTimeEntryActivities(
    $filter: FilterTimerActivityInput
    $order: OrderTimerActivityInput
    $skip: Int
    $take: Int
  ) {
    listTimeEntryActivities: listTimerActivities(
      listTimerActivitiesInput: {
        filter: $filter
        skip: $skip
        take: $take
        order: $order
      }
    ) {
      items {
        ...TimeEntryActivitySelect
      }
      total
    }
  }
  ${TIME_ENTRY_ACTIVITY_SELECT_FRAGMENT}
`;
