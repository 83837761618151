import { gql } from '@apollo/client';

const FIRM_PRINTING_CHECKS_SETTINGS_FRAGMENT = gql`
  fragment FirmPrintingChecksSettingsFragment on FirmType {
    id
    printingChecksSettings {
      mode
      offset
    }
  }
`;

export const FETCH_FIRM_PRINTING_CHECKS_SETTINGS = gql`
  query FetchFirmPrintingChecksSettings {
    firm: getFirm {
      ...FirmPrintingChecksSettingsFragment
    }
  }
  ${FIRM_PRINTING_CHECKS_SETTINGS_FRAGMENT}
`;

export const UPDATE_FIRM_PRINTING_CHECKS_SETTINGS = gql`
  mutation UpdateFirmPrintingChecksSettings(
    $input: SavePrintingChecksSettingsInput!
  ) {
    updatePrintingChecksSettings(savePrintingChecksSettingsInput: $input) {
      ...FirmPrintingChecksSettingsFragment
    }
  }
  ${FIRM_PRINTING_CHECKS_SETTINGS_FRAGMENT}
`;
