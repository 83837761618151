import React, { useCallback, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { getQueryName } from 'gql/utils';
import { useMutation, useQuery } from '@apollo/client';
import { Loader } from '@appclose/core';

import { QboMappingsType, QboSyncStatus } from '__generated__/globalTypes';
import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import Wizard, { WizardCompleted, WizardStep } from 'components/common/Wizard';
import { isFreePlan } from 'controllers/mode';
import {
  clearQboMappings,
  restoreQboMappings,
  storeQboMappings,
} from 'controllers/qboMappings';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { I18n, useIntl } from 'i18n';

import {
  GetQboMappingsPreferencesQuery,
  GetQboMappingsPreferencesQueryVariables,
  SaveQboMappingsPreferencesMutation,
  SaveQboMappingsPreferencesMutationVariables,
} from './__generated__/QboIntegrationSetupModal.gql';
import BankAccountsAndServiceItemsStep from './components/BankAccountsAndServiceItemsStep';
import DiscountsAndRefundsStep from './components/DiscountsAndRefundsStep';
import ImportContactsStep from './components/ImportContactsStep';
import QboIntegrationSetupResult from './components/QboIntegrationSetupResult';
import TrustAccountingStep from './components/TrustAccountingStep';
import {
  GET_QBO_INFO_PREFERENCES,
  SAVE_QBO_MAPPINGS_PREFERENCES,
} from './QboIntegrationSetupModal.gql';
import {
  QboIntegrationMappingType,
  QboIntegrationSetupModalPropsType,
} from './QboIntegrationSetupModal.types';

export default function QboIntegrationSetupModal({
  onClose,
  isEdit,
}: QboIntegrationSetupModalPropsType) {
  const { t } = useIntl();
  const [loading, setLoading] = useState(true);
  const [qboMappings, setQboMappings] = useState<QboIntegrationMappingType>({
    isEsqash: false,
    discountEnabled: false,
    operatingAccount: '',
    trustDepositItem: '',
    refundItem: '',
  });
  const [initialQboMappings, setInitialQboMappings] = useState<
    QboIntegrationMappingType
  >();
  const { data } = useQuery<
    GetQboMappingsPreferencesQuery,
    GetQboMappingsPreferencesQueryVariables
  >(GET_QBO_INFO_PREFERENCES, {
    fetchPolicy: 'network-only',
  });
  const [saveQboMappings] = useMutation<
    SaveQboMappingsPreferencesMutation,
    SaveQboMappingsPreferencesMutationVariables
  >(SAVE_QBO_MAPPINGS_PREFERENCES, {
    refetchQueries: [getQueryName(GET_QBO_INFO_PREFERENCES)],
    awaitRefetchQueries: true,
  });

  const { onConfirmClose, setShowConfirm } = useCloseConfirm({
    onClose,
  });

  const onChangeQboMappings = useCallback(
    (newQboMappings: Partial<QboIntegrationMappingType>) => {
      setShowConfirm(!isEqual(newQboMappings, initialQboMappings));
      storeQboMappings(newQboMappings);
    },
    [initialQboMappings, setShowConfirm]
  );

  const onUpdateQboMappings = useCallback(
    (values: Partial<QboIntegrationMappingType>) => {
      setQboMappings(values as QboIntegrationMappingType);
    },
    []
  );

  const onStepComplete = useCallback(
    async (values: Partial<QboIntegrationMappingType>, isLastStep: boolean) => {
      onUpdateQboMappings(values);

      if (isLastStep) {
        const {
          isEsqash,
          discountEnabled,
          ...mappings
        } = values as QboIntegrationMappingType;

        await saveQboMappings({
          variables: {
            input: mappings,
          },
        });

        clearQboMappings();
      }
    },
    [onUpdateQboMappings, saveQboMappings]
  );

  const loadedQboMappings = data?.qboMappings;
  const defaultResources = data?.defaultResources;
  const isSuccessfulConnected = data?.qbo.syncStatus === QboSyncStatus.FINISHED;

  useEffect(() => {
    if (loadedQboMappings && defaultResources) {
      const { defaultDiscountAccount } = defaultResources;

      setQboMappings({
        ...restoreQboMappings(defaultResources),
        ...(loadedQboMappings.mappings || ({} as QboMappingsType)),
        discountEnabled: !!defaultDiscountAccount,
        isEsqash: isFreePlan(),
      });

      setInitialQboMappings({
        ...(loadedQboMappings.mappings || ({} as QboMappingsType)),
        discountEnabled: !!defaultDiscountAccount,
        isEsqash: isFreePlan(),
      });

      setLoading(false);
    }
  }, [defaultResources, loadedQboMappings]);

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.qboIntegrationSetup.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Wizard>
              {(!isEdit || !isSuccessfulConnected) && (
                <WizardStep
                  title={t(
                    'modal.qboIntegrationSetup.step.importContacts.title'
                  )}
                >
                  <ImportContactsStep onClose={onClose} />
                </WizardStep>
              )}
              <WizardStep
                title={t(
                  'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.title'
                )}
              >
                <BankAccountsAndServiceItemsStep
                  initialValues={qboMappings}
                  onStepComplete={onStepComplete}
                  onPreviousStep={onUpdateQboMappings}
                  onClose={onConfirmClose}
                  onChange={onChangeQboMappings}
                />
              </WizardStep>
              <WizardStep
                title={
                  isFreePlan()
                    ? t(
                        'modal.qboIntegrationSetup.step.discountsAndRefunds.title.free'
                      )
                    : t(
                        'modal.qboIntegrationSetup.step.discountsAndRefunds.title.plus'
                      )
                }
              >
                <DiscountsAndRefundsStep
                  initialValues={qboMappings}
                  onStepComplete={onStepComplete}
                  onPreviousStep={onUpdateQboMappings}
                  onChange={onChangeQboMappings}
                />
              </WizardStep>
              <WizardStep
                title={t(
                  'modal.qboIntegrationSetup.step.trustAccounting.title'
                )}
              >
                <TrustAccountingStep
                  initialValues={qboMappings}
                  onStepComplete={onStepComplete}
                  onPreviousStep={onUpdateQboMappings}
                  onChange={onChangeQboMappings}
                />
              </WizardStep>
              <WizardCompleted>
                <QboIntegrationSetupResult onClose={onClose} />
              </WizardCompleted>
            </Wizard>
          </>
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
