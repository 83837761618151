import React from 'react';
import { Button, ButtonGroup } from '@appclose/ui';
import {
  AmountFormField,
  dateManager,
  DatePickerFormField,
  Form,
  FormGroup,
  TextAreaFormField,
  ToggleFormField,
  Fieldset,
} from '@appclose/core';

import ContactMatterFieldset from 'components/form/ContactMatterFieldset';
import FormGrid from 'components/common/FormGrid';
import BilledByField from 'components/fields/BilledByField';
import AdditionalInfoFieldset from 'components/form/AdditionalInfoFieldset';
import { SERVER_DATE_FORMAT } from 'constants/date';
import { I18n, useIntl } from 'i18n';
import { Entities } from 'constants/entities';

import { FlatFeeFormPropsType } from './FlatFeeForm.types';
import { FlatFeeFormSchema } from './FlatFeeForm.schema';
import styles from './FlatFeeForm.module.scss';

function FlatFeeForm({
  initialValues,
  isEdit,
  profileId,
  onSubmit,
  onCancel,
  onChange,
}: FlatFeeFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      validationSchema={FlatFeeFormSchema}
      onSubmit={onSubmit}
      onChange={onChange}
    >
      {({ values: { id }, isSubmitting }) => (
        <>
          <ContactMatterFieldset allowAddNewMatter />
          <Fieldset title={t('modal.flatFee.form.fieldset.billingInfo')}>
            <FormGroup>
              <TextAreaFormField
                name="description"
                label={t('modal.flatFee.form.field.description')}
              />
            </FormGroup>
            <FormGroup>
              <FormGrid>
                <AmountFormField
                  name="amount"
                  label={t('modal.flatFee.form.field.amount')}
                />
                <DatePickerFormField
                  name="date"
                  label={t('modal.flatFee.form.field.date')}
                  outputDateFormat={SERVER_DATE_FORMAT}
                  maxDate={dateManager().parse().endOf('day').valueOf()}
                  useHumanReadableFormat
                />
                <div className={styles.toggle}>
                  <ToggleFormField
                    name="billable"
                    label={t('modal.flatFee.form.field.billable')}
                  />
                </div>
                <BilledByField profileId={profileId} />
              </FormGrid>
            </FormGroup>
          </Fieldset>
          <AdditionalInfoFieldset entityType={Entities.EXPENSE} entityId={id} />
          <ButtonGroup>
            <Button onClick={onCancel}>
              <I18n id="modal.flatFee.form.button.cancel" />
            </Button>
            <Button type="submit" skin="primary" loading={isSubmitting}>
              <I18n
                id={
                  isEdit
                    ? 'modal.flatFee.form.button.update'
                    : 'modal.flatFee.form.button.create'
                }
              />
            </Button>
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}

export default FlatFeeForm;
