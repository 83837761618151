import * as Yup from 'yup';

import { PrintingChecksModes } from '__generated__/globalTypes';
import { getI18nErrorMessage } from 'i18n';

import { PrintCheckFormValuesType } from './PrintChecksForm.types';

export const PrintChecksFormSchema = Yup.object().shape<
  PrintCheckFormValuesType
>({
  startCheckNumber: Yup.number()
    .nullable()
    .when('mode', {
      is: (mode) => mode !== PrintingChecksModes.QBO,
      then: Yup.number().when(
        'lastCheckNumber',
        (lastCheckNumber: number | undefined) =>
          Yup.number()
            .required(
              getI18nErrorMessage(
                'modal.printChecks.form.error.startCheckNumber.required'
              )
            )
            .max(999999, ({ max }) =>
              getI18nErrorMessage(
                'modal.printChecks.form.error.startCheckNumber.max',
                { max }
              )
            )
            .moreThan(lastCheckNumber || 0, ({ more }) =>
              getI18nErrorMessage(
                'modal.printChecks.form.error.startCheckNumber.moreThenLast',
                {
                  more,
                }
              )
            )
      ),
    }),
  lastCheckNumber: Yup.number().nullable(),
  selectedChecks: Yup.array<string>().required(
    getI18nErrorMessage('modal.printChecks.form.error.selectedChecks.required')
  ),
  mode: Yup.mixed<PrintingChecksModes>(),
});
