import React, { useCallback } from 'react';
import omit from 'lodash/omit';
import { useMutation, useQuery } from '@apollo/client';
import { Loader, Modal } from '@appclose/core';

import { useIntl } from 'i18n';
import notification from 'controllers/notification';
import { track } from 'controllers/analytics';
import { EntryTypes, EventNames, SourceTypes } from 'constants/analytics';

import {
  CreateThirdPartyPayeeMutation,
  CreateThirdPartyPayeeMutationVariables,
  FetchThirdPartyPayeeQuery,
  FetchThirdPartyPayeeQueryVariables,
  EditThirdPartyPayeeMutation,
  EditThirdPartyPayeeMutationVariables,
} from './__generated__/ThirdPartyPayeeModal.gql';
import ThirdPartyPayeeForm, {
  ThirdPartyPayeeFormValuesType,
} from './components/ThirdPartyPayeeForm';
import {
  CREATE_THIRD_PARTY_PAYEE,
  FETCH_THIRD_PARTY_PAYEE,
  EDIT_THIRD_PARTY_PAYEE,
} from './ThirdPartyPayeeModal.gql';
import { ThirdPartyPayeeModalPropsType } from './ThirdPartyPayeeModal.types';
import styles from './ThirdPartyPayeeModal.module.scss';

export default function ThirdPartyPayeeModal({
  onCancel,
  onComplete,
  withNote = true,
  id,
}: ThirdPartyPayeeModalPropsType) {
  const { t } = useIntl();
  const isEdit = !!id;
  const [createThirdPartyPayee] = useMutation<
    CreateThirdPartyPayeeMutation,
    CreateThirdPartyPayeeMutationVariables
  >(CREATE_THIRD_PARTY_PAYEE);
  const [editThirdPartyPayee] = useMutation<
    EditThirdPartyPayeeMutation,
    EditThirdPartyPayeeMutationVariables
  >(EDIT_THIRD_PARTY_PAYEE);
  const { data, loading } = useQuery<
    FetchThirdPartyPayeeQuery,
    FetchThirdPartyPayeeQueryVariables
  >(FETCH_THIRD_PARTY_PAYEE, {
    variables: { id: id as string },
    skip: !isEdit,
  });

  const handleOnCancel = useCallback(() => {
    track(EventNames.CANCEL_ENTRY, {
      entry_type: EntryTypes.THIRD_PARTY_PAYEE,
      source_type: SourceTypes.POPUP_MODAL,
    });

    onCancel();
  }, [onCancel]);

  const handleOnSubmit = useCallback(
    async (values: ThirdPartyPayeeFormValuesType) => {
      if (isEdit) {
        const result = await editThirdPartyPayee({
          variables: {
            input: {
              id: id as string,
              ...values,
            },
          },
        });

        result.data?.payee && onComplete(result.data.payee);

        notification().entityCreated(
          t('modal.popup.thirdPartyPayee.notification.update.success')
        );
      } else {
        const result = await createThirdPartyPayee({
          variables: { input: values },
        });

        result.data?.payee && onComplete(result.data.payee);

        notification().entityCreated(
          t('modal.popup.thirdPartyPayee.notification.create.success')
        );
      }
    },
    [createThirdPartyPayee, editThirdPartyPayee, id, isEdit, onComplete, t]
  );

  const initialValues = {
    ...omit(data?.payee || {}, ['__typename', 'status', 'id']),
  } as ThirdPartyPayeeFormValuesType;

  return (
    <Modal
      className={styles.modal}
      onClose={handleOnCancel}
      title={t(
        isEdit
          ? 'modal.popup.thirdPartyPayee.title.edit'
          : 'modal.popup.thirdPartyPayee.title.new'
      )}
    >
      {loading ? (
        <Loader />
      ) : (
        <ThirdPartyPayeeForm
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          onCancel={handleOnCancel}
          withNote={withNote}
          isEdit={isEdit}
        />
      )}
    </Modal>
  );
}
