import { gql } from '@apollo/client';

import { AVATAR_FRAGMENT } from 'components/common/Avatar';
import { TEAM_MEMBER_SELECT_FRAGMENT } from 'components/fields/TeamMemberSelectFormField';

const MATTER_FRAGMENT = gql`
  fragment MatterModalFragment on MatterType {
    id
    name
    contact {
      id
      name
      isEntity
    }
    dateOpened
    matterNumber
    statuteOfLimitations
    billable
    notes
    teamMembers {
      member {
        ...TeamMemberSelect
      }
      role
      hourlyRate
    }
    type
    agreedFlatFee
  }
  ${AVATAR_FRAGMENT}
  ${TEAM_MEMBER_SELECT_FRAGMENT}
`;

export const FETCH_PROFILE = gql`
  query FetchProfile {
    profile: getProfile {
      id
      firstName
      lastName
      hourlyRate
      role
      accessLevel
      email
      picture {
        ...Avatar
      }
    }
  }
  ${AVATAR_FRAGMENT}
`;

export const CREATE_MATTER = gql`
  mutation CreateMatter($matter: CreateMatterInput!) {
    createMatter(createMatterInput: $matter) {
      id
    }
  }
`;

export const UPDATE_MATTER = gql`
  mutation UpdateMatter($matter: UpdateMatterInput!) {
    updateMatter(updateMatterInput: $matter) {
      ...MatterModalFragment
    }
  }
  ${MATTER_FRAGMENT}
`;

export const FETCH_MATTER = gql`
  query FetchMatter($id: ID!) {
    matter: getMatter(id: $id) {
      ...MatterModalFragment
    }
    invoices: listInvoices(listInvoiceInput: { filter: { matterId: $id } }) {
      total
    }
  }
  ${MATTER_FRAGMENT}
`;

export const FETCH_CONTACT = gql`
  query FetchContact($id: ID!) {
    contact: getContact(id: $id) {
      id
      name
      isEntity
    }
  }
`;
