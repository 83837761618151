import * as Yup from 'yup';

import { SubExpenseCategoryCostTypes } from '__generated__/globalTypes';
import { SelectContactType } from 'components/fields/ContactSelectFormField';
import { SelectMatterType } from 'components/fields/MatterSelectFormField';
import { SelectTeamMemberType } from 'components/fields/TeamMemberSelectFormField';
import { ExpenseCategorySelectType } from 'components/fields/ExpenseCategorySelectFormField';
import { getI18nErrorMessage } from 'i18n';
import { FILES_SCHEMA } from 'schemas/validations/files';

import { ExpenseFormValuesType } from './ExpenseForm.types';

export const ExpenseFormSchema = Yup.object().shape<ExpenseFormValuesType>({
  contact: Yup.mixed<SelectContactType>().required(
    getI18nErrorMessage('modal.expense.form.error.contact.required')
  ),
  matter: Yup.mixed<SelectMatterType>().required(
    getI18nErrorMessage('modal.expense.form.error.matter.required')
  ),
  amount: Yup.number()
    .moreThan(
      0,
      getI18nErrorMessage('modal.expense.form.error.amount.moreThan0')
    )
    .required(getI18nErrorMessage('modal.expense.form.error.amount.required')),
  date: Yup.string()
    .nullable()
    .required(getI18nErrorMessage('modal.expense.form.error.date.required')),
  costType: Yup.mixed<SubExpenseCategoryCostTypes>().required(
    getI18nErrorMessage('modal.expense.form.error.costType.required')
  ),
  category: Yup.mixed<ExpenseCategorySelectType>().required(
    getI18nErrorMessage('modal.expense.form.error.category.required')
  ),
  description: Yup.string()
    .max(3500, ({ max }) =>
      getI18nErrorMessage('modal.expense.form.error.description.maxLength', {
        max,
      })
    )
    .nullable(),
  billedBy: Yup.mixed<SelectTeamMemberType>().required(
    getI18nErrorMessage('modal.expense.form.error.billedBy.required')
  ),
  notes: Yup.string()
    .max(3500, ({ max }) =>
      getI18nErrorMessage('modal.expense.form.error.notes.maxLength', {
        max,
      })
    )
    .nullable(),
  files: FILES_SCHEMA,
});
