import { sortByDateField } from '@appclose/lib';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLazyQuery, useSubscription } from '@apollo/client';
import {
  Checkbox,
  Flex,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@appclose/ui';
import {
  Amount,
  FormArrayField,
  MediaQueryDesktop,
  MediaQueryMobile,
  OverlayLoader,
  useIsMobileDevice,
  Fieldset,
  Ellipsis,
} from '@appclose/core';
import { BillingIcon } from '@appclose/icons';

import { I18n, useIntl } from 'i18n';
import { ExpenseStatuses, OrderTypes } from '__generated__/globalTypes';
import Date from 'components/common/Date';
import Person from 'components/common/Person';
import { getContactName } from 'controllers/contact';
import MobileTable from 'components/common/MobileTable';
import FlatFeeStatus from 'components/common/FlatFeeStatus';

import FlatFeesFieldTableHeader from './components/FlatFeesFieldTableHeader';
import {
  FETCH_FLAT_FEES,
  ON_FLAT_FEE_CREATE,
  ON_FLAT_FEE_DELETE,
  ON_FLAT_FEE_UPDATE,
} from './FlatFeesField.gql';
import {
  FetchFlatFeesQuery,
  FetchFlatFeesQueryVariables,
  FlatFeesFieldFragment,
  FlatFeeUpdatedSubscription,
  FlatFeeUpdatedSubscriptionVariables,
  FlatFeeRemovedSubscription,
  FlatFeeRemovedSubscriptionVariables,
  FlatFeeCreatedSubscription,
  FlatFeeCreatedSubscriptionVariables,
} from './__generated__/FlatFeesField.gql';
import { FlatFeesFieldPropsType } from './FlatFeesField.types';
import styles from './FlatFeesField.module.scss';

export default function FlatFeesField({
  mattersIds,
  defaultValues = [],
  values = [],
}: FlatFeesFieldPropsType) {
  const { t } = useIntl();
  const isMobile = useIsMobileDevice();

  const [fetchFlatFees, { loading, data }] = useLazyQuery<
    FetchFlatFeesQuery,
    FetchFlatFeesQueryVariables
  >(FETCH_FLAT_FEES, { fetchPolicy: 'network-only' });

  const flatFeeItems = data?.flatFees?.items;
  const flatFees = useMemo(() => {
    if (!mattersIds || !flatFeeItems) {
      return [];
    }

    const flatFees: FlatFeesFieldFragment[] = [
      ...(defaultValues || []),
      ...flatFeeItems,
    ];

    return sortByDateField(flatFees, {
      fieldName: 'date',
      order: 'DESC',
    });
  }, [defaultValues, flatFeeItems, mattersIds]);

  const count = (mattersIds && flatFees.length) || '';
  const selected = values || defaultValues || [];
  const total = mattersIds
    ? selected.reduce((sum, { amount }) => sum + amount, 0)
    : 0;
  const hasFlatFees = !!flatFees.length;
  const filter = useMemo(
    () => ({
      mattersIds: mattersIds,
      statuses: [ExpenseStatuses.BILLABLE],
    }),
    [mattersIds]
  );
  const flatFeeIds = flatFees.map(({ id }) => id);

  const refetchFlatFees = useCallback(() => {
    if (mattersIds) {
      fetchFlatFees({
        variables: {
          input: {
            filter,
            order: { date: OrderTypes.DESC },
            take: null,
          },
        },
      });
    }
  }, [fetchFlatFees, filter, mattersIds]);

  useEffect(() => {
    refetchFlatFees();
  }, [refetchFlatFees]);

  useSubscription<
    FlatFeeCreatedSubscription,
    FlatFeeCreatedSubscriptionVariables
  >(ON_FLAT_FEE_CREATE, {
    variables: {
      flatFeeSubscriptionInput: filter,
    },
    onSubscriptionData: () => refetchFlatFees(),
  });

  useSubscription<
    FlatFeeRemovedSubscription,
    FlatFeeRemovedSubscriptionVariables
  >(ON_FLAT_FEE_DELETE, {
    variables: {
      flatFeeIds,
    },
    onSubscriptionData: () => refetchFlatFees(),
  });

  useSubscription<
    FlatFeeUpdatedSubscription,
    FlatFeeUpdatedSubscriptionVariables
  >(ON_FLAT_FEE_UPDATE, {
    variables: {
      flatFeeIds,
    },
  });

  const TableComponent = isMobile ? MobileTable : Table;

  return (
    <FormArrayField name="flatFees">
      {({ push, remove, values }) => (
        <Fieldset title={t('modal.invoice.form.flatFees.title', { count })}>
          {hasFlatFees || loading ? (
            <OverlayLoader loading={loading} className={styles.content}>
              {hasFlatFees && (
                <TableComponent>
                  <FlatFeesFieldTableHeader flatFees={flatFees} />
                  <TableBody>
                    <>
                      {flatFees.map((flatFee) => {
                        const {
                          id,
                          description,
                          date,
                          status,
                          amount,
                          billedBy,
                          matter,
                        } = flatFee;

                        return (
                          <TableRow
                            key={id}
                            onClick={() => {
                              if (
                                !values?.some(
                                  ({ id: valueId }) => id === valueId
                                )
                              ) {
                                push(flatFee);
                              } else {
                                const index = selected.findIndex(
                                  (item) => item.id === id
                                );

                                if (index !== -1) {
                                  remove(index);
                                }
                              }
                            }}
                          >
                            <TableCell width={`${isMobile ? 30 : 50}px`}>
                              <Checkbox
                                readOnly
                                checked={
                                  !!selected.find((item) => item.id === id)
                                }
                              />
                            </TableCell>
                            <MediaQueryMobile>
                              <TableCell
                                noWordBreak
                                maxWidth="0"
                                width="30%"
                                theme="light"
                              >
                                <Ellipsis className={styles.description}>
                                  {description}
                                </Ellipsis>
                                <Date value={date} />
                              </TableCell>
                              <TableCell>
                                <p>{getContactName(billedBy)}</p>
                              </TableCell>
                              <TableCell theme="strong" align="right">
                                <p>
                                  <Amount value={amount} />
                                </p>
                                <FlatFeeStatus status={status} />
                              </TableCell>
                            </MediaQueryMobile>
                            <MediaQueryDesktop>
                              <TableCell width="50px">
                                <BillingIcon />
                              </TableCell>
                              <TableCell theme="light" maxWidth="0" width="25%">
                                <Ellipsis className={styles.description}>
                                  {description}
                                </Ellipsis>
                                <Date value={date} />
                              </TableCell>
                              <TableCell maxWidth="0" width="20%">
                                <Ellipsis>{matter.name}</Ellipsis>
                              </TableCell>
                              <TableCell noWordBreak>
                                {billedBy && (
                                  <Person
                                    firstName={billedBy.firstName}
                                    lastName={billedBy.lastName}
                                    icon={billedBy.picture}
                                  />
                                )}
                              </TableCell>
                              <TableCell>
                                <FlatFeeStatus status={status} />
                              </TableCell>
                              <TableCell
                                noWordBreak
                                theme="strong"
                                align="right"
                              >
                                <Amount value={amount} />
                              </TableCell>
                            </MediaQueryDesktop>
                          </TableRow>
                        );
                      })}
                    </>
                  </TableBody>
                </TableComponent>
              )}
            </OverlayLoader>
          ) : (
            <p className={styles.emptyResult}>
              <I18n id="modal.invoice.form.flatFees.empty" />
            </p>
          )}
          {hasFlatFees && (
            <Flex
              alignItems="center"
              justify="space-between"
              className={styles.total}
            >
              <p className={styles.totalTitle}>
                <I18n
                  id="modal.invoice.form.flatFees.total"
                  values={{ total: selected.length || 0 }}
                />
              </p>
              <Amount value={total} className={styles.totalAmount} />
            </Flex>
          )}
        </Fieldset>
      )}
    </FormArrayField>
  );
}
