import React from 'react';
import {
  DatePickerFormField,
  FormGroup,
  InputFormField,
  USAStateSelectFormField,
  UpperFirstInputFormField,
  TextAreaFormField,
  StaticField,
  Fieldset,
  EmailExternalLink,
} from '@appclose/core';

import { useIntl } from 'i18n';
import FormGrid from 'components/common/FormGrid';
import AvatarAttach from 'components/common/AvatarAttach';
import AddressesFieldset from 'components/form/AddressesFieldset';
import PhoneFieldset from 'components/form/PhoneFieldset';
import GenderSelectFormField from 'components/fields/GenderSelectFormField';
import { getContactInitials } from 'controllers/contact';
import { SERVER_DATE_FORMAT } from 'constants/date';

import { FirmFieldsetPropsType } from './ProfileFieldset.types';
import styles from './ProfileFieldset.module.scss';

export default function ProfileFieldset({
  values: { profileAvatar, picture, firstName, lastName, email },
  setFieldValue,
}: FirmFieldsetPropsType) {
  const { t } = useIntl();

  return (
    <>
      <Fieldset title={t('form.profileFieldset.fieldsetTitle')}>
        <FormGroup>
          <FormGrid>
            <div>
              <FormGroup>
                <UpperFirstInputFormField
                  name="firstName"
                  label={t('form.profileFieldset.firstName')}
                />
              </FormGroup>
              <FormGroup>
                <UpperFirstInputFormField
                  name="lastName"
                  label={t('form.profileFieldset.lastName')}
                />
              </FormGroup>
              <FormGroup>
                <UpperFirstInputFormField
                  name="middleName"
                  label={t('form.profileFieldset.middleName')}
                />
              </FormGroup>
            </div>
            <AvatarAttach
              avatarFile={profileAvatar}
              avatar={picture}
              onChange={(file: File, picture: string | null) => {
                setFieldValue('profileAvatar', file);
                setFieldValue('picture', picture);
              }}
              placeholder={getContactInitials({ firstName, lastName }, true)}
            />
          </FormGrid>
        </FormGroup>
        <FormGroup>
          <FormGrid>
            <GenderSelectFormField name="gender" />
            <StaticField
              className={styles.email}
              title={t('form.profileFieldset.email')}
            >
              <EmailExternalLink email={email} />
            </StaticField>
            <InputFormField
              name="title"
              label={t('form.profileFieldset.title')}
            />
            <InputFormField
              name="website"
              label={t('form.profileFieldset.website')}
            />
          </FormGrid>
        </FormGroup>
      </Fieldset>
      <PhoneFieldset name="phoneNumbers" />
      <AddressesFieldset name="addresses" />
      <Fieldset title={t('form.profileFieldset.professionalInfoTitle')}>
        <FormGroup>
          <FormGrid>
            <InputFormField
              name="licenseNumber"
              label={t('form.profileFieldset.licenseNumber')}
            />
            <USAStateSelectFormField
              name="licensedInStates"
              label={t('form.profileFieldset.licensedInStates')}
              isMulti
            />
            <DatePickerFormField
              name="licensedSince"
              label={t('form.profileFieldset.licensedSince')}
              outputDateFormat={SERVER_DATE_FORMAT}
            />
          </FormGrid>
        </FormGroup>
        <FormGroup>
          <TextAreaFormField
            name="practiceAreas"
            label={t('form.profileFieldset.practiceAreas')}
          />
        </FormGroup>
      </Fieldset>
    </>
  );
}
