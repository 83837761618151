import { TFuncType } from 'i18n';
import {
  getLeadTitle,
  getSplittedInboxName,
  LeadTitleData,
} from 'controllers/lead';
import {
  InboxRecordsType,
  LeadContactInput,
  LeadSources,
  PhoneTypes,
} from '__generated__/globalTypes';

import {
  FetchLeadContactQuery,
  FetchLeadQuery,
} from './__generated__/LeadModal.gql';
import { LeadModalTabs } from './LeadModal.types';
import { LeadFormValuesType } from './components/LeadForm';

export function getTitle(t: TFuncType, lead?: FetchLeadQuery['getLead']) {
  if (!lead) {
    return t('modal.lead.title');
  }

  return getLeadTitle(lead as LeadTitleData);
}

export function getEditLeadInitialValues(
  initialValues: LeadFormValuesType,
  lead: FetchLeadQuery['getLead']
): LeadFormValuesType {
  const { id, individual, entity, isEntity, ...rest } = lead;
  let result;

  if (individual) {
    const { firstName, lastName, middleName, email } = individual;

    result = {
      id,
      selectedTab: LeadModalTabs.INDIVIDUAL,
      individual: {
        ...initialValues.individual,
        firstName,
        lastName,
        middleName,
        email,
        ...rest,
      },
    };
  }

  if (entity) {
    const { website, entityName, contacts } = entity;

    result = {
      id,
      selectedTab: LeadModalTabs.ENTITY,
      entity: {
        ...initialValues.entity,
        entityName,
        website,
        contacts,
        ...rest,
      },
    };
  }

  return result as LeadFormValuesType;
}

export function getInboxLeadInitialValues(
  initialValues: LeadFormValuesType,
  inboxData: InboxRecordsType
): LeadFormValuesType {
  return {
    ...initialValues,
    individual: {
      ...initialValues.individual,
      firstName: getSplittedInboxName(inboxData.name).firstName,
      lastName: getSplittedInboxName(inboxData.name).lastName,
      email: inboxData.email,
      notes: inboxData.description,
      phoneNumbers: [
        {
          number: inboxData.phoneNumber?.replace(/\D/g, '').slice(-10),
          type: PhoneTypes.MOBILE,
          isPrimary: true,
        },
      ],
      leadSource: inboxData.source,
    },
    entity: {
      ...initialValues.entity,
      entityName: inboxData.name,
      contacts: [
        { ...initialValues.entity?.contacts[0], email: inboxData.email },
      ],
      notes: inboxData.description,
      phoneNumbers: [
        {
          number: inboxData.phoneNumber?.replace(/\D/g, '').slice(-10),
          type: PhoneTypes.MOBILE,
          isPrimary: true,
        },
      ],
      leadSource: inboxData.source,
    },
  } as LeadFormValuesType;
}

export function getLeadContactInitialValues(
  initialValues: LeadFormValuesType,
  leadContact?: FetchLeadContactQuery['getContact']
): LeadFormValuesType {
  if (!leadContact) {
    return initialValues;
  }

  return {
    ...initialValues,
    selectedTab: leadContact.isEntity
      ? LeadModalTabs.ENTITY
      : LeadModalTabs.INDIVIDUAL,
    entity: {
      ...initialValues.entity,
      entityName: leadContact.name,
      website: leadContact.website || initialValues.entity?.website,
      phoneNumbers:
        leadContact.phoneNumbers || initialValues.entity?.phoneNumbers,
      contacts: (leadContact.billingContacts ||
        initialValues.entity?.contacts ||
        []) as LeadContactInput[],
      originatingAttorney:
        leadContact.originatingAttorney ||
        initialValues.entity!.originatingAttorney,
      leadSource: LeadSources.NONE,
      isImportant: false,
    },
    individual: {
      ...initialValues.individual,
      firstName: leadContact.firstName || initialValues.individual!.firstName,
      lastName: leadContact.lastName || initialValues.individual!.lastName,
      middleName:
        leadContact.middleName || initialValues.individual!.middleName,
      email: leadContact.email || initialValues.individual!.email,
      phoneNumbers:
        leadContact.phoneNumbers || initialValues.individual?.phoneNumbers,
      originatingAttorney:
        leadContact.originatingAttorney ||
        initialValues.individual!.originatingAttorney,
      leadSource: LeadSources.NONE,
      isImportant: false,
    },
  };
}
