import React from 'react';
import { Grid } from '@appclose/ui';

import ReconciliationSummary from 'components/common/ReconciliationSummary';
import {
  isClearedDeposit,
  isClearedDisbursement,
  isUnclearedDeposit,
  isUnclearedDisbursement,
} from 'controllers/reconciliation';
import { useIntl } from 'i18n';

import TransactionsTable from './components/TransactionsTable';
import BankStatementsForm from './components/BankStatementsForm';
import { ReconciliationDetailsPropsType } from './ReconciliationDetails.types';

export default function ReconciliationDetails({
  reconciliation: { id, transactions, summary },
}: ReconciliationDetailsPropsType) {
  const { t } = useIntl();
  const clearedDeposits = transactions?.filter(isClearedDeposit);
  const clearedDisbursements = transactions?.filter(isClearedDisbursement);
  const unclearedDeposits = transactions?.filter(isUnclearedDeposit);
  const unclearedDisbursements = transactions?.filter(isUnclearedDisbursement);

  return (
    <div>
      <Grid columns="790px 440px" gap={[60]}>
        <div>
          <TransactionsTable
            title={t('modal.reconciliationDetails.fieldset.clearedDeposits')}
            transactions={clearedDeposits}
          />
          <TransactionsTable
            isDisbursement
            title={t(
              'modal.reconciliationDetails.fieldset.clearedDisbursements'
            )}
            transactions={clearedDisbursements}
          />
          <TransactionsTable
            isUncleared
            title={t('modal.reconciliationDetails.fieldset.unclearedDeposits')}
            transactions={unclearedDeposits}
          />
          <TransactionsTable
            isDisbursement
            isUncleared
            title={t(
              'modal.reconciliationDetails.fieldset.unclearedDisbursements'
            )}
            transactions={unclearedDisbursements}
          />
        </div>
        <div>
          <ReconciliationSummary readonly loading={false} summary={summary} />
          <BankStatementsForm reconciliationId={id} />
        </div>
      </Grid>
    </div>
  );
}
