import React, {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { permissionProvider } from '@appclose/core';
import { AllowPermissionType } from '@appclose/lib';

export const PermissionsContext = createContext<{
  token: string | null;
  setToken: (currToken: string | null) => void;
  hasPermissions: (permissions: AllowPermissionType) => boolean;
  hasPermission: (
    resource: string,
    action: string,
    resourceId?: string
  ) => boolean;
}>({
  token: null,
  setToken: () => {},
  hasPermissions: () => false,
  hasPermission: () => false,
});

export default function PermissionsProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [token, setToken] = useState<string | null>(null);

  const handleOnTokenChange = useCallback((newToken: string | null) => {
    setToken(newToken);
  }, []);

  const hasPermissions = useCallback(
    (permissions) => {
      return permissionProvider().hasPermissions(permissions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token]
  );

  const hasPermission = useCallback(
    (resource: string, action: string, resourceId?: string) => {
      return permissionProvider().hasPermission(resource, action, resourceId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token]
  );

  const contextValue = useMemo(
    () => ({
      token,
      setToken: handleOnTokenChange,
      hasPermissions,
      hasPermission,
    }),
    [handleOnTokenChange, hasPermission, hasPermissions, token]
  );

  return (
    <PermissionsContext.Provider value={contextValue}>
      {children}
    </PermissionsContext.Provider>
  );
}
