import React, { useCallback } from 'react';
import set from 'lodash/set';
import { useFormContext, FieldError } from '@appclose/core';

import { AttachedFileType } from 'components/common/AttachedFile';
import AttachFiles from 'components/common/AttachFiles';

import { AttachFilesFormFieldPropsType } from './AttachFilesFormField.types';

export default function AttachFilesFormField({
  filesName,
  attachesName,
  onChange,
  ...rest
}: AttachFilesFormFieldPropsType) {
  const { setValues, getFieldMeta } = useFormContext();

  if (!filesName && !attachesName) {
    throw new Error('You must define a `setFieldValue` or `attachesName` prop');
  }

  const { value: files = undefined } = filesName
    ? getFieldMeta<File[]>(filesName)
    : {};
  const { value: attaches = undefined } = attachesName
    ? getFieldMeta<AttachedFileType[]>(attachesName)
    : {};

  const handleOnChange = useCallback(
    (files, attaches) => {
      setValues((values: any) => {
        let newValues = values;

        if (filesName) {
          newValues = set(newValues, filesName, files);
        }

        if (attachesName) {
          newValues = set(newValues, attachesName, attaches);
        }

        return newValues;
      });

      onChange && onChange(files, attaches);
    },
    [attachesName, filesName, onChange, setValues]
  );

  return (
    <>
      <AttachFiles
        {...rest}
        onChange={handleOnChange}
        files={files}
        attaches={attaches}
      />
      {filesName && <FieldError name={filesName} />}
      {attachesName && <FieldError name={attachesName} />}
    </>
  );
}
