import React, { useState } from 'react';
import {
  Button,
  AddButton,
  ButtonGroup,
  Flex,
  Offset,
  RadioGroup,
} from '@appclose/ui';
import {
  Form,
  FormGroup,
  RadioFormField,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Fieldset,
  DeletableGroup,
} from '@appclose/core';

import ContactSelectFormField from 'components/fields/ContactSelectFormField';
import { ContactTypes } from 'constants/contacts';
import { I18n, useIntl } from 'i18n';

import { ContactMatterPopupModalTabs } from '../../ContactMatterPopupModal.types';
import ContactFieldset from './components/ContactFieldset';
import EntityFieldset from './components/EntityFieldset';
import MatterFieldset from './components/MatterFieldset';
import { ContactMatterPopupFormSchema } from './ContactMatterPopupForm.schema';
import { ContactMatterPopupFormPropsType } from './ContactMatterPopupForm.types';

export default function ContactMatterPopupForm({
  initialValues = {} as any,
  defaultMatter,
  onSubmit,
  onCancel,
}: ContactMatterPopupFormPropsType) {
  const { t } = useIntl();
  const [isDefaultMatterWasSetUp, setIsDefaultMatterWasSetUpStatus] = useState(
    false
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ContactMatterPopupFormSchema}
    >
      {({
        isSubmitting,
        setFieldValue,
        values: { selectedTab, contact, matter },
      }) => {
        const isEntityType = contact.contactType === ContactTypes.ENTITY;
        const hasMatter = !!matter;

        return (
          <>
            <Offset bottom="xl">
              <Tabs
                onSelect={(index) => {
                  setFieldValue('selectedTab', index);

                  if (index === 1 && !matter) {
                    setFieldValue('matter', defaultMatter);
                  } else if (
                    index === 0 &&
                    matter &&
                    !isDefaultMatterWasSetUp
                  ) {
                    setFieldValue('matter', null);
                  }
                }}
              >
                <TabList>
                  <Tab>
                    <I18n id="modal.popup.contactMatter.form.tab.contact" />
                  </Tab>
                  <Tab>
                    <I18n id="modal.popup.contactMatter.form.tab.matter" />
                  </Tab>
                </TabList>
                <TabPanel>
                  <Fieldset
                    title={
                      contact.contactType === ContactTypes.ENTITY
                        ? t('modal.popup.contactMatter.form.contact.infoTitle')
                        : t('modal.popup.contactMatter.form.entity.infoTitle')
                    }
                  >
                    <RadioGroup>
                      <Flex direction="row" wrap="wrap">
                        <Offset right="xl">
                          <RadioFormField
                            name="contact.contactType"
                            value={ContactTypes.INDIVIDUAL}
                            label={t(
                              'modal.popup.contactMatter.form.contact.contactType.individual'
                            )}
                          />
                        </Offset>
                        <RadioFormField
                          name="contact.contactType"
                          value={ContactTypes.ENTITY}
                          label={t(
                            'modal.popup.contactMatter.form.contact.contactType.entity'
                          )}
                        />
                      </Flex>
                    </RadioGroup>
                    {isEntityType ? <EntityFieldset /> : <ContactFieldset />}
                  </Fieldset>
                  {matter ? (
                    <DeletableGroup
                      enabled
                      position="start"
                      onDelete={() => {
                        setFieldValue('matter', null);
                      }}
                    >
                      <MatterFieldset
                        title={t(
                          'modal.popup.contactMatter.form.contact.matterTitle'
                        )}
                      />
                    </DeletableGroup>
                  ) : (
                    <AddButton
                      onClick={() => {
                        setIsDefaultMatterWasSetUpStatus(true);
                        setFieldValue('matter', defaultMatter);
                      }}
                      size={28}
                      label={t('modal.popup.contactMatter.form.addMatter')}
                      skin="success"
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  <Fieldset
                    title={t(
                      'modal.popup.contactMatter.form.matter.contactTitle'
                    )}
                  >
                    <FormGroup>
                      <ContactSelectFormField name="contact.existingContact" />
                    </FormGroup>
                  </Fieldset>
                  <MatterFieldset
                    title={t('modal.popup.contactMatter.form.matter.infoTitle')}
                    type={matter?.type}
                  />
                </TabPanel>
              </Tabs>
            </Offset>
            <Flex justify="center">
              <ButtonGroup>
                <Button onClick={onCancel}>
                  <I18n id="modal.popup.contact.form.cancel" />
                </Button>
                <Button loading={isSubmitting} type="submit" skin="primary">
                  <I18n
                    id={
                      selectedTab === ContactMatterPopupModalTabs.MATTER
                        ? 'modal.popup.contactMatter.form.create.matter'
                        : hasMatter
                        ? 'modal.popup.contactMatter.form.create.contactMatter'
                        : 'modal.popup.contactMatter.form.create.contact'
                    }
                  />
                </Button>
              </ButtonGroup>
            </Flex>
          </>
        );
      }}
    </Form>
  );
}
