import { sortByDateField } from '@appclose/lib';
import React, { useEffect, useMemo, useCallback } from 'react';
import { useLazyQuery, useSubscription } from '@apollo/client';
import {
  Checkbox,
  Flex,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@appclose/ui';
import {
  Amount,
  FormArrayField,
  MediaQueryDesktop,
  MediaQueryMobile,
  OverlayLoader,
  useIsMobileDevice,
  Fieldset,
  Ellipsis,
} from '@appclose/core';
import { TimerIcon } from '@appclose/icons';

import Date from 'components/common/Date';
import Person from 'components/common/Person';
import TimeEntryStatus from 'components/common/TimeEntryStatus';
import { getContactName } from 'controllers/contact';
import { OrderTypes, ExpenseStatuses } from '__generated__/globalTypes';
import MobileTable from 'components/common/MobileTable/MobileTable';
import { I18n, useIntl } from 'i18n';

import TimeEntriesFieldTableHeader from './components/TimeEntriesFieldTableHeader';
import {
  FETCH_TIME_ENTRIES,
  ON_TIME_ENTRY_CREATE,
  ON_TIME_ENTRY_REMOVE,
  ON_TIME_ENTRY_UPDATE,
} from './TimeEntriesField.gql';
import {
  FetchTimeEntriesQuery,
  FetchTimeEntriesQueryVariables,
  TimeEntryCreateSubscription,
  TimeEntryCreateSubscriptionVariables,
  TimeEntriesFieldFragment,
  TimeEntryRemoveSubscription,
  TimeEntryRemoveSubscriptionVariables,
  TimeEntryUpdateSubscription,
  TimeEntryUpdateSubscriptionVariables,
} from './__generated__/TimeEntriesField.gql';
import { TimeEntriesFieldPropsType } from './TimeEntriesField.types';
import styles from './TimeEntriesField.module.scss';

export default function TimeEntriesField({
  mattersIds,
  defaultValues = [],
  values = [],
}: TimeEntriesFieldPropsType) {
  const { t } = useIntl();
  const isMobile = useIsMobileDevice();
  const [fetchTimeEntries, { loading, data }] = useLazyQuery<
    FetchTimeEntriesQuery,
    FetchTimeEntriesQueryVariables
  >(FETCH_TIME_ENTRIES, {
    fetchPolicy: 'network-only',
  });

  const timeEntriesItems = data?.timeEntries?.items;
  const timeEntries = useMemo(() => {
    if (!mattersIds || !timeEntriesItems) {
      return [];
    }

    const timeEntries: TimeEntriesFieldFragment[] = [
      ...(defaultValues || []),
      ...timeEntriesItems,
    ];

    return sortByDateField(timeEntries, {
      fieldName: 'date',
      order: 'DESC',
    });
  }, [timeEntriesItems, defaultValues, mattersIds]);

  const timeEntryIds = timeEntries.map(({ id }) => id);
  const count = (mattersIds && timeEntries.length) || '';
  const selected = values || [];
  const total = mattersIds
    ? selected.reduce((sum, { amount }) => sum + amount, 0)
    : 0;
  const hasTimeEntries = !!timeEntries.length;
  const filter = useMemo(
    () => ({
      mattersIds,
      statuses: [ExpenseStatuses.BILLABLE],
    }),
    [mattersIds]
  );

  const refetchTimeEntries = useCallback(() => {
    if (mattersIds) {
      fetchTimeEntries({
        variables: {
          input: {
            filter,
            order: { date: OrderTypes.DESC },
            take: null,
          },
        },
      });
    }
  }, [mattersIds, filter, fetchTimeEntries]);

  useEffect(() => {
    refetchTimeEntries();
  }, [refetchTimeEntries]);

  useSubscription<
    TimeEntryCreateSubscription,
    TimeEntryCreateSubscriptionVariables
  >(ON_TIME_ENTRY_CREATE, {
    variables: {
      filter,
    },
    onSubscriptionData: refetchTimeEntries,
  });

  useSubscription<
    TimeEntryUpdateSubscription,
    TimeEntryUpdateSubscriptionVariables
  >(ON_TIME_ENTRY_UPDATE, {
    variables: {
      includeIds: timeEntryIds,
    },
  });

  useSubscription<
    TimeEntryRemoveSubscription,
    TimeEntryRemoveSubscriptionVariables
  >(ON_TIME_ENTRY_REMOVE, {
    variables: {
      includeIds: timeEntryIds,
    },
    onSubscriptionData: () => refetchTimeEntries(),
  });

  const TableComponent = isMobile ? MobileTable : Table;

  return (
    <FormArrayField name="timeEntries">
      {({ push, remove, values }) => (
        <Fieldset title={t('modal.invoice.form.timeEntries.title', { count })}>
          {hasTimeEntries || loading ? (
            <OverlayLoader loading={loading} className={styles.content}>
              {hasTimeEntries && (
                <TableComponent>
                  <TimeEntriesFieldTableHeader timeEntries={timeEntries} />
                  <TableBody>
                    <>
                      {timeEntries.map((timeEntry) => {
                        const {
                          id,
                          date,
                          activity,
                          billedBy,
                          amount,
                          status,
                          matter,
                        } = timeEntry;

                        return (
                          <TableRow
                            key={id}
                            onClick={() => {
                              if (
                                !values?.some(
                                  ({ id: valueId }) => id === valueId
                                )
                              ) {
                                push(timeEntry);
                              } else {
                                const index = selected.findIndex(
                                  (item) => item.id === id
                                );

                                if (index !== -1) {
                                  remove(index);
                                }
                              }
                            }}
                          >
                            <TableCell width={`${isMobile ? 30 : 50}px`}>
                              <Checkbox
                                readOnly
                                checked={
                                  !!selected.find((item) => item.id === id)
                                }
                              />
                            </TableCell>
                            <MediaQueryMobile>
                              <TableCell
                                noWordBreak
                                maxWidth="0"
                                width="30%"
                                theme="light"
                              >
                                <Ellipsis className={styles.activity}>
                                  {activity?.name}
                                </Ellipsis>
                                <Date value={date} />
                              </TableCell>
                              <TableCell>{getContactName(billedBy)}</TableCell>
                              <TableCell theme="strong" align="right">
                                <p>
                                  <Amount value={amount} />
                                </p>
                                <TimeEntryStatus status={status} />
                              </TableCell>
                            </MediaQueryMobile>
                            <MediaQueryDesktop>
                              <TableCell width="50px">
                                <TimerIcon />
                              </TableCell>
                              <TableCell maxWidth="0" width="25%" theme="light">
                                <Ellipsis className={styles.activity}>
                                  {activity?.name}
                                </Ellipsis>
                                <Date value={date} />
                              </TableCell>
                              <TableCell maxWidth="0" width="20%">
                                <Ellipsis className={styles.activity}>
                                  {matter.name}
                                </Ellipsis>
                              </TableCell>
                              <TableCell noWordBreak>
                                {billedBy && (
                                  <Person
                                    firstName={billedBy.firstName}
                                    lastName={billedBy.lastName}
                                    icon={billedBy.picture}
                                  />
                                )}
                              </TableCell>
                              <TableCell>
                                <TimeEntryStatus status={status} />
                              </TableCell>
                              <TableCell
                                noWordBreak
                                theme="strong"
                                align="right"
                              >
                                <Amount value={amount} />
                              </TableCell>
                            </MediaQueryDesktop>
                          </TableRow>
                        );
                      })}
                    </>
                  </TableBody>
                </TableComponent>
              )}
            </OverlayLoader>
          ) : (
            <p className={styles.emptyResult}>
              <I18n id="modal.invoice.form.timeEntries.empty" />
            </p>
          )}
          {hasTimeEntries && (
            <Flex
              alignItems="center"
              justify="space-between"
              className={styles.total}
            >
              <p className={styles.totalTitle}>
                <I18n
                  id="modal.invoice.form.timeEntries.total"
                  values={{ total: selected.length || 0 }}
                />
              </p>
              <Amount value={total} className={styles.totalAmount} />
            </Flex>
          )}
        </Fieldset>
      )}
    </FormArrayField>
  );
}
