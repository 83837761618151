import React from 'react';
import { openConfirmAction } from '@appclose/core';

import { KYB_MODAL } from 'constants/modals';
import { isDemoBuild } from 'controllers/demo';
import { EventNames } from 'constants/analytics';
import { track } from 'controllers/analytics';
import { logout } from 'controllers/session';
import { openModal } from 'controllers/modal';
import { i18nMessage } from 'i18n';

export const openLogoutConfirm = (from?: 'onboarding' | 'agreement') =>
  openConfirmAction({
    name: i18nMessage('store.profile.logoutConfirm.name'),
    content: (
      <>
        {(() => {
          if (isDemoBuild()) {
            return i18nMessage('store.profile.logoutConfirm.content.main.demo');
          }

          if (from === 'onboarding') {
            return i18nMessage(
              'store.profile.logoutConfirm.content.fromOnboarding'
            );
          }

          if (from === 'agreement') {
            return i18nMessage(
              'store.profile.logoutConfirm.content.fromAgreement'
            );
          }

          return i18nMessage('store.profile.logoutConfirm.content.main');
        })()}
      </>
    ),
    okButtonTitle: i18nMessage('store.profile.logoutConfirm.okButton'),
    okButtonSkin: 'success',
    cancelButtonTitle: i18nMessage('store.profile.logoutConfirm.cancelButton'),
    onCancel() {
      track(EventNames.COMPLETE_ACC_SETUP_LOGOUT_CLICK, { button: 'no' });
    },
    onConfirm: logout,
  });

export const openLimitedAccessConfirm = (isOwner: boolean | undefined) =>
  openConfirmAction({
    name: isOwner
      ? i18nMessage('store.profile.limitedAccessConfirm.owner.name')
      : i18nMessage('store.profile.limitedAccessConfirm.teamMember.name'),
    content: isOwner
      ? i18nMessage('store.profile.limitedAccessConfirm.owner.content')
      : i18nMessage('store.profile.limitedAccessConfirm.teamMember.content'),
    okButtonTitle: isOwner
      ? i18nMessage('store.profile.limitedAccessConfirm.owner.okButton')
      : i18nMessage('store.profile.limitedAccessConfirm.teamMember.okButton'),
    okButtonSkin: 'primary',
    onConfirm: () =>
      isOwner &&
      openModal(KYB_MODAL),
  });

export const openWaitForKybConfirm = () =>
  openConfirmAction({
    name: i18nMessage('store.profile.waitForKybConfirm.name'),
    content: i18nMessage('store.profile.waitForKybConfirm.content'),
    okButtonTitle: i18nMessage('store.profile.waitForKybConfirm.okButton'),
    okButtonSkin: 'primary',
    onConfirm: () => {},
  });
