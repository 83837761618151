import { Session } from '@appclose/lib';
import gqlClient, { gqlClientServices } from 'gql';
import { history, SIGN_IN_ROUTE } from '@appclose/core';

import { EventNames } from 'constants/analytics';
import { track } from 'controllers/analytics';

import {
  SignOutMutation,
  SignOutMutationVariables,
} from './__generated__/session.gql';
import { SIGN_OUT } from './session.gql';

import { SessionBackupKeys } from './session.types';

export const session = new Session();

export async function logout() {
  await gqlClient.mutate<SignOutMutation, SignOutMutationVariables>({
    mutation: SIGN_OUT,
  });

  await gqlClientServices.logout();
  track(EventNames.COMPLETE_ACC_SETUP_LOGOUT_CLICK, { button: 'yes' });
  deleteAllBackups();

  history.push(SIGN_IN_ROUTE);

  gqlClient.clearStore();
}

export function deleteAllBackups(): void {
  deleteBackup(SessionBackupKeys.DEMO_FREE);
  deleteBackup(SessionBackupKeys.DEMO_PLUS);
}

export function backupTokens(backupKey: SessionBackupKeys): void {
  const refreshToken = session.getRefreshToken();
  const accessToken = session.getAccessToken();

  if (refreshToken && accessToken) {
    localStorage.setItem(`${backupKey}_REFRESH_TOKEN`, refreshToken);
    localStorage.setItem(`${backupKey}_ACCESS_TOKEN`, accessToken);
  }
}

export function restoreTokensFromBackup(backupKey: SessionBackupKeys): void {
  const refreshToken = localStorage.getItem(`${backupKey}_REFRESH_TOKEN`);
  const accessToken = localStorage.getItem(`${backupKey}_ACCESS_TOKEN`);

  if (refreshToken && accessToken) {
    session.setRefreshToken(refreshToken)
    session.setAccessToken(accessToken)
  }
}

export function getTokensFromBackup(backupKey: SessionBackupKeys) {
  const refreshToken = localStorage.getItem(`${backupKey}_REFRESH_TOKEN`);
  const accessToken = localStorage.getItem(`${backupKey}_ACCESS_TOKEN`);

  return {
    refreshToken,
    accessToken,
  }
}

export function deleteBackup(backupKey: SessionBackupKeys): void {
  localStorage.removeItem(`${backupKey}_REFRESH_TOKEN`);
  localStorage.removeItem(`${backupKey}_ACCESS_TOKEN`);
}

export function hasBackup(backupKey: SessionBackupKeys): boolean {
  const refreshToken = localStorage.getItem(`${backupKey}_REFRESH_TOKEN`);
  const accessToken = localStorage.getItem(`${backupKey}_ACCESS_TOKEN`);

  return !!refreshToken && !!accessToken;
}
