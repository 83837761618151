import React from 'react';
import { Button, ButtonGroup } from '@appclose/ui';
import {
  AmountFormField,
  dateManager,
  DatePickerFormField,
  Form,
  FormGroup,
  Fieldset,
} from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import { SERVER_DATE_FORMAT } from 'constants/date';
import { useIntl } from 'i18n';

import { DepositFormPropsType } from './DepositForm.types';
import { DepositFormSchema } from './DepositForm.schema';
import PaymentsFieldset from './components/PaymentsFieldset';
import { I18n } from '@appclose/core/src/i18n';

export default function DepositForm({
  initialValues,
  onSubmit,
  onCancel,
  onChange,
}: DepositFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      validationSchema={DepositFormSchema}
      onSubmit={onSubmit}
      onChange={onChange}
    >
      {({ values: { payments, amount }, setFieldValue, isSubmitting }) => {
        const totalAmount = payments.reduce(
          (sum, { amount }) => sum + amount,
          0
        );

        if (totalAmount !== amount) {
          setFieldValue('amount', totalAmount, true);
        }

        return (
          <>
            <PaymentsFieldset />
            <Fieldset title={t('modal.deposit.form.fieldset.details.title')}>
              <FormGroup>
                <FormGrid>
                  <AmountFormField
                    name="amount"
                    label={t('modal.deposit.form.fieldset.details.amount')}
                    disabled
                  />
                  <DatePickerFormField
                    useHumanReadableFormat
                    name="date"
                    label={t('modal.deposit.form.fieldset.details.date')}
                    outputDateFormat={SERVER_DATE_FORMAT}
                    maxDate={dateManager().parse().endOf('day').valueOf()}
                  />
                </FormGrid>
              </FormGroup>
            </Fieldset>
            <ButtonGroup>
              <Button onClick={onCancel}>
                <I18n id="modal.deposit.form.cancel" />
              </Button>
              <Button skin="primary" type="submit" loading={isSubmitting}>
                <I18n id="modal.deposit.form.submit" />
              </Button>
            </ButtonGroup>
          </>
        );
      }}
    </Form>
  );
}
