import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import { Button, ButtonGroup } from '@appclose/ui';
import {
  dateManager,
  DatePickerFormField,
  ExternalLink,
  Fieldset,
  FormGroup,
  ToggleFormField,
  useFormContext,
} from '@appclose/core';
import { isDemoBuild } from 'controllers/demo';
import { isPlusPlan } from 'controllers/mode';

import {
  BankAccountClasses,
  PaymentMethodsTypes,
} from '__generated__/globalTypes';
import PaymentMethodSelectFormField from 'components/fields/PaymentMethodSelectFormField';
import AdditionalInfoFieldset from 'components/form/AdditionalInfoFieldset';
import FormGrid from 'components/common/FormGrid';
import { TERMS_OF_SERVICE_ROUTE } from 'constants/routes';
import { I18n, useIntl } from 'i18n';
import { Entities } from 'constants/entities';
import {
  FetchLastFinishedReconciliationQuery,
  FetchLastFinishedReconciliationQueryVariables,
} from 'gql/shared/__generated__/reconsiliation.gql';
import { FETCH_LAST_FINISHED_RECONCILIATION } from 'gql/shared/reconsiliation.gql';

import InvoicesField from './components/InvoicesField';
import PaymentDetailsFieldset from './components/PaymentDetailsFieldset';
import styles from './CommonFormContent.module.scss';
import { ReceivePaymentFormValuesType } from '../../PaymentInfoFormsBlock.types';
import { CommonFormContentPropsType } from './CommonFormContent.types';
import { SERVER_DATE_FORMAT } from '../../../../../../../../constants/date';

export default function CommonFormContent({
  onCancel,
}: CommonFormContentPropsType) {
  const { t } = useIntl();
  const { values, isSubmitting } = useFormContext<
    ReceivePaymentFormValuesType
  >();

  const { data: lastReconciliationData } = useQuery<
    FetchLastFinishedReconciliationQuery,
    FetchLastFinishedReconciliationQueryVariables
  >(FETCH_LAST_FINISHED_RECONCILIATION, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: isDemoBuild() || !isPlusPlan() || values.destinationAccount !== BankAccountClasses.TRUST,
  });

  const lastReconciliation = useMemo(
    () => lastReconciliationData?.reconciliation.items[0],
    [lastReconciliationData?.reconciliation.items]
  );

  const {
    contact,
    matter,
    invoices = [],
    paymentMethod,
    agreeTermsAndConditionals = false,
  } = values;
  const matterId = matter?.id;
  const contactId = contact?.id;
  const isAgreeNecessary =
    !!paymentMethod &&
    [
      PaymentMethodsTypes.ACH,
      PaymentMethodsTypes.CC,
      PaymentMethodsTypes.TRANSFER_FROM_TRUST,
    ].includes(paymentMethod);

  const isDateDisabled =
    !!paymentMethod &&
    [PaymentMethodsTypes.ACH, PaymentMethodsTypes.CC].includes(paymentMethod);

  const unavailableMethods = isDemoBuild()
    ? [PaymentMethodsTypes.TRANSFER_FROM_TRUST, PaymentMethodsTypes.ACH, PaymentMethodsTypes.CC]
    : [PaymentMethodsTypes.TRANSFER_FROM_TRUST]; 

  const minDatepickerValue =
    values.destinationAccount === BankAccountClasses.OPERATING
      ? dateManager().getMaxDate(invoices.map(({ createdAt }) => createdAt))
      : dateManager().getMaxDate([
          ...invoices.map(({ createdAt }) => createdAt),
          lastReconciliation?.statementDate,
        ]);

  return (
    <>
      {(contactId || matterId) && (
        <>
          <p className={styles.invoicesNote}>
            <I18n id="modal.receivePayment.commonFormContent.note" />
          </p>
          <InvoicesField />
        </>
      )}
      {!!invoices.length && (
        <PaymentDetailsFieldset
          minDatepickerValue={minDatepickerValue}
        />
      )}
      {(contactId || matterId) &&
        paymentMethod !== PaymentMethodsTypes.TRANSFER_FROM_TRUST && (
          <Fieldset
            title={t(
              'modal.receivePayment.commonFormContent.fieldset.paymentMethod'
            )}
          >
            <FormGroup>
              <FormGrid>
                <PaymentMethodSelectFormField unavailableMethods={unavailableMethods} />
                {values.destinationAccount === BankAccountClasses.OPERATING && (
                  <DatePickerFormField
                    useHumanReadableFormat
                    name="paymentDate"
                    disabled={isDateDisabled}
                    outputDateFormat={SERVER_DATE_FORMAT}
                    label={t(
                      'modal.receivePayment.commonFormContent.paymentDate'
                    )}
                    minDate={dateManager()
                      .parse(minDatepickerValue || 0)
                      .startOf('day')
                      .valueOf()}
                    maxDate={dateManager().parse().endOf('day').valueOf()}
                  />
                )}
              </FormGrid>
            </FormGroup>
          </Fieldset>
        )}
      <AdditionalInfoFieldset
        entityType={Entities.PAYMENT}
        notesFieldName="internalMemo"
        className={classnames({
          [styles.additionalInfo]: contactId && isAgreeNecessary,
        })}
      />
      {contactId && isAgreeNecessary && (
        <FormGroup className={styles.termsAndConditionals}>
          <ToggleFormField
            name="agreeTermsAndConditionals"
            label={
              <span className={styles.terms}>
                <I18n
                  id="modal.receivePayment.commonFormContent.terms"
                  values={{
                    terms: (string: string) => (
                      <ExternalLink link={TERMS_OF_SERVICE_ROUTE}>
                        {string}
                      </ExternalLink>
                    ),
                  }}
                />
              </span>
            }
          />
        </FormGroup>
      )}
      <ButtonGroup>
        <Button onClick={onCancel}>
          <I18n id="modal.receivePayment.commonFormContent.button.cancel" />
        </Button>
        <Button
          type="submit"
          skin="primary"
          loading={isSubmitting}
          disabled={isAgreeNecessary && !agreeTermsAndConditionals}
        >
          <I18n id="modal.receivePayment.commonFormContent.button.submit" />
        </Button>
      </ButtonGroup>
    </>
  );
}
