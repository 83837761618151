import { gql } from '@apollo/client';

import { MATTER_SELECT_FRAGMENT } from 'components/fields/MatterSelectFormField';
import { CONTACT_SELECT_FRAGMENT } from 'components/fields/ContactSelectFormField';
import { INVOICE_HISTORY_FRAGMENT } from 'components/modals/pages/InvoiceDetailsModal';

import { EXPENSES_FIELD_FRAGMENT } from './components/InvoiceForm/components/InvoiceFormContent/components/ExpensesField';
import { TIME_ENTRIES_FIELD_FRAGMENT } from './components/InvoiceForm/components/InvoiceFormContent/components/TimeEntriesField';
import { FLAT_FEES_FIELD_FRAGMENT } from './components/InvoiceForm/components/InvoiceFormContent/components/FlatFeesField';

export const FETCH_CONTACT = gql`
  query FetchContact($id: ID!) {
    contact: getContact(id: $id) {
      ...ContactSelect
    }
  }
  ${CONTACT_SELECT_FRAGMENT}
`;

export const FETCH_MATTER = gql`
  query FetchMatter($id: ID!) {
    matter: getMatter(id: $id) {
      ...MatterSelect
    }
  }
  ${MATTER_SELECT_FRAGMENT}
`;

const INVOICE_EDIT_FRAGMENT = gql`
  fragment InvoiceEdit on InvoiceType {
    id
    contact {
      ...ContactSelect
    }
    invoiceNumber
    dueDate
    percentageDiscount
    discount
    balanceDue
    destinationAccount
    message
    internalMemo
    timeEntries {
      ...TimeEntriesField
    }
    matters {
      ...MatterSelect
    }
    expenses {
      ...ExpensesField
    }
    flatFees {
      ...FlatFeesField
    }
    scheduledPayments {
      plan {
        amount
        paymentDate
        status
      }
      settings {
        installments
        invoiceEvery
        invoicePeriod
        startDate
      }
    }
    createdAt
  }
  ${EXPENSES_FIELD_FRAGMENT}
  ${CONTACT_SELECT_FRAGMENT}
  ${MATTER_SELECT_FRAGMENT}
  ${TIME_ENTRIES_FIELD_FRAGMENT}
  ${FLAT_FEES_FIELD_FRAGMENT}
`;

export const FETCH_INVOICE = gql`
  query FetchInvoice($id: ID!) {
    invoice: getInvoice(id: $id) {
      ...InvoiceEdit
    }
  }
  ${INVOICE_EDIT_FRAGMENT}
`;

export const CREATE_INVOICE = gql`
  mutation CreateInvoice($invoice: CreateInvoiceInput!) {
    createInvoice(createInvoiceInput: $invoice) {
      id
      invoiceNumber
    }
  }
`;

export const UPDATE_INVOICE = gql`
  mutation UpdateInvoice($invoice: UpdateInvoiceInput!) {
    updateInvoice(updateInvoiceInput: $invoice) {
      ...InvoiceEdit
      ...InvoiceHistory
    }
  }
  ${INVOICE_EDIT_FRAGMENT}
  ${INVOICE_HISTORY_FRAGMENT}
`;
