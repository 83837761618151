import { gql } from '@apollo/client';

export const FETCH_LAST_CHECK_NUMBER = gql`
  query FetchLastCheckNumber {
    lastCheck: getLastCheckNumber {
      number
    }
  }
`;

export const PRINT_CHECKS_WITH_QBO = gql`
  mutation PrintChecksWithQbo($disbursementsIds: [ID!]!) {
    printChecks(printChecksInput: { disbursementsIds: $disbursementsIds }) {
      success
    }
  }
`;
