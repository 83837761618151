import React, { ChangeEvent, useMemo } from 'react';
import { Checkbox, TableCell, TableHead, TableRow } from '@appclose/ui';
import { useFormContext, useIsMobileDevice } from '@appclose/core';

import { I18n } from 'i18n';

import { OtosFieldTableHeaderPropsType } from './OtosFieldTableHeader.types';
import { DisbursementToOperatingFormValuesType } from '../../../../DisbursementToOperatingForm.types';

export default function OtosFieldTableHeader({
  otos,
}: OtosFieldTableHeaderPropsType) {
  const isMobile = useIsMobileDevice();
  const { values, setFieldValue } = useFormContext<
    DisbursementToOperatingFormValuesType
  >();

  const selectedAll = useMemo(
    () =>
      (otos?.length &&
        otos.every(({ id }) =>
          values.otos?.some(({ id: selectedId }) => selectedId === id)
        )) ||
      false,
    [otos, values.otos]
  );

  const onSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('otos', e.target.checked ? otos : [], true);
  };

  return (
    <TableHead theme="medium">
      <TableRow>
        <TableCell>
          <Checkbox checked={selectedAll} onChange={onSelectAll} />
        </TableCell>
        <TableCell />
        {isMobile ? (
          <>
            <TableCell>
              <I18n id="modal.disbursement.form.otos.table.type" />
            </TableCell>
            <TableCell>
              <I18n id="modal.printDeposits.form.deposits.table.amount" />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell>
              <I18n id="modal.disbursement.form.otos.table.type" />
            </TableCell>
            <TableCell>
              <I18n id="modal.disbursement.form.otos.table.date" />
            </TableCell>
            <TableCell>
              <I18n id="modal.disbursement.form.otos.table.ledger" />
            </TableCell>
            <TableCell>
              <I18n id="modal.printDeposits.form.deposits.table.amount" />
            </TableCell>
          </>
        )}
      </TableRow>
    </TableHead>
  );
}
