import React, { useMemo } from 'react';
import { SelectFormField } from '@appclose/core';
import { CalendarSelectFormFieldPropsType } from './CalendarSelectFormField.types';
import { formatCalendarsToSelectOptions } from './CalendarSelectFormField.utils';

export default function CalendarSelectFormField({ calendars, ...props }: CalendarSelectFormFieldPropsType) {
  const options = useMemo<{ value: string; label: string }[]>(
    () => formatCalendarsToSelectOptions(calendars),
    [calendars]
  );

  return (
    <SelectFormField
      options={options}
      {...props}
    />
  );
}
