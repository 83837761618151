import * as Yup from 'yup';

import { ContactMatterSelectContactType } from 'components/fields/ContactMatterSelectFormField';
import { getI18nErrorMessage } from 'i18n';
import { MEMO_PURPOSE_FILED_LENGTH_LIMIT } from 'constants/fieldsLimitations';

import { DisbursementToContactFormValuesType } from './DisbursementToContactForm.types';

export const DisbursementToContactFormSchema = Yup.object().shape<
  DisbursementToContactFormValuesType
>({
  contact: Yup.object<ContactMatterSelectContactType>().required(
    'Please select a contact / matter'
  ),
  amount: Yup.number()
    .moreThan(
      0,
      getI18nErrorMessage(
        'modal.disbursement.form.contact.error.amount.moreThan0'
      )
    )
    .required(
      getI18nErrorMessage(
        'modal.disbursement.form.contact.error.amount.required'
      )
    ),
  date: Yup.string()
    .nullable()
    .required(
      getI18nErrorMessage('modal.disbursement.form.contact.error.date.required')
    ),
  memo: Yup.string()
    .max(MEMO_PURPOSE_FILED_LENGTH_LIMIT, ({ max }) =>
      getI18nErrorMessage(
        'modal.disbursement.form.contact.error.memo.maxLength',
        { max }
      )
    )
    .required(
      getI18nErrorMessage('modal.disbursement.form.contact.error.memo.required')
    ),
  address: Yup.string(),
});
