import React, { ReactElement } from 'react';

import { TrustTransactionStatuses } from '__generated__/globalTypes';
import Label, { LabelPropsType } from 'components/common/Label';
import { I18n } from 'i18n';

import { TrustTransactionStatusPropsType } from './TrustTransactionStatus.types';

const TrustTransactionStatusLabels: Record<
  TrustTransactionStatuses,
  ReactElement<LabelPropsType>
> = {
  [TrustTransactionStatuses.CLEARED]: (
    <Label theme="success">
      <I18n id="enum.trust.transaction.status.cleared" />
    </Label>
  ),
  [TrustTransactionStatuses.UNCLEARED]: (
    <Label theme="info">
      <I18n id="enum.trust.transaction.status.uncleared" />
    </Label>
  ),
  [TrustTransactionStatuses.SETTLED]: (
    <Label theme="success">
      <I18n id="enum.trust.transaction.status.settled" />
    </Label>
  ),
  [TrustTransactionStatuses.UNSETTLED]: (
    <Label theme="warning">
      <I18n id="enum.trust.transaction.status.unsettled" />
    </Label>
  ),
  [TrustTransactionStatuses.VOIDED]: (
    <Label theme="tertiary">
      <I18n id="enum.trust.transaction.status.voided" />
    </Label>
  ),
  [TrustTransactionStatuses.ENTERED]: (
    <Label theme="primary">
      <I18n id="enum.trust.transaction.status.entered" />
    </Label>
  ),
};

export default function TrustTransactionStatus({
  status,
}: TrustTransactionStatusPropsType) {
  return TrustTransactionStatusLabels[status] || null;
}
