import React, { ReactElement } from 'react';

import { CheckPrintStatuses } from '__generated__/globalTypes';
import Label, { LabelPropsType } from 'components/common/Label';
import { I18n } from 'i18n';

import { CheckPrintStatusPropsType } from './CheckPrintStatus.types';

const CheckPrintStatusLabels: Record<
  CheckPrintStatuses,
  ReactElement<LabelPropsType>
> = {
  [CheckPrintStatuses.PRINTED]: (
    <Label theme="brand">
      <I18n id="enum.check.print.status.printed" />
    </Label>
  ),
  [CheckPrintStatuses.QBO_PRINTED]: (
    <Label theme="brand">
      <I18n id="enum.check.print.status.printedInQbo" />
    </Label>
  ),
  [CheckPrintStatuses.QBO_SENDED]: (
    <Label theme="info">
      <I18n id="enum.check.print.status.sentToQbo" />
    </Label>
  ),
};

export default function CheckPrintStatus({
  status,
}: CheckPrintStatusPropsType) {
  return status ? CheckPrintStatusLabels[status] : null;
}
