import React, { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { dateManager, history, Loader } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { MatterTeamMemberRoles, MatterTypes } from '__generated__/globalTypes';
import { sanitizeAmount } from 'controllers/amount';
import { track } from 'controllers/analytics';
import { DemoEventNames, EntryTypes } from 'constants/analytics';
import { MATTERS_ROUTE } from 'constants/routes';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { useIntl } from 'i18n';
import useUpdateFiles from 'hooks/useUpdateFiles';
import { Entities } from 'constants/entities';

import {
  CreateMatterMutation,
  CreateMatterMutationVariables,
  FetchContactQuery,
  FetchContactQueryVariables,
  FetchMatterQuery,
  FetchMatterQueryVariables,
  FetchProfileQuery,
  UpdateMatterMutation,
  UpdateMatterMutationVariables,
} from './__generated__/MatterModal.gql';
import { SERVER_DATE_FORMAT } from 'constants/date';
import MatterForm from './components/MatterForm';
import { MatterFormValuesType } from './components/MatterForm/MatterForm.types';
import {
  CREATE_MATTER,
  FETCH_CONTACT,
  FETCH_MATTER,
  FETCH_PROFILE,
  UPDATE_MATTER,
} from './MatterModal.gql';
import { MatterModalPropsType } from './MatterModal.types';

export default function MatterModal({
  onClose,
  id,
  contactId,
  openNew = true,
}: MatterModalPropsType) {
  const { t } = useIntl();
  const isEdit = !!id;
  const { onUpdateFiles } = useUpdateFiles(Entities.MATTER);

  const [createMatter] = useMutation<
    CreateMatterMutation,
    CreateMatterMutationVariables
  >(CREATE_MATTER);
  const [updateMatter] = useMutation<
    UpdateMatterMutation,
    UpdateMatterMutationVariables
  >(UPDATE_MATTER);
  const { loading: loadingProfile, data: profileData } = useQuery<
    FetchProfileQuery
  >(FETCH_PROFILE);
  const { loading, data } = useQuery<
    FetchMatterQuery,
    FetchMatterQueryVariables
  >(FETCH_MATTER, {
    variables: { id: id as string },
    skip: !isEdit,
    fetchPolicy: 'network-only',
  });
  const { loading: contactLoading, data: contactData } = useQuery<
    FetchContactQuery,
    FetchContactQueryVariables
  >(FETCH_CONTACT, {
    variables: { id: contactId as string },
    skip: !contactId || isEdit,
  });

  const title = isEdit
    ? t('modal.matter.title.edit')
    : t('modal.matter.title.add');

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });

  const handleOnSubmit = useCallback(
    async ({
      contact,
      teamMembers,
      matterNumber,
      files,
      type,
      ...matter
    }: MatterFormValuesType) => {
      const inputTeamMembers = teamMembers?.map(
        ({ member: { id: userId }, role: userRole, hourlyRate }) => ({
          userId,
          userRole,
          hourlyRate: sanitizeAmount(hourlyRate),
        })
      );

      let matterId = id;

      if (!isEdit) {
        const result = await createMatter({
          variables: {
            matter: {
              ...matter,
              matterNumber,
              type,
              contactId: contact?.id as string,
              teamMembers: inputTeamMembers,
            },
          },
        });

        notification().entityCreated(t('modal.matter.notification.created'));

        track(DemoEventNames.DEMO_ENTRY_CREATED, {
          entry_type: EntryTypes.MATTER,
        });

        matterId = result.data?.createMatter.id;
      } else {
        await updateMatter({
          variables: {
            matter: {
              id: id as string,
              ...matter,
              teamMembers: inputTeamMembers,
            },
          },
        });

        notification().entityUpdated(t('modal.matter.notification.updated'));
      }

      if (matterId && files) {
        await onUpdateFiles({ entityId: matterId, ...files });
      }

      onClose();

      if (openNew && !isEdit && matterId) {
        history.push(`${MATTERS_ROUTE}/${matterId}`);
      }
    },
    [createMatter, id, isEdit, onClose, openNew, updateMatter, t, onUpdateFiles]
  );

  let initialValues = useMemo<MatterFormValuesType>(
    () =>
      isEdit && data?.matter
        ? {
            ...data.matter,
            contact: data.matter.contact || undefined,
            dateOpened: dateManager()
              .parse(data.matter.dateOpened)
              .format(SERVER_DATE_FORMAT),
            statuteOfLimitations: data.matter.statuteOfLimitations
              ? dateManager()
                  .parse(data.matter.statuteOfLimitations)
                  .format(SERVER_DATE_FORMAT)
              : undefined,
          }
        : {
            name: '',
            dateOpened: dateManager()
              .parse()
              .startOf('day')
              .format(SERVER_DATE_FORMAT),
            type: MatterTypes.HYBRID,
            billable: true,
            agreedFlatFee: 1,
            teamMembers: profileData?.profile
              ? [
                  {
                    member: profileData.profile,
                    role: MatterTeamMemberRoles.LEAD_ATTORNEY,
                    hourlyRate: sanitizeAmount(profileData.profile.hourlyRate),
                  },
                ]
              : [],
            contact: contactData?.contact,
          },
    [isEdit, data, profileData, contactData]
  );

  const hasInvoices = sanitizeAmount(data?.invoices.total) > 0;

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>{title}</ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loadingProfile || contactLoading || loading ? (
          <Loader />
        ) : (
          <MatterForm
            initialValues={initialValues}
            isEdit={isEdit}
            hasInvoices={hasInvoices}
            onSubmit={handleOnSubmit}
            onCancel={onConfirmClose}
            onChange={onFormChange}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
