/* tslint:disable */
/* eslint-disable */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: string;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: number | string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { [key: string]: any };
  /** The `Upload` scalar type represents a file upload. */
  Upload: File;
};

/** Possible access levels */
export enum AccessLevels {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  LIMITED_USER = 'LIMITED_USER',
  READ_ONLY_USER = 'READ_ONLY_USER',
}

export type AccountStatementSummaryType = {
  __typename: 'AccountStatementSummaryType';
  dueOperating: Scalars['Float'];
  dueTrust: Scalars['Float'];
  trustBalance: Scalars['Float'];
  totalPaid: Scalars['Float'];
  trustDetails?: Maybe<AccountStatementTrustDetailsType>;
};

export type AccountStatementTrustDetailsType = {
  __typename: 'AccountStatementTrustDetailsType';
  contactBalance?: Maybe<Scalars['Float']>;
  matters?: Maybe<Array<StrippedMatterType>>;
};

export type AccountStatementType = {
  __typename: 'AccountStatementType';
  contact: PublicContactType;
  firm: PublicFirmType;
  summary: AccountStatementSummaryType;
  invoices: Array<InvoiceType>;
};

export type AccountTokensType = {
  __typename: 'AccountTokensType';
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  temporaryToken?: Maybe<Scalars['String']>;
  truncatedPhoneNumber?: Maybe<Scalars['String']>;
  isTwoFactorAuthEnable: Scalars['Boolean'];
};

export type AccountType = {
  __typename: 'AccountType';
  id: Scalars['ID'];
  validated: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  firmSize?: Maybe<FirmSizes>;
  mode?: Maybe<Modes>;
};

export type AddressInput = {
  type?: Maybe<AddressTypes>;
  city: Scalars['String'];
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  state: UsaStates;
  zipCode: Scalars['String'];
};

export type AddressType = {
  __typename: 'AddressType';
  type?: Maybe<AddressTypes>;
  city: Scalars['String'];
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  state: UsaStates;
  zipCode: Scalars['String'];
};

/** Possible address types */
export enum AddressTypes {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export type AgreeScheduledBillInput = {
  invoiceId: Scalars['ID'];
  paymentMethod: PaymentMethodsTypes;
};

export type AttachedEntities = {
  __typename: 'AttachedEntities';
  contact?: Maybe<AttachedToFileEntity>;
  matter?: Maybe<AttachedToFileEntity>;
  lead?: Maybe<AttachedToFileEntity>;
  invoice?: Maybe<AttachedToFileEntity>;
  voidInvoice?: Maybe<AttachedToFileEntity>;
  payment?: Maybe<AttachedToFileEntity>;
  signature?: Maybe<AttachedToFileEntity>;
  signatureTemplate?: Maybe<AttachedToFileEntity>;
  expense?: Maybe<AttachedToFileEntity>;
  reconciliation?: Maybe<AttachedToFileEntity>;
  trustTransfer?: Maybe<AttachedToFileEntity>;
};

export type AttachedToFileEntity = {
  __typename: 'AttachedToFileEntity';
  id: Scalars['ID'];
  type?: Maybe<AttachedToFileEntityType>;
};

/** Types of expenses and payments in entities attached to file */
export enum AttachedToFileEntityType {
  PAYMENT = 'PAYMENT',
  REFUND = 'REFUND',
  CREDIT_MEMO = 'CREDIT_MEMO',
  SUB_EXPENSE = 'SUB_EXPENSE',
  FLAT_FEE = 'FLAT_FEE',
  TIME_ENTRY = 'TIME_ENTRY',
}

/** Classes of bank accounts */
export enum BankAccountClasses {
  OPERATING = 'OPERATING',
  TRUST = 'TRUST',
}

export type BankAccountInput = {
  holderName: Scalars['String'];
  routingNumber: Scalars['String'];
  accountNumber: Scalars['String'];
  type: BankAccountTypes;
};

export type BankAccountType = {
  __typename: 'BankAccountType';
  id: Scalars['ID'];
  holderName: Scalars['String'];
  routingNumber: Scalars['String'];
  accountNumberLast4: Scalars['String'];
  type: BankAccountTypes;
  class: BankAccountClasses;
  stripeBankAccountStatus: StripeBankAccountStatus;
  /** Only for TRUST accounts */
  feeBankAccount?: Maybe<FeeBankAccount>;
};

/** Types of bank accounts */
export enum BankAccountTypes {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'PERSONAL',
}

/** Possible types of banners */
export enum BannerTypes {
  LINK_TRUST_ACCOUNT = 'LINK_TRUST_ACCOUNT',
  ADD_TEAM_MEMBERS = 'ADD_TEAM_MEMBERS',
  ADD_CONTACTS = 'ADD_CONTACTS',
}

export type BillType = {
  __typename: 'BillType';
  contact: ContactType;
  firm: PublicFirmType;
  dueDate: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  accounts?: Maybe<Array<BankAccountType>>;
  status: InvoiceStatusesTypes;
  originalAmount: Scalars['Float'];
  paidAmount: Scalars['Float'];
  balanceDue: Scalars['Float'];
  requestedAmount: Scalars['Float'];
  scheduledPayments?: Maybe<ScheduledPaymentsType>;
  source?: Maybe<PaymentSourceType>;
  message?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  percentageDiscount: Scalars['Boolean'];
  discount: Scalars['Float'];
  destinationAccount?: Maybe<BankAccountClasses>;
  paymentDate?: Maybe<Scalars['Date']>;
  paymentAmount?: Maybe<Scalars['Float']>;
  paymentId?: Maybe<Scalars['ID']>;
  truncatedPAN?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethodsTypes>;
};

/** Possible entity billing contact types */
export enum BillingContactTypes {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export type Breadcrumb = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BreadcrumbType = {
  __typename: 'BreadcrumbType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CalculateReconciliationInput = {
  transactions: Array<Scalars['String']>;
  statementBalance: Scalars['Float'];
  date: Scalars['Date'];
};

export type CalculateScheduledPaymentsInput = {
  amount?: Maybe<Scalars['Float']>;
  settings: ScheduledPaymentsSettingsInput;
};

export type CalendarGoogleSynchronizeInput = {
  calendarId?: Maybe<Scalars['ID']>;
  calendarName?: Maybe<Scalars['String']>;
};

/** Types of calendar item colors */
export enum CalendarItemColor {
  COLOR_1 = 'COLOR_1',
  COLOR_2 = 'COLOR_2',
  COLOR_3 = 'COLOR_3',
  COLOR_4 = 'COLOR_4',
  COLOR_5 = 'COLOR_5',
  COLOR_6 = 'COLOR_6',
  COLOR_7 = 'COLOR_7',
  COLOR_8 = 'COLOR_8',
  COLOR_9 = 'COLOR_9',
  COLOR_10 = 'COLOR_10',
}

export type CalendarItemDeadlineDataInput = {
  subject: Scalars['String'];
  deadlineType: Scalars['String'];
  status?: Maybe<CalendarItemStatuses>;
  description?: Maybe<Scalars['String']>;
  sharedToTeamMembers?: Maybe<Array<Scalars['ID']>>;
  sharedToContacts?: Maybe<Array<Scalars['ID']>>;
};

export type CalendarItemDeadlineDataType = {
  __typename: 'CalendarItemDeadlineDataType';
  subject: Scalars['String'];
  deadlineType: Scalars['String'];
  dueDate: Scalars['DateTime'];
  status?: Maybe<CalendarItemStatuses>;
  description?: Maybe<Scalars['String']>;
  sharedToTeamMembers?: Maybe<Array<UserType>>;
  sharedToContacts?: Maybe<Array<ContactType>>;
};

export type CalendarItemEventDataInputType = {
  subject: Scalars['String'];
  allDay?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  teamMembersAttendees?: Maybe<Array<Scalars['ID']>>;
  contactsAttendees?: Maybe<Array<Scalars['ID']>>;
};

export type CalendarItemEventDataType = {
  __typename: 'CalendarItemEventDataType';
  subject: Scalars['String'];
  allDay?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  teamMembersAttendees?: Maybe<Array<UserType>>;
  contactAttendees?: Maybe<Array<ContactType>>;
};

/** Types of calendar item priorities */
export enum CalendarItemPriority {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export type CalendarItemRemindersType = {
  __typename: 'CalendarItemRemindersType';
  dimension: CalendarReminderDimensions;
  value: Scalars['Float'];
};

export type CalendarItemRepeatDurationInput = {
  type: CalendarRepeatDurations;
  repeats?: Maybe<Scalars['Int']>;
  daysCount?: Maybe<Scalars['Int']>;
};

export type CalendarItemRepeatDurationType = {
  __typename: 'CalendarItemRepeatDurationType';
  type: CalendarRepeatDurations;
  repeats?: Maybe<Scalars['Int']>;
  daysCount?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type CalendarItemRepeatInput = {
  type: CalendarRepeatTypes;
  interval?: Maybe<Scalars['Int']>;
  intervalType?: Maybe<CalendarRepeatTypes>;
  duration?: Maybe<CalendarItemRepeatDurationInput>;
};

export type CalendarItemRepeatType = {
  __typename: 'CalendarItemRepeatType';
  type: CalendarRepeatTypes;
  interval?: Maybe<Scalars['Float']>;
  intervalType?: Maybe<CalendarRepeatTypes>;
  duration?: Maybe<CalendarItemRepeatDurationType>;
};

/** Types of calendar item share */
export enum CalendarItemShareTypes {
  ASSIGNEE = 'ASSIGNEE',
  ATTENDEES = 'ATTENDEES',
  SHARE = 'SHARE',
  OWN = 'OWN',
}

/** Types of calendar item source */
export enum CalendarItemSourceTypes {
  GOOGLE_CALENDAR = 'GOOGLE_CALENDAR',
  OUTLOOK_CALENDAR = 'OUTLOOK_CALENDAR',
}

/** Types of calendar item statuses */
export enum CalendarItemStatuses {
  COMPLETE = 'COMPLETE',
  NOT_COMPLETE = 'NOT_COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type CalendarItemSubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
  priorities?: Maybe<Array<CalendarItemPriority>>;
  contactId?: Maybe<Scalars['ID']>;
  matterId?: Maybe<Scalars['ID']>;
  itemTypes?: Maybe<Array<CalendarItemTypes>>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type CalendarItemTaskDataType = {
  __typename: 'CalendarItemTaskDataType';
  subject: Scalars['String'];
  dueDate: Scalars['DateTime'];
  status?: Maybe<CalendarItemStatuses>;
  description?: Maybe<Scalars['String']>;
  reminders?: Maybe<Array<CalendarItemRemindersType>>;
  location?: Maybe<Scalars['String']>;
  assignedToContacts?: Maybe<Array<ContactType>>;
  assignedToTeamMembers?: Maybe<Array<UserType>>;
  sharedToTeamMembers?: Maybe<Array<UserType>>;
  sharedToContacts?: Maybe<Array<ContactType>>;
};

export type CalendarItemTaskInputType = {
  subject: Scalars['String'];
  status?: Maybe<CalendarItemStatuses>;
  description?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  assignedToTeamMembers?: Maybe<Array<Scalars['ID']>>;
  assignedToContacts?: Maybe<Array<Scalars['ID']>>;
  sharedToTeamMembers?: Maybe<Array<Scalars['ID']>>;
  sharedToContacts?: Maybe<Array<Scalars['ID']>>;
};

export type CalendarItemType = {
  __typename: 'CalendarItemType';
  id: Scalars['ID'];
  priority: CalendarItemPriority;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  color?: Maybe<CalendarItemColor>;
  ownerId: Scalars['ID'];
  contact?: Maybe<ContactType>;
  matter?: Maybe<MatterType>;
  itemType: CalendarItemTypes;
  source?: Maybe<CalendarItemSourceTypes>;
  taskData?: Maybe<CalendarItemTaskDataType>;
  eventData?: Maybe<CalendarItemEventDataType>;
  deadlineData?: Maybe<CalendarItemDeadlineDataType>;
  subEntryItemData?: Maybe<CalendarSubEntryItemDataType>;
  repeats?: Maybe<CalendarItemRepeatType>;
  insertOnCalendar?: Maybe<Array<InsertOnCalendarType>>;
};

/** Types of calendar item */
export enum CalendarItemTypes {
  EXTERNAL = 'EXTERNAL',
  EVENT = 'EVENT',
  DEADLINE = 'DEADLINE',
  TASK = 'TASK',
  DEADLINE_SUB_ENTRY = 'DEADLINE_SUB_ENTRY',
  TASK_SUB_ENTRY = 'TASK_SUB_ENTRY',
}

export type CalendarOutlookSynchronizeInput = {
  calendarId?: Maybe<Scalars['ID']>;
  calendarName?: Maybe<Scalars['String']>;
};

export type CalendarReloadRequiredType = {
  __typename: 'CalendarReloadRequiredType';
  calendarReloadRequired: Scalars['Boolean'];
};

/** Types of calendar reminder dimensions */
export enum CalendarReminderDimensions {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
}

/** Types of calendar durations */
export enum CalendarRepeatDurations {
  INFINITELY = 'INFINITELY',
  REPEATS = 'REPEATS',
  TILL_DATE = 'TILL_DATE',
}

/** Types modification of calendar repeat */
export enum CalendarRepeatModificationTypes {
  CURRENT = 'CURRENT',
  CURRENT_AND_FOLLOWING = 'CURRENT_AND_FOLLOWING',
}

/** Types of calendar repeat */
export enum CalendarRepeatTypes {
  NEVER = 'NEVER',
  CUSTOM = 'CUSTOM',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export type CalendarSubEntryItemDataInput = {
  subject?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
};

export type CalendarSubEntryItemDataType = {
  __typename: 'CalendarSubEntryItemDataType';
  subject: Scalars['String'];
  parentId: Scalars['ID'];
};

export type CalendarSyncGoogleSettingsType = {
  __typename: 'CalendarSyncGoogleSettingsType';
  calendarName: Scalars['String'];
  active: Scalars['Boolean'];
};

export type CalendarSyncOutlookSettingsType = {
  __typename: 'CalendarSyncOutlookSettingsType';
  calendarName: Scalars['String'];
  active: Scalars['Boolean'];
};

export type CalendarSyncSettingsInput = {
  google?: Maybe<Scalars['Boolean']>;
  outlook?: Maybe<Scalars['Boolean']>;
};

export type CalendarSyncSettingsType = {
  __typename: 'CalendarSyncSettingsType';
  google?: Maybe<CalendarSyncGoogleSettingsType>;
  outlook?: Maybe<CalendarSyncOutlookSettingsType>;
};

/** Types of calendars */
export enum Calendars {
  OWN_CALENDAR = 'OWN_CALENDAR',
  GOOGLE_CALENDAR = 'GOOGLE_CALENDAR',
}

export type CancelInvoiceScheduleInput = {
  id: Scalars['ID'];
};

export type CancelPrintChecksInput = {
  checkNumber: Scalars['Int'];
};

export type ChangeModeInput = {
  mode: Modes;
};

export type ChangePasswordInput = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type CheckFolderNameExistingInput = {
  folderName: Scalars['String'];
  driveId?: Maybe<Scalars['ID']>;
};

export type CheckFolderNameExistingType = {
  __typename: 'CheckFolderNameExistingType';
  exists: Scalars['Boolean'];
};

/** Types of disbursement check print status */
export enum CheckPrintStatuses {
  PRINTED = 'PRINTED',
  QBO_SENDED = 'QBO_SENDED',
  QBO_PRINTED = 'QBO_PRINTED',
}

export type CheckType = {
  __typename: 'CheckType';
  number?: Maybe<Scalars['Float']>;
  status?: Maybe<CheckPrintStatuses>;
  createdAt: Scalars['DateTime'];
};

export type CloseMatterInput = {
  id: Scalars['ID'];
};

/** Possible contact types */
export enum CombinedContactTypes {
  CONTACT = 'CONTACT',
  ENTITY = 'ENTITY',
}

/** Common errors */
export enum CommonAppErrors {
  UNIQUE_VIOLATION = 'UNIQUE_VIOLATION',
  NOT_FOUND_ERROR = 'NOT_FOUND_ERROR',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  INVALID_INVOICE = 'INVALID_INVOICE',
  NOT_UNIQUE_INVOICES_TARGET = 'NOT_UNIQUE_INVOICES_TARGET',
  NOT_UNIQUE_INVOICES_BANK_ACCOUNT = 'NOT_UNIQUE_INVOICES_BANK_ACCOUNT',
  INVOICE_CANNOT_CHANGE_CONTACT_OR_MATTERS = 'INVOICE_CANNOT_CHANGE_CONTACT_OR_MATTERS',
  MATTER_CLOSED = 'MATTER_CLOSED',
  MATTER_NOT_FOUND = 'MATTER_NOT_FOUND',
  MATTER_BILLABLE_REQUIRED = 'MATTER_BILLABLE_REQUIRED',
  MATTER_FLAT_FEE_AMOUNT_OVERFLOW = 'MATTER_FLAT_FEE_AMOUNT_OVERFLOW',
  MATTER_CANNOT_CONVERT = 'MATTER_CANNOT_CONVERT',
  MATTER_NON_APPLICABLE_TO_MATTER_TYPE = 'MATTER_NON_APPLICABLE_TO_MATTER_TYPE',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  NOT_ACTIVE_ACCOUNT = 'NOT_ACTIVE_ACCOUNT',
  NOT_ACTIVE_CONTACT = 'NOT_ACTIVE_CONTACT',
  SETUP_TRUST_ACCOUNTING_INVALID_BALANCE = 'SETUP_TRUST_ACCOUNTING_INVALID_BALANCE',
  SETUP_TRUST_ACCOUNTING_INVALID_STEP = 'SETUP_TRUST_ACCOUNTING_INVALID_STEP',
  SETUP_TRUST_ACCOUNTING_NEGATIVE_CLEARED_BALANCE = 'SETUP_TRUST_ACCOUNTING_NEGATIVE_CLEARED_BALANCE',
  CANT_REFUND_FROM_TRUST_ACCOUNT = 'CANT_REFUND_FROM_TRUST_ACCOUNT',
  NOT_ALLOWED_EMAIL_DOMAIN = 'NOT_ALLOWED_EMAIL_DOMAIN',
  NOT_ALLOWED_PHONE_NUMBER = 'NOT_ALLOWED_PHONE_NUMBER',
  EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED',
  AUTH_SIGNIN_INVALID_CREDENTIALS = 'AUTH_SIGNIN_INVALID_CREDENTIALS',
  AUTH_SIGNUP_EMAIL_ALREADY_USED = 'AUTH_SIGNUP_EMAIL_ALREADY_USED',
  FIRM_PAYMENT_LINK_CONTACT_NOT_FOUND = 'FIRM_PAYMENT_LINK_CONTACT_NOT_FOUND',
  FIRM_CANT_CHANGE_PRINT_MODE = 'FIRM_CANT_CHANGE_PRINT_MODE',
  FIRM_ONLY_OWNER_CAN_HIDE_BANNER = 'FIRM_ONLY_OWNER_CAN_HIDE_BANNER',
  FIRM_CANT_SENT_KYB = 'FIRM_CANT_SENT_KYB',
  FIRM_KYB_VALIDATION_FAILED = 'FIRM_KYB_VALIDATION_FAILED',
  FIRM_KYB_NOT_COMPLETED = 'FIRM_KYB_NOT_COMPLETED',
  FIRM_ONBOARDING_NOT_COMPLETED = 'FIRM_ONBOARDING_NOT_COMPLETED',
  TRUST_TRANSFER_INVALID_CONTACT = 'TRUST_TRANSFER_INVALID_CONTACT',
  TRUST_TRANSACTION_RECONCILIATION_DATE_COVER = 'TRUST_TRANSACTION_RECONCILIATION_DATE_COVER',
  TRUST_TRANSACTION_INVALID_DEPOSIT_TYPE = 'TRUST_TRANSACTION_INVALID_DEPOSIT_TYPE',
  TRUST_TRANSACTION_TRANSACTION_COUNT_OVERFLOW = 'TRUST_TRANSACTION_TRANSACTION_COUNT_OVERFLOW',
  TRUST_TRANSACTION_CANT_VOID_WITHOUT_CHECK = 'TRUST_TRANSACTION_CANT_VOID_WITHOUT_CHECK',
  PAYMENTS_REFUND_UNSETTLED_TRANSACTION = 'PAYMENTS_REFUND_UNSETTLED_TRANSACTION',
  PAYMENTS_INSUFFICIENT_FUNDS = 'PAYMENTS_INSUFFICIENT_FUNDS',
  PAYMENTS_BANK_DECLINED_PAYMENT = 'PAYMENTS_BANK_DECLINED_PAYMENT',
  PAYMENTS_INVALID_CARD_NUMBER = 'PAYMENTS_INVALID_CARD_NUMBER',
  PAYMENTS_CVV_INVALID = 'PAYMENTS_CVV_INVALID',
  PAYMENTS_INVALID_PAYMENT_METHOD = 'PAYMENTS_INVALID_PAYMENT_METHOD',
  PAYMENTS_NOTHING_TO_PAY = 'PAYMENTS_NOTHING_TO_PAY',
  PAYMENTS_DISABLED = 'PAYMENTS_DISABLED',
  PROMO_CODE_EXPIRED = 'PROMO_CODE_EXPIRED',
  PROMO_CODE_NOT_EXISTS = 'PROMO_CODE_NOT_EXISTS',
  SIGNATURE_NOT_FOUND = 'SIGNATURE_NOT_FOUND',
  SIGNATURE_ALREADY_SIGNED = 'SIGNATURE_ALREADY_SIGNED',
  SIGNATURE_DECLINED = 'SIGNATURE_DECLINED',
  SIGNATURE_PDF_NOT_UPDATED = 'SIGNATURE_PDF_NOT_UPDATED',
  SIGNATURE_EMPTY_OR_ENCRYPTED_FILE = 'SIGNATURE_EMPTY_OR_ENCRYPTED_FILE',
  SIGNATURE_MAX_PAGES_COUNT_EXCEEDED = 'SIGNATURE_MAX_PAGES_COUNT_EXCEEDED',
  THIRD_PARTY_PAYEE_ALREADY_EXISTS = 'THIRD_PARTY_PAYEE_ALREADY_EXISTS',
  THIRD_PARTY_PAYEE_NOT_FOUND = 'THIRD_PARTY_PAYEE_NOT_FOUND',
  THIRD_PARTY_PAYEE_IN_USE = 'THIRD_PARTY_PAYEE_IN_USE',
  THIRD_PARTY_PAYEE_DISABLED = 'THIRD_PARTY_PAYEE_DISABLED',
  DISABLED_UPDATING_INVOICE_FILES = 'DISABLED_UPDATING_INVOICE_FILES',
  FILES_NOT_FOUND = 'FILES_NOT_FOUND',
  TIMER_ACTIVITY_ALREADY_EXISTS = 'TIMER_ACTIVITY_ALREADY_EXISTS',
  TIMER_ACTIVITY_NOT_FOUND = 'TIMER_ACTIVITY_NOT_FOUND',
  TIMER_ACTIVITY_INBUILT_CHANGE_RESTRICTED = 'TIMER_ACTIVITY_INBUILT_CHANGE_RESTRICTED',
  TIMER_ACTIVITY_HAS_RELATIONS = 'TIMER_ACTIVITY_HAS_RELATIONS',
  TIMER_ACTIVITY_CANNOT_USE_NOT_ACTIVE = 'TIMER_ACTIVITY_CANNOT_USE_NOT_ACTIVE',
  BANK_ACCOUNTS_OPERATING_ALREADY_EXISTS = 'BANK_ACCOUNTS_OPERATING_ALREADY_EXISTS',
  BANK_ACCOUNTS_TRUST_ALREADY_EXISTS = 'BANK_ACCOUNTS_TRUST_ALREADY_EXISTS',
  BANK_ACCOUNT_ONLY_OWNER_CAN_CREATE_OPERATING = 'BANK_ACCOUNT_ONLY_OWNER_CAN_CREATE_OPERATING',
  BANK_ACCOUNT_DUPLICATION = 'BANK_ACCOUNT_DUPLICATION',
  BANK_ACCOUNT_TOKEN_INVALID = 'BANK_ACCOUNT_TOKEN_INVALID',
  BANK_ACCOUNT_NOT_FOUND = 'BANK_ACCOUNT_NOT_FOUND',
  SIGNERS_NOT_UNIQUE = 'SIGNERS_NOT_UNIQUE',
  SUB_EXPENSE_CATEGORY_NOT_FOUND = 'SUB_EXPENSE_CATEGORY_NOT_FOUND',
  SUB_EXPENSE_CATEGORY_INBUILT_CHANGE_RESTRICTED = 'SUB_EXPENSE_CATEGORY_INBUILT_CHANGE_RESTRICTED',
  SUB_EXPENSE_CATEGORY_HAS_RELATIONS = 'SUB_EXPENSE_CATEGORY_HAS_RELATIONS',
  SUB_EXPENSE_CATEGORY_ALREADY_EXISTS = 'SUB_EXPENSE_CATEGORY_ALREADY_EXISTS',
  SUB_EXPENSE_CATEGORY_CANNOT_USE_NOT_ACTIVE = 'SUB_EXPENSE_CATEGORY_CANNOT_USE_NOT_ACTIVE',
  SUB_EXPENSE_AGREED_FLAT_FEE_OVERFLOW = 'SUB_EXPENSE_AGREED_FLAT_FEE_OVERFLOW',
  EXPENSES_RESTRICT_UPDATE_STATUS = 'EXPENSES_RESTRICT_UPDATE_STATUS',
  EXPENSE_RESTRICTED_TO_REMOVE = 'EXPENSE_RESTRICTED_TO_REMOVE',
  EXPENSE_BILLABLE_REQUIRED = 'EXPENSE_BILLABLE_REQUIRED',
  EXPENSE_CANNOT_SET_BILLABLE = 'EXPENSE_CANNOT_SET_BILLABLE',
  AUTH_SIGNUP_INVALID_CAPTCHA = 'AUTH_SIGNUP_INVALID_CAPTCHA',
  AUTH_SIGNUP_NOT_ALLOWED_COUNTRY = 'AUTH_SIGNUP_NOT_ALLOWED_COUNTRY',
  PAYMENTS_CONTACT_ADDRESS_REQUIRED = 'PAYMENTS_CONTACT_ADDRESS_REQUIRED',
  SCHEDULED_PAYMENTS_INVALID_PLAN = 'SCHEDULED_PAYMENTS_INVALID_PLAN',
  CALENDAR_ITEM_NOT_FOUND = 'CALENDAR_ITEM_NOT_FOUND',
  CALENDAR_ITEM_DATA_MISSED = 'CALENDAR_ITEM_DATA_MISSED',
  CALENDAR_ITEM_INCOMPATIBLE_FIELDS = 'CALENDAR_ITEM_INCOMPATIBLE_FIELDS',
  CALENDAR_ITEM_CONTACT_NOT_FOUND = 'CALENDAR_ITEM_CONTACT_NOT_FOUND',
  CALENDAR_ITEM_USER_NOT_FOUND = 'CALENDAR_ITEM_USER_NOT_FOUND',
  CALENDAR_ITEM_DIRECT_CREATION_RESTRICTED = 'CALENDAR_ITEM_DIRECT_CREATION_RESTRICTED',
  CALENDAR_ITEM_INVALID_REPEAT = 'CALENDAR_ITEM_INVALID_REPEAT',
  CALENDAR_ITEM_NO_BOTH_REPEATS_AND_INSERTS = 'CALENDAR_ITEM_NO_BOTH_REPEATS_AND_INSERTS',
  CALENDAR_ITEM_REPEAT_BROKEN_ITEM = 'CALENDAR_ITEM_REPEAT_BROKEN_ITEM',
  CALENDAR_ITEM_REPEAT_TILL_DATE_OVERFLOW = 'CALENDAR_ITEM_REPEAT_TILL_DATE_OVERFLOW',
  CALENDAR_ITEM_INVALID_TIME_RANGE = 'CALENDAR_ITEM_INVALID_TIME_RANGE',
  RECONCILIATION_NO_PICKED_TRANSACTIONS = 'RECONCILIATION_NO_PICKED_TRANSACTIONS',
  RECONCILIATION_NOT_FOUND = 'RECONCILIATION_NOT_FOUND',
  FILES_EXTENSION_REQUIRED = 'FILES_EXTENSION_REQUIRED',
  SITE_BUILDER_DOMAIN_NOT_FOUND = 'SITE_BUILDER_DOMAIN_NOT_FOUND',
  SITE_BUILDER_CONFIGURATION_NOT_FOUND = 'SITE_BUILDER_CONFIGURATION_NOT_FOUND',
  SITE_BUILDER_CUSTOM_DOMAIN_ALREADY_CREATED = 'SITE_BUILDER_CUSTOM_DOMAIN_ALREADY_CREATED',
  SITE_BUILDER_CUSTOM_DOMAIN_CNAME_RECORD_NOT_FOUND = 'SITE_BUILDER_CUSTOM_DOMAIN_CNAME_RECORD_NOT_FOUND',
  SITE_BUILDER_CUSTOM_DOMAIN_CONNECTION_FAILED = 'SITE_BUILDER_CUSTOM_DOMAIN_CONNECTION_FAILED',
  SITE_BUILDER_DISABLED_FOR_DEMO = 'SITE_BUILDER_DISABLED_FOR_DEMO',
  SITE_BUILDER_DISABLED_FOR_CURRENT_ONBOARD_STATUS = 'SITE_BUILDER_DISABLED_FOR_CURRENT_ONBOARD_STATUS',
  SITE_BUILDER_INITIAL_CONFIG_ALREADY_CREATED = 'SITE_BUILDER_INITIAL_CONFIG_ALREADY_CREATED',
  SITE_BUILDER_CUSTOM_DOMAIN_INVALID = 'SITE_BUILDER_CUSTOM_DOMAIN_INVALID',
  INVOICES_BANK_ACCOUNT_MUST_BE_ONBOARDED = 'INVOICES_BANK_ACCOUNT_MUST_BE_ONBOARDED',
  INVOICES_TRUST_ACCOUNTING_MUST_BE_COMPLETED = 'INVOICES_TRUST_ACCOUNTING_MUST_BE_COMPLETED',
  INVOICES_CANT_EDIT_AFTER_SCHEDULED_PLAN_APPROVED = 'INVOICES_CANT_EDIT_AFTER_SCHEDULED_PLAN_APPROVED',
  INVOICES_CANT_EDIT_WITH_TRANSACTIONS = 'INVOICES_CANT_EDIT_WITH_TRANSACTIONS',
  INVOICES_INVALID_PAYMENT_DATE = 'INVOICES_INVALID_PAYMENT_DATE',
  INVOICES_CANNOT_SET_CUSTOM_PAYMENT_DATE = 'INVOICES_CANNOT_SET_CUSTOM_PAYMENT_DATE',
  CHECKS_SETTINGS_MUST_BE_INITIALIZED = 'CHECKS_SETTINGS_MUST_BE_INITIALIZED',
  CHECKS_QBO_NOT_SUPPORTED = 'CHECKS_QBO_NOT_SUPPORTED',
  CHECKS_INVALID_NUMBER = 'CHECKS_INVALID_NUMBER',
  CHECKS_CANNOT_PRINT_TWICE = 'CHECKS_CANNOT_PRINT_TWICE',
  CHECKS_CANT_CANCEL_PRINT = 'CHECKS_CANT_CANCEL_PRINT',
  TEAM_MEMBERS_CANT_RESEND_INVITATION = 'TEAM_MEMBERS_CANT_RESEND_INVITATION',
  GOOGLE_UNEXPECTED_API_RESPONSE = 'GOOGLE_UNEXPECTED_API_RESPONSE',
  GOOGLE_NOT_CONFIGURED = 'GOOGLE_NOT_CONFIGURED',
  GOOGLE_AUTH_REQUIRED = 'GOOGLE_AUTH_REQUIRED',
  GOOGLE_CALENDAR_NOT_FOUND = 'GOOGLE_CALENDAR_NOT_FOUND',
  MICROSOFT_NOT_CONFIGURED = 'MICROSOFT_NOT_CONFIGURED',
  MICROSOFT_AUTH_REQUIRED = 'MICROSOFT_AUTH_REQUIRED',
  MICROSOFT_OUTLOOK_CALENDAR_NAME_DUPLICATE = 'MICROSOFT_OUTLOOK_CALENDAR_NAME_DUPLICATE',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_PRIMARY_NUMBERS_OVERFLOW = 'USER_PRIMARY_NUMBERS_OVERFLOW',
  QBO_COMPANY_ALREADY_CONNECTED = 'QBO_COMPANY_ALREADY_CONNECTED',
  QBO_UNKNOWN_ERROR = 'QBO_UNKNOWN_ERROR',
  QBO_NOT_CONFIGURED = 'QBO_NOT_CONFIGURED',
  QBO_RESYNC_UPDATE_NOT_SUPPORTED = 'QBO_RESYNC_UPDATE_NOT_SUPPORTED',
  QBO_RESYNC_INVALID_IDS = 'QBO_RESYNC_INVALID_IDS',
  QBO_RESYNC_ONLY_FOR_REFUND_PAYMENT = 'QBO_RESYNC_ONLY_FOR_REFUND_PAYMENT',
  QBO_CANT_SWITCH_COMPANY = 'QBO_CANT_SWITCH_COMPANY',
  FILE_STORAGE_PARENT_FOLDER_NOT_FOUND = 'FILE_STORAGE_PARENT_FOLDER_NOT_FOUND',
  FILE_STORAGE_FOLDER_NOT_FOUND = 'FILE_STORAGE_FOLDER_NOT_FOUND',
  FILE_STORAGE_SYSTEM_FOLDER_UPDATE_PROHIBITED = 'FILE_STORAGE_SYSTEM_FOLDER_UPDATE_PROHIBITED',
  FILE_STORAGE_FILE_ATTACH_INFO_WRONG = 'FILE_STORAGE_FILE_ATTACH_INFO_WRONG',
  FILE_STORAGE_DISABLED_FOR_DEMO = 'FILE_STORAGE_DISABLED_FOR_DEMO',
  FILE_STORAGE_FILTER_DISABLED = 'FILE_STORAGE_FILTER_DISABLED',
  FILE_STORAGE_DESTINATION_AND_ITEM_SHOULD_BE_DIFFERENT = 'FILE_STORAGE_DESTINATION_AND_ITEM_SHOULD_BE_DIFFERENT',
  FILE_STORAGE_DESTINATION_FOLDER_SHOULD_BE_DEFINED = 'FILE_STORAGE_DESTINATION_FOLDER_SHOULD_BE_DEFINED',
  FILE_STORAGE_DESTINATION_FOLDER_IS_NOT_UNIQUE = 'FILE_STORAGE_DESTINATION_FOLDER_IS_NOT_UNIQUE',
  FILE_STORAGE_SYNC_ACCOUNT_INVALID_CREDENTIALS = 'FILE_STORAGE_SYNC_ACCOUNT_INVALID_CREDENTIALS',
  FILE_STORAGE_SOMETHING_WRONG_WITH_SYNC_ACCOUNT = 'FILE_STORAGE_SOMETHING_WRONG_WITH_SYNC_ACCOUNT',
  FILE_STORAGE_SYNC_ACCOUNT_INVALID_PERMISSIONS = 'FILE_STORAGE_SYNC_ACCOUNT_INVALID_PERMISSIONS',
  FILE_STORAGE_SYNC_DESTINATION_FOLDER_DELETED = 'FILE_STORAGE_SYNC_DESTINATION_FOLDER_DELETED',
  AUTH_TWO_FACTOR_TOKEN_NOT_FOUND = 'AUTH_TWO_FACTOR_TOKEN_NOT_FOUND',
  AUTH_TWO_FACTOR_TOKEN_INVALID = 'AUTH_TWO_FACTOR_TOKEN_INVALID',
  AUTH_TWO_FACTOR_OPTION_NOT_FOUND = 'AUTH_TWO_FACTOR_OPTION_NOT_FOUND',
  AUTH_TWO_FACTOR_OPTION_DISABLE_FOR_DEMO = 'AUTH_TWO_FACTOR_OPTION_DISABLE_FOR_DEMO',
  AUTH_TWO_FACTOR_ALREADY_ENABLED = 'AUTH_TWO_FACTOR_ALREADY_ENABLED',
  DISABLED_FOR_DEMO = 'DISABLED_FOR_DEMO',
  DISABLED_FOR_CURRENT_ONBOARD_STATUS = 'DISABLED_FOR_CURRENT_ONBOARD_STATUS',
  LEAD_NOT_FOUND = 'LEAD_NOT_FOUND',
  LEAD_MUST_HAVE_SAME_STATUS = 'LEAD_MUST_HAVE_SAME_STATUS',
  LEAD_CANT_MOVE_OUT_ARCHIVED = 'LEAD_CANT_MOVE_OUT_ARCHIVED',
  LEAD_ARCHIVE_REASON_REQUIRED = 'LEAD_ARCHIVE_REASON_REQUIRED',
  LEAD_RESTRICTED_TO_BE_EMPTY = 'LEAD_RESTRICTED_TO_BE_EMPTY',
  LEAD_CANT_CHANGE_LEAD_SOURCE = 'LEAD_CANT_CHANGE_LEAD_SOURCE',
  LEAD_STATUS_NOT_FOUND = 'LEAD_STATUS_NOT_FOUND',
  LEAD_STATUS_SYSTEM_EDIT_RESTRICTED = 'LEAD_STATUS_SYSTEM_EDIT_RESTRICTED',
  LEAD_STATUS_TOO_MANY_LEAD_IDS = 'LEAD_STATUS_TOO_MANY_LEAD_IDS',
  LEAD_STATUS_CONTACT_ASSOCIATION_CONFLICT = 'LEAD_STATUS_CONTACT_ASSOCIATION_CONFLICT',
  MODE_UPGRADING_INVALID_ONBOARD_STATUS = 'MODE_UPGRADING_INVALID_ONBOARD_STATUS',
  MODE_UPGRADING_ALREADY_UPGRADED = 'MODE_UPGRADING_ALREADY_UPGRADED',
  MODE_UPGRADING_AVAILABLE_ONLY_FOR_OWNER = 'MODE_UPGRADING_AVAILABLE_ONLY_FOR_OWNER',
  OAUTH_SERVICE_ID_INVALID = 'OAUTH_SERVICE_ID_INVALID',
}

export type CommonAppErrorsType = {
  __typename: 'CommonAppErrorsType';
  example: CommonAppErrors;
};

export type CommonDateTimeFilterInput = {
  search?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type CommonPaymentStatFilterInput = {
  search?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type CommonPaymentStatType = {
  __typename: 'CommonPaymentStatType';
  paidToOperation: Scalars['Float'];
  dueToOperation: Scalars['Float'];
  dueToTrust: Scalars['Float'];
};

export type ConnectCustomDomainForSiteInput = {
  domain: Scalars['String'];
};

export type ContactMatterType =
  | ContactType
  | MatterType
  | ThirdPartyPayeeType
  | PlainTextType;

export type ContactMattersTrustSummaryType = {
  __typename: 'ContactMattersTrustSummaryType';
  balance: Scalars['Float'];
  qty: Scalars['Float'];
};

export type ContactPaymentStatFilterInput = {
  search?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  contactId?: Maybe<Scalars['ID']>;
  /** Pass matterId === null to exclude matters stat */
  matterId?: Maybe<Scalars['ID']>;
  bankAccountClass?: Maybe<BankAccountClasses>;
};

export type ContactPaymentStatType = {
  __typename: 'ContactPaymentStatType';
  paid: Scalars['Float'];
  due: Scalars['Float'];
  overdue: Scalars['Float'];
};

export type ContactRevenueType = {
  __typename: 'ContactRevenueType';
  id: Scalars['ID'];
  name: Scalars['String'];
  isEntity: Scalars['Boolean'];
  balanceDue: Scalars['Float'];
  paidAmount: Scalars['Float'];
  revenueAbsolute: Scalars['Float'];
  revenuePercent: Scalars['Float'];
};

export type ContactStatType = {
  __typename: 'ContactStatType';
  phoneNumbers?: Maybe<Array<PhoneNumberType>>;
  addresses?: Maybe<Array<AddressType>>;
  id: Scalars['ID'];
  status: ContactStatuses;
  name: Scalars['String'];
  originatingAttorney?: Maybe<UserType>;
  notes?: Maybe<Scalars['String']>;
  isEntity: Scalars['Boolean'];
  conflictsCheckCompleted: Scalars['Boolean'];
  engagementHasBeenSigned: Scalars['Boolean'];
  w9FormSentToClient: Scalars['Boolean'];
  taxable: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderTypes>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  billingContacts?: Maybe<Array<EntityBillingContactsType>>;
  matters?: Maybe<Array<MatterType>>;
  qboSyncState?: Maybe<QboEntitySyncStatus>;
  trustBalance?: Maybe<Scalars['Float']>;
  date: Scalars['DateTime'];
  originalAmount: Scalars['Float'];
  requestedAmount: Scalars['Float'];
  paidAmount: Scalars['Float'];
  balanceDue: Scalars['Float'];
  totalInvoices: Scalars['Float'];
  amountRate: Scalars['Float'];
};

/** Possible contact statuses */
export enum ContactStatuses {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED',
}

export type ContactSubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
  combinedContactType?: Maybe<CombinedContactTypes>;
  statuses?: Maybe<Array<ContactStatuses>>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type ContactType = {
  __typename: 'ContactType';
  phoneNumbers?: Maybe<Array<PhoneNumberType>>;
  addresses?: Maybe<Array<AddressType>>;
  id: Scalars['ID'];
  status: ContactStatuses;
  name: Scalars['String'];
  originatingAttorney?: Maybe<UserType>;
  notes?: Maybe<Scalars['String']>;
  isEntity: Scalars['Boolean'];
  conflictsCheckCompleted: Scalars['Boolean'];
  engagementHasBeenSigned: Scalars['Boolean'];
  w9FormSentToClient: Scalars['Boolean'];
  taxable: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderTypes>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  billingContacts?: Maybe<Array<EntityBillingContactsType>>;
  matters?: Maybe<Array<MatterType>>;
  qboSyncState?: Maybe<QboEntitySyncStatus>;
  trustBalance?: Maybe<Scalars['Float']>;
};

export type ContactsImportResultType = {
  __typename: 'ContactsImportResultType';
  processed: Scalars['Int'];
  added: Scalars['Int'];
  ignored: Scalars['Int'];
  duplicated: Scalars['Int'];
  invalid: Scalars['Int'];
};

export type CopyFileInput = {
  id: Scalars['ID'];
  entityId?: Maybe<Scalars['ID']>;
  entityType?: Maybe<S3FileSources>;
  fileName?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['ID']>;
};

export type CreateAccountInput = {
  webCaptcha?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  demoUserId?: Maybe<Scalars['ID']>;
  tempUserId?: Maybe<Scalars['ID']>;
  utmParams?: Maybe<UtmParamsInput>;
  promoCode?: Maybe<Scalars['String']>;
};

export type CreateCalendarItemInputType = {
  priority: CalendarItemPriority;
  color?: Maybe<CalendarItemColor>;
  contactId?: Maybe<Scalars['ID']>;
  matterId?: Maybe<Scalars['ID']>;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  itemType: CalendarItemTypes;
  taskData?: Maybe<CalendarItemTaskInputType>;
  eventData?: Maybe<CalendarItemEventDataInputType>;
  deadlineData?: Maybe<CalendarItemDeadlineDataInput>;
  repeats?: Maybe<CalendarItemRepeatInput>;
  insertOnCalendar?: Maybe<Array<InsertOnCalendarInput>>;
};

export type CreateContactDataInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderTypes>;
  originatingAttorney: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  conflictsCheckCompleted: Scalars['Boolean'];
  engagementHasBeenSigned: Scalars['Boolean'];
  w9FormSentToClient: Scalars['Boolean'];
  taxable: Scalars['Boolean'];
  phoneNumbers?: Maybe<Array<PhoneNumberInput>>;
  addresses?: Maybe<Array<AddressInput>>;
  email: Scalars['String'];
};

export type CreateContactInput = {
  contact?: Maybe<CreateContactDataInput>;
  entity?: Maybe<CreateEntityDataInput>;
};

export type CreateDemoAccountInput = {
  mode: Modes;
};

export type CreateDemoAccountResponseType = {
  __typename: 'CreateDemoAccountResponseType';
  email: Scalars['String'];
};

export type CreateDepositInput = {
  transactions: Array<CreateDepositTransactionInput>;
  date: Scalars['Date'];
};

export type CreateDepositTransactionInput = {
  paymentMethod: PaymentMethodsTypes;
  amount: Scalars['Float'];
  memo: Scalars['String'];
  contactId: Scalars['ID'];
  matterId?: Maybe<Scalars['ID']>;
};

export type CreateDisbursementToContactInput = {
  contactId: Scalars['ID'];
  matterId?: Maybe<Scalars['ID']>;
  date: Scalars['Date'];
  memo: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
};

export type CreateDisbursementToOperatingInput = {
  otosIds: Array<Scalars['String']>;
  memo: Scalars['String'];
};

export type CreateDisbursementToThirdPartyInput = {
  contactId: Scalars['ID'];
  matterId?: Maybe<Scalars['ID']>;
  thirdPartyPayeeId?: Maybe<Scalars['ID']>;
  date: Scalars['Date'];
  memo: Scalars['String'];
  amount: Scalars['Float'];
};

export type CreateEntityDataInput = {
  name?: Maybe<Scalars['String']>;
  originatingAttorney: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<Array<PhoneNumberInput>>;
  addresses?: Maybe<Array<AddressInput>>;
  billingContacts: Array<EntityBillingContactsInput>;
  conflictsCheckCompleted: Scalars['Boolean'];
  engagementHasBeenSigned: Scalars['Boolean'];
  w9FormSentToClient: Scalars['Boolean'];
  taxable: Scalars['Boolean'];
};

export type CreateFilesInput = {
  entityId: Scalars['ID'];
  files: Array<S3FileInput>;
  folderId?: Maybe<Scalars['ID']>;
};

export type CreateFilesWithoutEntityInput = {
  files: Array<S3FileInput>;
  folderId?: Maybe<Scalars['ID']>;
};

export type CreateFlatFeeInput = {
  contactId: Scalars['ID'];
  matterId: Scalars['ID'];
  amount: Scalars['Float'];
  date: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  billable?: Maybe<Scalars['Boolean']>;
  billedById: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
};

export type CreateFolderDataInput = {
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
};

export type CreateInvoiceInput = {
  contactId: Scalars['ID'];
  /** Matter id required only for TRUST invoices */
  matterId?: Maybe<Scalars['ID']>;
  timeEntries?: Maybe<Array<Scalars['ID']>>;
  expenses?: Maybe<Array<Scalars['ID']>>;
  flatFees?: Maybe<Array<Scalars['ID']>>;
  amount: Scalars['Float'];
  scheduleSettings?: Maybe<ScheduledPaymentsSettingsInput>;
  dueDate: Scalars['DateTime'];
  internalMemo?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  percentageDiscount: Scalars['Boolean'];
  discount: Scalars['Float'];
  destinationAccount: BankAccountClasses;
};

export type CreateLeadInput = {
  isEntity: Scalars['Boolean'];
  individual?: Maybe<LeadIndividualInput>;
  entity?: Maybe<LeadEntityInput>;
  phoneNumbers: Array<PhoneNumberInput>;
  notes?: Maybe<Scalars['String']>;
  originatingAttorneyId: Scalars['ID'];
  leadSource: LeadSources;
  isImportant?: Maybe<Scalars['Boolean']>;
  linkedContactId?: Maybe<Scalars['ID']>;
  createdFromInbox?: Maybe<Scalars['Boolean']>;
};

export type CreateLeadStatusInput = {
  title: Scalars['String'];
  color?: Maybe<LeadStatusColors>;
};

export type CreateMatterInput = {
  type?: Maybe<MatterTypes>;
  agreedFlatFee?: Maybe<Scalars['Float']>;
  contactId: Scalars['ID'];
  name: Scalars['String'];
  dateOpened: Scalars['Date'];
  statuteOfLimitations?: Maybe<Scalars['Date']>;
  matterNumber?: Maybe<Scalars['String']>;
  teamMembers?: Maybe<Array<MatterTeamMemberInput>>;
  billable?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
};

export type CreatePasswordInput = {
  password: Scalars['String'];
};

export type CreatePaymentInput = {
  matterId?: Maybe<Scalars['ID']>;
  amount: Scalars['Float'];
  paymentMethod: PaymentMethodsTypes;
  invoices: Array<Scalars['ID']>;
  internalMemo?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['Date']>;
};

export type CreatePaymentIntentInput = {
  matterId?: Maybe<Scalars['ID']>;
  amount: Scalars['Float'];
  paymentMethod: PaymentMethodsTypes;
  invoiceIds: Array<Scalars['ID']>;
  internalMemo?: Maybe<Scalars['String']>;
};

export type CreateReconciliationInput = {
  transactions: Array<Scalars['String']>;
  statementBalance: Scalars['Float'];
  statementDate: Scalars['Date'];
};

export type CreateRefundPaymentInput = {
  id: Scalars['ID'];
  amount: Scalars['Float'];
  internalMemo?: Maybe<Scalars['String']>;
};

export type CreateSignatureDraftWithTemplatesInput = {
  signers: Array<SignerTemplateInput>;
  templateId: Scalars['ID'];
  title: Scalars['String'];
  files?: Maybe<Array<S3FileInput>>;
  text?: Maybe<Scalars['String']>;
  nonSignatoryRecipients?: Maybe<Array<Scalars['String']>>;
};

export type CreateSignatureDraftWithoutTemplatesInput = {
  signers: Array<SignerInput>;
  completeInOrder: Scalars['Boolean'];
  title: Scalars['String'];
  files: Array<S3FileInput>;
  text?: Maybe<Scalars['String']>;
  nonSignatoryRecipients?: Maybe<Array<Scalars['String']>>;
};

export type CreateSignatureTemplateInput = {
  signerRoles: Array<SignerRoleTemplateInput>;
  completeInOrder: Scalars['Boolean'];
  title: Scalars['String'];
  files: Array<S3FileInput>;
};

export type CreateSubExpenseCategoryInput = {
  name: Scalars['String'];
  costType: SubExpenseCategoryCostTypes;
};

export type CreateSubExpenseInput = {
  contactId: Scalars['ID'];
  matterId: Scalars['ID'];
  amount: Scalars['Float'];
  date: Scalars['Date'];
  categoryId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  billable?: Maybe<Scalars['Boolean']>;
  billedById: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
};

export type CreateTeamMemberInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  role: TeamMemberRoles;
  accessLevel?: Maybe<AccessLevels>;
  hourlyRate?: Maybe<Scalars['Float']>;
};

export type CreateThirdPartyPayeesInput = {
  payeeName: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  primaryPhone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
};

export type CreateTimeEntryInput = {
  timerId?: Maybe<Scalars['ID']>;
  contactId: Scalars['ID'];
  matterId: Scalars['ID'];
  durationSec: Scalars['Float'];
  billable?: Maybe<Scalars['Boolean']>;
  billedById: Scalars['ID'];
  activityId: Scalars['ID'];
  billingRate?: Maybe<Scalars['Float']>;
  date: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type CreateTimerActivityInput = {
  name: Scalars['String'];
};

export type CreateTimerInput = {
  id?: Maybe<Scalars['ID']>;
  matterId?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
};

export type CreateTrustTransferInput = {
  fromContactId: Scalars['ID'];
  fromMatterId?: Maybe<Scalars['ID']>;
  toContactId: Scalars['ID'];
  toMatterId?: Maybe<Scalars['ID']>;
  description: Scalars['String'];
  amount: Scalars['Float'];
};

export type CreateVoidInvoiceInput = {
  id: Scalars['ID'];
  voidInvoiceText?: Maybe<Scalars['String']>;
};

export type CredentialVerificationTokenType = {
  __typename: 'CredentialVerificationTokenType';
  passwordVerificationToken: Scalars['String'];
};

export type Credentials = {
  login: Scalars['String'];
  password: Scalars['String'];
};

export type CreditMemoDetalizationInput = {
  timeEntryAmount?: Maybe<Scalars['Float']>;
  expenseAmount?: Maybe<Scalars['Float']>;
  flatFeeAmount?: Maybe<Scalars['Float']>;
};

export type CreditMemoDetalizationType = {
  __typename: 'CreditMemoDetalizationType';
  timeEntryAmount?: Maybe<Scalars['Float']>;
  expenseAmount?: Maybe<Scalars['Float']>;
  flatFeeAmount?: Maybe<Scalars['Float']>;
};

export type CreditMemoInput = {
  invoiceId: Scalars['ID'];
  creditAmount: Scalars['Float'];
  memoDetalization?: Maybe<CreditMemoDetalizationInput>;
  memoText?: Maybe<Scalars['String']>;
};

/** Types of dashboard analytic */
export enum DashboardAnalyticTypes {
  OUTSTANDING_INVOICES = 'OUTSTANDING_INVOICES',
  PAID_INVOICES = 'PAID_INVOICES',
}

export type DashboardStatContactType = {
  __typename: 'DashboardStatContactType';
  name: Scalars['String'];
  balanceDue?: Maybe<Scalars['Float']>;
  countOfInvoices: Scalars['Int'];
  paidAmount?: Maybe<Scalars['Float']>;
};

export type DashboardStatFilterInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  analyticType: DashboardAnalyticTypes;
  filter?: Maybe<CommonDateTimeFilterInput>;
};

export type DashboardStatType = {
  __typename: 'DashboardStatType';
  outstandingAmount?: Maybe<Scalars['Float']>;
  paidAmount?: Maybe<Scalars['Float']>;
  contacts?: Maybe<Array<DashboardStatContactType>>;
};

export type DefaultDomainInput = {
  defaultDomain: Scalars['String'];
};

export type DeleteFileInput = {
  id: Scalars['ID'];
};

export type DeleteFilesInput = {
  entityId: Scalars['ID'];
  fileIds?: Maybe<Array<Scalars['ID']>>;
};

export type DeleteFolderInput = {
  id: Scalars['ID'];
};

export type DemoSignInInput = {
  mode?: Maybe<Modes>;
  webCaptcha?: Maybe<Scalars['String']>;
  utmParams?: Maybe<UtmParamsInput>;
};

export type DepositFilterInput = {
  contactId?: Maybe<Scalars['ID']>;
  matterId?: Maybe<Scalars['ID']>;
  sourceId?: Maybe<Scalars['ID']>;
  statuses?: Maybe<Array<Maybe<TrustTransactionStatuses>>>;
  paymentMethods?: Maybe<Array<Maybe<PaymentMethodsTypes>>>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export type DepositSubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
  contactId?: Maybe<Scalars['ID']>;
  matterId?: Maybe<Scalars['ID']>;
  sourceId?: Maybe<Scalars['ID']>;
  statuses?: Maybe<Array<Maybe<TrustTransactionStatuses>>>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export type DepositType = {
  __typename: 'DepositType';
  id: Scalars['ID'];
  date: Scalars['Date'];
  type: TrustTransactionTypes;
  depositTransactions: Array<DepositType>;
  parent?: Maybe<DepositType>;
  systemTransaction?: Maybe<PaymentType>;
  amount: Scalars['Float'];
  status: TrustTransactionStatuses;
  ledger?: Maybe<ContactMatterType>;
  source?: Maybe<ContactType>;
  paymentMethod: PaymentMethodsTypes;
  memo?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
  ledgerBalance?: Maybe<Scalars['Float']>;
  transfer?: Maybe<TrustTransferType>;
  qboSyncState?: Maybe<QboEntitySyncStatus>;
};

export type DisbursementFilterInput = {
  contactId?: Maybe<Scalars['ID']>;
  matterId?: Maybe<Scalars['ID']>;
  statuses?: Maybe<Array<Maybe<TrustTransactionStatuses>>>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export type DisbursementSubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
  contactId?: Maybe<Scalars['ID']>;
  matterId?: Maybe<Scalars['ID']>;
  statuses?: Maybe<Array<Maybe<TrustTransactionStatuses>>>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export type DisbursementType = {
  __typename: 'DisbursementType';
  id: Scalars['ID'];
  date: Scalars['Date'];
  type: TrustTransactionTypes;
  ledger: ContactMatterType;
  amount: Scalars['Float'];
  payee?: Maybe<ContactMatterType>;
  status: TrustTransactionStatuses;
  balance?: Maybe<Scalars['Float']>;
  ledgerBalance?: Maybe<Scalars['Float']>;
  parent?: Maybe<DisbursementType>;
  check?: Maybe<CheckType>;
  payment?: Maybe<PaymentType>;
  paymentMethod: PaymentMethodsTypes;
  otoTransactions?: Maybe<Array<Maybe<DisbursementType>>>;
  memo?: Maybe<Scalars['String']>;
  skipped: Scalars['Boolean'];
  transfer?: Maybe<TrustTransferType>;
  qboSyncState?: Maybe<QboEntitySyncStatus>;
};

export type DrivesType = {
  __typename: 'DrivesType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EmailInput = {
  email: Scalars['String'];
};

export type EnableTwoFactorAuthInput = {
  /** the unchanged SMS code is used for the on Dev environment, check with backend developer for the code */
  phoneNumber: Scalars['String'];
  passwordVerificationToken: Scalars['String'];
};

export type EntityBillingContactsInput = {
  id?: Maybe<Scalars['ID']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  type: BillingContactTypes;
};

export type EntityBillingContactsType = {
  __typename: 'EntityBillingContactsType';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  type: BillingContactTypes;
};

export type ExpenseStatFilterInput = {
  search?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
  matterId?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['ID']>;
  billedByIds?: Maybe<Array<Scalars['String']>>;
  statuses?: Maybe<Array<ExpenseStatuses>>;
};

export type ExpenseStatType = {
  __typename: 'ExpenseStatType';
  totalAmount: Scalars['Float'];
  subExpenseTotalAmount: Scalars['Float'];
  flatFeeTotalAmount: Scalars['Float'];
  timeEntryTotalAmount: Scalars['Float'];
};

/** Types of expense status */
export enum ExpenseStatuses {
  BILLABLE = 'BILLABLE',
  NOT_BILLABLE = 'NOT_BILLABLE',
  BILLED = 'BILLED',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
}

export type ExpenseSubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
  billable?: Maybe<Scalars['Boolean']>;
  mattersIds?: Maybe<Array<Scalars['ID']>>;
  contactId?: Maybe<Scalars['ID']>;
  billedByIds?: Maybe<Array<Scalars['String']>>;
  statuses?: Maybe<Array<ExpenseStatuses>>;
  types?: Maybe<Array<ExpenseTypes>>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export type ExpenseType = {
  __typename: 'ExpenseType';
  id: Scalars['ID'];
  contact: ContactType;
  matter: MatterType;
  amount: Scalars['Float'];
  date: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  billable?: Maybe<Scalars['Boolean']>;
  billedBy: UserType;
  status: ExpenseStatuses;
  type: ExpenseTypes;
  category: SubExpenseCategoryType;
};

/** Types of expenses */
export enum ExpenseTypes {
  SUB_EXPENSE = 'SUB_EXPENSE',
  FLAT_FEE = 'FLAT_FEE',
  TIME_ENTRY = 'TIME_ENTRY',
}

export type ExternalSignerInput = {
  name: Scalars['String'];
  email: Scalars['String'];
};

export type ExternalSignerType = {
  __typename: 'ExternalSignerType';
  name: Scalars['String'];
  email: Scalars['String'];
};

export type FeeBankAccount = {
  __typename: 'FeeBankAccount';
  truncatedNumber: Scalars['String'];
};

export type FetchCalendarItemsInput = {
  filter?: Maybe<FilterCalendarItemsInput>;
};

export type FetchCalendarItemsType = {
  __typename: 'FetchCalendarItemsType';
  items: Array<CalendarItemType>;
};

export type FileAttachEntityInput = {
  id: Scalars['ID'];
  entityId?: Maybe<Scalars['ID']>;
  entityType?: Maybe<S3FileSources>;
  contactId?: Maybe<Scalars['ID']>;
  matterId?: Maybe<Scalars['ID']>;
  fileName?: Maybe<Scalars['String']>;
};

export type FileBase64ResponseType = {
  __typename: 'FileBase64ResponseType';
  data: Scalars['String'];
  mimetype: Scalars['String'];
  name: Scalars['String'];
};

/** Possible categories to file storage */
export enum FileCategories {
  OTHER = 'OTHER',
  MEDIA = 'MEDIA',
  DOCUMENTS = 'DOCUMENTS',
}

export type FileDataToUploadInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  mimetype: Scalars['String'];
};

export type FileFolderListFilter = {
  search?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  contactIds?: Maybe<Array<Scalars['ID']>>;
  matterIds?: Maybe<Array<Scalars['ID']>>;
  leadIds?: Maybe<Array<Scalars['ID']>>;
  invoiceIds?: Maybe<Array<Scalars['ID']>>;
  voidInvoiceIds?: Maybe<Array<Scalars['ID']>>;
  paymentIds?: Maybe<Array<Scalars['ID']>>;
  signatureIds?: Maybe<Array<Scalars['ID']>>;
  signatureTemplateIds?: Maybe<Array<Scalars['ID']>>;
  expenseIds?: Maybe<Array<Scalars['ID']>>;
  reconciliationIds?: Maybe<Array<Scalars['ID']>>;
  trustTransferIds?: Maybe<Array<Scalars['ID']>>;
  source?: Maybe<S3FileSources>;
  folderId?: Maybe<Scalars['ID']>;
  hideClosedMatters?: Maybe<Scalars['Boolean']>;
  fileId?: Maybe<Scalars['ID']>;
  fileIds?: Maybe<Array<Scalars['ID']>>;
  category?: Maybe<FileCategories>;
  type?: Maybe<FileStorageItemTypes>;
  id?: Maybe<Scalars['ID']>;
};

export type FileFolderListInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  contactId?: Maybe<Scalars['ID']>;
  matterId?: Maybe<Scalars['ID']>;
  leadId?: Maybe<Scalars['ID']>;
  filters?: Maybe<FileFolderListFilter>;
  order?: Maybe<OrderFilesStorageInput>;
};

export type FileStorageItemType = {
  __typename: 'FileStorageItemType';
  id: Scalars['ID'];
  name: Scalars['String'];
  attach?: Maybe<AttachedEntities>;
  size?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  isFolder: Scalars['Boolean'];
  mimetype?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['String']>;
  isSystemFolder?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['ID']>;
};

/** Possible item types in file storage */
export enum FileStorageItemTypes {
  FOLDERS = 'FOLDERS',
  FILES = 'FILES',
}

/** File storage sync error types */
export enum FileStorageSyncErrorTypes {
  DESTINATION_TROUBLE = 'DESTINATION_TROUBLE',
  ACCOUNT_TROUBLE = 'ACCOUNT_TROUBLE',
}

export type FileStorageSyncOptionType = {
  __typename: 'FileStorageSyncOptionType';
  folderId?: Maybe<Scalars['ID']>;
  driveId?: Maybe<Scalars['ID']>;
  fileSyncActive?: Maybe<Scalars['Boolean']>;
  syncType?: Maybe<FileStorageSyncTypes>;
  driveName?: Maybe<Scalars['String']>;
  folderName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  breadcrumbs?: Maybe<Array<BreadcrumbType>>;
  currentDriveErrorType?: Maybe<FileStorageSyncErrorTypes>;
  currentDriveErrorCode?: Maybe<CommonAppErrors>;
};

/** File storage sync types */
export enum FileStorageSyncTypes {
  TO_EXTERNAL_STORAGE = 'TO_EXTERNAL_STORAGE',
  TWO_WAY_SYNC = 'TWO_WAY_SYNC',
}

export type FileSubscriptionInput = {
  includeFilesIds?: Maybe<Array<Scalars['ID']>>;
  contactIds?: Maybe<Array<Scalars['ID']>>;
  matterIds?: Maybe<Array<Scalars['ID']>>;
  leadIds?: Maybe<Array<Scalars['ID']>>;
  invoiceIds?: Maybe<Array<Scalars['ID']>>;
  voidInvoiceIds?: Maybe<Array<Scalars['ID']>>;
  paymentIds?: Maybe<Array<Scalars['ID']>>;
  signatureIds?: Maybe<Array<Scalars['ID']>>;
  signatureTemplateIds?: Maybe<Array<Scalars['ID']>>;
  expenseIds?: Maybe<Array<Scalars['ID']>>;
  reconciliationIds?: Maybe<Array<Scalars['ID']>>;
  trustTransferIds?: Maybe<Array<Scalars['ID']>>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type FileUrlResponseType = {
  __typename: 'FileURLResponseType';
  url: Scalars['String'];
};

export type FileUrlWithBase64ResponseType = {
  __typename: 'FileURLWithBase64ResponseType';
  url: Scalars['String'];
  base64: FileBase64ResponseType;
};

export type FileUploadUrlType = {
  __typename: 'FileUploadUrlType';
  id: Scalars['ID'];
  name: Scalars['String'];
  mimetype: Scalars['String'];
  url: Scalars['String'];
};

export type FileUploadingUrlsType = {
  __typename: 'FileUploadingUrlsType';
  files: Array<FileUploadUrlType>;
};

export type FilterCalendarItemsInput = {
  priorities?: Maybe<Array<CalendarItemPriority>>;
  contactId?: Maybe<Scalars['ID']>;
  matterId?: Maybe<Scalars['ID']>;
  shareTypes?: Maybe<Array<CalendarItemShareTypes>>;
  itemTypes?: Maybe<Array<CalendarItemTypes>>;
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  statuses?: Maybe<Array<CalendarItemStatuses>>;
  calendars?: Maybe<Array<Calendars>>;
};

export type FilterContactInput = {
  search?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  combinedContactType?: Maybe<CombinedContactTypes>;
  statuses?: Maybe<Array<ContactStatuses>>;
};

export type FilterContactWithMattersInput = {
  search?: Maybe<Scalars['String']>;
  excludeContactIds?: Maybe<Array<Scalars['ID']>>;
  excludeMatterIds?: Maybe<Array<Scalars['ID']>>;
  includeContactIds?: Maybe<Array<Scalars['ID']>>;
  onlyInitializedTrustLedger?: Maybe<Scalars['Boolean']>;
  onlyPositiveTrustBalance?: Maybe<Scalars['Boolean']>;
};

export type FilterExpenseInput = {
  search?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
  types?: Maybe<Array<ExpenseTypes>>;
  billable?: Maybe<Scalars['Boolean']>;
  mattersIds?: Maybe<Array<Scalars['ID']>>;
  contactId?: Maybe<Scalars['ID']>;
  billedByIds?: Maybe<Array<Scalars['String']>>;
  statuses?: Maybe<Array<ExpenseStatuses>>;
};

export type FilterFilesInput = {
  search?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  entityIds?: Maybe<Array<Scalars['ID']>>;
  mimeTypes?: Maybe<Array<Scalars['String']>>;
  strictFilter?: Maybe<Scalars['Boolean']>;
};

export type FilterFlatFeeInput = {
  search?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
  billable?: Maybe<Scalars['Boolean']>;
  mattersIds?: Maybe<Array<Scalars['ID']>>;
  contactId?: Maybe<Scalars['ID']>;
  billedByIds?: Maybe<Array<Scalars['String']>>;
  statuses?: Maybe<Array<ExpenseStatuses>>;
};

export type FilterInboxRecordsInput = {
  name?: Maybe<Scalars['String']>;
  leadSource?: Maybe<LeadSources>;
};

export type FilterLeadsArchiveInput = {
  name?: Maybe<Scalars['String']>;
  leadSource?: Maybe<LeadSources>;
  importance?: Maybe<LeadImportance>;
  statusId?: Maybe<Scalars['ID']>;
  archiveReason?: Maybe<Scalars['String']>;
  originatingAttorneyId?: Maybe<Scalars['ID']>;
};

export type FilterLeadsInput = {
  name?: Maybe<Scalars['String']>;
  leadSource?: Maybe<LeadSources>;
  importance?: Maybe<LeadImportance>;
  statusId?: Maybe<Scalars['ID']>;
  archiveReason?: Maybe<Scalars['String']>;
  originatingAttorneyId?: Maybe<Scalars['ID']>;
};

export type FilterPaymentInput = {
  search?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  contactId?: Maybe<Scalars['ID']>;
  matterId?: Maybe<Scalars['ID']>;
  paymentMethod?: Maybe<PaymentMethodsTypes>;
  statuses?: Maybe<Array<PaymentStatuses>>;
  types?: Maybe<Array<TransactionTypes>>;
  bankAccountClass?: Maybe<BankAccountClasses>;
};

export type FilterRevenueStatInput = {
  search?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  contactType?: Maybe<CombinedContactTypes>;
};

export type FilterSubExpenseCategoryInput = {
  search?: Maybe<Scalars['String']>;
  scopes?: Maybe<Array<SubExpenseCategoryScope>>;
  statuses?: Maybe<Array<SubExpenseCategoryStatus>>;
  costTypes?: Maybe<Array<SubExpenseCategoryCostTypes>>;
};

export type FilterSubExpenseInput = {
  search?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
  costType?: Maybe<SubExpenseCategoryCostTypes>;
  billable?: Maybe<Scalars['Boolean']>;
  mattersIds?: Maybe<Array<Scalars['ID']>>;
  contactId?: Maybe<Scalars['ID']>;
  billedByIds?: Maybe<Array<Scalars['String']>>;
  statuses?: Maybe<Array<ExpenseStatuses>>;
};

export type FilterTeamMemberInput = {
  search?: Maybe<Scalars['String']>;
  exclude?: Maybe<Array<Scalars['ID']>>;
  matterId?: Maybe<Scalars['ID']>;
  statuses?: Maybe<Array<TeamMemberStatuses>>;
  roles?: Maybe<Array<TeamMemberRoles>>;
  accessLevels?: Maybe<Array<AccessLevels>>;
  hasLeads?: Maybe<Scalars['Boolean']>;
};

export type FilterThirdPartyPayeeInput = {
  search?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  companyName?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<ThirdPartyPayeesStatus>>;
};

export type FilterTimeEntryInput = {
  search?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
  billable?: Maybe<Scalars['Boolean']>;
  mattersIds?: Maybe<Array<Scalars['ID']>>;
  contactId?: Maybe<Scalars['ID']>;
  activityId?: Maybe<Scalars['ID']>;
  billedByIds?: Maybe<Array<Scalars['String']>>;
  statuses?: Maybe<Array<ExpenseStatuses>>;
};

export type FilterTimerActivityInput = {
  search?: Maybe<Scalars['String']>;
  scopes?: Maybe<Array<TimerActivityScopes>>;
  statuses?: Maybe<Array<TimerActivityStatuses>>;
};

export type FilterTimerInput = {
  statuses?: Maybe<Array<TimerStatusTypes>>;
};

export type FinancialTilesUpdatedType = {
  __typename: 'FinancialTilesUpdatedType';
  firmId: Scalars['ID'];
};

/** Possible entity types of firms */
export enum FirmEntityTypes {
  C_CORP = 'C_CORP',
  S_CORP = 'S_CORP',
  SOLE_PROP = 'SOLE_PROP',
  PARTNERSHIP = 'PARTNERSHIP',
  LLC = 'LLC',
  NOT_FOR_PROFIT = 'NOT_FOR_PROFIT',
  GOVERNMENT_AGENCY = 'GOVERNMENT_AGENCY',
}

/** Firm size values */
export enum FirmSizes {
  S1 = 'S1',
  S2_4 = 'S2_4',
  S5_9 = 'S5_9',
  S10_19 = 'S10_19',
  S20_99 = 'S20_99',
  S100_500 = 'S100_500',
  S501_PLUS = 'S501_PLUS',
  NOT_A_LAW_FIRM = 'NOT_A_LAW_FIRM',
}

export type FirmType = {
  __typename: 'FirmType';
  name: Scalars['String'];
  legalName?: Maybe<Scalars['String']>;
  entityType?: Maybe<FirmEntityTypes>;
  businessStartedSince?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<PhoneNumberType>;
  address?: Maybe<AddressType>;
  logo?: Maybe<PictureType>;
  id: Scalars['ID'];
  size?: Maybe<FirmSizes>;
  principal?: Maybe<PrincipalType>;
  trustAccountingStatus: TrustAccountingStatuses;
  printingChecksSettings?: Maybe<PrintingChecksSettingsType>;
  banners: Array<BannerTypes>;
  /**
   * example
   *  full:America/Chicago
   *  short: CDT
   */
  timezone?: Maybe<TimezoneType>;
  paymentLink?: Maybe<Scalars['String']>;
  ownerInfo?: Maybe<OwnerInfoType>;
  printingDepositSlipSettings?: Maybe<PrintingDepostSlipSettingsType>;
  stripeKybStatus?: Maybe<StripeKybStatus>;
  stripeKybErrors?: Maybe<Array<Scalars['String']>>;
  paymentsEnabled: Scalars['Boolean'];
};

export type FlatFeeMatterStatType = {
  __typename: 'FlatFeeMatterStatType';
  agreedFlatFee: Scalars['Float'];
  remainingBalance: Scalars['Float'];
  billedFlatFees: Scalars['Float'];
  billedExpenses: Scalars['Float'];
  paidExpenses: Scalars['Float'];
  balanceDue: Scalars['Float'];
  amountOfNonBillable: Scalars['Float'];
  profitability: Scalars['Float'];
  sumOfAllFlatFees: Scalars['Float'];
};

export type FlatFeeSubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
  billable?: Maybe<Scalars['Boolean']>;
  mattersIds?: Maybe<Array<Scalars['ID']>>;
  contactId?: Maybe<Scalars['ID']>;
  billedByIds?: Maybe<Array<Scalars['String']>>;
  statuses?: Maybe<Array<ExpenseStatuses>>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export type FlatFeeType = {
  __typename: 'FlatFeeType';
  id: Scalars['ID'];
  contact: ContactType;
  matter: MatterType;
  invoice?: Maybe<InvoiceType>;
  amount: Scalars['Float'];
  date: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  billable?: Maybe<Scalars['Boolean']>;
  billedBy: UserType;
  notes?: Maybe<Scalars['String']>;
  status: ExpenseStatuses;
};

export type FolderPathType = {
  __typename: 'FolderPathType';
  id: Scalars['ID'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
};

export type FoldersInDriveType = {
  __typename: 'FoldersInDriveType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Possible gender types */
export enum GenderTypes {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  UNSPECIFIED = 'UNSPECIFIED',
}

export type GenerateRedirectUrlInput = {
  redirectUri: Scalars['String'];
  serviceId: Scalars['String'];
  payload: Scalars['String'];
};

export type GenerateRedirectUrlResponseType = {
  __typename: 'GenerateRedirectUrlResponseType';
  redirectUri: Scalars['String'];
};

export type GetDepositSlipInput = {
  depositsIds: Array<Scalars['ID']>;
  date: Scalars['Date'];
};

export type GetDriveFoldersResponseType = {
  __typename: 'GetDriveFoldersResponseType';
  folders: Array<FoldersInDriveType>;
  nextPageToken?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
};

export type GetDrivesResponseType = {
  __typename: 'GetDrivesResponseType';
  drives: Array<DrivesType>;
  nextPageToken?: Maybe<Scalars['String']>;
};

export type GetFileOfFolderInput = {
  id: Scalars['ID'];
};

export type GetFolderInput = {
  contactId?: Maybe<Scalars['ID']>;
  matterId?: Maybe<Scalars['ID']>;
  leadId?: Maybe<Scalars['ID']>;
};

export type GetGoogleDriveFoldersInput = {
  driveId?: Maybe<Scalars['ID']>;
  nextPageToken?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
};

export type GetGoogleDrivesInput = {
  nextPageToken?: Maybe<Scalars['String']>;
};

export type GetPdfCheckInput = {
  disbursementsIds: Array<Scalars['ID']>;
};

export type GetPathInput = {
  id: Scalars['ID'];
};

export type GetQboRedirectUrlInput = {
  redirectRoute: Scalars['String'];
};

export type GetQboRedirectUrlResponse = {
  __typename: 'GetQboRedirectUrlResponse';
  url: Scalars['String'];
};

export type GetSamplePdfCheckInput = {
  mode: PrintingChecksModes;
  offset?: Maybe<Array<Scalars['Float']>>;
};

export type GetSignatureTemplateInput = {
  id: Scalars['ID'];
};

export type GetSignedUrlForUploadingFilesInput = {
  filesToUpload: Array<FileDataToUploadInput>;
};

export type GetTrustBalanceInput = {
  contactId?: Maybe<Scalars['ID']>;
  matterId?: Maybe<Scalars['ID']>;
};

export type GoogleAuthUrlType = {
  __typename: 'GoogleAuthUrlType';
  authUrl: Scalars['String'];
};

export type GoogleCalendarDataType = {
  __typename: 'GoogleCalendarDataType';
  calendarName: Scalars['String'];
  calendarId: Scalars['ID'];
};

export enum GoogleCalendarErrorCodes {
  INVALID_GRANT = 'INVALID_GRANT',
  INVALID_TOKEN = 'INVALID_TOKEN',
  INSUFFICIENT_CREDENTIALS = 'INSUFFICIENT_CREDENTIALS',
}

export type GooglePersonalIntegrationStatusType = {
  __typename: 'GooglePersonalIntegrationStatusType';
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  error?: Maybe<GoogleCalendarErrorCodes>;
};

export type HideBannerInput = {
  banner: BannerTypes;
  skip?: Maybe<Scalars['Boolean']>;
};

export type InboxApiUrlType = {
  __typename: 'InboxApiUrlType';
  url: Scalars['String'];
};

export type InboxCounterUpdateType = {
  __typename: 'InboxCounterUpdateType';
  notViewedCount: Scalars['Int'];
};

export type InboxRecordSubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
  source?: Maybe<LeadSources>;
};

export type InboxRecordsType = {
  __typename: 'InboxRecordsType';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  source?: Maybe<LeadSources>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type InitialBalanceInput = {
  contactId: Scalars['ID'];
  matterId?: Maybe<Scalars['ID']>;
  balance: Scalars['Float'];
  unclearedTransactions?: Maybe<Array<UnclearedTransactionInput>>;
};

export type InsertOnCalendarInput = {
  id?: Maybe<Scalars['ID']>;
  subject: Scalars['String'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};

export type InsertOnCalendarType = {
  __typename: 'InsertOnCalendarType';
  id: Scalars['ID'];
  subject: Scalars['String'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};

export type IntegrationType = {
  __typename: 'IntegrationType';
  status: Scalars['String'];
  connectedAt?: Maybe<Scalars['DateTime']>;
  disconnectedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  serviceId: Scalars['String'];
};

export type InvoiceClientHistory = {
  __typename: 'InvoiceClientHistory';
  email: Scalars['String'];
};

export type InvoiceCreditMemoHistory = {
  __typename: 'InvoiceCreditMemoHistory';
  id: Scalars['ID'];
  creditAmount: Scalars['Float'];
  requestedAmount: Scalars['Float'];
  balanceDue: Scalars['Float'];
};

export type InvoiceFilterInput = {
  search?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  contactId?: Maybe<Scalars['ID']>;
  matterId?: Maybe<Scalars['ID']>;
  statuses?: Maybe<Array<Maybe<InvoiceStatusesTypes>>>;
  destinationAccount?: Maybe<BankAccountClasses>;
  isOverdue?: Maybe<Scalars['Boolean']>;
  hasMissedPayments?: Maybe<Scalars['Boolean']>;
};

export type InvoiceHistoryType = {
  __typename: 'InvoiceHistoryType';
  date: Scalars['DateTime'];
  status: InvoiceHistoryTypes;
  creditMemo?: Maybe<InvoiceCreditMemoHistory>;
  partialPayment?: Maybe<InvoicePartialPaymentHistory>;
  client?: Maybe<InvoiceClientHistory>;
};

/** Types of invoice history */
export enum InvoiceHistoryTypes {
  CREATED = 'CREATED',
  SENT = 'SENT',
  VIEWED = 'VIEWED',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  CREDIT_MEMO = 'CREDIT_MEMO',
  VOIDED = 'VOIDED',
  REFUNDED = 'REFUNDED',
  EDIT = 'EDIT',
}

export type InvoiceOverdueReminderInput = {
  emailText: Scalars['String'];
  overdueDayQty: Scalars['Int'];
  ongoingOverdueDayQty: Scalars['Int'];
  stopToRemindAfterDayQty?: Maybe<Scalars['Int']>;
  stopToRemindAfterSentQty?: Maybe<Scalars['Int']>;
  includeAccountStatementLink?: Maybe<Scalars['Boolean']>;
};

export type InvoiceOverdueReminderType = {
  __typename: 'InvoiceOverdueReminderType';
  emailText: Scalars['String'];
  overdueDayQty: Scalars['Int'];
  ongoingOverdueDayQty: Scalars['Int'];
  stopToRemindAfterDayQty?: Maybe<Scalars['Int']>;
  stopToRemindAfterSentQty?: Maybe<Scalars['Int']>;
  includeAccountStatementLink?: Maybe<Scalars['Boolean']>;
  reminderSchedule: Array<InvoiceReminderScheduleType>;
};

export type InvoicePartialPaymentHistory = {
  __typename: 'InvoicePartialPaymentHistory';
  paymentAmount: Scalars['Float'];
  dueAmount: Scalars['Float'];
};

export type InvoicePaymentReminderInput = {
  prepaymentReminder?: Maybe<InvoicePrepaymentReminderInput>;
  overdueReminder?: Maybe<InvoiceOverdueReminderInput>;
};

export type InvoicePaymentReminderType = {
  __typename: 'InvoicePaymentReminderType';
  prepaymentReminder?: Maybe<InvoicePrepaymentReminderType>;
  overdueReminder?: Maybe<InvoiceOverdueReminderType>;
};

export type InvoicePrepaymentReminderInput = {
  emailText: Scalars['String'];
  dayQtyBefore: Scalars['Int'];
  includeAccountStatementLink?: Maybe<Scalars['Boolean']>;
};

export type InvoicePrepaymentReminderType = {
  __typename: 'InvoicePrepaymentReminderType';
  emailText: Scalars['String'];
  dayQtyBefore: Scalars['Int'];
  includeAccountStatementLink?: Maybe<Scalars['Boolean']>;
  reminderSchedule: Array<InvoiceReminderScheduleType>;
};

export type InvoiceReminderScheduleType = {
  __typename: 'InvoiceReminderScheduleType';
  date: Scalars['DateTime'];
  sent: Scalars['Boolean'];
};

export type InvoiceStatFilterInput = {
  search?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  contactId?: Maybe<Scalars['ID']>;
  statuses?: Maybe<Array<InvoiceStatusesTypes>>;
  bankAccountClass?: Maybe<BankAccountClasses>;
  contactAmountOrder?: Maybe<OrderTypes>;
  overdue?: Maybe<Scalars['Boolean']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

/** Types of invoice statuses */
export enum InvoiceStatusesTypes {
  CREATED = 'CREATED',
  SENT = 'SENT',
  VIEWED = 'VIEWED',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  VOIDED = 'VOIDED',
}

export type InvoiceSubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
  contactId?: Maybe<Scalars['ID']>;
  mattersIds?: Maybe<Array<Scalars['ID']>>;
  statuses?: Maybe<Array<Maybe<InvoiceStatusesTypes>>>;
  destinationAccount?: Maybe<BankAccountClasses>;
  isOverdue?: Maybe<Scalars['Boolean']>;
  hasMissedPayments?: Maybe<Scalars['Boolean']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type InvoiceType = {
  __typename: 'InvoiceType';
  id: Scalars['ID'];
  contact: ContactType;
  matters?: Maybe<Array<MatterType>>;
  invoiceNumber: Scalars['String'];
  dueDate: Scalars['DateTime'];
  status: InvoiceStatusesTypes;
  paidAmount: Scalars['Float'];
  originalAmount: Scalars['Float'];
  balanceDue: Scalars['Float'];
  requestedAmount: Scalars['Float'];
  history: Array<InvoiceHistoryType>;
  createdAt: Scalars['DateTime'];
  link: Scalars['String'];
  accounts?: Maybe<Array<BankAccountType>>;
  creditMemo?: Maybe<Array<PaymentType>>;
  timeEntries?: Maybe<Array<TimeEntryType>>;
  expenses?: Maybe<Array<SubExpenseType>>;
  flatFees?: Maybe<Array<FlatFeeType>>;
  voidInvoice?: Maybe<VoidInvoiceType>;
  paymentReminder?: Maybe<InvoicePaymentReminderType>;
  scheduledPayments?: Maybe<ScheduledPaymentsType>;
  allowedMemoDetalization?: Maybe<CreditMemoDetalizationType>;
  internalMemo?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  percentageDiscount: Scalars['Boolean'];
  discount: Scalars['Float'];
  destinationAccount?: Maybe<BankAccountClasses>;
  overdueDayQty?: Maybe<Scalars['Int']>;
  missedPaymentQty?: Maybe<Scalars['Int']>;
  qboSyncState?: Maybe<QboEntitySyncStatus>;
  transactions?: Maybe<Array<PaymentType>>;
};

export type InvoicesStatType = {
  __typename: 'InvoicesStatType';
  amount: Scalars['Float'];
  paidAmount: Scalars['Float'];
  totalInvoices: Scalars['Float'];
  contacts: PaginatedContactStatList;
};

/** Types of bank account business activity field */
export enum KybBusinessActivityType {
  LEGAL_SERVICES_AND_ATTORNEYS = 'LEGAL_SERVICES_AND_ATTORNEYS',
}

export type KybBusinessAddressInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: UsaStates;
  zip: Scalars['String'];
};

export type KybBusinessInfoInput = {
  businessName: Scalars['String'];
  legalBusinessName: Scalars['String'];
  structure: KybCompanyType;
  businessActivity: KybBusinessActivityType;
  businessStartDate: Scalars['Date'];
  taxId: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export enum KybCompanyType {
  LLC = 'LLC',
  PRIVATE_PARTNERSHIP = 'PRIVATE_PARTNERSHIP',
  PRIVATE_CORPORATION = 'PRIVATE_CORPORATION',
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
  SINGLE_MEMBER_LLC = 'SINGLE_MEMBER_LLC',
}

export type KybInput = {
  businessInfo: KybBusinessInfoInput;
  controlPerson: KybPersonInput;
  businessAddress?: Maybe<KybBusinessAddressInput>;
  owners?: Maybe<Array<KybPersonInput>>;
  bankAccount: BankAccountInput;
};

export type KybPersonInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  dateOfBirth: Scalars['Date'];
  ssn: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  ownershipPercentage?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  /** Required only for controlPerson block */
  relationship?: Maybe<PersonRelationship>;
};

export type LastCheckNumberType = {
  __typename: 'LastCheckNumberType';
  number?: Maybe<Scalars['Float']>;
};

export type LeadArchiveReason = {
  __typename: 'LeadArchiveReason';
  name: Scalars['String'];
};

export type LeadContactInput = {
  type: LeadContactTypes;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export type LeadContactType = {
  __typename: 'LeadContactType';
  type: LeadContactTypes;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

/** Types of Lead contacts */
export enum LeadContactTypes {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export type LeadEntityInput = {
  entityName?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  contacts: Array<LeadContactInput>;
};

export type LeadEntityType = {
  __typename: 'LeadEntityType';
  entityName?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  contacts: Array<LeadContactType>;
};

export enum LeadImportance {
  ANY_PRIORITY = 'ANY_PRIORITY',
  HAS_IMPORTANT_MARK = 'HAS_IMPORTANT_MARK',
  HAS_NOT_IMPORTANT_MARK = 'HAS_NOT_IMPORTANT_MARK',
}

export type LeadIndividualInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type LeadIndividualType = {
  __typename: 'LeadIndividualType';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

/**
 * IMPORTANT! This enum will be replaced with id or raw string
 * https://appclose.atlassian.net/wiki/spaces/ESQ/pages/2328854543/CRM#Impact-on-other-functionalities
 * Types of Lead sources
 */
export enum LeadSources {
  NONE = 'NONE',
  PERSONAL_ADVICE = 'PERSONAL_ADVICE',
  WEBSITE = 'WEBSITE',
  FACEBOOK = 'FACEBOOK',
  GOOGLE_ADS = 'GOOGLE_ADS',
  GOOGLE_MAPS = 'GOOGLE_MAPS',
  LINKEDIN = 'LINKEDIN',
  LAWYER_DIRECTORY = 'LAWYER_DIRECTORY',
  OFFLINE_EVENT = 'OFFLINE_EVENT',
  ONLINE_EVENT = 'ONLINE_EVENT',
  PRESS = 'PRESS',
}

export enum LeadStatus {
  ARCHIVED = 'ARCHIVED',
  NOT_ARCHIVED = 'NOT_ARCHIVED',
}

export enum LeadStatusColors {
  COLOR_1 = 'COLOR_1',
  COLOR_2 = 'COLOR_2',
  COLOR_3 = 'COLOR_3',
  COLOR_4 = 'COLOR_4',
  COLOR_5 = 'COLOR_5',
  COLOR_6 = 'COLOR_6',
  COLOR_7 = 'COLOR_7',
  COLOR_8 = 'COLOR_8',
  COLOR_9 = 'COLOR_9',
  COLOR_10 = 'COLOR_10',
}

export enum LeadStatusRole {
  HIRED = 'HIRED',
  DID_NOT_HIRED = 'DID_NOT_HIRED',
  INITIAL_STATUS = 'INITIAL_STATUS',
}

export type LeadStatusType = {
  __typename: 'LeadStatusType';
  id: Scalars['ID'];
  title: Scalars['String'];
  order: Scalars['Int'];
  isSystem: Scalars['Boolean'];
  isInitialStatus: Scalars['Boolean'];
  isDisplayable: Scalars['Boolean'];
  color: LeadStatusColors;
  role?: Maybe<LeadStatusRole>;
};

export type LeadSubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
  leadSource?: Maybe<LeadSources>;
  importance?: Maybe<LeadImportance>;
  status?: Maybe<LeadStatus>;
};

export type LeadType = {
  __typename: 'LeadType';
  id: Scalars['ID'];
  statusId: Scalars['ID'];
  isEntity: Scalars['Boolean'];
  individual?: Maybe<LeadIndividualType>;
  entity?: Maybe<LeadEntityType>;
  phoneNumbers: Array<PhoneNumberType>;
  notes?: Maybe<Scalars['String']>;
  originatingAttorney: UserType;
  linkedContact?: Maybe<ContactType>;
  leadSource: LeadSources;
  isImportant: Scalars['Boolean'];
  archiveReason?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  createdFromInbox: Scalars['Boolean'];
  folderId: Scalars['ID'];
};

export type LedgerActivityType = {
  __typename: 'LedgerActivityType';
  balance: Scalars['Float'];
  lastActivity?: Maybe<Scalars['DateTime']>;
  contactId: Scalars['ID'];
  matterId?: Maybe<Scalars['ID']>;
  contactName: Scalars['String'];
  matterName?: Maybe<Scalars['String']>;
  isEntity: Scalars['Boolean'];
};

export type LimitedFlatFeeMatterStat = {
  __typename: 'LimitedFlatFeeMatterStat';
  agreedFlatFee: Scalars['Float'];
  flatFeesSum: Scalars['Float'];
};

export type ListContactInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterContactInput>;
  order?: Maybe<OrderContactInput>;
};

export type ListContactsWithMattersInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterContactWithMattersInput>;
  order?: Maybe<OrderContactInput>;
};

export type ListDepositInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<DepositFilterInput>;
  order?: Maybe<OrderDepositInput>;
};

export type ListDisbursementInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<DisbursementFilterInput>;
  order?: Maybe<OrderDisbursementInput>;
};

export type ListExpensesInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterExpenseInput>;
  order?: Maybe<OrderExpenseInput>;
};

export type ListFilesInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterFilesInput>;
  order?: Maybe<OrderFilesInput>;
};

export type ListFlatFeesInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterFlatFeeInput>;
  order?: Maybe<OrderFlatFeeInput>;
};

export type ListInboxRecordsInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterInboxRecordsInput>;
  order?: Maybe<OrderInboxRecords>;
};

export type ListInvoiceInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<InvoiceFilterInput>;
  order?: Maybe<OrderInvoiceInput>;
};

export type ListLeadArchiveReasonsInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  query: Scalars['String'];
};

export type ListLeadsArchiveInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterLeadsArchiveInput>;
  order?: Maybe<OrderLeadsArchiveInput>;
};

export type ListLeadsInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterLeadsInput>;
  order?: Maybe<OrderLeadsInput>;
};

export type ListLedgerActivitiesInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  order?: Maybe<OrderLedgerActivityInput>;
  search?: Maybe<Scalars['String']>;
};

export type ListMatterInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<MatterFilterInput>;
  order?: Maybe<OrderMatterInput>;
};

export type ListOtoInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  order?: Maybe<OrderOtoInput>;
};

export type ListPaymentInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterPaymentInput>;
  order?: Maybe<OrderPaymentInput>;
};

export type ListQboSyncHistoryFilters = {
  entityType?: Maybe<QboEntityTypes>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  isFailed?: Maybe<Scalars['Boolean']>;
  query?: Maybe<Scalars['String']>;
};

export type ListQboSyncHistoryInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filters?: Maybe<ListQboSyncHistoryFilters>;
};

export type ListReconciliationInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<ReconciliationFilterInput>;
  order?: Maybe<OrderReconciliationInput>;
};

export type ListReconciliationTransactionsInput = {
  statementDate: Scalars['Date'];
  reconciliationId?: Maybe<Scalars['ID']>;
};

export type ListSignatureTemplatesInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<SignatureTemplatesFilterInput>;
  order?: Maybe<SignatureTemplatesOrderInput>;
};

export type ListSignaturesInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<SignatureFilterInput>;
  order?: Maybe<SignatureOrderInput>;
};

export type ListSubExpenseCategories = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterSubExpenseCategoryInput>;
  order?: Maybe<OrderSubExpenseCategoryInput>;
};

export type ListSubExpensesInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterSubExpenseInput>;
  order?: Maybe<OrderSubExpenseInput>;
};

export type ListTeamMembersInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterTeamMemberInput>;
};

export type ListThirdPartyPayeeInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterThirdPartyPayeeInput>;
  order?: Maybe<OrderThirdPartyPayeeInput>;
};

export type ListTimeEntriesInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterTimeEntryInput>;
  order?: Maybe<OrderTimeEntryInput>;
};

export type ListTimerActivityInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<FilterTimerActivityInput>;
  order?: Maybe<OrderTimerActivityInput>;
};

export type ListTrustTransactionInput = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filter?: Maybe<TrustTransactionFilterInput>;
  order?: Maybe<OrderTrustTransactionInput>;
};

export type MatterFilterInput = {
  search?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  clientId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  status?: Maybe<MatterStatuses>;
};

/** Possible matter statuses */
export enum MatterStatuses {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
}

export type MatterSubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
  clientId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  status?: Maybe<MatterStatuses>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type MatterTeamMember = {
  __typename: 'MatterTeamMember';
  member: UserType;
  role: MatterTeamMemberRoles;
  hourlyRate?: Maybe<Scalars['Float']>;
};

export type MatterTeamMemberInput = {
  userId: Scalars['ID'];
  userRole: MatterTeamMemberRoles;
  hourlyRate: Scalars['Float'];
};

/** Possible access levels */
export enum MatterTeamMemberRoles {
  LEAD_ATTORNEY = 'LEAD_ATTORNEY',
  ATTORNEY = 'ATTORNEY',
  PARALEGAL = 'PARALEGAL',
  BILLABLE_ADMIN = 'BILLABLE_ADMIN',
}

export type MatterType = {
  __typename: 'MatterType';
  id: Scalars['ID'];
  type: MatterTypes;
  agreedFlatFee?: Maybe<Scalars['Float']>;
  status: MatterStatuses;
  contact: ContactType;
  name: Scalars['String'];
  dateOpened: Scalars['Date'];
  statuteOfLimitations?: Maybe<Scalars['Date']>;
  matterNumber: Scalars['String'];
  teamMembers: Array<MatterTeamMember>;
  billable: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  trustBalance?: Maybe<Scalars['Float']>;
};

/** Possible matter types */
export enum MatterTypes {
  HYBRID = 'HYBRID',
  FLAT_FEE = 'FLAT_FEE',
  CONTINGENT = 'CONTINGENT',
}

export type MicrosoftAuthUrlType = {
  __typename: 'MicrosoftAuthUrlType';
  authUrl: Scalars['String'];
};

export enum MicrosoftCalendarErrorCodes {
  INVALID_AUTHORIZATION = 'INVALID_AUTHORIZATION',
}

export type MicrosoftPersonalIntegrationStatusType = {
  __typename: 'MicrosoftPersonalIntegrationStatusType';
  email?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  error?: Maybe<MicrosoftCalendarErrorCodes>;
};

/** Modes values */
export enum Modes {
  FREE = 'FREE',
  PLUS = 'PLUS',
}

export type MoveFileInput = {
  id: Scalars['ID'];
  destinationFolderId?: Maybe<Scalars['ID']>;
};

export type MoveFolderInput = {
  id: Scalars['ID'];
  destinationFolderId?: Maybe<Scalars['ID']>;
};

export type Mutation = {
  __typename: 'Mutation';
  /**
   * Action: UPDATE
   * Resource: TEAM_MANAGEMENT
   */
  activateTeamMember: UserType;
  /**
   * Action: UPDATE
   * Resource: BILL
   */
  agreeScheduledBill: BillType;
  /**
   * Action: CREATE
   * Resource: PUBLIC_API
   */
  allowIntegrationAccess: GenerateRedirectUrlResponseType;
  /**
   * Action: UPDATE
   * Resource: FILES
   */
  attachEntity: FileStorageItemType;
  /**
   * Action: UPDATE
   * Resource: INVOICE
   */
  cancelInvoiceSchedule: InvoiceType;
  /**
   * Action: CREATE
   * Resource: CHECK
   */
  cancelPrintChecks: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: SIGNATURE
   */
  cancelSignature: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: PROFILE
   */
  changeMode: UserType;
  /**
   * Action: UPDATE
   * Resource: PASSWORD
   */
  changePassword: AccountType;
  /**
   * Action: UPDATE
   * Resource: MATTER
   */
  closeMatter: MatterType;
  /**
   * Action: CREATE
   * Resource: TRUST_ACCOUNTING_SETUP
   */
  completeContactsFillStep: SuccessResponseType;
  /**
   * Action: CREATE
   * Resource: EMAIL
   */
  confirmEmail: AccountTokensType;
  /**
   * Action: CREATE
   * Resource: SITE_BUILDER
   */
  connectCustomDomainForSite: SuccessResponseType;
  /**
   * Operating be connected with sendKyb
   *
   * Action: CREATE
   * Resource: BANK_ACCOUNT
   */
  connectTrustAccount: SuccessResponseType;
  /**
   * Action: CREATE
   * Resource: FILES
   */
  copyFile: FileStorageItemType;
  /**
   * Action: CREATE
   * Resource: CALENDAR
   */
  createCalendarItem: CalendarItemType;
  /**
   * Action: CREATE
   * Resource: CONTACT
   */
  createContact: ContactType;
  /**
   * Action: CREATE
   * Resource: CONTACT_FILES
   */
  createContactFiles: Array<S3FileType>;
  /**
   * Action: CREATE
   * Resource: CREDIT_MEMO
   */
  createCreditMemo: PaymentType;
  createDemoAccount: CreateDemoAccountResponseType;
  /**
   * Action: CREATE
   * Resource: DEPOSIT
   */
  createDeposit: DepositType;
  /**
   * Action: CREATE
   * Resource: DISBURSEMENT_CONTACT
   */
  createDisbursementToContact: DisbursementType;
  /**
   * Action: CREATE
   * Resource: DISBURSEMENT_OPERATING
   */
  createDisbursementToOperating: DisbursementType;
  /**
   * Action: CREATE
   * Resource: DISBURSEMENT_3RD_PARTY
   */
  createDisbursementToThirdParty: DisbursementType;
  /**
   * Action: CREATE
   * Resource: EXPENSE_FILES
   */
  createExpenseFiles: Array<S3FileType>;
  /**
   * Action: CREATE
   * Resource: FILES
   */
  createFiles: Array<FileStorageItemType>;
  /**
   * Action: CREATE
   * Resource: PAYMENT_LINK
   */
  createFirmPaymentLink: FirmType;
  /**
   * Action: CREATE
   * Resource: FLAT_FEE
   */
  createFlatFee: FlatFeeType;
  /**
   * Action: CREATE
   * Resource: FOLDERS
   */
  createFolder: FileStorageItemType;
  /**
   * Action: CREATE
   * Resource: INVOICE
   */
  createInvoice: InvoiceType;
  /**
   * Action: CREATE
   * Resource: INVOICE_FILES
   */
  createInvoiceFiles: Array<S3FileType>;
  /**
   * Action: CREATE
   * Resource: LEAD
   */
  createLead: LeadType;
  /**
   * Action: CREATE
   * Resource: LEAD_FILES
   */
  createLeadFiles: Array<S3FileType>;
  /**
   * Action: CREATE
   * Resource: LEAD_STATUSES
   */
  createLeadStatus: LeadStatusType;
  /**
   * Action: CREATE
   * Resource: MATTER
   */
  createMatter: MatterType;
  /**
   * Action: CREATE
   * Resource: MATTER_FILES
   */
  createMatterFiles: Array<S3FileType>;
  /**
   * Action: CREATE
   * Resource: PASSWORD
   */
  createPassword: AccountType;
  /**
   * Non applicable for CC and ACH payments
   *
   * Action: CREATE
   * Resource: PAYMENT
   */
  createPayment: PaymentType;
  /**
   * Action: CREATE
   * Resource: PAYMENT_FILES
   */
  createPaymentFiles: Array<S3FileType>;
  /**
   * Only for CC and ACH payments
   *
   * Action: CREATE
   * Resource: PAYMENT
   */
  createPaymentIntent: PaymentIntentType;
  /**
   * Action: CREATE
   * Resource: RECONCILIATION
   */
  createReconciliation: ReconciliationType;
  /**
   * Action: CREATE
   * Resource: RECONCILIATION_FILES
   */
  createReconciliationFiles: Array<S3FileType>;
  /**
   * Action: CREATE
   * Resource: REFUND_PAYMENT
   */
  createRefundPayment: PaymentType;
  /**
   * Action: CREATE
   * Resource: SIGNATURE
   */
  createSignatureDraftWithTemplates: SignatureWithUrlType;
  /**
   * Action: CREATE
   * Resource: SIGNATURE
   */
  createSignatureDraftWithoutTemplates: SignatureWithUrlType;
  /**
   * Action: CREATE
   * Resource: SIGNATURE_TEMPLATE
   */
  createSignatureTemplate: SignatureTemplateWithUrlType;
  /**
   * Action: UPDATE
   * Resource: SIGNATURE
   */
  createSigningEmbeddedUrl: SignatureUrlType;
  /**
   * Action: CREATE
   * Resource: SITE_BUILDER
   */
  createSiteInitialConfiguration: SuccessResponseType;
  /**
   * Action: CREATE
   * Resource: SUB_EXPENSE
   */
  createSubExpense: SubExpenseType;
  /**
   * Action: CREATE
   * Resource: SUB_EXPENSE_CATEGORY
   */
  createSubExpenseCategory: SubExpenseCategoryType;
  /**
   * Action: CREATE
   * Resource: TEAM_MANAGEMENT
   */
  createTeamMember: UserType;
  /**
   * Action: CREATE
   * Resource: THIRD_PARTY_PAYEES
   */
  createThirdPartyPayee: ThirdPartyPayeeType;
  /**
   * Action: CREATE
   * Resource: TIME_ENTRY
   */
  createTimeEntry: TimeEntryType;
  /**
   * Action: CREATE
   * Resource: TIMER
   */
  createTimer: TimerType;
  /**
   * Action: CREATE
   * Resource: TIMER_ACTIVITY
   */
  createTimerActivity: TimerActivityType;
  /**
   * Action: CREATE
   * Resource: TRUST_TRANSFER
   */
  createTrustTransfer: TrustTransferType;
  /**
   * Action: CREATE
   * Resource: TRUST_TRANSFER_FILES
   */
  createTrustTransferFiles: Array<S3FileType>;
  /**
   * Action: CREATE
   * Resource: VOID_INVOICE
   */
  createVoidInvoice: InvoiceType;
  /**
   * Action: CREATE
   * Resource: VOID_INVOICE_FILES
   */
  createVoidInvoiceFiles: Array<S3FileType>;
  /**
   * Action: UPDATE
   * Resource: TEAM_MANAGEMENT
   */
  deactivateTeamMember: UserType;
  /**
   * Action: DELETE
   * Resource: CALENDAR
   */
  deleteCalendarItem: RemovedEntityType;
  /**
   * Action: DELETE
   * Resource: CONTACT
   */
  deleteContact: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: CONTACT_FILES
   */
  deleteContactFiles: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: EXPENSE_FILES
   */
  deleteExpenseFiles: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: FILES
   */
  deleteFile: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: FOLDERS
   */
  deleteFolder: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: LEAD_INBOX
   */
  deleteInboxRecord: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: INVOICE_FILES
   */
  deleteInvoiceFiles: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: LEAD_FILES
   */
  deleteLeadFiles: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: LEAD_STATUSES
   */
  deleteLeadStatus: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: MATTER_FILES
   */
  deleteMatterFiles: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: PAYMENT_FILES
   */
  deletePaymentFiles: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: RECONCILIATION
   */
  deleteReconciliation: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: RECONCILIATION_FILES
   */
  deleteReconciliationFiles: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: SUB_EXPENSE_CATEGORY
   */
  deleteSubExpenseCategory: RemovedEntityType;
  /**
   * Action: DELETE
   * Resource: THIRD_PARTY_PAYEES
   */
  deleteThirdPartyPayee: RemovedEntityType;
  /**
   * Action: DELETE
   * Resource: TIMER_ACTIVITY
   */
  deleteTimerActivity: RemovedEntityType;
  /**
   * Action: DELETE
   * Resource: TRUST_TRANSFER_FILES
   */
  deleteTrustTransferFiles: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: VOID_INVOICE_FILES
   */
  deleteVoidInvoiceFiles: SuccessResponseType;
  demoSignIn: AccountTokensType;
  /**
   * Action: READ
   * Resource: PUBLIC_API
   */
  denyIntegrationAccess: GenerateRedirectUrlResponseType;
  /**
   * Action: DELETE
   * Resource: PROFILE
   */
  disableTwoFactorAuthentication: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: SITE_BUILDER
   */
  disconnectCustomDomainForSite: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: QBO
   */
  disconnectFromQbo: SuccessResponseType;
  /**
   * Action: CREATE
   * Resource: PROFILE
   */
  enableTwoFactorAuthentication: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: SIGNATURE
   */
  finishSignature: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: FILES
   */
  getSignedUrlForUploadingFiles: FileUploadingUrlsType;
  /**
   * Action: UPDATE
   * Resource: FIRM
   */
  hideBanner: FirmType;
  /**
   * Action: CREATE
   * Resource: CONTACT
   */
  importContacts: ContactsImportResultType;
  /**
   * Action: CREATE
   * Resource: SITE_BUILDER
   */
  lockDefaultDomain: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  logoutGoogleFirmIntegration: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: GOOGLE_PERSONAL_INTEGRATION
   */
  logoutGooglePersonalIntegration: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: MICROSOFT_PERSONAL_INTEGRATION
   */
  logoutMicrosoftPersonalIntegration: SuccessResponseType;
  /**
   * Action: CREATE
   * Resource: FILES
   */
  moveFile: FileStorageItemType;
  /**
   * Action: UPDATE
   * Resource: FOLDERS
   */
  moveFolder: FileStorageItemType;
  /**
   * Action: UPDATE
   * Resource: BILL
   */
  payBill: PaymentIntentType;
  /**
   * Action: CREATE
   * Resource: CHECK
   */
  printChecks: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: QBO
   */
  qboBulkResync: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: CONTACT
   */
  qboBulkResyncContact: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: CREDIT_MEMO
   */
  qboBulkResyncCreditMemo: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: DEPOSIT
   */
  qboBulkResyncDeposit: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: DISBURSEMENT
   */
  qboBulkResyncDisbursement: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: INVOICE
   */
  qboBulkResyncInvoice: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: PAYMENT
   */
  qboBulkResyncPayment: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: REFUND_PAYMENT
   */
  qboBulkResyncRefundPayment: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: CONTACT
   */
  qboResyncContact: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: CREDIT_MEMO
   */
  qboResyncCreditMemo: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: DEPOSIT
   */
  qboResyncDeposit: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: DISBURSEMENT
   */
  qboResyncDisbursement: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: INVOICE
   */
  qboResyncInvoice: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: PAYMENT
   */
  qboResyncPayment: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: REFUND_PAYMENT
   */
  qboResyncRefundPayment: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: FLAT_FEE
   */
  removeFlatFee: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: SIGNATURE_TEMPLATE
   */
  removeSignatureTemplate: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: SUB_EXPENSE
   */
  removeSubExpense: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: TEAM_MANAGEMENT
   */
  removeTeamMemberInvitation: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: TIME_ENTRY
   */
  removeTimeEntry: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: FILES
   */
  renameFile: FileStorageItemType;
  /**
   * Action: UPDATE
   * Resource: FOLDERS
   */
  renameFolder: FileStorageItemType;
  /**
   * Action: UPDATE
   * Resource: BANK_ACCOUNT
   */
  replaceBankAccount: SuccessResponseType;
  resendAuthSmsCode: SuccessResponseType;
  /**
   * Action: CREATE
   * Resource: PROFILE
   */
  resendCode: SuccessResponseType;
  resendConfirmationEmail: SuccessResponseType;
  /**
   * Action: SEND
   * Resource: TEAM_MANAGEMENT
   */
  resendTeamMemberInvitation: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: PASSWORD
   */
  resetPassword: AccountType;
  /**
   * Action: UPDATE
   * Resource: FIRM
   */
  saveOwnerInfo: FirmType;
  /**
   * Action: UPDATE
   * Resource: QBO
   */
  saveQboMappingsPreferences: QboMappingsType;
  /**
   * Action: UPDATE
   * Resource: PAYMENT_REMINDER
   */
  scheduleInvoiceReminders: InvoiceType;
  /**
   * Action: SEND
   * Resource: ACCOUNT_STATEMENT
   */
  sendAccountStatementLinkEmail: SuccessResponseType;
  /**
   * Action: SEND
   * Resource: CREDIT_MEMO
   */
  sendCreditMemo: PaymentType;
  /**
   * Action: SEND
   * Resource: INVOICE
   */
  sendInvoice: InvoiceType;
  /**
   * Action: UPDATE
   * Resource: FIRM
   */
  sendKyb: SuccessResponseType;
  /**
   * Action: SEND
   * Resource: PAYMENT
   */
  sendPayment: PaymentType;
  /**
   * Action: SEND
   * Resource: REFUND_PAYMENT
   */
  sendRefundPayment: PaymentType;
  /**
   * Action: REMIND
   * Resource: SIGNATURE
   */
  sendReminderForSignature: SuccessResponseType;
  sendResetPasswordEmail: SuccessResponseType;
  /**
   * Action: SEND
   * Resource: VOID_INVOICE
   */
  sendVoidInvoice: InvoiceType;
  /**
   * Action: CREATE
   * Resource: TRUST_ACCOUNTING_SETUP
   */
  setInitialLedgerBalances: SuccessResponseType;
  setPrivacy?: Maybe<UserType>;
  /**
   * Action: UPDATE
   * Resource: SUB_EXPENSE_CATEGORY
   */
  setStatusSubExpenseCategory: SubExpenseCategoryType;
  /**
   * Action: UPDATE
   * Resource: THIRD_PARTY_PAYEES
   */
  setStatusThirdPartyPayee: ThirdPartyPayeeType;
  /**
   * Action: UPDATE
   * Resource: TIMER_ACTIVITY
   */
  setStatusTimerActivity: TimerActivityType;
  signIn: AccountTokensType;
  signInWithToken: AccountTokensType;
  /**
   * Action: SIGN_OUT
   * Resource: USER
   */
  signOut: SuccessResponseType;
  signUp: AccountType;
  /**
   * Action: CREATE
   * Resource: TRUST_ACCOUNTING_SETUP
   */
  skipQboIntegrationStep: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: CALENDAR
   */
  synchronizeGoogleCalendar: CalendarSyncSettingsType;
  /**
   * Action: UPDATE
   * Resource: CALENDAR
   */
  synchronizeOutlookCalendar: CalendarSyncSettingsType;
  /**
   * Action: UPDATE
   * Resource: PROFILE
   */
  trackAnalyticEvent: SuccessResponseType;
  trackPublicAnalyticEvent: SuccessResponseType;
  twoFactorSignIn: AccountTokensType;
  /**
   * Action: UPDATE
   * Resource: CALENDAR
   */
  updateCalendarItem: CalendarItemType;
  /**
   * Action: UPDATE
   * Resource: CALENDAR
   */
  updateCalendarSyncSettings: CalendarSyncSettingsType;
  /**
   * Action: UPDATE
   * Resource: CONTACT
   */
  updateContact: ContactType;
  /**
   * Action: UPDATE
   * Resource: CONTACT_FILES
   */
  updateContactFiles: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: EXPENSE_FILES
   */
  updateExpenseFiles: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: FIRM
   */
  updateFirm: FirmType;
  /**
   * Action: UPDATE
   * Resource: FLAT_FEE
   */
  updateFlatFee: FlatFeeType;
  /**
   * Action: UPDATE
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  updateGoogleFileStorageSyncOptions: FileStorageSyncOptionType;
  /**
   * Action: UPDATE
   * Resource: INVOICE
   */
  updateInvoice: InvoiceType;
  /**
   * Action: UPDATE
   * Resource: INVOICE_FILES
   */
  updateInvoiceFiles: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: LEAD
   */
  updateLead: LeadType;
  /**
   * Action: UPDATE
   * Resource: LEAD_FILES
   */
  updateLeadFiles: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: LEAD_STATUSES
   */
  updateLeadStatus: LeadStatusType;
  /**
   * Action: UPDATE
   * Resource: LEAD
   */
  updateLeadsStatus: UpdateLeadsStatusType;
  /**
   * Action: UPDATE
   * Resource: MATTER
   */
  updateMatter: MatterType;
  /**
   * Action: UPDATE
   * Resource: MATTER_FILES
   */
  updateMatterFiles: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: MATTER
   */
  updateMatterType: MatterType;
  /**
   * Action: UPDATE
   * Resource: PAYMENT_FILES
   */
  updatePaymentFiles: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: FIRM
   */
  updatePrintingChecksSettings: FirmType;
  /**
   * Action: UPDATE
   * Resource: FIRM
   */
  updatePrintingDepositSlipSettings: FirmType;
  /**
   * Action: UPDATE
   * Resource: PROFILE
   */
  updateProfile: UserType;
  /**
   * Action: UPDATE
   * Resource: RECONCILIATION
   */
  updateReconciliation: ReconciliationType;
  /**
   * Action: UPDATE
   * Resource: RECONCILIATION_FILES
   */
  updateReconciliationFiles: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: PROFILE
   */
  updateRoundingRules: RoundingRulesType;
  /**
   * Action: UPDATE
   * Resource: SIGNATURE
   */
  updateSignature: SignatureUrlType;
  /**
   * Action: UPDATE
   * Resource: SIGNATURE_TEMPLATE
   */
  updateSignatureTemplate: SignatureTemplateWithUrlType;
  /**
   * Action: UPDATE
   * Resource: SITE_BUILDER
   */
  updateSiteActivity: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: SUB_EXPENSE
   */
  updateSubExpense: SubExpenseType;
  /**
   * Action: UPDATE
   * Resource: SUB_EXPENSE_CATEGORY
   */
  updateSubExpenseCategory: SubExpenseCategoryType;
  /**
   * Action: UPDATE
   * Resource: TEAM_MANAGEMENT
   */
  updateTeamMember: UserType;
  /**
   * Action: UPDATE
   * Resource: THIRD_PARTY_PAYEES
   */
  updateThirdPartyPayee: ThirdPartyPayeeType;
  /**
   * Action: UPDATE
   * Resource: TIME_ENTRY
   */
  updateTimeEntry: TimeEntryType;
  /**
   * Action: UPDATE
   * Resource: TIMER
   */
  updateTimer: TimerType;
  /**
   * Action: UPDATE
   * Resource: TIMER_ACTIVITY
   */
  updateTimerActivity: TimerActivityType;
  /**
   * Action: UPDATE
   * Resource: TRUST_TRANSFER_FILES
   */
  updateTrustTransferFiles: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: VOID_INVOICE_FILES
   */
  updateVoidInvoiceFiles: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: MODE
   */
  upgradeMode: SuccessResponseType;
  /**
   * Action: CREATE
   * Resource: PROFILE
   */
  verifyCredentials: CredentialVerificationTokenType;
  /**
   * Action: CREATE
   * Resource: PROFILE
   */
  verifyTwoFactorCode: TwoFactorAuthenticationOptionsType;
  /**
   * Action: DELETE
   * Resource: DISBURSEMENT
   */
  voidDisbursement: SuccessResponseType;
};

export type MutationActivateTeamMemberArgs = {
  id: Scalars['ID'];
};

export type MutationAgreeScheduledBillArgs = {
  agreeScheduledBillInput: AgreeScheduledBillInput;
};

export type MutationAllowIntegrationAccessArgs = {
  GenerateRedirectUrlInput: GenerateRedirectUrlInput;
};

export type MutationAttachEntityArgs = {
  attachEntityInput: FileAttachEntityInput;
};

export type MutationCancelInvoiceScheduleArgs = {
  cancelInvoiceScheduleInput: CancelInvoiceScheduleInput;
};

export type MutationCancelPrintChecksArgs = {
  cancelPrintChecksInput: CancelPrintChecksInput;
};

export type MutationCancelSignatureArgs = {
  signatureIdInput: SignatureIdInput;
};

export type MutationChangeModeArgs = {
  changeModeInput: ChangeModeInput;
};

export type MutationChangePasswordArgs = {
  changePasswordInput: ChangePasswordInput;
};

export type MutationCloseMatterArgs = {
  closeMatterInput: CloseMatterInput;
};

export type MutationConnectCustomDomainForSiteArgs = {
  connectCustomDomainForSiteInput: ConnectCustomDomainForSiteInput;
};

export type MutationConnectTrustAccountArgs = {
  connectTrustAccountInput: BankAccountInput;
};

export type MutationCopyFileArgs = {
  copyFileInput: CopyFileInput;
};

export type MutationCreateCalendarItemArgs = {
  createCalendarItemInput: CreateCalendarItemInputType;
};

export type MutationCreateContactArgs = {
  createContactInput: CreateContactInput;
};

export type MutationCreateContactFilesArgs = {
  createFilesInput: CreateFilesInput;
};

export type MutationCreateCreditMemoArgs = {
  creditMemoInput: CreditMemoInput;
};

export type MutationCreateDemoAccountArgs = {
  createDemoAccountInput: CreateDemoAccountInput;
};

export type MutationCreateDepositArgs = {
  createDepositInput: CreateDepositInput;
};

export type MutationCreateDisbursementToContactArgs = {
  createDisbursementInput: CreateDisbursementToContactInput;
};

export type MutationCreateDisbursementToOperatingArgs = {
  createDisbursementInput: CreateDisbursementToOperatingInput;
};

export type MutationCreateDisbursementToThirdPartyArgs = {
  createDisbursementInput: CreateDisbursementToThirdPartyInput;
};

export type MutationCreateExpenseFilesArgs = {
  createFilesInput: CreateFilesInput;
};

export type MutationCreateFilesArgs = {
  createFilesInput: CreateFilesWithoutEntityInput;
};

export type MutationCreateFlatFeeArgs = {
  createFlatFeeInput: CreateFlatFeeInput;
};

export type MutationCreateFolderArgs = {
  createFolderInput: CreateFolderDataInput;
};

export type MutationCreateInvoiceArgs = {
  createInvoiceInput: CreateInvoiceInput;
};

export type MutationCreateInvoiceFilesArgs = {
  createFilesInput: CreateFilesInput;
};

export type MutationCreateLeadArgs = {
  createLeadInput: CreateLeadInput;
};

export type MutationCreateLeadFilesArgs = {
  createFilesInput: CreateFilesInput;
};

export type MutationCreateLeadStatusArgs = {
  createLeadStatusInput: CreateLeadStatusInput;
};

export type MutationCreateMatterArgs = {
  createMatterInput: CreateMatterInput;
};

export type MutationCreateMatterFilesArgs = {
  createFilesInput: CreateFilesInput;
};

export type MutationCreatePasswordArgs = {
  createPasswordInput: CreatePasswordInput;
};

export type MutationCreatePaymentArgs = {
  createPaymentInput: CreatePaymentInput;
};

export type MutationCreatePaymentFilesArgs = {
  createFilesInput: CreateFilesInput;
};

export type MutationCreatePaymentIntentArgs = {
  createPaymentIntentInput: CreatePaymentIntentInput;
};

export type MutationCreateReconciliationArgs = {
  createReconciliationInput: CreateReconciliationInput;
};

export type MutationCreateReconciliationFilesArgs = {
  createFilesInput: CreateFilesInput;
};

export type MutationCreateRefundPaymentArgs = {
  createRefundPaymentInput: CreateRefundPaymentInput;
};

export type MutationCreateSignatureDraftWithTemplatesArgs = {
  createSignatureDraftWithTemplatesInput: CreateSignatureDraftWithTemplatesInput;
};

export type MutationCreateSignatureDraftWithoutTemplatesArgs = {
  createSignatureDraftWithoutTemplatesInput: CreateSignatureDraftWithoutTemplatesInput;
};

export type MutationCreateSignatureTemplateArgs = {
  createSignatureTemplateInput: CreateSignatureTemplateInput;
};

export type MutationCreateSubExpenseArgs = {
  createSubExpenseInput: CreateSubExpenseInput;
};

export type MutationCreateSubExpenseCategoryArgs = {
  createSubExpenseCategory: CreateSubExpenseCategoryInput;
};

export type MutationCreateTeamMemberArgs = {
  createTeamMemberInput: CreateTeamMemberInput;
};

export type MutationCreateThirdPartyPayeeArgs = {
  thirdPartyPayeeInput: CreateThirdPartyPayeesInput;
};

export type MutationCreateTimeEntryArgs = {
  createTimerInput: CreateTimeEntryInput;
};

export type MutationCreateTimerArgs = {
  createTimerInput: CreateTimerInput;
};

export type MutationCreateTimerActivityArgs = {
  createTimerActivityInput: CreateTimerActivityInput;
};

export type MutationCreateTrustTransferArgs = {
  createTrustTransferInput: CreateTrustTransferInput;
};

export type MutationCreateTrustTransferFilesArgs = {
  createFilesInput: CreateFilesInput;
};

export type MutationCreateVoidInvoiceArgs = {
  createVoidInvoiceInput: CreateVoidInvoiceInput;
};

export type MutationCreateVoidInvoiceFilesArgs = {
  createFilesInput: CreateFilesInput;
};

export type MutationDeactivateTeamMemberArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCalendarItemArgs = {
  repeatModificationType?: Maybe<CalendarRepeatModificationTypes>;
  id: Scalars['ID'];
};

export type MutationDeleteContactArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteContactFilesArgs = {
  deleteFilesInput: DeleteFilesInput;
};

export type MutationDeleteExpenseFilesArgs = {
  deleteFilesInput: DeleteFilesInput;
};

export type MutationDeleteFileArgs = {
  deleteFilesInput: DeleteFileInput;
};

export type MutationDeleteFolderArgs = {
  deleteFolderInput: DeleteFolderInput;
};

export type MutationDeleteInboxRecordArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteInvoiceFilesArgs = {
  deleteFilesInput: DeleteFilesInput;
};

export type MutationDeleteLeadFilesArgs = {
  deleteFilesInput: DeleteFilesInput;
};

export type MutationDeleteLeadStatusArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteMatterFilesArgs = {
  deleteFilesInput: DeleteFilesInput;
};

export type MutationDeletePaymentFilesArgs = {
  deleteFilesInput: DeleteFilesInput;
};

export type MutationDeleteReconciliationArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteReconciliationFilesArgs = {
  deleteFilesInput: DeleteFilesInput;
};

export type MutationDeleteSubExpenseCategoryArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteThirdPartyPayeeArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteTimerActivityArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteTrustTransferFilesArgs = {
  deleteFilesInput: DeleteFilesInput;
};

export type MutationDeleteVoidInvoiceFilesArgs = {
  deleteFilesInput: DeleteFilesInput;
};

export type MutationDemoSignInArgs = {
  demoSignInInput: DemoSignInInput;
};

export type MutationDenyIntegrationAccessArgs = {
  GenerateRedirectUrlInput: GenerateRedirectUrlInput;
};

export type MutationEnableTwoFactorAuthenticationArgs = {
  enableTwoFactorInput: EnableTwoFactorAuthInput;
};

export type MutationFinishSignatureArgs = {
  signatureIdInput: SignatureIdInput;
};

export type MutationGetSignedUrlForUploadingFilesArgs = {
  getSignedUrlForUploadingFilesInput: GetSignedUrlForUploadingFilesInput;
};

export type MutationHideBannerArgs = {
  hideBannerInput: HideBannerInput;
};

export type MutationImportContactsArgs = {
  vCardFiles: Array<Scalars['Upload']>;
};

export type MutationLockDefaultDomainArgs = {
  defaultDomainInput: DefaultDomainInput;
};

export type MutationMoveFileArgs = {
  moveFileInput: MoveFileInput;
};

export type MutationMoveFolderArgs = {
  moveFolderInput: MoveFolderInput;
};

export type MutationPayBillArgs = {
  payBillInput: PayBillInput;
};

export type MutationPrintChecksArgs = {
  printChecksInput: PrintChecksInput;
};

export type MutationQboBulkResyncArgs = {
  qboBulkResyncInput: QboBulkResyncInput;
};

export type MutationQboBulkResyncContactArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationQboBulkResyncCreditMemoArgs = {
  id: Array<Scalars['ID']>;
};

export type MutationQboBulkResyncDepositArgs = {
  id: Array<Scalars['ID']>;
};

export type MutationQboBulkResyncDisbursementArgs = {
  id: Array<Scalars['ID']>;
};

export type MutationQboBulkResyncInvoiceArgs = {
  id: Array<Scalars['ID']>;
};

export type MutationQboBulkResyncPaymentArgs = {
  id: Array<Scalars['ID']>;
};

export type MutationQboBulkResyncRefundPaymentArgs = {
  id: Array<Scalars['ID']>;
};

export type MutationQboResyncContactArgs = {
  id: Scalars['ID'];
};

export type MutationQboResyncCreditMemoArgs = {
  id: Scalars['ID'];
};

export type MutationQboResyncDepositArgs = {
  id: Scalars['ID'];
};

export type MutationQboResyncDisbursementArgs = {
  id: Scalars['ID'];
};

export type MutationQboResyncInvoiceArgs = {
  id: Scalars['ID'];
};

export type MutationQboResyncPaymentArgs = {
  id: Scalars['ID'];
};

export type MutationQboResyncRefundPaymentArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveFlatFeeArgs = {
  removeFlatFeeInput: RemoveFlatFeeInput;
};

export type MutationRemoveSignatureTemplateArgs = {
  removeSignatureTemplateInput: RemoveSignatureTemplateInput;
};

export type MutationRemoveSubExpenseArgs = {
  removeSubExpenseInput: RemoveSubExpenseInput;
};

export type MutationRemoveTeamMemberInvitationArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveTimeEntryArgs = {
  removeTimeEntryInput: RemoveTimeEntryInput;
};

export type MutationRenameFileArgs = {
  renameFileInput: RenameFileInput;
};

export type MutationRenameFolderArgs = {
  renameFilesInput: RenameFolderDataInput;
};

export type MutationReplaceBankAccountArgs = {
  replaceBankAccountInput: ReplaceBankAccountInput;
};

export type MutationResendAuthSmsCodeArgs = {
  resendAuthSmsCode: TwoFactorAuthenticationResendCode;
};

export type MutationResendConfirmationEmailArgs = {
  resendConfirmationEmailInput: EmailInput;
};

export type MutationResendTeamMemberInvitationArgs = {
  memberId: Scalars['ID'];
};

export type MutationResetPasswordArgs = {
  resetPasswordInput: ResetPasswordInput;
};

export type MutationSaveOwnerInfoArgs = {
  ownerInfoInput: OwnerInfoInput;
};

export type MutationSaveQboMappingsPreferencesArgs = {
  qboSaveMappingsInput: QboSaveMappingsInput;
};

export type MutationScheduleInvoiceRemindersArgs = {
  scheduleInvoiceRemindersInput: ScheduleInvoiceRemindersInput;
};

export type MutationSendAccountStatementLinkEmailArgs = {
  sendAccountStatementLinkEmailInput: EmailInput;
};

export type MutationSendCreditMemoArgs = {
  sendMemoInput: SendCreditMemoInput;
};

export type MutationSendInvoiceArgs = {
  sendInvoiceInput: SendInvoiceInput;
};

export type MutationSendKybArgs = {
  kybInput: KybInput;
};

export type MutationSendPaymentArgs = {
  sendPaymentInput: SendPaymentInput;
};

export type MutationSendRefundPaymentArgs = {
  sendRefundPaymentInput: SendRefundPaymentInput;
};

export type MutationSendReminderForSignatureArgs = {
  signatureIdInput: SignatureIdInput;
};

export type MutationSendResetPasswordEmailArgs = {
  sendResetPasswordEmail: EmailInput;
};

export type MutationSendVoidInvoiceArgs = {
  sendVoidInvoiceInput: SendVoidInvoiceInput;
};

export type MutationSetInitialLedgerBalancesArgs = {
  setInitialBalancesInput: SetInitialBalancesInput;
};

export type MutationSetPrivacyArgs = {
  privacy: Scalars['Boolean'];
};

export type MutationSetStatusSubExpenseCategoryArgs = {
  setStatusSubExpenseCategoryInput: SetStatusSubExpenseCategoryInput;
};

export type MutationSetStatusThirdPartyPayeeArgs = {
  setStatusThirdPartyPayeeInput: SetStatusThirdPartyPayeesInput;
};

export type MutationSetStatusTimerActivityArgs = {
  setStatusTimerActivityInput: SetStatusTimerActivityInput;
};

export type MutationSignInArgs = {
  credentials: Credentials;
};

export type MutationSignInWithTokenArgs = {
  refreshToken: Scalars['String'];
};

export type MutationSignUpArgs = {
  createAccountInput: CreateAccountInput;
};

export type MutationSynchronizeGoogleCalendarArgs = {
  calendarGoogleSynchronizeInput: CalendarGoogleSynchronizeInput;
};

export type MutationSynchronizeOutlookCalendarArgs = {
  calendarOutlookSynchronizeInput: CalendarOutlookSynchronizeInput;
};

export type MutationTrackAnalyticEventArgs = {
  trackAnalyticEventInput: TrackAnalyticEventInput;
};

export type MutationTrackPublicAnalyticEventArgs = {
  trackPublicAnalyticEventInput: TrackPublicAnalyticEventInput;
};

export type MutationTwoFactorSignInArgs = {
  twoFactorAuthSignInInput: TwoFactorAuthSignInInput;
};

export type MutationUpdateCalendarItemArgs = {
  updateCalendarItemsInput: UpdateCalendarItemInputType;
};

export type MutationUpdateCalendarSyncSettingsArgs = {
  calendarSyncSettings: CalendarSyncSettingsInput;
};

export type MutationUpdateContactArgs = {
  updateContactInput: UpdateContactInput;
};

export type MutationUpdateContactFilesArgs = {
  updateFilesInput: UpdateFilesInput;
};

export type MutationUpdateExpenseFilesArgs = {
  updateFilesInput: UpdateFilesInput;
};

export type MutationUpdateFirmArgs = {
  updateFirmInput: UpdateFirmInput;
  logo?: Maybe<Scalars['Upload']>;
};

export type MutationUpdateFlatFeeArgs = {
  updateFlatFeeInput: UpdateFlatFeeInput;
};

export type MutationUpdateGoogleFileStorageSyncOptionsArgs = {
  updateSyncOptionsInput: UpdateSyncOptionsInput;
};

export type MutationUpdateInvoiceArgs = {
  updateInvoiceInput: UpdateInvoiceInput;
};

export type MutationUpdateInvoiceFilesArgs = {
  updateFilesInput: UpdateFilesInput;
};

export type MutationUpdateLeadArgs = {
  updateLeadInput: UpdateLeadInput;
  id: Scalars['ID'];
};

export type MutationUpdateLeadFilesArgs = {
  updateFilesInput: UpdateFilesInput;
};

export type MutationUpdateLeadStatusArgs = {
  updateLeadStatusInput: UpdateLeadStatusInput;
  id: Scalars['ID'];
};

export type MutationUpdateLeadsStatusArgs = {
  updateLeadsStatusInput: UpdateLeadsStatusInput;
};

export type MutationUpdateMatterArgs = {
  updateMatterInput: UpdateMatterInput;
};

export type MutationUpdateMatterFilesArgs = {
  updateFilesInput: UpdateFilesInput;
};

export type MutationUpdateMatterTypeArgs = {
  updateMatterTypeInput: UpdateMatterTypeInput;
};

export type MutationUpdatePaymentFilesArgs = {
  updateFilesInput: UpdateFilesInput;
};

export type MutationUpdatePrintingChecksSettingsArgs = {
  savePrintingChecksSettingsInput: SavePrintingChecksSettingsInput;
};

export type MutationUpdatePrintingDepositSlipSettingsArgs = {
  savePrintingDepositSlipSettingsInput: SavePrintingDepositSlipSettingsInput;
};

export type MutationUpdateProfileArgs = {
  updateProfileInput: UpdateProfileInput;
  picture?: Maybe<Scalars['Upload']>;
};

export type MutationUpdateReconciliationArgs = {
  updateReconciliationInput: UpdateReconciliationInput;
};

export type MutationUpdateReconciliationFilesArgs = {
  updateFilesInput: UpdateFilesInput;
};

export type MutationUpdateRoundingRulesArgs = {
  updateRoundingRulesInput: UpdateRoundingRulesInput;
};

export type MutationUpdateSignatureArgs = {
  signatureIdInput: SignatureIdInput;
};

export type MutationUpdateSignatureTemplateArgs = {
  updateSignatureTemplateInput: UpdateSignatureTemplateInput;
};

export type MutationUpdateSiteActivityArgs = {
  updateSiteActivityInput: UpdateSiteActivityInput;
};

export type MutationUpdateSubExpenseArgs = {
  updateSubExpenseInput: UpdateSubExpenseInput;
};

export type MutationUpdateSubExpenseCategoryArgs = {
  updateSubExpenseCategoryInput: UpdateSubExpenseCategoryInput;
};

export type MutationUpdateTeamMemberArgs = {
  picture?: Maybe<Scalars['Upload']>;
  updateTeamMemberInput: UpdateTeamMemberInput;
};

export type MutationUpdateThirdPartyPayeeArgs = {
  thirdPartyPayeeInput: UpdateThirdPartyPayeesInput;
};

export type MutationUpdateTimeEntryArgs = {
  updateTimerInput: UpdateTimeEntryInput;
};

export type MutationUpdateTimerArgs = {
  updateTimerInput: UpdateTimerInput;
};

export type MutationUpdateTimerActivityArgs = {
  updateTimerActivityInput: UpdateTimerActivityInput;
};

export type MutationUpdateTrustTransferFilesArgs = {
  updateFilesInput: UpdateFilesInput;
};

export type MutationUpdateVoidInvoiceFilesArgs = {
  updateFilesInput: UpdateFilesInput;
};

export type MutationUpgradeModeArgs = {
  upgradeModeInput: UpgradeModeInput;
};

export type MutationVerifyCredentialsArgs = {
  verifyCredentialsInput: VerifyCredentialsInput;
};

export type MutationVerifyTwoFactorCodeArgs = {
  verifyCodeInput: VerifyCodeInput;
};

export type MutationVoidDisbursementArgs = {
  id: Scalars['ID'];
};

export type OAuthExternalServiceType = {
  __typename: 'OAuthExternalServiceType';
  serviceId: Scalars['ID'];
  name: Scalars['String'];
  scopes: Array<Scopes>;
};

/** OnboardStatuses values */
export enum OnboardStatuses {
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  OWNER_FILLING = 'OWNER_FILLING',
  PROFILE_FILLING = 'PROFILE_FILLING',
  MODE_SELECTION = 'MODE_SELECTION',
  LIMITED_ACCESS = 'LIMITED_ACCESS',
  KYB_FILLING = 'KYB_FILLING',
  KYB_SENT = 'KYB_SENT',
  COMPLETE = 'COMPLETE',
  BLOCKED = 'BLOCKED',
}

export type OrderContactInput = {
  name?: Maybe<OrderTypes>;
  email?: Maybe<OrderTypes>;
  createdAt?: Maybe<OrderTypes>;
};

export type OrderDepositInput = {
  date?: Maybe<OrderTypes>;
  status?: Maybe<OrderTypes>;
  amount?: Maybe<OrderTypes>;
};

export type OrderDisbursementInput = {
  date?: Maybe<OrderTypes>;
  amount?: Maybe<OrderTypes>;
  status?: Maybe<OrderTypes>;
};

export type OrderExpenseInput = {
  billedBy?: Maybe<OrderTypes>;
  status?: Maybe<OrderTypes>;
  date?: Maybe<OrderTypes>;
  contact?: Maybe<OrderTypes>;
  matter?: Maybe<OrderTypes>;
};

export type OrderFilesInput = {
  name?: Maybe<OrderTypes>;
  dateAdded?: Maybe<OrderTypes>;
  mimetype?: Maybe<OrderTypes>;
};

export type OrderFilesStorageInput = {
  name?: Maybe<OrderTypes>;
  updatedAt?: Maybe<OrderTypes>;
  size?: Maybe<OrderTypes>;
};

export type OrderFlatFeeInput = {
  billedBy?: Maybe<OrderTypes>;
  status?: Maybe<OrderTypes>;
  date?: Maybe<OrderTypes>;
  contact?: Maybe<OrderTypes>;
  matter?: Maybe<OrderTypes>;
  description?: Maybe<OrderTypes>;
};

export type OrderInboxRecords = {
  name?: Maybe<OrderTypes>;
  updatedAt?: Maybe<OrderTypes>;
  email?: Maybe<OrderTypes>;
  source?: Maybe<OrderTypes>;
};

export type OrderInvoiceInput = {
  invoiceNumber?: Maybe<OrderTypes>;
  createdAt?: Maybe<OrderTypes>;
  dueDate?: Maybe<OrderTypes>;
  status?: Maybe<OrderTypes>;
  originalAmount?: Maybe<OrderTypes>;
  requestedAmount?: Maybe<OrderTypes>;
};

export type OrderLeadsArchiveInput = {
  name?: Maybe<OrderTypes>;
  updatedAt?: Maybe<OrderTypes>;
  hasContact?: Maybe<OrderTypes>;
  contactName?: Maybe<OrderTypes>;
};

export type OrderLeadsInput = {
  name?: Maybe<OrderTypes>;
  updatedAt?: Maybe<OrderTypes>;
  hasContact?: Maybe<OrderTypes>;
  contactName?: Maybe<OrderTypes>;
};

export type OrderLedgerActivityInput = {
  lastActivity?: Maybe<OrderTypes>;
  balance?: Maybe<OrderTypes>;
  contactName?: Maybe<OrderTypes>;
  matterName?: Maybe<OrderTypes>;
};

export type OrderMatterInput = {
  matterNumber?: Maybe<OrderTypes>;
  dateOpened?: Maybe<OrderTypes>;
  trustBalance?: Maybe<OrderTypes>;
};

export type OrderOtoInput = {
  date?: Maybe<OrderTypes>;
  amount?: Maybe<OrderTypes>;
};

export type OrderPaymentInput = {
  invoiceNumber?: Maybe<OrderTypes>;
  createdAt?: Maybe<OrderTypes>;
  paymentDate?: Maybe<OrderTypes>;
  amount?: Maybe<OrderTypes>;
};

export type OrderReconciliationInput = {
  statementDate?: Maybe<OrderTypes>;
  status?: Maybe<OrderTypes>;
  statementBalance?: Maybe<OrderTypes>;
};

export type OrderRevenueStatInput = {
  name?: Maybe<OrderTypes>;
  balanceDue?: Maybe<OrderTypes>;
  paidAmount?: Maybe<OrderTypes>;
  revenueAbsolute?: Maybe<OrderTypes>;
  revenuePercent?: Maybe<OrderTypes>;
};

export type OrderSubExpenseCategoryInput = {
  forceTopIds?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<OrderTypes>;
  status?: Maybe<OrderTypes>;
  costType?: Maybe<OrderTypes>;
};

export type OrderSubExpenseInput = {
  billedBy?: Maybe<OrderTypes>;
  status?: Maybe<OrderTypes>;
  date?: Maybe<OrderTypes>;
  costType?: Maybe<SubExpenseCategoryCostTypes>;
  contact?: Maybe<OrderTypes>;
  matter?: Maybe<OrderTypes>;
  category?: Maybe<OrderTypes>;
};

export type OrderThirdPartyPayeeInput = {
  forceTopIds?: Maybe<Array<Scalars['ID']>>;
  payeeName?: Maybe<OrderTypes>;
  companyName?: Maybe<OrderTypes>;
  email?: Maybe<OrderTypes>;
  status?: Maybe<OrderTypes>;
  phoneNumber?: Maybe<OrderTypes>;
};

export type OrderTimeEntryInput = {
  billedBy?: Maybe<OrderTypes>;
  contact?: Maybe<OrderTypes>;
  matter?: Maybe<OrderTypes>;
  status?: Maybe<OrderTypes>;
  amount?: Maybe<OrderTypes>;
  date?: Maybe<OrderTypes>;
  activity?: Maybe<OrderTypes>;
};

export type OrderTimerActivityInput = {
  forceTopIds?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<OrderTypes>;
  status?: Maybe<OrderTypes>;
};

export type OrderTrustTransactionInput = {
  date?: Maybe<OrderTypes>;
};

/** Possible order types */
export enum OrderTypes {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type OutlookCalendarDataType = {
  __typename: 'OutlookCalendarDataType';
  calendarName: Scalars['String'];
  calendarId: Scalars['String'];
};

export type OwnerInfoInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  ssn: Scalars['String'];
  dateOfBirth: Scalars['Date'];
  address: AddressInput;
};

export type OwnerInfoType = {
  __typename: 'OwnerInfoType';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  ssn: Scalars['String'];
  dateOfBirth: Scalars['Date'];
  address: AddressType;
};

export type PaginatedContactList = {
  __typename: 'PaginatedContactList';
  items: Array<ContactType>;
  total: Scalars['Float'];
};

export type PaginatedContactRevenueStatList = {
  __typename: 'PaginatedContactRevenueStatList';
  items: Array<ContactRevenueType>;
  total: Scalars['Int'];
};

export type PaginatedContactStatList = {
  __typename: 'PaginatedContactStatList';
  items: Array<ContactStatType>;
  total: Scalars['Int'];
};

export type PaginatedContactWithMatterListType = {
  __typename: 'PaginatedContactWithMatterListType';
  items: Array<StrippedContactWithMattersType>;
  total: Scalars['Float'];
};

export type PaginatedDepositListType = {
  __typename: 'PaginatedDepositListType';
  items: Array<DepositType>;
  total: Scalars['Float'];
};

export type PaginatedDisbursementListType = {
  __typename: 'PaginatedDisbursementListType';
  items: Array<DisbursementType>;
  total: Scalars['Float'];
};

export type PaginatedEntitySyncStatusType = {
  __typename: 'PaginatedEntitySyncStatusType';
  items: Array<QboEntitySyncStatusType>;
  total: Scalars['Int'];
};

export type PaginatedExpenseResponseType = {
  __typename: 'PaginatedExpenseResponseType';
  items: Array<ExpenseType>;
  total: Scalars['Int'];
};

export type PaginatedFileFolderListType = {
  __typename: 'PaginatedFileFolderListType';
  items: Array<FileStorageItemType>;
  total: Scalars['Float'];
};

export type PaginatedFileListType = {
  __typename: 'PaginatedFileListType';
  items: Array<S3FileType>;
  total: Scalars['Float'];
};

export type PaginatedFlatFeeListType = {
  __typename: 'PaginatedFlatFeeListType';
  items: Array<FlatFeeType>;
  total: Scalars['Int'];
};

export type PaginatedInboxRecordsType = {
  __typename: 'PaginatedInboxRecordsType';
  items: Array<InboxRecordsType>;
  total: Scalars['Int'];
};

export type PaginatedInvoiceList = {
  __typename: 'PaginatedInvoiceList';
  items: Array<InvoiceType>;
  total: Scalars['Int'];
};

export type PaginatedLeadArchiveReasonsType = {
  __typename: 'PaginatedLeadArchiveReasonsType';
  total: Scalars['Int'];
  items: Array<LeadArchiveReason>;
};

export type PaginatedLeadsType = {
  __typename: 'PaginatedLeadsType';
  items: Array<LeadType>;
  total: Scalars['Int'];
};

export type PaginatedLedgerActivityListType = {
  __typename: 'PaginatedLedgerActivityListType';
  items: Array<LedgerActivityType>;
  total: Scalars['Float'];
};

export type PaginatedMatterList = {
  __typename: 'PaginatedMatterList';
  items: Array<MatterType>;
  total: Scalars['Int'];
};

export type PaginatedPaymentsList = {
  __typename: 'PaginatedPaymentsList';
  items: Array<PaymentType>;
  total: Scalars['Float'];
};

export type PaginatedReconciliationListType = {
  __typename: 'PaginatedReconciliationListType';
  items: Array<ReconciliationType>;
  total: Scalars['Float'];
};

export type PaginatedSignatureListType = {
  __typename: 'PaginatedSignatureListType';
  items: Array<SignatureType>;
  total: Scalars['Int'];
};

export type PaginatedSignatureTemplateList = {
  __typename: 'PaginatedSignatureTemplateList';
  items: Array<SignatureTemplateType>;
  total: Scalars['Int'];
};

export type PaginatedSubExpenseCategoriesList = {
  __typename: 'PaginatedSubExpenseCategoriesList';
  items: Array<SubExpenseCategoryType>;
  total: Scalars['Int'];
};

export type PaginatedSubExpenseListType = {
  __typename: 'PaginatedSubExpenseListType';
  items: Array<SubExpenseType>;
  total: Scalars['Int'];
};

export type PaginatedTeamMembersListType = {
  __typename: 'PaginatedTeamMembersListType';
  items: Array<UserType>;
  total: Scalars['Int'];
};

export type PaginatedThirdPartyPayeesResponseType = {
  __typename: 'PaginatedThirdPartyPayeesResponseType';
  items: Array<ThirdPartyPayeeType>;
  total: Scalars['Int'];
};

export type PaginatedTimeEntryList = {
  __typename: 'PaginatedTimeEntryList';
  items: Array<TimeEntryType>;
  total: Scalars['Int'];
};

export type PaginatedTimerActivityList = {
  __typename: 'PaginatedTimerActivityList';
  items: Array<TimerActivityType>;
  total: Scalars['Int'];
};

export type PaginatedTrustTransactionsListType = {
  __typename: 'PaginatedTrustTransactionsListType';
  items: Array<TrustTransactionType>;
  total: Scalars['Float'];
};

export type PayBillInput = {
  amount?: Maybe<Scalars['Float']>;
  paymentMethod: PaymentMethodsTypes;
  invoicesIds: Array<Scalars['String']>;
};

export type PaymentIntentType = {
  __typename: 'PaymentIntentType';
  clientSecret: Scalars['String'];
  stripeAccountId: Scalars['String'];
};

/** Types of payment methods */
export enum PaymentMethodsTypes {
  CASH = 'CASH',
  CHECK = 'CHECK',
  CC = 'CC',
  ACH = 'ACH',
  WIRE_TRANSFER = 'WIRE_TRANSFER',
  TRANSFER_FROM_TRUST = 'TRANSFER_FROM_TRUST',
  OTHER = 'OTHER',
  CREDIT_ISSUED = 'CREDIT_ISSUED',
}

export type PaymentSourceType = {
  __typename: 'PaymentSourceType';
  id: Scalars['ID'];
  truncatedPAN?: Maybe<Scalars['String']>;
  truncatedAccountNumber?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  type: PaymentMethodsTypes;
};

export type PaymentStatFilterInput = {
  search?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  contactId?: Maybe<Scalars['ID']>;
};

/** Payment statuses */
export enum PaymentStatuses {
  CLEARED = 'CLEARED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

export type PaymentSubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
  contactId?: Maybe<Scalars['ID']>;
  paymentMethod?: Maybe<PaymentMethodsTypes>;
  statuses?: Maybe<Array<PaymentStatuses>>;
  types?: Maybe<Array<TransactionTypes>>;
  bankAccountClass?: Maybe<BankAccountClasses>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type PaymentType = {
  __typename: 'PaymentType';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  refundAmount: Scalars['Float'];
  parentAmount?: Maybe<Scalars['Float']>;
  paymentDate: Scalars['Date'];
  contact: ContactType;
  paymentMethod: PaymentMethodsTypes;
  source?: Maybe<PaymentSourceType>;
  bankAccount: BankAccountType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  invoices?: Maybe<Array<InvoiceType>>;
  parent?: Maybe<PaymentType>;
  refundPayment?: Maybe<PaymentType>;
  refundNumber?: Maybe<Scalars['String']>;
  status: PaymentStatuses;
  type: TransactionTypes;
  memoNumber?: Maybe<Scalars['String']>;
  memoInvoice?: Maybe<InvoiceType>;
  memoText?: Maybe<Scalars['String']>;
  memoDetalization?: Maybe<CreditMemoDetalizationType>;
  truncatedPAN?: Maybe<Scalars['String']>;
  internalMemo?: Maybe<Scalars['String']>;
  qboSyncState?: Maybe<QboEntitySyncStatus>;
};

export type PaymentsStatType = {
  __typename: 'PaymentsStatType';
  amount: Scalars['Float'];
  totalTransactions: Scalars['Int'];
};

/** Possible period types */
export enum PeriodTypes {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export enum PersonRelationship {
  OWNER = 'OWNER',
  EXECUTIVE = 'EXECUTIVE',
}

export type PhoneNumberInput = {
  number: Scalars['String'];
  type?: Maybe<PhoneTypes>;
  isPrimary?: Maybe<Scalars['Boolean']>;
};

export type PhoneNumberType = {
  __typename: 'PhoneNumberType';
  number: Scalars['String'];
  type?: Maybe<PhoneTypes>;
  isPrimary?: Maybe<Scalars['Boolean']>;
};

/** Possible phone types */
export enum PhoneTypes {
  HOME = 'HOME',
  MOBILE = 'MOBILE',
  OFFICE = 'OFFICE',
  FAX = 'FAX',
}

export type PictureInput = {
  original?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};

export type PictureType = {
  __typename: 'PictureType';
  original?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};

export type PlainTextType = {
  __typename: 'PlainTextType';
  value: Scalars['String'];
};

export type PlatformVersionInput = {
  platform: Platforms;
  version: Scalars['String'];
};

/** Platform version requirement */
export enum PlatformVersionRequirement {
  NO_UPDATES = 'NO_UPDATES',
  UPDATE_RECOMMEND = 'UPDATE_RECOMMEND',
  UPDATE_REQUIRED = 'UPDATE_REQUIRED',
}

/** Available platforms */
export enum Platforms {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

export type PlatformsVersionType = {
  __typename: 'PlatformsVersionType';
  requirement: PlatformVersionRequirement;
};

/** Principal titles  */
export enum PrincipalTitles {
  CEO = 'CEO',
  COO = 'COO',
  CFO = 'CFO',
  CTO = 'CTO',
  DIRECTOR = 'Director',
  SUPERVISOR = 'Supervisor',
  ANALYST = 'Analyst',
  MANAGER = 'Manager',
  OTHER = 'Other',
  OWNER = 'Owner',
}

export type PrincipalType = {
  __typename: 'PrincipalType';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  ownershipPercent: Scalars['Int'];
  title: PrincipalTitles;
  dateOfBirth: Scalars['Date'];
  address: AddressType;
};

export type PrintChecksInput = {
  disbursementsIds: Array<Scalars['ID']>;
  checkStartNumber?: Maybe<Scalars['Int']>;
};

/** Possible printing checks modes */
export enum PrintingChecksModes {
  QBO = 'QBO',
  STANDARD = 'STANDARD',
  VOUCHER = 'VOUCHER',
}

export type PrintingChecksSettingsType = {
  __typename: 'PrintingChecksSettingsType';
  mode?: Maybe<PrintingChecksModes>;
  offset?: Maybe<Array<Scalars['Float']>>;
};

export type PrintingDepostSlipSettingsType = {
  __typename: 'PrintingDepostSlipSettingsType';
  offset?: Maybe<Array<Scalars['Float']>>;
};

export type PrintingOffsetInput = {
  offset?: Maybe<Array<Scalars['Float']>>;
};

/** Types of public analytic events */
export enum PublicAnalyticEvent {
  SIGN_UP_FORM_INTERACTION = 'SIGN_UP_FORM_INTERACTION',
  SIGN_UP_FORM_CLOSED = 'SIGN_UP_FORM_CLOSED',
  SIGN_UP_FORM_TIMER = 'SIGN_UP_FORM_TIMER',
  SIGN_UP_FORM_VISITED = 'SIGN_UP_FORM_VISITED',
}

export type PublicContactType = {
  __typename: 'PublicContactType';
  phoneNumbers?: Maybe<Array<PhoneNumberType>>;
  addresses?: Maybe<Array<AddressType>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type PublicFirmType = {
  __typename: 'PublicFirmType';
  name: Scalars['String'];
  logo?: Maybe<PictureType>;
  website?: Maybe<Scalars['String']>;
  address?: Maybe<AddressType>;
  phoneNumber?: Maybe<PhoneNumberType>;
};

export type QboAccountType = {
  __typename: 'QboAccountType';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: QboAccountTypes;
};

/** Possible QBO account types */
export enum QboAccountTypes {
  OTHER_CURRENT_LIABILITIES = 'OTHER_CURRENT_LIABILITIES',
  BANK = 'BANK',
  OTHER_CURRENT_ASSETS = 'OTHER_CURRENT_ASSETS',
  CREDIT_CARD = 'CREDIT_CARD',
  FIXED_ASSET = 'FIXED_ASSET',
  OTHER_ASSET = 'OTHER_ASSET',
  EQUITY = 'EQUITY',
  INCOME = 'INCOME',
  EXPENSE = 'EXPENSE',
  OTHER_INCOME = 'OTHER_INCOME',
  OTHER_EXPENSES = 'OTHER_EXPENSES',
}

export type QboBulkResyncEntitiesInput = {
  entityType: QboEntityTypes;
  ids: Array<Scalars['ID']>;
};

export type QboBulkResyncInput = {
  entities: Array<QboBulkResyncEntitiesInput>;
};

export type QboEntitiesInitialSyncState = {
  __typename: 'QboEntitiesInitialSyncState';
  finished: Scalars['Boolean'];
};

export enum QboEntitySyncStatus {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

export type QboEntitySyncStatusType = {
  __typename: 'QboEntitySyncStatusType';
  id: Scalars['ID'];
  action: QboSyncHistoryAction;
  entityType: QboEntityTypes;
  entityId: Scalars['String'];
  isFail: Scalars['Boolean'];
  idOrName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
};

/** CUSTOMER equals CONTACT in our system */
export enum QboEntityTypes {
  INVOICE = 'INVOICE',
  CUSTOMER = 'CUSTOMER',
  FIRM = 'FIRM',
  CREDIT_MEMO = 'CREDIT_MEMO',
  PAYMENT = 'PAYMENT',
  PAYMENT_REFUND = 'PAYMENT_REFUND',
  DEPOSIT = 'DEPOSIT',
  OTO = 'OTO',
  DISBURSEMENT = 'DISBURSEMENT',
}

export type QboIntegrationDetailsType = {
  __typename: 'QboIntegrationDetailsType';
  connected: Scalars['Boolean'];
  connectionError?: Maybe<CommonAppErrors>;
  syncStatus?: Maybe<QboSyncStatus>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  syncQty?: Maybe<Scalars['Float']>;
  errorQty?: Maybe<Scalars['Float']>;
  actualCompanyName?: Maybe<Scalars['String']>;
  expectedCompanyName?: Maybe<Scalars['String']>;
};

export type QboIntegrationDisabledType = {
  __typename: 'QboIntegrationDisabledType';
  firmId: Scalars['ID'];
};

export type QboIntegrationEnabledType = {
  __typename: 'QboIntegrationEnabledType';
  success: Scalars['Boolean'];
  firmId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type QboMappingsResponseType = {
  __typename: 'QboMappingsResponseType';
  mappings?: Maybe<QboMappingsType>;
};

export type QboMappingsType = {
  __typename: 'QboMappingsType';
  operatingAccount: Scalars['String'];
  trustAccount?: Maybe<Scalars['String']>;
  trustDepositAccount?: Maybe<Scalars['String']>;
  timeEntryItem?: Maybe<Scalars['String']>;
  flatFeeItem?: Maybe<Scalars['String']>;
  expenseItem?: Maybe<Scalars['String']>;
  commonInvoiceItem?: Maybe<Scalars['String']>;
  trustDepositItem: Scalars['String'];
  refundItem: Scalars['String'];
  creditCardFeesAccount?: Maybe<Scalars['String']>;
};

export type QboResourcesDetailsType = {
  __typename: 'QboResourcesDetailsType';
  accounts: Array<QboAccountType>;
  serviceItems: Array<QboServiceItemType>;
  defaultDiscountAccount?: Maybe<Scalars['ID']>;
};

export type QboSaveMappingsInput = {
  operatingAccount: Scalars['String'];
  trustAccount?: Maybe<Scalars['String']>;
  trustDepositAccount?: Maybe<Scalars['String']>;
  timeEntryItem?: Maybe<Scalars['String']>;
  flatFeeItem?: Maybe<Scalars['String']>;
  expenseItem?: Maybe<Scalars['String']>;
  commonInvoiceItem?: Maybe<Scalars['String']>;
  trustDepositItem: Scalars['String'];
  refundItem: Scalars['String'];
  creditCardFeesAccount?: Maybe<Scalars['String']>;
};

export type QboServiceItemType = {
  __typename: 'QboServiceItemType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type QboSyncFinishedType = {
  __typename: 'QboSyncFinishedType';
  success: Scalars['Boolean'];
  firmId: Scalars['ID'];
  syncQty: Scalars['Float'];
  errorQty: Scalars['Float'];
};

export enum QboSyncHistoryAction {
  FIRM_AUTH_TOKEN_MISSED = 'FIRM_AUTH_TOKEN_MISSED',
  FIRM_AUTH_REFRESH_FAILED = 'FIRM_AUTH_REFRESH_FAILED',
  FIRM_MONTHLY_FEES_CREATED = 'FIRM_MONTHLY_FEES_CREATED',
  FIRM_MONTHLY_FEES_CREATE_FAILED = 'FIRM_MONTHLY_FEES_CREATE_FAILED',
  FIRM_MONTHLY_FEES_MAPPINGS_MISSED = 'FIRM_MONTHLY_FEES_MAPPINGS_MISSED',
  INVOICE_CREATED = 'INVOICE_CREATED',
  INVOICE_UPDATED = 'INVOICE_UPDATED',
  INVOICE_UPDATE_FAILED = 'INVOICE_UPDATE_FAILED',
  INVOICE_CREATE_FAILED = 'INVOICE_CREATE_FAILED',
  INVOICE_NUMBER_DUPLICATED = 'INVOICE_NUMBER_DUPLICATED',
  INVOICE_CUSTOMER_NOT_EXPORTED = 'INVOICE_CUSTOMER_NOT_EXPORTED',
  INVOICE_MAPPINGS_MISSED = 'INVOICE_MAPPINGS_MISSED',
  INVOICE_NOT_EXPORTED = 'INVOICE_NOT_EXPORTED',
  INVOICE_VOIDED = 'INVOICE_VOIDED',
  INVOICE_VOID_FAILED = 'INVOICE_VOID_FAILED',
  CUSTOMER_CREATED = 'CUSTOMER_CREATED',
  CUSTOMER_CREATE_FAILED = 'CUSTOMER_CREATE_FAILED',
  CUSTOMER_UPDATED = 'CUSTOMER_UPDATED',
  CUSTOMER_UPDATE_FAILED = 'CUSTOMER_UPDATE_FAILED',
  CUSTOMER_NOT_EXPORTED = 'CUSTOMER_NOT_EXPORTED',
  CUSTOMER_NAME_DUPLICATED = 'CUSTOMER_NAME_DUPLICATED',
  CREDIT_MEMO_MAPPINGS_MISSED = 'CREDIT_MEMO_MAPPINGS_MISSED',
  CREDIT_MEMO_INVOICE_NOT_EXPORTED = 'CREDIT_MEMO_INVOICE_NOT_EXPORTED',
  CREDIT_MEMO_CREATE_FAILED = 'CREDIT_MEMO_CREATE_FAILED',
  CREDIT_MEMO_NUMBER_DUPLICATED = 'CREDIT_MEMO_NUMBER_DUPLICATED',
  CREDIT_MEMO_PAYMENT_CREATE_FAILED = 'CREDIT_MEMO_PAYMENT_CREATE_FAILED',
  CREDIT_MEMO_CREATED = 'CREDIT_MEMO_CREATED',
  PAYMENT_CUSTOMER_NOT_EXPORTED = 'PAYMENT_CUSTOMER_NOT_EXPORTED',
  PAYMENT_NO_INVOICES_FOUND = 'PAYMENT_NO_INVOICES_FOUND',
  PAYMENT_CREATED = 'PAYMENT_CREATED',
  PAYMENT_CREATE_FAILED = 'PAYMENT_CREATE_FAILED',
  PAYMENT_REFUND_NO_MAPPINGS_FOUND = 'PAYMENT_REFUND_NO_MAPPINGS_FOUND',
  PAYMENT_REFUND_CUSTOMER_NOT_EXPORTED = 'PAYMENT_REFUND_CUSTOMER_NOT_EXPORTED',
  PAYMENT_REFUND_NO_INVOICES_FOUND = 'PAYMENT_REFUND_NO_INVOICES_FOUND',
  PAYMENT_REFUND_CREATED = 'PAYMENT_REFUND_CREATED',
  PAYMENT_REFUND_CREATE_FAILED = 'PAYMENT_REFUND_CREATE_FAILED',
  DEPOSIT_NO_MAPPINGS_FOUND = 'DEPOSIT_NO_MAPPINGS_FOUND',
  DEPOSIT_VALIDATION_ERROR = 'DEPOSIT_VALIDATION_ERROR',
  DEPOSIT_CREATED = 'DEPOSIT_CREATED',
  DEPOSIT_CREATE_FAILED = 'DEPOSIT_CREATE_FAILED',
  OTO_NO_MAPPINGS_FOUND = 'OTO_NO_MAPPINGS_FOUND',
  OTO_CUSTOMER_NOT_EXPORTED = 'OTO_CUSTOMER_NOT_EXPORTED',
  OTO_CREATED = 'OTO_CREATED',
  OTO_CREATE_FAILED = 'OTO_CREATE_FAILED',
  OTO_VALIDATION_ERROR = 'OTO_VALIDATION_ERROR',
  DISBURSEMENT_CLEARED = 'DISBURSEMENT_CLEARED',
  DISBURSEMENT_NO_MAPPINGS_FOUND = 'DISBURSEMENT_NO_MAPPINGS_FOUND',
  DISBURSEMENT_CLEAR_FAILED = 'DISBURSEMENT_CLEAR_FAILED',
  DISBURSEMENT_CLEAR_VALIDATION_ERROR = 'DISBURSEMENT_CLEAR_VALIDATION_ERROR',
  DISBURSEMENT_VALIDATION_ERROR = 'DISBURSEMENT_VALIDATION_ERROR',
  DISBURSEMENT_CREATE_FAILED = 'DISBURSEMENT_CREATE_FAILED',
  DISBURSEMENT_CREATED = 'DISBURSEMENT_CREATED',
  DISBURSEMENT_NOT_EXPORTED = 'DISBURSEMENT_NOT_EXPORTED',
  DISBURSEMENT_PRINT_STATUS_CHANGE_FAILED = 'DISBURSEMENT_PRINT_STATUS_CHANGE_FAILED',
  DISBURSEMENT_PRINTED = 'DISBURSEMENT_PRINTED',
  DISBURSEMENT_NEED_TO_PRINT = 'DISBURSEMENT_NEED_TO_PRINT',
  DISBURSEMENT_VOIDED = 'DISBURSEMENT_VOIDED',
}

/** Possible QBO sync statuses */
export enum QboSyncStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED',
}

export type Query = {
  __typename: 'Query';
  /**
   * Action: READ
   * Resource: SIGNATURE
   */
  downloadSignatureDocumentWithAuditTrail: SignatureUrlType;
  /**
   * Action: READ
   * Resource: SIGNATURE
   */
  downloadSignatureDocumentOnly: SignatureUrlType;
  /**
   * Action: READ
   * Resource: SIGNATURE
   */
  downloadSignatureAuditTrail: SignatureUrlType;
  /**
   * Action: READ
   * Resource: SIGNATURE
   */
  getSignature: SignatureType;
  /**
   * Action: LIST
   * Resource: SIGNATURE
   */
  listSignatures: PaginatedSignatureListType;
  /**
   * Action: READ
   * Resource: SIGNATURE_TEMPLATE
   */
  getSignatureTemplate: SignatureTemplateType;
  /**
   * Action: LIST
   * Resource: SIGNATURE_TEMPLATE
   */
  listSignatureTemplates: PaginatedSignatureTemplateList;
  getOAuthExternalServiceInfo?: Maybe<OAuthExternalServiceType>;
  /**
   * Action: READ
   * Resource: PUBLIC_API
   */
  getIntegrations?: Maybe<Array<IntegrationType>>;
  /**
   * Action: LIST
   * Resource: CONTACT
   */
  listContacts: PaginatedContactList;
  /**
   * Action: LIST
   * Resource: CONTACT
   */
  listContactsWithMatters: PaginatedContactWithMatterListType;
  /**
   * Action: READ
   * Resource: CONTACT
   */
  getContact: ContactType;
  /**
   * Action: READ
   * Resource: CONTACT
   */
  getContactQboSyncStatus?: Maybe<QboEntitySyncStatusType>;
  /**
   * Action: LIST
   * Resource: DEPOSIT
   */
  listDeposits: PaginatedDepositListType;
  /**
   * Action: READ
   * Resource: DEPOSIT
   */
  getDeposit: DepositType;
  /**
   * Action: READ
   * Resource: RECONCILIATION
   */
  listPrintableDeposits: Array<DepositType>;
  /**
   * Action: READ
   * Resource: DEPOSIT
   */
  getDepositSlipPDF: FileUrlWithBase64ResponseType;
  /**
   * Action: READ
   * Resource: DEPOSIT
   */
  getSampleDepositSlipPDF: FileBase64ResponseType;
  /**
   * Action: READ
   * Resource: DEPOSIT
   */
  getDepositPDF: FileBase64ResponseType;
  /**
   * Action: READ
   * Resource: DEPOSIT
   */
  getDepositQboSyncStatus?: Maybe<QboEntitySyncStatusType>;
  /**
   * Action: LIST
   * Resource: DISBURSEMENT
   */
  listOtos: PaginatedDisbursementListType;
  /**
   * Action: LIST
   * Resource: DISBURSEMENT
   */
  listDisbursements: PaginatedDisbursementListType;
  /**
   * Action: LIST
   * Resource: DISBURSEMENT
   */
  listNotPrintedDisbursements: PaginatedDisbursementListType;
  /**
   * Action: READ
   * Resource: DISBURSEMENT
   */
  getDisbursement: DisbursementType;
  /**
   * Action: READ
   * Resource: DISBURSEMENT
   */
  getLastCheckNumber: LastCheckNumberType;
  /**
   * Action: READ
   * Resource: DISBURSEMENT
   */
  getPdfCheck: FileUrlWithBase64ResponseType;
  /**
   * Action: READ
   * Resource: CHECK
   */
  getSampleCheckPDF: FileBase64ResponseType;
  /**
   * Action: READ
   * Resource: DISBURSEMENT
   */
  getDisbursementPDF: FileBase64ResponseType;
  /**
   * Action: READ
   * Resource: DISBURSEMENT
   */
  getDisbursementQboSyncStatus?: Maybe<QboEntitySyncStatusType>;
  /**
   * Action: LIST
   * Resource: TRANSACTION_LEDGER
   */
  listTrustTransactions: PaginatedTrustTransactionsListType;
  /**
   * Action: READ
   * Resource: TRUST_ACCOUNTING_STATISTICS
   */
  getTrustFinancialInfo: TrustFinancialInfoType;
  /**
   * Action: READ
   * Resource: TRUST_ACCOUNTING_STATISTICS
   */
  getTrustBalance: TrustBalanceType;
  /**
   * Action: READ
   * Resource: TRUST_ACCOUNTING_STATISTICS
   */
  getContactMattersTrustSummary: ContactMattersTrustSummaryType;
  /**
   * Action: LIST
   * Resource: TRANSACTION_LEDGER
   */
  listLedgerActivities: PaginatedLedgerActivityListType;
  /**
   * Action: READ
   * Resource: RECONCILIATION
   */
  calculateReconciliation: ReconciliationResultType;
  /**
   * Action: READ
   * Resource: RECONCILIATION
   */
  listReconciliationTransactions: Array<TrustTransactionType>;
  /**
   * Action: LIST
   * Resource: RECONCILIATION
   */
  listReconciliations: PaginatedReconciliationListType;
  /**
   * Action: READ
   * Resource: RECONCILIATION
   */
  getReconciliation: ReconciliationType;
  /**
   * Action: READ
   * Resource: RECONCILIATION
   */
  getReconciliationDetailedPDF: FileUrlResponseType;
  /**
   * Action: READ
   * Resource: RECONCILIATION
   */
  getReconciliation3WayPDF: FileUrlResponseType;
  /**
   * Action: READ
   * Resource: TRUST_TRANSFER
   */
  getTrustTransfer: TrustTransferType;
  /**
   * Action: READ
   * Resource: TRUST_TRANSFER
   */
  getTrustTransferPDF: FileBase64ResponseType;
  /**
   * Action: LIST
   * Resource: THIRD_PARTY_PAYEES
   */
  listThirdPartyPayees: PaginatedThirdPartyPayeesResponseType;
  /**
   * Action: READ
   * Resource: THIRD_PARTY_PAYEES
   */
  getThirdPartyPayee: ThirdPartyPayeeType;
  /**
   * Action: READ
   * Resource: ACCOUNT_STATEMENT
   */
  getAccountStatements: AccountStatementType;
  /**
   * Action: READ
   * Resource: BANK_ACCOUNT
   */
  getBankAccount: BankAccountType;
  /**
   * Action: LIST
   * Resource: BANK_ACCOUNT
   */
  listBankAccounts: Array<BankAccountType>;
  /**
   * Action: READ
   * Resource: BANK_ACCOUNT
   */
  getTrustFeesAccountIntent: TrustFeesAccountConnectType;
  /**
   * Action: READ
   * Resource: PAYMENT
   */
  getPayment: PaymentType;
  /**
   * Action: LIST
   * Resource: PAYMENT
   */
  listPayments: PaginatedPaymentsList;
  /**
   * Action: READ
   * Resource: PAYMENT
   */
  getReceiptPDF: FileUrlResponseType;
  /**
   * Action: READ
   * Resource: REFUND_PAYMENT
   */
  getRefundReceiptPDF: FileBase64ResponseType;
  /**
   * Action: READ
   * Resource: PAYMENT
   */
  getPaymentQboSyncStatus?: Maybe<QboEntitySyncStatusType>;
  /**
   * Action: READ
   * Resource: REFUND_PAYMENT
   */
  getRefundPaymentQboSyncStatus?: Maybe<QboEntitySyncStatusType>;
  /**
   * Action: READ
   * Resource: BILL
   */
  getBill: BillType;
  /**
   * Action: READ
   * Resource: INVOICE
   */
  getInvoice: InvoiceType;
  /**
   * Action: LIST
   * Resource: INVOICE
   */
  listInvoices: PaginatedInvoiceList;
  /**
   * Action: CREATE
   * Resource: INVOICE
   */
  calculateScheduledPayments: Array<ScheduledPaymentType>;
  /**
   * Action: READ
   * Resource: INVOICE
   */
  getInvoicePDF: FileUrlResponseType;
  /**
   * Action: READ
   * Resource: CREDIT_MEMO
   */
  getCreditMemoPDF: FileBase64ResponseType;
  /**
   * Action: READ
   * Resource: INVOICE
   */
  getInvoiceQboSyncStatus?: Maybe<QboEntitySyncStatusType>;
  /**
   * Action: READ
   * Resource: CREDIT_MEMO
   */
  getCreditMemoQboSyncStatus?: Maybe<QboEntitySyncStatusType>;
  /**
   * Action: READ
   * Resource: CALENDAR
   */
  getCalendarSyncSettings: CalendarSyncSettingsType;
  /**
   * Action: LIST
   * Resource: CALENDAR
   */
  listGoogleCalendars: Array<GoogleCalendarDataType>;
  /**
   * Action: LIST
   * Resource: CALENDAR
   */
  listOutlookCalendars: Array<OutlookCalendarDataType>;
  /**
   * Action: LIST
   * Resource: CALENDAR
   */
  fetchCalendarItems: FetchCalendarItemsType;
  /**
   * Action: READ
   * Resource: CALENDAR
   */
  getCalendarItem: CalendarItemType;
  /**
   * Action: READ
   * Resource: GOOGLE_PERSONAL_INTEGRATION
   */
  getGoogleAuthUrl: GoogleAuthUrlType;
  /**
   * Action: READ
   * Resource: GOOGLE_PERSONAL_INTEGRATION
   */
  getGooglePersonalIntegrationStatus: GooglePersonalIntegrationStatusType;
  /**
   * Action: READ
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  getFirmGoogleAuthUrl: GoogleAuthUrlType;
  /**
   * Action: READ
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  getGoogleFirmIntegrationStatus: GooglePersonalIntegrationStatusType;
  /**
   * Action: LIST
   * Resource: LEAD_STATUSES
   */
  listLeadStatuses: Array<LeadStatusType>;
  /**
   * Action: READ
   * Resource: LEAD
   */
  getLead: LeadType;
  /**
   * Action: LIST
   * Resource: LEAD
   */
  listLeads: PaginatedLeadsType;
  /**
   * Action: LIST
   * Resource: LEAD_ARCHIVE
   */
  listLeadsArchive: PaginatedLeadsType;
  /**
   * Action: READ
   * Resource: LEAD
   */
  getPredefinedReasons: Array<LeadArchiveReason>;
  /**
   * Action: READ
   * Resource: LEAD
   */
  listArchiveReasons: PaginatedLeadArchiveReasonsType;
  /**
   * Action: LIST
   * Resource: CONTACT_FILES
   */
  listContactFiles: PaginatedFileListType;
  /**
   * Action: LIST
   * Resource: MATTER_FILES
   */
  listMatterFiles: PaginatedFileListType;
  /**
   * Action: LIST
   * Resource: INVOICE_FILES
   */
  listInvoiceFiles: PaginatedFileListType;
  /**
   * Action: LIST
   * Resource: PAYMENT_FILES
   */
  listPaymentFiles: PaginatedFileListType;
  /**
   * Action: LIST
   * Resource: SIGNATURE
   */
  listSignatureFiles: PaginatedFileListType;
  /**
   * Action: LIST
   * Resource: SIGNATURE_TEMPLATE
   */
  listSignatureTemplateFiles: PaginatedFileListType;
  /**
   * Action: LIST
   * Resource: EXPENSE_FILES
   */
  listExpenseFiles: PaginatedFileListType;
  /**
   * Action: LIST
   * Resource: VOID_INVOICE_FILES
   */
  listVoidInvoiceFiles: PaginatedFileListType;
  /**
   * Action: LIST
   * Resource: RECONCILIATION_FILES
   */
  listReconciliationFiles: PaginatedFileListType;
  /**
   * Action: LIST
   * Resource: TRUST_TRANSFER_FILES
   */
  listTrustTransferFiles: PaginatedFileListType;
  /**
   * Action: LIST
   * Resource: FILES
   */
  getFileAndFolderList: Array<FileStorageItemType>;
  /**
   * Action: LIST
   * Resource: FILES
   */
  getFileOrFolder?: Maybe<FileStorageItemType>;
  /**
   * Action: LIST
   * Resource: FILES
   */
  getFileAndFolderPaginatedList: PaginatedFileFolderListType;
  /**
   * Action: READ
   * Resource: FILES
   */
  getPath: Array<FolderPathType>;
  /**
   * Action: READ
   * Resource: FILES
   */
  getFolder?: Maybe<FileStorageItemType>;
  /**
   * Action: LIST
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  getDrivesFromGoogle: GetDrivesResponseType;
  /**
   * Action: LIST
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  getFoldersFromGoogle: GetDriveFoldersResponseType;
  /**
   * Action: LIST
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  getGoogleSyncOptions?: Maybe<FileStorageSyncOptionType>;
  /**
   * Action: READ
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  googleCheckFolderName?: Maybe<CheckFolderNameExistingType>;
  /**
   * Action: READ
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  googleCheckAccountStatus?: Maybe<SuccessResponseType>;
  /**
   * Action: LIST
   * Resource: LEAD_FILES
   */
  listLeadFiles: PaginatedFileListType;
  /**
   * Action: CREATE
   * Resource: QBO
   */
  getQboRedirectUrl: GetQboRedirectUrlResponse;
  /**
   * Action: READ
   * Resource: QBO
   */
  getQboIntegrationDetails: QboIntegrationDetailsType;
  /**
   * Action: READ
   * Resource: QBO
   */
  getQboResourcesDetails: QboResourcesDetailsType;
  /**
   * Action: READ
   * Resource: QBO
   */
  getQboMappingsPreferences: QboMappingsResponseType;
  /**
   * Action: READ
   * Resource: QBO
   */
  getQboInitialSyncState: QboEntitiesInitialSyncState;
  /**
   * Action: READ
   * Resource: QBO
   */
  listQboSyncHistory: PaginatedEntitySyncStatusType;
  /**
   * Action: READ
   * Resource: FIRM
   */
  getFirm: FirmType;
  /**
   * Action: PUBLIC_READ
   * Resource: FIRM
   */
  getPublicFirm: PublicFirmType;
  /**
   * Action: READ
   * Resource: PROFILE
   */
  getProfile: UserType;
  /**
   * Action: READ
   * Resource: PROFILE
   */
  getRoundingRules: RoundingRulesType;
  /**
   * Action: READ
   * Resource: PROFILE
   */
  getTwoFactorOption?: Maybe<TwoFactorAuthenticationOptionsType>;
  /**
   * Action: READ
   * Resource: MATTER
   */
  getMatter: MatterType;
  /**
   * Action: LIST
   * Resource: MATTER
   */
  listMatters: PaginatedMatterList;
  /**
   * Action: READ
   * Resource: MATTER
   */
  getLimitedFlatFeeMatterStat: LimitedFlatFeeMatterStat;
  /**
   * Action: LIST
   * Resource: EXPENSE
   */
  listExpenses: PaginatedExpenseResponseType;
  /**
   * Action: LIST
   * Resource: SUB_EXPENSE
   */
  listSubExpenses: PaginatedSubExpenseListType;
  /**
   * Action: READ
   * Resource: SUB_EXPENSE
   */
  getSubExpense: SubExpenseType;
  /**
   * Action: LIST
   * Resource: SUB_EXPENSE_CATEGORY
   */
  listSubExpenseCategories: PaginatedSubExpenseCategoriesList;
  /**
   * Action: READ
   * Resource: SUB_EXPENSE_CATEGORY
   */
  getSubExpenseCategory: SubExpenseCategoryType;
  /**
   * Action: LIST
   * Resource: FLAT_FEE
   */
  listFlatFees: PaginatedFlatFeeListType;
  /**
   * Action: READ
   * Resource: FLAT_FEE
   */
  getFlatFee: FlatFeeType;
  /**
   * Action: LIST
   * Resource: TIMER
   */
  listTimers: Array<TimerType>;
  /**
   * Action: READ
   * Resource: TIMER
   */
  getTimer: TimerType;
  /**
   * Action: LIST
   * Resource: TIME_ENTRY
   */
  listTimeEntries: PaginatedTimeEntryList;
  /**
   * Action: READ
   * Resource: TIME_ENTRY
   */
  getTimeEntry: TimeEntryType;
  /**
   * Action: LIST
   * Resource: TIMER_ACTIVITY
   */
  listTimerActivities: PaginatedTimerActivityList;
  /**
   * Action: READ
   * Resource: TIMER_ACTIVITY
   */
  getTimerActivity: TimerActivityType;
  /**
   * Action: READ
   * Resource: PAYMENTS_STAT
   */
  getPaymentsStat: PaymentsStatType;
  /**
   * Action: READ
   * Resource: INVOICES_STAT
   */
  getInvoicesStat: InvoicesStatType;
  /**
   * Action: READ
   * Resource: INVOICES_STAT
   */
  getContactSummaryStat: ContactPaymentStatType;
  /**
   * Action: READ
   * Resource: INVOICES_STAT
   */
  getCommonPaymentStat: CommonPaymentStatType;
  /**
   * Action: READ
   * Resource: INVOICES_STAT
   */
  getDashboardStat: DashboardStatType;
  /**
   * Action: READ
   * Resource: INVOICES_STAT
   */
  getRevenueStat: RevenueStatType;
  /**
   * Action: READ
   * Resource: EXPENSES_STAT
   */
  getExpensesStat: ExpenseStatType;
  /**
   * Action: READ
   * Resource: EXPENSES_STAT
   */
  getFlatFeeMatterStat: FlatFeeMatterStatType;
  /**
   * Action: READ
   * Resource: PRIVATE
   */
  errorExample: CommonAppErrorsType;
  /**
   * Action: READ
   * Resource: WEB_URLS
   */
  listWebUrls: WebUrlsType;
  /**
   * Action: LIST
   * Resource: TEAM_MANAGEMENT
   */
  listTeamMembers: PaginatedTeamMembersListType;
  /**
   * Action: READ
   * Resource: TEAM_MANAGEMENT
   */
  getTeamMember: UserType;
  /**
   * Action: REPORT
   * Resource: CONTACT
   */
  getContactsCSVReport: FileUrlResponseType;
  /**
   * Action: REPORT
   * Resource: PAYMENT
   */
  getPaymentsCSVReport: FileUrlResponseType;
  /**
   * Action: REPORT
   * Resource: INVOICE
   */
  getInvoicesCSVReport: FileUrlResponseType;
  getPlatformVersion: PlatformsVersionType;
  /**
   * Action: READ
   * Resource: SITE_BUILDER
   */
  getSiteInfo: SiteInfoResponseType;
  /**
   * Action: READ
   * Resource: SITE_BUILDER
   */
  isDefaultDomainFree: SuccessResponseType;
  /**
   * Action: READ
   * Resource: SITE_BUILDER
   */
  getSiteConfiguratorUrl: SiteConfiguratorUrlResponseType;
  /**
   * Action: READ
   * Resource: SITE_BUILDER
   */
  getSiteActivity: SiteActivityResponseType;
  /**
   * Action: READ
   * Resource: MICROSOFT_PERSONAL_INTEGRATION
   */
  getMicrosoftAuthUrl: MicrosoftAuthUrlType;
  /**
   * Action: READ
   * Resource: MICROSOFT_PERSONAL_INTEGRATION
   */
  getMicrosoftPersonalIntegrationStatus: MicrosoftPersonalIntegrationStatusType;
  getHealth: SuccessResponseType;
  /**
   * Action: LIST
   * Resource: LEAD_INBOX
   */
  listInboxRecords: PaginatedInboxRecordsType;
  /**
   * Action: LIST
   * Resource: LEAD_INBOX
   */
  getNotViewedInboxRecordsCount: Scalars['Int'];
  /**
   * Action: READ
   * Resource: LEAD_INBOX
   */
  getInboxApiUrl: InboxApiUrlType;
  /**
   * Action: READ
   * Resource: LEAD_INBOX
   */
  getGoogleInboxApiUrl: InboxApiUrlType;
};

export type QueryDownloadSignatureDocumentWithAuditTrailArgs = {
  signatureIdInput: SignatureIdInput;
};

export type QueryDownloadSignatureDocumentOnlyArgs = {
  signatureIdInput: SignatureIdInput;
};

export type QueryDownloadSignatureAuditTrailArgs = {
  signatureIdInput: SignatureIdInput;
};

export type QueryGetSignatureArgs = {
  signatureIdInput: SignatureIdInput;
};

export type QueryListSignaturesArgs = {
  listSignaturesInput: ListSignaturesInput;
};

export type QueryGetSignatureTemplateArgs = {
  getSignatureTemplateInput: GetSignatureTemplateInput;
};

export type QueryListSignatureTemplatesArgs = {
  listSignatureTemplatesInput: ListSignatureTemplatesInput;
};

export type QueryGetOAuthExternalServiceInfoArgs = {
  serviceId: Scalars['ID'];
};

export type QueryListContactsArgs = {
  listContactInput?: Maybe<ListContactInput>;
};

export type QueryListContactsWithMattersArgs = {
  listContactsWithMattersInput?: Maybe<ListContactsWithMattersInput>;
};

export type QueryGetContactArgs = {
  id: Scalars['ID'];
};

export type QueryGetContactQboSyncStatusArgs = {
  id: Scalars['ID'];
};

export type QueryListDepositsArgs = {
  listDepositInput?: Maybe<ListDepositInput>;
};

export type QueryGetDepositArgs = {
  id: Scalars['ID'];
};

export type QueryGetDepositSlipPdfArgs = {
  getDepositSlipInput: GetDepositSlipInput;
};

export type QueryGetSampleDepositSlipPdfArgs = {
  printingOffsetInput?: Maybe<PrintingOffsetInput>;
};

export type QueryGetDepositPdfArgs = {
  id: Scalars['ID'];
};

export type QueryGetDepositQboSyncStatusArgs = {
  id: Scalars['ID'];
};

export type QueryListOtosArgs = {
  listOtoInput?: Maybe<ListOtoInput>;
};

export type QueryListDisbursementsArgs = {
  listDisbursementInput?: Maybe<ListDisbursementInput>;
};

export type QueryGetDisbursementArgs = {
  id: Scalars['ID'];
};

export type QueryGetPdfCheckArgs = {
  getPDFCheckInput: GetPdfCheckInput;
};

export type QueryGetSampleCheckPdfArgs = {
  getSampleCheckPDFInput: GetSamplePdfCheckInput;
};

export type QueryGetDisbursementPdfArgs = {
  id: Scalars['ID'];
};

export type QueryGetDisbursementQboSyncStatusArgs = {
  id: Scalars['ID'];
};

export type QueryListTrustTransactionsArgs = {
  listTransactionLedgerInput?: Maybe<ListTrustTransactionInput>;
};

export type QueryGetTrustFinancialInfoArgs = {
  trustFinancialInfoInput?: Maybe<TrustFinancialInfoInput>;
};

export type QueryGetTrustBalanceArgs = {
  getTrustBalanceInput?: Maybe<GetTrustBalanceInput>;
};

export type QueryGetContactMattersTrustSummaryArgs = {
  contactId: Scalars['ID'];
};

export type QueryListLedgerActivitiesArgs = {
  listLedgerActivitiesInput?: Maybe<ListLedgerActivitiesInput>;
};

export type QueryCalculateReconciliationArgs = {
  calculateReconciliationInput: CalculateReconciliationInput;
};

export type QueryListReconciliationTransactionsArgs = {
  listReconciliationTransactionsInput: ListReconciliationTransactionsInput;
};

export type QueryListReconciliationsArgs = {
  listReconciliationInput?: Maybe<ListReconciliationInput>;
};

export type QueryGetReconciliationArgs = {
  id: Scalars['ID'];
};

export type QueryGetReconciliationDetailedPdfArgs = {
  id: Scalars['ID'];
};

export type QueryGetReconciliation3WayPdfArgs = {
  id: Scalars['ID'];
};

export type QueryGetTrustTransferArgs = {
  id: Scalars['ID'];
};

export type QueryGetTrustTransferPdfArgs = {
  id: Scalars['ID'];
};

export type QueryListThirdPartyPayeesArgs = {
  listThirdPartyPayeeInput: ListThirdPartyPayeeInput;
};

export type QueryGetThirdPartyPayeeArgs = {
  id: Scalars['ID'];
};

export type QueryGetBankAccountArgs = {
  id: Scalars['ID'];
};

export type QueryGetPaymentArgs = {
  id: Scalars['ID'];
};

export type QueryListPaymentsArgs = {
  listPaymentsInput?: Maybe<ListPaymentInput>;
};

export type QueryGetReceiptPdfArgs = {
  id: Scalars['ID'];
};

export type QueryGetRefundReceiptPdfArgs = {
  id: Scalars['ID'];
};

export type QueryGetPaymentQboSyncStatusArgs = {
  id: Scalars['ID'];
};

export type QueryGetRefundPaymentQboSyncStatusArgs = {
  id: Scalars['ID'];
};

export type QueryGetBillArgs = {
  id: Scalars['ID'];
};

export type QueryGetInvoiceArgs = {
  id: Scalars['ID'];
};

export type QueryListInvoicesArgs = {
  listInvoiceInput?: Maybe<ListInvoiceInput>;
};

export type QueryCalculateScheduledPaymentsArgs = {
  calculateScheduledPaymentsInput: CalculateScheduledPaymentsInput;
};

export type QueryGetInvoicePdfArgs = {
  id: Scalars['ID'];
};

export type QueryGetCreditMemoPdfArgs = {
  id: Scalars['ID'];
};

export type QueryGetInvoiceQboSyncStatusArgs = {
  id: Scalars['ID'];
};

export type QueryGetCreditMemoQboSyncStatusArgs = {
  id: Scalars['ID'];
};

export type QueryFetchCalendarItemsArgs = {
  fetchCalendarItemsInput: FetchCalendarItemsInput;
};

export type QueryGetCalendarItemArgs = {
  id: Scalars['ID'];
};

export type QueryGetLeadArgs = {
  id: Scalars['ID'];
};

export type QueryListLeadsArgs = {
  listLeadsInput: ListLeadsInput;
};

export type QueryListLeadsArchiveArgs = {
  listLeadsArchiveInput: ListLeadsArchiveInput;
};

export type QueryListArchiveReasonsArgs = {
  listLeadArchiveReasonsInput: ListLeadArchiveReasonsInput;
};

export type QueryListContactFilesArgs = {
  listFilesInput?: Maybe<ListFilesInput>;
};

export type QueryListMatterFilesArgs = {
  listFilesInput?: Maybe<ListFilesInput>;
};

export type QueryListInvoiceFilesArgs = {
  listFilesInput?: Maybe<ListFilesInput>;
};

export type QueryListPaymentFilesArgs = {
  listFilesInput?: Maybe<ListFilesInput>;
};

export type QueryListSignatureFilesArgs = {
  listFilesInput?: Maybe<ListFilesInput>;
};

export type QueryListSignatureTemplateFilesArgs = {
  listFilesInput?: Maybe<ListFilesInput>;
};

export type QueryListExpenseFilesArgs = {
  listFilesInput?: Maybe<ListFilesInput>;
};

export type QueryListVoidInvoiceFilesArgs = {
  listFilesInput?: Maybe<ListFilesInput>;
};

export type QueryListReconciliationFilesArgs = {
  listFilesInput?: Maybe<ListFilesInput>;
};

export type QueryListTrustTransferFilesArgs = {
  listFilesInput?: Maybe<ListFilesInput>;
};

export type QueryGetFileAndFolderListArgs = {
  fileFolderListInput?: Maybe<FileFolderListInput>;
};

export type QueryGetFileOrFolderArgs = {
  getFileOrFolderInput: GetFileOfFolderInput;
};

export type QueryGetFileAndFolderPaginatedListArgs = {
  fileFolderListInput?: Maybe<FileFolderListInput>;
};

export type QueryGetPathArgs = {
  getPathInput: GetPathInput;
};

export type QueryGetFolderArgs = {
  getFolderInput: GetFolderInput;
};

export type QueryGetDrivesFromGoogleArgs = {
  getDrivesInput?: Maybe<GetGoogleDrivesInput>;
};

export type QueryGetFoldersFromGoogleArgs = {
  getDriveFolderInput?: Maybe<GetGoogleDriveFoldersInput>;
};

export type QueryGoogleCheckFolderNameArgs = {
  googleCheckFolderNameInput: CheckFolderNameExistingInput;
};

export type QueryListLeadFilesArgs = {
  listFilesInput?: Maybe<ListFilesInput>;
};

export type QueryGetQboRedirectUrlArgs = {
  getQboRedirectUrlInput: GetQboRedirectUrlInput;
};

export type QueryListQboSyncHistoryArgs = {
  listQboSyncHistoryInput: ListQboSyncHistoryInput;
};

export type QueryGetRoundingRulesArgs = {
  billedById?: Maybe<Scalars['ID']>;
};

export type QueryGetMatterArgs = {
  id: Scalars['ID'];
};

export type QueryListMattersArgs = {
  listMatterInput?: Maybe<ListMatterInput>;
};

export type QueryGetLimitedFlatFeeMatterStatArgs = {
  matterId: Scalars['ID'];
};

export type QueryListExpensesArgs = {
  listExpensesInput: ListExpensesInput;
};

export type QueryListSubExpensesArgs = {
  listSubExpensesInput: ListSubExpensesInput;
};

export type QueryGetSubExpenseArgs = {
  id: Scalars['ID'];
};

export type QueryListSubExpenseCategoriesArgs = {
  listSubExpenseCategories: ListSubExpenseCategories;
};

export type QueryGetSubExpenseCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryListFlatFeesArgs = {
  listFlatFeesInput: ListFlatFeesInput;
};

export type QueryGetFlatFeeArgs = {
  id: Scalars['ID'];
};

export type QueryListTimersArgs = {
  timerContactInput?: Maybe<FilterTimerInput>;
};

export type QueryGetTimerArgs = {
  id: Scalars['ID'];
};

export type QueryListTimeEntriesArgs = {
  listTimeEntryInput: ListTimeEntriesInput;
};

export type QueryGetTimeEntryArgs = {
  id: Scalars['ID'];
};

export type QueryListTimerActivitiesArgs = {
  listTimerActivitiesInput: ListTimerActivityInput;
};

export type QueryGetTimerActivityArgs = {
  id: Scalars['ID'];
};

export type QueryGetPaymentsStatArgs = {
  paymentStatFilterInput?: Maybe<PaymentStatFilterInput>;
};

export type QueryGetInvoicesStatArgs = {
  invoiceStatFilterInput?: Maybe<InvoiceStatFilterInput>;
};

export type QueryGetContactSummaryStatArgs = {
  contactPaymentStatFilterInput?: Maybe<ContactPaymentStatFilterInput>;
};

export type QueryGetCommonPaymentStatArgs = {
  commonPaymentStatFilterInput?: Maybe<CommonPaymentStatFilterInput>;
};

export type QueryGetDashboardStatArgs = {
  dashboardStatFilterInput: DashboardStatFilterInput;
};

export type QueryGetRevenueStatArgs = {
  revenueStatInput?: Maybe<RevenueStatInput>;
};

export type QueryGetExpensesStatArgs = {
  expenseStatFilterInput?: Maybe<ExpenseStatFilterInput>;
};

export type QueryGetFlatFeeMatterStatArgs = {
  matterId?: Maybe<Scalars['ID']>;
};

export type QueryListTeamMembersArgs = {
  listTeamMembersInput?: Maybe<ListTeamMembersInput>;
};

export type QueryGetTeamMemberArgs = {
  id: Scalars['ID'];
};

export type QueryGetContactsCsvReportArgs = {
  reportContactInput: ReportContactInput;
};

export type QueryGetPaymentsCsvReportArgs = {
  reportPaymentInput: ReportPaymentInput;
};

export type QueryGetInvoicesCsvReportArgs = {
  reportInvoiceInput: ReportInvoiceInput;
};

export type QueryGetPlatformVersionArgs = {
  inputPlatformVersion: PlatformVersionInput;
};

export type QueryIsDefaultDomainFreeArgs = {
  defaultDomainInput: DefaultDomainInput;
};

export type QueryListInboxRecordsArgs = {
  listInboxRecordsInput: ListInboxRecordsInput;
};

export type ReconciliationFilterInput = {
  statuses?: Maybe<Array<Maybe<ReconciliationStatuses>>>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export type ReconciliationResultType = {
  __typename: 'ReconciliationResultType';
  beginningBalance: Scalars['Float'];
  clearedDeposits: Scalars['Float'];
  clearedDisbursements: Scalars['Float'];
  clearedBalance: Scalars['Float'];
  endingBalance: Scalars['Float'];
  clearedDifference: Scalars['Float'];
  unclearedDeposits: Scalars['Float'];
  unclearedDisbursements: Scalars['Float'];
  adjustedBalance: Scalars['Float'];
  transactionLedgerBalance: Scalars['Float'];
  allContactLedgersBalance: Scalars['Float'];
  canBeCompleted: Scalars['Boolean'];
  balanceDifference: Scalars['Float'];
};

/** Types of reconciliation statuses */
export enum ReconciliationStatuses {
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export type ReconciliationType = {
  __typename: 'ReconciliationType';
  id: Scalars['ID'];
  summary: ReconciliationResultType;
  statementBalance: Scalars['Float'];
  endingBalance: Scalars['Float'];
  statementDate: Scalars['Date'];
  transactions?: Maybe<Array<TrustTransactionType>>;
  status: ReconciliationStatuses;
  hasStatement: Scalars['Boolean'];
};

export type RemoveFlatFeeInput = {
  id: Scalars['ID'];
};

export type RemoveSignatureTemplateInput = {
  id: Scalars['ID'];
};

export type RemoveSubExpenseInput = {
  id: Scalars['ID'];
};

export type RemoveTimeEntryInput = {
  id: Scalars['ID'];
};

export type RemovedEntitySubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
};

export type RemovedEntityType = {
  __typename: 'RemovedEntityType';
  id: Scalars['ID'];
};

export type RenameFileInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RenameFolderDataInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ReplaceBankAccountInput = {
  bankAccountId: Scalars['ID'];
  bankAccount: BankAccountInput;
};

export type ReportContactInput = {
  filter: FilterContactInput;
  order: OrderContactInput;
};

export type ReportInvoiceInput = {
  filter: InvoiceFilterInput;
  order: OrderInvoiceInput;
};

export type ReportPaymentInput = {
  filter: FilterPaymentInput;
  order: OrderPaymentInput;
};

export type ResetPasswordInput = {
  password: Scalars['String'];
};

export type RevenueStatInput = {
  filter?: Maybe<FilterRevenueStatInput>;
  order?: Maybe<OrderRevenueStatInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type RevenueStatType = {
  __typename: 'RevenueStatType';
  totalRevenueAbsolute?: Maybe<Scalars['Float']>;
  contacts: PaginatedContactRevenueStatList;
};

/** rounding rules direction */
export enum RoundingRulesDirection {
  UP = 'UP',
  DOWN = 'DOWN',
}

export type RoundingRulesType = {
  __typename: 'RoundingRulesType';
  enabled: Scalars['Boolean'];
  rounding: RoundingRulesDirection;
  divider: Scalars['Int'];
};

export type S3FileInput = {
  name: Scalars['String'];
  mimetype: Scalars['String'];
  url: Scalars['String'];
};

/** Possible file sources */
export enum S3FileSources {
  CONTACT = 'CONTACT',
  MATTER = 'MATTER',
  INVOICE = 'INVOICE',
  VOID_INVOICE = 'VOID_INVOICE',
  PAYMENT = 'PAYMENT',
  SIGNATURE = 'SIGNATURE',
  SIGNATURE_TEMPLATE = 'SIGNATURE_TEMPLATE',
  EXPENSE = 'EXPENSE',
  RECONCILIATION = 'RECONCILIATION',
  TRUST_TRANSFER = 'TRUST_TRANSFER',
  LEAD = 'LEAD',
}

export type S3FileType = {
  __typename: 'S3FileType';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
  /** file size in bytes */
  size?: Maybe<Scalars['Float']>;
  dateAdded: Scalars['DateTime'];
  mimetype: Scalars['String'];
  source: S3FileSources;
};

export type SavePrintingChecksSettingsInput = {
  mode?: Maybe<PrintingChecksModes>;
  offset?: Maybe<Array<Scalars['Float']>>;
};

export type SavePrintingDepositSlipSettingsInput = {
  offset?: Maybe<Array<Scalars['Float']>>;
};

export type ScheduleInvoiceRemindersInput = {
  invoiceId: Scalars['ID'];
  paymentReminder: InvoicePaymentReminderInput;
};

/** Types of scheduled payment statuses */
export enum ScheduledPaymentStatuses {
  SCHEDULED = 'SCHEDULED',
  PAID = 'PAID',
  FAILED = 'FAILED',
}

export type ScheduledPaymentType = {
  __typename: 'ScheduledPaymentType';
  amount: Scalars['Float'];
  paymentDate: Scalars['Date'];
  status: ScheduledPaymentStatuses;
};

export type ScheduledPaymentsSettingsInput = {
  installments: Scalars['Int'];
  invoiceEvery: Scalars['Int'];
  invoicePeriod: PeriodTypes;
  startDate: Scalars['Date'];
};

export type ScheduledPaymentsSettingsType = {
  __typename: 'ScheduledPaymentsSettingsType';
  installments: Scalars['Int'];
  invoiceEvery: Scalars['Int'];
  invoicePeriod: PeriodTypes;
  startDate: Scalars['Date'];
};

export type ScheduledPaymentsType = {
  __typename: 'ScheduledPaymentsType';
  settings?: Maybe<ScheduledPaymentsSettingsType>;
  plan?: Maybe<Array<ScheduledPaymentType>>;
  planApproved: Scalars['Boolean'];
};

/** Permissions which user grants by oauth */
export enum Scopes {
  CONTACT_READ = 'CONTACT_READ',
  MATTER_READ = 'MATTER_READ',
  FIRM_READ = 'FIRM_READ',
  EXPENSE_CREATE = 'EXPENSE_CREATE',
}

export type SendCreditMemoInput = {
  id: Scalars['ID'];
  contactId: Scalars['ID'];
  subject?: Maybe<Scalars['String']>;
  /** e-mail carbon copy */
  cc?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Scalars['ID']>>;
  includeAccountStatementLink?: Maybe<Scalars['Boolean']>;
};

export type SendInvoiceInput = {
  id: Scalars['ID'];
  contactId: Scalars['ID'];
  subject?: Maybe<Scalars['String']>;
  /** e-mail carbon copy */
  cc?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Scalars['ID']>>;
  includeAccountStatementLink?: Maybe<Scalars['Boolean']>;
};

export type SendPaymentInput = {
  id: Scalars['ID'];
  contactId: Scalars['ID'];
  subject?: Maybe<Scalars['String']>;
  /** e-mail carbon copy */
  cc?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Scalars['ID']>>;
  includeAccountStatementLink?: Maybe<Scalars['Boolean']>;
};

export type SendRefundPaymentInput = {
  id: Scalars['ID'];
  contactId: Scalars['ID'];
  subject?: Maybe<Scalars['String']>;
  /** e-mail carbon copy */
  cc?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Scalars['ID']>>;
  includeAccountStatementLink?: Maybe<Scalars['Boolean']>;
};

export type SendVoidInvoiceInput = {
  id: Scalars['ID'];
  contactId: Scalars['ID'];
  subject?: Maybe<Scalars['String']>;
  /** e-mail carbon copy */
  cc?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Scalars['ID']>>;
  includeAccountStatementLink?: Maybe<Scalars['Boolean']>;
};

export type SetInitialBalancesInput = {
  endingBalance: Scalars['Float'];
  statementDate: Scalars['Date'];
  ledgerBalances?: Maybe<Array<InitialBalanceInput>>;
};

export type SetStatusSubExpenseCategoryInput = {
  id: Scalars['ID'];
  status: SubExpenseCategoryStatus;
};

export type SetStatusThirdPartyPayeesInput = {
  id: Scalars['ID'];
  status: Scalars['String'];
};

export type SetStatusTimerActivityInput = {
  id: Scalars['ID'];
  status: TimerActivityStatuses;
};

export type SignatureFilterInput = {
  statuses?: Maybe<Array<SignatureStatuses>>;
  search?: Maybe<Scalars['String']>;
};

export type SignatureIdInput = {
  id: Scalars['ID'];
};

export type SignatureOrderInput = {
  title?: Maybe<OrderTypes>;
  status?: Maybe<OrderTypes>;
  createdAt?: Maybe<OrderTypes>;
};

/** Possible signature statuses */
export enum SignatureStatuses {
  DRAFT = 'DRAFT',
  NOT_PREPARED = 'NOT_PREPARED',
  SENT = 'SENT',
  PARTIALLY_SIGNED = 'PARTIALLY_SIGNED',
  SIGNED = 'SIGNED',
  DECLINE_REQUEST_SENT = 'DECLINE_REQUEST_SENT',
  DECLINED = 'DECLINED',
  ERROR = 'ERROR',
}

export type SignatureSubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
  statuses?: Maybe<Array<SignatureStatuses>>;
};

/** Possible signature template statuses */
export enum SignatureTemplateStatuses {
  DRAFT = 'DRAFT',
  READY = 'READY',
  ERROR = 'ERROR',
}

export type SignatureTemplateSubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
  statuses?: Maybe<Array<SignatureTemplateStatuses>>;
};

export type SignatureTemplateType = {
  __typename: 'SignatureTemplateType';
  id: Scalars['ID'];
  signerRoles: Array<SignerRoleTemplateType>;
  completeInOrder: Scalars['Boolean'];
  title: Scalars['String'];
  status: SignatureTemplateStatuses;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SignatureTemplateWithUrlType = {
  __typename: 'SignatureTemplateWithUrlType';
  template: SignatureTemplateType;
  templateUrl: SignatureUrlType;
};

export type SignatureTemplatesFilterInput = {
  statuses?: Maybe<Array<SignatureTemplateStatuses>>;
  search?: Maybe<Scalars['String']>;
};

export type SignatureTemplatesOrderInput = {
  createdAt?: Maybe<OrderTypes>;
  status?: Maybe<OrderTypes>;
};

export type SignatureType = {
  __typename: 'SignatureType';
  id: Scalars['ID'];
  signers: Array<SignerType>;
  template?: Maybe<SignatureTemplateType>;
  title: Scalars['String'];
  status: SignatureStatuses;
  completeInOrder: Scalars['Boolean'];
  text?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  nonSignatoryRecipients?: Maybe<Array<Scalars['String']>>;
  hasWithoutServiceInfoVersion?: Maybe<Scalars['Boolean']>;
  waitForPdfUpdate: Scalars['Boolean'];
};

export type SignatureUrlType = {
  __typename: 'SignatureUrlType';
  url: Scalars['String'];
  expiresAt: Scalars['DateTime'];
};

export type SignatureWithUrlType = {
  __typename: 'SignatureWithUrlType';
  signature: SignatureType;
  signatureUrl: SignatureUrlType;
};

export type SignerInput = {
  externalSigner?: Maybe<ExternalSignerInput>;
  contactId?: Maybe<Scalars['ID']>;
  teamMemberId?: Maybe<Scalars['ID']>;
};

export type SignerRoleTemplateInput = {
  roleName: Scalars['String'];
};

export type SignerRoleTemplateType = {
  __typename: 'SignerRoleTemplateType';
  id: Scalars['ID'];
  roleName: Scalars['String'];
};

/** Possible signer statuses */
export enum SignerStatuses {
  NOT_PREPARED = 'NOT_PREPARED',
  PREPARED = 'PREPARED',
  SENT = 'SENT',
  VIEWED = 'VIEWED',
  SIGNED = 'SIGNED',
  DECLINED = 'DECLINED',
}

export type SignerTemplateInput = {
  externalSigner?: Maybe<ExternalSignerInput>;
  contactId?: Maybe<Scalars['ID']>;
  teamMemberId?: Maybe<Scalars['ID']>;
  roleId: Scalars['ID'];
};

export type SignerType = {
  __typename: 'SignerType';
  id: Scalars['ID'];
  contact?: Maybe<ContactType>;
  teamMember?: Maybe<UserType>;
  externalSigner?: Maybe<ExternalSignerType>;
  status: SignerStatuses;
  order?: Maybe<Scalars['Float']>;
  roleName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SiteActivityResponseType = {
  __typename: 'SiteActivityResponseType';
  isActive: Scalars['Boolean'];
};

export type SiteConfiguratorUrlResponseType = {
  __typename: 'SiteConfiguratorUrlResponseType';
  url: Scalars['String'];
};

export type SiteInfoResponseType = {
  __typename: 'SiteInfoResponseType';
  defaultDomain?: Maybe<Scalars['String']>;
  customDomain?: Maybe<Scalars['String']>;
  isConfigurationExists: Scalars['Boolean'];
};

export enum StripeBankAccountStatus {
  CONNECTED = 'CONNECTED',
  MIGRATION_REQUIRED = 'MIGRATION_REQUIRED',
  FEE_ACCOUNT_REQUIRED = 'FEE_ACCOUNT_REQUIRED',
  FEE_ACCOUNT_PROCESSING = 'FEE_ACCOUNT_PROCESSING',
}

export enum StripeKybStatus {
  SENT = 'SENT',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export type StrippedContactWithMattersType = {
  __typename: 'StrippedContactWithMattersType';
  id: Scalars['ID'];
  name: Scalars['String'];
  isEntity: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  billingContacts?: Maybe<Array<EntityBillingContactsType>>;
  matters?: Maybe<Array<StrippedMatterType>>;
  trustBalance?: Maybe<Scalars['Float']>;
};

export type StrippedMatterType = {
  __typename: 'StrippedMatterType';
  id: Scalars['ID'];
  type: MatterTypes;
  name: Scalars['String'];
  matterNumber: Scalars['String'];
  trustBalance?: Maybe<Scalars['Float']>;
};

/** Types of sub-expense cost */
export enum SubExpenseCategoryCostTypes {
  SOFT = 'SOFT',
  HARD = 'HARD',
}

/** Sub Expense category scopes */
export enum SubExpenseCategoryScope {
  FIRM = 'FIRM',
  GLOBAL = 'GLOBAL',
}

/** Sub Expense category status */
export enum SubExpenseCategoryStatus {
  ACTIVE = 'ACTIVE',
  NOT_ACTIVE = 'NOT_ACTIVE',
}

export type SubExpenseCategorySubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
  statuses?: Maybe<Array<SubExpenseCategoryStatus>>;
  scopes?: Maybe<Array<SubExpenseCategoryScope>>;
  costTypes?: Maybe<Array<SubExpenseCategoryCostTypes>>;
};

export type SubExpenseCategoryType = {
  __typename: 'SubExpenseCategoryType';
  id: Scalars['ID'];
  name: Scalars['String'];
  costType: SubExpenseCategoryCostTypes;
  status: SubExpenseCategoryStatus;
  scope: SubExpenseCategoryScope;
};

export type SubExpenseSubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
  costType?: Maybe<SubExpenseCategoryCostTypes>;
  billable?: Maybe<Scalars['Boolean']>;
  mattersIds?: Maybe<Array<Scalars['ID']>>;
  contactId?: Maybe<Scalars['ID']>;
  billedByIds?: Maybe<Array<Scalars['String']>>;
  statuses?: Maybe<Array<ExpenseStatuses>>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export type SubExpenseType = {
  __typename: 'SubExpenseType';
  id: Scalars['ID'];
  contact: ContactType;
  matter: MatterType;
  invoice?: Maybe<InvoiceType>;
  amount: Scalars['Float'];
  date: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  billable?: Maybe<Scalars['Boolean']>;
  billedBy: UserType;
  notes?: Maybe<Scalars['String']>;
  status: ExpenseStatuses;
  category: SubExpenseCategoryType;
};

export type Subscription = {
  __typename: 'Subscription';
  /**
   * Action: READ
   * Resource: SIGNATURE
   */
  signatureCreated: SignatureType;
  /**
   * Action: READ
   * Resource: SIGNATURE
   */
  signatureUpdated: SignatureType;
  /**
   * Action: READ
   * Resource: SIGNATURE
   */
  signatureDeleted: RemovedEntityType;
  /**
   * Action: READ
   * Resource: SIGNATURE_TEMPLATE
   */
  signatureTemplateCreated: SignatureTemplateType;
  /**
   * Action: READ
   * Resource: SIGNATURE_TEMPLATE
   */
  signatureTemplateUpdated: SignatureTemplateType;
  /**
   * Action: READ
   * Resource: SIGNATURE_TEMPLATE
   */
  signatureTemplateRemoved: RemovedEntityType;
  /**
   * Action: READ
   * Resource: CONTACT
   */
  contactCreated: ContactType;
  /**
   * Action: READ
   * Resource: CONTACT
   */
  contactUpdated: ContactType;
  /**
   * Action: READ
   * Resource: CONTACT
   */
  contactDeleted: RemovedEntityType;
  /**
   * Action: READ
   * Resource: DEPOSIT
   */
  depositCreated: DepositType;
  /**
   * Action: READ
   * Resource: DEPOSIT
   */
  depositUpdated: DepositType;
  /**
   * Action: READ
   * Resource: DISBURSEMENT
   */
  disbursementCreated: DisbursementType;
  /**
   * Action: READ
   * Resource: DISBURSEMENT
   */
  disbursementUpdated: DisbursementType;
  /**
   * Action: READ
   * Resource: RECONCILIATION
   */
  reconciliationCreated: ReconciliationType;
  /**
   * Action: READ
   * Resource: RECONCILIATION
   */
  reconciliationUpdated: ReconciliationType;
  /**
   * Action: READ
   * Resource: RECONCILIATION
   */
  reconciliationDeleted: RemovedEntityType;
  /**
   * Action: READ
   * Resource: TRANSACTION_LEDGER
   */
  trustTransactionCreated: TrustTransactionType;
  /**
   * Action: READ
   * Resource: TRANSACTION_LEDGER
   */
  trustTransactionUpdated: TrustTransactionType;
  /**
   * Action: READ
   * Resource: THIRD_PARTY_PAYEES
   */
  thirdPartyPayeeCreated: ThirdPartyPayeeType;
  /**
   * Action: READ
   * Resource: THIRD_PARTY_PAYEES
   */
  thirdPartyPayeesUpdated: ThirdPartyPayeeType;
  /**
   * Action: READ
   * Resource: THIRD_PARTY_PAYEES
   */
  thirdPartyPayeesDeleted: RemovedEntityType;
  /**
   * Action: READ
   * Resource: BANK_ACCOUNT
   */
  bankAccountCreated: BankAccountType;
  /**
   * Action: READ
   * Resource: BANK_ACCOUNT
   */
  bankAccountUpdated: BankAccountType;
  /**
   * Action: READ
   * Resource: BANK_ACCOUNT
   */
  bankAccountDeleted: RemovedEntityType;
  /**
   * Action: READ
   * Resource: PAYMENT
   */
  paymentCreated: PaymentType;
  /**
   * Action: READ
   * Resource: PAYMENT
   */
  paymentUpdated: PaymentType;
  /**
   * Action: READ
   * Resource: INVOICE
   */
  invoiceCreated: InvoiceType;
  /**
   * Action: READ
   * Resource: INVOICE
   */
  invoiceUpdated: InvoiceType;
  /**
   * Action: READ
   * Resource: CALENDAR
   */
  calendarItemCreated: CalendarItemType;
  /**
   * Action: READ
   * Resource: CALENDAR
   */
  calendarItemUpdated: CalendarItemType;
  /**
   * Action: READ
   * Resource: CALENDAR
   */
  calendarItemDeleted: RemovedEntityType;
  /**
   * Action: READ
   * Resource: CALENDAR
   */
  calendarReloadRequired: CalendarReloadRequiredType;
  /**
   * Action: READ
   * Resource: CALENDAR
   */
  calendarSyncSettingsUpdated: CalendarSyncSettingsType;
  /**
   * Action: READ
   * Resource: GOOGLE_PERSONAL_INTEGRATION
   */
  googlePersonalIntegrationStatusUpdated: GooglePersonalIntegrationStatusType;
  /**
   * Action: READ
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  googleFirmIntegrationStatusUpdated: GooglePersonalIntegrationStatusType;
  /**
   * Action: READ
   * Resource: LEAD
   */
  leadCreated: LeadType;
  /**
   * Action: READ
   * Resource: LEAD
   */
  leadUpdated: LeadType;
  /**
   * Action: READ
   * Resource: LEAD
   */
  leadStatusCreated: LeadStatusType;
  /**
   * Action: READ
   * Resource: LEAD
   */
  leadStatusUpdated: LeadStatusType;
  /**
   * Action: READ
   * Resource: LEAD
   */
  leadStatusDeleted: RemovedEntityType;
  /**
   * Action: READ
   * Resource: FILES
   */
  fileCreated: S3FileType;
  /**
   * Action: READ
   * Resource: FILES
   */
  fileDeleted: RemovedEntityType;
  /**
   * Action: READ
   * Resource: FILES
   */
  fileStorageCreated: FileStorageItemType;
  /**
   * Action: READ
   * Resource: FILES
   */
  fileStorageUpdated: FileStorageItemType;
  /**
   * Action: READ
   * Resource: FILES
   */
  fileStorageDeleted: RemovedEntityType;
  /**
   * Action: READ
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  fileStorageSyncOptionUpdated: FileStorageSyncOptionType;
  /**
   * Action: READ
   * Resource: QBO
   */
  qboIntegrationEnabled: QboIntegrationEnabledType;
  /**
   * Action: READ
   * Resource: QBO
   */
  qboIntegrationDisabled: QboIntegrationDisabledType;
  /**
   * Action: READ
   * Resource: QBO
   */
  qboSyncFinished: QboSyncFinishedType;
  /**
   * Action: READ
   * Resource: QBO
   */
  qboInitialEntitiesSyncFinished: QboEntitiesInitialSyncState;
  /**
   * Action: READ
   * Resource: PROFILE
   */
  profileUpdated: UserType;
  /**
   * Action: READ
   * Resource: FIRM
   */
  firmUpdated: FirmType;
  /**
   * Action: READ
   * Resource: PROFILE
   */
  twoFactorAuthenticationOptionUpdated?: Maybe<TwoFactorAuthenticationOptionsType>;
  /**
   * Action: READ
   * Resource: MATTER
   */
  matterCreated: MatterType;
  /**
   * Action: READ
   * Resource: MATTER
   */
  matterUpdated: MatterType;
  /**
   * Action: READ
   * Resource: MATTER
   */
  matterClosed: MatterType;
  /**
   * Action: READ
   * Resource: EXPENSE
   */
  expenseCreated: ExpenseType;
  /**
   * Action: READ
   * Resource: EXPENSE
   */
  expenseUpdated: ExpenseType;
  /**
   * Action: READ
   * Resource: EXPENSE
   */
  expenseRemoved: RemovedEntityType;
  /**
   * Action: READ
   * Resource: SUB_EXPENSE
   */
  subExpenseCreated: SubExpenseType;
  /**
   * Action: READ
   * Resource: SUB_EXPENSE
   */
  subExpenseUpdated: SubExpenseType;
  /**
   * Action: READ
   * Resource: SUB_EXPENSE
   */
  subExpenseRemoved: RemovedEntityType;
  /**
   * Action: READ
   * Resource: SUB_EXPENSE_CATEGORY
   */
  subExpenseCategoryCreated: SubExpenseCategoryType;
  /**
   * Action: READ
   * Resource: SUB_EXPENSE_CATEGORY
   */
  subExpenseCategoryUpdated: SubExpenseCategoryType;
  /**
   * Action: READ
   * Resource: SUB_EXPENSE_CATEGORY
   */
  subExpenseCategoryRemoved: RemovedEntityType;
  /**
   * Action: READ
   * Resource: FLAT_FEE
   */
  flatFeeCreated: FlatFeeType;
  /**
   * Action: READ
   * Resource: FLAT_FEE
   */
  flatFeeUpdated: FlatFeeType;
  /**
   * Action: READ
   * Resource: FLAT_FEE
   */
  flatFeeRemoved: RemovedEntityType;
  /**
   * Action: READ
   * Resource: TIME_ENTRY
   */
  timeEntryCreated: TimeEntryType;
  /**
   * Action: READ
   * Resource: TIME_ENTRY
   */
  timeEntryUpdated: TimeEntryType;
  /**
   * Action: READ
   * Resource: TIME_ENTRY
   */
  timeEntryRemoved: RemovedEntityType;
  /**
   * Action: READ
   * Resource: TIMER
   */
  timerCreated: TimerType;
  /**
   * Action: READ
   * Resource: TIMER
   */
  timerUpdated: TimerType;
  /**
   * Action: READ
   * Resource: TIMER_ACTIVITY
   */
  timerActivityCreated: TimerActivityType;
  /**
   * Action: READ
   * Resource: TIMER_ACTIVITY
   */
  timerActivityUpdated: TimerActivityType;
  /**
   * Action: READ
   * Resource: TIMER_ACTIVITY
   */
  timerActivityDeleted: RemovedEntityType;
  /**
   * Action: READ
   * Resource: INVOICES_STAT
   */
  financialTilesUpdated: FinancialTilesUpdatedType;
  /**
   * Action: READ
   * Resource: TEAM_MANAGEMENT
   */
  teamMemberCreated: UserType;
  /**
   * Action: READ
   * Resource: TEAM_MANAGEMENT
   */
  teamMemberUpdated: UserType;
  /**
   * Action: READ
   * Resource: TEAM_MANAGEMENT
   */
  teamMemberActivated: UserType;
  /**
   * Action: READ
   * Resource: TEAM_MANAGEMENT
   */
  teamMemberDeactivated: UserType;
  /**
   * Action: READ
   * Resource: TEAM_MANAGEMENT
   */
  teamMemberRemoved: RemovedEntityType;
  /**
   * Action: READ
   * Resource: MICROSOFT_PERSONAL_INTEGRATION
   */
  microsoftPersonalIntegrationStatusUpdated: MicrosoftPersonalIntegrationStatusType;
  /**
   * Action: READ
   * Resource: LEAD
   */
  inboxRecordCreated: InboxRecordsType;
  /**
   * Action: READ
   * Resource: LEAD
   */
  notViewedCounterUpdated: InboxCounterUpdateType;
  /**
   * Action: READ
   * Resource: LEAD
   */
  inboxRecordDeleted: RemovedEntityType;
};

export type SubscriptionSignatureCreatedArgs = {
  signatureSubscriptionInput: SignatureSubscriptionInput;
};

export type SubscriptionSignatureUpdatedArgs = {
  signatureSubscriptionInput: SignatureSubscriptionInput;
};

export type SubscriptionSignatureDeletedArgs = {
  removedEntitySubscriptionInput: RemovedEntitySubscriptionInput;
};

export type SubscriptionSignatureTemplateCreatedArgs = {
  signatureTemplateSubscriptionInput: SignatureTemplateSubscriptionInput;
};

export type SubscriptionSignatureTemplateUpdatedArgs = {
  signatureTemplateSubscriptionInput: SignatureTemplateSubscriptionInput;
};

export type SubscriptionSignatureTemplateRemovedArgs = {
  removedEntitySubscriptionInput: RemovedEntitySubscriptionInput;
};

export type SubscriptionContactCreatedArgs = {
  contactSubscriptionInput: ContactSubscriptionInput;
};

export type SubscriptionContactUpdatedArgs = {
  contactSubscriptionInput: ContactSubscriptionInput;
};

export type SubscriptionContactDeletedArgs = {
  removedEntitySubscriptionInput: RemovedEntitySubscriptionInput;
};

export type SubscriptionDepositCreatedArgs = {
  depositSubscriptionInput: DepositSubscriptionInput;
};

export type SubscriptionDepositUpdatedArgs = {
  depositSubscriptionInput: DepositSubscriptionInput;
};

export type SubscriptionDisbursementCreatedArgs = {
  disbursementSubscriptionInput: DisbursementSubscriptionInput;
};

export type SubscriptionDisbursementUpdatedArgs = {
  disbursementSubscriptionInput: DisbursementSubscriptionInput;
};

export type SubscriptionReconciliationCreatedArgs = {
  reconciliationSubscriptionInput: ReconciliationFilterInput;
};

export type SubscriptionReconciliationUpdatedArgs = {
  reconciliationSubscriptionInput: ReconciliationFilterInput;
};

export type SubscriptionTrustTransactionCreatedArgs = {
  trustTransactionSubscriptionInput: TrustTransactionSubscriptionInput;
};

export type SubscriptionTrustTransactionUpdatedArgs = {
  trustTransactionSubscriptionInput: TrustTransactionSubscriptionInput;
};

export type SubscriptionThirdPartyPayeeCreatedArgs = {
  thirdPartyPayeesSubscriptionInput: ThirdPartyPayeesSubscriptionInput;
};

export type SubscriptionThirdPartyPayeesUpdatedArgs = {
  thirdPartyPayeesSubscriptionInput: ThirdPartyPayeesSubscriptionInput;
};

export type SubscriptionThirdPartyPayeesDeletedArgs = {
  removedEntitySubscriptionInput: RemovedEntitySubscriptionInput;
};

export type SubscriptionPaymentCreatedArgs = {
  paymentSubscriptionInput: PaymentSubscriptionInput;
};

export type SubscriptionPaymentUpdatedArgs = {
  paymentSubscriptionInput: PaymentSubscriptionInput;
};

export type SubscriptionInvoiceCreatedArgs = {
  invoiceSubscriptionInput: InvoiceSubscriptionInput;
};

export type SubscriptionInvoiceUpdatedArgs = {
  invoiceSubscriptionInput: InvoiceSubscriptionInput;
};

export type SubscriptionCalendarItemCreatedArgs = {
  calendarItemSubscriptionInput: CalendarItemSubscriptionInput;
};

export type SubscriptionCalendarItemUpdatedArgs = {
  calendarItemSubscriptionInput: CalendarItemSubscriptionInput;
};

export type SubscriptionCalendarItemDeletedArgs = {
  removedEntitySubscriptionInput: RemovedEntitySubscriptionInput;
};

export type SubscriptionLeadCreatedArgs = {
  leadSubscriptionInput: LeadSubscriptionInput;
};

export type SubscriptionLeadUpdatedArgs = {
  leadSubscriptionInput: LeadSubscriptionInput;
};

export type SubscriptionFileCreatedArgs = {
  fileSubscriptionInput: FileSubscriptionInput;
};

export type SubscriptionFileDeletedArgs = {
  fileSubscriptionInput: FileSubscriptionInput;
};

export type SubscriptionFileStorageCreatedArgs = {
  fileStorageSubscriptionInput: FileFolderListFilter;
};

export type SubscriptionFileStorageUpdatedArgs = {
  fileStorageSubscriptionInput: FileFolderListFilter;
};

export type SubscriptionFileStorageDeletedArgs = {
  fileStorageSubscriptionInput: FileFolderListFilter;
};

export type SubscriptionMatterCreatedArgs = {
  matterSubscriptionInput: MatterSubscriptionInput;
};

export type SubscriptionMatterUpdatedArgs = {
  matterSubscriptionInput: MatterSubscriptionInput;
};

export type SubscriptionMatterClosedArgs = {
  matterSubscriptionInput: MatterSubscriptionInput;
};

export type SubscriptionExpenseCreatedArgs = {
  expenseSubscriptionInput: ExpenseSubscriptionInput;
};

export type SubscriptionExpenseUpdatedArgs = {
  expenseSubscriptionInput: ExpenseSubscriptionInput;
};

export type SubscriptionExpenseRemovedArgs = {
  removedEntitySubscriptionInput: RemovedEntitySubscriptionInput;
};

export type SubscriptionSubExpenseCreatedArgs = {
  subExpenseSubscriptionInput: SubExpenseSubscriptionInput;
};

export type SubscriptionSubExpenseUpdatedArgs = {
  subExpenseSubscriptionInput: SubExpenseSubscriptionInput;
};

export type SubscriptionSubExpenseRemovedArgs = {
  removedEntitySubscriptionInput: RemovedEntitySubscriptionInput;
};

export type SubscriptionSubExpenseCategoryCreatedArgs = {
  subExpenseCategorySubscriptionInput: SubExpenseCategorySubscriptionInput;
};

export type SubscriptionSubExpenseCategoryUpdatedArgs = {
  subExpenseCategorySubscriptionInput: SubExpenseCategorySubscriptionInput;
};

export type SubscriptionSubExpenseCategoryRemovedArgs = {
  removedEntitySubscriptionInput: RemovedEntitySubscriptionInput;
};

export type SubscriptionFlatFeeCreatedArgs = {
  flatFeeSubscriptionInput: FlatFeeSubscriptionInput;
};

export type SubscriptionFlatFeeUpdatedArgs = {
  flatFeeSubscriptionInput: FlatFeeSubscriptionInput;
};

export type SubscriptionFlatFeeRemovedArgs = {
  removedEntitySubscriptionInput: RemovedEntitySubscriptionInput;
};

export type SubscriptionTimeEntryCreatedArgs = {
  timeEntrySubscriptionInput: TimeEntrySubscriptionInput;
};

export type SubscriptionTimeEntryUpdatedArgs = {
  timeEntrySubscriptionInput: TimeEntrySubscriptionInput;
};

export type SubscriptionTimeEntryRemovedArgs = {
  removedEntitySubscriptionInput: RemovedEntitySubscriptionInput;
};

export type SubscriptionTimerCreatedArgs = {
  timerSubscriptionInput: TimerSubscriptionInput;
};

export type SubscriptionTimerUpdatedArgs = {
  timerSubscriptionInput: TimerSubscriptionInput;
};

export type SubscriptionTimerActivityCreatedArgs = {
  timerActivitySubscriptionInput: TimerActivitySubscriptionInput;
};

export type SubscriptionTimerActivityUpdatedArgs = {
  timerActivitySubscriptionInput: TimerActivitySubscriptionInput;
};

export type SubscriptionTimerActivityDeletedArgs = {
  removedEntitySubscriptionInput: RemovedEntitySubscriptionInput;
};

export type SubscriptionTeamMemberCreatedArgs = {
  teamMemberSubscriptionInput: TeamMemberSubscriptionInput;
};

export type SubscriptionTeamMemberUpdatedArgs = {
  teamMemberSubscriptionInput: TeamMemberSubscriptionInput;
};

export type SubscriptionTeamMemberActivatedArgs = {
  teamMemberSubscriptionInput: TeamMemberSubscriptionInput;
};

export type SubscriptionTeamMemberDeactivatedArgs = {
  teamMemberSubscriptionInput: TeamMemberSubscriptionInput;
};

export type SubscriptionTeamMemberRemovedArgs = {
  removedEntitySubscriptionInput: RemovedEntitySubscriptionInput;
};

export type SubscriptionInboxRecordCreatedArgs = {
  inboxRecordSubscriptionInput: InboxRecordSubscriptionInput;
};

export type SubscriptionInboxRecordDeletedArgs = {
  removedEntitySubscriptionInput: RemovedEntitySubscriptionInput;
};

export type SuccessResponseType = {
  __typename: 'SuccessResponseType';
  success: Scalars['Boolean'];
};

/** Possible team member access levels */
export enum TeamMemberRoles {
  ATTORNEY = 'ATTORNEY',
  PARALEGAL = 'PARALEGAL',
  BILLABLE_ADMIN = 'BILLABLE_ADMIN',
}

/** Possible team member statuses */
export enum TeamMemberStatuses {
  INVITE_SENT = 'INVITE_SENT',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export type TeamMemberSubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
  statuses?: Maybe<Array<TeamMemberStatuses>>;
  roles?: Maybe<Array<TeamMemberRoles>>;
  accessLevels?: Maybe<Array<AccessLevels>>;
};

export type ThirdPartyPayeeType = {
  __typename: 'ThirdPartyPayeeType';
  id: Scalars['ID'];
  payeeName: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  primaryPhone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address?: Maybe<AddressType>;
  status: ThirdPartyPayeesStatus;
};

/** Possible third party payee status */
export enum ThirdPartyPayeesStatus {
  ACTIVE = 'ACTIVE',
  NOT_ACTIVE = 'NOT_ACTIVE',
}

export type ThirdPartyPayeesSubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
  statuses?: Maybe<Array<ThirdPartyPayeesStatus>>;
};

export type TimeEntrySubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
  billable?: Maybe<Scalars['Boolean']>;
  mattersIds?: Maybe<Array<Scalars['ID']>>;
  contactId?: Maybe<Scalars['ID']>;
  billedByIds?: Maybe<Array<Scalars['String']>>;
  statuses?: Maybe<Array<ExpenseStatuses>>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export type TimeEntryType = {
  __typename: 'TimeEntryType';
  id: Scalars['ID'];
  timer?: Maybe<TimerType>;
  contact: ContactType;
  matter: MatterType;
  invoice?: Maybe<InvoiceType>;
  status: ExpenseStatuses;
  durationSec?: Maybe<Scalars['Float']>;
  billable: Scalars['Boolean'];
  billedBy: UserType;
  billingRate?: Maybe<Scalars['Float']>;
  amount: Scalars['Float'];
  activity?: Maybe<TimerActivityType>;
  date: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

/** Scopes of timer activity */
export enum TimerActivityScopes {
  GLOBAL = 'GLOBAL',
  FIRM = 'FIRM',
  PRIVATE = 'PRIVATE',
}

/** Statuses of timer activities */
export enum TimerActivityStatuses {
  ACTIVE = 'ACTIVE',
  NOT_ACTIVE = 'NOT_ACTIVE',
}

export type TimerActivitySubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
  statuses?: Maybe<Array<TimerActivityStatuses>>;
  scopes?: Maybe<Array<TimerActivityScopes>>;
};

export type TimerActivityType = {
  __typename: 'TimerActivityType';
  id: Scalars['ID'];
  name: Scalars['String'];
  status: TimerActivityStatuses;
  scope: TimerActivityScopes;
};

export type TimerHistoryType = {
  __typename: 'TimerHistoryType';
  date: Scalars['DateTime'];
  status: TimerStatusTypes;
};

/** Types of timer status */
export enum TimerStatusTypes {
  STARTED = 'STARTED',
  PAUSED = 'PAUSED',
  STOPPED = 'STOPPED',
}

export type TimerSubscriptionInput = {
  matterId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
};

export type TimerType = {
  __typename: 'TimerType';
  id: Scalars['ID'];
  status: TimerStatusTypes;
  matter?: Maybe<MatterType>;
  contact?: Maybe<ContactType>;
  history: Array<TimerHistoryType>;
  totalSeconds: Scalars['Float'];
  lastStateUpdated: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
};

export type TimezoneType = {
  __typename: 'TimezoneType';
  full: Scalars['String'];
  short: Scalars['String'];
};

export type TrackAnalyticEventInput = {
  event: Scalars['String'];
  data: Scalars['JSON'];
  timestamp?: Maybe<Scalars['Int']>;
};

export type TrackPublicAnalyticEventInput = {
  event: PublicAnalyticEvent;
  data: Scalars['JSON'];
  timestamp?: Maybe<Scalars['Int']>;
};

/** Transaction types */
export enum TransactionTypes {
  PAYMENT = 'PAYMENT',
  REFUND = 'REFUND',
  CREDIT_MEMO = 'CREDIT_MEMO',
}

/** Types of trust accounting status */
export enum TrustAccountingStatuses {
  MERCHANT_SETUP = 'MERCHANT_SETUP',
  QBO_SETUP = 'QBO_SETUP',
  CONTACTS_SETUP = 'CONTACTS_SETUP',
  BALANCES_SETUP = 'BALANCES_SETUP',
  COMPLETE = 'COMPLETE',
}

export type TrustBalanceType = {
  __typename: 'TrustBalanceType';
  balance?: Maybe<Scalars['Float']>;
};

export type TrustFeesAccountConnectType = {
  __typename: 'TrustFeesAccountConnectType';
  clientSecret: Scalars['String'];
};

export type TrustFinancialInfoInput = {
  contactId?: Maybe<Scalars['ID']>;
  /** Pass matterId === null to exclude matters stat */
  matterId?: Maybe<Scalars['ID']>;
};

export type TrustFinancialInfoType = {
  __typename: 'TrustFinancialInfoType';
  balance: Scalars['Float'];
  depositsAmount: Scalars['Float'];
  disbursementsAmount: Scalars['Float'];
  otoAmount: Scalars['Float'];
};

export type TrustTransactionFilterInput = {
  contactId?: Maybe<Scalars['ID']>;
  matterId?: Maybe<Scalars['ID']>;
};

/** Types of trust transaction status */
export enum TrustTransactionStatuses {
  CLEARED = 'CLEARED',
  UNCLEARED = 'UNCLEARED',
  SETTLED = 'SETTLED',
  UNSETTLED = 'UNSETTLED',
  VOIDED = 'VOIDED',
  ENTERED = 'ENTERED',
}

export type TrustTransactionSubscriptionInput = {
  includeIds?: Maybe<Array<Scalars['ID']>>;
  contactId?: Maybe<Scalars['ID']>;
  matterId?: Maybe<Scalars['ID']>;
};

export type TrustTransactionType = {
  __typename: 'TrustTransactionType';
  id: Scalars['ID'];
  date: Scalars['Date'];
  type: TrustTransactionTypes;
  status: TrustTransactionStatuses;
  amount: Scalars['Float'];
  balance?: Maybe<Scalars['Float']>;
  ledgerBalance?: Maybe<Scalars['Float']>;
  ledger: ContactMatterType;
  payeeOrSource?: Maybe<ContactMatterType>;
  children?: Maybe<Array<TrustTransactionType>>;
  parent?: Maybe<TrustTransactionType>;
  check?: Maybe<CheckType>;
  transfer?: Maybe<TrustTransferType>;
  qboSyncState?: Maybe<QboEntitySyncStatus>;
};

/** Types of trust transaction types */
export enum TrustTransactionTypes {
  OTO = 'OTO',
  DISBURSEMENT_CONTACT = 'DISBURSEMENT_CONTACT',
  DISBURSEMENT_OPERATING = 'DISBURSEMENT_OPERATING',
  DISBURSEMENT_THIRD_PARTY = 'DISBURSEMENT_THIRD_PARTY',
  DEPOSIT = 'DEPOSIT',
  INITIAL_BALANCE = 'INITIAL_BALANCE',
  DEPOSIT_TRANSFER = 'DEPOSIT_TRANSFER',
  DISBURSEMENT_TRANSFER = 'DISBURSEMENT_TRANSFER',
}

export type TrustTransferType = {
  __typename: 'TrustTransferType';
  id: Scalars['ID'];
  date: Scalars['Date'];
  amount: Scalars['Float'];
  fromContact: ContactType;
  fromMatter?: Maybe<MatterType>;
  toContact: ContactType;
  toMatter?: Maybe<MatterType>;
  description: Scalars['String'];
  status: TrustTransactionStatuses;
};

export type TwoFactorAuthSignInInput = {
  smsCode: Scalars['String'];
  temporaryToken: Scalars['String'];
};

export type TwoFactorAuthenticationOptionsType = {
  __typename: 'TwoFactorAuthenticationOptionsType';
  truncatedPhoneNumber: Scalars['String'];
  userId: Scalars['ID'];
};

export type TwoFactorAuthenticationResendCode = {
  temporaryToken: Scalars['String'];
};

/** USA states abbreviations */
export enum UsaStates {
  AL = 'AL',
  AK = 'AK',
  AS = 'AS',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  DC = 'DC',
  FM = 'FM',
  FL = 'FL',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MH = 'MH',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  MP = 'MP',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PW = 'PW',
  PA = 'PA',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VI = 'VI',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

export type UnclearedTransactionInput = {
  memo: Scalars['String'];
  type: UnclearedTransactionTypes;
  amount: Scalars['Float'];
  date: Scalars['Date'];
};

/** Types of uncleared trust transaction types */
export enum UnclearedTransactionTypes {
  DEPOSIT = 'DEPOSIT',
  DISBURSEMENT = 'DISBURSEMENT',
}

export type UpdateCalendarItemInputType = {
  id: Scalars['ID'];
  priority?: Maybe<CalendarItemPriority>;
  color?: Maybe<CalendarItemColor>;
  contactId?: Maybe<Scalars['ID']>;
  matterId?: Maybe<Scalars['ID']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  itemType: CalendarItemTypes;
  taskData?: Maybe<CalendarItemTaskInputType>;
  eventData?: Maybe<CalendarItemEventDataInputType>;
  deadlineData?: Maybe<CalendarItemDeadlineDataInput>;
  subEntryItemData?: Maybe<CalendarSubEntryItemDataInput>;
  repeats?: Maybe<CalendarItemRepeatInput>;
  repeatModificationType?: Maybe<CalendarRepeatModificationTypes>;
  insertOnCalendar?: Maybe<Array<InsertOnCalendarInput>>;
};

export type UpdateContactDataInput = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderTypes>;
  originatingAttorney?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  conflictsCheckCompleted?: Maybe<Scalars['Boolean']>;
  engagementHasBeenSigned?: Maybe<Scalars['Boolean']>;
  w9FormSentToClient?: Maybe<Scalars['Boolean']>;
  taxable?: Maybe<Scalars['Boolean']>;
  phoneNumbers?: Maybe<Array<PhoneNumberInput>>;
  addresses?: Maybe<Array<AddressInput>>;
  email?: Maybe<Scalars['String']>;
};

export type UpdateContactInput = {
  contact?: Maybe<UpdateContactDataInput>;
  entity?: Maybe<UpdateEntityDataInput>;
};

export type UpdateEntityDataInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  originatingAttorney?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<Array<PhoneNumberInput>>;
  addresses?: Maybe<Array<AddressInput>>;
  billingContacts?: Maybe<Array<EntityBillingContactsInput>>;
  conflictsCheckCompleted?: Maybe<Scalars['Boolean']>;
  engagementHasBeenSigned?: Maybe<Scalars['Boolean']>;
  w9FormSentToClient?: Maybe<Scalars['Boolean']>;
  taxable?: Maybe<Scalars['Boolean']>;
};

export type UpdateFilesInput = {
  entityId: Scalars['ID'];
  newFiles?: Maybe<Array<S3FileInput>>;
  fileIdsToDelete?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateFirmInput = {
  name?: Maybe<Scalars['String']>;
  legalName?: Maybe<Scalars['String']>;
  entityType?: Maybe<FirmEntityTypes>;
  businessStartedSince?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<PhoneNumberInput>;
  address?: Maybe<AddressInput>;
  logo?: Maybe<PictureInput>;
  size?: Maybe<FirmSizes>;
};

export type UpdateFlatFeeInput = {
  id: Scalars['ID'];
  contactId?: Maybe<Scalars['ID']>;
  matterId?: Maybe<Scalars['ID']>;
  billedById?: Maybe<Scalars['ID']>;
  amount?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  billable?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
};

export type UpdateInvoiceInput = {
  id: Scalars['ID'];
  contactId: Scalars['ID'];
  /** Matter id required only for TRUST invoices */
  matterId?: Maybe<Scalars['ID']>;
  timeEntries?: Maybe<Array<Scalars['ID']>>;
  expenses?: Maybe<Array<Scalars['ID']>>;
  flatFees?: Maybe<Array<Scalars['ID']>>;
  amount?: Maybe<Scalars['Float']>;
  scheduleSettings?: Maybe<ScheduledPaymentsSettingsInput>;
  dueDate: Scalars['DateTime'];
  internalMemo?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  percentageDiscount: Scalars['Boolean'];
  discount: Scalars['Float'];
};

export type UpdateLeadContactInput = {
  type: LeadContactTypes;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type UpdateLeadEntityInput = {
  entityName?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<UpdateLeadContactInput>>;
};

export type UpdateLeadIndividualInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type UpdateLeadInput = {
  individual?: Maybe<UpdateLeadIndividualInput>;
  entity?: Maybe<UpdateLeadEntityInput>;
  phoneNumbers?: Maybe<Array<PhoneNumberInput>>;
  notes?: Maybe<Scalars['String']>;
  originatingAttorneyId?: Maybe<Scalars['ID']>;
  leadSource?: Maybe<LeadSources>;
  isImportant?: Maybe<Scalars['Boolean']>;
};

export type UpdateLeadStatusInput = {
  title?: Maybe<Scalars['String']>;
  color?: Maybe<LeadStatusColors>;
  order?: Maybe<Scalars['Int']>;
};

export type UpdateLeadsStatusInput = {
  leadIds: Array<Scalars['ID']>;
  statusId: Scalars['ID'];
  archiveReason?: Maybe<Scalars['String']>;
  associateWithContactId?: Maybe<Scalars['ID']>;
  createContactInput?: Maybe<CreateContactInput>;
};

export type UpdateLeadsStatusType = {
  __typename: 'UpdateLeadsStatusType';
  statusId: Scalars['ID'];
  leadIds: Array<Scalars['ID']>;
  contactCreated: Scalars['Boolean'];
  linkedContact?: Maybe<ContactType>;
};

export type UpdateMatterInput = {
  id: Scalars['ID'];
  agreedFlatFee?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  dateOpened?: Maybe<Scalars['Date']>;
  statuteOfLimitations?: Maybe<Scalars['Date']>;
  teamMembers?: Maybe<Array<MatterTeamMemberInput>>;
  billable?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
};

export type UpdateMatterTypeInput = {
  id: Scalars['ID'];
  type: MatterTypes;
};

export type UpdateProfileInput = {
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderTypes>;
  title?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  additionalEmails?: Maybe<Array<Scalars['String']>>;
  timezone?: Maybe<Scalars['Float']>;
  hourlyRate?: Maybe<Scalars['Float']>;
  accessLevel?: Maybe<AccessLevels>;
  active?: Maybe<Scalars['Boolean']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licensedSince?: Maybe<Scalars['Date']>;
  licensedInStates?: Maybe<Array<UsaStates>>;
  practiceAreas?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Scalars['Boolean']>;
  phoneNumbers?: Maybe<Array<PhoneNumberInput>>;
  addresses?: Maybe<Array<AddressInput>>;
  website?: Maybe<Scalars['String']>;
  picture?: Maybe<PictureInput>;
};

export type UpdateReconciliationInput = {
  id: Scalars['ID'];
  transactions: Array<Scalars['String']>;
  statementBalance: Scalars['Float'];
  statementDate: Scalars['Date'];
};

export type UpdateRoundingRulesInput = {
  enabled: Scalars['Boolean'];
  rounding: RoundingRulesDirection;
  divider: Scalars['Int'];
};

export type UpdateSignatureTemplateInput = {
  id: Scalars['ID'];
};

export type UpdateSiteActivityInput = {
  isActive: Scalars['Boolean'];
};

export type UpdateSubExpenseCategoryInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  costType: SubExpenseCategoryCostTypes;
};

export type UpdateSubExpenseInput = {
  id: Scalars['ID'];
  contactId?: Maybe<Scalars['ID']>;
  matterId?: Maybe<Scalars['ID']>;
  billedById?: Maybe<Scalars['ID']>;
  amount?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['Date']>;
  categoryId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  billable?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
};

export type UpdateSyncOptionsInput = {
  driveId?: Maybe<Scalars['ID']>;
  driveName?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['ID']>;
  parentId?: Maybe<Scalars['ID']>;
  folderName?: Maybe<Scalars['String']>;
  syncType?: Maybe<FileStorageSyncTypes>;
  fileSyncActive?: Maybe<Scalars['Boolean']>;
  breadcrumbs?: Maybe<Array<Breadcrumb>>;
  allowNotUniqueName?: Maybe<Scalars['Boolean']>;
};

export type UpdateTeamMemberInput = {
  firstName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderTypes>;
  title?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  additionalEmails?: Maybe<Array<Scalars['String']>>;
  timezone?: Maybe<Scalars['Float']>;
  hourlyRate?: Maybe<Scalars['Float']>;
  accessLevel?: Maybe<AccessLevels>;
  active?: Maybe<Scalars['Boolean']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licensedSince?: Maybe<Scalars['Date']>;
  licensedInStates?: Maybe<Array<UsaStates>>;
  practiceAreas?: Maybe<Array<Scalars['String']>>;
  searchable?: Maybe<Scalars['Boolean']>;
  phoneNumbers?: Maybe<Array<PhoneNumberInput>>;
  addresses?: Maybe<Array<AddressInput>>;
  website?: Maybe<Scalars['String']>;
  picture?: Maybe<PictureInput>;
  id: Scalars['ID'];
  role?: Maybe<TeamMemberRoles>;
};

export type UpdateThirdPartyPayeesInput = {
  id: Scalars['ID'];
  payeeName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  primaryPhone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
};

export type UpdateTimeEntryInput = {
  id: Scalars['ID'];
  timerId?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['ID']>;
  matterId?: Maybe<Scalars['ID']>;
  billedById?: Maybe<Scalars['ID']>;
  activityId?: Maybe<Scalars['ID']>;
  billable: Scalars['Boolean'];
  durationSec?: Maybe<Scalars['Float']>;
  billingRate?: Maybe<Scalars['Float']>;
  date: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type UpdateTimerActivityInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateTimerInput = {
  id: Scalars['ID'];
  matterId?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<TimerStatusTypes>;
};

export type UpgradeModeInput = {
  strategy: UpgradeModeStrategy;
};

/** Possible strategies for mode upgrading */
export enum UpgradeModeStrategy {
  CLEAR_DATA = 'CLEAR_DATA',
  MIGRATE_DATA = 'MIGRATE_DATA',
}

export type UserType = {
  __typename: 'UserType';
  accessLevel?: Maybe<AccessLevels>;
  active?: Maybe<Scalars['Boolean']>;
  additionalEmails?: Maybe<Array<Scalars['String']>>;
  addresses?: Maybe<Array<AddressType>>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderTypes>;
  hourlyRate?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isOwner: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licensedInStates?: Maybe<Array<UsaStates>>;
  licensedSince?: Maybe<Scalars['Date']>;
  limitedAccessDayQty?: Maybe<Scalars['Int']>;
  middleName?: Maybe<Scalars['String']>;
  mode?: Maybe<Modes>;
  onboardStatus: OnboardStatuses;
  phoneNumbers?: Maybe<Array<PhoneNumberType>>;
  picture?: Maybe<PictureType>;
  practiceAreas?: Maybe<Array<Scalars['String']>>;
  privacy: Scalars['Boolean'];
  role: TeamMemberRoles;
  searchable?: Maybe<Scalars['Boolean']>;
  status: TeamMemberStatuses;
  timezone?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type UtmParamsInput = {
  campaign?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type VerifyCodeInput = {
  smsCode: Scalars['String'];
};

export type VerifyCredentialsInput = {
  password: Scalars['String'];
};

export type VoidInvoiceType = {
  __typename: 'VoidInvoiceType';
  id: Scalars['ID'];
  voidInvoiceText?: Maybe<Scalars['String']>;
};

export type WebUrlsType = {
  __typename: 'WebUrlsType';
  operatingMAF: Scalars['String'];
  trustMAF: Scalars['String'];
  selectPlan: Scalars['String'];
  success: Scalars['String'];
};

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ContactMatterType: [
      'ContactType',
      'MatterType',
      'ThirdPartyPayeeType',
      'PlainTextType',
    ],
  },
};
export default result;
