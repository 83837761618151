import { gql } from '@apollo/client';

import { AVATAR_FRAGMENT } from 'components/common/Avatar';

export const FETCH_PROFILE = gql`
  query FetchProfile {
    getProfile {
      id
      firstName
      lastName
      picture {
        ...Avatar
      }
    }
  }
  ${AVATAR_FRAGMENT}
`;

export const CREATE_CONTACT = gql`
  mutation CreateContact($input: CreateContactInput!) {
    createContact(createContactInput: $input) {
      id
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(updateContactInput: $input) {
      id
      name
      isEntity
      originatingAttorney {
        id
        firstName
        lastName
        picture {
          ...Avatar
        }
      }
      notes
      conflictsCheckCompleted
      w9FormSentToClient
      engagementHasBeenSigned
      taxable
      addresses {
        address1
        address2
        city
        state
        type
        zipCode
      }
      phoneNumbers {
        number
        type
      }
      firstName
      lastName
      middleName
      gender
      email
      website
      billingContacts {
        firstName
        lastName
        middleName
        email
        type
      }
    }
  }
  ${AVATAR_FRAGMENT}
`;

export const FETCH_CONTACT = gql`
  query FetchContact($id: ID!) {
    getContact(id: $id) {
      id
      name
      isEntity
      originatingAttorney {
        id
        firstName
        lastName
        picture {
          ...Avatar
        }
      }
      notes
      conflictsCheckCompleted
      w9FormSentToClient
      engagementHasBeenSigned
      taxable
      addresses {
        address1
        address2
        city
        state
        type
        zipCode
      }
      phoneNumbers {
        number
        type
        isPrimary
      }
      firstName
      lastName
      middleName
      gender
      email
      website
      billingContacts {
        firstName
        lastName
        middleName
        email
        type
      }
    }
  }
  ${AVATAR_FRAGMENT}
`;

export const IMPORT_CONTACTS = gql`
  mutation ImportContacts($vCardFiles: [Upload!]!) {
    summary: importContacts(vCardFiles: $vCardFiles) {
      processed
      added
      ignored
      duplicated
      invalid
    }
  }
`;
