import React from 'react';
import capitalize from 'lodash/capitalize';
import lowerCase from 'lodash/lowerCase';
import { useSubscription } from '@apollo/client';
import { history, traceError } from '@appclose/core';
import { Flex, Text4 } from '@appclose/ui';

import { BankAccountClasses } from '__generated__/globalTypes';
import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import notification from 'controllers/notification';
import { OPERATING_ACCOUNT_COMPLETED } from 'constants/routes';
import useCloseConfirm from 'hooks/useCloseConfirm';
import useBankAccountClasses from 'hooks/useBankAccountClasses';
import { I18n, useIntl } from 'i18n';

import {
  BankAccountUpdatedSubscription,
  BankAccountUpdatedSubscriptionVariables,
} from './__generated__/BankAccountAgreementModal.gql';
import { ON_BANK_ACCOUNT_UPDATED } from './BankAccountAgreementModal.gql';
import { BankAccountAgreementModalPropsType } from './BankAccountAgreementModal.types';

export default function BankAccountAgreementModal({
  onClose,
  accountClass,
}: BankAccountAgreementModalPropsType) {
  const { t } = useIntl();
  const { getBankAccountClassLabelWithAccount } = useBankAccountClasses();

  useSubscription<
    BankAccountUpdatedSubscription,
    BankAccountUpdatedSubscriptionVariables
  >(ON_BANK_ACCOUNT_UPDATED, {
    onSubscriptionData: async ({ subscriptionData }) => {
      try {
        const accountUpdateData = subscriptionData?.data;
        const { class: updatedAccountClass } =
          accountUpdateData?.bankAccountUpdated || {};

        if (updatedAccountClass === accountClass) {
          onClose();

          notification().entityCreated(
            t('modal.bankAccountAgreement.notification.linked', {
              bankAccountName: capitalize(bankAccountName),
            })
          );

          if (updatedAccountClass === BankAccountClasses.OPERATING) {
            history.push(OPERATING_ACCOUNT_COMPLETED);
          }
        }
      } catch (e: any) {
        traceError(e);
      }
    },
  });

  const { onConfirmClose } = useCloseConfirm({
    onClose,
    defaultShowConfirm: false,
  });
  const bankAccountName = lowerCase(
    getBankAccountClassLabelWithAccount(accountClass)
  );

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n
            id="modal.bankAccountAgreement.title"
            values={{ bankAccountName }}
          />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {accountClass === BankAccountClasses.TRUST && (
          <Flex direction="column" alignItems="center">
            <Text4 offset={{ bottom: 16 }}>
              <I18n id="modal.bankAccountAgreement.trust.note" />
            </Text4>
          </Flex>
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
