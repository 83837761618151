import React from 'react';
import { Button, ButtonGroup } from '@appclose/ui';
import {
  AmountFormField,
  dateManager,
  Form,
  FormGroup,
  InputField,
  TextAreaFormField,
  Fieldset,
} from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import Check from 'components/common/Check';
import { SERVER_DATE_FORMAT } from 'constants/date';
import { useIntl, I18n } from 'i18n';
import { MEMO_PURPOSE_FILED_LENGTH_LIMIT } from 'constants/fieldsLimitations';

import styles from '../../../../DisbursementModal.module.scss';
import OwedToOperating from '../../../OwedToOperating';
import { DisbursementToOperatingFormPropsType } from './DisbursementToOperatingForm.types';
import { DisbursementToContactFormSchema } from './DisbursementToOperatingForm.schema';
import OtosField from './components/OtosField';

export default function DisbursementToOperatingForm({
  initialValues,
  onSubmit,
  onCancel,
  onChange,
}: DisbursementToOperatingFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      validationSchema={DisbursementToContactFormSchema}
      onSubmit={onSubmit}
      onChange={onChange}
    >
      {({
        isSubmitting,
        values: { otos, amount, memo },
        setFieldValue,
        submitForm,
      }) => (
        <>
          <Fieldset
            title={t('modal.disbursement.form.operating.fieldset.payee.title')}
          >
            <FormGroup>
              <InputField
                disabled
                label="Pay to..."
                type="text"
                value={t('modal.disbursement.form.operating.fieldset.payee.to')}
              />
            </FormGroup>
            <FormGroup>
              <OwedToOperating />
            </FormGroup>
            <OtosField values={otos} defaultValues={initialValues.otos} />
          </Fieldset>
          <Fieldset
            title={t(
              'modal.disbursement.form.operating.fieldset.payment.title'
            )}
          >
            <FormGroup>
              <FormGrid>
                <AmountFormField
                  disabled
                  label={t(
                    'modal.disbursement.form.operating.fieldset.payment.amount'
                  )}
                  name="amount"
                />
              </FormGrid>
            </FormGroup>
            <FormGroup>
              <TextAreaFormField
                name="memo"
                label={t(
                  'modal.disbursement.form.operating.fieldset.payment.memo'
                )}
                maxLength={MEMO_PURPOSE_FILED_LENGTH_LIMIT}
              />
            </FormGroup>
          </Fieldset>
          <Check
            isPreview
            isToOperating
            date={dateManager().parse().format(SERVER_DATE_FORMAT)}
            amount={amount}
            memo={memo}
          />
          <ButtonGroup className={styles.buttons}>
            <Button onClick={onCancel}>
              <I18n id="modal.disbursement.form.cancel" />
            </Button>
            <Button skin="primary" type="submit" loading={isSubmitting}>
              <I18n id="modal.disbursement.form.submit" />
            </Button>
            <Button
              skin="primary"
              loading={isSubmitting}
              onClick={() => {
                setFieldValue('printMode', true, true);
                submitForm();
              }}
            >
              <I18n id="modal.disbursement.form.saveAndPrint" />
            </Button>
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}
