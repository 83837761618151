import React from 'react';
import { Grid, Text4, Caption3 } from '@appclose/ui';
import {
  useFormContext,
  FormGroup,
  Amount,
  CheckboxFormField,
  AmountFormField,
} from '@appclose/core';
import { BillingIcon, TimerIcon, ExpenseIcon } from '@appclose/icons';

import { sanitizeAmount } from 'controllers/amount';
import { I18n } from 'i18n';

import { InvoiceCreditMemoFormValuesType } from '../../InvoiceCreditMemoForm.types';
import { ExtendedDetailsPropsTypes } from './ExtendedDetails.types';
import styles from './ExtendedDetails.module.scss';

function ExtendedDetails({ memoDetalization }: ExtendedDetailsPropsTypes) {
  const {
    setValues,
    values,
  } = useFormContext<InvoiceCreditMemoFormValuesType>();

  const {
    enableFlatFees,
    enableTimeEntries,
    enableExpenses,
    flatFeeAmount = 0,
    timeEntryAmount = 0,
    expenseAmount = 0,
  } = values;

  const hasFlatFees = !!memoDetalization?.flatFeeAmount;
  const hasTimeEntries = !!memoDetalization?.timeEntryAmount;
  const hasExpenses = !!memoDetalization?.expenseAmount;

  const flatFeesTotal = sanitizeAmount(memoDetalization?.flatFeeAmount);
  const timeEntriesTotal = sanitizeAmount(memoDetalization?.timeEntryAmount);
  const expensesTotal = sanitizeAmount(memoDetalization?.expenseAmount);

  const gridProps = {
    className: styles.entryDetails,
    columns: '36px 24px auto 120px',
    align: 'center' as const,
    gap: [16] as [number],
  };

  return (
    <FormGroup>
      <Caption3 weight="light" color="secondary">
        <I18n id="modal.creditMemo.form.memoDetails.extended.note" />
      </Caption3>
      {hasFlatFees && (
        <div className={styles.entryRow}>
          <Grid {...gridProps}>
            <CheckboxFormField
              name="enableFlatFees"
              onChange={(e) => {
                if (!e.target.checked) {
                  setValues((values) => ({
                    ...values,
                    flatFeeAmount: 0,
                    creditAmount: timeEntryAmount + expenseAmount,
                  }));
                }
              }}
            />
            <BillingIcon />
            <Text4 weight="semibold">
              <I18n id="modal.creditMemo.form.memoDetails.extended.flatFees" />
            </Text4>
            <Amount strong value={flatFeesTotal} />
          </Grid>
          <AmountFormField
            fieldClassName={styles.amount}
            name="flatFeeAmount"
            disabled={!enableFlatFees}
            max={flatFeesTotal}
            onChange={(value) => {
              setValues(
                (values) => ({
                  ...values,
                  flatFeeAmount: value,
                  creditAmount: timeEntryAmount + expenseAmount + value,
                }),
                true
              );
            }}
          />
        </div>
      )}
      {hasTimeEntries && (
        <div className={styles.entryRow}>
          <Grid {...gridProps}>
            <CheckboxFormField
              name="enableTimeEntries"
              onChange={(e) => {
                if (!e.target.checked) {
                  setValues((values) => ({
                    ...values,
                    timeEntryAmount: 0,
                    creditAmount: flatFeeAmount + expenseAmount,
                  }));
                }
              }}
            />
            <TimerIcon />
            <Text4 weight="semibold">
              <I18n id="modal.creditMemo.form.memoDetails.extended.timeEntries" />
            </Text4>
            <Amount strong value={timeEntriesTotal} />
          </Grid>
          <AmountFormField
            fieldClassName={styles.amount}
            name="timeEntryAmount"
            disabled={!enableTimeEntries}
            max={timeEntriesTotal}
            onChange={(value) => {
              setValues(
                (values) => ({
                  ...values,
                  timeEntryAmount: value,
                  creditAmount: flatFeeAmount + expenseAmount + value,
                }),
                true
              );
            }}
          />
        </div>
      )}
      {hasExpenses && (
        <div className={styles.entryRow}>
          <Grid {...gridProps}>
            <CheckboxFormField
              name="enableExpenses"
              onChange={(e) => {
                if (!e.target.checked) {
                  setValues((values) => ({
                    ...values,
                    expenseAmount: 0,
                    creditAmount: timeEntryAmount + flatFeeAmount,
                  }));
                }
              }}
            />
            <ExpenseIcon />
            <Text4 weight="semibold">
              <I18n id="modal.creditMemo.form.memoDetails.extended.expenses" />
            </Text4>
            <Amount strong value={expensesTotal} />
          </Grid>
          <AmountFormField
            fieldClassName={styles.amount}
            name="expenseAmount"
            disabled={!enableExpenses}
            max={expensesTotal}
            onChange={(value) => {
              setValues(
                (values) => ({
                  ...values,
                  expenseAmount: value,
                  creditAmount: timeEntryAmount + flatFeeAmount + value,
                }),
                true
              );
            }}
          />
        </div>
      )}
    </FormGroup>
  );
}

export default ExtendedDetails;
