import { gql } from '@apollo/client';

export const FETCH_FLAT_FEE_MATTER = gql`
  query FetchFlatFeeMatter($matterId: ID!) {
    matter: getMatter(id: $matterId) {
      id
      agreedFlatFee
      billable
    }
  }
`;

export const FETCH_MATTER_FLAT_FEES = gql`
  query FetchMatterFlatFees($matterId: ID!) {
    getLimitedFlatFeeMatterStat(matterId: $matterId) {
      flatFeesSum
    }
  }
`;

export const UPDATE_MATTER_FLAT_FEE_AMOUNT = gql`
  mutation UpdateMatterFlatFeeAmount($id: ID!, $agreedFlatFee: Float!) {
    updateMatter(
      updateMatterInput: { id: $id, agreedFlatFee: $agreedFlatFee }
    ) {
      id
      agreedFlatFee
    }
  }
`;
