import { gql } from '@apollo/client';

import { INVOICE_ACTIVITIES_FRAGMENT } from './components/InvoiceActivitiesTable';

export const INFO_FRAGMENT = gql`
  fragment Info on InvoiceType {
    ...InvoiceActivities
    id
    invoiceNumber
    destinationAccount
    dueDate
    message
    internalMemo
    requestedAmount
    originalAmount
    discount
    percentageDiscount
    status
    contact {
      id
      name
    }
    matters {
      id
      name
    }
    scheduledPayments {
      planApproved
      plan {
        amount
        paymentDate
        status
      }
    }
    qboSyncState
  }
  ${INVOICE_ACTIVITIES_FRAGMENT}
`;

export const CANCEL_PAYMENT_PLAN = gql`
  mutation CancelPaymentPlan($id: ID!) {
    cancelInvoiceSchedule(cancelInvoiceScheduleInput: { id: $id }) {
      id
      scheduledPayments {
        planApproved
        plan {
          amount
          paymentDate
          status
        }
      }
    }
  }
`;
