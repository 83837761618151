import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import { TableBody, TableRow, TableCell, Checkbox } from '@appclose/ui';
import { Amount } from '@appclose/core';
import { ArrowDownIcon } from '@appclose/icons';

import { TrustTransactionTypes } from '__generated__/globalTypes';
import TrustTransactionType from 'components/common/TrustTransactionType';
import Date from 'components/common/Date';
import LedgerName from 'components/common/LedgerName';
import DisbursementAmount from 'components/common/DisbursementAmount';
import PrivacyValue from 'components/common/PrivacyValue';

import { TransactionsTableRowPropsType } from './TransactionsTableRow.types';
import styles from './TransactionsTableRow.module.scss';

export default function TransactionsTableRow({
  transaction: { type, date, ledger, payeeOrSource, amount, status, children },
  isSelected,
  onClick,
}: TransactionsTableRowPropsType) {
  const [isExpanded, setExpanded] = useState(false);
  const isExpandable = Boolean(children?.length);
  const isDeposit = type === TrustTransactionTypes.DEPOSIT;
  const handleExpandClick = useCallback(
    (e) => {
      setExpanded(!isExpanded);
      e.preventDefault();
      e.stopPropagation();
    },
    [setExpanded, isExpanded]
  );

  return (
    <TableBody className={classnames({ [styles.expanded]: isExpanded })}>
      <TableRow
        className={classnames({ [styles.mainRow]: isExpanded })}
        onClick={onClick}
      >
        <TableCell>
          <Checkbox readOnly checked={isSelected} />
        </TableCell>
        <TableCell noWordBreak>
          <TrustTransactionType type={type} />
        </TableCell>
        <TableCell noWordBreak theme="light">
          <Date value={date} />
        </TableCell>
        <TableCell>
          <PrivacyValue>
            <LedgerName value={ledger} />
          </PrivacyValue>
        </TableCell>
        <TableCell>
          <PrivacyValue>
            <LedgerName value={payeeOrSource} />
          </PrivacyValue>
        </TableCell>
        <TableCell noWordBreak>
          {isDeposit && (
            <PrivacyValue>
              <Amount strong value={amount} />
            </PrivacyValue>
          )}
        </TableCell>
        <TableCell noWordBreak theme="strong">
          {!isDeposit && (
            <PrivacyValue>
              <DisbursementAmount status={status} amount={amount} />
            </PrivacyValue>
          )}
        </TableCell>
        <TableCell className={styles.expandCell} onClick={handleExpandClick}>
          {isExpandable && (
            <ArrowDownIcon
              className={classnames({
                [styles.notExpandedIcon]: !isExpanded,
              })}
            />
          )}
        </TableCell>
      </TableRow>
      <>
        {isExpandable &&
          isExpanded &&
          children?.length &&
          children.map(({ id, ledger, payeeOrSource, amount }, index) => (
            <TableRow
              key={id}
              className={classnames(styles.additionalRow, {
                [styles.lastRow]: index + 1 === children.length,
              })}
              onClick={onClick}
            >
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell>
                <PrivacyValue>
                  <LedgerName value={ledger} />
                </PrivacyValue>
              </TableCell>
              <TableCell>
                <PrivacyValue>
                  <LedgerName value={payeeOrSource} />
                </PrivacyValue>
              </TableCell>
              <TableCell>
                {isDeposit && (
                  <PrivacyValue>
                    <Amount strong value={amount} />
                  </PrivacyValue>
                )}
              </TableCell>
              <TableCell theme="strong">
                {!isDeposit && (
                  <PrivacyValue>
                    <DisbursementAmount status={status} amount={amount} />
                  </PrivacyValue>
                )}
              </TableCell>
              <TableCell />
            </TableRow>
          ))}
      </>
    </TableBody>
  );
}
