import React, { useCallback, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { useMutation } from '@apollo/client';
import { FormDataType } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import Wizard, { WizardCompleted, WizardStep } from 'components/common/Wizard';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { I18n, useIntl } from 'i18n';

import {
  SetInitialLedgerBalancesMutation,
  SetInitialLedgerBalancesMutationVariables,
} from './__generated__/TrustSetupBalancesStepModal.gql';
import ContactsAndMattersStep from './components/ContactsAndMattersStep';
import FinalizingVerificationStep from './components/FinalizingVerificationStep';
import InitialDateStep from './components/InitialDateStep';
import TrustSetupBalancesResult from './components/TrustSetupBalancesResult';
import { SET_INITIAL_LEDGER_BALANCES } from './TrustSetupBalancesStepModal.gql';
import {
  SetupInitialBalancesType,
  TrustSetupBalancesStepModalPropsType,
} from './TrustSetupBalancesStepModal.types';

export default function TrustSetupBalancesStepModal({
  onClose,
}: TrustSetupBalancesStepModalPropsType) {
  const { t } = useIntl();
  const initialData = useMemo<SetupInitialBalancesType>(
    () => ({
      statementDate: '',
      bankStatementEndingBalance: 0,
      ledgerBalances: [],
    }),
    []
  );
  const [initialBalances, setInitialBalances] = useState<
    SetupInitialBalancesType
  >(initialData);

  const [setInitialLedgerBalances, { loading }] = useMutation<
    SetInitialLedgerBalancesMutation,
    SetInitialLedgerBalancesMutationVariables
  >(SET_INITIAL_LEDGER_BALANCES);

  const handleOnSetupInitialBalances = useCallback(
    (value: SetupInitialBalancesType) => {
      setInitialBalances({ ...initialBalances, ...value });
    },
    [initialBalances]
  );

  const { onConfirmClose, setShowConfirm } = useCloseConfirm({
    onClose,
  });

  const handleOnComplete = useCallback(
    async (values: SetupInitialBalancesType) => {
      handleOnSetupInitialBalances(values);

      const initialBalances: SetInitialLedgerBalancesMutationVariables['initialBalances'] = {
        statementDate: values.statementDate,
        endingBalance: values.bankStatementEndingBalance,
        ledgerBalances: values?.ledgerBalances?.map((item) => ({
          balance: item.balance,
          contactId: item.contact.id,
          matterId: item.matter?.id,
          unclearedTransactions: item.unclearedTransactions,
        })),
      };

      return setInitialLedgerBalances({
        variables: {
          initialBalances,
        },
      });
    },
    [handleOnSetupInitialBalances, setInitialLedgerBalances]
  );

  const handleOnChange = useCallback(
    ({ values }: FormDataType<any>) => {
      setShowConfirm(!isEqual(initialData, values));
    },
    [initialData, setShowConfirm]
  );

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.trustSetupBalancesStep.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        <Wizard>
          <WizardStep
            title={t('modal.trustSetupBalancesStep.wizard.step.initialDate')}
          >
            <InitialDateStep
              initialValues={initialBalances}
              onCancel={onConfirmClose}
              onChange={handleOnChange}
              onSetStatementDate={handleOnSetupInitialBalances}
            />
          </WizardStep>
          <WizardStep
            title={t(
              'modal.trustSetupBalancesStep.wizard.step.contactsMatters'
            )}
          >
            <ContactsAndMattersStep
              initialValues={initialBalances}
              onSetTransactions={handleOnSetupInitialBalances}
              onChange={handleOnChange}
            />
          </WizardStep>
          <WizardStep
            title={t('modal.trustSetupBalancesStep.wizard.step.verification')}
          >
            <FinalizingVerificationStep
              initialValues={initialBalances}
              onFinalizingVerification={handleOnComplete}
              onChange={handleOnChange}
              loading={loading}
            />
          </WizardStep>
          <WizardCompleted>
            <TrustSetupBalancesResult onClose={onClose} />
          </WizardCompleted>
        </Wizard>
      </ModalPageContent>
    </ModalPage>
  );
}
