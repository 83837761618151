import { history, permissionProvider } from '@appclose/core';
import { getTokenPayload } from '@appclose/lib';

import { isPlusPlan } from '../mode';
import { session } from '../session';
import { EsqPermissionType } from './permission.types';

export function initPermissionProvider() {
  permissionProvider(() => {
    const params = new URLSearchParams(history.location.search);
    const token = params.get('token') || session.getAccessToken();

    if (!token) {
      return {};
    }

    const tokenPayload = getTokenPayload(token);

    return tokenPayload.esq || {};
  });
}

export function checkEsqPermission({
  plusPlan = false,
  resource,
  action,
  permissions,
}: EsqPermissionType): boolean {
  return (
    (plusPlan ? isPlusPlan() : true) &&
    (resource && action
      ? permissionProvider().hasPermission(resource, action)
      : true) &&
    (permissions ? permissionProvider().hasPermissions(permissions) : true)
  );
}
