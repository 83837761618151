import React, { useEffect } from 'react';
import { Loader, StaticField, useFormContext } from '@appclose/core';
import { useLazyQuery } from '@apollo/client';
import { Flex, Note } from '@appclose/ui';

import { I18n, useIntl } from 'i18n';
import useProfile from 'hooks/useProfile';
import { GET_ROUNDING_RULES } from 'components/pages/ProfilePage/components/RoundingRulesPage/RoundingRulesPage.gql';
import {
  GetRoundingRulesQuery,
  GetRoundingRulesQueryVariables,
} from 'components/pages/ProfilePage/components/RoundingRulesPage/__generated__/RoundingRulesPage.gql';
import {
  getFormattedDuration,
  getRoundedDuration,
} from './RoundedDurationField.utils';
import { TimeEntryFormValuesType } from '../../TimeEntryForm.types';

export default function RoundedDurationField() {
  const { t } = useIntl();
  const { profile } = useProfile();
  const {
    values: { billedBy, durationSec = 0 },
    setFieldValue,
  } = useFormContext<TimeEntryFormValuesType>();

  const [getRoundingRules, { data, loading }] = useLazyQuery<
    GetRoundingRulesQuery,
    GetRoundingRulesQueryVariables
  >(GET_ROUNDING_RULES, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (billedBy) {
      getRoundingRules({
        variables: {
          billedById: billedBy.id,
        },
      });
    }
  }, [billedBy, getRoundingRules]);

  useEffect(() => {
    if (data?.getRoundingRules.enabled && durationSec) {
      setFieldValue(
        'roundedDuration',
        getRoundedDuration(data.getRoundingRules, durationSec)
      );
    } else if (data?.getRoundingRules.enabled === false) {
      setFieldValue('roundedDuration', undefined);
    }
  }, [durationSec, data, setFieldValue]);

  if (loading) {
    return <Loader />;
  }

  if (!billedBy || !data?.getRoundingRules.enabled) {
    return null;
  }

  const { rounding, divider } = data.getRoundingRules;
  const username =
    profile!.id === billedBy.id
      ? 'your'
      : `${billedBy.firstName} ${billedBy.lastName}'s`;
  const roundedDuration = getRoundedDuration(
    data.getRoundingRules,
    durationSec
  );

  return (
    <Flex direction="column">
      <StaticField
        title="Rounded duration"
        tooltip={t('page.timeEntries.hint')}
      >
        {getFormattedDuration(roundedDuration)}
      </StaticField>
      <Note theme="important" offset={{ top: 12 }}>
        <I18n
          id="page.timeEntries.roundedMessage"
          values={{ divider, username, rounding: rounding.toLowerCase() }}
        />
      </Note>
    </Flex>
  );
}
