import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Text3 } from '@appclose/ui';
import { dateManager, openConfirmAction } from '@appclose/core';

import { SERVER_DATE_FORMAT } from 'constants/date';
import { RECONCILIATION_DETAILS_MODAL } from 'constants/modals';
import { openModal } from 'controllers/modal';
import { useIntl } from 'i18n';

import {
  FetchLastReconciliationQuery,
  FetchLastReconciliationQueryVariables,
} from './__generated__/useCanCreateTrustTransaction.gql';
import { FETCH_LAST_RECONCILIATION } from './useCanCreateTrustTransaction.gql';

export default function useCanCreateTrustTransaction(
  transactionType: 'deposit' | 'disbursement',
  closeCurrentModal: () => void
) {
  const { t } = useIntl();
  const dispatch = useDispatch();
  const { data, loading: loadingLastReconciliation } = useQuery<
    FetchLastReconciliationQuery,
    FetchLastReconciliationQueryVariables
  >(FETCH_LAST_RECONCILIATION);

  const lastStatementDate = useMemo(
    () => data?.reconciliation.items[0]?.statementDate,
    [data]
  );

  const lastReconciliationId = useMemo(
    () => data?.reconciliation.items[0]?.id,
    [data]
  );

  const getCanCreateTrustTransaction = useCallback(
    (date: string) => {
      if (!lastStatementDate) {
        return true;
      }

      const parsedDate = dateManager().parse(date, SERVER_DATE_FORMAT);

      const lastStatement = dateManager()
        .parse(lastStatementDate, SERVER_DATE_FORMAT)
        .endOf('day');

      return parsedDate.isAfter(lastStatement);
    },
    [lastStatementDate]
  );

  const openUnableToCreateTrustTransactionPopup = useCallback(() => {
    dispatch(
      openConfirmAction({
        name: t('trustTransaction.confirm.cantCreate.name', {
          transactionType,
        }),
        content: (
          <Text3 offset={{ left: 24, right: 24 }}>
            {t('trustTransaction.confirm.cantCreate.content', {
              transactionType,
            })}
          </Text3>
        ),
        okButtonTitle: t('trustTransaction.confirm.cantCreate.ok'),
        okButtonSkin: 'primary',
        onConfirm() {
          closeCurrentModal();

          openModal(RECONCILIATION_DETAILS_MODAL, {
            id: lastReconciliationId,
          });
        },
        cancelButtonTitle: t('trustTransaction.confirm.cantCreate.cancel'),
      })
    );
  }, [dispatch, t, lastReconciliationId, transactionType, closeCurrentModal]);

  return {
    loadingLastReconciliation,
    getCanCreateTrustTransaction,
    openUnableToCreateTrustTransactionPopup,
  };
}
