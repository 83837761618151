import React, { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { Flex } from '@appclose/ui';
import { Loader, Tooltip } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import Date from 'components/common/Date';
import { PermissionGuard } from 'components/common/PermissionGuard';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import Actions, { Action } from 'components/common/Actions';
import useReconciliationActions from 'hooks/useReconciliationActions';
import { I18n, useIntl } from 'i18n';

import {
  FetchReconciliationQuery,
  FetchReconciliationQueryVariables,
} from './__generated__/ReconciliationDetailsModal.gql';
import { FETCH_RECONCILIATION } from './ReconciliationDetailsModal.gql';
import { ReconciliationDetailsModalPropsType } from './ReconciliationDetailsModal.types';
import ReconciliationDetails from './components/ReconciliationDetails';
import styles from './ReconciliationDetailsModal.module.scss';

export default function ReconciliationDetailsModal({
  id,
  onClose,
}: ReconciliationDetailsModalPropsType) {
  const { t } = useIntl();
  const {
    onDownloadReconciliationDetailedPdf,
    onReconciliationDelete,
    pdfLoading,
  } = useReconciliationActions({ id });

  const handleReconciliationDelete = useCallback(() => {
    onReconciliationDelete();
    onClose();
  }, [onReconciliationDelete, onClose]);

  const { data, loading } = useQuery<
    FetchReconciliationQuery,
    FetchReconciliationQueryVariables
  >(FETCH_RECONCILIATION, {
    variables: { id },
    fetchPolicy: 'network-only',
  });

  const reconciliation = data?.reconciliation as FetchReconciliationQuery['reconciliation'];

  return (
    <ModalPage className={styles.modal} onClose={onClose}>
      <ModalPageHeader>
        <Flex alignItems="center" justify="space-between">
          <ModalPageTitle>
            <Flex alignItems="center">
              <span>
                <I18n id="modal.reconciliationDetails.title" />
              </span>
              {reconciliation?.statementDate && (
                <Date
                  className={styles.date}
                  value={reconciliation?.statementDate}
                />
              )}
            </Flex>
          </ModalPageTitle>
          <Actions>
            <PermissionGuard
              resource={PermissionResources.RECONCILIATION}
              action={PermissionActions.READ}
            >
              <Tooltip
                content={t(
                  'modal.reconciliationDetails.actions.download.tooltip'
                )}
              >
                <Action
                  type="download"
                  onClick={onDownloadReconciliationDetailedPdf}
                  loading={pdfLoading}
                />
              </Tooltip>
            </PermissionGuard>
            <PermissionGuard
              resource={PermissionResources.RECONCILIATION}
              action={PermissionActions.DELETE}
            >
              <Tooltip
                content={t(
                  'modal.reconciliationDetails.actions.delete.tooltip'
                )}
              >
                <Action type="delete" onClick={handleReconciliationDelete} />
              </Tooltip>
            </PermissionGuard>
          </Actions>
        </Flex>
      </ModalPageHeader>
      <ModalPageContent>
        {loading ? (
          <Loader />
        ) : (
          <ReconciliationDetails reconciliation={reconciliation} />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
