import { gql } from '@apollo/client';

import { AVATAR_FRAGMENT } from 'components/common/Avatar';

export const TIME_ENTRIES_FIELD_FRAGMENT = gql`
  fragment TimeEntriesField on TimeEntryType {
    id
    date
    status
    amount
    activity {
      id
      name
    }
    billedBy {
      id
      firstName
      lastName
      picture {
        ...Avatar
      }
    }
    matter {
      id
      name
    }
  }
  ${AVATAR_FRAGMENT}
`;

export const FETCH_TIME_ENTRIES = gql`
  query FetchTimeEntries($input: ListTimeEntriesInput!) {
    timeEntries: listTimeEntries(listTimeEntryInput: $input) {
      total
      items {
        ...TimeEntriesField
      }
    }
  }
  ${TIME_ENTRIES_FIELD_FRAGMENT}
`;

export const ON_TIME_ENTRY_CREATE = gql`
  subscription TimeEntryCreate($filter: TimeEntrySubscriptionInput!) {
    timeEntryCreated(timeEntrySubscriptionInput: $filter) {
      id
    }
  }
`;

export const ON_TIME_ENTRY_UPDATE = gql`
  subscription TimeEntryUpdate($includeIds: [ID!]!) {
    timeEntryUpdated(timeEntrySubscriptionInput: { includeIds: $includeIds }) {
      ...TimeEntriesField
    }
  }
  ${TIME_ENTRIES_FIELD_FRAGMENT}
`;

export const ON_TIME_ENTRY_REMOVE = gql`
  subscription TimeEntryRemove($includeIds: [ID!]!) {
    timeEntryRemoved(
      removedEntitySubscriptionInput: { includeIds: $includeIds }
    ) {
      id
    }
  }
`;
