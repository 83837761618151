import React from 'react';
import { Button, ButtonGroup } from '@appclose/ui';
import {
  Amount,
  AmountFormField,
  Form,
  FormGroup,
  StaticField,
  ToggleFormField,
  Fieldset,
} from '@appclose/core';

import {
  BankAccountClasses,
  InvoiceHistoryTypes,
} from '__generated__/globalTypes';
import FormGrid from 'components/common/FormGrid';
import Date from 'components/common/Date';
import AdditionalInfoFieldset from 'components/form/AdditionalInfoFieldset';
import { sanitizeAmount } from 'controllers/amount';
import { isPlusPlan } from 'controllers/mode';
import { I18n, useIntl } from 'i18n';
import { Entities } from 'constants/entities';

import ExtendedDetails from './components/ExtendedDetails';
import { InvoiceCreditMemoFormPropsType } from './InvoiceCreditMemoForm.types';
import { InvoiceCreditMemoFormFormSchema } from './InvoiceCreditMemoForm.schema';
import styles from './InvoiceCreditMemoForm.module.scss';

export default function InvoiceCreditMemoForm({
  initialValues,
  onSubmit,
  onCancel,
  onChange,
}: InvoiceCreditMemoFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      onChange={onChange}
      validationSchema={InvoiceCreditMemoFormFormSchema}
    >
      {({ isSubmitting, values: { invoice, creditAmount } }) => {
        const balanceDue = sanitizeAmount(invoice?.balanceDue);
        const paidAmount = sanitizeAmount(invoice?.paidAmount);
        const requestedAmount = sanitizeAmount(invoice?.requestedAmount);
        const unpaidBalance = balanceDue - creditAmount;
        const memoDetalization = invoice?.allowedMemoDetalization;
        const showExpandedDetails =
          invoice.destinationAccount !== BankAccountClasses.TRUST &&
          isPlusPlan();

        return (
          <>
            <Fieldset title={t('modal.creditMemo.form.invoiceDetails.title')}>
              <FormGroup>
                <FormGrid>
                  <StaticField
                    title={t('modal.creditMemo.form.invoiceDetails.contact')}
                  >
                    <span>{invoice?.contact.name}</span>
                  </StaticField>
                  <StaticField
                    title={t('modal.creditMemo.form.invoiceDetails.date')}
                  >
                    <Date
                      value={
                        invoice?.history.find(
                          ({ status }) => status === InvoiceHistoryTypes.SENT
                        )?.date
                      }
                    />
                  </StaticField>
                  <StaticField
                    title={t(
                      'modal.creditMemo.form.invoiceDetails.invoiceNumber'
                    )}
                  >
                    {invoice?.invoiceNumber}
                  </StaticField>
                </FormGrid>
              </FormGroup>
              <FormGroup>
                <FormGrid>
                  <StaticField
                    title={t(
                      'modal.creditMemo.form.invoiceDetails.requestedAmount'
                    )}
                  >
                    <Amount value={requestedAmount} />
                  </StaticField>
                  <StaticField
                    title={t('modal.creditMemo.form.invoiceDetails.paidAmount')}
                  >
                    <Amount value={paidAmount} />
                  </StaticField>
                  <StaticField
                    title={t('modal.creditMemo.form.invoiceDetails.balanceDue')}
                  >
                    <Amount value={balanceDue} />
                  </StaticField>
                </FormGrid>
              </FormGroup>
            </Fieldset>
            <Fieldset title={t('modal.creditMemo.form.memoDetails.title')}>
              {showExpandedDetails && (
                <ExtendedDetails memoDetalization={memoDetalization} />
              )}
              <FormGroup>
                <FormGrid>
                  <AmountFormField
                    name="creditAmount"
                    label={
                      isPlusPlan()
                        ? t(
                            'modal.creditMemo.form.memoDetails.creditAmount.plus'
                          )
                        : t(
                            'modal.creditMemo.form.memoDetails.creditAmount.free'
                          )
                    }
                    disabled={showExpandedDetails}
                    tooltip={t(
                      'modal.creditMemo.form.memoDetails.creditAmount.tooltip'
                    )}
                  />
                  <StaticField
                    title={t('modal.creditMemo.form.memoDetails.unpaidBalance')}
                  >
                    <Amount value={unpaidBalance} />
                  </StaticField>
                </FormGrid>
              </FormGroup>
            </Fieldset>
            <AdditionalInfoFieldset
              entityType={Entities.PAYMENT}
              className={styles.additionalInfo}
              notesFieldName="memoText"
            />
            <FormGroup className={styles.sendEmailToggle}>
              <ToggleFormField
                name="sendEmail"
                label={t('modal.creditMemo.form.sendEmail.toggle')}
              />
            </FormGroup>
            <ButtonGroup>
              <Button onClick={onCancel}>
                <I18n id="modal.creditMemo.form.cancel" />
              </Button>
              <Button loading={isSubmitting} type="submit" skin="primary">
                <I18n id="modal.creditMemo.form.submit" />
              </Button>
            </ButtonGroup>
          </>
        );
      }}
    </Form>
  );
}
