import * as Yup from 'yup';

import { FinalizingVerificationStepFormValuesType } from './FinalizingVerificationStep.types';

export const FinalizingVerificationStepSchema = Yup.object().shape<
  FinalizingVerificationStepFormValuesType
>({
  bankStatementEndingBalance: Yup.number().required(
    'Please enter a bank statement ending balance'
  ),
  ledgerBalances: Yup.array<any>(),
  statementDate: Yup.string().required(),
});
