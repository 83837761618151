import { gql } from '@apollo/client';

export const FETCH_DISBURSEMENT_PDF = gql`
  query fetchDisbursementPdf($id: ID!) {
    pdf: getDisbursementPDF(id: $id) {
      data
      mimetype
      name
    }
  }
`;
