import React, { useEffect, useMemo, useState } from 'react';
import {
  FormGroup,
  InputFormField,
  USAStateSelectFormField,
  USAZipCodeFormField,
  SelectFormField,
  useFormContext,
  MediaQueryDesktop,
  MediaQueryMobile,
  UpperFirstInputFormField,
} from '@appclose/core';

import FormGrid from 'components/common/FormGrid/FormGrid';
import FieldsetFormArray from 'components/form/FieldsetFormArray';
import useAddressTypes from 'hooks/useAddressTypes';
import { useIntl } from 'i18n';

import { INITIAL_ADDRESS } from 'constants/address';
import { AddressTypes } from '__generated__/globalTypes';
import { AddressesFieldsetPropsType } from './AddressesFieldset.types';

export default function AddressesFieldset({
  name = 'addresses',
  title,
  allowRemoveLast = false,
  limit = 5,
}: AddressesFieldsetPropsType) {
  const { t } = useIntl();
  const fieldsetTitle = title || t('form.addressesFieldset.defaultTitle');
  const { addressTypesOptions } = useAddressTypes();
  const { getFieldMeta } = useFormContext();
  const { value } = getFieldMeta<typeof INITIAL_ADDRESS[]>(name);
  const primary = value?.find(({ type }) => type === AddressTypes.PRIMARY);
  const [hasPrimary, setHasPrimary] = useState(!!primary);

  useEffect(() => {
    setHasPrimary(!!primary);
  }, [primary]);

  const initialData = useMemo(
    () =>
      !hasPrimary
        ? INITIAL_ADDRESS
        : { ...INITIAL_ADDRESS, type: AddressTypes.SECONDARY },
    [hasPrimary]
  );

  const addressTypesOptionsWithoutPrimary = useMemo(
    () =>
      addressTypesOptions.filter(({ value }) => value !== AddressTypes.PRIMARY),
    [addressTypesOptions]
  );

  return (
    <FieldsetFormArray
      title={fieldsetTitle}
      name={name}
      addButtonLabel={t('form.addressesFieldset.addButtonLabel')}
      allowRemoveLast={allowRemoveLast}
      initialData={initialData}
      limit={limit}
    >
      {({ resolveName, value }) => (
        <FormGroup>
          <MediaQueryDesktop>
            <FormGrid>
              <SelectFormField
                name={resolveName('type')}
                label={t('form.addressesFieldset.type')}
                options={
                  !primary || value === primary
                    ? addressTypesOptions
                    : addressTypesOptionsWithoutPrimary
                }
              />
              <InputFormField
                name={resolveName('city')}
                label={t('form.addressesFieldset.city')}
              />
              <UpperFirstInputFormField
                name={resolveName('address1')}
                label={t('form.addressesFieldset.address1')}
              />
              <USAStateSelectFormField
                showPlaceholder="onFocus"
                name={resolveName('state')}
              />
              <InputFormField
                name={resolveName('address2')}
                label={t('form.addressesFieldset.address2')}
              />
              <USAZipCodeFormField name={resolveName('zipCode')} />
            </FormGrid>
          </MediaQueryDesktop>
          <MediaQueryMobile>
            <FormGrid>
              <SelectFormField
                name={resolveName('type')}
                label={t('form.addressesFieldset.type')}
                options={
                  !primary || value === primary
                    ? addressTypesOptions
                    : addressTypesOptionsWithoutPrimary
                }
              />
              <UpperFirstInputFormField
                name={resolveName('address1')}
                label={t('form.addressesFieldset.address1')}
              />
              <InputFormField
                name={resolveName('address2')}
                label={t('form.addressesFieldset.address2')}
              />
              <InputFormField
                name={resolveName('city')}
                label={t('form.addressesFieldset.city')}
              />
              <USAStateSelectFormField
                showPlaceholder="onFocus"
                name={resolveName('state')}
              />
              <USAZipCodeFormField name={resolveName('zipCode')} />
            </FormGrid>
          </MediaQueryMobile>
        </FormGroup>
      )}
    </FieldsetFormArray>
  );
}
