import React, { useCallback, useEffect, useMemo } from 'react';
import set from 'lodash/set';
import debounce from 'lodash/debounce';
import { useLazyQuery } from '@apollo/client';
import { useFormContext } from '@appclose/core';

import ReconciliationSummary from 'components/common/ReconciliationSummary';

import { ReconciliationFormValuesType } from '../../ReconciliationForm.types';
import {
  CalculateSummaryQuery,
  CalculateSummaryQueryVariables,
} from './__generated__/Summary.gql';
import { CALCULATE_SUMMARY } from './Summary.gql';

export default function Summary() {
  const {
    setValues,
    values: { transactions, statementBalance, statementDate },
    initialValues: { statementDate: initialStatementDate },
  } = useFormContext<ReconciliationFormValuesType>();

  const [calculateSummary, { loading, data }] = useLazyQuery<
    CalculateSummaryQuery,
    CalculateSummaryQueryVariables
  >(CALCULATE_SUMMARY, { fetchPolicy: 'network-only' });

  const debouncedCalculateSummary = useMemo(
    () => debounce(calculateSummary, 1000),
    [calculateSummary]
  );

  const transactionsIds = useMemo(() => transactions.map(({ id }) => id), [
    transactions,
  ]);

  const recalculateSummary = useCallback(
    () =>
      debouncedCalculateSummary({
        variables: {
          input: {
            date: statementDate || initialStatementDate,
            statementBalance,
            transactions: transactionsIds,
          },
        },
      }),
    [
      debouncedCalculateSummary,
      initialStatementDate,
      statementBalance,
      statementDate,
      transactionsIds,
    ]
  );

  useEffect(() => {
    recalculateSummary();
  }, [recalculateSummary]);

  const summary = data?.summary || {};

  const { canBeCompleted } = summary as CalculateSummaryQuery['summary'];

  useEffect(() => {
    setValues((values: any) =>
      set(values, 'canCompleteReconciliation', canBeCompleted)
    );
  }, [canBeCompleted, setValues]);

  return <ReconciliationSummary loading={loading} summary={data?.summary} />;
}
