import React from 'react';
import {
  FormGroup,
  InputFormField,
  UpperFirstInputFormField,
} from '@appclose/core';

import { useIntl } from 'i18n';

export default function ContactFieldset() {
  const { t } = useIntl();

  return (
    <>
      <FormGroup>
        <UpperFirstInputFormField
          name="contact.contact.firstName"
          label={t('modal.popup.contactMatter.form.contact.firstName')}
        />
      </FormGroup>
      <FormGroup>
        <UpperFirstInputFormField
          name="contact.contact.lastName"
          label={t('modal.popup.contactMatter.form.contact.lastName')}
        />
      </FormGroup>
      <FormGroup>
        <InputFormField
          name="contact.contact.email"
          label={t('modal.popup.contactMatter.form.contact.email')}
        />
      </FormGroup>
    </>
  );
}
