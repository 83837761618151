import React from 'react';
import set from 'lodash/set';
import { Button, ButtonGroup, Note } from '@appclose/ui';
import {
  AmountFormField,
  ExternalLink,
  Form,
  FormGroup,
  InputFormField,
  UpperFirstInputFormField,
  Fieldset,
} from '@appclose/core';

import { useIntl, I18n } from 'i18n';
import FormGrid from 'components/common/FormGrid';
import RoleSelectFormField from 'components/fields/RoleSelectFormField';
import AccessLevelSelectFormField from 'components/fields/AccessLevelSelectFormField';
import ProfileFieldset from 'components/form/ProfileFieldset';
import ModeGuard from 'components/common/ModeGuard';
import { AccessLevels, Modes } from '__generated__/globalTypes';

import { TeamMemberFormPropsType } from './TeamMemberForm.types';
import {
  TeamMemberBaseFormSchema,
  TeamMemberExtendedFormSchema,
} from './TeamMemberForm.schema';
import { HELP_ROLES_AND_ACCESS_LEVELS_URL } from 'constants/env';

export default function TeamMemberForm({
  initialValues,
  isEdit,
  isExtended,
  canSetAccessLevel,
  onSubmit,
  onCancel,
  onChange,
}: TeamMemberFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={
        isExtended ? TeamMemberExtendedFormSchema : TeamMemberBaseFormSchema
      }
      onChange={onChange}
    >
      {(form) => {
        const { values, setValues } = form;
        const { accessLevel } = values;

        return (
          <>
            {isExtended ? (
              <ProfileFieldset {...form} />
            ) : (
              <Fieldset title={t('modal.teamMember.form.personalInfo.title')}>
                <FormGroup>
                  <FormGrid>
                    <UpperFirstInputFormField
                      name="firstName"
                      label={t('modal.teamMember.form.personalInfo.firstName')}
                    />
                    <UpperFirstInputFormField
                      name="lastName"
                      label={t('modal.teamMember.form.personalInfo.lastName')}
                    />
                  </FormGrid>
                </FormGroup>
                <FormGroup>
                  <InputFormField
                    name="email"
                    label={t('modal.teamMember.form.personalInfo.email')}
                    disabled={isEdit}
                  />
                </FormGroup>
              </Fieldset>
            )}
            <Fieldset title={t('modal.teamMember.form.roleAndAccess.title')}>
              <ModeGuard mode={Modes.PLUS}>
                <Note theme="important-small" offset={{ bottom: 20 }}>
                  <I18n
                    id="modal.teamMember.form.roleAndAccess.tooltip"
                    values={{
                      a: (name: string) => (
                        <ExternalLink link={HELP_ROLES_AND_ACCESS_LEVELS_URL}>
                          {name}
                        </ExternalLink>
                      ),
                    }}
                  />
                </Note>
              </ModeGuard>
              <FormGroup>
                <FormGrid>
                  <RoleSelectFormField />
                  <ModeGuard mode={Modes.PLUS}>
                    <AccessLevelSelectFormField
                      disabled={!canSetAccessLevel}
                      onChange={(accessLevel) => {
                        if (accessLevel === AccessLevels.READ_ONLY_USER) {
                          setValues(set(values, 'hourlyRate', null));
                        }
                      }}
                    />
                    {accessLevel !== AccessLevels.READ_ONLY_USER && (
                      <AmountFormField
                        name="hourlyRate"
                        label={t(
                          'modal.teamMember.form.roleAndAccess.hourlyRate'
                        )}
                      />
                    )}
                  </ModeGuard>
                </FormGrid>
              </FormGroup>
            </Fieldset>
            <ButtonGroup>
              <Button onClick={onCancel}>
                <I18n id="modal.teamMember.form.cancel" />
              </Button>
              <Button loading={form.isSubmitting} type="submit" skin="primary">
                {isEdit ? (
                  <I18n id="modal.teamMember.form.update.submit" />
                ) : (
                  <I18n id="modal.teamMember.form.create.submit" />
                )}
              </Button>
            </ButtonGroup>
          </>
        );
      }}
    </Form>
  );
}
