import { gql } from '@apollo/client';

import { INVOICE_INFO_FRAGMENT } from 'components/common/InvoiceInfo';

export const FETCH_CREDIT_MEMO = gql`
  query FetchCreditMemo($id: ID!) {
    creditMemo: getPayment(id: $id) {
      id
      contact {
        id
        name
      }
      amount
      bankAccount {
        id
        class
      }
      status
      paymentMethod
      createdAt
      memoNumber
      memoText
      memoInvoice {
        ...InvoiceInfo
      }
      memoDetalization {
        timeEntryAmount
        expenseAmount
        flatFeeAmount
      }
      qboSyncState
    }
  }
  ${INVOICE_INFO_FRAGMENT}
`;
