import React, { useCallback } from 'react';
import set from 'lodash/set';
import { Text4 } from '@appclose/ui';
import { FormGroup, useFormContext, Fieldset } from '@appclose/core';

import { BankAccountClasses, Modes } from '__generated__/globalTypes';
import { isPlusPlan } from 'controllers/mode';
import FormGrid from 'components/common/FormGrid';
import ModeGuard from 'components/common/ModeGuard/ModeGuard';
import ContactSelectFormField from 'components/fields/ContactSelectFormField';
import { SelectMatterType } from 'components/fields/MatterSelectFormField';
import MatterSelectFormField from 'components/fields/MatterSelectFormField/MatterSelectFormField';
import ContactMatterSelectFormField from 'components/fields/ContactMatterSelectFormField';
import { I18n, useIntl } from 'i18n';

import { InvoiceFormValuesType } from '../../../../InvoiceForm.types';
import { ContactAndMatterFieldsetPropsType } from './ContactAndMatterFieldset.types';

export default function ContactAndMatterFieldset({
  isEdit,
  resetValues,
}: ContactAndMatterFieldsetPropsType) {
  const { t } = useIntl();
  const {
    values: { contact, destinationAccount },
    setValues,
  } = useFormContext<InvoiceFormValuesType>();

  const handleOnContactChange = useCallback(() => {
    setValues((values) => set(values, 'matters', undefined), true);
    resetValues();
  }, [resetValues, setValues]);

  const handleOnMatterChange = useCallback(
    (value?: SelectMatterType) => {
      setValues((values) => {
        let newValues = values;

        if (value && !contact) {
          newValues = set(
            newValues,
            'contact',
            ((value as unknown) as SelectMatterType[])?.[0]?.contact
          );
        }

        return newValues;
      }, true);

      resetValues();
    },
    [contact, resetValues, setValues]
  );

  return (
    <Fieldset
      title={
        isPlusPlan()
          ? t('modal.invoice.form.fieldset.contactMatter')
          : t('modal.invoice.form.fieldset.contact')
      }
    >
      <FormGroup>
        <ModeGuard mode={Modes.PLUS}>
          <>
            {destinationAccount === BankAccountClasses.OPERATING ? (
              <FormGrid>
                <ContactSelectFormField
                  disabled={isEdit}
                  onChange={handleOnContactChange}
                  allowAddNew
                />
                <MatterSelectFormField
                  isMulti
                  name="matters"
                  disabled={isEdit}
                  filter={{ clientId: contact?.id }}
                  onChange={handleOnMatterChange}
                  allowAddNew
                />
              </FormGrid>
            ) : (
              <ContactMatterSelectFormField
                allowAddNew
                label={t('modal.invoice.form.field.contactMatter.label')}
                disabled={isEdit}
                tooltip={
                  <>
                    <Text4>
                      <I18n id="modal.invoice.form.field.contactMatter.tooltip.paragraph.1" />
                    </Text4>
                    <Text4 offset={{ top: 10 }}>
                      <I18n id="modal.invoice.form.field.contactMatter.tooltip.paragraph.2" />
                    </Text4>
                  </>
                }
                showTrustLedgerBalance
              />
            )}
          </>
        </ModeGuard>
        <ModeGuard mode={Modes.FREE}>
          <FormGrid>
            <ContactSelectFormField
              disabled={isEdit}
              onChange={handleOnContactChange}
              allowAddNew
            />
          </FormGrid>
        </ModeGuard>
      </FormGroup>
    </Fieldset>
  );
}
