import React from 'react';
import { Button, ButtonGroup } from '@appclose/ui';
import { Form } from '@appclose/core';

import { useIntl } from 'i18n';
// import { I18n } from 'i18n';

import AccountFieldset from './components/AccountFieldset';
import {
  BankAccountFormSchemaPropsType,
} from './BankAccountForm.types';
import { BankAccountFormSchema } from './BankAccountForm.schema';
import styles from './BankAccountForm.module.scss';

export default function BankAccountForm({
  initialValues,
  accountClass,
  onSubmit,
  onClose,
  onChange,
  submitTitle,
}: BankAccountFormSchemaPropsType) {
  const { t } = useIntl();
  submitTitle = submitTitle || t('form.merchantApplication.submit');

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      onChange={onChange}
      validationSchema={BankAccountFormSchema}
    >
      {({ isSubmitting }) => (
        <>
          <AccountFieldset accountClass={accountClass} />
          <ButtonGroup className={styles.submit}>
            {onClose && (
              <Button onClick={onClose} disabled={isSubmitting}>
                Cancel
              </Button>
            )}
            <Button loading={isSubmitting} type="submit" skin="primary">
              {submitTitle}
            </Button>
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}
