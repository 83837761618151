import { gql } from '@apollo/client';

export const CONTACT_FRAGMENT = gql`
  fragment Contact on ContactType {
    id
    name
    email
    isEntity
    createdAt
    qboSyncState
    phoneNumbers {
      number
      type
      isPrimary
    }
    billingContacts {
      firstName
      lastName
      email
      type
    }
  }
`;

export const FETCH_CONTACTS = gql`
  query FetchContacts(
    $skip: Int
    $take: Int
    $filter: FilterContactInput
    $order: OrderContactInput
  ) {
    listContacts(
      listContactInput: {
        skip: $skip
        take: $take
        filter: $filter
        order: $order
      }
    ) {
      total
      items {
        ...Contact
      }
    }
  }
  ${CONTACT_FRAGMENT}
`;
