import React from 'react';
import classnames from 'classnames';
import { Grid, Text4 } from '@appclose/ui';

import { PrintingChecksModes } from '__generated__/globalTypes';
import { I18n } from 'i18n';

import { ReactComponent as CheckIcon } from './assets/check.svg';
import { CheckTypeSelectPropsType } from './CheckTypeSelect.types';
import styles from './CheckTypeSelect.module.scss';

export default function CheckTypeSelect({
  onChange,
  type,
}: CheckTypeSelectPropsType) {
  return (
    <Grid columns="min-content min-content" gap={[30]}>
      <div
        className={classnames(styles.checkOption, {
          [styles.selected]: type === PrintingChecksModes.VOUCHER,
        })}
        onClick={() => onChange(PrintingChecksModes.VOUCHER)}
      >
        <div className={styles.check}>
          <CheckIcon />
        </div>
        <Text4 weight="semibold">
          <I18n id="modal.printSettings.checkTypeSelect.voucher" />
        </Text4>
      </div>
      <div
        className={classnames(styles.checkOption, {
          [styles.selected]: type === PrintingChecksModes.STANDARD,
        })}
        onClick={() => onChange(PrintingChecksModes.STANDARD)}
      >
        <div className={styles.check}>
          <CheckIcon />
          <CheckIcon />
          <CheckIcon />
        </div>
        <Text4 weight="semibold">
          <I18n id="modal.printSettings.checkTypeSelect.standard" />
        </Text4>
      </div>
    </Grid>
  );
}
