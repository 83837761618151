import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDrag } from 'react-use-gesture';

import { OffsetType, PreviewPropsType, PreviewTypes } from './Preview.types';
import styles from './Preview.module.scss';
import { Bounds } from './Preview.constants';

export default function Preview({
  type,
  offset: extOffset,
  onChange,
}: PreviewPropsType) {
  const [offset, setOffset] = useState(extOffset);
  const bind = useDrag(
    ({ down, movement: [x, y] }) => {
      const newOffset = [Math.round(x), Math.round(y)] as OffsetType;

      setOffset(newOffset);

      if (!down) {
        onChange(newOffset);
      }
    },
    { bounds: Bounds[type], initial: () => extOffset }
  );

  useEffect(() => {
    setOffset(extOffset);
  }, [extOffset]);

  return (
    <div
      className={classnames(styles.preview, {
        [styles.check]: type === PreviewTypes.CHECK,
        [styles.depositSlip]: type === PreviewTypes.DEPOSIT_SLIP,
      })}
    >
      <div
        style={{ marginLeft: offset[0], marginTop: offset[1] }}
        className={styles.value}
        {...bind()}
      />
    </div>
  );
}
