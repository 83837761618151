import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client';
import { history, Loader, openConfirmAction, traceError } from '@appclose/core';
import { useIntl } from 'i18n';

import { QBO_INTEGRATION_SETUP_MODAL } from 'constants/modals';
import { clearQboMappings } from 'controllers/qboMappings';
import { openModal, isSameModalOpen } from 'controllers/modal';
import notification from 'controllers/notification';
import useDemo from '../useDemo';

import {
  DisconnectQboMutation,
  DisconnectQboMutationVariables,
  FetchQboLinkQuery,
  FetchQboLinkQueryVariables,
  FetchQboQuery,
  FetchQboQueryVariables,
  QboIntegrationDisabledSubscription,
  QboIntegrationDisabledSubscriptionVariables,
  QboIntegrationEnabledSubscription,
  QboIntegrationEnabledSubscriptionVariables,
} from './__generated__/useQboIntegration.gql';
import {
  DISCONNECT_QBO,
  FETCH_QBO,
  FETCH_QBO_LINK,
  ON_QBO_DISABLED,
  ON_QBO_ENABLED,
} from './useQboIntegration.gql';
import {
  QboIntegrationPropsType,
  QboIntegrationType,
} from './useQboIntegration.types';
import { CommonAppErrors } from '../../__generated__/globalTypes';

const QBO_SEARCH_TAG = 'qbo';

export default function useQboIntegration(
  { redirectRoute }: QboIntegrationPropsType = {
    redirectRoute: window.location.pathname,
  }
): QboIntegrationType {
  const { t } = useIntl();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const { onOpenDemoUnavailableConfirmOrUserCallback } = useDemo();

  const { loading: loadingQboData, data: qboData, refetch } = useQuery<
    FetchQboQuery,
    FetchQboQueryVariables
  >(FETCH_QBO);

  const [fetchQboLink, { loading: loadingLink, data: linkData }] = useLazyQuery<
    FetchQboLinkQuery,
    FetchQboLinkQueryVariables
  >(FETCH_QBO_LINK);

  const [disconnectQbo] = useMutation<
    DisconnectQboMutation,
    DisconnectQboMutationVariables
  >(DISCONNECT_QBO);

  const { connected = false, name = '', expectedCompanyName = '', email = '', connectionError } = qboData?.qbo || {};

  const errorText = useMemo<string | null>(() => {
    if (connectionError === CommonAppErrors.QBO_COMPANY_ALREADY_CONNECTED) {
      return t('qboIntegration.error.companyAlreadyConnected');
    } else if (connectionError === CommonAppErrors.QBO_CANT_SWITCH_COMPANY) {
      return t('qboIntegration.error.cantSwitchCompany', { companyName: expectedCompanyName });
    } else if (connectionError === CommonAppErrors.QBO_UNKNOWN_ERROR) {
      return t('qboIntegration.error.unknownError');
    } else {
      return null;
    }
  }, [connectionError, t, expectedCompanyName]);

  const handleOnDisconnect = useCallback(() => {
    onOpenDemoUnavailableConfirmOrUserCallback(t('qboIntegration.confirm.demo.disconnect'), ()=> {
      dispatch(
        openConfirmAction({
          name: t('qboIntegration.confirm.disconnect.title'),
          content: t('qboIntegration.confirm.disconnect.content'),
          okButtonTitle: t('qboIntegration.confirm.disconnect.button.ok'),
          cancelButtonTitle: t('qboIntegration.confirm.disconnect.button.cancel'),
          okButtonSkin: 'primary',
          async onConfirm() {
            try {
              clearQboMappings();
              await disconnectQbo();
              await refetch();
              notification().info(t('qboIntegration.notification.disconnected'));
            } catch (e) {
              traceError(e);
            }
          },
        })
      )
  });
  }, [disconnectQbo, dispatch, onOpenDemoUnavailableConfirmOrUserCallback, refetch, t]);

  const handleOnConnect = useCallback(async () => {
    onOpenDemoUnavailableConfirmOrUserCallback(t('qboIntegration.confirm.demo.connect'), () => {
      if (!connected) {
        fetchQboLink({
          variables: {
            redirect: redirectRoute,
          },
        });

        return;
      }

      openModal(QBO_INTEGRATION_SETUP_MODAL, { isEdit: true });
    });

  }, [connected, fetchQboLink, onOpenDemoUnavailableConfirmOrUserCallback, redirectRoute, t]);

  useSubscription<
    QboIntegrationEnabledSubscription,
    QboIntegrationEnabledSubscriptionVariables
  >(ON_QBO_ENABLED, {
    onSubscriptionData: async () => {
      try {
        await refetch();
      } catch (e) {
        traceError(e);
      }
    },
  });

  useSubscription<
    QboIntegrationDisabledSubscription,
    QboIntegrationDisabledSubscriptionVariables
  >(ON_QBO_DISABLED, {
    onSubscriptionData: async () => {
      try {
        await refetch();
      } catch (e) {
        traceError(e);
      }
    },
  });

  useEffect(() => {
    if (linkData?.link?.url) {
      dispatch(
        openConfirmAction({
          name: t('qboIntegration.confirm.openQuickBookOnline'),
          content: <Loader />,
        })
      );

      window.location.href = linkData?.link?.url;
    }
  }, [dispatch, linkData, t]);

  useEffect(() => {
    if (search.includes(QBO_SEARCH_TAG) && !isSameModalOpen(QBO_INTEGRATION_SETUP_MODAL)) {
      history.replace(pathname);

      openModal(QBO_INTEGRATION_SETUP_MODAL);
    }
  }, [pathname, search]);

  const connectedAs = connected
    ? `${name} (${email})`
    : undefined;

  return {
    connected,
    connectedAs,
    errorText,
    loading: loadingQboData || loadingLink,
    onConnect: handleOnConnect,
    onDisconnect: handleOnDisconnect,
  };
}
