import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ShepherdTour } from 'react-shepherd';
import 'shepherd.js/dist/css/shepherd.css';

import { useIsMobileDevice } from '@appclose/core';
import useAsideState from 'hooks/useAsideState';
import { isDemoBuild } from 'controllers/demo';
import { useIntl } from 'i18n';

import { TourPropsType } from './Tour.types';
import { TOUR_OPTIONS } from './Tour.constants';
import './Tour.module.scss';
import { getTourSteps } from './Tour.utils';
import { TourContext } from './Tour.context';
import TourModal from './components/TourModal';

export default function Tour({ children }: TourPropsType) {
  const { pathname } = useLocation();
  const isMobileDevice = useIsMobileDevice();
  const { setIsOpen: setMenuOpen } = useAsideState();

  const { t } = useIntl();

  const toursOptions = useMemo(
    () => ({
      t,
      isMobile: isMobileDevice,
      setMenuOpen,
    }),
    [isMobileDevice, setMenuOpen, t]
  );

  const steps = useMemo(() => getTourSteps(pathname, toursOptions), [
    pathname,
    toursOptions,
  ]);

  const hasTour = useMemo(() => steps !== undefined, [steps]);

  if (!isDemoBuild()) {
    return <>{children}</>;
  }

  return (
    <TourContext.Provider value={{ hasTour }}>
      <ShepherdTour steps={steps || []} tourOptions={TOUR_OPTIONS}>
        <>
          {children}
          <TourModal />
        </>
      </ShepherdTour>
    </TourContext.Provider>
  );
}
