import React from 'react';
import { AmountFormField, FormGroup, ToggleFormField } from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import MatterTypesSelectFormField from 'components/fields/MatterTypesSelectFormField';
import { MatterTypes } from '__generated__/globalTypes';
import { useIntl } from 'i18n';

import { MatterBillingTypeFieldsetPropsType } from './MatterBillingTypeFieldset.types';

export default function MatterBillingTypeFieldset({
  name,
  type = MatterTypes.HYBRID,
  isEdit = false,
  hasInvoices = false,
}: MatterBillingTypeFieldsetPropsType) {
  const fieldBaseName = name ? `${name}.` : '';
  const { t } = useIntl();

  return (
    <FormGroup>
      <FormGrid align={type === MatterTypes.HYBRID ? 'end' : undefined}>
        <MatterTypesSelectFormField
          name={`${fieldBaseName}type`}
          disabled={isEdit}
          tooltip={t('page.matter.overview.type.flatFee.hint')}
        />
        {type === MatterTypes.HYBRID && (
          <ToggleFormField
            name={`${fieldBaseName}billable`}
            label={t('matterBillingTypeFieldset.billable')}
            disabled={isEdit && hasInvoices}
          />
        )}
        {type === MatterTypes.FLAT_FEE && (
          <AmountFormField
            name={`${fieldBaseName}agreedFlatFee`}
            label={t('matterBillingTypeFieldset.agreedFlatFee')}
            max={Number.MAX_SAFE_INTEGER}
          />
        )}
      </FormGrid>
    </FormGroup>
  );
}
