import { gql } from '@apollo/client';

export const FETCH_QBO_SYNC_CONTACT_STATUS = gql`
  query FetchQboSyncContactStatus($id: ID!) {
    syncStatus: getContactQboSyncStatus(id: $id) {
      id
      action
    }
  }
`;

export const QBO_RESYNC_CONTACT = gql`
  mutation QboResyncContact($id: ID!) {
    resyncQbo: qboResyncContact(id: $id) {
      success
    }
  }
`;

export const FETCH_QBO_SYNC_INVOICE_STATUS = gql`
  query FetchQboSyncInvoiceStatus($id: ID!) {
    syncStatus: getInvoiceQboSyncStatus(id: $id) {
      id
      action
    }
  }
`;

export const QBO_RESYNC_INVOICE = gql`
  mutation QboResyncInvoice($id: ID!) {
    resyncQbo: qboResyncInvoice(id: $id) {
      success
    }
  }
`;

export const FETCH_QBO_SYNC_PAYMENT_STATUS = gql`
  query FetchQboSyncPaymentStatus($id: ID!) {
    syncStatus: getPaymentQboSyncStatus(id: $id) {
      id
      action
    }
  }
`;

export const QBO_RESYNC_PAYMENT = gql`
  mutation QboResyncPayment($id: ID!) {
    resyncQbo: qboResyncPayment(id: $id) {
      success
    }
  }
`;

export const FETCH_QBO_SYNC_REFUND_STATUS = gql`
  query FetchQboSyncRefundStatus($id: ID!) {
    syncStatus: getRefundPaymentQboSyncStatus(id: $id) {
      id
      action
    }
  }
`;

export const QBO_RESYNC_REFUND = gql`
  mutation QboResyncRefund($id: ID!) {
    resyncQbo: qboResyncRefundPayment(id: $id) {
      success
    }
  }
`;

export const FETCH_QBO_SYNC_CREDIT_MEMO_STATUS = gql`
  query FetchQboSyncCreditMemoStatus($id: ID!) {
    syncStatus: getCreditMemoQboSyncStatus(id: $id) {
      id
      action
    }
  }
`;

export const QBO_RESYNC_CREDIT_MEMO = gql`
  mutation QboResyncCreditMemo($id: ID!) {
    resyncQbo: qboResyncCreditMemo(id: $id) {
      success
    }
  }
`;

export const FETCH_QBO_SYNC_DEPOSIT_STATUS = gql`
  query FetchQboSyncDepositStatus($id: ID!) {
    syncStatus: getDepositQboSyncStatus(id: $id) {
      id
      action
    }
  }
`;

export const QBO_RESYNC_DEPOSIT = gql`
  mutation QboResyncDeposit($id: ID!) {
    resyncQbo: qboResyncDeposit(id: $id) {
      success
    }
  }
`;

export const FETCH_QBO_SYNC_DISBURSEMENT_STATUS = gql`
  query FetchQboSyncDisbursementStatus($id: ID!) {
    syncStatus: getDisbursementQboSyncStatus(id: $id) {
      id
      action
    }
  }
`;

export const QBO_RESYNC_DISBURSEMENT = gql`
  mutation QboResyncDisbursement($id: ID!) {
    resyncQbo: qboResyncDisbursement(id: $id) {
      success
    }
  }
`;
