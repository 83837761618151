import * as Yup from 'yup';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import { EMAIL_VALIDATION_SCHEMA } from '@appclose/core';

import { getI18nErrorMessage } from 'i18n';

export const NON_SIGNATORY_RECIPIENTS_SCHEMA = Yup.array()
  .of(
    Yup.object()
      .shape({
        email: EMAIL_VALIDATION_SCHEMA
          .required(getI18nErrorMessage('modal.signatureDocument.form.nonSignatoryRecipients.error.email.required')),
      })
      .required()
  )
  .test(
    'uniqRecipient',
    getI18nErrorMessage(
      'modal.signatureDocument.form.nonSignatoryRecipients.error.unique'
    ),
    (recipients) => {
      const recipientsData = recipients?.map((recipient) => recipient.email);
      const uniqueRecipientsCount = uniqWith(recipientsData, isEqual).length;

      return uniqueRecipientsCount === recipients?.length;
    }
  )
  .defined();
