import gqlClient from 'gql';
import { traceError } from '@appclose/core';

import { Modes } from '__generated__/globalTypes';

import {
  FetchProfileQuery,
  FetchProfileQueryVariables,
} from './__generated__/mode.gql';
import { FETCH_PROFILE } from './mode.gql';

export function getMode() {
  try {
    const result = gqlClient.cache.readQuery<
      FetchProfileQuery,
      FetchProfileQueryVariables
    >({ query: FETCH_PROFILE });

    return result?.profile?.mode;
  } catch (e) {
    traceError(e);
  }
}

export function isPlusPlan() {
  return getMode() === Modes.PLUS;
}

export function isFreePlan() {
  return getMode() === Modes.FREE;
}
