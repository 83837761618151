import { gql } from '@apollo/client';

import { AVATAR_FRAGMENT } from 'components/common/Avatar';

export const FETCH_PROFILE = gql`
  query FetchProfile {
    getProfile {
      id
      firstName
      lastName
      picture {
        ...Avatar
      }
    }
  }
  ${AVATAR_FRAGMENT}
`;

export const CREATE_LEAD = gql`
  mutation CreateLead($input: CreateLeadInput!) {
    createLead(createLeadInput: $input) {
      id
    }
  }
`;

export const UPDATE_LEAD = gql`
  mutation UpdateLead($input: UpdateLeadInput!, $id: ID!) {
    updateLead(updateLeadInput: $input, id: $id) {
      id
      isEntity
      originatingAttorney {
        id
        firstName
        lastName
        picture {
          ...Avatar
        }
      }
      notes
      phoneNumbers {
        number
        type
      }
      individual {
        firstName
        lastName
        middleName
        email
      }
      entity {
        entityName
        website
        contacts {
          firstName
          lastName
          middleName
          email
          type
        }
      }
    }
  }
  ${AVATAR_FRAGMENT}
`;

export const FETCH_LEAD = gql`
  query FetchLead($id: ID!) {
    getLead(id: $id) {
      id
      isEntity
      isImportant
      notes
      leadSource
      originatingAttorney {
        id
        firstName
        lastName
        picture {
          ...Avatar
        }
      }
      phoneNumbers {
        number
        type
        isPrimary
      }
      individual {
        firstName
        lastName
        middleName
        email
      }
      entity {
        entityName
        website
        contacts {
          firstName
          lastName
          middleName
          email
          type
        }
      }
    }
  }
  ${AVATAR_FRAGMENT}
`;

export const FETCH_LEAD_CONTACT = gql`
  query FetchLeadContact($id: ID!) {
    getContact(id: $id) {
      id
      name
      isEntity
      originatingAttorney {
        id
        firstName
        lastName
        picture {
          ...Avatar
        }
      }
      notes
      conflictsCheckCompleted
      w9FormSentToClient
      engagementHasBeenSigned
      taxable
      phoneNumbers {
        number
        type
        isPrimary
      }
      firstName
      lastName
      middleName
      gender
      email
      website
      billingContacts {
        firstName
        lastName
        middleName
        email
        type
      }
    }
  }
  ${AVATAR_FRAGMENT}
`;
