import React, { useCallback, useMemo } from 'react';
import isNil from 'lodash/isNil';
import { useMutation, useQuery } from '@apollo/client';
import { dateManager, Loader } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { track } from 'controllers/analytics';
import { DemoEventNames, EntryTypes } from 'constants/analytics';
import { SERVER_DATE_FORMAT } from 'constants/date';
import notification from 'controllers/notification';
import { I18n, useIntl } from 'i18n';
import useUpdateFiles from 'hooks/useUpdateFiles';
import { Entities } from 'constants/entities';
import { FETCH_LAST_FINISHED_RECONCILIATION } from 'gql/shared/reconsiliation.gql';
import {
  FetchLastFinishedReconciliationQuery,
  FetchLastFinishedReconciliationQueryVariables,
} from 'gql/shared/__generated__/reconsiliation.gql';

import {
  CREATE_RECONCILIATION,
  FETCH_RECONCILIATION,
  UPDATE_RECONCILIATION,
} from './ReconciliationModal.gql';
import {
  CreateReconciliationMutation,
  CreateReconciliationMutationVariables,
  FetchReconciliationQuery,
  FetchReconciliationQueryVariables,
  UpdateReconciliationMutation,
  UpdateReconciliationMutationVariables,
} from './__generated__/ReconciliationModal.gql';
import ReconciliationForm, {
  ReconciliationFormValuesType,
} from './components/ReconciliationForm';
import { ReconciliationModalPropsType } from './ReconciliationModal.types';
import styles from './ReconciliationModal.module.scss';

export default function ReconciliationModal({
  id,
  onClose,
}: ReconciliationModalPropsType) {
  const { t } = useIntl();
  const isEdit = !!id;
  const { onUpdateFiles } = useUpdateFiles(Entities.RECONCILIATION);
  const [createReconciliation] = useMutation<
    CreateReconciliationMutation,
    CreateReconciliationMutationVariables
  >(CREATE_RECONCILIATION);
  const [updateReconciliation] = useMutation<
    UpdateReconciliationMutation,
    UpdateReconciliationMutationVariables
  >(UPDATE_RECONCILIATION);
  const { data, loading } = useQuery<
    FetchReconciliationQuery,
    FetchReconciliationQueryVariables
  >(FETCH_RECONCILIATION, {
    variables: { id: id as string },
    skip: !isEdit,
    fetchPolicy: 'network-only',
  });
  const {
    data: lastReconciliationData,
    loading: lastReconciliationLoading,
  } = useQuery<
    FetchLastFinishedReconciliationQuery,
    FetchLastFinishedReconciliationQueryVariables
  >(FETCH_LAST_FINISHED_RECONCILIATION, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const handleSubmit = useCallback(
    async ({
      reconciliationId,
      transactions: selectedTransactions,
      statementBalance,
      statementDate,
      files,
    }: ReconciliationFormValuesType) => {
      const transactions = selectedTransactions.map(({ id }) => id);

      if (isEdit) {
        await updateReconciliation({
          variables: {
            input: {
              id: reconciliationId as string,
              transactions,
              statementBalance,
              statementDate,
            },
          },
        });

        notification().entityUpdated(
          t('modal.reconciliation.notification.updated')
        );
      } else {
        const { data } = await createReconciliation({
          variables: {
            input: {
              transactions,
              statementBalance,
              statementDate,
            },
          },
        });

        reconciliationId = data?.createReconciliation.id;

        notification().entityCreated(
          t('modal.reconciliation.notification.created')
        );

        track(DemoEventNames.DEMO_ENTRY_CREATED, {
          entry_type: EntryTypes.RECONCILIATION,
        });
      }

      if (reconciliationId && files) {
        await onUpdateFiles({ entityId: reconciliationId, ...files });
      }

      onClose();
    },
    [
      createReconciliation,
      isEdit,
      onClose,
      t,
      updateReconciliation,
      onUpdateFiles,
    ]
  );

  const { transactions, statementDate, statementBalance } = {
    ...data?.reconciliation,
  };
  const lastReconciliationDate =
    lastReconciliationData?.reconciliation?.items[0]?.statementDate;
  const lastStatementDate =
    lastReconciliationDate &&
    dateManager()
      .parse(lastReconciliationDate)
      .startOf('day')
      .add(1, 'day')
      .valueOf();

  const initialValues = useMemo<ReconciliationFormValuesType>(
    () => ({
      reconciliationId: id,
      lastStatementDate,
      transactions: transactions || [],
      statementDate: statementDate
        ? dateManager()
            .parse(statementDate)
            .endOf('day')
            .format(SERVER_DATE_FORMAT)
        : dateManager().parse().endOf('day').format(SERVER_DATE_FORMAT),
      statementBalance: isNil(statementBalance) ? 0 : statementBalance,
      canCompleteReconciliation: false,
    }),
    [id, lastStatementDate, statementBalance, statementDate, transactions]
  );

  return (
    <ModalPage className={styles.modal} onClose={onClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.reconciliation.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading || lastReconciliationLoading ? (
          <Loader />
        ) : (
          <ReconciliationForm
            initialValues={initialValues}
            isEdit={isEdit}
            onSubmit={handleSubmit}
            onClose={onClose}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
