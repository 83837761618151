import React, { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import set from 'lodash/set';
import { useQuery } from '@apollo/client';
import {
  getFieldError,
  SelectField,
  useFormContext,
  permissionProvider,
  usePopupModal,
} from '@appclose/core';

import { track } from 'controllers/analytics';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import { EntryTypes, EventNames, SourceTypes } from 'constants/analytics';
import ContactMatter from 'components/common/ContactMatter';
import ContactMatterPopupModal from 'components/modals/popups/ContactMatterPopupModal';
import { useIntl } from 'i18n';

import { FETCH_CONTACTS_WITH_MATTERS } from './ContactMatterSelectFormField.gql';
import {
  FetchContactsWithMattersQuery,
  FetchContactsWithMattersQueryVariables,
} from './__generated__/ContactMatterSelectFormField.gql';
import {
  ContactMatterSelectContactType,
  ContactMatterSelectFormFieldOptionsType,
  ContactMatterSelectFormFieldOptionValueType,
  ContactMatterSelectFormFieldPropsType,
  ContactMatterSelectMatterType,
} from './ContactMatterSelectFormField.types';
import styles from './ContactMatterSelectFormField.module.scss';

export default function ContactMatterSelectFormField({
  contactFieldName = 'contact',
  matterFieldName = 'matter',
  onChange,
  excludeContactIds,
  excludeMatterIds,
  includeContactIds,
  onlyInitializedTrustLedger = false,
  onlyPositiveTrustBalance = false,
  showTrustLedgerBalance = false,
  allowAddNew,
  ...props
}: ContactMatterSelectFormFieldPropsType) {
  const { t } = useIntl();
  const [search, setSearch] = useState('');
  const formContext = useFormContext<any>();
  const { values, initialValues, setValues } = formContext;

  const contact = get(
    values,
    contactFieldName
  ) as ContactMatterSelectContactType;
  const matter = get(values, matterFieldName) as ContactMatterSelectMatterType;

  const withAddNew =
    allowAddNew &&
    permissionProvider().hasPermissions({
      [PermissionResources.CONTACT]: {
        '*': {
          actions: [PermissionActions.CREATE],
        },
      },
      [PermissionResources.MATTER]: {
        '*': {
          actions: [PermissionActions.CREATE],
        },
      },
    });

  const initialContact = get(
    initialValues,
    contactFieldName
  ) as ContactMatterSelectContactType;
  const initialMatter = get(
    initialValues,
    matterFieldName
  ) as ContactMatterSelectMatterType;

  const { loading, data: contactsData, fetchMore, refetch } = useQuery<
    FetchContactsWithMattersQuery,
    FetchContactsWithMattersQueryVariables
  >(FETCH_CONTACTS_WITH_MATTERS, {
    variables: {
      filter: {
        search,
        includeContactIds,
        onlyInitializedTrustLedger,
        onlyPositiveTrustBalance,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const { openPopupModal } = usePopupModal(
    ContactMatterPopupModal,
    (contactMatter) => {
      setValues((values: any) =>
        set(
          set(values, contactFieldName, contactMatter.contact),
          matterFieldName,
          contactMatter?.matter
        )
      );

      refetch();
    }
  );

  const openAddNewModal = useCallback(() => {
    track(EventNames.ADD_NEW_ENTRY, {
      entry_type: EntryTypes.CONTACT_MATTER,
      source_type: SourceTypes.POPUP_MODAL,
    });

    openPopupModal();
  }, [openPopupModal]);

  const contactsWithMatters = useMemo(
    () => contactsData?.contactsWithMatters?.items || [],
    [contactsData?.contactsWithMatters?.items]
  );

  const onLoadMore = useCallback(async () => {
    const { data } = await fetchMore({
      variables: {
        skip: contactsWithMatters.length,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => ({
        contactsWithMatters: {
          ...previousResult?.contactsWithMatters,
          items: [
            ...(previousResult.contactsWithMatters?.items || []),
            ...(fetchMoreResult?.contactsWithMatters?.items || []),
          ],
        },
      }),
    });

    return contactsWithMatters.length !== data.contactsWithMatters.total;
  }, [contactsWithMatters.length, fetchMore]);

  const renderOptionLabel = useCallback(
    ({ contact, matter }: ContactMatterSelectFormFieldOptionValueType) => {
      const trustBalance = (matter || contact).trustBalance;

      return (
        <ContactMatter
          contact={contact}
          matter={matter}
          trustBalance={showTrustLedgerBalance ? trustBalance : undefined}
          className={styles.option}
        />
      );
    },
    [showTrustLedgerBalance]
  );

  const getSelectOptions = useCallback(
    (
      contactsWithMatters: FetchContactsWithMattersQuery['contactsWithMatters']['items'] = []
    ) => {
      return contactsWithMatters.reduce<ContactMatterSelectFormFieldOptionsType>(
        (options, contact) => {
          const shouldSkipContact =
            onlyPositiveTrustBalance && !contact.trustBalance;

          if (!excludeContactIds?.includes(contact?.id) && !shouldSkipContact) {
            options.push({
              label: renderOptionLabel({ contact }),
              value: { contact },
            });
          }

          contact.matters?.forEach((matter) => {
            const shouldSkipMatter =
              onlyPositiveTrustBalance && !matter.trustBalance;

            if (!excludeMatterIds?.includes(matter?.id) && !shouldSkipMatter) {
              options.push({
                label: renderOptionLabel({ contact, matter }),
                value: { contact, matter },
              });
            }
          });

          return options;
        },
        []
      );
    },
    [
      excludeContactIds,
      excludeMatterIds,
      onlyPositiveTrustBalance,
      renderOptionLabel,
    ]
  );

  const defaultOptions = useMemo(() => {
    if (!initialContact && !initialMatter) {
      return [];
    }

    return [
      {
        label: renderOptionLabel({
          contact: initialContact,
          matter: initialMatter,
        }),
        value: { contact: initialContact, matter: initialMatter },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = useMemo(() => {
    const options = getSelectOptions(contactsWithMatters);

    if (contact) {
      const hasOptionForContact = options.some(
        ({
          value: {
            contact: { id: contactId },
          },
        }) => contactId === contact?.id
      );

      if (!hasOptionForContact) {
        options.push({
          label: renderOptionLabel({ contact }),
          value: { contact },
        });
      }
    }

    if (contact && matter) {
      const hasOptionForContactMatter = options.some(
        ({
          value: {
            contact: { id: contactId },
            matter: matterOptionValue,
          },
        }) => contactId === contact?.id && matterOptionValue?.id === matter?.id
      );

      if (!hasOptionForContactMatter) {
        options.push({
          label: renderOptionLabel({ contact, matter }),
          value: { contact, matter },
        });
      }
    }

    return options;
  }, [
    contact,
    contactsWithMatters,
    getSelectOptions,
    matter,
    renderOptionLabel,
  ]);

  const onSearchChange = useMemo(
    () => debounce((value: string) => setSearch(value), 500),
    [setSearch]
  );

  const valueResolver = useCallback(
    (value: ContactMatterSelectFormFieldOptionValueType) =>
      [value?.contact?.id, value?.matter?.id].filter(Boolean).join('-'),
    []
  );

  const handleOnChange = useCallback(
    (value: ContactMatterSelectFormFieldOptionValueType) => {
      setValues(
        (values: any) =>
          set(
            set(values, contactFieldName, value?.contact),
            matterFieldName,
            value?.matter
          ),
        true
      );

      onChange?.(value);
    },
    [contactFieldName, matterFieldName, onChange, setValues]
  );

  const value = useMemo(() => {
    if (!contact && !matter) {
      return;
    }

    return {
      contact,
      matter,
    };
  }, [contact, matter]);

  const error = useMemo(() => {
    const contactError = getFieldError(formContext, contactFieldName);
    const matterError = getFieldError(formContext, matterFieldName);

    return contactError || matterError;
  }, [contactFieldName, formContext, matterFieldName]);

  return (
    <SelectField
      withSearch
      value={value}
      error={error}
      name="contactsWithMatters"
      label={t('field.contactMatterSelect.label')}
      isLoading={loading}
      options={options}
      defaultOptions={defaultOptions}
      valueResolver={valueResolver}
      onSearchChange={onSearchChange}
      onChange={handleOnChange}
      onLoadOptions={onLoadMore}
      onAdd={withAddNew ? openAddNewModal : undefined}
      addLabel={t('field.contactMatterSelect.addLabel')}
      {...props}
    />
  );
}
