import React from 'react';

import { FineTuneAlignmentPropsType } from './FineTuneAlignment.types';
import Preview, { Bounds } from './components/Preview';
import Controls from './components/Controls';
import { Flex, Offset } from '@appclose/ui';

export default function FineTuneAlignment({
  type,
  offset,
  onChange,
}: FineTuneAlignmentPropsType) {
  return (
    <Flex alignItems="flex-end" justify="space-between" wrap="wrap">
      <Preview type={type} offset={offset} onChange={onChange} />
      <Offset top="l">
        <Controls offset={offset} bound={Bounds[type]} onChange={onChange} />
      </Offset>
    </Flex>
  );
}
