import { openModal } from 'controllers/modal';
import { DEPOSIT_MODAL, DEPOSIT_WARNING_MODAL } from 'constants/modals';
import { DEPOSIT_FEATURE_HIDE_WARNING_STATUS_KEY } from 'constants/localStorage';
import {
  DepositType,
  PaymentMethodsTypes,
  TrustTransactionStatuses,
} from '__generated__/globalTypes';

export function getDepositFeatureWarningStatus() {
  return (
    localStorage.getItem(DEPOSIT_FEATURE_HIDE_WARNING_STATUS_KEY) === 'true'
  );
}

export function setDepositFeatureWarningStatus(status: boolean) {
  return localStorage.setItem(
    DEPOSIT_FEATURE_HIDE_WARNING_STATUS_KEY,
    String(status)
  );
}

export function openDepositOrWarningModal() {
  if (getDepositFeatureWarningStatus()) {
    openModal(DEPOSIT_MODAL);
  } else {
    openModal(DEPOSIT_WARNING_MODAL);
  }
}

export function isDepositPrintable({
  status,
  paymentMethod,
}: Pick<DepositType, 'status' | 'paymentMethod'>) {
  return (
    status === TrustTransactionStatuses.UNCLEARED &&
    (paymentMethod === PaymentMethodsTypes.CHECK ||
      paymentMethod === PaymentMethodsTypes.CASH)
  );
}
