export {
  SIGN_IN_ROUTE,
  SIGN_UP_ROUTE,
  ROOT_ROUTE,
  NOT_FOUND_ROUTE,
  EXPERT_SUPPORT_ROUTE,
  SUPPORT_PAGE_URL,
  PRIVACY_POLICY_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
} from '@appclose/core';

/* Account */
export const CONFIRM_EMAIL_ROUTE = '/verify';
export const REPEAT_CONFIRM_EMAIL_ROUTE = '/verification-repeat';
export const SETUP_PASSWORD_ROUTE = '/invite';
export const RESET_PASSWORD_ROUTE = '/reset-password';
export const CHANGE_PASSWORD_ROUTE = '/change-password';
export const SIGN_UP_SUCCESS_ROUTE = '/thank-you';

/* Onboarding */
export const ONBOARDING_ROUTE = '/onboarding';
export const ONBOARDING_PROFILE_ROUTE = ONBOARDING_ROUTE + '/profile';
export const ONBOARDING_CHOOSE_MODE_ROUTE = ONBOARDING_ROUTE + '/choose-mode';
export const ONBOARDING_OWNER_INFO_ROUTE = ONBOARDING_ROUTE + '/owner-info';
export const ONBOARDING_MERCHANT_APPLICATION_ROUTE =
  ONBOARDING_ROUTE + '/merchant-application';
export const ONBOARDING_COMPLETE_ROUTE = ONBOARDING_ROUTE + '/complete';
export const ONBOARDING_BLOCKED_ROUTE = ONBOARDING_ROUTE + '/update-needed';

/* Common */
export const BILLING_ROUTE = '/billing';
export const CALENDAR_ROUTE = '/calendar';
export const CONTACTS_ROUTE = '/contacts';
export const LEADS_ROUTE = '/leads';
export const DASHBOARD_ROUTE = '/dashboard';
export const EXPENSES_ROUTE = '/expenses';
export const FILE_STORAGE_ROOT_ROUTE = '/file-storage';
export const FILE_STORAGE_NODE_ROUTE = FILE_STORAGE_ROOT_ROUTE + '/:nodeId';
export const FILES_ROUTE = '/files';
export const FLAT_FEES_ROUTE = '/flat-fees';
export const INVOICES_ROUTE = '/invoices';
export const MATTERS_ROUTE = '/matters';
export const OPERATING_ACCOUNT_COMPLETED = '/operating-account-completed';
export const OVERVIEW_ROUTE = '/overview';
export const PROFILE_ROUTE = '/profile';
export const REFUNDS_ROUTE = '/refunds';
export const REPORTS_ROUTE = '/reports';
export const SETTINGS_ROUTE = '/settings';
export const SIGNATURE_ROUTE = '/signature';
export const TIME_ENTRIES_ROUTE = '/time-entries';
export const TRANSACTIONS_ROUTE = '/transactions';
export const TRUST_ROUTE = '/trust';

/* Entities */
export const ENTITIES_ROUTE = '/entities/:entityName/:entityId';

/* Dashboard */
export const DASHBOARD_OUTSTANDING_INVOICES =
  DASHBOARD_ROUTE + '/outstanding-invoices';
export const DASHBOARD_PAID_INVOICES = DASHBOARD_ROUTE + '/paid-invoices';
export const DASHBOARD_CONTACTS_REVENUE = DASHBOARD_ROUTE + '/contacts-revenue';

/* Contact */
export const CONTACT_ROUTE = CONTACTS_ROUTE + '/:id';
export const CONTACT_OVERVIEW_ROUTE = CONTACT_ROUTE + OVERVIEW_ROUTE;
export const CONTACT_MATTERS_ROUTE = CONTACT_ROUTE + MATTERS_ROUTE;
export const CONTACT_INVOICES_ROUTE = CONTACT_ROUTE + INVOICES_ROUTE;
export const CONTACT_TRANSACTIONS_ROUTE = CONTACT_ROUTE + TRANSACTIONS_ROUTE;
export const CONTACT_EXPENSES_ROUTE = CONTACT_ROUTE + EXPENSES_ROUTE;
export const CONTACT_TIME_ENTRIES_ROUTE = CONTACT_ROUTE + TIME_ENTRIES_ROUTE;
export const CONTACT_FLAT_FEES_ROUTE = CONTACT_ROUTE + FLAT_FEES_ROUTE;
export const CONTACT_FILES_ROOT_ROUTE = CONTACT_ROUTE + FILES_ROUTE;
export const CONTACT_FILES_NODE_ROUTE =
  CONTACT_ROUTE + FILES_ROUTE + '/:nodeId';

/* Lead */
export const LEADS_BOARD_ROUTE = LEADS_ROUTE + '/board';
export const LEADS_INBOX_ROUTE = LEADS_ROUTE + '/inbox';
export const LEADS_INBOX_INTEGRATIONS_ROUTE =
  LEADS_INBOX_ROUTE + '/integrations';
export const LEADS_ARCHIVE_ROUTE = LEADS_ROUTE + '/archive';
export const LEAD_ROUTE = '/lead/:id';
export const LEAD_OVERVIEW_ROUTE = LEAD_ROUTE + OVERVIEW_ROUTE;
export const LEAD_FILES_ROOT_ROUTE = LEAD_ROUTE + FILES_ROUTE;
export const LEAD_FILES_NODE_ROUTE = LEAD_ROUTE + FILES_ROUTE + '/:nodeId';

/* Billing */
export const BILLING_INVOICES_ROUTE = BILLING_ROUTE + INVOICES_ROUTE;
export const BILLING_TRANSACTIONS_ROUTE = BILLING_ROUTE + TRANSACTIONS_ROUTE;

/* Public */
export const PAYMENT_LINK_ROUTE = '/payment-link';
export const SIGN_ROUTE = '/sign';

/* Reports */
export const REPORTS_TRANSACTIONS_ROUTE = REPORTS_ROUTE + TRANSACTIONS_ROUTE;
export const REPORTS_INVOICES_ROUTE = REPORTS_ROUTE + INVOICES_ROUTE;
export const REPORTS_CONTACTS_ROUTE = REPORTS_ROUTE + CONTACTS_ROUTE;

/* Matter */
export const MATTER_ROUTE = MATTERS_ROUTE + '/:id';
export const MATTER_OVERVIEW_ROUTE = MATTER_ROUTE + OVERVIEW_ROUTE;
export const MATTER_TIME_ENTRIES_ROUTE = MATTER_ROUTE + TIME_ENTRIES_ROUTE;
export const MATTER_EXPENSES_ROUTE = MATTER_ROUTE + EXPENSES_ROUTE;
export const MATTER_INVOICES_ROUTE = MATTER_ROUTE + INVOICES_ROUTE;
export const MATTER_TRANSACTIONS_ROUTE = MATTER_ROUTE + TRANSACTIONS_ROUTE;
export const MATTER_FLAT_FEES_ROUTE = MATTER_ROUTE + FLAT_FEES_ROUTE;
export const MATTER_FILES_ROOT_ROUTE = MATTER_ROUTE + FILES_ROUTE;
export const MATTER_FILES_NODE_ROUTE = MATTER_ROUTE + FILES_ROUTE + '/:nodeId';

/* Trust */
export const TRUST_TRANSACTION_LEDGER_ROUTE =
  TRUST_ROUTE + '/transaction-ledger';
export const TRUST_DEPOSITS_ROUTE = TRUST_ROUTE + '/deposits';
export const TRUST_DISBURSEMENTS_ROUTE = TRUST_ROUTE + '/disbursements';
export const TRUST_RECONCILIATIONS_ROUTE = TRUST_ROUTE + '/reconciliations';
export const TRUST_CONTACT_LEDGERS_ROUTE = TRUST_ROUTE + '/contact-ledgers';

/* Settings */
export const SETTINGS_FIRM_PROFILE_ROUTE = SETTINGS_ROUTE + '/firm-profile';
export const SETTINGS_TEAM_MEMBERS_ROUTE = SETTINGS_ROUTE + '/team-members';
export const SETTINGS_ACCOUNTS_ROUTE = SETTINGS_ROUTE + '/accounts';
export const SETTINGS_INTEGRATIONS_ROUTE = SETTINGS_ROUTE + '/integrations';
export const SETTINGS_INTEGRATIONS_QBO_SYNC_HISTORY_ROUTE =
  SETTINGS_INTEGRATIONS_ROUTE + '/qbo-sync-history';
export const SETTINGS_PAYMENT_LINK_ROUTE = SETTINGS_ROUTE + '/payment-link';
export const SETTINGS_CUSTOM_DATA_ENTRIES_ROUTE =
  SETTINGS_ROUTE + '/custom-data-entries';
export const SETTINGS_CUSTOM_DATA_ENTRIES_EXPENSE_CATEGORIES_ROUTE =
  SETTINGS_CUSTOM_DATA_ENTRIES_ROUTE + '/expense-categories';
export const SETTINGS_CUSTOM_DATA_ENTRIES_TIME_ENTRY_ACTIVITIES_ROUTE =
  SETTINGS_CUSTOM_DATA_ENTRIES_ROUTE + '/time-entry-activities';
export const SETTINGS_CUSTOM_DATA_ENTRIES_THIRD_PARTY_PAYEES_ROUTE =
  SETTINGS_CUSTOM_DATA_ENTRIES_ROUTE + '/third-party-payees';
export const SETTINGS_PROFESSIONAL_WEBSITE_ROUTE =
  SETTINGS_ROUTE + '/professional-website';
export const SETTINGS_PLAN_ROUTE = SETTINGS_ROUTE + '/plan';

/* eSignature */
export const SIGNATURE_DOCUMENTS_ROUTE = SIGNATURE_ROUTE + '/documents';
export const SIGNATURE_TEMPLATES_ROUTE = SIGNATURE_ROUTE + '/templates';

/* Expenses */
export const EXPENSES_ACTIVITIES_ROUTE = EXPENSES_ROUTE + '/activities';
export const EXPENSES_TIME_ENTRIES_ROUTE = EXPENSES_ROUTE + '/time-entries';
export const EXPENSES_SUB_EXPENSES_ROUTE = EXPENSES_ROUTE + '/expenses';
export const EXPENSES_FLAT_FEES_ROUTE = EXPENSES_ROUTE + '/flat-fees';

/* Client */
export const CLIENT_ROUTE = '/client';
export const CLIENT_FUNDS_BALANCE_ROUTE = CLIENT_ROUTE + '/funds-balance';
export const CLIENT_PAY_INVOICES_ROUTE = CLIENT_ROUTE + '/pay-invoices';
export const CLIENT_PAY_INVOICE_ROUTE = CLIENT_ROUTE + '/pay-invoice/:id';

/* Mobile */
export const MOBILE_ROUTE = '/mobile';
export const MOBILE_SELECT_PLAN_ROUTE = MOBILE_ROUTE + '/select-plan';
export const MOBILE_SUCCESS_ROUTE = MOBILE_ROUTE + '/success';
export const MOBILE_MERCHANT_OPERATING_ROUTE =
  MOBILE_ROUTE + '/merchant-application/operating';
export const MOBILE_MERCHANT_TRUST_ROUTE =
  MOBILE_ROUTE + '/merchant-application/trust';

/* Profile */
export const PROFILE_SETTINGS_ROUTE = PROFILE_ROUTE + '/settings';
export const PROFILE_SECURITY_ROUTE = PROFILE_ROUTE + '/security';
export const PROFILE_CALENDAR_SYNC_ROUTE = PROFILE_ROUTE + '/calendar';
export const PROFILE_ROUNDING_RULES_ROUTE = PROFILE_ROUTE + '/rounding-rules';
