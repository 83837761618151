import * as Yup from 'yup';

import { PaymentMethodsTypes } from '__generated__/globalTypes';
import { ContactMatterSelectContactType } from 'components/fields/ContactMatterSelectFormField';

import { DepositFormValuesType } from './DepositForm.types';

export const DepositFormSchema = Yup.object().shape<
  Omit<DepositFormValuesType, 'amount'>
>({
  payments: Yup.array(
    Yup.object()
      .shape<DepositFormValuesType['payments'][0]>({
        paymentMethod: Yup.mixed<PaymentMethodsTypes>().required(
          'Please select a payment method'
        ),
        amount: Yup.number()
          .moreThan(0, 'Amount should be more than 0')
          .required('Please enter an amount'),
        memo: Yup.string().required('Please enter a memo'),
        contact: Yup.mixed<ContactMatterSelectContactType>().required(
          'Please select a contact / matter'
        ),
      })
      .required('Please describe a payment')
  ).required('Please describe payments'),
  date: Yup.string().nullable().required('Please select a deposit date'),
});
