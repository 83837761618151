import { gql } from '@apollo/client';

import { LEDGER_NAME_FRAGMENT } from 'components/common/LedgerName';

export const FETCH_RECONCILIATION_TRANSACTIONS = gql`
  query FetchReconciliationTransactions(
    $statementDate: Date!
    $reconciliationId: ID
  ) {
    transactions: listReconciliationTransactions(
      listReconciliationTransactionsInput: {
        statementDate: $statementDate
        reconciliationId: $reconciliationId
      }
    ) {
      id
      date
      type
      status
      amount
      ledger {
        ...LedgerName
      }
      payeeOrSource {
        ...LedgerName
      }
      children {
        id
        type
        amount
        ledger {
          ...LedgerName
        }
        payeeOrSource {
          ...LedgerName
        }
      }
    }
  }
  ${LEDGER_NAME_FRAGMENT}
`;
