import { gql } from '@apollo/client';

export const FETCH_TRUST_TRANSFER = gql`
  query FetchTrustTransfer($id: ID!) {
    trustTransfer: getTrustTransfer(id: $id) {
      id
      date
      amount
      fromContact {
        id
        name
      }
      fromMatter {
        id
        name
      }
      toContact {
        id
        name
      }
      toMatter {
        id
        name
      }
      description
      status
    }
  }
`;
