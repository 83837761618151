import React from 'react';
import { Modal } from '@appclose/core';
import { Button, ButtonGroup, Flex } from '@appclose/ui';
import { UpgradeModeStrategy } from '__generated__/globalTypes';

import { I18n } from 'i18n';

import {
  TransferExistingInvoicesModalPropsType,
} from './TransferExistingInvoicesModal.types';

import styles from './TransferExistingInvoicesModal.module.scss';

export default function TransferExistingInvoicesModal({
  onComplete,
  onCancel,
}: TransferExistingInvoicesModalPropsType) {
  return (
    <Modal
      title={<I18n id="modal.popup.transferExistingInvoices.title" />}
      onClose={onCancel}
    >
      <section className={styles.container}>
        <Flex
          justify="center"
          direction="column"
          className={styles.content}
        >
          <I18n id="modal.popup.transferExistingInvoices.content" />
        </Flex>
        <Flex as="footer" justify="center">
          <ButtonGroup className={styles.buttons}>
            <Button
              onClick={() => onComplete(UpgradeModeStrategy.CLEAR_DATA)}
            >
              <I18n id="modal.popup.transferExistingInvoices.clear" />
            </Button>
            <Button
              skin="primary"
              onClick={() => onComplete(UpgradeModeStrategy.MIGRATE_DATA)}
            >
              <I18n id="modal.popup.transferExistingInvoices.migrate" />
            </Button>
          </ButtonGroup>
        </Flex>
      </section>
    </Modal>
  );
}
