import React from 'react';
import { useDispatch } from 'react-redux';
import { openConfirmAction, traceError } from '@appclose/core';
import { PrintingChecksModes } from '__generated__/globalTypes';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { PRINT_CHECKS_MODAL, PRINT_SETTINGS_MODAL } from 'constants/modals';
import { openModal } from 'controllers/modal';
import usePrintChecks from 'hooks/usePrintChecks';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { I18n, useIntl } from 'i18n';

import PrintingOptionsForm, {
  PrintingOptionsFormActionsType,
  PrintingOptionsFormValuesType,
} from './components/PrintingOptionsForm';
import { PrintingOptionsModalPropsType } from './PrintingOptionsModal.types';

export default function PrintingOptionsModal({
  onClose,
  ...restData
}: PrintingOptionsModalPropsType) {
  const { t } = useIntl();
  const dispatch = useDispatch();
  const { savePrintingChecksSettings } = usePrintChecks();

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });

  const handleOnSubmit = async (
    values: PrintingOptionsFormValuesType,
    { setSubmitting }: PrintingOptionsFormActionsType
  ) => {
    dispatch(
      openConfirmAction({
        name: t('modal.printingOptions.submit.confirm.name'),
        content: t('modal.printingOptions.submit.confirm.content'),
        okButtonTitle: t('modal.printingOptions.submit.confirm.okButton'),
        okButtonSkin: 'primary',
        cancelButtonTitle: t(
          'modal.printingOptions.submit.confirm.cancelButton'
        ),
        onConfirm: async () => {
          try {
            await savePrintingChecksSettings(values);

            notification().entityCreated(
              t('modal.printingOptions.notification.created')
            );

            onClose();

            openModal(
              values.mode === PrintingChecksModes.QBO
                ? PRINT_CHECKS_MODAL
                : PRINT_SETTINGS_MODAL,
              { ...restData }
            );
          } catch (e) {
            traceError(e);
            setSubmitting(false);
          }
        },
      })
    );
  };

  const initialValues = ({
    mode: undefined,
  } as any) as PrintingOptionsFormValuesType;

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.printingOptions.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        <PrintingOptionsForm
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          onCancel={onConfirmClose}
          onChange={onFormChange}
        />
      </ModalPageContent>
    </ModalPage>
  );
}
