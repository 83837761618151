import { gql } from '@apollo/client';

import { LEDGER_NAME_FRAGMENT } from 'components/common/LedgerName';

export const OTO_FIELD_FRAGMENT = gql`
  fragment OtoField on DisbursementType {
    id
    date
    type
    amount
    ledger {
      ...LedgerName
    }
  }
  ${LEDGER_NAME_FRAGMENT}
`;

export const FETCH_OTOS = gql`
  query FetchOtos($input: ListOtoInput) {
    otos: listOtos(listOtoInput: $input) {
      total
      items {
        ...OtoField
      }
    }
  }
  ${OTO_FIELD_FRAGMENT}
`;
