import { useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { openConfirmAction, usePopupModal } from '@appclose/core';
import { Note } from '@appclose/ui';

import { UpgradeModeStrategy } from '__generated__/globalTypes';
import { gqlClientServices, refreshToken } from 'gql';
import { I18n, useIntl } from 'i18n';
import notification from 'controllers/notification';
import { session } from 'controllers/session';
import useImperativeQuery from 'hooks/useImperativeQuery';
import useDemo from 'hooks/useDemo';
import TransferExistingInvoicesModal from 'components/modals/popups/TransferExistingInvoicesModal';
import { PermissionsContext } from 'components/common/App/components/PermissionsProvider';

import { UseUpgradeModeOptionsType } from './useUpgradeMode.types';
import {
  UpgradeModeMutation,
  UpgradeModeMutationVariables,
  FetchInvoicesTotalQuery,
  FetchInvoicesTotalQueryVariables,
} from './__generated__/useUpgradeMode.gql';
import { FETCH_INVOICES_TOTAL, UPGRADE_MODE } from './useUpgradeMode.gql';

export default function useUpgradeMode({
  onCompleted,
}: UseUpgradeModeOptionsType): any {
  const { t } = useIntl();
  const { onOpenDemoUnavailableConfirmOrUserCallback } = useDemo();
  const dispatch = useDispatch();
  const { setToken } = useContext(PermissionsContext);

  const [upgradeModeMutation, { loading: loadingUpgradeMode }] = useMutation<
    UpgradeModeMutation,
    UpgradeModeMutationVariables
  >(UPGRADE_MODE, {
    async onCompleted() {
      await refreshToken();

      setToken(session.getAccessToken());

      gqlClientServices.reconnectWs();

      notification().successAction(t('hook.useUpgradeMode.update.success'));

      onCompleted();
    },
  });

  const [
    fetchInvoicesTotal,
    { loading: loadingFetchInvoicesTotal },
  ] = useImperativeQuery<
    FetchInvoicesTotalQuery,
    FetchInvoicesTotalQueryVariables
  >(FETCH_INVOICES_TOTAL);

  const loading = loadingUpgradeMode || loadingFetchInvoicesTotal;

  const upgradeMode = useCallback(
    async (strategy: UpgradeModeStrategy = UpgradeModeStrategy.CLEAR_DATA) => {
      await upgradeModeMutation({ variables: { strategy } });
    },
    [upgradeModeMutation]
  );

  const { openPopupModal: openTransferExistingInvoicesModal } = usePopupModal(
    TransferExistingInvoicesModal,
    upgradeMode
  );

  const openUpgradeModeConfirm = useCallback(
    (onConfirm) => {
      dispatch(
        openConfirmAction({
          name: t('hook.useUpgradeMode.confirm.title'),
          content: (
            <I18n
              id="hook.useUpgradeMode.confirm.content"
              values={{
                note: (string: string) => (
                  <Note theme="important">{string}</Note>
                ),
              }}
            />
          ),
          okButtonSkin: 'primary',
          okButtonTitle: t('hook.useUpgradeMode.confirm.ok'),
          cancelButtonTitle: t('hook.useUpgradeMode.confirm.cancel'),
          onConfirm,
        })
      );
    },
    [t, dispatch]
  );

  const upgradeModeExecute = useCallback(async () => {
    const {
      data: {
        listInvoices: { total: totalInvoices },
      },
    } = await fetchInvoicesTotal();

    openUpgradeModeConfirm(() => {
      if (totalInvoices > 0) {
        openTransferExistingInvoicesModal();
      } else {
        upgradeMode();
      }
    });
  }, [
    fetchInvoicesTotal,
    openUpgradeModeConfirm,
    openTransferExistingInvoicesModal,
    upgradeMode,
  ]);

  const upgradeModeProcessing = useCallback(
    () =>
      onOpenDemoUnavailableConfirmOrUserCallback(
        t('hook.useUpgradeMode.confirm.title'),
        upgradeModeExecute
      ),
    [onOpenDemoUnavailableConfirmOrUserCallback, t, upgradeModeExecute]
  );

  return [upgradeModeProcessing, { loading }];
}
