import React from 'react';
import { Button, ButtonGroup } from '@appclose/ui';
import { Form, Tab, TabList, TabPanel, Tabs } from '@appclose/core';

import { I18n, useIntl } from 'i18n';

import ContactFieldset from './components/ContactFieldset';
import EntityFieldset from './components/EntityFieldset';
import { ContactFormSchema } from './ContactForm.schema';
import { ContactAndEntityFormPropsType } from './ContactForm.types';
import { ContactModalModalTabs } from '../../ContactModal.types';
import MultipleImportFieldset from './components/MultipleImportFieldset';

export default function ContactForm({
  initialValues = {} as any,
  isEdit,
  isLead,
  defaultTab,
  onSubmit,
  onCancel,
  onChange,
}: ContactAndEntityFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      onChange={onChange}
      validationSchema={ContactFormSchema}
    >
      {(form) => {
        const {
          isSubmitting,
          setFieldValue,
          values: { id, selectedTab, importSummary },
        } = form;
        const isMultipleImport =
          selectedTab === ContactModalModalTabs.MULTIPLE_IMPORT;
        const showButtons = !isMultipleImport || !isSubmitting;
        const showCancel = showButtons && (!isMultipleImport || !importSummary);
        const submitButtonText = (() => {
          switch (true) {
            case isMultipleImport && importSummary:
              return t('modal.contact.form.submit.importSuccess');
            case !isMultipleImport && isEdit:
              return t('modal.contact.form.submit.save');
            case !isMultipleImport && !isEdit:
              return t('modal.contact.form.submit.create');
            case isMultipleImport:
              return t('modal.contact.form.submit.import');
          }
        })();

        return (
          <>
            {isEdit && selectedTab === ContactModalModalTabs.INDIVIDUAL && (
              <ContactFieldset id={id} />
            )}
            {isEdit && selectedTab === ContactModalModalTabs.ENTITY && (
              <EntityFieldset id={id} />
            )}
            {!isEdit && (
              <Tabs
                defaultIndex={defaultTab}
                onSelect={(index) => setFieldValue('selectedTab', index)}
              >
                <TabList>
                  <Tab>
                    <I18n id="modal.contact.form.tab.individual" />
                  </Tab>
                  <Tab>
                    <I18n id="modal.contact.form.tab.entity" />
                  </Tab>
                  {!isLead && (
                    <Tab>
                      <I18n id="modal.contact.form.tab.multipleImport" />
                    </Tab>
                  )}
                </TabList>
                <TabPanel>
                  <ContactFieldset />
                </TabPanel>
                <TabPanel>
                  <EntityFieldset />
                </TabPanel>
                {!isLead && (
                  <TabPanel>
                    <MultipleImportFieldset />
                  </TabPanel>
                )}
              </Tabs>
            )}
            {showButtons && (
              <ButtonGroup>
                {showCancel && (
                  <Button onClick={onCancel}>
                    <I18n id="modal.contact.form.cancel" />
                  </Button>
                )}
                <Button loading={isSubmitting} type="submit" skin="primary">
                  {submitButtonText}
                </Button>
              </ButtonGroup>
            )}
          </>
        );
      }}
    </Form>
  );
}
