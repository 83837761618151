import React, { useCallback } from 'react';
import set from 'lodash/set';
import { Grid } from '@appclose/ui';
import {
  AmountFormField,
  FormGroup,
  useFormContext,
  useIsMobileDevice,
} from '@appclose/core';

import { AccessLevels } from '__generated__/globalTypes';
import FieldsetFormArray from 'components/form/FieldsetFormArray';
import TeamMemberSelectFormField from 'components/fields/TeamMemberSelectFormField';
import RoleSelectFormField from 'components/fields/RoleSelectFormField';
import FormGrid from 'components/common/FormGrid';
import { sanitizeAmount } from 'controllers/amount';
import { useIntl } from 'i18n';

import { TeamMembersFieldsetPropsType } from './TeamMembersFieldset.types';
import { MatterFormValuesType } from '../../MatterForm.types';

export default function TeamMembersFieldset({
  name = 'teamMembers',
  title,
  addButtonLabel,
  exclude,
}: TeamMembersFieldsetPropsType) {
  const { t } = useIntl();
  const isMobile = useIsMobileDevice();
  const { setValues } = useFormContext<MatterFormValuesType>();

  const setInitialTeamMemberValues = useCallback(
    (resolveName: (fieldName?: string | undefined) => string) => (
      teamMember: any
    ) => {
      setValues((values) =>
        set(
          set(values, resolveName('role'), teamMember?.role),
          resolveName('hourlyRate'),
          sanitizeAmount(teamMember?.hourlyRate)
        )
      );
    },
    [setValues]
  );

  return (
    <FieldsetFormArray
      title={title || t('modal.matter.form.fieldset.teamMembers.title')}
      name={name}
      addButtonLabel={
        addButtonLabel || t('modal.matter.form.fieldset.teamMembers.addButton')
      }
      allowRemoveLast={false}
    >
      {({ resolveName, value }) => {
        const allowLeadAttorney = [
          AccessLevels.SUPER_ADMIN,
          AccessLevels.ADMIN,
        ].includes(value?.member?.accessLevel);

        return (
          <FormGroup>
            <FormGrid>
              <TeamMemberSelectFormField
                name={resolveName('member')}
                label={t('modal.matter.form.field.member.label')}
                placeholder={t('modal.matter.form.field.member.placeholder')}
                exclude={exclude}
                onChange={setInitialTeamMemberValues(resolveName)}
              />
              <Grid gap={[40, 20]} columns={isMobile ? '1fr' : '3fr 2fr'}>
                <RoleSelectFormField
                  name={resolveName('role')}
                  forMatter
                  allowLeadAttorney={allowLeadAttorney}
                />
                <AmountFormField
                  name={resolveName('hourlyRate')}
                  label={t('modal.matter.form.field.hourlyRate')}
                />
              </Grid>
            </FormGrid>
          </FormGroup>
        );
      }}
    </FieldsetFormArray>
  );
}
