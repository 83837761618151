import React, { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import CalendarSyncModal, { CalendarSyncFormValuesType } from 'components/common/CalendarSyncModal';
import { GoogleCalendarSyncModalPropsType } from './GoogleCalendarSyncModal.types';
import { FETCH_GOOGLE_CALENDARS, SYNC_GOOGLE_CALENDAR } from './GoogleCalendarSyncModal.gql';
import {
  FetchGoogleCalendarsQuery,
  FetchGoogleCalendarsQueryVariables,
  SyncGoogleCalendarMutation,
  SyncGoogleCalendarMutationVariables,
} from './__generated__/GoogleCalendarSyncModal.gql';

export default function GoogleCalendarSyncModal({
  onClose,
}: GoogleCalendarSyncModalPropsType) {
  const { loading, data } = useQuery<
    FetchGoogleCalendarsQuery,
    FetchGoogleCalendarsQueryVariables
    >(FETCH_GOOGLE_CALENDARS, {
    fetchPolicy: 'network-only',
  });

  const [syncGoogleCalendar] = useMutation<
    SyncGoogleCalendarMutation,
    SyncGoogleCalendarMutationVariables
  >(SYNC_GOOGLE_CALENDAR);

  const onSubmit = useCallback(
    async (input: CalendarSyncFormValuesType) => {
      await syncGoogleCalendar({
        variables: {
          input,
        },
      });
    },
    [syncGoogleCalendar]
  );

  return (
    <CalendarSyncModal calendarName="Google" loading={loading} calendars={data?.calendars} onSubmit={onSubmit} onClose={onClose} />
  );
}
