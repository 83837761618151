import { gql } from '@apollo/client';

import { ADDRESS_FRAGMENT } from 'components/common/Address';

export const FETCH_FIRM = gql`
  query FetchFirm {
    firm: getFirm {
      id
      stripeKybStatus
      stripeKybErrors
      owner: ownerInfo {
        firstName
        lastName
        phoneNumber
        ssn
        dateOfBirth
        address {
          ...Address
        }
      }
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export const SEND_KYB = gql`
  mutation SendKyb($input: KybInput!) {
    sendKyb(kybInput: $input) {
      success
    }
  }
`;