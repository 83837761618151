import React from 'react';
import classnames from 'classnames';
import { Button } from '@appclose/ui';
import { EditIcon, DownloadIcon, TrashIcon } from '@appclose/icons';

import { ActionPropsType } from './Action.types';
import styles from './Action.module.scss';

const icons = {
  edit: <EditIcon />,
  download: <DownloadIcon />,
  delete: <TrashIcon />,
};

export default function Action({
  type,
  className,
  onClick,
  loading,
}: ActionPropsType) {
  return (
    <Button
      skin="shell"
      className={classnames(styles.action, className, {
        [styles.loading]: loading,
      })}
      loading={loading}
      onClick={onClick}
    >
      {icons[type]}
    </Button>
  );
}
