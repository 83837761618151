import React, { MouseEvent, useCallback, useRef, useState } from 'react';
import { toPng } from 'html-to-image';
import { downloadFileByUrl } from '@appclose/lib';
import { Button, Grid } from '@appclose/ui';
import { DownloadIcon, WidgetIcon } from '@appclose/icons';
import { Dropdown, OverlayLoader, useCopyToClipboard } from '@appclose/core';

import { I18n, IntlMessageIdType, useIntl } from 'i18n';
import notification from 'controllers/notification';
import { track } from 'controllers/analytics';
import { EventNames } from 'constants/analytics';

import {
  PaymentButtonBackground,
  PaymentButtonColor,
  PaymentButtonEventTypes,
  PaymentButtonMethods,
  PaymentButtonPropsType,
  PaymentButtonText,
} from './PaymentButton.types';
import { ReactComponent as MonesqIcon } from './assets/monesq.svg';
import { ReactComponent as VisaIcon } from './assets/visa.svg';
import { ReactComponent as MastercardIcon } from './assets/mastercard.svg';
import { ReactComponent as DiscoverIcon } from './assets/discover.svg';
import { ReactComponent as AmexIcon } from './assets/amex.svg';
import { ReactComponent as EcheckIcon } from './assets/echeck.svg';
import { ReactComponent as MonesqPayIcon } from './assets/monesq-pay.svg';
import { ReactComponent as VisaDarkIcon } from './assets/visa-dark.svg';
import { ReactComponent as MastercardDarkIcon } from './assets/mastercard-dark.svg';
import { ReactComponent as DiscoverDarkIcon } from './assets/discover-dark.svg';
import { ReactComponent as AmexDarkIcon } from './assets/amex-dark.svg';
import { ReactComponent as EcheckDarkIcon } from './assets/echeck-dark.svg';
import { ReactComponent as MonesqPayDarkIcon } from './assets/monesq-pay-dark.svg';
import styles from './PaymentButton.module.scss';

export default function PaymentButton({
  link,
  language,
  background,
  color,
  text,
  paymentMethods = Object.values(PaymentButtonMethods),
  eventButtonVariation,
}: PaymentButtonPropsType) {
  const { t } = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const [generatingImage, setGeneratingImageStatus] = useState(false);
  const [, copyToClipboard] = useCopyToClipboard();

  const handleOnLinkClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => e.preventDefault(),
    []
  );

  const trackEvent = (type: PaymentButtonEventTypes) => {
    track(EventNames.PAYMENT_BUTTON_ADDED, {
      complete_type: type,
      language,
      wbsite_background: background,
      button_color: color,
      button_text: text,
      button_variation: eventButtonVariation,
    });
  };

  const onDownloadImage = async () => {
    if (ref.current) {
      setGeneratingImageStatus(true);

      await toPng(ref.current).then((url) =>
        downloadFileByUrl(url, { filename: 'monesq-payment-button' })
      );

      setGeneratingImageStatus(false);

      trackEvent(PaymentButtonEventTypes.DOWNLOAD);
    }
  };

  const onCopyWidget = () => {
    if (ref.current) {
      copyToClipboard(ref.current.outerHTML);

      notification().info(
        t('modal.paymentButton.notification.widgetCopied'),
        <WidgetIcon fill="#fff" />
      );

      trackEvent(PaymentButtonEventTypes.COPY);
    }
  };

  return (
    <Dropdown
      toggleComponent={() => (
        <OverlayLoader loading={generatingImage}>
          <div ref={ref}>
            <style>
              {`
            .monesq-aside {
              display: inline-block;
              border-radius: 10px;
              transition: background-color ease-out 0.5s;
            }

            .monesq-aside:hover {
              background-color: ${
                background === PaymentButtonBackground.DARK
                  ? 'rgba(255, 255, 255, 0.05)'
                  : '#f8f9fa'
              };
            }

            .monesq-aside:active {
              background-color: ${
                background === PaymentButtonBackground.DARK
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(32, 194, 210, 0.2)'
              };
            }

            .monesq-link {
              display: block;
              padding: 20px;
              color: #1b1d1e;
              text-decoration: none !important;
              font-family: Arial, sans-serif;
              font-size: 16px;
              font-weight: 700;
              line-height: 1.25;
            }

            .monesq-button {
              padding: 12px 20px;
              border-radius: 6px;
              text-align: center;
              white-space: nowrap;

              ${
                text === PaymentButtonText.PAY_WITH_MONESQ ? 'min-width: 188px;' : ''
              }

              ${
                ![PaymentButtonColor.YELLOW, PaymentButtonColor.DARK].includes(
                  color
                )
                  ? `
                    background-color: #fff;
                    box-shadow: ${
                      background === PaymentButtonBackground.DARK
                        ? '0 5px 12px rgba(0, 0, 0, 0.07)'
                        : '0 0 0 0.3px #d1d2d2, 0 5px 12px rgba(0, 0, 0, 0.07)'
                    };
                  `
                  : ''
              }

              ${
                color === PaymentButtonColor.YELLOW
                  ? `
                    background-color: #fddc03;
                    box-shadow: 0px 5px 12px rgba(253, 220, 3, 0.2);
                  `
                  : ''
              }

              ${
                color === PaymentButtonColor.DARK
                  ? `
                    background-color: #1b1d1e;
                    color: #fff;
                    box-shadow: 0 0 0 0.3px #d1d2d2, 0 5px 12px rgba(0, 0, 0, 0.1);
                  `
                  : ''
              }
            }

            .monesq-icon {
              margin-left: 6px;
              vertical-align: text-bottom;
            }

            .monesq-methods {
              display: flex;
              gap: 4px;
              margin: 10px 0 0;
              padding: 0;
              list-style: none;
              align-items: center;
              justify-content: center;
            }

            .monesq-methods li {
              line-height: 0;
            }
          `}
            </style>
            <aside className="monesq-aside">
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="monesq-link"
                onClick={handleOnLinkClick}
              >
                <div className="monesq-button">
                  <I18n
                    id={
                      `modal.paymentButton.form.text.${text}.${language}` as IntlMessageIdType
                    }
                  />
                  {text === PaymentButtonText.PAY_WITH_MONESQ && (
                    <MonesqIcon
                      className="monesq-icon"
                      fill={color === PaymentButtonColor.DARK ? '#fff' : '#000'}
                    />
                  )}
                </div>
                <ul className="monesq-methods">
                  {paymentMethods.map((method) => (
                    <li key={method}>
                      {method === PaymentButtonMethods.VISA &&
                        (background === PaymentButtonBackground.DARK ? (
                          <VisaDarkIcon />
                        ) : (
                          <VisaIcon />
                        ))}
                      {method === PaymentButtonMethods.MASTERCARD &&
                        (background === PaymentButtonBackground.DARK ? (
                          <MastercardDarkIcon />
                        ) : (
                          <MastercardIcon />
                        ))}
                      {method === PaymentButtonMethods.DISCOVER &&
                        (background === PaymentButtonBackground.DARK ? (
                          <DiscoverDarkIcon />
                        ) : (
                          <DiscoverIcon />
                        ))}
                      {method === PaymentButtonMethods.AMEX &&
                        (background === PaymentButtonBackground.DARK ? (
                          <AmexDarkIcon />
                        ) : (
                          <AmexIcon />
                        ))}
                      {method === PaymentButtonMethods.ECHECK &&
                        (background === PaymentButtonBackground.DARK ? (
                          <EcheckDarkIcon />
                        ) : (
                          <EcheckIcon />
                        ))}
                      {method === PaymentButtonMethods.MONESQ &&
                        (background === PaymentButtonBackground.DARK ? (
                          <MonesqPayDarkIcon />
                        ) : (
                          <MonesqPayIcon />
                        ))}
                    </li>
                  ))}
                </ul>
              </a>
            </aside>
          </div>
        </OverlayLoader>
      )}
      content={() => (
        <Grid gap={[0, 4]} className={styles.context}>
          <Button
            skin="shell"
            className={styles.button}
            onClick={onDownloadImage}
          >
            <DownloadIcon />
            <I18n id="modal.paymentButton.downloadImage" />
          </Button>
          <Button skin="shell" className={styles.button} onClick={onCopyWidget}>
            <WidgetIcon />
            <I18n id="modal.paymentButton.copyWidget" />
          </Button>
        </Grid>
      )}
    />
  );
}
