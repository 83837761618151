import React from 'react';
import set from 'lodash/set';
import { Form, FormGroup, Fieldset } from '@appclose/core';

import { Modes, BankAccountClasses } from '__generated__/globalTypes';
import FormGrid from 'components/common/FormGrid';
import ContactSelectFormField, {
  SelectContactType,
} from 'components/fields/ContactSelectFormField';
import ModeGuard from 'components/common/ModeGuard';
import MatterSelectFormField, {
  SelectMatterType,
} from 'components/fields/MatterSelectFormField';
import BankAccountDueStatistic from '../BankAccountDueStatistic';
import CommonFormContent from '../CommonFormContent';

import { isPlusPlan } from 'controllers/mode';
import { useIntl } from 'i18n';

import {
  ReceivePaymentFormPropsType,
  ReceivePaymentFormValuesType,
} from '../../PaymentInfoFormsBlock.types';
import { ReceivePaymentFormSchema } from '../../PaymentInfoFormsBlock.schema';

export default function ToOperatingForm({
  initialValues,
  onSubmit,
  onCancel,
  onChange,
  setContact,
  setMatter,
}: ReceivePaymentFormPropsType) {
  const { t } = useIntl();
  const fieldsetTitle = isPlusPlan()
    ? t('modal.receivePayment.form.fieldset.contactAndMatter')
    : t('modal.receivePayment.form.fieldset.contact');

  return (
    <Form<ReceivePaymentFormValuesType>
      initialValues={initialValues}
      validationSchema={ReceivePaymentFormSchema}
      onSubmit={onSubmit}
      onChange={onChange}
    >
      {({ setValues, values: { contact } }) => {
        const onChangeMatter = (matter?: SelectMatterType) => {
          setValues((values) => ({
            ...values,
            matter,
            invoices: [],
            contact: contact || matter?.contact,
          }));
        };

        const onChangeContact = (contact?: SelectContactType) => {
          setValues((values) =>
            set(set(values, 'invoices', []), 'matter', undefined)
          );

          setContact(contact);
        };

        return (
          <>
            <Fieldset title={fieldsetTitle}>
              <FormGroup>
                <FormGrid>
                  <ContactSelectFormField
                    showLabel="always"
                    onChange={onChangeContact}
                  />
                  <ModeGuard mode={Modes.PLUS}>
                    <MatterSelectFormField
                      showLabel="always"
                      placeholder={t(
                        'modal.receivePayment.form.matterSelectPlaceholder'
                      )}
                      onChange={onChangeMatter}
                      filter={{
                        clientId: contact?.id,
                      }}
                    />
                  </ModeGuard>
                </FormGrid>
              </FormGroup>
              <FormGroup>
                <FormGrid>
                  <BankAccountDueStatistic
                    bankAccountClass={BankAccountClasses.OPERATING}
                  />
                </FormGrid>
              </FormGroup>
            </Fieldset>
            <CommonFormContent onCancel={onCancel} />
          </>
        );
      }}
    </Form>
  );
}
