import React, { useCallback } from 'react';
import { DownloadIcon, EmailIcon, RefundIcon } from '@appclose/icons';

import { TransactionTypes } from '__generated__/globalTypes';
import { useIntl } from 'i18n';
import {
  PAYMENT_REFUND_MODAL,
  SEND_PAYMENT_RECEIPT_MODAL,
} from 'constants/modals';
import { isPaymentRefundable } from 'controllers/payment';
import ActionsPanel, {
  ActionsPanelButton,
} from 'components/common/ActionsPanel';
import { PermissionGuard } from 'components/common/PermissionGuard';
import { openModal } from 'controllers/modal';
import usePaymentActions from 'hooks/usePaymentActions';

import { PermissionActions, PermissionResources } from 'constants/permissions';
import { PaymentActionsPropsType } from './PaymentActions.types';

export default function PaymentActions({ payment }: PaymentActionsPropsType) {
  const { t } = useIntl();
  const { id } = payment;
  const isRefundable = isPaymentRefundable(payment);

  const { downloadPaymentPdf, downloadPaymentPdfLoading } = usePaymentActions({
    id,
    type: TransactionTypes.PAYMENT,
  });

  const openPaymentRefund = useCallback(() => {
    openModal(PAYMENT_REFUND_MODAL, { id });
  }, [id]);

  const openSendPaymentReceipt = useCallback(() => {
    openModal(SEND_PAYMENT_RECEIPT_MODAL, { id });
  }, [id]);

  return (
    <ActionsPanel>
      <PermissionGuard
        resource={PermissionResources.PAYMENT}
        action={PermissionActions.SEND}
      >
        <ActionsPanelButton
          skin="primary"
          icon={<EmailIcon />}
          title={t('modal.paymentDetails.actions.sendReceipt')}
          onClick={openSendPaymentReceipt}
        />
      </PermissionGuard>
      <PermissionGuard
        resource={PermissionResources.PAYMENT}
        action={PermissionActions.READ}
      >
        <ActionsPanelButton
          skin="secondary"
          icon={<DownloadIcon />}
          title={t('modal.paymentDetails.actions.downloadPdf')}
          loading={downloadPaymentPdfLoading}
          onClick={downloadPaymentPdf}
        />
      </PermissionGuard>
      {isRefundable && (
        <PermissionGuard
          resource={PermissionResources.REFUND_PAYMENT}
          action={PermissionActions.CREATE}
        >
          <ActionsPanelButton
            skin="warning"
            icon={<RefundIcon />}
            onClick={openPaymentRefund}
            title={t('modal.paymentDetails.actions.refund')}
          />
        </PermissionGuard>
      )}
    </ActionsPanel>
  );
}
