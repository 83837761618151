import {
  FormGroup,
  InputFormField,
  UpperFirstInputFormField,
  TextAreaFormField,
  ToggleFormField,
  Fieldset,
} from '@appclose/core';

import { useIntl } from 'i18n';
import FormGrid from 'components/common/FormGrid';
import PhoneFieldset from 'components/form/PhoneFieldset';
import ContactsFieldset from 'components/form/ContactsFieldset';
import LeadSourceSelectFormField from 'components/fields/LeadSourceSelectFormField';
import TeamMemberSelectFormField from 'components/fields/TeamMemberSelectFormField';
import { Entities } from 'constants/entities';
import FilesFormField from 'components/fields/FilesFormField';

import { EntityFieldsetPropsType } from './EntityFieldset.types';

import styles from './EntityFieldset.module.scss';

const EntityFieldset = ({ id }: EntityFieldsetPropsType) => {
  const { t } = useIntl();

  return (
    <>
      <Fieldset title={t('modal.lead.form.info')}>
        <FormGrid>
          <UpperFirstInputFormField
            name="entity.entityName"
            label={t('modal.lead.form.entityName')}
          />
          <InputFormField
            name="entity.website"
            label={t('modal.lead.form.website')}
          />
        </FormGrid>
      </Fieldset>
      <ContactsFieldset name="entity.contacts" />
      <PhoneFieldset name="entity.phoneNumbers" allowRemoveLast />
      <Fieldset title={t('modal.lead.form.other')}>
        <FormGroup>
          <TextAreaFormField
            name="entity.notes"
            label={t('modal.lead.form.notes')}
          />
        </FormGroup>
        <FormGroup>
          <FormGrid>
            <TeamMemberSelectFormField
              name="entity.originatingAttorney"
              label={t('modal.lead.form.originatingAttorney')}
            />
            <LeadSourceSelectFormField name="entity.leadSource" />
          </FormGrid>
        </FormGroup>
        <FormGroup>
          <FilesFormField entityType={Entities.LEAD} entityId={id} />
        </FormGroup>
        <FormGroup className={styles.toggle}>
          <ToggleFormField
            name="entity.isImportant"
            label={t('modal.lead.form.toggle')}
          />
        </FormGroup>
      </Fieldset>
    </>
  );
};

export default EntityFieldset;
