import * as Yup from 'yup';

import { sanitizeAmount } from 'controllers/amount';
import { InvoiceCreditMemoInvoiceType } from 'components/modals/pages/InvoiceCreditMemoModal';
import { getI18nErrorMessage } from 'i18n';
import { FILES_SCHEMA } from 'schemas/validations/files';

import { InvoiceCreditMemoFormValuesType } from './InvoiceCreditMemoForm.types';

export const InvoiceCreditMemoFormFormSchema = Yup.object().shape<InvoiceCreditMemoFormValuesType>(
  {
    invoice: Yup.object<InvoiceCreditMemoInvoiceType>().required(
      getI18nErrorMessage('modal.creditMemo.form.error.invoice.required')
    ),
    creditAmount: Yup.number()
      .required(
        getI18nErrorMessage(
          'modal.creditMemo.form.error.memoDetails.creditAmount.required'
        )
      )
      .when(['invoice'], (invoice: InvoiceCreditMemoInvoiceType) =>
        Yup.number()
          .max(
            sanitizeAmount(invoice.balanceDue),
            getI18nErrorMessage(
              'modal.creditMemo.form.error.memoDetails.creditAmount.max'
            )
          )
          .min(1, ({ min }) =>
            getI18nErrorMessage(
              'modal.creditMemo.form.error.memoDetails.creditAmount.min',
              { min }
            )
          )
      ),
    memoText: Yup.string(),
    sendEmail: Yup.boolean().required(),
    files: FILES_SCHEMA,
  }
);
