import { gql } from '@apollo/client';

export const TIME_EXPENSE_CATEGORY_MODAL_FRAGMENT = gql`
  fragment ExpenseCategory on SubExpenseCategoryType {
    id
    name
    costType
  }
`;

export const CREATE_EXPENSE_CATEGORY = gql`
  mutation CreateExpenseCategory($input: CreateSubExpenseCategoryInput!) {
    category: createSubExpenseCategory(createSubExpenseCategory: $input) {
      ...ExpenseCategory
    }
  }
  ${TIME_EXPENSE_CATEGORY_MODAL_FRAGMENT}
`;

export const FETCH_EXPENSE_CATEGORY = gql`
  query FetchExpenseCategory($id: ID!) {
    category: getSubExpenseCategory(id: $id) {
      ...ExpenseCategory
    }
  }
  ${TIME_EXPENSE_CATEGORY_MODAL_FRAGMENT}
`;

export const EDIT_EXPENSE_CATEGORY = gql`
  mutation EditExpenseCategory($input: UpdateSubExpenseCategoryInput!) {
    category: updateSubExpenseCategory(updateSubExpenseCategoryInput: $input) {
      ...ExpenseCategory
    }
  }
  ${TIME_EXPENSE_CATEGORY_MODAL_FRAGMENT}
`;
