import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import max from 'lodash/max';
import classnames from 'classnames';
import without from 'lodash/without';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { openConfirmAction, permissionProvider } from '@appclose/core';

import { TimerStatusTypes } from '__generated__/globalTypes';
import {
  EventNames,
  SectionTypes,
  TimerActionTypes,
} from 'constants/analytics';
import { TIME_ENTRY_MODAL } from 'constants/modals';
import { openModal } from 'controllers/modal';
import { track } from 'controllers/analytics';
import useEntityRouteMatch from 'hooks/useEntityRouteMatch';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import { TOUR_STEPS_NAME, TourStepTarget } from 'components/common/Tour';
import { useIntl } from 'i18n';

import {
  CreateTimerMutation,
  CreateTimerMutationVariables,
  FetchMatterQuery,
  FetchMatterQueryVariables,
  FetchTimersQuery,
  FetchTimersQueryVariables,
  TimerCreatedSubscription,
  TimerCreatedSubscriptionVariables,
  TimerFragmentFragment,
  TimerUpdatedSubscription,
  TimerUpdatedSubscriptionVariables,
  UpdateTimerInfoMutation,
  UpdateTimerInfoMutationVariables,
  UpdateTimerStatusMutation,
  UpdateTimerStatusMutationVariables,
} from './__generated__/TimeTracker.gql';
import Timer from './components/Timer';
import Timers from './components/Timers';
import { TimerFormType } from './components/Timers/components/TimerForm/TimerForm.types';
import {
  CREATE_TIMER,
  FETCH_MATTER,
  FETCH_TIMERS,
  ON_TIMER_CREATED,
  ON_TIMER_UPDATED,
  UPDATE_TIMER_INFO,
  UPDATE_TIMER_STATUS,
} from './TimeTracker.gql';
import { TimeTrackerPropsType } from './TimeTracker.types';
import styles from './TimeTracker.module.scss';

let processingTimers: string[] = [];

export default function TimeTracker({ className }: TimeTrackerPropsType) {
  const { t } = useIntl();
  const dispatch = useDispatch();
  const { matterId } = useEntityRouteMatch();
  const [showTimersStatus, setShowTimersStatus] = useState(false);
  const [loadingTimer, setLoadingTimer] = useState<string | undefined>();
  const statuses = [TimerStatusTypes.STARTED, TimerStatusTypes.PAUSED];
  const { data, refetch } = useQuery<
    FetchTimersQuery,
    FetchTimersQueryVariables
  >(FETCH_TIMERS, {
    variables: { statuses },
  });
  const { data: matterData } = useQuery<
    FetchMatterQuery,
    FetchMatterQueryVariables
  >(FETCH_MATTER, {
    variables: { id: matterId as string },
    skip: !matterId,
  });
  const [createTimer] = useMutation<
    CreateTimerMutation,
    CreateTimerMutationVariables
  >(CREATE_TIMER, {
    update: (proxy, { data: result }) => {
      if (result?.createTimer) {
        const query = {
          query: FETCH_TIMERS,
          variables: { statuses },
        };
        const data = proxy.readQuery<FetchTimersQuery>(query);

        if (data?.listTimers) {
          proxy.writeQuery<FetchTimersQuery, FetchTimersQueryVariables>({
            ...query,
            data: {
              ...data,
              listTimers: [...data.listTimers, result.createTimer],
            },
          });
        }
      }
    },
  });
  const [updateTimerStatus] = useMutation<
    UpdateTimerStatusMutation,
    UpdateTimerStatusMutationVariables
  >(UPDATE_TIMER_STATUS, {
    update: (proxy, { data: result }) => {
      if (result?.updateTimer) {
        const query = {
          query: FETCH_TIMERS,
          variables: { statuses },
        };
        const data = proxy.readQuery<FetchTimersQuery>(query);

        if (data?.listTimers) {
          proxy.writeQuery<FetchTimersQuery, FetchTimersQueryVariables>({
            ...query,
            data: {
              ...data,
              listTimers: data.listTimers.reduce(
                (timers: FetchTimersQuery['listTimers'], timer) => {
                  if (timer.id === result.updateTimer.id) {
                    if (
                      result.updateTimer.status === TimerStatusTypes.STOPPED
                    ) {
                      return timers;
                    }

                    return [...timers, { ...timer, ...result.updateTimer }];
                  }

                  return [...timers, timer];
                },
                []
              ),
            },
          });
        }
      }
    },
    onCompleted({ updateTimer: { id } }) {
      processingTimers = without(processingTimers, id);
    },
  });

  const [updateTimerInfo] = useMutation<
    UpdateTimerInfoMutation,
    UpdateTimerInfoMutationVariables
  >(UPDATE_TIMER_INFO);

  useSubscription<TimerCreatedSubscription, TimerCreatedSubscriptionVariables>(
    ON_TIMER_CREATED,
    {
      onSubscriptionData: () => refetch(),
      skip: !permissionProvider().hasPermission(
        PermissionResources.TIMER,
        PermissionActions.READ
      ),
    }
  );

  useSubscription<TimerUpdatedSubscription, TimerUpdatedSubscriptionVariables>(
    ON_TIMER_UPDATED,
    {
      onSubscriptionData: ({ subscriptionData }) => {
        if (
          subscriptionData.data?.timerUpdated?.status &&
          !statuses.includes(subscriptionData.data.timerUpdated.status)
        ) {
          return refetch();
        }
      },
      skip: !permissionProvider().hasPermission(
        PermissionResources.TIMER,
        PermissionActions.READ
      ),
    }
  );

  const [timers, setTimers] = useState(data?.listTimers || []);
  const [activeTimer, setActiveTimer] = useState<
    FetchTimersQuery['listTimers'][0]
  >();

  useEffect(() => {
    setTimers((prevTimers) =>
      (data?.listTimers || []).map((timer) => ({
        ...timer,
        totalSeconds:
          max([
            prevTimers.find(({ id }) => timer.id === id)?.totalSeconds,
            timer.totalSeconds,
          ]) || 0,
      }))
    );
  }, [data]);

  useEffect(() => {
    if (timers.length === 0) {
      setActiveTimer(undefined);
    } else {
      const startedTimer = timers.find(
        ({ status }) => status === TimerStatusTypes.STARTED
      );

      if (startedTimer && startedTimer.id !== activeTimer?.id) {
        setActiveTimer(startedTimer);
      } else {
        setActiveTimer(
          timers.find(({ id }) => id === activeTimer?.id) || timers[0]
        );
      }
    }
  }, [timers, activeTimer, setActiveTimer]);

  const handleOnTimerTick = useCallback(
    (id: string) => {
      setTimers((prevTimers) =>
        prevTimers.map((timer) =>
          timer.id === id
            ? {
                ...timer,
                totalSeconds: timer.totalSeconds + 1,
              }
            : timer
        )
      );
    },
    [setTimers]
  );

  const handleOnUpdateTimerStatus = async ({
    id,
    status,
  }: {
    id: string;
    status: TimerStatusTypes;
  }) => {
    const timer = timers.find((timer) => timer.id === id);

    if (timer && timer.status !== status && !processingTimers.includes(id)) {
      processingTimers.push(id);

      await updateTimerStatus({
        variables: { id, status },
        optimisticResponse: {
          updateTimer: {
            __typename: 'TimerType',
            id,
            status,
          },
        },
      });
    }
  };

  const handleOnStartTimer = async (id?: string) => {
    if (!id) {
      const newId = uuidV4();
      const matter = matterData?.getMatter;

      await createTimer({
        variables: { id: newId, matterId },
        optimisticResponse: {
          createTimer: {
            __typename: 'TimerType',
            id: newId,
            status: TimerStatusTypes.STARTED,
            matter: matterId
              ? ({
                  ...(matter || {}),
                  __typename: 'MatterType',
                  id: matter?.id || matterId,
                  name: matter?.name || '',
                  matterNumber: matter?.matterNumber || '',
                } as TimerFragmentFragment['matter'])
              : null,
            totalSeconds: 0,
            description: null,
          },
        },
      });
    } else {
      setLoadingTimer(id);
      await handleOnUpdateTimerStatus({ id, status: TimerStatusTypes.STARTED });
    }

    setLoadingTimer(undefined);
  };

  const onStart = () => {
    handleOnStartTimer(activeTimer?.id);

    if (activeTimer?.id) {
      track(EventNames.ACTION_WITH_TIMER, {
        action_type: TimerActionTypes.START_TIMER,
        section_type: SectionTypes.MAIN_SCREEN,
      });
    } else {
      track(EventNames.ACTION_WITH_TIMER, {
        action_type: TimerActionTypes.ADD_NEW_TIMER,
        section_type: SectionTypes.MAIN_SCREEN,
      });
    }
  };

  const handleOnPauseTimer = async (id?: string) => {
    if (id) {
      setLoadingTimer(id);
      await handleOnUpdateTimerStatus({ id, status: TimerStatusTypes.PAUSED });
      setLoadingTimer(undefined);
    }
  };

  const onPause = () => {
    handleOnPauseTimer(activeTimer?.id);

    track(EventNames.ACTION_WITH_TIMER, {
      action_type: TimerActionTypes.PAUSE_TIMER,
      section_type: SectionTypes.MAIN_SCREEN,
    });
  };

  const handleOnDoneTimer = (id?: string) => {
    if (id) {
      handleOnUpdateTimerStatus({ id, status: TimerStatusTypes.PAUSED });
    }

    openModal(TIME_ENTRY_MODAL, { timerId: id });
    setShowTimersStatus(false);
  };

  const onDone = () => {
    handleOnDoneTimer(activeTimer?.id);

    track(EventNames.ACTION_WITH_TIMER, {
      action_type: TimerActionTypes.COMPLETE_TIMER,
      section_type: SectionTypes.MAIN_SCREEN,
    });
  };

  const handleOnCancelTimer = (id?: string) => {
    dispatch(
      openConfirmAction({
        content: t('timeTracker.timers.close.content'),
        cancelButtonTitle: t('timeTracker.timers.close.cancelButton'),
        okButtonTitle: t('timeTracker.timers.close.okButton'),
        okButtonSkin: 'primary',
        onConfirm() {
          if (id) {
            handleOnUpdateTimerStatus({ id, status: TimerStatusTypes.STOPPED });
          }
        },
      })
    );
  };

  const handleOnUpdateTimer = useCallback(
    ({
      id,
      description = null,
      matter = null,
      contact = null,
    }: TimerFormType) =>
      updateTimerInfo({
        variables: {
          id,
          description,
          matterId: matter?.id || null,
          contactId: contact?.id || null,
        },
        optimisticResponse: {
          updateTimer: {
            __typename: 'TimerType',
            id,
            description,
            matter,
            contact,
          },
        },
      }),
    [updateTimerInfo]
  );

  const handleOnClickTimer = useCallback(() => {
    setShowTimersStatus(true);
    track(EventNames.OPEN_TIMER_LIST);
  }, [setShowTimersStatus]);

  const handleOnCloseTimers = useCallback(() => {
    setShowTimersStatus(false);
  }, [setShowTimersStatus]);

  const checkIsPaused = useCallback(
    (status: TimerStatusTypes) => status === TimerStatusTypes.PAUSED,
    []
  );

  return (
    <TourStepTarget step={TOUR_STEPS_NAME.TIME_TRACKER}>
      <Timer
        seconds={activeTimer?.totalSeconds}
        loading={!!loadingTimer}
        blocked={!!loadingTimer}
        compact
        isPaused={!activeTimer || checkIsPaused(activeTimer.status)}
        count={timers.length}
        className={classnames(styles.timer, className)}
        onStart={onStart}
        onPause={onPause}
        onDone={onDone}
        onClick={handleOnClickTimer}
      />
      {createPortal(
        <Timers
          timers={timers}
          loadingTimer={loadingTimer}
          className={classnames(styles.timers, {
            [styles.showTimers]: showTimersStatus,
          })}
          onAdd={handleOnStartTimer}
          onClose={handleOnCloseTimers}
          onTimerTick={handleOnTimerTick}
          onTimerStart={handleOnStartTimer}
          onTimerPause={handleOnPauseTimer}
          onTimerDone={handleOnDoneTimer}
          onTimerCancel={handleOnCancelTimer}
          onTimerUpdate={handleOnUpdateTimer}
        />,
        document.body
      )}
    </TourStepTarget>
  );
}
