import * as Yup from 'yup';

import { CONTACTS_VALIDATION_SCHEMA } from 'schemas/validations/contacts';

import { EntityFieldsetValuesType } from '../../ContactMatterPopupForm.types';
import { CONTACT_ENTITY_NAME_VALIDATION_SCHEMA } from 'schemas/validations/contact';

export const EntityFieldsetSchema = Yup.object().shape<EntityFieldsetValuesType>(
  {
    name: CONTACT_ENTITY_NAME_VALIDATION_SCHEMA,
    billingContacts: CONTACTS_VALIDATION_SCHEMA.required(),
  }
).required();
