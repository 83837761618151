import React from 'react';
import { ButtonGroup, Button, Note, Text4 } from '@appclose/ui';
import { Form, FormGroup, DatePickerFormField, Fieldset } from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import { SERVER_DATE_FORMAT } from 'constants/date';
import PrintingSampleFieldset from 'components/form/PrintingSampleFieldset';
import { openModal } from 'controllers/modal';
import { PRINT_DEPOSITS_SAMPLE_PREVIEW_MODAL } from 'constants/modals';
import { I18n, useIntl } from 'i18n';

import PrintableDepositsField from './components/PrintableDepositsField';
import { PrintChecksFormSchema } from './PrintDepositsForm.schema';
import { PrintDepositsFormPropsType } from './PrintDepositsForm.types';

export default function PrintDepositsForm({
  initialValues,
  onOpenFineTuneAlignment,
  onSubmit,
  onCancel,
  onChange,
}: PrintDepositsFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      validationSchema={PrintChecksFormSchema}
      onSubmit={onSubmit}
      onChange={onChange}
    >
      {({ isSubmitting, values }) => {
        const { deposits, date } = values;

        return (
          <>
            <PrintableDepositsField />
            <Fieldset
              title={t('modal.printDeposits.form.depositSlipDetails.title')}
            >
              <FormGroup>
                <FormGrid>
                  <DatePickerFormField
                    name="date"
                    label={t(
                      'modal.printDeposits.form.depositSlipDetails.date'
                    )}
                    outputDateFormat={SERVER_DATE_FORMAT}
                    useHumanReadableFormat
                  />
                </FormGrid>
              </FormGroup>
            </Fieldset>
            <PrintingSampleFieldset
              onOpenSample={() => {
                openModal(PRINT_DEPOSITS_SAMPLE_PREVIEW_MODAL, {}, values);
              }}
              isOpenSampleDisabled={isSubmitting}
              onOpenFineTuneAlignment={() =>
                onOpenFineTuneAlignment(
                  deposits.map(({ id }) => id),
                  date
                )
              }
              isOpenFineTuneAlignmentDisabled={isSubmitting}
            />
            <Note theme="important" offset={{ top: 60, bottom: 60 }}>
              <I18n id="modal.printDeposits.form.notification.main" />
              <Text4 offset={{ top: 8 }} opacity={0.6}>
                <I18n id="modal.printDeposits.form.notification.additional" />
              </Text4>
            </Note>
            <ButtonGroup>
              <Button onClick={onCancel}>
                <I18n id="modal.printDeposits.form.cancel" />
              </Button>
              <Button skin="primary" type="submit">
                <I18n id="modal.printDeposits.form.submit" />
              </Button>
            </ButtonGroup>
          </>
        );
      }}
    </Form>
  );
}
