import gqlClient from 'gql';
import { openConfirmAction, traceError } from '@appclose/core';

import notification from 'controllers/notification';
import { i18nMessage } from 'i18n';

import { DELETE_RECONCILIATION } from './reconciliation.gql';
import {
  DeleteReconciliationMutation,
  DeleteReconciliationMutationVariables,
} from './__generated__/reconciliation.gql';

export const openDeleteReconciliationConfirmAction = ({
  id,
  onSuccess,
}: {
  id: string;
  onSuccess?(): void;
}) =>
  openConfirmAction({
    name: i18nMessage('store.reconciliation.deleteReconciliationConfirm.name'),
    content: i18nMessage(
      'store.reconciliation.deleteReconciliationConfirm.content'
    ),
    okButtonTitle: i18nMessage(
      'store.reconciliation.deleteReconciliationConfirm.okButton'
    ),
    cancelButtonTitle: i18nMessage(
      'store.reconciliation.deleteReconciliationConfirm.cancelButton'
    ),
    okButtonSkin: 'primary',
    async onConfirm() {
      try {
        await gqlClient.mutate<
          DeleteReconciliationMutation,
          DeleteReconciliationMutationVariables
        >({
          mutation: DELETE_RECONCILIATION,
          variables: { id },
        });

        notification().entityDeleted(
          i18nMessage('store.reconciliation.deleteReconciliationConfirm.notify')
        );

        onSuccess && onSuccess();
      } catch (e) {
        traceError(e);
      }
    },
  });
