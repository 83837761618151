import * as Yup from 'yup';
import { EMAIL_VALIDATION_SCHEMA } from '@appclose/core';

import { getI18nErrorMessage } from 'i18n';
import { LeadSources } from '__generated__/globalTypes';
import { PHONE_FIELDSET_SCHEMA } from 'components/form/PhoneFieldset';
import { NOTES_VALIDATION_SCHEMA } from 'schemas/validations/notes';
import {
  CONTACT_FIRST_NAME_VALIDATION_SCHEMA,
  CONTACT_LAST_NAME_VALIDATION_SCHEMA,
} from 'schemas/validations/contact';

import { FetchProfileQuery } from '../../../../__generated__/LeadModal.gql';
import { IndividualFieldsetValuesType } from './IndividualFieldset.types';

export const IndividualFieldsetSchema = Yup.object().shape<IndividualFieldsetValuesType>(
  {
    firstName: CONTACT_FIRST_NAME_VALIDATION_SCHEMA,
    lastName: CONTACT_LAST_NAME_VALIDATION_SCHEMA,
    middleName: Yup.string().nullable(),
    email: EMAIL_VALIDATION_SCHEMA.required(
      getI18nErrorMessage(
        'modal.contact.form.contact.fieldset.info.email.required'
      )
    ),
    isImportant: Yup.boolean().required(),
    notes: NOTES_VALIDATION_SCHEMA,
    leadSource: Yup.mixed<LeadSources>().required(
      getI18nErrorMessage('error.leads.create.leadSource')
    ),
    phoneNumbers: PHONE_FIELDSET_SCHEMA.required(
      getI18nErrorMessage('error.leads.type.required')
    ),
    originatingAttorney: Yup.object<FetchProfileQuery['getProfile']>().required(
      getI18nErrorMessage(
        'modal.contact.form.contact.error.originatingAttorney'
      )
    ),
  }
);
