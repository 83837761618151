import React, { useCallback }  from 'react';
import { useFormikContext } from 'formik';
import { Fieldset } from '@appclose/core';
import { I18n } from 'i18n';
import FormGrid from 'components/common/FormGrid';
import GoogleDriveSelectFormField from '../GoogleDriveSelectFormField/GoogleDriveSelectFormField';
import { GoogleDriveSyncOptionsFormValuesType, SyncFolderType } from '../../GoogleDriveSyncOptionsModal.types';

export default function GoogleDriveFieldset() {
  const {
    values: { drive },
    setValues,
  } = useFormikContext<GoogleDriveSyncOptionsFormValuesType>();

  const onChangeDrive = useCallback((nextDrive: { id?: string; name?: string }) => {
    if (drive?.id !== nextDrive?.id) {
      setValues(
        (values) => ({
          ...values,
          drive,
          syncFolderType: SyncFolderType.NEW,
          folderId: null,
          folderName: null,
          breadcrumbs: []
        }),
        true
      );
    }
  }, [drive, setValues]);

  return (
    <Fieldset
      title={
        <I18n id='modal.googleDriveSyncOptions.fieldset.drive' />
      }
    >
      <FormGrid>
        <GoogleDriveSelectFormField onChange={onChangeDrive} />
      </FormGrid>
    </Fieldset>
  );
}
