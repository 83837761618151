import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Grid, Note } from '@appclose/ui';
import {
  Fieldset,
  Form,
  FormGroup,
  RadioFormField,
  StaticField,
} from '@appclose/core';
import noop from 'lodash/noop';

import FormGrid from 'components/common/FormGrid';
import { I18n, useIntl } from 'i18n';

import styles from './PaymentButtonForm.module.scss';
import PaymentButton, {
  PaymentButtonBackground,
  PaymentButtonColor,
  PaymentButtonEventVariations,
  PaymentButtonLanguage,
  PaymentButtonText,
} from '../PaymentButton';
import { PaymentButtonMethods } from '../PaymentButton/PaymentButton.types';
import { PaymentButtonFormPropsType } from './PaymentButtonForm.types';

export default function PaymentButtonForm({
  paymentLink,
}: PaymentButtonFormPropsType) {
  const { t } = useIntl();
  const initialValues = useMemo(
    () => ({
      language: PaymentButtonLanguage.EN,
      background: PaymentButtonBackground.LIGHT,
      color: PaymentButtonColor.WHITE,
      text: PaymentButtonText.MAKE_PAYMENT,
    }),
    []
  );

  return (
    <Form<typeof initialValues>
      onSubmit={noop}
      initialValues={initialValues}
      className={styles.form}
    >
      {({ values }) => (
        <>
          <Fieldset title={t('modal.paymentButton.form.buttonSettings')}>
            <FormGroup>
              <FormGrid>
                <StaticField title={t('modal.paymentButton.form.language')}>
                  <Grid gap={[12]} autoFlow="column">
                    <RadioFormField
                      name="language"
                      value={PaymentButtonLanguage.EN}
                      label={t('modal.paymentButton.form.language.english')}
                    />
                    <RadioFormField
                      name="language"
                      value={PaymentButtonLanguage.ES}
                      label={t('modal.paymentButton.form.language.spanish')}
                    />
                  </Grid>
                </StaticField>
                <StaticField title={t('modal.paymentButton.form.background')}>
                  <Grid gap={[12]} autoFlow="column">
                    <RadioFormField
                      name="background"
                      value={PaymentButtonBackground.LIGHT}
                      label={t('modal.paymentButton.form.background.light')}
                    />
                    <RadioFormField
                      name="background"
                      value={PaymentButtonBackground.DARK}
                      label={t('modal.paymentButton.form.background.dark')}
                    />
                  </Grid>
                </StaticField>
              </FormGrid>
            </FormGroup>
            <FormGroup>
              <FormGrid>
                <StaticField title={t('modal.paymentButton.form.color')}>
                  <Grid gap={[12]} autoFlow="column">
                    <RadioFormField
                      name="color"
                      value={PaymentButtonColor.WHITE}
                      label={t('modal.paymentButton.form.color.white')}
                    />
                    <RadioFormField
                      name="color"
                      value={PaymentButtonColor.YELLOW}
                      label={t('modal.paymentButton.form.color.yellow')}
                    />
                    <RadioFormField
                      name="color"
                      value={PaymentButtonColor.DARK}
                      label={t('modal.paymentButton.form.color.dark')}
                    />
                  </Grid>
                </StaticField>
              </FormGrid>
            </FormGroup>
            <FormGroup>
              <StaticField title={t('modal.paymentButton.form.text')}>
                <Grid gap={[12]} autoFlow="column">
                  <RadioFormField
                    name="text"
                    value={PaymentButtonText.MAKE_PAYMENT}
                    label={t('modal.paymentButton.form.text.makePayment')}
                  />
                  <RadioFormField
                    name="text"
                    value={PaymentButtonText.PAY_INVOICE}
                    label={t('modal.paymentButton.form.text.payInvoice')}
                  />
                  <RadioFormField
                    name="text"
                    value={PaymentButtonText.PAY_RETAINER}
                    label={t('modal.paymentButton.form.text.payRetainer')}
                  />
                  <RadioFormField
                    name="text"
                    value={PaymentButtonText.PAY_WITH_MONESQ}
                    label={t('modal.paymentButton.form.text.payMonesq')}
                  />
                </Grid>
              </StaticField>
            </FormGroup>
          </Fieldset>
          <Fieldset title={t('modal.paymentButton.form.buttonVariations')}>
            <Note theme="important" className={styles.note}>
              <I18n id="modal.paymentButton.form.buttonVariations.help" />
            </Note>
            <Grid
              gap={[20]}
              columns="repeat(2, 1fr)"
              className={classnames(styles.grid, {
                [styles.dark]:
                  values.background === PaymentButtonBackground.DARK,
              })}
            >
              <PaymentButton
                {...values}
                link={paymentLink}
                eventButtonVariation={PaymentButtonEventVariations.ONE}
              />
              <PaymentButton
                {...values}
                link={paymentLink}
                paymentMethods={[
                  PaymentButtonMethods.VISA,
                  PaymentButtonMethods.MASTERCARD,
                  PaymentButtonMethods.MONESQ,
                ]}
                eventButtonVariation={PaymentButtonEventVariations.TWO}
              />
              <PaymentButton
                {...values}
                link={paymentLink}
                paymentMethods={[
                  PaymentButtonMethods.VISA,
                  PaymentButtonMethods.MASTERCARD,
                  PaymentButtonMethods.DISCOVER,
                  PaymentButtonMethods.AMEX,
                  PaymentButtonMethods.MONESQ,
                ]}
                eventButtonVariation={PaymentButtonEventVariations.THREE}
              />
              <PaymentButton
                {...values}
                link={paymentLink}
                paymentMethods={[
                  PaymentButtonMethods.VISA,
                  PaymentButtonMethods.MONESQ,
                ]}
                eventButtonVariation={PaymentButtonEventVariations.FOUR}
              />
              <PaymentButton
                {...values}
                link={paymentLink}
                paymentMethods={[
                  PaymentButtonMethods.VISA,
                  PaymentButtonMethods.MASTERCARD,
                  PaymentButtonMethods.DISCOVER,
                  PaymentButtonMethods.MONESQ,
                ]}
                eventButtonVariation={PaymentButtonEventVariations.FIVE}
              />
            </Grid>
          </Fieldset>
        </>
      )}
    </Form>
  );
}
