import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Flex, Grid } from '@appclose/ui';
import { useIsMobileDevice } from '@appclose/core';
import { useIntl } from 'i18n';
import { Modes } from '__generated__/globalTypes';
import useSticky from 'hooks/useSticky';

import Fees from '../Fees';
import Mode from '../Mode';
import FeatureList from '../FeatureList';
import MobileModes from '../MobileModes';
import MobileFeatureList from '../MobileFeatureList';
import Disclaimer from '../Disclaimer';

import { ModesComparePropsType } from './ModesCompare.types';
import { MODES_FEATURES } from '../../Modes.constants';

import styles from './ModesCompare.module.scss';

export default function ModesCompare({
  onSelectStandardMode,
  onSelectPlusMode,
}: ModesComparePropsType) {
  const { t } = useIntl();
  const isMobile = useIsMobileDevice();

  const { targetRef: feesRef } = useSticky<HTMLDivElement>({
    useMobile: true,
    zIndex: 2,
  });

  const { targetRef: solutionsRef, isStuck: solutionsIsStuck } = useSticky<
    HTMLDivElement
    >({
    targets: [feesRef.current],
    useMobile: false,
    zIndex: 2,
  });

  return (
    <Flex direction="column" alignItems="center" justify="center">
      <div ref={feesRef}>
        <Fees className={styles.fees} />
      </div>
      <Grid
        gap={[60, 40]}
        columns={isMobile ? 'auto' : 'repeat(2, 1fr)'}
        className={styles.solutions}
      >
        <Mode
          mode={Modes.FREE}
          title={t('plan.free')}
          price={0}
          description={t('modes.description.free')}
          onSelect={onSelectStandardMode}
        />
        <Mode
          mode={Modes.PLUS}
          title={t('plan.plus')}
          price={6.59}
          description={t('modes.description.plus')}
          onSelect={onSelectPlusMode}
        />
      </Grid>
      <div ref={solutionsRef}>
        <CSSTransition
          in={!isMobile && solutionsIsStuck}
          timeout={600}
          mountOnEnter
          unmountOnExit
          classNames={{
            enter: styles.fadeInUp,
            exit: styles.fadeOutUp,
          }}
          className={styles.minifySolutions}
        >
          <Grid
            gap={[60, 40]}
            columns="repeat(2, 1fr)"
            className={styles.modes}
          >
            <Mode
              mode={Modes.FREE}
              title={t('plan.free')}
              price={0}
              minify
              onSelect={onSelectStandardMode}
            />
            <Mode
              mode={Modes.PLUS}
              title={t('plan.plus')}
              price={6.59}
              minify
              onSelect={onSelectPlusMode}
            />
          </Grid>
        </CSSTransition>
      </div>
      {!isMobile ? (
        <Grid
          gap={[60, 40]}
          columns="repeat(2, 1fr)"
          className={styles.features}
        >
          {MODES_FEATURES(t).map(({ title, points }) =>
            Object.entries(Modes).map(([, mode]) => (
              <FeatureList
                title={title}
                features={Object.entries(points).reduce<string[]>(
                  (acc, [feature, modes]) =>
                    modes.includes(mode) ? [...acc, feature] : acc,
                  []
                )}
                key={`${title}-${mode}`}
              />
            ))
          )}
        </Grid>
      ) : (
        <div className={styles.features}>
          <MobileModes
            modes={[
              { title: t('plan.free'), onSelect: onSelectStandardMode },
              { title: t('plan.plus'), onSelect: onSelectPlusMode },
            ]}
            stickyRefs={[feesRef]}
          />
          {MODES_FEATURES(t).map(({ title, points }) => (
            <MobileFeatureList
              title={title}
              features={Object.entries(points)}
              key={`${title}`}
            />
          ))}
        </div>
      )}
      <Disclaimer />
    </Flex>
  );
}
