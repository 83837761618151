import React, { ReactElement } from 'react';

import { ExpenseStatuses } from '__generated__/globalTypes';
import Label, { LabelPropsType } from 'components/common/Label';
import { I18n } from 'i18n';

import { ExpenseStatusPropsType } from './ExpenseStatus.types';

const ExpenseStatusLabels: Record<
  ExpenseStatuses,
  ReactElement<LabelPropsType>
> = {
  [ExpenseStatuses.BILLABLE]: (
    <Label theme="info">
      <I18n id="enum.invoice.item.status.billable" />
    </Label>
  ),
  [ExpenseStatuses.NOT_BILLABLE]: (
    <Label theme="warning">
      <I18n id="enum.invoice.item.status.notBillable" />
    </Label>
  ),
  [ExpenseStatuses.BILLED]: (
    <Label theme="primary">
      <I18n id="enum.invoice.item.status.billed" />
    </Label>
  ),
  [ExpenseStatuses.PAID]: (
    <Label theme="success">
      <I18n id="enum.invoice.item.status.paid" />
    </Label>
  ),
  [ExpenseStatuses.PARTIALLY_PAID]: (
    <Label theme="success">
      <I18n id="enum.invoice.item.status.partiallyPaid" />
    </Label>
  ),
};

export default function ExpenseStatus({ status }: ExpenseStatusPropsType) {
  return ExpenseStatusLabels[status] || null;
}
