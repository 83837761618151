import React, { ReactElement } from 'react';
import { TrustTransactionTypes } from '__generated__/globalTypes';
import { PaidIcon, DueIcon, OperatingIcon } from '@appclose/icons';

import { TrustTransactionTypePropsType } from './TrustTransactionType.type';

const TrustTransactionTypeIcons: Record<TrustTransactionTypes, ReactElement> = {
  [TrustTransactionTypes.DEPOSIT]: <PaidIcon />,
  [TrustTransactionTypes.DEPOSIT_TRANSFER]: <PaidIcon />,
  [TrustTransactionTypes.DISBURSEMENT_CONTACT]: <DueIcon />,
  [TrustTransactionTypes.DISBURSEMENT_OPERATING]: <DueIcon />,
  [TrustTransactionTypes.DISBURSEMENT_THIRD_PARTY]: <DueIcon />,
  [TrustTransactionTypes.DISBURSEMENT_TRANSFER]: <DueIcon />,
  [TrustTransactionTypes.OTO]: <OperatingIcon />,
  [TrustTransactionTypes.INITIAL_BALANCE]: <>-</>,
};

const TrustTransactionTypeNames: Record<TrustTransactionTypes, string> = {
  [TrustTransactionTypes.DEPOSIT]: 'DEP',
  [TrustTransactionTypes.DEPOSIT_TRANSFER]: 'DEP',
  [TrustTransactionTypes.DISBURSEMENT_CONTACT]: 'DISB',
  [TrustTransactionTypes.DISBURSEMENT_OPERATING]: 'DISB',
  [TrustTransactionTypes.DISBURSEMENT_THIRD_PARTY]: 'DISB',
  [TrustTransactionTypes.DISBURSEMENT_TRANSFER]: 'DISB',
  [TrustTransactionTypes.OTO]: 'OTO',
  [TrustTransactionTypes.INITIAL_BALANCE]: '-',
};

function getTrustTransactionTypeName(
  type: TrustTransactionTypePropsType['type'],
  checkNumber: TrustTransactionTypePropsType['checkNumber']
) {
  const checkNumberText = checkNumber ? `#${checkNumber}` : '';

  return <>{`${TrustTransactionTypeNames[type]} ${checkNumberText}`}</>;
}

export default function TrustTransactionType({
  type,
  checkNumber,
  asIcon = false,
}: TrustTransactionTypePropsType) {
  return asIcon
    ? TrustTransactionTypeIcons[type]
    : getTrustTransactionTypeName(type, checkNumber);
}
