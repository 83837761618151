import React from 'react';
import { Flex } from '@appclose/ui';

import Avatar from 'components/common/Avatar';
import { getContactName } from 'controllers/contact';
import { I18n } from 'i18n';

import { TeamMemberPropsType } from './TeamMember.types';
import styles from './TeamMember.module.scss';

export default function TeamMember({
  picture,
  isYou,
  lastName,
  firstName,
}: TeamMemberPropsType) {
  return (
    <Flex alignItems="center">
      <Avatar
        icon={picture}
        firstName={firstName}
        lastName={lastName}
        size={24}
        className={styles.avatar}
      />
      {isYou ? (
        <I18n id="field.teamMemberSelect.teamMember.you" />
      ) : (
        getContactName({ firstName, lastName })
      )}
    </Flex>
  );
}
