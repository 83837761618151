import { gql } from '@apollo/client';

export const FILE_FRAGMENT = gql`
  fragment FileFragment on S3FileType {
    id
    name
    url
    size
    dateAdded
    mimetype
    source
  }
`;

export const LIST_CONTACT_FILES = gql`
  query ListContactFiles(
    $skip: Int
    $take: Int
    $filter: FilterFilesInput
    $order: OrderFilesInput
  ) {
    files: listContactFiles(
      listFilesInput: {
        skip: $skip
        take: $take
        filter: $filter
        order: $order
      }
    ) {
      items {
        ...FileFragment
      }
      total
    }
  }
  ${FILE_FRAGMENT}
`;

export const LIST_LEAD_FILES = gql`
  query ListLeadFiles(
    $skip: Int
    $take: Int
    $filter: FilterFilesInput
    $order: OrderFilesInput
  ) {
    files: listLeadFiles(
      listFilesInput: {
        skip: $skip
        take: $take
        filter: $filter
        order: $order
      }
    ) {
      items {
        ...FileFragment
      }
      total
    }
  }
  ${FILE_FRAGMENT}
`;

export const LIST_MATTER_FILES = gql`
  query ListMatterFiles(
    $skip: Int
    $take: Int
    $filter: FilterFilesInput
    $order: OrderFilesInput
  ) {
    files: listMatterFiles(
      listFilesInput: {
        skip: $skip
        take: $take
        filter: $filter
        order: $order
      }
    ) {
      items {
        ...FileFragment
      }
      total
    }
  }
  ${FILE_FRAGMENT}
`;

export const LIST_INVOICE_FILES = gql`
  query ListInvoiceFiles(
    $skip: Int
    $take: Int
    $filter: FilterFilesInput
    $order: OrderFilesInput
  ) {
    files: listInvoiceFiles(
      listFilesInput: {
        skip: $skip
        take: $take
        filter: $filter
        order: $order
      }
    ) {
      items {
        ...FileFragment
      }
      total
    }
  }
  ${FILE_FRAGMENT}
`;

export const LIST_EXPENSE_FILES = gql`
  query ListExpenseFiles(
    $skip: Int
    $take: Int
    $filter: FilterFilesInput
    $order: OrderFilesInput
  ) {
    files: listExpenseFiles(
      listFilesInput: {
        skip: $skip
        take: $take
        filter: $filter
        order: $order
      }
    ) {
      items {
        ...FileFragment
      }
      total
    }
  }
  ${FILE_FRAGMENT}
`;

export const LIST_PAYMENT_FILES = gql`
  query ListPaymentFiles(
    $skip: Int
    $take: Int
    $filter: FilterFilesInput
    $order: OrderFilesInput
  ) {
    files: listPaymentFiles(
      listFilesInput: {
        skip: $skip
        take: $take
        filter: $filter
        order: $order
      }
    ) {
      items {
        ...FileFragment
      }
      total
    }
  }
  ${FILE_FRAGMENT}
`;

export const LIST_VOID_INVOICE_FILES = gql`
  query ListVoidInvoiceFiles(
    $skip: Int
    $take: Int
    $filter: FilterFilesInput
    $order: OrderFilesInput
  ) {
    files: listVoidInvoiceFiles(
      listFilesInput: {
        skip: $skip
        take: $take
        filter: $filter
        order: $order
      }
    ) {
      items {
        ...FileFragment
      }
      total
    }
  }
  ${FILE_FRAGMENT}
`;

export const LIST_TRUST_TRANSFER_FILES = gql`
  query ListTrustTransferFiles(
    $skip: Int
    $take: Int
    $filter: FilterFilesInput
    $order: OrderFilesInput
  ) {
    files: listTrustTransferFiles(
      listFilesInput: {
        skip: $skip
        take: $take
        filter: $filter
        order: $order
      }
    ) {
      items {
        ...FileFragment
      }
      total
    }
  }
  ${FILE_FRAGMENT}
`;

export const LIST_RECONCILIATION_FILES = gql`
  query ListReconciliationFiles(
    $skip: Int
    $take: Int
    $filter: FilterFilesInput
    $order: OrderFilesInput
  ) {
    files: listReconciliationFiles(
      listFilesInput: {
        skip: $skip
        take: $take
        filter: $filter
        order: $order
      }
    ) {
      items {
        ...FileFragment
      }
      total
    }
  }
  ${FILE_FRAGMENT}
`;

export const LIST_SIGNATURE_FILES = gql`
  query ListSignatureFiles(
    $skip: Int
    $take: Int
    $filter: FilterFilesInput
    $order: OrderFilesInput
  ) {
    files: listSignatureFiles(
      listFilesInput: {
        skip: $skip
        take: $take
        filter: $filter
        order: $order
      }
    ) {
      items {
        ...FileFragment
      }
      total
    }
  }
  ${FILE_FRAGMENT}
`;

export const LIST_SIGNATURE_TEMPLATE_FILES = gql`
  query ListSignatureTemplateFiles(
    $skip: Int
    $take: Int
    $filter: FilterFilesInput
    $order: OrderFilesInput
  ) {
    files: listSignatureTemplateFiles(
      listFilesInput: {
        skip: $skip
        take: $take
        filter: $filter
        order: $order
      }
    ) {
      items {
        ...FileFragment
      }
      total
    }
  }
  ${FILE_FRAGMENT}
`;

export const ON_FILE_CREATED = gql`
  subscription OnFileCreated($input: FileSubscriptionInput!) {
    fileCreated(fileSubscriptionInput: $input) {
      id
    }
  }
`;

export const ON_FILE_DELETED = gql`
  subscription OnFileDeleted($input: FileSubscriptionInput!) {
    fileDeleted(fileSubscriptionInput: $input) {
      id
    }
  }
`;
