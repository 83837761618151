import React from 'react';
import { Button, ButtonGroup, Flex, Title4 } from '@appclose/ui';
import { Form, FormGroup, SecureInputFormField } from '@appclose/core';

import { I18n, useIntl } from 'i18n';

import { ChangePasswordFormSchema } from './ChangePasswordForm.schema';
import { ChangePasswordFormPropsType } from './ChangePasswordForm.types';
import styles from './ChangePasswordForm.module.scss';

export default function ChangePasswordForm({
  initialValues,
  onSubmit,
  onClose,
}: ChangePasswordFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ChangePasswordFormSchema}
    >
      {({ isSubmitting, values }) => {
        return (
          <Flex
            direction="column"
            alignItems="center"
            className={styles.container}
          >
            <Title4>
              <I18n id="modal.changePassword.form.title" />
            </Title4>
            <p className={styles.note}>
              <I18n id="modal.changePassword.form.note" />
            </p>
            <FormGroup>
              <SecureInputFormField
                disabledPaste={false}
                name="oldPassword"
                label={t('modal.changePassword.form.oldPassword')}
              />
            </FormGroup>
            <FormGroup>
              <SecureInputFormField
                disabledPaste={false}
                name="newPassword"
                label={t('modal.changePassword.form.newPassword')}
              />
            </FormGroup>
            <FormGroup>
              <SecureInputFormField
                disabledPaste={true}
                disabled={!values.newPassword}
                name="newPasswordConfirm"
                label={t('modal.changePassword.form.newPassword.confirm')}
              />
            </FormGroup>
            <ButtonGroup className={styles.submit}>
              <Button loading={isSubmitting} onClick={onClose}>
                <I18n id="modal.changePassword.form.cancel" />
              </Button>
              <Button loading={isSubmitting} type="submit" skin="primary">
                <I18n id="modal.changePassword.form.submit" />
              </Button>
            </ButtonGroup>
          </Flex>
        );
      }}
    </Form>
  );
}
