import { LeadFragment } from 'gql/shared/__generated__/lead.gql';

export enum ContactModalModalTabs {
  INDIVIDUAL,
  ENTITY,
  MULTIPLE_IMPORT,
}

export type ContactModalPropsType = {
  id?: string;
  onClose(): void;
  lead?: LeadFragment;
  defaultTab?: ContactModalModalTabs;
  openNew?: boolean;
};
