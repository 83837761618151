import { useIntl } from 'i18n';

import { UnclearedTransactionTypes } from '__generated__/globalTypes';
import { UnclearedTransactionOptionsType } from './UnclearedTransactionTypeSelectFormField.types';

export function useOptions(): UnclearedTransactionOptionsType {
  const { t } = useIntl();

  return [
    {
      value: UnclearedTransactionTypes.DEPOSIT,
      label: t('enum.transaction.uncleared.type.deposit'),
    },
    {
      value: UnclearedTransactionTypes.DISBURSEMENT,
      label: t('enum.transaction.uncleared.type.disbursement'),
    },
  ];
}
