import { gql } from '@apollo/client';

import { AVATAR_FRAGMENT } from 'components/common/Avatar';
import { INVOICE_INFO_FRAGMENT } from 'components/common/InvoiceInfo';

export const FETCH_EXPENSE = gql`
  query FetchExpense($id: ID!) {
    expense: getSubExpense(id: $id) {
      id
      amount
      date
      category {
        id
        name
        costType
      }
      status
      billedBy {
        id
        firstName
        lastName
        picture {
          ...Avatar
        }
      }
      contact {
        id
        name
      }
      matter {
        id
        name
      }
      description
      notes
      invoice {
        ...InvoiceInfo
      }
    }
  }
  ${AVATAR_FRAGMENT}
  ${INVOICE_INFO_FRAGMENT}
`;
