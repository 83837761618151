import { useCallback } from 'react';

import { openModal } from 'controllers/modal';
import { RECEIVE_PAYMENT_MODAL } from 'constants/modals';

import useAccessCheck from '../useAccessCheck/useAccessCheck';

const useOpenReceivePaymentModalFlow = () => {
  const { withLimitedAccessCheck } = useAccessCheck();

  const runFlow = useCallback(async (data: any) => {
    openModal(RECEIVE_PAYMENT_MODAL, data)
  }, []);

  return withLimitedAccessCheck(runFlow);
};

export default useOpenReceivePaymentModalFlow;
