import React from 'react';
import { useQuery } from '@apollo/client';
import { DownloadIcon } from '@appclose/icons';
import {
  Amount,
  FormGroup,
  Loader,
  StaticField,
  Fieldset,
} from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { I18n, useIntl } from 'i18n';
import ActionsPanel, {
  ActionsPanelButton,
} from 'components/common/ActionsPanel';
import FormGrid from 'components/common/FormGrid';
import TrustTransactionStatus from 'components/common/TrustTransactionStatus';
import Date from 'components/common/Date';
import ContactMatter from 'components/common/ContactMatter';
import StaticFilesField from 'components/common/StaticFilesField';
import { PermissionsGuard } from 'components/common/PermissionsGuard';
import useListFiles from 'hooks/useListFiles';
import useTrustTransferActions from 'hooks/useTrustTransferActions';
import { Entities } from 'constants/entities';

import { TrustTransferDetailsModalPropsType } from './TrustTransferDetailsModal.types';
import { TRUST_TRANSFER_ACTIONS_PERMISSIONS } from './TrustTransferDetailsModal.constants';
import { FETCH_TRUST_TRANSFER } from './TrustTransferDetailsModal.gql';
import {
  FetchTrustTransferQuery,
  FetchTrustTransferQueryVariables,
} from './__generated__/TrustTransferDetailsModal.gql';

export default function TrustTransferDetailsModal({
  id,
  onClose,
}: TrustTransferDetailsModalPropsType) {
  const { t } = useIntl();
  const {
    downloadTrustTransferPdf,
    downloadTrustTransferPdfLoading,
  } = useTrustTransferActions({ id });
  const { loading, data } = useQuery<
    FetchTrustTransferQuery,
    FetchTrustTransferQueryVariables
  >(FETCH_TRUST_TRANSFER, {
    variables: { id },
  });
  const trustTransfer = data?.trustTransfer;
  const { filesLoading, files } = useListFiles({
    entityType: Entities.TRUST_TRANSFER,
    input: { filter: { entityIds: [id] } },
  });

  return (
    <ModalPage onClose={onClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.trustTransferDetails.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading || filesLoading ? (
          <Loader />
        ) : (
          trustTransfer && (
            <>
              <PermissionsGuard allow={TRUST_TRANSFER_ACTIONS_PERMISSIONS}>
                <ActionsPanel>
                  <ActionsPanelButton
                    skin="secondary"
                    icon={<DownloadIcon />}
                    title={t('modal.trustTransferDetails.actions.downloadPdf')}
                    onClick={downloadTrustTransferPdf}
                    loading={downloadTrustTransferPdfLoading}
                  />
                </ActionsPanel>
              </PermissionsGuard>
              <Fieldset
                title={t('modal.trustTransferDetails.fieldset.details')}
              >
                <FormGroup>
                  <FormGrid>
                    <StaticField
                      title={t('modal.trustTransferDetails.field.from')}
                    >
                      <ContactMatter
                        contact={trustTransfer.fromContact}
                        matter={trustTransfer.fromMatter}
                      />
                    </StaticField>
                    <StaticField
                      title={t('modal.trustTransferDetails.field.to')}
                    >
                      <ContactMatter
                        contact={trustTransfer.toContact}
                        matter={trustTransfer.toMatter}
                      />
                    </StaticField>
                  </FormGrid>
                </FormGroup>
                <FormGroup>
                  <FormGrid adaptive={false}>
                    <StaticField
                      title={t('modal.trustTransferDetails.field.amount')}
                    >
                      <Amount value={trustTransfer.amount} />
                    </StaticField>
                    <StaticField
                      title={t('modal.trustTransferDetails.field.status')}
                    >
                      <TrustTransactionStatus status={trustTransfer.status} />
                    </StaticField>
                  </FormGrid>
                </FormGroup>
                <FormGroup>
                  <FormGrid>
                    <StaticField
                      title={t('modal.trustTransferDetails.field.contact')}
                    >
                      {trustTransfer.fromContact.name}
                    </StaticField>
                  </FormGrid>
                </FormGroup>
                <FormGroup>
                  <FormGrid>
                    <StaticField
                      title={t(
                        'modal.trustTransferDetails.field.paymentMethod.title'
                      )}
                    >
                      <I18n id="modal.trustTransferDetails.field.paymentMethod.value" />
                    </StaticField>
                    <StaticField
                      title={t('modal.trustTransferDetails.field.date')}
                    >
                      <Date value={trustTransfer.date} />
                    </StaticField>
                  </FormGrid>
                </FormGroup>
                <FormGroup>
                  <StaticField
                    title={t('modal.trustTransferDetails.field.description')}
                  >
                    {trustTransfer.description}
                  </StaticField>
                </FormGroup>
                <FormGroup>
                  {!!files?.items.length && (
                    <StaticFilesField files={files?.items} />
                  )}
                </FormGroup>
              </Fieldset>
            </>
          )
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
