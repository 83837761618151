import { gql } from '@apollo/client';

export const UPDATE_CONTACT_FILES = gql`
  mutation UpdateContactFiles($input: UpdateFilesInput!) {
    updateContactFiles(updateFilesInput: $input) {
      success
    }
  }
`;

export const UPDATE_LEAD_FILES = gql`
  mutation UpdateLeadFiles($input: UpdateFilesInput!) {
    updateLeadFiles(updateFilesInput: $input) {
      success
    }
  }
`;

export const UPDATE_MATTER_FILES = gql`
  mutation UpdateMatterFiles($input: UpdateFilesInput!) {
    updateMatterFiles(updateFilesInput: $input) {
      success
    }
  }
`;

export const UPDATE_INVOICE_FILES = gql`
  mutation UpdateInvoiceFiles($input: UpdateFilesInput!) {
    updateInvoiceFiles(updateFilesInput: $input) {
      success
    }
  }
`;

export const UPDATE_EXPENSE_FILES = gql`
  mutation UpdateExpenseFiles($input: UpdateFilesInput!) {
    updateExpenseFiles(updateFilesInput: $input) {
      success
    }
  }
`;

export const UPDATE_PAYMENT_FILES = gql`
  mutation UpdatePaymentFiles($input: UpdateFilesInput!) {
    updatePaymentFiles(updateFilesInput: $input) {
      success
    }
  }
`;

export const UPDATE_VOID_INVOICE_FILES = gql`
  mutation UpdateVoidInvoiceFiles($input: UpdateFilesInput!) {
    updateVoidInvoiceFiles(updateFilesInput: $input) {
      success
    }
  }
`;

export const UPDATE_TRUST_TRANSFER_FILES = gql`
  mutation UpdateTrustTransferFiles($input: UpdateFilesInput!) {
    updateTrustTransferFiles(updateFilesInput: $input) {
      success
    }
  }
`;

export const UPDATE_RECONCILIATION_FILES = gql`
  mutation UpdateReconciliationFiles($input: UpdateFilesInput!) {
    updateReconciliationFiles(updateFilesInput: $input) {
      success
    }
  }
`;
