import * as Yup from 'yup';
import { EMAIL_VALIDATION_SCHEMA } from '@appclose/core';
import {
  LeadContactType,
  LeadContactTypes
} from '__generated__/globalTypes';
import { getI18nErrorMessage } from 'i18n';

import { CONTACT_FIRST_NAME_VALIDATION_SCHEMA, CONTACT_LAST_NAME_VALIDATION_SCHEMA } from './contact';

export const LEAD_ENTITY_CONTACTS_VALIDATION_SCHEMA = Yup.array<
  Omit<LeadContactType, '__typename' | 'id'>
  >(
  Yup.object()
    .shape({
      type: Yup.mixed<LeadContactTypes>().required(
        getI18nErrorMessage('error.contacts.type.required')
      ),
      email: EMAIL_VALIDATION_SCHEMA,
      firstName: CONTACT_FIRST_NAME_VALIDATION_SCHEMA,
      lastName: CONTACT_LAST_NAME_VALIDATION_SCHEMA,
      middleName: Yup.string().nullable(),
    })
    .required()
)
  .min(1, getI18nErrorMessage('error.contacts.minLength'))
  .test(
    'hasOnePrimaryContact',
    getI18nErrorMessage('error.contacts.hasOnePrimaryContact'),
    (contacts) =>
      contacts?.filter(({ type }) => type === LeadContactTypes.PRIMARY)
        .length === 1
  );
