import { gql } from '@apollo/client';

import { TEAM_MEMBER_SELECT_FRAGMENT } from 'components/fields/TeamMemberSelectFormField';
import { MATTER_SELECT_FRAGMENT } from 'components/fields/MatterSelectFormField';
import { CONTACT_SELECT_FRAGMENT } from 'components/fields/ContactSelectFormField';
import { TIME_ENTRY_ACTIVITY_SELECT_FRAGMENT } from 'components/fields/TimeEntryActivitySelectFormField';

export const TIME_ENTRY_MODAL_FRAGMENT = gql`
  fragment TimeEntryModalFragment on TimeEntryType {
    id
    durationSec
    billable
    status
    billingRate
    amount
    activity {
      ...TimeEntryActivitySelect
    }
    date
    description
    notes
    contact {
      ...ContactSelect
    }
    matter {
      ...MatterSelect
    }
    billedBy {
      ...TeamMemberSelect
    }
    timer {
      id
    }
  }
  ${CONTACT_SELECT_FRAGMENT}
  ${MATTER_SELECT_FRAGMENT}
  ${TEAM_MEMBER_SELECT_FRAGMENT}
  ${TIME_ENTRY_ACTIVITY_SELECT_FRAGMENT}
`;

export const FETCH_TIMER = gql`
  query FetchTimer($id: ID!) {
    timer: getTimer(id: $id) {
      id
      totalSeconds
      matter {
        ...MatterSelect
      }
      contact {
        ...ContactSelect
      }
      description
    }
  }
  ${MATTER_SELECT_FRAGMENT}
  ${CONTACT_SELECT_FRAGMENT}
`;

export const FETCH_TIME_ENTRY = gql`
  query FetchTimeEntry($id: ID!) {
    timeEntry: getTimeEntry(id: $id) {
      ...TimeEntryModalFragment
    }
  }
  ${TIME_ENTRY_MODAL_FRAGMENT}
`;

export const CREATE_TIME_ENTRY = gql`
  mutation CreateTimeEntry($timeEntry: CreateTimeEntryInput!) {
    createTimeEntry(createTimerInput: $timeEntry) {
      ...TimeEntryModalFragment
    }
  }
  ${TIME_ENTRY_MODAL_FRAGMENT}
`;

export const UPDATE_TIME_ENTRY = gql`
  mutation UpdateTimeEntry($timeEntry: UpdateTimeEntryInput!) {
    updateTimeEntry(updateTimerInput: $timeEntry) {
      ...TimeEntryModalFragment
    }
  }
  ${TIME_ENTRY_MODAL_FRAGMENT}
`;

export const FETCH_CONTACT = gql`
  query FetchContact($id: ID!) {
    contact: getContact(id: $id) {
      ...ContactSelect
    }
  }
  ${CONTACT_SELECT_FRAGMENT}
`;

export const FETCH_MATTER = gql`
  query FetchMatter($id: ID!) {
    matter: getMatter(id: $id) {
      ...MatterSelect
    }
  }
  ${MATTER_SELECT_FRAGMENT}
`;
