import * as Yup from 'yup';

import {
  PrintingResults,
  PrintingResultsFormValuesType,
} from './PrintingResultsForm.types';

export const PrintingResultsFormSchema = Yup.object().shape<
  PrintingResultsFormValuesType
>({
  printResult: Yup.mixed<PrintingResults>().required(),
  reprintStartNo: Yup.number().defined(),
});
