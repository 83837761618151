import * as Yup from 'yup';
import { getI18nErrorMessage } from 'i18n';

import { TimeEntryActivityFormValuesType } from './TimeEntryActivityForm.types';

export const TimeEntryActivityFormSchema = Yup.object().shape<TimeEntryActivityFormValuesType>(
  {
    name: Yup.string()
      .min(2, ({ min }) =>
        getI18nErrorMessage(
          'modal.popup.timeEntryActivity.form.error.name.minLength',
          { min }
        )
      )
      .max(255, ({ max }) => getI18nErrorMessage(
        'modal.popup.timeEntryActivity.form.error.name.maxLength',
        { max }
      ))
      .required(
        getI18nErrorMessage(
          'modal.popup.timeEntryActivity.form.error.name.required'
        )
      ),
  }
);
