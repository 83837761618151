import React, { useContext } from 'react';
import { Form, Fieldset } from '@appclose/core';
import { Button, ButtonGroup, Text3 } from '@appclose/ui';

import { QboAccountTypes } from '__generated__/globalTypes';
import { WizardContext } from 'components/common/Wizard';
import { I18n, useIntl } from 'i18n';

import IntegrationMappingGrid from '../IntegrationMappingGrid';
import IntegrationMappingInfo from '../IntegrationMappingInfo';
import QboAccountSelectFormField from '../QboAccountSelectFormField';
import QboServiceItemSelectFormField from '../QboServiceItemSelectFormField';
import QboMappingTargetField from '../QboMappingTargetField';
import { BankAccountsAndServiceItemsStepSchema } from './BankAccountsAndServiceItemsStep.schema';
import {
  BankAccountsAndServiceItemsStepPropsType,
  BankAccountsAndServiceItemsStepValuesType,
} from './BankAccountsAndServiceItemsStep.types';

export default function BankAccountsAndServiceItemsStep({
  initialValues,
  onStepComplete,
  onPreviousStep,
  onClose,
  onChange,
}: BankAccountsAndServiceItemsStepPropsType) {
  const { t } = useIntl();
  const { goToPrevStep, goToNextStep, isFirstStep, isLastStep } = useContext(
    WizardContext
  );

  const handleOnSubmit = async (
    values: BankAccountsAndServiceItemsStepValuesType
  ) => {
    await onStepComplete(values, isLastStep);
    goToNextStep();
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      validationSchema={BankAccountsAndServiceItemsStepSchema}
    >
      {({ values, isSubmitting }) => {
        onChange(values);
        const { trustAccount, isEsqash } = values;

        return (
          <>
            <Text3 offset={{ bottom: 30 }}>
              <I18n id="modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.generalInfo" />
            </Text3>
            <Fieldset
              title={t(
                'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.mapOperatingAccount.title'
              )}
            >
              <IntegrationMappingInfo>
                <I18n id="modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.mapOperatingAccount.info" />
              </IntegrationMappingInfo>
              <IntegrationMappingGrid
                esqTitle={t(
                  'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.mapOperatingAccount.esqTitle'
                )}
                qboTitle={t(
                  'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.mapOperatingAccount.qboTitle'
                )}
              >
                <QboMappingTargetField
                  title={t(
                    'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.mapOperatingAccount.operatingAccount.title'
                  )}
                />
                <QboAccountSelectFormField
                  name="operatingAccount"
                  accountType={QboAccountTypes.BANK}
                  exclude={[trustAccount]}
                />
              </IntegrationMappingGrid>
            </Fieldset>
            <Fieldset
              title={
                isEsqash
                  ? t(
                      'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.mapServiceItems.title.free'
                    )
                  : t(
                      'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.mapServiceItems.title.plus'
                    )
              }
            >
              <IntegrationMappingInfo>
                {isEsqash ? (
                  <I18n id="modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.mapServiceItems.info.free" />
                ) : (
                  <I18n id="modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.mapServiceItems.info.plus" />
                )}
              </IntegrationMappingInfo>
              <IntegrationMappingGrid
                esqTitle={t(
                  'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.mapServiceItems.esqTitle'
                )}
                qboTitle={t(
                  'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.mapServiceItems.qboTitle'
                )}
                qboTooltip={t(
                  'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.mapServiceItems.qboTooltip'
                )}
              >
                {isEsqash ? (
                  <>
                    <QboMappingTargetField
                      title={t(
                        'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.mapServiceItems.commonInvoiceItem.title'
                      )}
                    />
                    <QboServiceItemSelectFormField name="commonInvoiceItem" />
                  </>
                ) : (
                  <>
                    <QboMappingTargetField
                      title={t(
                        'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.mapServiceItems.timeEntryItem.title'
                      )}
                      tooltip={t(
                        'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.mapServiceItems.timeEntryItem.tooltip'
                      )}
                    />
                    <QboServiceItemSelectFormField name="timeEntryItem" />
                    <QboMappingTargetField
                      title={t(
                        'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.mapServiceItems.flatFeeItem.title'
                      )}
                    />
                    <QboServiceItemSelectFormField name="flatFeeItem" />
                    <QboMappingTargetField
                      title={t(
                        'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.mapServiceItems.expenseItem.title'
                      )}
                      tooltip={t(
                        'modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.mapServiceItems.expenseItem.tooltip'
                      )}
                    />
                    <QboServiceItemSelectFormField name="expenseItem" />
                  </>
                )}
              </IntegrationMappingGrid>
            </Fieldset>
            <ButtonGroup>
              <Button
                onClick={
                  isFirstStep
                    ? onClose
                    : () => {
                        onPreviousStep(values);
                        goToPrevStep();
                      }
                }
                disabled={isSubmitting}
              >
                {isFirstStep ? (
                  <I18n id="modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.cancel" />
                ) : (
                  <I18n id="modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.back" />
                )}
              </Button>
              <Button type="submit" skin="primary" loading={isSubmitting}>
                <I18n id="modal.qboIntegrationSetup.step.bankAccountsAndServiceItems.continue" />
              </Button>
            </ButtonGroup>
          </>
        );
      }}
    </Form>
  );
}
