import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import WizardCompleted from './components/WizardCompleted';
import WizardProgress from './components/WizardProgress';
import { WizardStepPropsType } from './components/WizardStep/WizardStep.types';
import { WizardStep } from './index';

import { WizardPropsType } from './Wizard.types';
import { WizardContext } from './Wizard.context';

export default function Wizard({ children, onComplete }: WizardPropsType) {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const stepsChildren = useMemo(
    () =>
      React.Children.toArray(children).filter((child) => {
        return (child as ReactElement)?.type === WizardStep;
      }),
    [children]
  );

  const completedChild = useMemo(
    () =>
      React.Children.toArray(children).find((child) => {
        return (child as ReactElement)?.type === WizardCompleted;
      }),
    [children]
  );

  const steps = useMemo(
    () =>
      stepsChildren.map((child, step) => {
        return {
          step,
          title: (child as ReactElement<WizardStepPropsType>).props.title,
        };
      }),
    [stepsChildren]
  );

  const goToNextStep = useCallback(async () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    } else {
      setLoading(true);
      const success = !onComplete || (await onComplete());
      success && setCompleted(true);
      setLoading(false);
    }
  }, [onComplete, step, steps.length]);

  const goToPrevStep = useCallback(() => {
    if (step > 0) {
      setStep(step - 1);
    }
  }, [step]);

  const complete = useCallback(() => setCompleted(true), []);

  return (
    <WizardContext.Provider
      value={{
        step,
        completed,
        goToNextStep,
        goToPrevStep,
        complete,
        loading,
        isFirstStep: step === 0,
        isLastStep: step === steps.length - 1,
      }}
    >
      <WizardProgress steps={steps} />
      <div>{completed ? completedChild : stepsChildren[step]}</div>
    </WizardContext.Provider>
  );
}
