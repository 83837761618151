import React, { ChangeEvent, useCallback } from 'react';
import { Grid } from '@appclose/ui';
import {
  FormGroup,
  InputFormField,
  RadioFormField,
  useFormContext,
  useIsMobileDevice,
  Fieldset,
} from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import ContactSelectFormField from 'components/fields/ContactSelectFormField';
import TeamMemberSelectFormField from 'components/fields/TeamMemberSelectFormField';
import { useIntl } from 'i18n';

import { SignerTypes } from 'constants/esign';

import { SignerPropsType } from './Signer.types';
import styles from './Signer.module.scss';
import {
  SignersFormFieldValuesType,
} from '../../SignersFormField.types';

export default function Signer({
  role,
  index,
  resolveName,
  className,
}: SignerPropsType) {
  const { t } = useIntl();
  const isMobile = useIsMobileDevice();
  const { id: roleId = '', roleName } = role || {};
  const {
    setFieldValue,
    values: { signers },
  } = useFormContext<SignersFormFieldValuesType>();
  const { type } = signers[index];

  const onTypeChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(resolveName(), { type: value, roleId }, true);
    },
    [roleId, resolveName, setFieldValue]
  );

  return (
    <Fieldset
      className={className}
      title={t('modal.signatureDocument.form.signers.signer', {
        info: `${index + 1}${roleName ? ` - ${roleName}` : ''}`,
      })}
    >
      <FormGroup>
        <Grid
          gap={isMobile ? [0, 15] : [50]}
          columns={isMobile ? 'auto' : 'repeat(3, max-content)'}
        >
          <RadioFormField
            name={resolveName('type')}
            label={t(
              'modal.signatureDocument.form.signers.signer.contact.radio'
            )}
            value={SignerTypes.CONTACT}
            className={styles.radio}
            onChange={onTypeChange}
          />
          <RadioFormField
            name={resolveName('type')}
            label={t(
              'modal.signatureDocument.form.signers.signer.member.radio'
            )}
            value={SignerTypes.MEMBER}
            className={styles.radio}
            onChange={onTypeChange}
          />
          <RadioFormField
            name={resolveName('type')}
            label={t(
              'modal.signatureDocument.form.signers.signer.custom.radio'
            )}
            value={SignerTypes.CUSTOM}
            className={styles.radio}
            onChange={onTypeChange}
          />
        </Grid>
      </FormGroup>
      <FormGroup>
        <FormGrid>
          {type === SignerTypes.CONTACT && (
            <ContactSelectFormField
              allowAddNew
              name={resolveName('contact')}
              onChange={(contact) =>
                setFieldValue(resolveName('email'), contact?.email, true)
              }
            />
          )}
          {type === SignerTypes.MEMBER && (
            <TeamMemberSelectFormField
              name={resolveName('teamMember')}
              label={t(
                'modal.signatureDocument.form.signers.signer.member.teamMember'
              )}
              placeholder={t(
                'modal.signatureDocument.form.signers.signer.member.teamMember.placeholder'
              )}
              showLabel="onFocus"
              showPlaceholder="always"
              onChange={(teamMember) =>
                setFieldValue(resolveName('email'), teamMember?.email, true)
              }
            />
          )}
          {type === SignerTypes.CUSTOM && (
            <InputFormField
              name={resolveName('externalSigner.name')}
              label={t(
                'modal.signatureDocument.form.signers.signer.custom.name'
              )}
            />
          )}
          <InputFormField
            name={resolveName(
              type === SignerTypes.CUSTOM ? 'externalSigner.email' : 'email'
            )}
            label={t('modal.signatureDocument.form.signers.signer.email')}
            disabled={type !== SignerTypes.CUSTOM}
          />
        </FormGrid>
      </FormGroup>
    </Fieldset>
  );
}
