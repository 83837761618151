import React, { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import omit from 'lodash/omit';
import { dateManager, Loader } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { track } from 'controllers/analytics';
import { openModal } from 'controllers/modal';
import { DemoEventNames, EntryTypes } from 'constants/analytics';
import { SERVER_DATE_FORMAT } from 'constants/date';
import { EXPENSE_DETAILS_MODAL } from 'constants/modals';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { useIntl } from 'i18n';
import { SubExpenseCategoryCostTypes } from '__generated__/globalTypes';
import useUpdateFiles from 'hooks/useUpdateFiles';
import useProfile from 'hooks/useProfile';
import useLastUsage from 'hooks/useLastUsage';
import { Entities } from 'constants/entities';

import { ExpenseModalPropsType } from './ExpenseModal.types';
import ExpenseForm from './components/ExpenseForm';
import { ExpenseFormValuesType } from './components/ExpenseForm/ExpenseForm.types';
import {
  CREATE_EXPENSE,
  FETCH_CONTACT,
  FETCH_EXPENSE,
  FETCH_MATTER,
  UPDATE_EXPENSE,
} from './ExpenseModal.gql';
import {
  CreateExpenseMutation,
  CreateExpenseMutationVariables,
  FetchContactQuery,
  FetchContactQueryVariables,
  FetchExpenseQuery,
  FetchExpenseQueryVariables,
  FetchMatterQuery,
  FetchMatterQueryVariables,
  UpdateExpenseMutation,
  UpdateExpenseMutationVariables,
} from './__generated__/ExpenseModal.gql';

export default function ExpenseModal({
  id,
  contactId,
  matterId,
  onClose,
}: ExpenseModalPropsType) {
  const { t } = useIntl();
  const { setLastUsage: setLastUsageOfSoftCategory } = useLastUsage(
    'expenseSoftCategory'
  );
  const { setLastUsage: setLastUsageOfHardCategory } = useLastUsage(
    'expenseHardCategory'
  );
  const { onUpdateFiles } = useUpdateFiles(Entities.EXPENSE);
  const isEdit = !!id;
  const title = !isEdit
    ? t('modal.expense.create.title')
    : t('modal.expense.edit.title');
  const { loading: loadingProfile, profile } = useProfile();
  const { loading: loadingContact, data: contactData } = useQuery<
    FetchContactQuery,
    FetchContactQueryVariables
  >(FETCH_CONTACT, {
    variables: {
      id: contactId as string,
    },
    skip: !contactId,
  });
  const { loading: loadingMatter, data: matterData } = useQuery<
    FetchMatterQuery,
    FetchMatterQueryVariables
  >(FETCH_MATTER, {
    variables: {
      id: matterId as string,
    },
    skip: !matterId,
  });
  const [createExpense] = useMutation<
    CreateExpenseMutation,
    CreateExpenseMutationVariables
  >(CREATE_EXPENSE);
  const { loading, data } = useQuery<
    FetchExpenseQuery,
    FetchExpenseQueryVariables
  >(FETCH_EXPENSE, {
    variables: {
      id: id as string,
    },
    skip: !id,
  });
  const [updateExpense] = useMutation<
    UpdateExpenseMutation,
    UpdateExpenseMutationVariables
  >(UPDATE_EXPENSE);
  const isLoading =
    loading || loadingContact || loadingMatter || loadingProfile;
  const expense = data?.expense;
  const profileId = profile?.id || '';

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });

  const handleOnSubmit = useCallback(
    async ({
      contact,
      matter,
      category,
      billedBy,
      costType,
      files,
      ...values
    }: ExpenseFormValuesType) => {
      const data = {
        contactId: contact?.id as string,
        matterId: matter?.id as string,
        billedById: billedBy?.id as string,
        categoryId: category?.id as string,
        ...values,
      };

      let expenseId = id;

      if (!isEdit) {
        const result = await createExpense({
          variables: {
            input: data,
          },
        });

        notification().entityCreated(t('modal.expense.notification.created'));

        track(DemoEventNames.DEMO_ENTRY_CREATED, {
          entry_type: EntryTypes.EXPENSE,
        });

        expenseId = result.data?.createSubExpense.id;
      } else {
        await updateExpense({
          variables: {
            input: {
              id: id as string,
              ...data,
            },
          },
        });

        notification().entityUpdated(t('modal.expense.notification.updated'));
      }

      if (expenseId && files) {
        await onUpdateFiles({ entityId: expenseId, ...files });
      }

      onClose();

      category?.costType === SubExpenseCategoryCostTypes.SOFT
        ? setLastUsageOfSoftCategory(category?.id)
        : setLastUsageOfHardCategory(category?.id);

      expenseId && openModal(EXPENSE_DETAILS_MODAL, { id: expenseId });
    },
    [
      id,
      isEdit,
      onClose,
      setLastUsageOfSoftCategory,
      setLastUsageOfHardCategory,
      createExpense,
      t,
      updateExpense,
      onUpdateFiles,
    ]
  );

  const initialValues = {
    id,
    costType: expense?.category?.costType || SubExpenseCategoryCostTypes.SOFT,
    amount: 0,
    date: expense?.date
      ? dateManager().parse(expense.date).format(SERVER_DATE_FORMAT)
      : dateManager().parse().startOf('day').format(SERVER_DATE_FORMAT),
    billable: true,
    contact: contactData?.contact || matterData?.matter?.contact,
    matter: matterData?.matter,
    ...omit(expense || {}, ['id', 'status', 'date']),
  };

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>{title}</ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {isLoading ? (
          <Loader />
        ) : (
          <ExpenseForm
            initialValues={initialValues}
            isEdit={isEdit}
            profileId={profileId}
            onSubmit={handleOnSubmit}
            onCancel={onConfirmClose}
            onChange={onFormChange}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
