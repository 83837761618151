import { useQuery } from '@apollo/client';

import { FETCH_FIRM } from './useFirm.gql';
import { FetchFirmQuery } from './__generated__/useProfile.gql';

export default function useFirm() {
  const { data, refetch, loading } = useQuery<FetchFirmQuery>(FETCH_FIRM);

  const firmData = data?.firm || {};

  return {
    loading,
    data: firmData,
    refetch
  };
}
