import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { dateManager } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { track } from 'controllers/analytics';
import { openModal } from 'controllers/modal';
import { EntryTypes, DemoEventNames } from 'constants/analytics';
import { DEPOSIT_DETAILS_MODAL } from 'constants/modals';
import { SERVER_DATE_FORMAT } from 'constants/date';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import useCanCreateTrustTransaction from 'hooks/useCanCreateTrustTransaction';

import { useIntl, I18n } from 'i18n';

import { DepositModalPropsType } from './DepositModal.types';
import DepositForm from './components/DepositForm';
import {
  DepositFormActionsType,
  DepositFormValuesType,
} from './components/DepositForm/DepositForm.types';
import { CREATE_DEPOSIT } from './DepositModal.gql';
import {
  CreateDepositMutation,
  CreateDepositMutationVariables,
} from './__generated__/DepositModal.gql';

export default function DepositModal({ onClose }: DepositModalPropsType) {
  const { t } = useIntl();
  const [createDeposit] = useMutation<
    CreateDepositMutation,
    CreateDepositMutationVariables
  >(CREATE_DEPOSIT);
  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });
  const {
    getCanCreateTrustTransaction,
    openUnableToCreateTrustTransactionPopup,
  } = useCanCreateTrustTransaction('deposit', onClose);

  const handleOnSubmit = useCallback(
    async (
      values: DepositFormValuesType,
      { setSubmitting }: DepositFormActionsType
    ) => {
      const { payments, date } = values;

      const canCreate = getCanCreateTrustTransaction(date);

      if (!canCreate) {
        openUnableToCreateTrustTransactionPopup();

        return;
      }

      try {
        const { data } = await createDeposit({
          variables: {
            deposit: {
              transactions: payments.map(({ contact, matter, ...props }) => ({
                ...props,
                contactId: contact.id,
                matterId: matter?.id,
              })),
              date,
            },
          },
        });

        track(DemoEventNames.DEMO_ENTRY_CREATED, {
          entry_type: EntryTypes.TRUST_DEPOSIT,
        });

        notification().entityCreated(
          t('modal.deposit.notification.create.success')
        );

        onClose();
        openModal(DEPOSIT_DETAILS_MODAL, { id: data?.deposit.id });
      } catch (e) {
        setSubmitting(false);
      }
    },
    [
      createDeposit,
      onClose,
      t,
      getCanCreateTrustTransaction,
      openUnableToCreateTrustTransactionPopup,
    ]
  );

  const initialValues = {
    payments: [{ amount: 0 }],
    amount: 0,
    date: dateManager().parse().startOf('day').format(SERVER_DATE_FORMAT),
  } as DepositFormValuesType;

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.deposit.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        <DepositForm
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          onCancel={onConfirmClose}
          onChange={onFormChange}
        />
      </ModalPageContent>
    </ModalPage>
  );
}
