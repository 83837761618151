import React, { useCallback, useEffect } from 'react';
import set from 'lodash/set';
import { useFormContext } from '@appclose/core';

import TeamMemberSelectFormField from 'components/fields/TeamMemberSelectFormField';
import { useIntl } from 'i18n';

import {
  BilledByFieldPropsType,
  BilledByFormContext,
} from './BilledByField.types';

export default function BilledByField({
  profileId,
  onChange,
}: BilledByFieldPropsType) {
  const { t } = useIntl();
  const {
    values: { matter, billedBy },
    setValues,
  } = useFormContext<BilledByFormContext>();

  const getTeamMember = useCallback(
    (billedBy: BilledByFormContext['billedBy']) => {
      const { teamMembers = [] } = matter || {};

      return (
        teamMembers.find(({ member: { id } }) => id === billedBy?.id) ||
        teamMembers.find(({ member: { id } }) => id === profileId)
      );
    },
    [matter, profileId]
  );

  useEffect(() => {
    const teamMember = getTeamMember(billedBy);

    if (teamMember?.member.id !== billedBy?.id) {
      setValues((values) => set(values, 'billedBy', teamMember?.member), true);
      onChange?.(teamMember?.member, teamMember?.hourlyRate);
    }
  }, [matter, billedBy, profileId, onChange, getTeamMember, setValues]);

  const handleOnChange = useCallback(
    (billedBy: BilledByFormContext['billedBy']) => {
      const teamMember = getTeamMember(billedBy);

      onChange?.(billedBy, teamMember?.hourlyRate);
    },
    [getTeamMember, onChange]
  );

  return (
    <TeamMemberSelectFormField
      name="billedBy"
      placeholder=""
      label={t('field.billedBy.label')}
      matterId={matter?.id}
      disabled={!matter}
      onChange={handleOnChange}
      clearable={false}
    />
  );
}
