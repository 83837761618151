import { useIntl } from 'i18n';
import { AddressTypes } from '__generated__/globalTypes';
import { useCallback, useMemo } from 'react';

export default function useAddressTypes() {
  const { t } = useIntl();
  const addressTypes: Record<AddressTypes, string> = useMemo(
    () => ({
      [AddressTypes.PRIMARY]: t('enum.addressTypes.primary'),
      [AddressTypes.SECONDARY]: t('enum.addressTypes.secondary'),
    }),
    [t]
  );

  const addressTypesOptions: {
    label: string;
    value: AddressTypes;
  }[] = useMemo(
    () =>
      Object.entries(addressTypes).map(([value, label]) => ({
        value: value as AddressTypes,
        label,
      })),
    [addressTypes]
  );

  const getAddressTypeLabel = useCallback(
    (type?: AddressTypes | null) => {
      if (!type) {
        return '';
      }

      return addressTypes[type];
    },
    [addressTypes]
  );

  return { addressTypesOptions, getAddressTypeLabel };
}
