export enum EventNames {
  ACTION_WITH_TIMER = 'ACTION_WITH_TIMER',
  ADD_NEW_ENTRY = 'ADD_NEW_ENTRY',
  BILLABLE_ACTIVITY_VISITED = 'BILLABLE_ACTIVITY_VISITED',
  BILLING_VISITED = 'BILLING_VISITED',
  CALENDAR_VISITED = 'CALENDAR_VISITED',
  CANCEL_ENTRY = 'CANCEL_ENTRY',
  COMPLETE_ACC_SETUP_CONTINUE_CLICK = 'COMPLETE_ACC_SETUP_CONTINUE_CLICK',
  COMPLETE_ACC_SETUP_LOGOUT_CLICK = 'COMPLETE_ACC_SETUP_LOGOUT_CLICK',
  COMPLETE_ACC_SETUP_OUT_CLICK = 'COMPLETE_ACC_SETUP_OUT_CLICK',
  CONTACTS_VISITED = 'CONTACTS_VISITED',
  LEADS_VISITED = 'LEADS_VISITED',
  DASHBOARD_VISITED = 'DASHBOARD_VISITED',
  ESIGN_VISITED = 'ESIGN_VISITED',
  FILTER_USED = 'FILTER_USED',
  INVOICE_MANUALLY_SENT = 'INVOICE_MANUALLY_SENT',
  MAF_FIELD_EDITED = 'MAF_FIELD_EDITED',
  MATTERS_VISITED = 'MATTERS_VISITED',
  OPEN_TIMER_LIST = 'OPEN_TIMER_LIST',
  PAYMENT_BUTTON_ADDED = 'PAYMENT_BUTTON_ADDED',
  PERSONAL_SETTINGS_VISITED = 'PERSONAL_SETTINGS_VISITED',
  PRIVACY_SWITCHED = 'PRIVACY_SWITCHED',
  PROFESSIONAL_WEBSITE_SWITCH = 'PROFESSIONAL_WEBSITE_SWITCH',
  REFUNDS_VISITED = 'REFUNDS_VISITED',
  REPORTS_VISITED = 'REPORTS_VISITED',
  SESSION_START = 'SESSION_START',
  SETTINGS_VISITED = 'SETTINGS_VISITED',
  SUBSCRIBE_AND_SAVE = 'SUBSCRIBE_AND_SAVE',
  SUBSECTION_VISITED = 'SUBSECTION_VISITED',
  TRUST_VISITED = 'TRUST_VISITED',
  FILE_STORAGE_VISITED = 'FILE_STORAGE_VISITED',
}

export enum DemoEventNames {
  DEMO_SWITCH_TO_FREE = 'DEMO_SWITCH_TO_FREE',
  DEMO_SWITCH_TO_PLUS = 'DEMO_SWITCH_TO_PLUS',
  DEMO_SIGN_UP = 'DEMO_SIGN_UP',
  DEMO_ENTRY_CREATED = 'DEMO_ENTRY_CREATED',
}

export enum SectionTypes {
  BILLING = 'billing',
  CONTACTS = 'contacts',
  LEADS = 'leads',
  DASHBOARD = 'dashboard',
  ESIGN = 'esign',
  EXPENSES = 'expenses',
  MAIN_SCREEN = 'main screen',
  MATTERS = 'matters',
  PERSONAL_SETTINGS = 'personal settings',
  REPORTS = 'reports',
  SETTINGS = 'settings',
  TIMER_LIST = 'timer list',
  TRUST = 'trust',
}

export enum SubsectionTypes {
  ACCOUNTS = 'accounts',
  ACTIVITIES = 'activities',
  CALENDAR_SYNC = 'calendar sync',
  CONTACTS = 'contacts',
  LEADS = 'leads',
  CONTACT_LEDGERS = 'contact ledgers',
  DEPOSITS = 'deposits',
  DISBURSEMENTS = 'disbursements',
  DOCUMENTS = 'documents',
  EXPENSES = 'expenses',
  EXPENSE_CATEGORIES = 'expense categories',
  FILES = 'files',
  FIRM_PROFILE = 'firm profile',
  UPGRADE_PLAN = 'upgrade plan',
  FLAT_FEES = 'flat fees',
  INTEGRATIONS = 'integrations',
  INVOICES = 'invoices',
  MATTERS = 'matters',
  OUTSTANDING_INVOICES = 'outstanding invoices',
  OVERVIEW = 'overview',
  PAID_INVOICES = 'paid invoices',
  PAYMENT_LINK = 'payment link',
  PERSONAL_SETTINGS = 'personal settings',
  RECONCILIATIONS = 'reconciliations',
  ROUNDING_RULES = 'rounding rules',
  SECURITY = 'security',
  SITE = 'site',
  TEAM_MEMBERS = 'team members',
  TEMPLATES = 'templates',
  THIRD_PARTY_PAYEES = 'third party payees',
  TIME_ENTRIES = 'time entries',
  TIME_ENTRY_ACTIVITIES = 'time entry activities',
  TRANSACTIONS = 'transactions',
  TRANSACTION_LEDGER = 'transaction ledger',
}

export enum SubsectionTypes2 {
  INVOICES = 'invoices',
  FLAT_FEES = 'flat fees',
}

export enum EntryTypes {
  CONTACT = 'contact',
  LEAD = 'lead',
  MATTER = 'matter',
  CONTACT_MATTER = 'contact matter',
  INVOICE = 'invoice',
  TIME_ENTRY = 'time entry',
  TIME_ENTRY_ACTIVITY = 'time entry activity',
  FLAT_FEE = 'flat fee',
  EXPENSE = 'expense',
  EXPENSE_CATEGORY = 'expense category',
  THIRD_PARTY_PAYEE = 'third party payee',
  RECEIVE_PAYMENT = 'receive payment',
  TRUST_DISBURSEMENT = 'trust disbursement',
  TRUST_DEPOSIT = 'trust deposit',
  TRANSFER_FUNDS = 'transfer funds',
  RECONCILIATION = 'reconciliation',
  DOCUMENT_TO_SIGN = 'document to sign',
  TEMPLATE = 'template',
}

export enum SourceTypes {
  MAIN_MENU = 'main menu',
  SECTION = 'section',
  SUBSECTION = 'subsection',
  POPUP_MODAL = 'popup modal',
}

export enum TimerActionTypes {
  START_TIMER = 'start timer',
  DELETE_TIMER = 'delete timer',
  ADD_NEW_TIMER = 'add new timer',
  PAUSE_TIMER = 'pause timer',
  COMPLETE_TIMER = 'complete timer',
}

export enum MafSteps {
  OWNER_INFO = 1,
  MERCHANT_FORM = 2,
}

export enum MafTypes {
  OPERATING = 'operating',
  TRUST = 'trust',
}
