import { gql } from '@apollo/client';

import { ADDRESS_FRAGMENT } from 'components/common/Address';
import { LEDGER_NAME_FRAGMENT } from 'components/common/LedgerName';

export const FETCH_FIRM = gql`
  query FetchFirm {
    firm: getFirm {
      id
      name
      address {
        ...Address
      }
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export const FETCH_DEPOSIT = gql`
  query FetchDeposit($id: ID!) {
    deposit: getDeposit(id: $id) {
      id
      date
      status
      amount
      paymentMethod
      qboSyncState
      depositTransactions {
        id
        paymentMethod
        amount
        source {
          id
          name
          addresses {
            ...Address
          }
        }
        ledger {
          ...LedgerName
        }
        memo
      }
      systemTransaction {
        id
        paymentMethod
        paymentDate
        amount
        invoices {
          id
          invoiceNumber
          matters {
            id
            name
            matterNumber
          }
        }
        contact {
          id
          name
          addresses {
            ...Address
          }
        }
      }
    }
  }
  ${ADDRESS_FRAGMENT}
  ${LEDGER_NAME_FRAGMENT}
`;
