import * as Yup from 'yup';

import { getI18nErrorMessage } from 'i18n';
import { MEMO_PURPOSE_FILED_LENGTH_LIMIT } from 'constants/fieldsLimitations';

import { DisbursementToOperatingFormValuesType } from './DisbursementToOperatingForm.types';
import { OtoFieldItem } from './components/OtosField';

export const DisbursementToContactFormSchema = Yup.object().shape<
  DisbursementToOperatingFormValuesType
>({
  otos: Yup.array<OtoFieldItem>().required(
    getI18nErrorMessage('modal.disbursement.form.operating.error.otos.required')
  ),
  memo: Yup.string()
    .max(MEMO_PURPOSE_FILED_LENGTH_LIMIT, ({ max }) =>
      getI18nErrorMessage(
        'modal.disbursement.form.contact.error.memo.maxLength',
        { max }
      )
    )
    .required(
      getI18nErrorMessage(
        'modal.disbursement.form.operating.error.memo.required'
      )
    ),
});
