import React from 'react';
import { Loader, traceError } from '@appclose/core';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import {
  KybBusinessActivityType,
  KybInput,
  StripeKybStatus,
} from '__generated__/globalTypes';
import { ONBOARDING_COMPLETE_ROUTE } from 'constants/routes';
import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import MerchantApplicationForm, {
  MerchantApplicationsFormActionsType
} from 'components/form/MerchantApplicationForm';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { I18n } from 'i18n';

import { SEND_KYB, FETCH_FIRM } from './KybModal.gql';
import {
  SendKybMutation,
  SendKybMutationVariables,
  FetchFirmQuery,
  FetchFirmQueryVariables,
} from './__generated__/KybModal.gql';
import { KybModalPropsType } from './KybModal.types';

export default function KybModal({
  onClose,
  initialData,
}: KybModalPropsType) {
  const { data, loading } = useQuery<FetchFirmQuery, FetchFirmQueryVariables>(
    FETCH_FIRM, {
      fetchPolicy: 'network-only',
    }
  );
  const history = useHistory();

  const [sendKyb] = useMutation<
    SendKybMutation,
    SendKybMutationVariables
  >(SEND_KYB);

  const { onConfirmClose } = useCloseConfirm({
    onClose,
    defaultShowConfirm: true,
  });

  const handleOnSubmit = async (
    values: KybInput,
    { setSubmitting }: MerchantApplicationsFormActionsType
  ) => {
    try {
      await sendKyb({ variables: { input: values } });

      onClose();
      history.push(ONBOARDING_COMPLETE_ROUTE);
    } catch (e: any) {
      traceError(e);
      setSubmitting(false);
    }
  };

  const { address, ...owner } = data?.firm.owner || {};
  const initialValues = {
    controlPerson: data?.firm.owner && {
      ...owner,
      address1: address?.address1,
      address2: address?.address2,
      city: address?.city,
      state: address?.state,
      zip: address?.zipCode,
    },
    businessInfo: {
      businessActivity: KybBusinessActivityType.LEGAL_SERVICES_AND_ATTORNEYS
    },
    owners: [],
    ...initialData
  } as KybInput;


  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="page.onboarding.KYB.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading ? (
          <Loader />
        ) : (
          <MerchantApplicationForm
            initialValues={initialValues}
            isFailed={data?.firm.stripeKybStatus === StripeKybStatus.REJECTED}
            errors={data?.firm.stripeKybErrors}
            onSubmit={handleOnSubmit}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
