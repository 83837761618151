import { gql } from '@apollo/client';

import { AVATAR_FRAGMENT } from 'components/common/Avatar';

export const INVOICE_ACTIVITIES_FRAGMENT = gql`
  fragment InvoiceActivities on InvoiceType {
    timeEntries {
      id
      activity {
        id
        name
      }
      date
      billedBy {
        id
        firstName
        lastName
        picture {
          ...Avatar
        }
      }
      status
      amount
    }
    expenses {
      id
      category {
        id
        name
      }
      date
      status
      amount
      billedBy {
        id
        firstName
        lastName
        picture {
          ...Avatar
        }
      }
    }
    flatFees {
      id
      description
      date
      billedBy {
        id
        firstName
        lastName
        picture {
          ...Avatar
        }
      }
      status
      amount
    }
  }
  ${AVATAR_FRAGMENT}
`;
