import React, { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { useQuery } from '@apollo/client';
import {
  permissionProvider,
  SelectFormField,
  useFormContext,
  usePopupModal,
} from '@appclose/core';

import { MatterStatuses, OrderTypes } from '__generated__/globalTypes';
import MatterPopupModal from 'components/modals/popups/MatterPopupModal';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import { track } from 'controllers/analytics';
import { EntryTypes, EventNames, SourceTypes } from 'constants/analytics';
import { useIntl } from 'i18n';

import { FETCH_MATTER_OPTIONS } from './MatterSelectFormField.gql';
import {
  FetchMatterOptionsQuery,
  FetchMatterOptionsQueryVariables,
} from './__generated__/MatterSelectFormField.gql';
import {
  MatterSelectFormFieldPropsType,
  SelectMatterType,
} from './MatterSelectFormField.types';

export default function MatterSelectFormField({
  name = 'matter',
  label,
  placeholder,
  showLabel = 'onFocus',
  showPlaceholder = 'always',
  filter = {},
  allowAddNew = false,
  onChange,
  ...rest
}: MatterSelectFormFieldPropsType) {
  const { t } = useIntl();
  const [search, setSearch] = useState('');
  const { setFieldValue } = useFormContext<any>();
  const queryVariables = useMemo(
    () => ({
      filter: { search, status: MatterStatuses.ACTIVE, ...filter },
      order: { dateOpened: OrderTypes.DESC },
    }),
    [filter, search]
  );

  const { loading, data, fetchMore, refetch } = useQuery<
    FetchMatterOptionsQuery,
    FetchMatterOptionsQueryVariables
  >(FETCH_MATTER_OPTIONS, {
    variables: queryVariables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: rest.disabled,
  });
  const matters = useMemo(() => data?.listMatters.items || [], [data]);

  const withAddNew =
    allowAddNew &&
    permissionProvider().hasPermission(
      PermissionResources.MATTER,
      PermissionActions.CREATE
    );

  const { openPopupModal } = usePopupModal(MatterPopupModal, (matter) => {
    setFieldValue(name, rest.isMulti ? [matter] : matter);
    refetch();

    if (onChange) {
      onChange(matter);
    }
  });

  const onOpenPopupModal = useCallback(() => {
    track(EventNames.ADD_NEW_ENTRY, {
      entry_type: EntryTypes.MATTER,
      source_type: SourceTypes.POPUP_MODAL,
    });

    openPopupModal({
      contactId: filter.clientId || '',
    });
  }, [filter.clientId, openPopupModal]);

  const onLoadMore = useCallback(async () => {
    const { data } = await fetchMore({
      variables: {
        skip: matters.length,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => ({
        listMatters: {
          ...previousResult.listMatters,
          items: [
            ...previousResult.listMatters.items,
            ...(fetchMoreResult?.listMatters.items || []),
          ],
        },
      }),
    });

    return matters.length !== data.listMatters.total;
  }, [matters.length, fetchMore]);

  const onSearchChange = useMemo(
    () => debounce((value: string) => setSearch(value), 500),
    [setSearch]
  );
  const optionLabelResolver = useCallback(
    ({ name }: SelectMatterType) => name,
    []
  );
  const selectedOptionsResolver = useCallback(
    (matters: SelectMatterType[]) => matters.map(({ name }) => name).join(', '),
    []
  );

  return (
    <SelectFormField
      name={name}
      label={label === undefined ? t('field.matterSelect.label') : label}
      placeholder={placeholder || t('field.matterSelect.placeholder')}
      showLabel={showLabel}
      showPlaceholder={showPlaceholder}
      onChange={onChange}
      {...rest}
      clearable
      withSearch
      isLoading={loading}
      onSearchChange={onSearchChange}
      options={matters}
      optionLabelResolver={optionLabelResolver}
      selectedOptionsResolver={selectedOptionsResolver}
      onLoadOptions={onLoadMore}
      onAdd={withAddNew ? onOpenPopupModal : undefined}
      onAddDisabled={!withAddNew || !filter?.clientId}
    />
  );
}
