import React, { useEffect } from 'react';
import set from 'lodash/set';
import { useQuery } from '@apollo/client';
import {
  Checkbox,
  Flex,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text4,
} from '@appclose/ui';
import {
  Amount,
  FieldError,
  FormArrayField,
  OverlayLoader,
  useFormContext,
  Fieldset,
} from '@appclose/core';

import { I18n, useIntl } from 'i18n';
import Date from 'components/common/Date';
import LedgerName from 'components/common/LedgerName';
import PrivacyValue from 'components/common/PrivacyValue';
import usePaymentMethodsTypes from 'hooks/usePaymentMethodsTypes';

import { PrintDepositsFormValuesType } from '../../PrintDepositsForm.types';

import {
  FetchPrintableDepositsQuery,
  FetchPrintableDepositsQueryVariables,
} from './__generated__/PrintableDepositsField.gql';
import { FETCH_PRINTABLE_DEPOSITS } from './PrintableDepositsField.gql';
import { PrintableDepositsFieldPropsType } from './PrintableDepositsField.types';
import styles from './PrintableDepositsField.module.scss';
import PrintableDepositsFieldTableHeader from './components/PrintableDepositsFieldTableHeader';

export default function PrintableDepositsField({
  name = 'deposits',
}: PrintableDepositsFieldPropsType) {
  const { t } = useIntl();
  const { getPaymentMethodsTypeLabel } = usePaymentMethodsTypes();
  const form = useFormContext<PrintDepositsFormValuesType>();
  const {
    values: { deposits, preSelectedDepositIds = [] },
    setValues,
  } = form;

  const { loading, data } = useQuery<
    FetchPrintableDepositsQuery,
    FetchPrintableDepositsQueryVariables
  >(FETCH_PRINTABLE_DEPOSITS, {
    fetchPolicy: 'network-only',
  });

  const printableDeposits = data?.deposits;
  const hasNoPrintableDeposits = !printableDeposits?.length;
  const selectedDepositsIds = deposits.map(({ id }) => id);

  const total = (printableDeposits || []).reduce((sum, { id, amount }) => {
    if (selectedDepositsIds.some((depositId) => depositId === id)) {
      return sum + amount;
    }

    return sum;
  }, 0);

  useEffect(() => {
    if (printableDeposits?.length && preSelectedDepositIds.length) {
      setValues((values) => ({
        ...values,
        deposits: printableDeposits.filter(({ id }) =>
          preSelectedDepositIds.includes(id)
        ),
        preSelectedDepositIds: [],
      }));
    }
  }, [setValues, printableDeposits, preSelectedDepositIds]);

  useEffect(() => {
    if (
      printableDeposits?.length === 1 &&
      !deposits.find(({ id }) => id === printableDeposits[0].id)
    ) {
      setValues((values) => set(values, name, printableDeposits), true);
    }
  }, [name, printableDeposits, deposits, setValues]);

  return (
    <FormArrayField name={name}>
      {({ values, remove, insert }) => (
        <Fieldset title={t('modal.printDeposits.form.deposits.title')}>
          {!loading && hasNoPrintableDeposits ? (
            <Text4>
              <I18n id="modal.printDeposits.form.deposits.empty" />
            </Text4>
          ) : (
            <>
              <OverlayLoader className={styles.overlay} loading={loading}>
                {!hasNoPrintableDeposits && (
                  <Table>
                    <PrintableDepositsFieldTableHeader
                      printableDeposits={printableDeposits}
                    />
                    <TableBody>
                      <>
                        {printableDeposits?.map((deposit) => {
                          const {
                            id,
                            date,
                            ledger,
                            source,
                            paymentMethod,
                            amount,
                          } = deposit;

                          return (
                            <TableRow
                              key={id}
                              onClick={() => {
                                if (!values?.some((value) => id === value.id)) {
                                  insert(values.length, deposit);
                                } else {
                                  const index = values.findIndex(
                                    (value) => value.id === id
                                  );

                                  if (index !== -1) {
                                    remove(index);
                                  }
                                }
                              }}
                            >
                              <TableCell width="50px">
                                <Checkbox
                                  readOnly
                                  checked={
                                    !!values.find((value) => value.id === id)
                                  }
                                />
                              </TableCell>
                              <TableCell>DEP</TableCell>
                              <TableCell noWordBreak theme="light">
                                <Date value={date} />
                              </TableCell>
                              <TableCell>
                                <PrivacyValue>
                                  <LedgerName value={ledger} />
                                </PrivacyValue>
                              </TableCell>
                              <TableCell>
                                <PrivacyValue>
                                  <LedgerName value={source} />
                                </PrivacyValue>
                              </TableCell>
                              <TableCell>
                                {getPaymentMethodsTypeLabel(paymentMethod)}
                              </TableCell>
                              <TableCell
                                noWordBreak
                                theme="strong"
                                align="right"
                              >
                                <PrivacyValue>
                                  <Amount value={amount} />
                                </PrivacyValue>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    </TableBody>
                  </Table>
                )}
              </OverlayLoader>
              {!loading && !hasNoPrintableDeposits && (
                <Flex
                  alignItems="center"
                  justify="space-between"
                  className={styles.total}
                >
                  <p className={styles.totalTitle}>
                    <I18n
                      id="modal.printDeposits.form.deposits.total"
                      values={{ total: values.length || 0 }}
                    />
                  </p>
                  <Amount value={total} className={styles.totalAmount} />
                </Flex>
              )}
              <FieldError name={name} />
            </>
          )}
        </Fieldset>
      )}
    </FormArrayField>
  );
}
