export const ROUNDING_DURATIONS: { [key: number]: 6 | 10 | 15 } = {
  10: 6,
  6: 10,
  4: 15,
}

const ROUNDING_DIRECTIONS = ['UP', 'DOWN']

export const ROUNDING_OPTIONS = ROUNDING_DIRECTIONS.map((value) => ({
  value,
  label: value.toLowerCase(),
}));

export const DIVIDER_OPTIONS = Object.keys(ROUNDING_DURATIONS).map((value) => ({
  value: +value,
  label: `1/${value}`,
}));


