import { gql } from '@apollo/client';

import { LEDGER_NAME_FRAGMENT } from 'components/common/LedgerName';

const TRUST_TRANSACTION_FRAGMENT = gql`
  fragment TrustTransaction on TrustTransactionType {
    id
    date
    type
    status
    amount
    balance
    ledgerBalance
    ledger {
      ...LedgerName
    }
    payeeOrSource {
      ...LedgerName
    }
  }
  ${LEDGER_NAME_FRAGMENT}
`;

const RECONCILIATION_SUMMARY_FRAGMENT = gql`
  fragment ReconciliationSummary on ReconciliationResultType {
    beginningBalance
    clearedDeposits
    clearedDisbursements
    clearedBalance
    endingBalance
    clearedDifference
    unclearedDeposits
    unclearedDisbursements
    adjustedBalance
    transactionLedgerBalance
    allContactLedgersBalance
    balanceDifference
  }
`;

export const FETCH_RECONCILIATION = gql`
  query FetchReconciliation($id: ID!) {
    reconciliation: getReconciliation(id: $id) {
      id
      statementBalance
      statementDate
      status
      transactions {
        ...TrustTransaction
      }
      summary {
        ...ReconciliationSummary
      }
    }
  }
  ${TRUST_TRANSACTION_FRAGMENT}
  ${RECONCILIATION_SUMMARY_FRAGMENT}
`;
