import round from 'lodash/round';
import { dateManager } from '@appclose/core';

export function getTimerDuration(timer: number) {
  const secondsInHour = dateManager().getSeconds('hour');
  const secondsInMinute = dateManager().getSeconds('minute');
  const hours = Math.floor(timer / secondsInHour);
  const minutes = Math.floor(timer / secondsInMinute - hours * secondsInMinute);
  const seconds = Math.floor(
    timer - minutes * secondsInMinute - hours * secondsInHour
  );

  return { hours, minutes, seconds };
}

export function normalizeTime(time: number) {
  return time.toString(10).padStart(2, '0');
}

export function formatTimer(timer: number, withSeconds = true) {
  const { hours, minutes, seconds } = getTimerDuration(timer);

  return `${normalizeTime(hours)}:${normalizeTime(minutes)}${
    withSeconds ? `:${normalizeTime(seconds)}` : ''
  }`;
}

export function calcTotalByRate(hourlyRate: number, seconds: number) {
  return round((hourlyRate / dateManager().getSeconds('hour')) * seconds, 2);
}
