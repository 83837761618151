import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { downloadFileByBase64 } from '@appclose/lib';
import { traceError } from '@appclose/core';

import { useDepositActionsPropsType } from './useDepositActions.types';
import {
  FetchDepositPdfQuery,
  FetchDepositPdfQueryVariables,
} from './__generated__/useDepositActions.gql';
import { FETCH_DEPOSIT_PDF } from './useDepositActions.gql';

export default function useDepositActions({ id }: useDepositActionsPropsType) {
  const [
    fetchDepositPdf,
    { loading: downloadDepositPdfLoading },
  ] = useLazyQuery<FetchDepositPdfQuery, FetchDepositPdfQueryVariables>(
    FETCH_DEPOSIT_PDF,
    {
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
      onCompleted({ pdf: { data, mimetype, name } }) {
        downloadFileByBase64(data, mimetype, name);
      },
    }
  );

  const downloadDepositPdf = useCallback(async () => {
    try {
      await fetchDepositPdf();
    } catch (e) {
      traceError(e);
    }
  }, [fetchDepositPdf]);

  return {
    downloadDepositPdf,
    downloadDepositPdfLoading,
  };
}
