import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { isDemoBuild } from 'controllers/demo';
import { openDemoUnavailableConfirmAction } from 'store/modules/common/actions';

export default function useDemo() {
  const dispatch = useDispatch();
  const isDemo = isDemoBuild();

  const onOpenDemoUnavailableConfirmOrUserCallback = useCallback(
    (confirmTitle: string, userCallback: () => void) => {
      if (isDemo) {
        return dispatch(openDemoUnavailableConfirmAction(confirmTitle));
      }

      return userCallback();
    },
    [dispatch, isDemo]
  );

  return {
    isDemo,
    onOpenDemoUnavailableConfirmOrUserCallback,
  };
}
