import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { downloadFileByUrl } from '@appclose/lib';
import { usePopupModal, traceError } from '@appclose/core';

import AttachBankStatementModal from 'components/modals/popups/AttachBankStatementModal';
import { openDeleteReconciliationConfirmAction } from 'store/actions';

import {
  FETCH_RECONCILIATION_3RD_WAY_PDF,
  FETCH_RECONCILIATION_DETAILED_PDF,
} from './useReconciliationActions.gql';
import {
  GetReconciliation3rdWayPdfQuery,
  GetReconciliation3rdWayPdfQueryVariables,
  GetReconciliationDetailedPdfQuery,
  GetReconciliationDetailedPdfQueryVariables,
} from './__generated__/useReconciliationActions.gql';
import { useReconciliationActionsPropsType } from './useReconciliationActions.types';

export default function useReconciliationActions({
  id,
}: useReconciliationActionsPropsType) {
  const dispatch = useDispatch();
  const [
    fetchReconciliationDetailedPdf,
    { loading: loadingReconciliationDetailedPdf },
  ] = useLazyQuery<
    GetReconciliationDetailedPdfQuery,
    GetReconciliationDetailedPdfQueryVariables
  >(FETCH_RECONCILIATION_DETAILED_PDF, {
    fetchPolicy: 'network-only',
    onCompleted({ pdf: { url } }) {
      downloadFileByUrl(url);
    },
  });
  const [
    fetchReconciliation3rdWayPdf,
    { loading: loadingReconciliation3rdWayPdf },
  ] = useLazyQuery<
    GetReconciliation3rdWayPdfQuery,
    GetReconciliation3rdWayPdfQueryVariables
  >(FETCH_RECONCILIATION_3RD_WAY_PDF, {
    fetchPolicy: 'network-only',
    onCompleted({ pdf: { url } }) {
      downloadFileByUrl(url);
    },
  });
  const pdfLoading =
    loadingReconciliationDetailedPdf || loadingReconciliation3rdWayPdf;

  const onDownloadReconciliationDetailedPdf = useCallback(async () => {
    try {
      await fetchReconciliationDetailedPdf({
        variables: { id },
      });
    } catch (e) {
      traceError(e);
    }
  }, [fetchReconciliationDetailedPdf, id]);
  const onDownloadReconciliation3rdWayPdf = useCallback(async () => {
    try {
      await fetchReconciliation3rdWayPdf({
        variables: { id },
      });
    } catch (e) {
      traceError(e);
    }
  }, [fetchReconciliation3rdWayPdf, id]);
  const onReconciliationDelete = useCallback(
    () => dispatch(openDeleteReconciliationConfirmAction({ id })),
    [dispatch, id]
  );
  const { openPopupModal: openAttachStatementModal } = usePopupModal(
    AttachBankStatementModal
  );

  return {
    pdfLoading,
    onDownloadReconciliationDetailedPdf,
    onDownloadReconciliation3rdWayPdf,
    onReconciliationDelete,
    openAttachStatementModal,
  };
}
