import React, { ChangeEvent, useMemo } from 'react';
import { Checkbox, TableCell, TableHead, TableRow } from '@appclose/ui';
import { useFormContext, useIsMobileDevice } from '@appclose/core';

import { I18n } from 'i18n';

import { InvoiceFormValuesType } from '../../../../../../InvoiceForm.types';
import { TimeEntriesFieldPropsType } from './TimeEntriesFieldTableHeader.types';

export default function TimeEntriesFieldTableHeader({
  timeEntries,
}: TimeEntriesFieldPropsType) {
  const isMobile = useIsMobileDevice();
  const { values, setFieldValue } = useFormContext<InvoiceFormValuesType>();

  const selectedAll = useMemo(
    () =>
      (timeEntries?.length &&
        timeEntries.every(({ id }) =>
          values.timeEntries?.some(({ id: valuesId }) => valuesId === id)
        )) ||
      false,
    [timeEntries, values.timeEntries]
  );

  const onSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('timeEntries', e.target.checked ? timeEntries : [], true);
  };

  return (
    <TableHead theme="medium">
      <TableRow>
        <TableCell>
          <Checkbox checked={selectedAll} onChange={onSelectAll} />
        </TableCell>
        {!isMobile && <TableCell />}
        <TableCell>
          <I18n id="modal.invoice.form.timeEntries.table.timeEntry" />
        </TableCell>
        {!isMobile && (
          <TableCell>
            <I18n id="modal.invoice.form.timeEntries.table.matter" />
          </TableCell>
        )}
        <TableCell>
          <I18n id="modal.invoice.form.timeEntries.table.billedBy" />
        </TableCell>
        {!isMobile && (
          <TableCell>
            <I18n id="modal.invoice.form.timeEntries.table.status" />
          </TableCell>
        )}
        <TableCell align="right">
          <I18n id="modal.invoice.form.timeEntries.table.amount" />
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
