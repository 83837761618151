import React, { useCallback, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import {
  Button,
  ButtonGroup,
  List,
  ListItem,
  Offset,
  Text3,
  Text4,
  Note,
} from '@appclose/ui';
import { Loader } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { getSavedModalData, openModal } from 'controllers/modal';
import {
  PRINT_CHECKS_SAMPLE_PREVIEW_MODAL,
  PRINT_DEPOSITS_SAMPLE_PREVIEW_MODAL,
} from 'constants/modals';
import usePrintChecks from 'hooks/usePrintChecks';
import usePrintDepositSlip from 'hooks/usePrintDepositSlip';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { I18n } from 'i18n';

import {
  FineTuneAlignmentModalPropsType,
  FineTuneAlignmentSavedModalDataType,
} from './FineTuneAlignmentModal.type';
import FineTuneAlignment, {
  FineTuneAlignmentOffsetType,
  FineTuneAlignmentTypes,
} from './components/FineTuneAlignment';

export default function FineTuneAlignmentModal({
  type,
  mode,
  onClose,
}: FineTuneAlignmentModalPropsType) {
  const {
    loadingPrintChecksSettings,
    printChecksOffset,
    savePrintingChecksSettings,
    savePrintingChecksSettingsLoading,
  } = usePrintChecks({ skipLoading: type !== FineTuneAlignmentTypes.CHECK });
  const {
    loadingPrintDepositSlipSettings,
    printDepositSlipOffset,
    savePrintingDepositSlipSettings,
    savePrintingDepositSlipSettingsLoading,
  } = usePrintDepositSlip({
    skipLoading: type !== FineTuneAlignmentTypes.DEPOSIT_SLIP,
  });
  const loading = loadingPrintChecksSettings || loadingPrintDepositSlipSettings;
  const saveLoading =
    savePrintingChecksSettingsLoading || savePrintingDepositSlipSettingsLoading;
  const { offset: savedOffset } =
    getSavedModalData<FineTuneAlignmentSavedModalDataType>() || {};
  const [offset, setOffset] = useState<FineTuneAlignmentOffsetType>([0, 0]);

  const { onConfirmClose, setShowConfirm } = useCloseConfirm({
    onClose,
  });

  useEffect(() => {
    if (!loading) {
      const initialOffsets = savedOffset ||
        printChecksOffset ||
        printDepositSlipOffset || [0, 0];

      setShowConfirm(!isEqual(initialOffsets, printDepositSlipOffset));

      setOffset(
        savedOffset || printChecksOffset || printDepositSlipOffset || [0, 0]
      );
    }
  }, [
    loading,
    savedOffset,
    type,
    printChecksOffset,
    printDepositSlipOffset,
    setShowConfirm,
  ]);

  const handleOnChangeOffset = useCallback(
    (nextOffset: FineTuneAlignmentOffsetType) => {
      setShowConfirm(!isEqual(printDepositSlipOffset, nextOffset));
      setOffset(nextOffset);
    },
    [printDepositSlipOffset, setShowConfirm]
  );

  const handleOnSave = useCallback(async () => {
    if (type === FineTuneAlignmentTypes.CHECK) {
      await savePrintingChecksSettings({
        offset,
      });
    }

    if (type === FineTuneAlignmentTypes.DEPOSIT_SLIP) {
      await savePrintingDepositSlipSettings({
        offset,
      });
    }

    onClose();
  }, [
    type,
    offset,
    savePrintingChecksSettings,
    savePrintingDepositSlipSettings,
    onClose,
  ]);

  const handleOnPrintSample = useCallback(() => {
    if (type === FineTuneAlignmentTypes.CHECK) {
      openModal(
        PRINT_CHECKS_SAMPLE_PREVIEW_MODAL,
        { mode, offset },
        { offset }
      );
    }

    if (type === FineTuneAlignmentTypes.DEPOSIT_SLIP) {
      openModal(PRINT_DEPOSITS_SAMPLE_PREVIEW_MODAL, { offset }, { offset });
    }
  }, [type, mode, offset]);

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.fineTuneAlignment.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Offset bottom="xl">
              <List ordered>
                <ListItem>
                  <Text3 offset={{ bottom: 20 }}>
                    <I18n id="modal.fineTuneAlignment.note.drag" />
                  </Text3>
                  <Text4 color="secondary" offset={{ bottom: 20 }}>
                    <I18n id="modal.fineTuneAlignment.note.numbers" />
                  </Text4>
                  <Offset bottom="s">
                    <FineTuneAlignment
                      type={type}
                      offset={offset}
                      onChange={handleOnChangeOffset}
                    />
                  </Offset>
                </ListItem>
                <ListItem>
                  <Text3 offset={{ bottom: 20 }}>
                    <I18n id="modal.fineTuneAlignment.note.alignment" />
                  </Text3>
                  <Button onClick={handleOnPrintSample}>
                    <I18n id="modal.fineTuneAlignment.print" />
                  </Button>
                </ListItem>
              </List>
            </Offset>
            <Offset bottom="xxl">
              <Note>
                <I18n id="modal.fineTuneAlignment.note.save" />
              </Note>
            </Offset>
            <ButtonGroup>
              <Button onClick={onConfirmClose}>
                <I18n id="modal.fineTuneAlignment.cancel" />
              </Button>
              <Button
                skin="primary"
                loading={saveLoading}
                onClick={handleOnSave}
              >
                <I18n id="modal.fineTuneAlignment.save" />
              </Button>
            </ButtonGroup>
          </>
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
