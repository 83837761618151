import React from 'react';
import { Button, ButtonGroup } from '@appclose/ui';
import {
  AmountFormField,
  Form,
  FormGroup,
  StaticField,
  TextAreaFormField,
  Fieldset,
} from '@appclose/core';

import ContactMatterSelectFormField from 'components/fields/ContactMatterSelectFormField';
import ContactSelectFormField from 'components/fields/ContactSelectFormField';
import ContactMatter from 'components/common/ContactMatter';
import { sanitizeAmount } from 'controllers/amount';
import { I18n, useIntl } from 'i18n';
import FilesFormField from 'components/fields/FilesFormField';
import { Entities } from 'constants/entities';

import { TransferFundsFormPropsType } from './TransferFundsForm.types';
import { TransferFundsFormSchema } from './TransferFundsForm.schema';

export default function TransferFundsForm({
  initialValues,
  onSubmit,
  onCancel,
  onChange,
}: TransferFundsFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      validationSchema={TransferFundsFormSchema}
      onSubmit={onSubmit}
      onChange={onChange}
    >
      {({
        values: {
          contact,
          fromContact,
          fromMatter,
          toContact,
          toMatter,
          amount,
        },
        setValues,
        isSubmitting,
      }) => {
        return (
          <>
            <Fieldset title={t('modal.transferFunds.form.fieldset.info.title')}>
              <FormGroup>
                <ContactSelectFormField
                  name="contact"
                  tooltip={t(
                    'modal.transferFunds.form.fieldset.info.contact.tooltip'
                  )}
                  onChange={() => {
                    setValues((values) => ({
                      ...values,
                      fromContact: undefined,
                      fromMatter: undefined,
                      toContact: undefined,
                      toMatter: undefined,
                    }));
                  }}
                />
              </FormGroup>
              <FormGroup>
                <ContactMatterSelectFormField
                  label={t('modal.transferFunds.form.fieldset.info.from')}
                  contactFieldName="fromContact"
                  matterFieldName="fromMatter"
                  includeContactIds={contact?.id ? [contact.id] : undefined}
                  disabled={!contact}
                  onlyPositiveTrustBalance
                  showTrustLedgerBalance
                  excludeContactIds={
                    !toMatter ? toContact && [toContact.id] : undefined
                  }
                  excludeMatterIds={toMatter && [toMatter.id]}
                  onChange={(value) => {
                    if (!value) {
                      setValues((values) => ({
                        ...values,
                        toContact: undefined,
                        toMatter: undefined,
                      }));
                    }
                  }}
                />
              </FormGroup>
              <FormGroup>
                <ContactMatterSelectFormField
                  label={t('modal.transferFunds.form.fieldset.info.to')}
                  contactFieldName="toContact"
                  matterFieldName="toMatter"
                  includeContactIds={contact?.id ? [contact.id] : undefined}
                  disabled={!contact || !fromContact}
                  onlyInitializedTrustLedger
                  showTrustLedgerBalance
                  excludeContactIds={
                    !fromMatter ? fromContact && [fromContact.id] : undefined
                  }
                  excludeMatterIds={fromMatter && [fromMatter.id]}
                />
              </FormGroup>
            </Fieldset>
            <Fieldset title={t('modal.transferFunds.form.fieldset.amount')}>
              <FormGroup>
                <AmountFormField
                  name="amount"
                  label={t('modal.transferFunds.form.fieldset.amount')}
                  disabled={!contact || !fromContact || !toContact}
                />
              </FormGroup>
              {toContact && (
                <StaticField
                  title={t('modal.transferFunds.form.field.subtotals')}
                >
                  <ContactMatter
                    contact={{ name: toContact.name }}
                    matter={toMatter}
                    trustBalance={sanitizeAmount(
                      (toMatter
                        ? sanitizeAmount(toMatter?.trustBalance)
                        : sanitizeAmount(toContact?.trustBalance)) + amount
                    )}
                  />
                </StaticField>
              )}
            </Fieldset>
            <Fieldset
              title={t(
                'modal.transferFunds.form.fieldset.additionalInfo.title'
              )}
            >
              <FormGroup>
                <TextAreaFormField
                  name="description"
                  label={t(
                    'modal.transferFunds.form.fieldset.additionalInfo.description'
                  )}
                />
              </FormGroup>
              <FilesFormField
                entityType={Entities.TRUST_TRANSFER}
                tooltip={t(
                  'modal.transferFunds.form.fieldset.additionalInfo.attachment.tooltip'
                )}
              />
            </Fieldset>
            <ButtonGroup>
              <Button onClick={onCancel}>
                <I18n id="modal.deposit.form.cancel" />
              </Button>
              <Button skin="primary" type="submit" loading={isSubmitting}>
                <I18n id="modal.transferFunds.form.submit" />
              </Button>
            </ButtonGroup>
          </>
        );
      }}
    </Form>
  );
}
