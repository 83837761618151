import { gql } from '@apollo/client';

export const FETCH_DEPOSIT_SLIP_SAMPLE_PDF = gql`
  query FetchDepositSliSamplepPdf($input: PrintingOffsetInput) {
    sample: getSampleDepositSlipPDF(printingOffsetInput: $input) {
      data
      mimetype
      name
    }
  }
`;
