import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { setTracingUser } from '@appclose/core';

import useFirm from 'hooks/useFirm';

import { FETCH_PROFILE } from './useProfile.gql';
import {
  FetchProfileQuery,
  FetchProfileQueryVariables,
} from './__generated__/useProfile.gql';
import { AccessLevels } from '__generated__/globalTypes';

export default function useProfile() {
  const { loading, data } = useQuery<
    FetchProfileQuery,
    FetchProfileQueryVariables
  >(FETCH_PROFILE);

  const profile = useMemo(() => data?.profile, [data]);
  const { data: firmData } = useFirm();

  const hasLimitedAccess = !firmData.stripeKybStatus;

  const isUserHasLimitedAccessRights =
    !profile?.accessLevel ||
    [AccessLevels.LIMITED_USER, AccessLevels.READ_ONLY_USER].includes(
      profile?.accessLevel
    );
  const isLimitedUser = profile?.accessLevel === AccessLevels.LIMITED_USER;

  useEffect(() => {
    if (profile?.id) {
      setTracingUser(profile.id, {
        onboardStatus: profile.onboardStatus,
      });
    }
  }, [profile?.id, profile?.onboardStatus]);

  return {
    loading,
    profile,
    hasLimitedAccess,
    isLimitedUser,
    isOwner: profile?.isOwner,
    isUserHasLimitedAccessRights,
  };
}
