import React, { useEffect } from 'react';
import set from 'lodash/set';
import { Grid } from '@appclose/ui';
import {
  Hint,
  DatePickerFormField,
  AmountFormField,
  dateManager,
  useFormContext,
  Fieldset,
} from '@appclose/core';

import { SERVER_DATE_FORMAT } from 'constants/date';
import { I18n, useIntl } from 'i18n';

import { ReconciliationFormValuesType } from '../../ReconciliationForm.types';
import styles from './ReconciliationInfoFieldset.module.scss';

export default function ReconciliationInfoFieldset() {
  const { t } = useIntl();
  const {
    setValues,
    values: { lastStatementDate, statementDate },
  } = useFormContext<ReconciliationFormValuesType>();

  useEffect(() => {
    setValues((values) =>
      set(
        values,
        'transactions',
        statementDate
          ? values.transactions.filter(({ date }) =>
              dateManager()
                .parse(date)
                .isBefore(dateManager().parse(statementDate).endOf('day'))
            )
          : []
      )
    );
  }, [setValues, statementDate]);

  return (
    <Fieldset
      title={
        <>
          <I18n id="modal.reconciliation.form.info.title" />
          <Hint
            iconClassName={styles.hint}
            content={<I18n id="modal.reconciliation.form.info.hint" />}
          />
        </>
      }
    >
      <Grid columns="auto auto" gap={[20]}>
        <DatePickerFormField
          name="statementDate"
          label={t('modal.reconciliation.form.info.statementDate')}
          outputDateFormat={SERVER_DATE_FORMAT}
          minDate={lastStatementDate}
          maxDate={dateManager().parse().endOf('day').valueOf()}
        />
        <AmountFormField
          name="statementBalance"
          label={t('modal.reconciliation.form.info.statementBalance')}
        />
      </Grid>
    </Fieldset>
  );
}
