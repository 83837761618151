import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery, useSubscription } from '@apollo/client';
import { openConfirmAction, permissionProvider, usePopupModal } from '@appclose/core';
import { useIntl } from 'i18n';

import { BankAccountClasses, StripeBankAccountStatus } from '__generated__/globalTypes';

import { BANK_ACCOUNT_MODAL } from 'constants/modals';
import { PermissionActions, PermissionResources } from 'constants/permissions';

import { openModal } from 'controllers/modal';
import { isDemoBuild } from 'controllers/demo';
import { openDemoUnavailableConfirmAction } from 'store/modules/common/actions';
import useBankAccountClasses from 'hooks/useBankAccountClasses';

import ConnectFeeBankAccountModal from 'components/modals/popups/ConnectFeeBankAccountModal';

import {
  BankAccountCreatedSubscription,
  BankAccountCreatedSubscriptionVariables,
  BankAccountDeletedSubscription,
  BankAccountDeletedSubscriptionVariables,
  BankAccountUpdatedSubscription,
  BankAccountUpdatedSubscriptionVariables,
  FetchBankAccountsQuery,
  FetchBankAccountsQueryVariables,
} from './__generated__/useBankAccounts.gql';
import {
  FETCH_BANK_ACCOUNTS,
  ON_BANK_ACCOUNT_CREATED,
  ON_BANK_ACCOUNT_DELETED,
  ON_BANK_ACCOUNT_UPDATED,
} from './useBankAccounts.gql';
import { BankAccountsType } from './useBankAccounts.types';

export default function useBankAccounts(): BankAccountsType {
  const { t } = useIntl();
  const dispatch = useDispatch();
  const { getBankAccountClassLabelWithAccount } = useBankAccountClasses();

  const hasListPermissions = permissionProvider().hasPermission(
    PermissionResources.BANK_ACCOUNT,
    PermissionActions.LIST
  );
  const hasCreatePermissions = permissionProvider().hasPermission(
    PermissionResources.BANK_ACCOUNT,
    PermissionActions.CREATE
  );

  const { loading, data, refetch } = useQuery<
    FetchBankAccountsQuery,
    FetchBankAccountsQueryVariables
  >(FETCH_BANK_ACCOUNTS, {
    skip: !hasListPermissions,
  });

  useSubscription<
    BankAccountUpdatedSubscription,
    BankAccountUpdatedSubscriptionVariables
  >(ON_BANK_ACCOUNT_UPDATED, {
    onSubscriptionData: () => refetch(),
    skip: !hasListPermissions,
  });

  useSubscription<
    BankAccountCreatedSubscription,
    BankAccountCreatedSubscriptionVariables
  >(ON_BANK_ACCOUNT_CREATED, {
    onSubscriptionData: () => refetch(),
    skip: !hasListPermissions,
  });

  useSubscription<
    BankAccountDeletedSubscription,
    BankAccountDeletedSubscriptionVariables
  >(ON_BANK_ACCOUNT_DELETED, {
    onSubscriptionData: () => refetch(),
    skip: !hasListPermissions,
  });

  const bankAccounts = data?.bankAccounts;

  const operatingAccount = useMemo(
    () => bankAccounts?.find((acc) => acc.class === BankAccountClasses.OPERATING),
    [bankAccounts]
  );
  const trustAccount = useMemo(
    () => bankAccounts?.find((acc) => acc.class === BankAccountClasses.TRUST),
    [bankAccounts]
  );

  const { openPopupModal: openFeeAccountConnectPopupModal } = usePopupModal(ConnectFeeBankAccountModal, refetch);

  const onAccountConnect = useCallback(
    (accountClass: BankAccountClasses) => {
      if (isDemoBuild()) {
        return dispatch(
          openDemoUnavailableConfirmAction(
            getBankAccountClassLabelWithAccount(accountClass)
          )
        );
      }

      openModal(BANK_ACCOUNT_MODAL, { accountClass });
    },
    [dispatch, getBankAccountClassLabelWithAccount]
  );

  const onTrustAccountConnect = useCallback(
    () => onAccountConnect(BankAccountClasses.TRUST),
    [onAccountConnect]
  );
  const onOperatingAccountConnect = useCallback(
    () => onAccountConnect(BankAccountClasses.OPERATING),
    [onAccountConnect]
  );
  const onFeeAccountConnect = useCallback(
    () => {
      if (isDemoBuild()) {
        return dispatch(openDemoUnavailableConfirmAction(
          t('bankAccounts.confirm.connectFee.name'),
        ));
      }
  
      openFeeAccountConnectPopupModal();
    },
    [t, dispatch, openFeeAccountConnectPopupModal]
  );

  const showTrustConnectSuggestionIfNeeded = useCallback(() => {
    if (!trustAccount) {
      dispatch(openConfirmAction({
        name: t('bankAccounts.confirm.connectTrust.name'),
        content: t('bankAccounts.confirm.connectTrust.content'),
        cancelButtonTitle: t('bankAccounts.confirm.button.cancel'),
        okButtonTitle: t('bankAccounts.confirm.connectTrust.button.connect'),
        okButtonSkin: 'primary',
        onConfirm: () => openModal(BANK_ACCOUNT_MODAL, { accountClass: BankAccountClasses.TRUST }),
      }));
    }
  
    if (trustAccount && trustAccount.stripeBankAccountStatus === StripeBankAccountStatus.MIGRATION_REQUIRED) {
      dispatch(openConfirmAction({
        name: t('bankAccounts.confirm.reconnectTrust.name'),
        content: t('bankAccounts.confirm.reconnectTrust.content'),
        cancelButtonTitle: t('bankAccounts.confirm.button.cancel'),
        okButtonTitle: t('bankAccounts.confirm.reconnectTrust.button.connect'),
        okButtonSkin: 'primary',
        onConfirm: () => openModal(BANK_ACCOUNT_MODAL, { accountClass: BankAccountClasses.TRUST }),
      }));
    }
  }, [trustAccount, dispatch, t]);

  return {
    loading,
    bankAccounts,
    operatingAccount,
    trustAccount,
    refetchBankAccounts: refetch,
    showTrustConnectSuggestionIfNeeded,
    onTrustAccountConnect: hasCreatePermissions
      ? onTrustAccountConnect
      : undefined,
    onOperatingAccountConnect: hasCreatePermissions
      ? onOperatingAccountConnect
      : undefined,
    onFeeAccountConnect,
  };
}
