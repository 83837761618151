import {
  LeadContactInput,
  LeadContactTypes,
  LeadSources,
  LeadStatusColors,
} from '__generated__/globalTypes';

const INITIAL_ENTITY_BILLING_CONTACT: LeadContactInput = {
  firstName: '',
  lastName: '',
  middleName: '',
  email: '',
  type: LeadContactTypes.PRIMARY,
};

export const INITIAL_INDIVIDUAL_LEAD = {
  firstName: '',
  lastName: '',
  email: '',
  notes: '',
  isImportant: false,
  leadSource: LeadSources.NONE,
};

export const INITIAL_ENTITY_LEAD = {
  website: '',
  entityName: '',
  contacts: [INITIAL_ENTITY_BILLING_CONTACT],
  isImportant: false,
  notes: '',
  leadSource: LeadSources.NONE,
};

export enum LeadTypes {
  INDIVIDUAL = 'INDIVIDUAL',
  ENTITY = 'ENTITY',
}

export const LEAD_STATUS_COLORS = {
  [LeadStatusColors.COLOR_1]: '#989fa3',
  [LeadStatusColors.COLOR_2]: '#ffa132',
  [LeadStatusColors.COLOR_3]: '#7557ed',
  [LeadStatusColors.COLOR_4]: '#33ad69',
  [LeadStatusColors.COLOR_5]: '#e47af5',
  [LeadStatusColors.COLOR_6]: '#2775eb',
  [LeadStatusColors.COLOR_7]: '#fddc03',
  [LeadStatusColors.COLOR_8]: '#20c2d2',
  [LeadStatusColors.COLOR_9]: '#a4e750',
  [LeadStatusColors.COLOR_10]: '#ff5353',
};

export const LEAD_STATUS_COLORS_OPAQUE = {
  [LeadStatusColors.COLOR_1]: '#989fa333',
  [LeadStatusColors.COLOR_2]: '#ffa13233',
  [LeadStatusColors.COLOR_3]: '#7557ed33',
  [LeadStatusColors.COLOR_4]: '#33ad6933',
  [LeadStatusColors.COLOR_5]: '#e47af533',
  [LeadStatusColors.COLOR_6]: '#2775eb33',
  [LeadStatusColors.COLOR_7]: '#fddc0333',
  [LeadStatusColors.COLOR_8]: '#20c2d233',
  [LeadStatusColors.COLOR_9]: '#a4e75033',
  [LeadStatusColors.COLOR_10]: '#ff535333',
};
