import { gql } from '@apollo/client';

const FIRM_PRINTING_DEPOSIT_SLIP_SETTINGS_FRAGMENT = gql`
  fragment FirmPrintingDepositSlipSettingsFragment on FirmType {
    id
    printingDepositSlipSettings {
      offset
    }
  }
`;

export const FETCH_FIRM_PRINTING_DEPOSIT_SLIP_SETTINGS = gql`
  query FetchFirmPrintingDepositSlipSettings {
    firm: getFirm {
      ...FirmPrintingDepositSlipSettingsFragment
    }
  }
  ${FIRM_PRINTING_DEPOSIT_SLIP_SETTINGS_FRAGMENT}
`;

export const UPDATE_FIRM_PRINTING_DEPOSIT_SLIP_SETTINGS = gql`
  mutation UpdateFirmPrintingDepositSlipSettings(
    $input: SavePrintingDepositSlipSettingsInput!
  ) {
    updatePrintingDepositSlipSettings(
      savePrintingDepositSlipSettingsInput: $input
    ) {
      ...FirmPrintingDepositSlipSettingsFragment
    }
  }
  ${FIRM_PRINTING_DEPOSIT_SLIP_SETTINGS_FRAGMENT}
`;
