import { gql } from '@apollo/client';
import { AVATAR_FRAGMENT } from 'components/common/Avatar';
import { CONTACT_FRAGMENT } from './contact.gql';

const LEAD_FRAGMENT = gql`
  fragment Lead on LeadType {
    id
    isEntity
    statusId
    leadSource
    isImportant
    updatedAt
    archiveReason
    notes
    linkedContact {
      id
      name
      firstName
      lastName
      isEntity
      createdAt
    }
    originatingAttorney {
      firstName
      middleName
      lastName
      id
      picture {
        ...Avatar
      }
    }
    individual {
      email
      firstName
      lastName
      middleName
    }
    entity {
      entityName
      website
      contacts {
        firstName
        lastName
        middleName
        email
        type
      }
    }
    phoneNumbers {
      number
      type
      isPrimary
    }
  }
  ${AVATAR_FRAGMENT}
`;

export const FETCH_LEAD = gql`
  query FetchLead($id: ID!) {
    lead: getLead(id: $id) {
      ...Lead
    }
  }
  ${LEAD_FRAGMENT}
`;

export const FETCH_LEADS = gql`
  query FetchLeads(
    $skip: Int
    $take: Int
    $filter: FilterLeadsInput
    $order: OrderLeadsInput
  ) {
    listLeads(
      listLeadsInput: {
        skip: $skip
        take: $take
        filter: $filter
        order: $order
      }
    ) {
      total
      items {
        ...Lead
      }
    }
  }
  ${LEAD_FRAGMENT}
`;

export const FETCH_LEADS_ARCHIVE = gql`
  query FetchLeadsArchive(
    $skip: Int
    $take: Int
    $filter: FilterLeadsArchiveInput
    $order: OrderLeadsArchiveInput
  ) {
    listLeadsArchive(
      listLeadsArchiveInput: {
        skip: $skip
        take: $take
        filter: $filter
        order: $order
      }
    ) {
      total
      items {
        ...Lead
      }
    }
  }
  ${LEAD_FRAGMENT}
`;

export const FETCH_LEADS_COUNT = gql`
  query FetchLeadsCount($filter: FilterLeadsInput) {
    listLeads(listLeadsInput: { filter: $filter }) {
      total
    }
  }
`;

export const FETCH_LEAD_STATUSES = gql`
  query FetchLeadStatuses {
    listLeadStatuses {
      id
      title
      isInitialStatus
      isDisplayable
      isSystem
      order
      color
      role
    }
  }
`;

export const UPDATE_LEAD_STATUS = gql`
  mutation UpdateLeadStatus($input: UpdateLeadStatusInput!, $id: ID!) {
    updateLeadStatus(updateLeadStatusInput: $input, id: $id) {
      id
      title
      order
      color
      isDisplayable
      isInitialStatus
      isSystem
      role
    }
  }
`;

export const UPDATE_LEADS_STATUS = gql`
  mutation UpdateLeadsStatus($input: UpdateLeadsStatusInput!) {
    updateLeadsStatus(updateLeadsStatusInput: $input) {
      leadIds
      statusId
      contactCreated
      linkedContact {
        ...Contact
      }
    }
  }
  ${CONTACT_FRAGMENT}
`;

export const ON_LEAD_CREATED = gql`
  subscription leadCreated($input: LeadSubscriptionInput!) {
    leadCreated(leadSubscriptionInput: $input) {
      ...Lead
    }
  }
  ${LEAD_FRAGMENT}
`;

export const ON_LEAD_UPDATED = gql`
  subscription leadUpdated($input: LeadSubscriptionInput!) {
    leadUpdated(leadSubscriptionInput: $input) {
      ...Lead
    }
  }
  ${LEAD_FRAGMENT}
`;

export const ON_LEAD_STATUS_CREATED = gql`
  subscription leadStatusCreated {
    leadStatusCreated {
      id
      title
      order
      isSystem
      isInitialStatus
      isDisplayable
      color
      role
    }
  }
`;

export const ON_LEAD_STATUS_UPDATED = gql`
  subscription leadStatusUpdated {
    leadStatusUpdated {
      id
      title
      order
      isSystem
      isInitialStatus
      isDisplayable
      color
      role
    }
  }
`;

export const FETCH_ARCHIVE_REASONS = gql`
  query FetchArchiveReasons($skip: Int, $take: Int, $query: String!) {
    listArchiveReasons(
      listLeadArchiveReasonsInput: { skip: $skip, take: $take, query: $query }
    ) {
      total
      items {
        name
      }
    }
  }
`;

export const FETCH_PREDEFINED_REASONS = gql`
  query FetchPredefinedReasons {
    getPredefinedReasons {
      name
    }
  }
`;

export const DELETE_INBOX_RECORD = gql`
  mutation DeleteInboxRecord($id: ID!) {
    deleteInboxRecord(id: $id) {
      success
    }
  }
`;

export const ON_LEAD_STATUS_DELETED = gql`
  subscription leadStatusDeleted {
    leadStatusDeleted {
      id
    }
  }
`;

export const FETCH_LEADS_INBOX_UNSEEN = gql`
  query FetchLeadsInboxUnseen {
    getNotViewedInboxRecordsCount
  }
`;

export const ON_INBOX_UNSEEN_UPDATED = gql`
  subscription InboxUnseenUpdated {
    notViewedCounterUpdated {
      notViewedCount
    }
  }
`;
