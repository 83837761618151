import React, { useCallback } from 'react';
import omit from 'lodash/omit';
import { useMutation, useQuery } from '@apollo/client';
import { Loader, Modal } from '@appclose/core';

import { useIntl } from 'i18n';
import notification from 'controllers/notification';
import { track } from 'controllers/analytics';
import { EntryTypes, EventNames, SourceTypes } from 'constants/analytics';

import {
  CreateExpenseCategoryMutation,
  CreateExpenseCategoryMutationVariables,
  EditExpenseCategoryMutation,
  EditExpenseCategoryMutationVariables,
  FetchExpenseCategoryQuery,
  FetchExpenseCategoryQueryVariables,
} from './__generated__/ExpenseCategoryModal.gql';
import ExpenseCategoryForm, {
  ExpenseCategoryFormValuesType,
} from './components/ExpenseCategoryForm';
import {
  CREATE_EXPENSE_CATEGORY,
  FETCH_EXPENSE_CATEGORY,
  EDIT_EXPENSE_CATEGORY,
} from './ExpenseCategoryModal.gql';
import { ExpenseCategoryModalPropsType } from './ExpenseCategoryModal.types';

export default function ExpenseCategoryModal({
  onCancel,
  onComplete,
  withNote = true,
  costType,
  id,
}: ExpenseCategoryModalPropsType) {
  const { t } = useIntl();
  const isEdit = !!id;
  const [createExpenseCategory] = useMutation<
    CreateExpenseCategoryMutation,
    CreateExpenseCategoryMutationVariables
  >(CREATE_EXPENSE_CATEGORY);
  const [editExpenseCategory] = useMutation<
    EditExpenseCategoryMutation,
    EditExpenseCategoryMutationVariables
  >(EDIT_EXPENSE_CATEGORY);
  const { data, loading } = useQuery<
    FetchExpenseCategoryQuery,
    FetchExpenseCategoryQueryVariables
  >(FETCH_EXPENSE_CATEGORY, {
    fetchPolicy: 'network-only',
    variables: { id: id as string },
    skip: !isEdit,
  });

  const handleOnCancel = useCallback(() => {
    track(EventNames.CANCEL_ENTRY, {
      entry_type: EntryTypes.EXPENSE_CATEGORY,
      source_type: SourceTypes.POPUP_MODAL,
    });

    onCancel();
  }, [onCancel]);

  const handleOnSubmit = useCallback(
    async (values: ExpenseCategoryFormValuesType) => {
      if (isEdit) {
        const result = await editExpenseCategory({
          variables: {
            input: {
              id: id as string,
              ...values,
            },
          },
        });

        result.data?.category && onComplete(result.data.category);

        notification().entityCreated(
          t('modal.popup.expenseCategory.notification.update.success')
        );
      } else {
        const result = await createExpenseCategory({
          variables: { input: values },
        });

        result.data?.category && onComplete(result.data.category);

        notification().entityCreated(
          t('modal.popup.expenseCategory.notification.create.success')
        );
      }
    },
    [createExpenseCategory, editExpenseCategory, id, isEdit, onComplete, t]
  );

  const initialValues = {
    costType,
    ...omit(data?.category || {}, ['id']),
  } as ExpenseCategoryFormValuesType;

  return (
    <Modal
      onClose={handleOnCancel}
      title={t(
        isEdit
          ? 'modal.popup.expenseCategory.title.edit'
          : 'modal.popup.expenseCategory.title.new'
      )}
    >
      {loading ? (
        <Loader />
      ) : (
        <ExpenseCategoryForm
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          onCancel={handleOnCancel}
          withNote={withNote}
          isEdit={isEdit}
        />
      )}
    </Modal>
  );
}
