import { useIntl } from 'i18n';
import { GenderTypes } from '__generated__/globalTypes';
import { useCallback, useMemo } from 'react';

export default function useGenderTypes() {
  const { t } = useIntl();
  const genderTypes: Record<GenderTypes, string> = useMemo(
    () => ({
      [GenderTypes.FEMALE]: t('enum.genderTypes.female'),
      [GenderTypes.MALE]: t('enum.genderTypes.male'),
      [GenderTypes.UNSPECIFIED]: t('enum.genderTypes.unspecified'),
    }),
    [t]
  );

  const genderTypesOptions: {
    label: string;
    value: GenderTypes;
  }[] = useMemo(
    () =>
      Object.entries(genderTypes).map(([value, label]) => ({
        value: value as GenderTypes,
        label,
      })),
    [genderTypes]
  );

  const getGenderTypeLabel = useCallback(
    (type?: GenderTypes | null) => {
      if (!type) {
        return '';
      }

      return genderTypes[type];
    },
    [genderTypes]
  );

  return { genderTypesOptions, getGenderTypeLabel };
}
