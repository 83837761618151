import React, { useState, useCallback, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { currency, FormDataType, traceError } from '@appclose/core';

import EmailPreview from 'components/common/EmailPreview';
import { ModalSendEmailPage } from 'components/common/ModalSendEmailPage';
import { getContactName, getEntityPrimaryContact } from 'controllers/contact';
import SendEmailForm, {
  SendEmailFormActionsType,
  SendEmailFormValuesType,
} from 'components/form/SendEmailForm';
import { isDemoBuild } from 'controllers/demo';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { useIntl } from 'i18n';
import useListFiles from 'hooks/useListFiles';
import { Entities } from 'constants/entities';

import {
  FetchCreditMemoQuery,
  FetchCreditMemoQueryVariables,
  SendCreditMemoMutation,
  SendCreditMemoMutationVariables,
} from './__generated__/SendCreditMemoModal.gql';
import { FETCH_CREDIT_MEMO, SEND_CREDIT_MEMO } from './SendCreditMemoModal.gql';
import { SendCreditMemoModalPropsType } from './SendCreditMemoModal.types';

export default function SendCreditMemoModal({
  id,
  onClose,
}: SendCreditMemoModalPropsType) {
  const { t } = useIntl();
  const [sendEmailFormValues, setSendEmailFormValues] = useState<
    SendEmailFormValuesType
  >();
  const { filesLoading, files } = useListFiles({
    entityType: Entities.PAYMENT,
    input: { filter: { entityIds: [id] } },
  });
  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });
  const [sendCreditMemo] = useMutation<
    SendCreditMemoMutation,
    SendCreditMemoMutationVariables
  >(SEND_CREDIT_MEMO);
  const { loading, data } = useQuery<
    FetchCreditMemoQuery,
    FetchCreditMemoQueryVariables
  >(FETCH_CREDIT_MEMO, { variables: { id } });
  const creditMemo = data?.creditMemo as FetchCreditMemoQuery['creditMemo'];
  const invoice = creditMemo?.memoInvoice as NonNullable<
    FetchCreditMemoQuery['creditMemo']['memoInvoice']
  >;
  const firm = data?.firm;
  const contact = getEntityPrimaryContact(invoice);
  const invoiceNumber = invoice?.invoiceNumber || '';
  const initialValues = useMemo<SendEmailFormValuesType>(
    () => ({
      id,
      to: invoice?.contact,
      subject: t('modal.sendCreditMemo.subject', {
        firm: firm?.name,
        memoNumber: creditMemo?.memoNumber,
      }),
      text: t('modal.sendCreditMemo.message', {
        amount: creditMemo?.amount && currency().format(creditMemo.amount),
        contact: contact ? getContactName(contact) : '',
        invoiceNumber,
      }),
      from: firm?.name || '',
      contactId: contact?.id || '',
      files: files?.items,
      includeAccountStatementLink: true,
    }),
    [invoice, firm, files, contact, id, creditMemo, invoiceNumber, t]
  );

  const handleOnSubmit = useCallback(
    async (
      { files, from, to, ...values }: SendEmailFormValuesType,
      { setSubmitting }: SendEmailFormActionsType
    ) => {
      try {
        if (!isDemoBuild()) {
          await sendCreditMemo({
            variables: {
              sendMemoInput: {
                ...values,
                files: files?.map(({ id }) => id) as string[],
              },
            },
          });
        }

        onClose();

        notification().emailSent();
      } catch (e) {
        traceError(e);
        setSubmitting(false);
      }
    },
    [onClose, sendCreditMemo]
  );

  const handleFormChange = useCallback(
    (data: FormDataType<SendEmailFormValuesType>) => {
      onFormChange(data);
      setSendEmailFormValues(data.values);
    },
    [onFormChange]
  );

  return (
    <ModalSendEmailPage
      title={t('modal.sendCreditMemo.title')}
      loading={loading || filesLoading}
      onClose={onConfirmClose}
    >
      <SendEmailForm
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        onCancel={onConfirmClose}
        onChange={handleFormChange}
      />
      <EmailPreview
        text={sendEmailFormValues?.text || ''}
        invoice={invoice}
        firm={firm}
        files={sendEmailFormValues?.files}
        includeAccountStatementLink={
          sendEmailFormValues?.includeAccountStatementLink
        }
      />
    </ModalSendEmailPage>
  );
}
