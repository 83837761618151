import React from 'react';
import classnames from 'classnames';
import {
  Button,
  Caption2,
  Flex,
  Offset,
  Text3,
  Text4,
  Title1,
  Title3,
} from '@appclose/ui';
import { Amount } from '@appclose/core';

import { Modes } from '__generated__/globalTypes';
import { ReactComponent as AnchorIcon } from 'assets/anchor.svg';
import { I18n } from 'i18n';

import { ModePropsType } from './Mode.types';
import styles from './Mode.module.scss';
import { NO_BREAK_SPACE } from '@appclose/lib';

export default function Mode({
  mode,
  title,
  price,
  description,
  minify = false,
  onSelect,
}: ModePropsType) {
  const isFreeMode = mode === Modes.FREE;

  return (
    <article className={classnames(styles.mode, { [styles.minify]: minify })}>
      {minify ? (
        <Flex alignItems="center" justify="space-between">
          <div>
            <Flex alignItems="baseline">
              <Title3 offset={{ right: 18 }}>{title}</Title3>
              <Text4>
                <I18n
                  id={
                    isFreeMode
                      ? 'modes.mode.priceMonth'
                      : 'modes.mode.priceUserMonth'
                  }
                  values={{
                    price: <Amount value={price} className={styles.price} />,
                  }}
                />
              </Text4>
            </Flex>
            {isFreeMode && (
              <Caption2 offset={{ top: 5 }}>
                <I18n id="modes.mode.withAccount" />
              </Caption2>
            )}
          </div>
          {onSelect ? (
            <Button skin="brand" onClick={onSelect}>
              <I18n id="modes.mode.select" />
            </Button>
          ) : (
            <Caption2 color="gray" weight="bold">
              <I18n id="modes.mode.current" />
            </Caption2>
          )}
        </Flex>
      ) : (
        <>
          <Title1 offset={{ bottom: 15 }} className={styles.title}>
            {title}
          </Title1>
          {description && (
            <Text3 offset={{ bottom: 35 }} className={styles.description}>
              {description}
            </Text3>
          )}
          <Flex
            alignItems="baseline"
            justify="space-between"
            className={styles.priceWrapper}
          >
            <Text4>
              <I18n
                id={
                  isFreeMode
                    ? 'modes.mode.priceMonth'
                    : 'modes.mode.priceUserMonth'
                }
                values={{
                  price: <Amount value={price} className={styles.price} />,
                }}
              />
              {isFreeMode && (
                <>
                  {NO_BREAK_SPACE}
                  <I18n id="modes.mode.withAccount" />
                </>
              )}
              <AnchorIcon width={12} className={styles.anchor} />
            </Text4>
          </Flex>
          {onSelect ? (
            <Offset top="m">
              <Button skin="brand" onClick={onSelect}>
                <I18n id="modes.mode.proceedWith" values={{ title }} />
              </Button>
            </Offset>
          ) : (
            <Text3 color="gray" weight="bold" offset={{ top: 36 }}>
              <I18n id="modes.mode.current" />
            </Text3>
          )}
        </>
      )}
    </article>
  );
}
