import { gql } from '@apollo/client';

export const UPGRADE_MODE = gql`
  mutation UpgradeMode($strategy: UpgradeModeStrategy!) {
    upgradeMode(upgradeModeInput: { strategy: $strategy }) {
      success
    }
  }
`;

export const FETCH_INVOICES_TOTAL = gql`
  query FetchInvoicesTotal {
    listInvoices(listInvoiceInput: { skip: 0, take: 1 }) {
      total
    }
  }
`;
