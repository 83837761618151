import { useIntl } from 'i18n';
import { MatterTypes } from '__generated__/globalTypes';
import { useCallback, useMemo } from 'react';

export default function useMatterTypes() {
  const { t } = useIntl();
  const matterTypes: Record<MatterTypes, string> = useMemo(
    () => ({
      [MatterTypes.HYBRID]: t('enum.matterTypes.hybrid'),
      [MatterTypes.FLAT_FEE]: t('enum.matterTypes.flattFee'),
      [MatterTypes.CONTINGENT]: t('enum.matterTypes.contingent'),
    }),
    [t]
  );
  const matterTypesOptions: {
    value: MatterTypes;
    label: string;
  }[] = useMemo(
    () =>
      Object.entries(matterTypes).map(([value, label]) => ({
        value: value as MatterTypes,
        label,
      })),
    [matterTypes]
  );

  const getMatterTypeLabel = useCallback(
    (type?: MatterTypes | null) => {
      if (!type) {
        return '';
      }

      return matterTypes[type];
    },
    [matterTypes]
  );

  return {
    matterTypesOptions,
    getMatterTypeLabel,
  };
}
