import { gql } from '@apollo/client';

export const LEDGER_NAME_FRAGMENT = gql`
  fragment LedgerName on ContactMatterType {
    ... on ContactType {
      id
      name
    }
    ... on MatterType {
      id
      name
      matterNumber
      contact {
        id
        name
      }
    }
    ... on PlainTextType {
      name: value
    }
    ... on ThirdPartyPayeeType {
      id
      name: payeeName
    }
  }
`;
