import React, { useCallback, useEffect, useState } from 'react';
import ReactCrop, { Crop as CropConfigType } from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import { getFileDataUrl } from '@appclose/lib';
import { Button, ButtonGroup } from '@appclose/ui';
import { Modal, useIsMobileDevice } from '@appclose/core';

import { CropModalPropsType } from './CropModal.types';
import styles from './CropModal.module.scss';

export default function CropModal({
  file,
  onSave,
  onClose,
}: CropModalPropsType) {
  const isMobileDevice = useIsMobileDevice();
  const [src, setSrc] = useState<string>();
  const [image, setImage] = useState();
  const [crop, setCrop] = useState<CropConfigType>({
    unit: '%',
    aspect: 1,
    width: 30,
  });

  useEffect(() => {
    getFileDataUrl(file).then(setSrc);
  }, [file]);

  const cropImg = useCallback(
    (image, crop): Promise<File> => {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      canvas.width = crop.width;
      canvas.height = crop.height;

      const ctx = canvas.getContext('2d');

      ctx?.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      return new Promise((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(
                new File([blob], file.name, {
                  lastModified: new Date().getTime(),
                  type: file.type,
                })
              );
            } else {
              reject();
            }
          },
          image.type,
          1
        );
      });
    },
    [file.name, file.type]
  );
  const handleOnImageLoaded = useCallback((image) => setImage(image), []);
  const handleOnChange = useCallback((c) => setCrop(c), []);
  const handleOnSave = useCallback(
    () =>
      cropImg(image, crop).then((file) => {
        onSave(file);
        onClose();
      }),
    [image, crop, cropImg, onSave, onClose]
  );

  return src ? (
    <Modal onClose={onClose} theme={isMobileDevice ? 'page' : undefined}>
      <ReactCrop
        src={src}
        crop={crop}
        onImageLoaded={handleOnImageLoaded}
        onChange={handleOnChange}
      />
      <ButtonGroup className={styles.buttons}>
        <Button onClick={onClose}>Cancel</Button>
        <Button skin="primary" onClick={handleOnSave}>
          Save
        </Button>
      </ButtonGroup>
    </Modal>
  ) : null;
}
