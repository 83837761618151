import * as Yup from 'yup';

import { FILES_SCHEMA } from 'schemas/validations/files';

import { LeadFormValuesType } from './LeadForm.types';
import { IndividualFieldsetSchema } from './components/IndividualFieldset';
import { EntityFieldsetSchema } from './components/EntityFieldset';
import { LeadModalTabs } from '../../LeadModal.types';

export const LeadFormSchema = Yup.object().shape<
  Partial<LeadFormValuesType>
>({
  individual: Yup.object<any>().when('selectedTab', {
    is: LeadModalTabs.INDIVIDUAL,
    then: IndividualFieldsetSchema,
  }),
  entity: Yup.object<any>().when('selectedTab', {
    is: LeadModalTabs.ENTITY,
    then: EntityFieldsetSchema,
  }),
  files: FILES_SCHEMA,
});
