import { FormActions } from '@appclose/core';

export enum PrintingResults {
  YES = 'YES',
  SOME = 'SOME',
  NO = 'NO',
}

export type PrintingResultsFormValuesType = {
  reprintStartNo: number;
  printResult: PrintingResults;
};

export type PrintingResultsFormActionsType = FormActions<
  PrintingResultsFormValuesType
>;

export type PrintingResultsFormPropsType = {
  initialValues: PrintingResultsFormValuesType;
  totalChecks: number;
  onSubmit(
    values: PrintingResultsFormValuesType,
    actions: PrintingResultsFormActionsType
  ): void;
};
