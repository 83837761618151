import { RoundingRulesType } from '__generated__/globalTypes';
import { getTimerDuration } from 'controllers/timer';
import { ROUNDING_DURATIONS } from 'constants/roundingRules';

export function getRoundedDuration(rules: Omit<RoundingRulesType, "__typename">, duration: number) {
  const { rounding, divider } = rules;
  const roundingDuration = ROUNDING_DURATIONS[divider] * 60;

  return rounding === 'UP'
    ? duration + roundingDuration - ((duration % roundingDuration) || roundingDuration)
    : duration - (duration % roundingDuration);
}

export function getFormattedDuration(duration: number) {
  const { hours, minutes } = getTimerDuration(duration);
  const formattedHours = ('0' + hours).slice(-2);
  const formattedMinutes = ('0' + minutes).slice(-2);

  return `${formattedHours} : ${formattedMinutes}`;
}
