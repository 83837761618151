import React from 'react';
import gqlClient from 'gql';
import { Text3 } from '@appclose/ui';
import { openConfirmAction, traceError } from '@appclose/core';

import notification from 'controllers/notification';
import { i18nMessage } from 'i18n';

import { VOID_DISBURSEMENT } from './disbursement.gql';
import {
  VoidDisbursementMutation,
  VoidDisbursementMutationVariables,
} from './__generated__/disbursement.gql';

export const openVoidDisbursementConfirmAction = ({
  id,
  checkNumber,
  onSuccess,
}: {
  id: string;
  checkNumber?: number | null;
  onSuccess?(): void;
}) =>
  openConfirmAction({
    name: i18nMessage('store.disbursement.voidDisbursementConfirm.name'),
    title: i18nMessage('store.disbursement.voidDisbursementConfirm.title'),
    content: (
      <Text3 offset={{ left: 16, right: 16 }}>
        {i18nMessage('store.disbursement.voidDisbursementConfirm.content')}
      </Text3>
    ),
    okButtonTitle: checkNumber
      ? i18nMessage(
          'store.disbursement.voidDisbursementConfirm.okButton.withCheck',
          { checkNumber }
        )
      : i18nMessage(
          'store.disbursement.voidDisbursementConfirm.okButton.withoutCheck'
        ),
    okButtonSkin: 'primary',
    cancelButtonTitle: i18nMessage(
      'store.disbursement.voidDisbursementConfirm.cancelButton'
    ),
    async onConfirm() {
      try {
        await gqlClient.mutate<
          VoidDisbursementMutation,
          VoidDisbursementMutationVariables
        >({
          mutation: VOID_DISBURSEMENT,
          variables: { id },
        });

        notification().info(
          i18nMessage('store.disbursement.voidDisbursementConfirm.notify')
        );

        onSuccess && onSuccess();
      } catch (e) {
        traceError(e);
      }
    },
  });
