import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Flex } from '@appclose/ui';
import {
  Amount,
  FormGroup,
  Loader,
  StaticField,
  Fieldset,
} from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import Actions, { Action } from 'components/common/Actions';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import { EXPENSE_MODAL, INVOICE_DETAILS_MODAL } from 'constants/modals';
import { openDeleteExpenseConfirmAction } from 'store/actions';
import Date from 'components/common/Date';
import { ExpenseStatuses } from '__generated__/globalTypes';
import ExpenseStatus from 'components/common/ExpenseStatus';
import Person from 'components/common/Person/Person';
import FormGrid from 'components/common/FormGrid';
import InvoiceInfo from 'components/common/InvoiceInfo';
import { PermissionGuard } from 'components/common/PermissionGuard';
import { openModal } from 'controllers/modal';
import AdditionalInfoDetailsFieldset from 'components/common/AdditionalInfoDetailsFieldset';
import ContactMatterDetailsFieldset from 'components/common/ContactMatterDetailsFieldset';
import useSubExpenseCategoryCostTypes from 'hooks/useSubExpenseCategoryCostTypes';
import { I18n, useIntl } from 'i18n';
import useListFiles from 'hooks/useListFiles';
import { Entities } from 'constants/entities';

import { ExpenseDetailsModalPropsType } from './ExpenseDetailsModal.types';
import { FETCH_EXPENSE } from './ExpenseDetailsModal.gql';
import {
  FetchExpenseQuery,
  FetchExpenseQueryVariables,
} from './__generated__/ExpenseDetailsModal.gql';
import styles from './ExpenseDetailsModal.module.scss';

export default function ExpenseDetailsModal({
  id,
  onClose,
}: ExpenseDetailsModalPropsType) {
  const { t } = useIntl();
  const dispatch = useDispatch();
  const {
    getSubExpenseCategoryCostTypeLabel,
  } = useSubExpenseCategoryCostTypes();
  const { loading, data } = useQuery<
    FetchExpenseQuery,
    FetchExpenseQueryVariables
  >(FETCH_EXPENSE, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
  const expense = data?.expense;
  const { filesLoading, files } = useListFiles({
    entityType: Entities.EXPENSE,
    input: { filter: { entityIds: [id] } },
    skip: !expense,
  });
  const actionsAllowed =
    !filesLoading && expense
      ? [ExpenseStatuses.BILLABLE, ExpenseStatuses.NOT_BILLABLE].includes(
          expense.status
        )
      : false;

  const handleOnOpenExpenseModal = useCallback(
    () => openModal(EXPENSE_MODAL, { id }),
    [id]
  );

  const handleOnOpenDeleteExpenseConfirm = useCallback(
    () =>
      dispatch(
        openDeleteExpenseConfirmAction({
          id,
          onSuccess() {
            onClose();
          },
        })
      ),
    [dispatch, id, onClose]
  );

  return (
    <ModalPage onClose={onClose}>
      <ModalPageHeader>
        <Flex alignItems="center" justify="space-between">
          <ModalPageTitle>
            <I18n id="modal.expenseDetails.title" />
          </ModalPageTitle>
          {actionsAllowed && (
            <Actions>
              <PermissionGuard
                resource={PermissionResources.EXPENSE}
                action={PermissionActions.UPDATE}
              >
                <Action type="edit" onClick={handleOnOpenExpenseModal} />
              </PermissionGuard>
              <PermissionGuard
                resource={PermissionResources.EXPENSE}
                action={PermissionActions.DELETE}
              >
                <Action
                  type="delete"
                  onClick={handleOnOpenDeleteExpenseConfirm}
                />
              </PermissionGuard>
            </Actions>
          )}
        </Flex>
      </ModalPageHeader>
      <ModalPageContent>
        {loading || filesLoading ? (
          <Loader />
        ) : (
          expense && (
            <>
              {expense.invoice && (
                <FormGroup className={styles.invoice}>
                  <StaticField title={t('modal.expenseDetails.invoice.title')}>
                    <InvoiceInfo
                      invoice={expense.invoice}
                      onViewButtonClick={() => {
                        openModal(INVOICE_DETAILS_MODAL, {
                          id: expense.invoice?.id,
                        });
                      }}
                    />
                  </StaticField>
                </FormGroup>
              )}
              <Fieldset title={t('modal.expenseDetails.details.title')}>
                <FormGroup>
                  <FormGrid>
                    <StaticField
                      title={t('modal.expenseDetails.details.title')}
                    >
                      {getSubExpenseCategoryCostTypeLabel(
                        expense.category.costType
                      )}
                    </StaticField>
                    <StaticField
                      title={t('modal.expenseDetails.details.category')}
                    >
                      {expense.category.name}
                    </StaticField>
                  </FormGrid>
                </FormGroup>
                {expense.description && (
                  <FormGroup>
                    <StaticField
                      title={t('modal.expenseDetails.details.description')}
                    >
                      {expense.description}
                    </StaticField>
                  </FormGroup>
                )}
                <FormGroup>
                  <FormGrid>
                    <StaticField
                      title={t('modal.expenseDetails.details.amount')}
                    >
                      <Amount value={expense.amount} />
                    </StaticField>
                    <StaticField title={t('modal.expenseDetails.details.date')}>
                      <Date value={expense.date} />
                    </StaticField>
                    <StaticField
                      title={t('modal.expenseDetails.details.status')}
                    >
                      <ExpenseStatus status={expense.status} />
                    </StaticField>
                    <StaticField
                      title={t('modal.expenseDetails.details.billedBy')}
                    >
                      {expense.billedBy && (
                        <Person
                          firstName={expense.billedBy.firstName}
                          lastName={expense.billedBy.lastName}
                          icon={expense.billedBy.picture}
                          className={styles.billedBy}
                        />
                      )}
                    </StaticField>
                  </FormGrid>
                </FormGroup>
              </Fieldset>
              <ContactMatterDetailsFieldset
                matter={expense.matter}
                contact={expense.contact}
              />
              <AdditionalInfoDetailsFieldset
                notes={expense.notes}
                files={files?.items}
              />
            </>
          )
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
