import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { AddButton, Button, ButtonGroup } from '@appclose/ui';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { CONTACT_MODAL, MATTER_MODAL } from 'constants/modals';
import { openModal } from 'controllers/modal';
import { I18n, useIntl } from 'i18n';

import {
  CompleteContactsFillStepMutation,
  CompleteContactsFillStepMutationVariables,
} from './__generated__/TrustSetupContactsMattersModal.gql';
import ContactsMattersList from './components/ContactsMattersList';
import { COMPLETE_CONTACTS_MATTERS_STEP } from './TrustSetupContactsMattersModal.gql';
import { TrustSetupQboContactsMattersModalPropsType } from './TrustSetupContactsMattersModal.types';
import styles from './TrustSetupContactsMattersModal.module.scss';

export default function TrustSetupContactsMattersModal({
  onClose,
}: TrustSetupQboContactsMattersModalPropsType) {
  const { t } = useIntl();
  const [skipQboIntegrationStep, { loading }] = useMutation<
    CompleteContactsFillStepMutation,
    CompleteContactsFillStepMutationVariables
  >(COMPLETE_CONTACTS_MATTERS_STEP);

  const handleOnComplete = useCallback(async () => {
    await skipQboIntegrationStep();

    onClose();
  }, [onClose, skipQboIntegrationStep]);

  const openContactModal = useCallback(() => {
    openModal(CONTACT_MODAL, { openNew: false });
  }, []);

  const openMatterModal = useCallback(() => {
    openModal(MATTER_MODAL, { openNew: false });
  }, []);

  return (
    <ModalPage onClose={onClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.trustSetupContactsMatters.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        <p className={styles.info}>
          <I18n id="modal.trustSetupContactsMatters.info" />
        </p>
        <ContactsMattersList />
        <ButtonGroup>
          <AddButton
            onClick={openContactModal}
            skin="success"
            size={28}
            disabled={loading}
            label={t('modal.trustSetupContactsMatters.button.addContact')}
          />
          <AddButton
            onClick={openMatterModal}
            skin="success"
            size={28}
            disabled={loading}
            label={t('modal.trustSetupContactsMatters.button.addMatter')}
          />
        </ButtonGroup>
        <Button
          onClick={handleOnComplete}
          skin="primary"
          loading={loading}
          className={styles.completeButton}
        >
          <I18n id="modal.trustSetupContactsMatters.button.allAdded" />
        </Button>
      </ModalPageContent>
    </ModalPage>
  );
}
