import { gql } from '@apollo/client';

import { CONTACT_SELECT_FRAGMENT } from '../ContactSelectFormField';
import { TEAM_MEMBER_SELECT_FRAGMENT } from '../TeamMemberSelectFormField';

export const MATTER_SELECT_FRAGMENT = gql`
  fragment MatterSelect on MatterType {
    id
    name
    contact {
      ...ContactSelect
    }
    teamMembers {
      member {
        ...TeamMemberSelect
      }
      hourlyRate
    }
    matterNumber
    type
  }
  ${CONTACT_SELECT_FRAGMENT}
  ${TEAM_MEMBER_SELECT_FRAGMENT}
`;

export const FETCH_MATTER_OPTIONS = gql`
  query FetchMatterOptions(
    $filter: MatterFilterInput
    $order: OrderMatterInput
    $skip: Int
    $take: Int
  ) {
    listMatters(
      listMatterInput: {
        filter: $filter
        order: $order
        skip: $skip
        take: $take
      }
    ) {
      items {
        ...MatterSelect
      }
      total
    }
  }
  ${MATTER_SELECT_FRAGMENT}
`;
