import React from 'react';
import {
  AmountFormField,
  dateManager,
  DatePickerFormField,
  Form,
  FormGroup,
  FormSubmissionError,
  TextAreaFormField,
  ToggleFormField,
  Fieldset,
} from '@appclose/core';
import { Button, ButtonGroup, Offset } from '@appclose/ui';

import ContactMatterFieldset from 'components/form/ContactMatterFieldset';
import FormGrid from 'components/common/FormGrid';
import ExpenseCategoryCostTypeSelectFormField from 'components/fields/ExpenseCategoryCostTypeSelectFormField';
import ExpenseCategorySelectFormField from 'components/fields/ExpenseCategorySelectFormField';
import BilledByField from 'components/fields/BilledByField';
import AdditionalInfoFieldset from 'components/form/AdditionalInfoFieldset';
import { SERVER_DATE_FORMAT } from 'constants/date';
import { I18n, useIntl } from 'i18n';
import { Entities } from 'constants/entities';

import { ExpenseFormPropsType } from './ExpenseForm.types';
import { ExpenseFormSchema } from './ExpenseForm.schema';
import styles from './ExpenseForm.module.scss';

export default function ExpenseForm({
  initialValues,
  isEdit,
  profileId,
  onSubmit,
  onCancel,
  onChange,
}: ExpenseFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      validationSchema={ExpenseFormSchema}
      onSubmit={onSubmit}
      onChange={onChange}
    >
      {({ values: { id, costType }, setFieldValue, isSubmitting }) => (
        <>
          <ContactMatterFieldset allowAddNewMatter />
          <Fieldset title={t('modal.expense.form.fieldset.info.title')}>
            <FormGroup>
              <FormGrid>
                <ExpenseCategoryCostTypeSelectFormField
                  onChange={() => {
                    setFieldValue('category', undefined, true);
                  }}
                />
                <ExpenseCategorySelectFormField
                  costType={costType}
                  allowAddNew
                  onChange={(data) => {
                    if (data && data.costType !== costType) {
                      setFieldValue('costType', data.costType, true);
                    }
                  }}
                />
              </FormGrid>
            </FormGroup>
            <FormGroup>
              <TextAreaFormField
                name="description"
                label={t('modal.expense.form.fieldset.info.description')}
              />
            </FormGroup>
            <FormGroup>
              <FormGrid>
                <AmountFormField
                  name="amount"
                  label={t('modal.expense.form.fieldset.info.amount')}
                />
                <DatePickerFormField
                  name="date"
                  label={t('modal.expense.form.fieldset.info.date')}
                  outputDateFormat={SERVER_DATE_FORMAT}
                  maxDate={dateManager().parse().endOf('day').valueOf()}
                  useHumanReadableFormat
                  allowInput={false}
                />
                <div className={styles.toggle}>
                  <ToggleFormField
                    name="billable"
                    label={t('modal.expense.form.fieldset.info.billable')}
                  />
                </div>
                <BilledByField profileId={profileId} />
              </FormGrid>
            </FormGroup>
          </Fieldset>
          <AdditionalInfoFieldset entityType={Entities.EXPENSE} entityId={id} />
          <Offset bottom="m">
            <FormSubmissionError />
          </Offset>
          <ButtonGroup>
            <Button onClick={onCancel}>
              <I18n id="modal.expense.form.cancel" />
            </Button>
            <Button type="submit" skin="primary" loading={isSubmitting}>
              {isEdit
                ? t('modal.expense.form.update.submit')
                : t('modal.expense.form.create.submit')}
            </Button>
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}
