import * as Yup from 'yup';
import { currency } from '@appclose/core';

import { getI18nErrorMessage } from 'i18n';
import { MATTER_AGREED_FLAT_FEE_MAX_AMOUNT } from 'constants/matter';

import { FlatFeeMatterAmountFormValuesType } from './FlatFeeMatterAmountForm.types';

export const FlatFeeMatterAmountFormSchema = Yup.object().shape<
  Omit<FlatFeeMatterAmountFormValuesType, 'originalAgreedFlatFee'>
>({
  agreedFlatFee: Yup.number()
    .required(
      getI18nErrorMessage(
        'modal.popup.flatFeeMatterAmount.form.error.agreedFlatFee.required'
      )
    )
    .when(['originalAgreedFlatFee'], (originalAgreedFlatFee: number) =>
      Yup.number()
        .required(
          getI18nErrorMessage(
            'modal.popup.flatFeeMatterAmount.form.error.agreedFlatFee.required'
          )
        )
        .min(originalAgreedFlatFee, ({ min }) =>
          getI18nErrorMessage(
            'modal.popup.flatFeeMatterAmount.form.error.agreedFlatFee.min',
            { min: currency().format(min) }
          )
        )
        .max(MATTER_AGREED_FLAT_FEE_MAX_AMOUNT, ({ max }) =>
          getI18nErrorMessage(
            'modal.popup.flatFeeMatterAmount.form.error.agreedFlatFee.max',
            { max: currency().format(max) }
          )
        )
    ),
});
