import React from 'react';
import { openConfirmAction } from '@appclose/core';

import { EventNames } from 'constants/analytics';
import { track } from 'controllers/analytics';
import { i18nMessage } from 'i18n';

export const openCancelConfirmAction = ({
  name,
  onClose,
}: {
  name?: string;
  onClose: () => void;
}) =>
  openConfirmAction({
    name: name,
    content: i18nMessage('store.common.cancelConfirm.content'),
    cancelButtonTitle: i18nMessage('store.common.cancelConfirm.cancelButton'),
    okButtonTitle: i18nMessage('store.common.cancelConfirm.okButton'),
    okButtonSkin: 'primary',
    onConfirm: () => {
      track(EventNames.CANCEL_ENTRY);
      onClose();
    },
  });

export const openDemoUnavailableConfirmAction = (
  name: string,
  content?: React.ReactNode
) => {
  content =
    content ||
    i18nMessage('store.common.demoUnavailableConfirm.defaultContent');

  return openConfirmAction({
    name,
    content,
    okButtonTitle: i18nMessage('store.common.demoUnavailableConfirm.okButton'),
    okButtonSkin: 'primary',
  });
};
