import * as Yup from 'yup';

import { FILES_SCHEMA } from 'schemas/validations/files';

import { AttachBankStatementFormValuesType } from './AttachBankStatementModal.types';

export const AttachBankStatementModalSchema = Yup.object().shape<AttachBankStatementFormValuesType>(
  {
    files: FILES_SCHEMA,
  }
);
