import {
  FetchContactQuery,
  FetchInvoiceQuery,
  FetchMatterQuery,
} from './__generated__/ReceivePaymentModal.gql';

export const getInitialContact = (
  contactData?: FetchContactQuery,
  matterData?: FetchMatterQuery,
  invoiceData?: FetchInvoiceQuery
) => {
  return (
    contactData?.contact ||
    matterData?.matter?.contact ||
    invoiceData?.invoice?.contact
  );
};

export const getInitialMatter = (
  matterData?: FetchMatterQuery,
  invoiceData?: FetchInvoiceQuery
) => {
  return matterData?.matter || invoiceData?.invoice?.matters?.[0];
};

export const getInitialInvoices = (invoiceData?: FetchInvoiceQuery) => {
  return invoiceData?.invoice ? [invoiceData.invoice] : [];
};
