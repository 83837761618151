import React, { memo } from 'react';
import { useFormContext, Fieldset, useUpdateEffect } from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import ContactSelectFormField, {
  SelectContactType,
} from 'components/fields/ContactSelectFormField';
import MatterSelectFormField, {
  SelectMatterType,
} from 'components/fields/MatterSelectFormField';
import { useIntl } from 'i18n';

import { ContactMatterFieldsetPropsType } from './ContactMatterFieldset.types';

const ContactMatterFieldset = memo(function ContactMatterFieldset({
  excludeContacts,
  allowAddNewMatter,
  showLabel,
  disabled,
  onChange,
}: ContactMatterFieldsetPropsType) {
  const { t } = useIntl();
  const {
    values: { contact, matter },
    setValues,
  } = useFormContext<{
    contact?: SelectContactType | null;
    matter?: SelectMatterType | null;
  }>();

  useUpdateEffect(() => {
    onChange?.(contact || undefined, matter || undefined);
  }, [contact, matter, onChange]);

  return (
    <Fieldset title={t('form.contactMatterFieldset.title')}>
      <FormGrid>
        <ContactSelectFormField
          showLabel={showLabel}
          showPlaceholder="always"
          clearable
          exclude={excludeContacts}
          disabled={disabled}
          onChange={(contact) => {
            setValues(
              (values) => ({
                ...values,
                contact,
                matter: undefined,
              }),
              true
            );
          }}
        />
        <MatterSelectFormField
          showLabel={showLabel}
          showPlaceholder="always"
          filter={{ clientId: contact?.id }}
          disabled={disabled}
          onChange={(matter) => {
            setValues(
              (values) => ({
                ...values,
                matter,
                contact: values.contact || matter.contact,
              }),
              true
            );
          }}
          allowAddNew={allowAddNewMatter}
        />
      </FormGrid>
    </Fieldset>
  );
});

export default ContactMatterFieldset;
