import { gql } from '@apollo/client';

export const TRACK_ANALYTICS_EVENT = gql`
  mutation TrackAnalyticsEvent($event: String!, $data: JSON!, $timestamp: Int) {
    trackAnalyticEvent(
      trackAnalyticEventInput: {
        event: $event
        data: $data
        timestamp: $timestamp
      }
    ) {
      success
    }
  }
`;

export const TRACK_PUBLIC_ANALYTICS_EVENT = gql`
  mutation TrackPublicAnalyticsEvent(
    $event: PublicAnalyticEvent!
    $data: JSON!
    $timestamp: Int
  ) {
    trackPublicAnalyticEvent(
      trackPublicAnalyticEventInput: {
        event: $event
        data: $data
        timestamp: $timestamp
      }
    ) {
      success
    }
  }
`;
