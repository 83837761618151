import React from 'react';
import { FormGroup, StaticField, Fieldset } from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import Address from 'components/common/Address';
import EntityQboSyncStatus from 'components/common/EntityQboSyncStatus';
import LedgerName from 'components/common/LedgerName';
import Date from 'components/common/Date';
import usePaymentMethodsTypes from 'hooks/usePaymentMethodsTypes';
import { useIntl } from 'i18n';

import { CommonDisbursementDetailsPropsType } from '../../DisbursementDetailsModal.types';
import styles from '../../DisbursementDetailsModal.module.scss';
import AssociatedPayments from '../AssociatedPayments';
import PaymentDetails from '../PaymentDetails';

export default function OtoDisbursementDetails({
  disbursement: {
    id,
    qboSyncState,
    amount,
    date,
    payment,
    ledger,
    status,
    memo,
  },
  firm: { name, address },
}: CommonDisbursementDetailsPropsType) {
  const { t } = useIntl();
  const { getPaymentMethodsTypeLabel } = usePaymentMethodsTypes();

  return (
    <Fieldset
      title={t('modal.disbursementDetails.fieldset.disbursement.title')}
    >
      <FormGroup className={styles.details}>
        <FormGrid>
          <StaticField title={t('modal.disbursementDetails.fieldset.from')}>
            {name}
            {address && <Address addresses={[address]} />}
          </StaticField>
          <StaticField title={t('modal.disbursementDetails.fieldset.to')}>
            {name}
            {address && <Address addresses={[address]} />}
          </StaticField>
          <PaymentDetails amount={amount} status={status} />
          <StaticField title={t('modal.disbursementDetails.fieldset.ledger')}>
            <LedgerName value={ledger} />
          </StaticField>
        </FormGrid>
      </FormGroup>
      <FormGroup>
        <FormGrid>
          <StaticField title={t('modal.disbursementDetails.payment.method')}>
            {getPaymentMethodsTypeLabel(payment?.paymentMethod)}
          </StaticField>
          <StaticField title={t('modal.disbursementDetails.fieldset.date')}>
            <Date value={date} />
          </StaticField>
        </FormGrid>
      </FormGroup>
      <FormGroup>
        <StaticField title={t('modal.disbursementDetails.fieldset.memo')}>
          {memo}
        </StaticField>
      </FormGroup>
      <FormGroup>
        <StaticField
          title={t('modal.disbursementDetails.fieldset.qboSyncStatus')}
        >
          <EntityQboSyncStatus
            entityId={id}
            qboSyncState={qboSyncState || undefined}
            entityType="disbursement"
          />
        </StaticField>
      </FormGroup>
      {payment && <AssociatedPayments payments={[payment]} />}
    </Fieldset>
  );
}
