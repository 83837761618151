import React from 'react';
import ReactZendesk, { ZendeskAPI } from 'react-zendesk';

import { isDemoBuild } from 'controllers/demo';
import { ZENDESK_KEY } from 'constants/env';

export default function Zendesk() {
  if (!isDemoBuild() || !ZENDESK_KEY) {
    return null;
  }

  return (
    <ReactZendesk
      zendeskKey={ZENDESK_KEY}
      onLoaded={() => {
        ZendeskAPI('webWidget', 'setLocale', 'en-US');
      }}
    />
  );
}
