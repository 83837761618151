import React from 'react';
import { getMode } from 'controllers/mode';

import { ModeGuardPropsType } from './ModeGuard.types';

export default function ModeGuard({ mode, children }: ModeGuardPropsType) {
  const isAllowed = mode === getMode();

  return isAllowed ? <>{children}</> : null;
}
