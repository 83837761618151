import React from 'react';
import { Switch, Route as ReactRouterRoute } from 'react-router-dom';

import {
  SIGN_IN_ROUTE,
  SIGN_UP_ROUTE,
  REPEAT_CONFIRM_EMAIL_ROUTE,
  CONFIRM_EMAIL_ROUTE,
  RESET_PASSWORD_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  ONBOARDING_ROUTE,
  SETUP_PASSWORD_ROUTE,
  NOT_FOUND_ROUTE,
  SIGN_ROUTE,
  PAYMENT_LINK_ROUTE,
  CLIENT_ROUTE,
  MOBILE_ROUTE,
  OPERATING_ACCOUNT_COMPLETED,
  SIGN_UP_SUCCESS_ROUTE,
} from 'constants/routes';
import { REACT_APP_BLOCK_SIGN_UP } from 'constants/env';
import { isDemoBuild } from 'controllers/demo';
import { session } from 'controllers/session';
import Route from 'components/common/Route';
import ProtectedRoute from 'components/common/ProtectedRoute';
import PublicRoute from 'components/common/PublicRoute';
import LayoutAsync from 'components/common/Layout/LayoutAsync';
import ConfirmEmailPageAsync from 'components/pages/ConfirmEmailPage/ConfirmEmailPageAsync';
import SignInPageAsync from 'components/pages/SignInPage/SignInPageAsync';
import SignUpPageAsync from 'components/pages/SignUpPage/SignUpPageAsync';
import SignUpBlockedPageAsync from 'components/pages/SignUpBlockedPage/SignUpBlockedPageAsync';
import SignUpSuccessPageAsync from 'components/pages/SignUpSuccessPage/SignUpSuccessPageAsync';
import ResetPasswordPageAsync from 'components/pages/ResetPasswordPage/ResetPasswordPageAsync';
import ChangePasswordPageAsync from 'components/pages/ChangePasswordPage/ChangePasswordPageAsync';
import OnboardingPageAsync from 'components/pages/OnboardingPage/OnboardingPageAsync';
import SetupPasswordPageAsync from 'components/pages/SetupPasswordPage/SetupPasswordPageAsync';
import NotFoundPageAsync from 'components/common/NotFoundPage/NotFoundPageAsync';
import DemoSignInPageAsync from 'components/pages/DemoSignInPage/DemoSignInPageAsync';
import SignDocumentPageAsync from 'components/pages/SignDocumentPage/SignDocumentPageAsync';
import RepeatEmailConfirmationPageAsync from 'components/pages/RepeatEmailConfirmationPage/RepeatEmailConfirmationPageAsync';
import PaymentLinkPageAsync from 'components/pages/PaymentLinkPage/PaymentLinkPageAsync';
import ClientPageAsync from 'components/pages/ClientPage/ClientPageAsync';
import MobilePageAsync from 'components/pages/MobilePage/MobilePageAsync';
import OperatingAccountCompletedAsync from 'components/pages/OperatingAccountCompleted/OperatingAccountCompletedAsync';

export default function Router(): React.ReactElement {
  const isDemo = isDemoBuild();
  const SignInComponent = isDemo ? DemoSignInPageAsync : SignInPageAsync;

  return (
    <Switch>
      <ReactRouterRoute
        exact
        path={NOT_FOUND_ROUTE}
        component={NotFoundPageAsync}
      />
      <Route exact path={SIGN_IN_ROUTE} component={SignInComponent} />
      {!isDemo && (
        <Route
          exact
          path={REPEAT_CONFIRM_EMAIL_ROUTE}
          component={RepeatEmailConfirmationPageAsync}
        />
      )}
      {!isDemo && (
        <Route
          exact
          path={SIGN_UP_ROUTE}
          component={REACT_APP_BLOCK_SIGN_UP === 'true' ? SignUpBlockedPageAsync : SignUpPageAsync}
        />
      )}
      {!isDemo && (
        <Route
          exact
          path={SIGN_UP_SUCCESS_ROUTE}
          component={SignUpSuccessPageAsync}
        />
      )}
      {!isDemo && (
        <Route
          exact
          path={RESET_PASSWORD_ROUTE}
          component={ResetPasswordPageAsync}
        />
      )}
      <PublicRoute
        exact
        path={SETUP_PASSWORD_ROUTE}
        component={SetupPasswordPageAsync}
      />
      <PublicRoute
        exact
        path={CONFIRM_EMAIL_ROUTE}
        component={ConfirmEmailPageAsync}
      />
      <PublicRoute
        exact
        path={CHANGE_PASSWORD_ROUTE}
        component={ChangePasswordPageAsync}
      />
      <PublicRoute
        exact
        path={PAYMENT_LINK_ROUTE}
        component={PaymentLinkPageAsync}
      />
      <PublicRoute exact path={SIGN_ROUTE} component={SignDocumentPageAsync} />
      <PublicRoute path={CLIENT_ROUTE} component={ClientPageAsync} />
      <PublicRoute
        path={MOBILE_ROUTE}
        session={session}
        component={MobilePageAsync}
      />
      <ProtectedRoute path={ONBOARDING_ROUTE} component={OnboardingPageAsync} />
      <ProtectedRoute
        path={OPERATING_ACCOUNT_COMPLETED}
        component={OperatingAccountCompletedAsync}
      />
      <ProtectedRoute component={LayoutAsync} />
    </Switch>
  );
}
