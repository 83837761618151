import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import { Grid } from '@appclose/ui';
import {
  FormGroup,
  InputFormField,
  DatePickerFormField,
  dateManager,
  Loader,
  useUpdateEffect,
  FieldError,
  getFieldError,
  useFormContext
} from '@appclose/core';

import { ScheduledPaymentsSettingsInput } from '__generated__/globalTypes';
import FormGrid from 'components/common/FormGrid';
import PeriodSelectFormField from 'components/fields/PeriodSelectFormField';
import SchedulePreview from 'components/common/SchedulePreview/SchedulePreview';
import { SERVER_DATE_FORMAT } from 'constants/date';
import { I18n, useIntl } from 'i18n';

import {
  CalculateScheduledPaymentsQuery,
  CalculateScheduledPaymentsQueryVariables,
} from './__generated__/SchedulePaymentsFormField.gql';
import { CALCULATE_SCHEDULED_PAYMENTS } from './SchedulePaymentsFormField.gql';
import { SchedulePaymentsFormFieldPropsType } from './SchedulePaymentsFormField.types';
import { isScheduledPaymentsAllowed } from './SchedulePaymentsFormField.utils';
import styles from './SchedulePaymentsFormField.module.scss';

export default function SchedulePaymentsFormField({
  scheduleSettings,
  schedulePlan,
  scheduleLoading,
  amount,
  onScheduleChange,
  setFieldValue,
}: SchedulePaymentsFormFieldPropsType) {
  const { t } = useIntl();
  const form = useFormContext();
  const { refetch } = useQuery<
    CalculateScheduledPaymentsQuery,
    CalculateScheduledPaymentsQueryVariables
  >(CALCULATE_SCHEDULED_PAYMENTS, {
    skip: true,
  });

  const installmentsError = getFieldError(form, 'scheduleSettings.installments');
  const invoiceEveryError = getFieldError(form, 'scheduleSettings.invoiceEvery');

  const handleOnRefreshSchedule = useMemo(
    () =>
      debounce(
        ({
          amount,
          scheduleSettings,
        }: Pick<
          SchedulePaymentsFormFieldPropsType,
          'amount' | 'scheduleSettings'
        >) => {
          if (isScheduledPaymentsAllowed(scheduleSettings, amount)) {
            setFieldValue('scheduleLoading', true);

            refetch({
              input: {
                amount,
                settings: scheduleSettings as ScheduledPaymentsSettingsInput,
              },
            }).then(({ data }) => {
              setFieldValue('schedulePlan', data?.plan);
              setFieldValue('scheduleLoading', false);
            });
          }
        },
        500
      ),
    [refetch, setFieldValue]
  );

  useUpdateEffect(() => {
    setFieldValue('schedulePlan', null);
    handleOnRefreshSchedule({ amount, scheduleSettings });
  }, [amount, handleOnRefreshSchedule, scheduleSettings]);

  useUpdateEffect(() => {
    onScheduleChange(schedulePlan || []);
  }, [onScheduleChange, schedulePlan]);

  return (
    <>
      <FormGroup>
        <p className={styles.notes}>
          <I18n id="modal.invoice.form.field.scheduleSettings.notes" />
        </p>
      </FormGroup>
      <FormGroup>
        <FormGrid>
          <div>
            <Grid columns="80px auto" gap={[20]}>
              <InputFormField
                type="number"
                name="scheduleSettings.installments"
                label={t(
                  'modal.invoice.form.field.scheduleSettings.installments'
                )}
                placeholder="0"
                hideErrorMsg
              />
              <div>
                <DatePickerFormField
                  name="scheduleSettings.startDate"
                  label={t('modal.invoice.form.field.scheduleSettings.startDate')}
                  outputDateFormat={SERVER_DATE_FORMAT}
                  minDate={dateManager().parse().startOf('day').valueOf()}
                  showLabel="alwaysMinified"
                  hideErrorMsg
                />
                {!installmentsError &&
                  <FieldError name="scheduleSettings.startDate" />
                }
              </div>
            </Grid>
            {!!installmentsError && (
              <>
                <FieldError name="scheduleSettings.installments" />
                <FieldError name="scheduleSettings.startDate" />
              </>
            )}
          </div>
          <div>
            <Grid columns="80px auto" gap={[20]}>
              <InputFormField
                type="number"
                name="scheduleSettings.invoiceEvery"
                label={t(
                  'modal.invoice.form.field.scheduleSettings.invoiceEvery'
                )}
                placeholder="0"
                hideErrorMsg
              />
              <div>
                <PeriodSelectFormField
                  name="scheduleSettings.invoicePeriod"
                  showLabel="alwaysMinified"
                  hideErrorMsg
                />
                {!invoiceEveryError &&
                  <FieldError name="scheduleSettings.invoicePeriod" />
                }
              </div>
            </Grid>
            {!!invoiceEveryError && (
              <>
                <FieldError name="scheduleSettings.invoiceEvery" />
                <FieldError name="scheduleSettings.invoicePeriod" />
              </>
            )}
          </div>
        </FormGrid>
      </FormGroup>
      <FormGroup>
        {scheduleLoading ? <Loader /> : <SchedulePreview plan={schedulePlan} />}
      </FormGroup>
    </>
  );
}
