import React from 'react';
import classnames from 'classnames';
import { Amount } from '@appclose/core';

import { ContactMatterPropsType } from './ContactMatter.types';
import styles from './ContactMatter.module.scss';

export default function ContactMatter({
  contact,
  matter,
  trustBalance,
  className,
}: ContactMatterPropsType) {
  return (
    <p className={classnames(styles.contactMatter, className)}>
      {contact && <span className={styles.contact}>{contact.name}</span>}
      {matter && (
        <span className={styles.matter}>
          {`${contact ? ' | ' : ''}${[matter.name, matter.matterNumber]
            .filter(Boolean)
            .join(' #')}`}
        </span>
      )}
      {trustBalance || trustBalance === 0 ? (
        <span className={styles.trustBalance}>
          <Amount strong value={trustBalance} />
        </span>
      ) : null}
    </p>
  );
}
