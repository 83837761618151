import React, { useCallback } from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { Router as ReactRouterDom } from 'react-router-dom';
import {
  configureStore,
  history,
  Loader,
  RawIntlProvider,
  useConfigureIntl,
  initErrorTracing,
} from '@appclose/core';

import gqlClient from 'gql';
import { analytics } from 'controllers/analytics';
import { isDemoBuild } from 'controllers/demo';
import { GQL_ERRORS_TO_SKIP_TRACING } from 'controllers/error';
import { initPermissionProvider } from 'controllers/permission';

import './App.module.scss';
import AppContent from './components/AppContent';
import PermissionsProvider from './components/PermissionsProvider';

initErrorTracing({
  tags: { demoMode: isDemoBuild() ? 'enabled' : 'disabled' },
  errorCodesToSkip: GQL_ERRORS_TO_SKIP_TRACING,
});

analytics();
initPermissionProvider();

const store = configureStore();

export default function App() {
  const locale = 'en-US';
  const clientMessagesProvider = useCallback(
    () =>
      import(`i18n/messages/${locale}.json`).then((module) => ({
        messages: module.default,
      })),
    [locale]
  );
  const { intl } = useConfigureIntl({
    locale,
    clientMessagesProvider,
  });

  if (!intl) {
    return <Loader />;
  }

  return (
    <ReactRouterDom history={history}>
      <PermissionsProvider>
        <Provider store={store}>
          <ApolloProvider
            client={
              gqlClient as any /* TODO: Should be fixed after `apollo-link-token-refresh` updated */
            }
          >
            <RawIntlProvider value={intl}>
              <AppContent />
            </RawIntlProvider>
          </ApolloProvider>
        </Provider>
      </PermissionsProvider>
    </ReactRouterDom>
  );
}
