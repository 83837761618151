import React, { memo, useMemo } from 'react';
import { Form, traceError } from '@appclose/core';
import debounce from 'lodash/debounce';

import BankStatementsFieldset from 'components/form/BankStatementsFieldset';
import useUpdateFiles from 'hooks/useUpdateFiles';
import { Entities } from 'constants/entities';

import {
  BankStatementsFormPropsType,
  BankStatementsFormValuesType,
} from './BankStatementsForm.types';

const BankStatementsForm = memo(function BankStatementsForm({
  reconciliationId,
}: BankStatementsFormPropsType) {
  const { onUpdateFiles } = useUpdateFiles(Entities.RECONCILIATION);

  const handleSubmit = useMemo(
    () =>
      debounce(async ({ files }: BankStatementsFormValuesType) => {
        if (files) {
          try {
            await onUpdateFiles({ entityId: reconciliationId, ...files });
          } catch (e) {
            traceError(e);
          }
        }
      }, 500),
    [reconciliationId, onUpdateFiles]
  );

  return (
    <>
      <Form initialValues={{}} onSubmit={handleSubmit}>
        {({ submitForm }) => (
          <BankStatementsFieldset id={reconciliationId} onChange={submitForm} />
        )}
      </Form>
    </>
  );
});

export default BankStatementsForm;
