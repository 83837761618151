import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import lowerCase from 'lodash/lowerCase';
import { useMutation } from '@apollo/client';
import { Loader, openConfirmAction } from '@appclose/core';
import useBankAccounts from 'hooks/useBankAccounts';


import {
  BankAccountClasses,
  BankAccountTypes,
} from '__generated__/globalTypes';
import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import BankAccountForm, {
  BankAccountFormValuesType,
} from 'components/form/BankAccountForm';

import useCloseConfirm from 'hooks/useCloseConfirm';
import useBankAccountClasses from 'hooks/useBankAccountClasses';
import useProfile from 'hooks/useProfile';
import { I18n, useIntl } from 'i18n';

import {
  ConnectTrustAccountMutation,
  ConnectTrustAccountMutationVariables,
  ReplaceBankAccountMutation,
  ReplaceBankAccountMutationVariables,
} from './__generated__/BankAccountModal.gql';
import { CONNECT_TRUST_ACCOUNT, REPLACE_BANK_ACCOUNT } from './BankAccountModal.gql';
import { BankAccountModalPropsType } from './BankAccountModal.types';

export default function BankAccountModal({
  onClose,
  accountClass,
}: BankAccountModalPropsType) {
  const { t } = useIntl();
  const dispatch = useDispatch();
  const { getBankAccountClassLabelWithAccount } = useBankAccountClasses();
  const { hasLimitedAccess } = useProfile();

  const { operatingAccount, trustAccount, onFeeAccountConnect } = useBankAccounts();
  const account = accountClass === BankAccountClasses.OPERATING ? operatingAccount : trustAccount;

  const [connectTrustAccount] = useMutation<
    ConnectTrustAccountMutation,
    ConnectTrustAccountMutationVariables
  >(CONNECT_TRUST_ACCOUNT);

  const [replaceBankAccount] = useMutation<
    ReplaceBankAccountMutation,
    ReplaceBankAccountMutationVariables
  >(REPLACE_BANK_ACCOUNT);

  const bankAccountName = lowerCase(
    getBankAccountClassLabelWithAccount(accountClass)
  );

  const hasAccountLimitedAccess =
    hasLimitedAccess && accountClass === BankAccountClasses.OPERATING;

  const onCloseWithLimitedAccess = useCallback(() => {
    dispatch(
      openConfirmAction({
        name: t('modal.bankAccount.close.notOnboarded.confirm.name'),
        content: t('modal.bankAccount.close.notOnboarded.confirm.content'),
        cancelButtonTitle: t('store.common.cancelConfirm.cancelButton'),
        okButtonTitle: t('modal.bankAccount.close.notOnboarded.confirm.submit'),
        okButtonSkin: 'primary',
        onConfirm: onClose,
      })
    );
  }, [dispatch, t, onClose]);

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });

  const handleOnSubmit = useCallback(
    async (values: BankAccountFormValuesType) => {  
      if (account) {
        await replaceBankAccount({
          variables: { input: { bankAccountId: account.id, bankAccount: values } }
        })
      } else {
        await connectTrustAccount({
          variables: { input: values }
        });
      }

      if (accountClass === BankAccountClasses.TRUST && !account?.feeBankAccount?.truncatedNumber) {
        setTimeout(() => onFeeAccountConnect(), 1000);
      }

      onClose();
    },
    [account, accountClass, onClose, connectTrustAccount, replaceBankAccount, onFeeAccountConnect]
  );

  const initialValues = {
    type: BankAccountTypes.BUSINESS,
  } as BankAccountFormValuesType;

  return (
    <ModalPage
      onClose={
        hasAccountLimitedAccess ? onCloseWithLimitedAccess : onConfirmClose
      }
    >
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.bankAccount.title" values={{ bankAccountName }} />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {false ? (
          <Loader />
        ) : (
          <BankAccountForm
            accountClass={accountClass}
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            onClose={onConfirmClose}
            onChange={onFormChange}
            submitTitle={t('modal.bankAccount.title', { bankAccountName })}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
