import React from 'react';
import { Button, ButtonGroup } from '@appclose/ui';
import { Form, Tab, TabList, TabPanel, Tabs } from '@appclose/core';

import { I18n, useIntl } from 'i18n';

import IndividualFieldset from './components/IndividualFieldset';
import EntityFieldset from './components/EntityFieldset';
import { LeadFormSchema } from './LeadForm.schema';
import { LeadFormPropsType } from './LeadForm.types';
import { LeadModalTabs } from '../../LeadModal.types';

export default function LeadForm({
  initialValues = {} as any,
  isEdit,
  defaultTab,
  onSubmit,
  onCancel,
  onChange,
}: LeadFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      onChange={onChange}
      validationSchema={LeadFormSchema}
    >
      {(form) => {
        const {
          isSubmitting,
          setFieldValue,
          values: { id, selectedTab },
        } = form;
        const showButtons = !isSubmitting;
        const submitButtonText = isEdit
          ? t('modal.lead.form.save')
          : t('modal.lead.form.create');

        return (
          <>
            {isEdit && selectedTab === LeadModalTabs.INDIVIDUAL && (
              <IndividualFieldset id={id} />
            )}
            {isEdit && selectedTab === LeadModalTabs.ENTITY && (
              <EntityFieldset id={id} />
            )}
            {!isEdit && (
              <Tabs
                defaultIndex={defaultTab}
                onSelect={(index) => setFieldValue('selectedTab', index)}
              >
                <TabList>
                  <Tab>
                    <I18n id="modal.lead.form.individual" />
                  </Tab>
                  <Tab>
                    <I18n id="modal.lead.form.entity" />
                  </Tab>
                </TabList>
                <TabPanel>
                  <IndividualFieldset />
                </TabPanel>
                <TabPanel>
                  <EntityFieldset />
                </TabPanel>
              </Tabs>
            )}
            {showButtons && (
              <ButtonGroup>
                <Button onClick={onCancel}>
                  <I18n id="modal.lead.form.cancel" />
                </Button>
                <Button loading={isSubmitting} type="submit" skin="primary">
                  {submitButtonText}
                </Button>
              </ButtonGroup>
            )}
          </>
        );
      }}
    </Form>
  );
}
