import React, { useCallback } from 'react';
import { Modal } from '@appclose/core';
import { Checkbox, ButtonGroup, Button } from '@appclose/ui';

import { BankAccountClasses } from '__generated__/globalTypes';
import { openModal } from 'controllers/modal';
import { DEPOSIT_MODAL } from 'constants/modals';
import {
  getDepositFeatureWarningStatus,
  setDepositFeatureWarningStatus,
} from 'controllers/deposit';
import { useOpenCreateInvoiceModalFlow } from 'hooks/flows';
import { I18n, useIntl } from 'i18n';

import { DepositWarningModalPropsType } from './DepositWarningModal.types';
import styles from './DepositWarningModal.module.scss';

export default function DepositWarningModal({
  onClose,
}: DepositWarningModalPropsType) {
  const { t } = useIntl();
  const runOpenCreateInvoiceModalFlow = useOpenCreateInvoiceModalFlow();

  const handleCreateInvoiceClick = useCallback(() => {
    onClose();
    runOpenCreateInvoiceModalFlow({ account: BankAccountClasses.TRUST });
  }, [onClose, runOpenCreateInvoiceModalFlow]);

  const handleCreateDepositClick = useCallback(() => {
    onClose();
    openModal(DEPOSIT_MODAL);
  }, [onClose]);

  const handleWarningStatusChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDepositFeatureWarningStatus(e.target.checked);
    },
    []
  );

  return (
    <Modal
      className={styles.modal}
      title={t('modal.depositWarning.title')}
      onClose={onClose}
    >
      <p>
        <I18n id="modal.depositWarning.text" />
      </p>
      <Checkbox
        className={styles.check}
        defaultChecked={getDepositFeatureWarningStatus()}
        onChange={handleWarningStatusChange}
        label={t('modal.depositWarning.check')}
      />
      <ButtonGroup className={styles.buttons}>
        <Button onClick={handleCreateInvoiceClick}>
          <I18n id="modal.depositWarning.createInvoice" />
        </Button>
        <Button skin="primary" onClick={handleCreateDepositClick}>
          <I18n id="modal.depositWarning.proceedToDeposit" />
        </Button>
      </ButtonGroup>
    </Modal>
  );
}
