import React, { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Loader, traceError } from '@appclose/core';
import { Button, ButtonGroup } from '@appclose/ui';
import { getDataUri } from '@appclose/lib';

import PdfViewer from 'components/common/PdfViewer';
import notification from 'controllers/notification';
import { I18n, useIntl } from 'i18n';

import {
  FetchPdfCheckQuery,
  FetchPdfCheckQueryVariables,
  PrintChecksMutation,
  PrintChecksMutationVariables,
} from './__generated__/PrintChecksPreview.gql';
import { FETCH_PDF_CHECK, PRINT_CHECKS } from './PrintChecksPreview.gql';
import { PrintSamplePropsType } from './PrintChecksPreview.types';
import styles from './PrintChecksPreview.module.scss';

export default function PrintChecksPreview({
  selectedChecks,
  startCheckNumber,
  onCancel,
  onPrint,
}: PrintSamplePropsType) {
  const { t } = useIntl();
  const { loading, data } = useQuery<
    FetchPdfCheckQuery,
    FetchPdfCheckQueryVariables
  >(FETCH_PDF_CHECK, {
    fetchPolicy: 'network-only',
    variables: {
      checks: {
        disbursementsIds: selectedChecks || [],
      },
    },
  });
  const [printChecks, { loading: loadingPrintChecks }] = useMutation<
    PrintChecksMutation,
    PrintChecksMutationVariables
  >(PRINT_CHECKS);

  const { url, base64: { data: base64data = '', mimetype = '' } = {} } =
    data?.pdf || {};
  const dataUri = getDataUri(base64data, mimetype);

  const handleOnPrint = useCallback(async () => {
    try {
      await printChecks({
        variables: {
          input: {
            checkStartNumber: startCheckNumber,
            disbursementsIds: selectedChecks,
          },
        },
      });

      notification().info(
        t('modal.printChecksPreview.printPreview.notification.info')
      );

      onPrint();
    } catch (e) {
      traceError(e);
    }
  }, [onPrint, printChecks, selectedChecks, startCheckNumber, t]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <PdfViewer file={dataUri} />
      <ButtonGroup className={styles.buttons}>
        <Button onClick={onCancel} disabled={loadingPrintChecks}>
          <I18n id="modal.printChecksPreview.printPreview.cancel" />
        </Button>
        <Button
          link={url || ''}
          target="_blank"
          skin="primary"
          onClick={handleOnPrint}
          loading={loadingPrintChecks}
        >
          <I18n id="modal.printChecksPreview.printPreview.submit" />
        </Button>
      </ButtonGroup>
    </>
  );
}
