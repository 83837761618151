import { useCallback } from 'react';
import { Button, ButtonGroup, Text3 } from '@appclose/ui';
import { Modal } from '@appclose/core';

import { I18n, useIntl } from 'i18n';
import { openModal } from 'controllers/modal';
import { MATTER_MODAL } from 'constants/modals';

import { LeadMatterPopupModalPropsType } from './LeadMatterPopupModal.types';
import styles from './LeadMatterPopupModal.module.scss';

export default function LeadMatterPopupModal({
  contactId,
  onCancel,
}: LeadMatterPopupModalPropsType) {
  const { t } = useIntl();

  const onConfirm = useCallback(async () => {
    openModal(MATTER_MODAL, { contactId });

    onCancel();
  }, [contactId, onCancel]);

  return (
    <Modal title={t('modal.popup.lead.createMatter.title')} onClose={onCancel}>
      <Text3 offset={{ top: 20, bottom: 5 }} align="center">
        <I18n id="modal.popup.lead.createMatter.text" />
      </Text3>
      <ButtonGroup className={styles.submit}>
        <Button onClick={onCancel}>
          <I18n id="modal.popup.status.form.cancel" />
        </Button>
        <Button skin="primary" onClick={onConfirm}>
          <I18n id="modal.popup.lead.createMatter.confirm" />
        </Button>
      </ButtonGroup>
    </Modal>
  );
}
