import { gql } from '@apollo/client';

export const FETCH_QBO_LINK = gql`
  query FetchQboLink($redirect: String!) {
    link: getQboRedirectUrl(
      getQboRedirectUrlInput: { redirectRoute: $redirect }
    ) {
      url
    }
  }
`;

export const FETCH_QBO = gql`
  query FetchQBO {
    qbo: getQboIntegrationDetails {
      connected
      name
      email
      connectionError
      actualCompanyName
      expectedCompanyName
    }
  }
`;

export const DISCONNECT_QBO = gql`
  mutation DisconnectQbo {
    disconnectFromQbo {
      success
    }
  }
`;

export const ON_QBO_ENABLED = gql`
  subscription QboIntegrationEnabled {
    qboIntegrationEnabled {
      success
      name
      email
      firmId
    }
  }
`;

export const ON_QBO_DISABLED = gql`
  subscription QboIntegrationDisabled {
    qboIntegrationDisabled {
      firmId
    }
  }
`;
