import React, { useMemo } from 'react';
import debounce from 'lodash/debounce';
import { Grid } from '@appclose/ui';
import { Form, TextAreaFormField } from '@appclose/core';
import { MatterIcon, ContactsIcon, NoteIcon } from '@appclose/icons';

import MatterSelectFormField from 'components/fields/MatterSelectFormField';
import ContactSelectFormField from 'components/fields/ContactSelectFormField';
import { useIntl } from 'i18n';

import { TimerFormPropsType, TimerFormType } from './TimerForm.types';
import styles from './TimerForm.module.scss';

export default function TimerForm({ timer, onChange }: TimerFormPropsType) {
  const { t } = useIntl();
  const handleOnSubmit = useMemo(
    () =>
      debounce((values: TimerFormType) => {
        if (
          timer.description !== values.description ||
          timer.matter?.id !== values.matter?.id ||
          timer.contact?.id !== values.contact?.id
        ) {
          onChange(values);
        }
      }, 500),
    [timer, onChange]
  );

  return (
    <Form
      initialValues={timer}
      className={styles.timerForm}
      onSubmit={handleOnSubmit}
    >
      {({ submitForm, setFieldValue, values: { contact } }) => (
        <Grid columns="auto 1fr" gap={[16, 12]} align="center">
          <ContactsIcon />
          <ContactSelectFormField
            label=""
            showPlaceholder="always"
            className={styles.select}
            fieldClassName={styles.field}
            onChange={() => {
              setFieldValue('matter', undefined);
              submitForm();
            }}
            allowAddNew
          />
          <MatterIcon />
          <MatterSelectFormField
            label=""
            showPlaceholder="always"
            className={styles.select}
            fieldClassName={styles.field}
            onChange={(matter) => {
              if (matter?.contact && !contact) {
                setFieldValue('contact', matter.contact);
              }

              submitForm();
            }}
            filter={{ clientId: contact?.id }}
            allowAddNew
          />
          <NoteIcon />
          <TextAreaFormField
            name="description"
            placeholder={t('timeTracker.timers.form.field.description')}
            fieldClassName={styles.field}
            rows={2}
            onChange={submitForm}
          />
        </Grid>
      )}
    </Form>
  );
}
