import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useMutation, useQuery } from '@apollo/client';
import { dateManager, Loader } from '@appclose/core';

import { BankAccountClasses } from '__generated__/globalTypes';
import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { DUE_DAYS } from 'components/fields/DueDaysSelectFormField';
import { sanitizeAmount } from 'controllers/amount';
import { track } from 'controllers/analytics';
import { openModal } from 'controllers/modal';
import { isPlusPlan } from 'controllers/mode';
import notification from 'controllers/notification';
import { DemoEventNames, EntryTypes } from 'constants/analytics';
import { SERVER_DATE_FORMAT, SERVER_DATE_TIME_FORMAT } from 'constants/date';
import { INVOICE_DETAILS_MODAL } from 'constants/modals';
import useBankAccounts from 'hooks/useBankAccounts';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { useIntl } from 'i18n';
import useUpdateFiles from 'hooks/useUpdateFiles';
import { Entities } from 'constants/entities';

import {
  CreateInvoiceMutation,
  CreateInvoiceMutationVariables,
  FetchContactQuery,
  FetchContactQueryVariables,
  FetchInvoiceQuery,
  FetchInvoiceQueryVariables,
  FetchMatterQuery,
  FetchMatterQueryVariables,
  UpdateInvoiceMutation,
  UpdateInvoiceMutationVariables,
} from './__generated__/InvoiceModal.gql';
import InvoiceForm from './components/InvoiceForm';
import { InvoiceFormValuesType } from './components/InvoiceForm/InvoiceForm.types';
import {
  CREATE_INVOICE,
  FETCH_CONTACT,
  FETCH_INVOICE,
  FETCH_MATTER,
  UPDATE_INVOICE,
} from './InvoiceModal.gql';
import { InvoiceModalPropsType } from './InvoiceModal.types';
import styles from './InvoiceModal.module.scss';

export default function InvoiceModal({
  id,
  matterId,
  contactId,
  account,
  onClose,
}: InvoiceModalPropsType) {
  const { t } = useIntl();
  const isEdit = !!id;
  const title = isEdit
    ? t('modal.invoice.title.edit')
    : t('modal.invoice.title.add');
  const { onUpdateFiles } = useUpdateFiles(Entities.INVOICE);
  const {
    loading: loadingOnboardedBankAccounts,
    bankAccounts,
  } = useBankAccounts();
  const { loading: loadingContact, data: contactData } = useQuery<
    FetchContactQuery,
    FetchContactQueryVariables
  >(FETCH_CONTACT, {
    variables: { id: contactId as string },
    skip: !contactId || isEdit,
  });
  const { loading: loadingMatter, data: matterData } = useQuery<
    FetchMatterQuery,
    FetchMatterQueryVariables
  >(FETCH_MATTER, {
    variables: { id: matterId as string },
    skip: !matterId || isEdit,
  });
  const { loading, data } = useQuery<
    FetchInvoiceQuery,
    FetchInvoiceQueryVariables
  >(FETCH_INVOICE, {
    variables: { id: id as string },
    skip: !isEdit,
  });
  const [createInvoice] = useMutation<
    CreateInvoiceMutation,
    CreateInvoiceMutationVariables
  >(CREATE_INVOICE);
  const [updateInvoice] = useMutation<
    UpdateInvoiceMutation,
    UpdateInvoiceMutationVariables
  >(UPDATE_INVOICE);

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });

  const handleOnSubmit = useCallback(
    async ({
      contact,
      matter,
      matters,
      dueDays,
      total: expensesAndTimeEntriesTotal,
      amount: paymentRequest,
      scheduleRecurringPayments,
      scheduleSettings,
      schedulePlan,
      scheduleLoading,
      destinationAccount,
      timeEntries: formTimeEntries,
      expenses: formExpenses,
      flatFees: formFlatFees,
      invoiceNumber: formInvoiceNumber,
      files,
      createdAt,
      ...invoice
    }: InvoiceFormValuesType) => {
      const isInvoiceForOperating =
        destinationAccount === BankAccountClasses.OPERATING;
      const withDiscount = isInvoiceForOperating && isPlusPlan();
      const amount = sanitizeAmount(
        withDiscount ? expensesAndTimeEntriesTotal : paymentRequest
      );
      const timeEntries = isInvoiceForOperating
        ? formTimeEntries?.map(({ id }) => id)
        : undefined;
      const expenses = isInvoiceForOperating
        ? formExpenses?.map(({ id }) => id)
        : undefined;
      const flatFees = isInvoiceForOperating
        ? formFlatFees?.map(({ id }) => id)
        : undefined;
      const contactId = contact?.id as string;
      const invoiceNumber = formInvoiceNumber?.length
        ? formInvoiceNumber
        : undefined;

      let invoiceId = id;

      if (!isEdit) {
        const { data } = await createInvoice({
          variables: {
            invoice: {
              ...invoice,
              amount,
              destinationAccount,
              contactId,
              timeEntries,
              expenses,
              flatFees,
              invoiceNumber,
              matterId:
                destinationAccount === BankAccountClasses.TRUST
                  ? matter?.id
                  : undefined,
              scheduleSettings: scheduleRecurringPayments
                ? scheduleSettings
                : null,
            },
          },
        });

        notification().entityCreated(t('modal.invoice.notification.created'));

        track(DemoEventNames.DEMO_ENTRY_CREATED, {
          entry_type: EntryTypes.INVOICE,
        });

        invoiceId = data?.createInvoice.id;
      } else {
        await updateInvoice({
          variables: {
            invoice: {
              ...invoice,
              id: id as string,
              amount,
              contactId,
              timeEntries,
              expenses,
              flatFees,
              matterId:
                destinationAccount === BankAccountClasses.TRUST
                  ? matter?.id
                  : undefined,
              scheduleSettings: scheduleRecurringPayments
                ? scheduleSettings
                : null,
            },
          },
        });

        notification().entityUpdated(t('modal.invoice.notification.updated'));
      }

      if (invoiceId && files) {
        await onUpdateFiles({ entityId: invoiceId, ...files });
      }

      onClose();

      openModal(INVOICE_DETAILS_MODAL, {
        id: invoiceId,
      });
    },
    [id, t, onClose, onUpdateFiles, createInvoice, updateInvoice, isEdit]
  );

  const isLoading =
    loadingContact ||
    loadingMatter ||
    loading ||
    loadingOnboardedBankAccounts;

  const {
    balanceDue,
    discount,
    percentageDiscount,
    dueDate,
    scheduledPayments,
    destinationAccount,
    contact,
    matters,
    ...invoice
  } = {
    ...data?.invoice,
  };
  const dueDays = !isEdit ? DUE_DAYS['30_DAYS'] : DUE_DAYS.CUSTOM;

  let initialValues: InvoiceFormValuesType = {
    ...invoice,
    contact: contact || contactData?.contact || matterData?.matter?.contact,
    matter:
      (destinationAccount || account) === BankAccountClasses.TRUST
        ? matterData?.matter
        : undefined,
    matters:
      matters || (!!matterData?.matter ? [matterData!.matter] : undefined),
    destinationAccount:
      destinationAccount || account || BankAccountClasses.OPERATING,
    amount: sanitizeAmount(balanceDue),
    dueDays,
    dueDate: !isEdit
      ? dateManager()
          .parse()
          .add(parseInt(dueDays) || 0, 'days')
          .format(SERVER_DATE_TIME_FORMAT)
      : dateManager().format(dueDate, SERVER_DATE_TIME_FORMAT),
    percentageDiscount: percentageDiscount || false,
    discount: sanitizeAmount(discount),
    scheduleSettings: scheduledPayments?.settings
      ? {
          ...scheduledPayments?.settings,
          startDate: dateManager()
            .parse(scheduledPayments.settings.startDate)
            .format(SERVER_DATE_FORMAT),
        }
      : undefined,
    schedulePlan: scheduledPayments?.plan,
    scheduleRecurringPayments: !!scheduledPayments,
    scheduleLoading: false,
  };

  return (
    <ModalPage
      className={classNames({ [styles.modal]: isPlusPlan() })}
      onClose={onConfirmClose}
    >
      <ModalPageHeader>
        <ModalPageTitle>{title}</ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {isLoading ? (
          <Loader />
        ) : (
          <InvoiceForm
            initialValues={initialValues}
            isEdit={isEdit}
            bankAccounts={bankAccounts}
            onCancel={onConfirmClose}
            onSubmit={handleOnSubmit}
            onChange={onFormChange}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
