import { gql } from '@apollo/client';

import { LEDGER_NAME_FRAGMENT } from 'components/common/LedgerName';

export const NOT_PRINTED_DISBURSEMENTS_FIELD_FRAGMENT = gql`
  fragment NotPrintedDisbursementsFieldFragment on DisbursementType {
    id
    date
    status
    ledger {
      ...LedgerName
    }
    payee {
      ...LedgerName
    }
    amount
    type
  }
  ${LEDGER_NAME_FRAGMENT}
`;

export const FETCH_NOT_PRINTED_DISBURSEMENTS = gql`
  query FetchNotPrintedDisbursements {
    notPrintedDisbursements: listNotPrintedDisbursements {
      total
      items {
        ...NotPrintedDisbursementsFieldFragment
      }
    }
  }
  ${NOT_PRINTED_DISBURSEMENTS_FIELD_FRAGMENT}
`;
