import React from 'react';
import { SelectFormField } from '@appclose/core';

import { CONTACT_TYPE_OPTIONS } from 'constants/contacts';

import { ContactTypeSelectFormFieldPropsType } from './ContactTypeSelectFormField.types';

export default function ContactTypeSelectFormField({
  name = 'type',
  label = 'Contact type',
  options = CONTACT_TYPE_OPTIONS,
  ...rest
}: ContactTypeSelectFormFieldPropsType) {
  return (
    <SelectFormField name={name} label={label} options={options} {...rest} />
  );
}
