import { PermissionActions, PermissionResources } from 'constants/permissions';

export const DISBURSEMENT_ACTIONS_PERMISSIONS = {
  [PermissionResources.DISBURSEMENT]: {
    '*': { actions: [PermissionActions.READ] },
  },
  [PermissionResources.CHECK]: {
    '*': {
      actions: [PermissionActions.CREATE, PermissionActions.DELETE],
    },
  },
};
