import React from 'react';
import { Button, Text3 } from '@appclose/ui';
import { Fieldset } from '@appclose/core';

import { I18n } from 'i18n';

import { PrintingSampleFieldsetPropsType } from './PrintingSampleFieldset.types';

export default function PrintingSampleFieldset({
  onOpenSample,
  isOpenSampleDisabled,
  onOpenFineTuneAlignment,
  isOpenFineTuneAlignmentDisabled,
}: PrintingSampleFieldsetPropsType) {
  return (
    <Fieldset title="Printing sample">
      <Text3 offset={{ bottom: 20 }}>
        <I18n id="form.printingSampleFieldset.printSample.text" />
      </Text3>
      <Button onClick={onOpenSample} disabled={isOpenSampleDisabled}>
        <I18n id="form.printingSampleFieldset.printSample.button" />
      </Button>
      <Text3 offset={{ top: 32, bottom: 20 }}>
        <I18n id="form.printingSampleFieldset.fineTuneAlignment.text" />
      </Text3>
      <Button
        onClick={onOpenFineTuneAlignment}
        disabled={isOpenFineTuneAlignmentDisabled}
      >
        <I18n id="form.printingSampleFieldset.fineTuneAlignment.button" />
      </Button>
    </Fieldset>
  );
}
