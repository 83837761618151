import React from 'react';
import { FormGroup, StaticField, Amount, Fieldset } from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import Address from 'components/common/Address';
import LedgerName from 'components/common/LedgerName';
import Date from 'components/common/Date';
import TrustTransactionStatus from 'components/common/TrustTransactionStatus';
import EntityQboSyncStatus from 'components/common/EntityQboSyncStatus';
import usePaymentMethodsTypes from 'hooks/usePaymentMethodsTypes';
import { useIntl } from 'i18n';

import { CommonDisbursementDetailsPropsType } from '../../DisbursementDetailsModal.types';
import styles from '../../DisbursementDetailsModal.module.scss';

export default function VoidedDisbursementDetails({
  disbursement: {
    id,
    amount,
    status,
    date,
    payee,
    ledger,
    memo,
    paymentMethod,
    check,
    qboSyncState,
  },
  firm: { name, address },
}: CommonDisbursementDetailsPropsType) {
  const { t } = useIntl();
  const { getPaymentMethodsTypeLabel } = usePaymentMethodsTypes();

  return (
    <Fieldset
      title={t('modal.disbursementDetails.fieldset.disbursement.title')}
    >
      <FormGroup className={styles.details}>
        <FormGrid>
          <StaticField title={t('modal.disbursementDetails.fieldset.from')}>
            {name}
            {address && <Address addresses={[address]} />}
          </StaticField>
          <StaticField title={t('modal.disbursementDetails.fieldset.to')}>
            <LedgerName value={payee} />
            {payee?.__typename === 'ThirdPartyPayeeType' && payee?.address && (
              <Address addresses={[payee.address]} />
            )}
          </StaticField>
          <StaticField title={t('modal.disbursementDetails.voided.amount')}>
            <Amount value={amount} />
          </StaticField>
          <StaticField title={t('modal.disbursementDetails.payment.status')}>
            <TrustTransactionStatus status={status} />
          </StaticField>
          <StaticField title={t('modal.disbursementDetails.fieldset.ledger')}>
            <LedgerName value={ledger} />
          </StaticField>
        </FormGrid>
      </FormGroup>
      <FormGroup>
        <FormGrid>
          <StaticField title={t('modal.disbursementDetails.payment.method')}>
            {getPaymentMethodsTypeLabel(paymentMethod)}
          </StaticField>
          <StaticField
            title={t('modal.disbursementDetails.payment.checkNumber')}
          >
            {check?.number}
          </StaticField>
          <StaticField
            title={t('modal.disbursementDetails.payment.checkCreatedAt')}
          >
            <Date value={check?.createdAt} />
          </StaticField>
          <StaticField title={t('modal.disbursementDetails.voided.date')}>
            <Date value={date} />
          </StaticField>
        </FormGrid>
      </FormGroup>
      <FormGroup>
        <StaticField title={t('modal.disbursementDetails.fieldset.memo')}>
          {memo}
        </StaticField>
      </FormGroup>
      <FormGroup>
        <StaticField
          title={t('modal.disbursementDetails.fieldset.qboSyncStatus')}
        >
          <EntityQboSyncStatus
            entityId={id}
            qboSyncState={qboSyncState || undefined}
            entityType="disbursement"
          />
        </StaticField>
      </FormGroup>
    </Fieldset>
  );
}
