import React, { useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { Amount, StaticField } from '@appclose/core';

import { sanitizeAmount } from 'controllers/amount';
import { calcTotalByRate } from 'controllers/timer';
import { useIntl } from 'i18n';

import { TotalFieldPropsType } from './TotalField.types';

export default function TotalField({
  amount,
  billingRate,
  durationSec = 0,
  setFieldValue,
}: TotalFieldPropsType) {
  const { t } = useIntl();

  const updateTotal = useMemo(
    () =>
      debounce(
        ({
          billingRate,
          durationSec = 0,
          setFieldValue,
        }: Omit<TotalFieldPropsType, 'amount'>) => {
          setFieldValue(
            'amount',
            calcTotalByRate(sanitizeAmount(billingRate), durationSec)
          );
        },
        250
      ),
    []
  );

  useEffect(
    () =>
      updateTotal({
        billingRate,
        durationSec,
        setFieldValue,
      }),
    [billingRate, durationSec, setFieldValue, updateTotal]
  );

  return (
    <StaticField title={t('modal.timeEntry.form.fieldset.info.total')}>
      <Amount value={sanitizeAmount(amount)} />
    </StaticField>
  );
}
