import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { traceError, usePopupModal } from '@appclose/core';

import { I18n, useIntl } from 'i18n';

import { PaymentMethodsTypes } from '__generated__/globalTypes';
import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { DemoEventNames, EntryTypes } from 'constants/analytics';
import { PAYMENT_DETAILS_MODAL } from 'constants/modals';
import { track } from 'controllers/analytics';
import notification from 'controllers/notification';
import { openModal } from 'controllers/modal';

import ConfirmPaymentIntentModal from 'components/modals/pages/ConfirmPaymentIntentModal';
import PaymentIntentSuccessPopupModal from 'components/modals/popups/PaymentIntentSuccessPopupModal';

import useCloseConfirm from 'hooks/useCloseConfirm';

import {
  CreatePaymentMutation,
  CreatePaymentMutationVariables,
  CreatePaymentIntentMutation,
  CreatePaymentIntentMutationVariables,
} from './__generated__/ReceivePaymentModal.gql';
import { ReceivePaymentModalPropsType } from './ReceivePaymentModal.types';

import { CREATE_PAYMENT, CREATE_PAYMENT_INTENT } from './ReceivePaymentModal.gql';

import { ReceivePaymentFormValuesType } from './components/PaymentInfoFormsBlock/PaymentInfoFormsBlock.types';
import PaymentInfoFormsBlock from './components/PaymentInfoFormsBlock';


export default function ReceivePaymentModal({
  invoiceId,
  matterId,
  contactId,
  onClose,
}: ReceivePaymentModalPropsType) {
  const { t } = useIntl();

  const { openPopupModal: openConfirmPaymentModal } = usePopupModal(
    ConfirmPaymentIntentModal
  );

  const { openPopupModal: openSuccessModal } = usePopupModal(
    PaymentIntentSuccessPopupModal
  );

  const [createPayment] = useMutation<
    CreatePaymentMutation,
    CreatePaymentMutationVariables
  >(CREATE_PAYMENT);

  const [createPaymentIntent] = useMutation<
    CreatePaymentIntentMutation,
    CreatePaymentIntentMutationVariables
  >(CREATE_PAYMENT_INTENT);
 
  const { onConfirmClose, onFormChange } = useCloseConfirm({ onClose });

  const handleSubmitPaymentIntent = useCallback(async (values: ReceivePaymentFormValuesType) => {    
    try {
      const { data } = await createPaymentIntent({
        variables: {
          paymentIntent: {
            invoiceIds: (values.invoices || []).map(({ id }) => id),
            matterId: values.matter?.id,
            amount: values.amount || 0,
            paymentMethod: values.paymentMethod || PaymentMethodsTypes.CC,
            internalMemo: values.internalMemo,
          },
        }
      });

      const { clientSecret, stripeAccountId } = data?.createPaymentIntent || {};

      openConfirmPaymentModal({
        clientSecret: clientSecret || '',
        stripeAccountId: stripeAccountId || '',
        onSuccess: () => {
          onClose();
          openSuccessModal();
        },
      });
    } catch (error: any) {
      notification().error(error);
      traceError(error);
    }
  }, [createPaymentIntent, openConfirmPaymentModal, openSuccessModal, onClose]);

  const handleSubmitPayment = useCallback(async (values: ReceivePaymentFormValuesType) => {
    try {
      const { paymentMethod = PaymentMethodsTypes.CASH } = values;
      const { data } = await createPayment({
        variables: {
          payment: {
            invoices: (values.invoices || []).map(({ id }) => id),
            matterId: values.matter?.id,
            amount: values.amount || 0,
            paymentMethod: paymentMethod,
            internalMemo: values.internalMemo,
            paymentDate: values.paymentDate,
          },
        }
      });

      const { id } = data?.createPayment || {};

      notification().entityCreated(
        t('modal.receivePayment.notification.paymentProcessed')
      );

      track(DemoEventNames.DEMO_ENTRY_CREATED, {
        entry_type: EntryTypes.RECEIVE_PAYMENT,
      });

      onClose();

      openModal(PAYMENT_DETAILS_MODAL, { id });
    } catch (error: any) {
      notification().error(error);
      traceError(error);
    }
  }, [createPayment, onClose, t])

  const handleSubmit = useCallback((values: ReceivePaymentFormValuesType) => {
    const { paymentMethod } = values;

    if ([PaymentMethodsTypes.CC, PaymentMethodsTypes.ACH].includes(paymentMethod!)) {
      return handleSubmitPaymentIntent(values)
    } else {
      return handleSubmitPayment(values);
    }
  }, [handleSubmitPaymentIntent, handleSubmitPayment]);

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.receivePayment.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        <PaymentInfoFormsBlock
          invoiceId={invoiceId}
          matterId={matterId}
          contactId={contactId}
          onSubmit={handleSubmit}
          onFormChange={onFormChange}
          onClose={onConfirmClose}
        />
      </ModalPageContent>
    </ModalPage>
  );
}
