import React from 'react';
import { FormGroup, StaticField, Fieldset } from '@appclose/core';

import { CheckPrintStatuses } from '__generated__/globalTypes';
import FormGrid from 'components/common/FormGrid';
import Address from 'components/common/Address';
import LedgerName from 'components/common/LedgerName';
import Date from 'components/common/Date';
import Check from 'components/common/Check';
import EntityQboSyncStatus from 'components/common/EntityQboSyncStatus';
import { PermissionGuard } from 'components/common/PermissionGuard';
import { useIntl } from 'i18n';
import { PermissionActions, PermissionResources } from 'constants/permissions';

import { CommonDisbursementDetailsPropsType } from '../../DisbursementDetailsModal.types';
import styles from '../../DisbursementDetailsModal.module.scss';
import PaymentMethodDetails from '../PaymentMethodDetails';
import PaymentDetails from '../PaymentDetails';

export default function ThirdPartyDisbursementDetails({
  disbursement: {
    id,
    amount,
    status,
    date,
    payee,
    ledger,
    memo,
    paymentMethod,
    check,
    qboSyncState,
  },
  firm: { name, address },
}: CommonDisbursementDetailsPropsType) {
  const { t } = useIntl();

  const isCheckPrinted = [
    CheckPrintStatuses.PRINTED,
    CheckPrintStatuses.QBO_PRINTED,
  ].includes(check?.status as CheckPrintStatuses);
  const checkNumber = check?.number;

  return (
    <>
      <Fieldset
        title={t('modal.disbursementDetails.fieldset.disbursement.title')}
      >
        <FormGroup className={styles.details}>
          <FormGrid>
            <StaticField title={t('modal.disbursementDetails.fieldset.from')}>
              {name}
              {address && <Address addresses={[address]} />}
            </StaticField>
            <StaticField title={t('modal.disbursementDetails.fieldset.to')}>
              <LedgerName value={payee} />
              {payee?.__typename === 'ThirdPartyPayeeType' && payee?.address ? (
                <Address addresses={[payee.address]} />
              ) : (
                ''
              )}
            </StaticField>
            <PaymentDetails
              amount={amount}
              status={status}
              checkPrintStatus={check?.status}
            />
            <StaticField title={t('modal.disbursementDetails.fieldset.ledger')}>
              <LedgerName value={ledger} />
            </StaticField>
          </FormGrid>
        </FormGroup>
        <FormGroup>
          <FormGrid>
            <PaymentMethodDetails
              paymentMethod={paymentMethod}
              checkNumber={checkNumber}
            />
            <StaticField title={t('modal.disbursementDetails.fieldset.date')}>
              <Date value={date} />
            </StaticField>
          </FormGrid>
        </FormGroup>
        <FormGroup>
          <StaticField title={t('modal.disbursementDetails.fieldset.memo')}>
            {memo}
          </StaticField>
        </FormGroup>
        <FormGroup>
          <StaticField
            title={t('modal.disbursementDetails.fieldset.qboSyncStatus')}
          >
            <EntityQboSyncStatus
              entityId={id}
              qboSyncState={qboSyncState || undefined}
              entityType="disbursement"
            />
          </StaticField>
        </FormGroup>
      </Fieldset>
      {isCheckPrinted && checkNumber && (
        <PermissionGuard
          resource={PermissionResources.BANK_ACCOUNT}
          action={PermissionActions.LIST}
        >
          <Check
            checkNumber={checkNumber}
            payee={payee?.name}
            date={date}
            amount={amount}
            memo={memo || ''}
          />
        </PermissionGuard>
      )}
    </>
  );
}
