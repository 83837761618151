import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Loader } from '@appclose/core';
import { DownloadIcon, PrintIcon, VoidIcon } from '@appclose/icons';

import {
  TrustTransactionTypes,
  TrustTransactionStatuses,
} from '__generated__/globalTypes';
import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import ActionsPanel, {
  ActionsPanelButton,
} from 'components/common/ActionsPanel';
import { PermissionsGuard } from 'components/common/PermissionsGuard';
import { PermissionGuard } from 'components/common/PermissionGuard';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import usePrintChecks from 'hooks/usePrintChecks';
import { openVoidDisbursementConfirmAction } from 'store/actions';
import useDisbursementActions from 'hooks/useDisbursementActions';
import { useIntl } from 'i18n';

import { DisbursementDetailsModalPropsType } from './DisbursementDetailsModal.types';
import { DISBURSEMENT_ACTIONS_PERMISSIONS } from './DisbursementDetailsModal.constants';
import { FETCH_FIRM, FETCH_DISBURSEMENT } from './DisbursementDetailsModal.gql';
import {
  FetchFirmQuery,
  FetchFirmQueryVariables,
  FetchDisbursementQuery,
  FetchDisbursementQueryVariables,
} from './__generated__/DisbursementDetailsModal.gql';
import OperatingDisbursementDetails from './components/OperatingDisbursementDetails';
import ContactDisbursementDetails from './components/ContactDisbursementDetails';
import ThirdPartyDisbursementDetails from './components/ThirdPartyDisbursementDetails';
import OtoDisbursementDetails from './components/OtoDisbursementDetails';
import VoidedDisbursementDetails from './components/VoidedDisbursementDetails';

export default function DisbursementDetailsModal({
  id,
  onClose,
}: DisbursementDetailsModalPropsType) {
  const { t } = useIntl();
  const { openPrintChecks } = usePrintChecks();
  const dispatch = useDispatch();

  const { loading: firmLoading, data: firmData } = useQuery<
    FetchFirmQuery,
    FetchFirmQueryVariables
  >(FETCH_FIRM);

  const { loading: disbursementLoading, data: disbursementData } = useQuery<
    FetchDisbursementQuery,
    FetchDisbursementQueryVariables
  >(FETCH_DISBURSEMENT, {
    variables: {
      id,
    },
  });

  const loading = firmLoading || disbursementLoading;

  const firm = firmData?.firm;
  const disbursement = disbursementData?.disbursement;

  const {
    downloadDisbursementPdf,
    downloadDisbursementPdfLoading,
  } = useDisbursementActions({ id, type: disbursement?.type });

  const isOtoDisbursement = disbursement?.type === TrustTransactionTypes.OTO;
  const isContactDisbursement =
    disbursement?.type === TrustTransactionTypes.DISBURSEMENT_CONTACT;
  const isOperatingDisbursement =
    disbursement?.type === TrustTransactionTypes.DISBURSEMENT_OPERATING;
  const isThirdPartyDisbursement =
    disbursement?.type === TrustTransactionTypes.DISBURSEMENT_THIRD_PARTY;

  const handlePrintClick = useCallback(
    () => openPrintChecks(disbursement?.id),
    [disbursement, openPrintChecks]
  );

  const isCleared = disbursement?.status === TrustTransactionStatuses.CLEARED;
  const isVoided = disbursement?.status === TrustTransactionStatuses.VOIDED;
  const checkNumber = disbursement?.check?.number;
  const checkStatus = disbursement?.check?.status;

  const handleVoidDisbursement = useCallback(() => {
    dispatch(
      openVoidDisbursementConfirmAction({
        id,
        checkNumber,
        onSuccess() {
          onClose();
        },
      })
    );
  }, [dispatch, onClose, id, checkNumber]);

  let DetailsComponent;

  switch (true) {
    case isVoided:
      DetailsComponent = VoidedDisbursementDetails;
      break;
    case isOperatingDisbursement:
      DetailsComponent = OperatingDisbursementDetails;
      break;
    case isContactDisbursement:
      DetailsComponent = ContactDisbursementDetails;
      break;
    case isThirdPartyDisbursement:
      DetailsComponent = ThirdPartyDisbursementDetails;
      break;
    case isOtoDisbursement:
      DetailsComponent = OtoDisbursementDetails;
      break;
    default:
      DetailsComponent = VoidedDisbursementDetails;
  }

  return (
    <ModalPage onClose={onClose}>
      <ModalPageHeader>
        {!loading && (
          <ModalPageTitle>
            {isOtoDisbursement
              ? t('modal.disbursementDetails.title.oto')
              : t('modal.disbursementDetails.title.disbursement', {
                  checkNumber: checkNumber ? `#${checkNumber}` : '',
                })}
          </ModalPageTitle>
        )}
      </ModalPageHeader>
      <ModalPageContent>
        {loading ? (
          <Loader />
        ) : (
          <>
            {!isVoided && (
              <PermissionsGuard allow={DISBURSEMENT_ACTIONS_PERMISSIONS}>
                <ActionsPanel>
                  <PermissionGuard
                    resource={PermissionResources.DISBURSEMENT}
                    action={PermissionActions.READ}
                  >
                    <ActionsPanelButton
                      skin="secondary"
                      icon={<DownloadIcon />}
                      title={t('modal.disbursementDetails.actions.downloadPdf')}
                      onClick={downloadDisbursementPdf}
                      loading={downloadDisbursementPdfLoading}
                    />
                  </PermissionGuard>
                  {!isOtoDisbursement &&
                    !isCleared &&
                    (!checkStatus ? (
                      <PermissionGuard
                        resource={PermissionResources.CHECK}
                        action={PermissionActions.CREATE}
                      >
                        <ActionsPanelButton
                          skin="yellow"
                          icon={<PrintIcon />}
                          title={t(
                            'modal.disbursementDetails.actions.printCheck'
                          )}
                          onClick={handlePrintClick}
                        />
                      </PermissionGuard>
                    ) : (
                      <PermissionGuard
                        resource={PermissionResources.CHECK}
                        action={PermissionActions.DELETE}
                      >
                        <ActionsPanelButton
                          skin="void"
                          icon={<VoidIcon />}
                          title={t(
                            'modal.disbursementDetails.actions.voidPrintedCheck'
                          )}
                          onClick={handleVoidDisbursement}
                        />
                      </PermissionGuard>
                    ))}
                </ActionsPanel>
              </PermissionsGuard>
            )}
            {firm && disbursement && (
              <DetailsComponent disbursement={disbursement} firm={firm} />
            )}
          </>
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
