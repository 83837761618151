import { gql } from '@apollo/client';

export const FETCH_RECONCILIATION_DETAILED_PDF = gql`
  query getReconciliationDetailedPdf($id: ID!) {
    pdf: getReconciliationDetailedPDF(id: $id) {
      url
    }
  }
`;

export const FETCH_RECONCILIATION_3RD_WAY_PDF = gql`
  query getReconciliation3rdWayPdf($id: ID!) {
    pdf: getReconciliation3WayPDF(id: $id) {
      url
    }
  }
`;
