import { UnclearedTransactionTypes } from '__generated__/globalTypes';
import { sanitizeAmount } from 'controllers/amount';

import { SetupInitialBalancesType } from './TrustSetupBalancesStepModal.types';

export function getUnclearedBalances(
  ledgerBalances: SetupInitialBalancesType['ledgerBalances']
): {
  unclearedDepositsBalance: number;
  unclearedDisbursementsBalance: number;
} {
  if (!ledgerBalances) {
    return {
      unclearedDepositsBalance: 0,
      unclearedDisbursementsBalance: 0,
    };
  }

  return ledgerBalances.reduce(
    (acc, { unclearedTransactions }) => ({
      unclearedDepositsBalance:
        acc.unclearedDepositsBalance +
        (unclearedTransactions || [])
          .filter(({ type }) => type === UnclearedTransactionTypes.DEPOSIT)
          .reduce((acc, { amount }) => acc + amount, 0) *
          -1,
      unclearedDisbursementsBalance:
        acc.unclearedDisbursementsBalance +
        (unclearedTransactions || [])
          .filter(({ type }) => type === UnclearedTransactionTypes.DISBURSEMENT)
          .reduce((acc, { amount }) => acc - amount, 0) *
          -1,
    }),
    {
      unclearedDepositsBalance: 0,
      unclearedDisbursementsBalance: 0,
    }
  );
}

export function getTotalLedgersBalance(
  ledgerBalances: SetupInitialBalancesType['ledgerBalances']
): number {
  if (!ledgerBalances) {
    return 0;
  }

  return ledgerBalances.reduce((acc, { balance }) => acc + balance, 0);
}

export function getEndingBankStatementBalance(
  ledgerBalances: SetupInitialBalancesType['ledgerBalances']
): number {
  if (!ledgerBalances) {
    return 0;
  }

  return ledgerBalances?.reduce((acc, { balance, unclearedTransactions }) => {
    const unclearedBalance = (unclearedTransactions || []).reduce(
      (acc, { type, amount }) =>
        // reverting the uncleared transactions history on ledger balance
        // to get "cleared" transaction ledger balance to match it with
        // bank statement ending balance entered by user
        type === UnclearedTransactionTypes.DEPOSIT
          ? acc - amount
          : acc + amount,
      0
    );

    return sanitizeAmount(acc + balance + unclearedBalance);
  }, 0);
}
