import { gql } from '@apollo/client';

export const FETCH_CONTACTS_WITH_MATTERS = gql`
  query FetchContactsWithMatters($input: ListContactsWithMattersInput) {
    contactsWithMatters: listContactsWithMatters(
      listContactsWithMattersInput: $input
    ) {
      items {
        id
        name
        matters {
          id
          matterNumber
          name
        }
      }
    }
  }
`;
