import React from 'react';
import { Button, Flex } from '@appclose/ui';
import { Fieldset } from '@appclose/core';

import { ReactComponent as SuccessIcon } from 'assets/success.svg';
import { I18n, useIntl } from 'i18n';

import { TrustSetupBalancesResultPropsType } from './TrustSetupBalancesResult.types';
import styles from './TrustSetupBalancesResult.module.scss';

export default function TrustSetupBalancesResult({
  onClose,
}: TrustSetupBalancesResultPropsType) {
  const { t } = useIntl();

  return (
    <Fieldset
      title={t(
        'modal.trustSetupBalancesStep.trustSetupBalancesResult.fieldset.initialSetupResult'
      )}
    >
      <Flex direction="column" alignItems="center">
        <SuccessIcon className={styles.successIcon} />
        <p className={styles.congratulations}>
          <I18n id="modal.trustSetupBalancesStep.trustSetupBalancesResult.congratulations" />
        </p>
        <p className={styles.congratulationsDescription}>
          <I18n id="modal.trustSetupBalancesStep.trustSetupBalancesResult.successfullySetup" />
        </p>
        <Button onClick={onClose}>
          <I18n id="modal.trustSetupBalancesStep.trustSetupBalancesResult.button.close" />
        </Button>
      </Flex>
    </Fieldset>
  );
}
