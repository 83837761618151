import React from 'react';
import gqlClient from 'gql';
import { history, openConfirmAction } from '@appclose/core';

import EntityActionConfirmContent from 'components/common/EntityActionConfirmContent';
import notification from 'controllers/notification';
import { i18nMessage } from 'i18n';
import { CONTACTS_ROUTE } from 'constants/routes';

import { DELETE_CONTACT } from './contact.gql';
import {
  DeleteContactMutation,
  DeleteContactMutationVariables,
} from './__generated__/contact.gql';

export const openDeleteContactConfirmAction = ({
  id,
  name,
}: {
  id: string;
  name: string;
}) =>
  openConfirmAction({
    name: i18nMessage('store.contact.deleteContactConfirm.name'),
    content: (
      <EntityActionConfirmContent
        title={i18nMessage('store.contact.deleteContactConfirm.title')}
        name={name}
        description={i18nMessage(
          'store.contact.deleteContactConfirm.description'
        )}
      />
    ),
    okButtonTitle: i18nMessage('store.contact.deleteContactConfirm.okButton'),
    cancelButtonTitle: i18nMessage(
      'store.contact.deleteContactConfirm.cancelButton'
    ),
    okButtonSkin: 'primary',
    async onConfirm() {
      await gqlClient.mutate<
        DeleteContactMutation,
        DeleteContactMutationVariables
      >({ mutation: DELETE_CONTACT, variables: { id } });

      notification().entityDeleted(
        i18nMessage('store.contact.deleteContactConfirm.notify')
      );

      history.replace(CONTACTS_ROUTE);
    },
  });
