import React from 'react';
import { AddButton } from '@appclose/ui';
import classnames from 'classnames';

import { checkEsqPermission } from 'controllers/permission';

import { AddNewButtonPropsType } from './AddNewButton.types';
import styles from './AddNewButton.module.scss';

export default function AddNewButton({
  className,
  onClick,
  disabled,
  loading,
  ...permissions
}: AddNewButtonPropsType) {
  if (!checkEsqPermission(permissions)) {
    return null;
  }

  return (
    <AddButton
      iconSize={10}
      size={28}
      className={classnames(styles.addNewButton, className)}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
    />
  );
}
