import { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { PRINT_CHECKS_MODAL, PRINTING_OPTIONS_MODAL } from 'constants/modals';
import { openModal } from 'controllers/modal';
import { SavePrintingChecksSettingsInput } from '__generated__/globalTypes';

import {
  FETCH_FIRM_PRINTING_CHECKS_SETTINGS,
  UPDATE_FIRM_PRINTING_CHECKS_SETTINGS,
} from './usePrintChecks.gql';
import {
  FetchFirmPrintingChecksSettingsQuery,
  FetchFirmPrintingChecksSettingsQueryVariables,
  UpdateFirmPrintingChecksSettingsMutation,
  UpdateFirmPrintingChecksSettingsMutationVariables,
} from './__generated__/usePrintChecks.gql';
import { UsePrintChecksPropsType } from './usePrintChecks.types';

export default function usePrintChecks({
  skipLoading = false,
}: UsePrintChecksPropsType = {}) {
  const { loading, data } = useQuery<
    FetchFirmPrintingChecksSettingsQuery,
    FetchFirmPrintingChecksSettingsQueryVariables
  >(FETCH_FIRM_PRINTING_CHECKS_SETTINGS, {
    skip: skipLoading,
  });
  const [
    updatePrintingChecksSettings,
    { loading: savePrintingChecksSettingsLoading },
  ] = useMutation<
    UpdateFirmPrintingChecksSettingsMutation,
    UpdateFirmPrintingChecksSettingsMutationVariables
  >(UPDATE_FIRM_PRINTING_CHECKS_SETTINGS);

  const firm = data?.firm;
  const mode = firm?.printingChecksSettings?.mode;
  const printChecksOffset = firm?.printingChecksSettings?.offset as
    | [number, number]
    | undefined;

  const openPrintChecks = useCallback(
    (id?: string) => {
      if (!firm) {
        return;
      }

      openModal(
        firm.printingChecksSettings
          ? PRINT_CHECKS_MODAL
          : PRINTING_OPTIONS_MODAL,
        { id }
      );
    },
    [firm]
  );

  const savePrintingChecksSettings = useCallback(
    async (input: SavePrintingChecksSettingsInput) => {
      await updatePrintingChecksSettings({ variables: { input } });
    },
    [updatePrintingChecksSettings]
  );

  return {
    loadingPrintChecksSettings: loading,
    mode,
    printChecksOffset,
    openPrintChecks,
    savePrintingChecksSettings,
    savePrintingChecksSettingsLoading,
  };
}
