import React from 'react';
import set from 'lodash/set';
import { Form, FormGroup, Fieldset } from '@appclose/core';
import { Text4 } from '@appclose/ui';

import FormGrid from 'components/common/FormGrid';
import ContactSelectFormField, {
  SelectContactType,
} from 'components/fields/ContactSelectFormField';
import ContactMatterSelectFormField, {
  ContactMatterSelectFormFieldOptionValueType,
} from 'components/fields/ContactMatterSelectFormField';
import TrustAccountBalance from 'components/common/TrustAccountBalance';
import CommonFormContent from '../CommonFormContent';

import { isPlusPlan } from 'controllers/mode';
import { I18n, useIntl } from 'i18n';

import {
  ReceivePaymentFormPropsType,
  ReceivePaymentFormValuesType,
} from '../../PaymentInfoFormsBlock.types';
import { ReceivePaymentFormSchema } from '../../PaymentInfoFormsBlock.schema';

export default function FromTrustToOperatingForm({
  initialValues,
  onSubmit,
  onCancel,
  onChange,
  setContact,
  setMatter,
}: ReceivePaymentFormPropsType) {
  const { t } = useIntl();
  const fieldsetTitle = isPlusPlan()
    ? t('modal.receivePayment.form.fieldset.contactAndMatter')
    : t('modal.receivePayment.form.fieldset.contact');

  return (
    <Form<ReceivePaymentFormValuesType>
      initialValues={initialValues}
      validationSchema={ReceivePaymentFormSchema}
      onSubmit={onSubmit}
      onChange={onChange}
    >
      {({ values: { contact, matter }, setValues }) => {
        const onContactMatterChange = (
          contactMatter?: ContactMatterSelectFormFieldOptionValueType
        ) => {
          setValues((values) => set(values, 'invoices', []));
          setMatter(contactMatter?.matter);
          setContact(contactMatter?.contact);
        };
        const onContactChange = (contact?: SelectContactType) => {
          setValues((values) => set(values, 'invoices', []));
          setContact(contact);
        };

        return (
          <>
            <Fieldset title={fieldsetTitle}>
              <FormGroup>
                {isPlusPlan() ? (
                  <ContactMatterSelectFormField
                    showTrustLedgerBalance
                    onlyPositiveTrustBalance
                    onChange={onContactMatterChange}
                    label={t(
                      'modal.receivePayment.form.contactMatterSelectLabel'
                    )}
                    tooltip={
                      <>
                        <Text4>
                          <I18n id="modal.invoice.form.field.contactMatter.tooltip.paragraph.1" />
                        </Text4>
                        <Text4 offset={{ top: 10 }}>
                          <I18n id="modal.invoice.form.field.contactMatter.tooltip.paragraph.2" />
                        </Text4>
                      </>
                    }
                  />
                ) : (
                  <FormGrid>
                    <ContactSelectFormField
                      onChange={onContactChange}
                      showLabel="always"
                    />
                  </FormGrid>
                )}
              </FormGroup>
              <FormGroup>
                <FormGrid>
                  <TrustAccountBalance
                    contactId={contact?.id}
                    matterId={matter?.id}
                  />
                </FormGrid>
              </FormGroup>
            </Fieldset>
            <CommonFormContent onCancel={onCancel} />
          </>
        );
      }}
    </Form>
  );
}
