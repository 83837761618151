import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Amount } from '@appclose/core';
import { TrustBoldIcon } from '@appclose/icons';
import variables from '@appclose/ui/src/scss/_export.module.scss';

import FinancialBlock from 'components/common/FinancialBlock';
import { sanitizeAmount } from 'controllers/amount';
import useOnFinancialTilesUpdated from 'hooks/useOnFinancialTilesUpdated';
import { useIntl } from 'i18n';

import {
  FetchTrustBalanceQuery,
  FetchTrustBalanceQueryVariables,
} from './__generated__/TrustAccountBalance.gql';
import { FETCH_TRUST_BALANCE } from './TrustAccountBalance.gql';
import { TrustAccountBalancePropsType } from './TrustAccountBalance.types';
import styles from './TrustAccountBalance.module.scss';

export default function TrustAccountBalance({
  contactId,
  matterId,
}: TrustAccountBalancePropsType) {
  const { t } = useIntl();
  const [fetchTrustBalance, { loading, data, refetch }] = useLazyQuery<
    FetchTrustBalanceQuery,
    FetchTrustBalanceQueryVariables
  >(FETCH_TRUST_BALANCE, {
    fetchPolicy: 'network-only',
  });
  const balance = sanitizeAmount(data?.statistic.balance);

  useEffect(() => {
    fetchTrustBalance({
      variables: {
        input: { contactId, matterId },
      },
    });
  }, [fetchTrustBalance, contactId, matterId]);

  useOnFinancialTilesUpdated(refetch);

  return (
    <FinancialBlock
      className={styles.balance}
      theme={{
        value: styles.value,
      }}
      title={t('trustAccountBalance.title')}
      icon={{
        bgColor: variables.colorSystemPrimary,
        content: <TrustBoldIcon width={28} height={28} fill="#fff" />,
      }}
      value={!loading && <Amount value={balance} />}
      loading={loading}
    />
  );
}
