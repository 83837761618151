import React from 'react';
import { Button, ButtonGroup, Flex, Offset, Note } from '@appclose/ui';
import {
  Form,
  FormGroup,
  FormSubmissionError,
  InputFormField,
} from '@appclose/core';

import { I18n, useIntl } from 'i18n';

import { TimeEntryActivityFormSchema } from './TimeEntryActivityForm.schema';
import { TimeEntryActivityFormPropsType } from './TimeEntryActivityForm.types';
import styles from './TimeEntryActivityForm.module.scss';

export default function TimeEntryActivityForm({
  initialValues = {} as any,
  withNote,
  isEdit,
  onSubmit,
  onCancel,
}: TimeEntryActivityFormPropsType) {
  const { t } = useIntl();

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={TimeEntryActivityFormSchema}
    >
      {({ isSubmitting }) => {
        return (
          <div className={styles.formContent}>
            <FormGroup>
              <InputFormField
                name="name"
                label={t('modal.popup.timeEntryActivity.form.name')}
              />
            </FormGroup>
            {withNote && (
              <Offset top="xl">
                <Note theme="important">
                  <I18n id="modal.popup.timeEntryActivity.form.note" />
                </Note>
              </Offset>
            )}
            <Offset top="xs">
              <FormSubmissionError />
            </Offset>
            <Offset top="xl">
              <Flex justify="center">
                <ButtonGroup>
                  <Button onClick={onCancel}>
                    <I18n id="modal.popup.timeEntryActivity.form.cancel" />
                  </Button>
                  <Button loading={isSubmitting} type="submit" skin="primary">
                    <I18n
                      id={
                        isEdit
                          ? 'modal.popup.timeEntryActivity.form.update'
                          : 'modal.popup.timeEntryActivity.form.create'
                      }
                    />
                  </Button>
                </ButtonGroup>
              </Flex>
            </Offset>
          </div>
        );
      }}
    </Form>
  );
}
