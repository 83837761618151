import React, { useState, useCallback, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { traceError } from '@appclose/core';

import EmailPreview from 'components/common/EmailPreview';
import { ModalSendEmailPage } from 'components/common/ModalSendEmailPage';
import { getContactName, getEntityPrimaryContact } from 'controllers/contact';
import SendEmailForm, {
  SendEmailFormActionsType,
  SendEmailFormValuesType,
} from 'components/form/SendEmailForm';
import { isDemoBuild } from 'controllers/demo';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { useIntl } from 'i18n';
import useListFiles from 'hooks/useListFiles';
import { Entities } from 'constants/entities';

import {
  FetchInvoiceQuery,
  FetchInvoiceQueryVariables,
  SendVoidInvoiceMutation,
  SendVoidInvoiceMutationVariables,
} from './__generated__/SendVoidInvoiceModal.gql';
import { FETCH_INVOICE, SEND_VOID_INVOICE } from './SendVoidInvoiceModal.gql';
import { SendVoidInvoiceModalPropsType } from './SendVoidInvoiceModal.types';
import { FormDataType } from '@appclose/core';

export default function SendVoidInvoiceModal({
  id,
  onClose,
}: SendVoidInvoiceModalPropsType) {
  const { t } = useIntl();
  const { filesLoading, files } = useListFiles({
    entityType: Entities.INVOICE,
    input: { filter: { entityIds: [id], strictFilter: false } },
  });
  const [sendEmailFormValues, setSendEmailFormValues] = useState<
    SendEmailFormValuesType
  >();
  const { loading, data } = useQuery<
    FetchInvoiceQuery,
    FetchInvoiceQueryVariables
  >(FETCH_INVOICE, { variables: { id: id as string } });
  const [sendVoidInvoice] = useMutation<
    SendVoidInvoiceMutation,
    SendVoidInvoiceMutationVariables
  >(SEND_VOID_INVOICE);
  const invoice = data?.invoice;
  const firm = data?.firm;
  const contact = getEntityPrimaryContact(invoice);
  const initialValues = useMemo<SendEmailFormValuesType>(
    () => ({
      id,
      to: invoice?.contact as any,
      subject: t('modal.sendVoidInvoice.subject', {
        number: invoice?.invoiceNumber,
        firm: firm?.name,
      }),
      text: t('modal.sendVoidInvoice.message', {
        contact: contact && getContactName(contact),
      }),
      from: firm?.name || '',
      contactId: contact?.id || '',
      files: files?.items,
      includeAccountStatementLink: true,
    }),
    [id, invoice, firm, t, contact, files]
  );
  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });

  const handleOnSubmit = useCallback(
    async (
      { files, from, to, ...values }: SendEmailFormValuesType,
      { setSubmitting }: SendEmailFormActionsType
    ) => {
      try {
        if (!isDemoBuild()) {
          await sendVoidInvoice({
            variables: {
              sendVoidInvoiceInput: {
                ...values,
                files: files?.map(({ id }) => id) as string[],
              },
            },
          });
        }

        onClose();

        notification().emailSent();
      } catch (e) {
        traceError(e);
        setSubmitting(false);
      }
    },
    [onClose, sendVoidInvoice]
  );

  const handleFormChange = useCallback(
    (data: FormDataType<SendEmailFormValuesType>) => {
      onFormChange(data);
      setSendEmailFormValues(data.values);
    },
    [onFormChange]
  );

  return (
    <ModalSendEmailPage
      title={t('modal.sendVoidInvoice.title')}
      loading={loading || filesLoading}
      onClose={onConfirmClose}
    >
      <SendEmailForm
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        onCancel={onConfirmClose}
        onChange={handleFormChange}
        sendButtonText={t('modal.sendVoidInvoice.button.sendNotice')}
      />
      <EmailPreview
        text={sendEmailFormValues?.text}
        invoice={invoice}
        firm={firm}
        files={sendEmailFormValues?.files}
        includeAccountStatementLink={
          sendEmailFormValues?.includeAccountStatementLink
        }
      />
    </ModalSendEmailPage>
  );
}
