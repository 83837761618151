import React from 'react';
import { StaticField } from '@appclose/core';

import AssociatedPayment from 'components/common/AssociatedPayment';
import { useIntl } from 'i18n';

import { AssociatedPaymentsPropsType } from './AssociatedPayments.types';
import styles from './AssociatedPayments.module.scss';

export default function AssociatedPayments({
  payments,
}: AssociatedPaymentsPropsType) {
  const { t } = useIntl();

  return (
    <StaticField
      className={styles.associatedPayments}
      title={t('modal.disbursementDetails.associatedPayments')}
    >
      {payments?.map((payment) => {
        if (!payment) {
          return null;
        }

        return <AssociatedPayment key={payment.id} payment={payment} />;
      })}
    </StaticField>
  );
}
