import { gql } from '@apollo/client';

export const FETCH_PAYMENT_LINK = gql`
  query FetchPaymentLink {
    getFirm {
      id
      paymentLink
    }
  }
`;
