import { useCallback, useState } from 'react';
import { useFormContext, uuidV4 } from '@appclose/core';
import { useMutation } from '@apollo/client';

import { GET_UPLOAD_FILES_URL } from './useUploadFiles.gql';
import {
  GetUploadFilesUrlMutation,
  GetUploadFilesUrlMutationVariables,
} from './__generated__/useUploadFiles.gql';
import { UploadType, UseUploadFilesReturnType } from './useUploadFiles.types';

export default function useUploadFiles(): UseUploadFilesReturnType {
  const { addProcess } = useFormContext();
  const [uploads, setUploads] = useState<UploadType[]>([]);
  const [getUploadUrls] = useMutation<
    GetUploadFilesUrlMutation,
    GetUploadFilesUrlMutationVariables
  >(GET_UPLOAD_FILES_URL);

  const updateUpload = useCallback((id: string, data: Partial<UploadType>) => {
    setUploads((prevUploads) =>
      prevUploads.reduce<UploadType[]>(
        (acc, item) => [
          ...acc,
          {
            ...item,
            ...(item.id === id ? data : {}),
          },
        ],
        []
      )
    );
  }, []);

  const sendUpload = useCallback(
    (upload) => {
      return fetch(upload.url, {
        method: 'PUT',
        body: upload.file,
        signal: upload.abortController.signal,
      })
        .then(() => {
          updateUpload(upload.id, {
            uploading: false,
            error: false,
          });
        })
        .catch(() => {
          updateUpload(upload.id, {
            uploading: false,
            error: true,
          });
        });
    },
    [updateUpload]
  );

  const onUpload = useCallback(
    (files: File[]) => {
      if (files.length) {
        const newUploads: UploadType[] = files.map(
          (file) => ({
            id: uuidV4(),
            file,
            uploading: true,
            error: false,
            abortController: new AbortController(),
          }),
          []
        );

        setUploads((prevUploads) => [...prevUploads, ...newUploads]);

        addProcess(
          (async () => {
            const promises: Promise<void>[] = [];
            const { data } = await getUploadUrls({
              variables: {
                files: newUploads.map(
                  ({ id, file: { name, type: mimetype } }) => ({
                    id,
                    name,
                    mimetype,
                  })
                ),
              },
            });

            data?.uploads.files.forEach((file) => {
              const upload = newUploads.find(({ id }) => id === file.id);

              if (upload) {
                const uploadWithUrl = { ...upload, url: file.url };
                updateUpload(upload.id, uploadWithUrl);

                promises.push(sendUpload(uploadWithUrl));
              }
            });

            await Promise.all(promises);
          })()
        );
      }
    },
    [addProcess, getUploadUrls, updateUpload, sendUpload]
  );

  const onDelete = useCallback((ids: string[]) => {
    if (ids.length) {
      setUploads((prevUploads) =>
        prevUploads.reduce<UploadType[]>((acc, upload) => {
          if (ids.includes(upload.id)) {
            upload.abortController.abort();

            return acc;
          }

          return [...acc, upload];
        }, [])
      );
    }
  }, []);

  const onRetryUpload = useCallback(
    (id: string) => {
      const upload = uploads.find((upload) => upload.id === id);

      if (!upload?.url) {
        return;
      }

      updateUpload(upload.id, {
        uploading: true,
        error: false,
      });

      addProcess(sendUpload(upload));
    },
    [uploads, updateUpload, sendUpload, addProcess]
  );

  return {
    uploads,
    onUpload,
    onRetryUpload,
    onDelete,
  };
}
