import * as Yup from 'yup';

import { getI18nErrorMessage } from 'i18n';

import {
  CalendarSyncFormValuesType, CalendarType,
} from './CalendarSyncModal.types';

export const CalendarSyncModalSchema = Yup.object().shape<CalendarSyncFormValuesType>(
  {
    type: Yup.mixed<CalendarType>().required(),
    calendarId: Yup.string()
      .when('type', {
        is: (type) => type === CalendarType.EXIST,
        then: Yup.string().required(
          getI18nErrorMessage('modal.calendarSync.schema.calendarId.required')
        ),
      })
      .optional(),
    calendarName: Yup.string()
      .when('type', {
        is: (type) => type === CalendarType.CUSTOM,
        then: Yup.string().required(
          getI18nErrorMessage('modal.calendarSync.schema.calendarName.required')
        ),
      })
      .optional()
  }
);
