import { useIntl } from 'i18n';
import { SubExpenseCategoryCostTypes } from '__generated__/globalTypes';
import { useCallback, useMemo } from 'react';

export default function useSubExpenseCategoryCostTypes() {
  const { t } = useIntl();
  const subExpenseCategoryCostTypes: Record<
    SubExpenseCategoryCostTypes,
    string
  > = useMemo(
    () => ({
      [SubExpenseCategoryCostTypes.SOFT]: t(
        'enum.subExpenseCategoryCostTypes.soft'
      ),
      [SubExpenseCategoryCostTypes.HARD]: t(
        'enum.subExpenseCategoryCostTypes.hard'
      ),
    }),
    [t]
  );

  const subExpenseCategoryCostTypesOptions: {
    label: string;
    value: SubExpenseCategoryCostTypes;
  }[] = useMemo(
    () =>
      Object.entries(subExpenseCategoryCostTypes).map(([value, label]) => ({
        value: value as SubExpenseCategoryCostTypes,
        label,
      })),
    [subExpenseCategoryCostTypes]
  );

  const getSubExpenseCategoryCostTypeLabel = useCallback(
    (type?: SubExpenseCategoryCostTypes | null) => {
      if (!type) {
        return '';
      }

      return subExpenseCategoryCostTypes[type];
    },
    [subExpenseCategoryCostTypes]
  );

  return {
    subExpenseCategoryCostTypesOptions,
    getSubExpenseCategoryCostTypeLabel,
  };
}
