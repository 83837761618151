import React, { useContext } from 'react';
import { Button, ButtonGroup, Note } from '@appclose/ui';
import {
  AmountFormField,
  Form,
  FormGroup,
  Fieldset,
  traceError,
} from '@appclose/core';

import { WizardContext } from 'components/common/Wizard';
import FormGrid from 'components/common/FormGrid';
import { I18n, useIntl } from 'i18n';

import {
  getTotalLedgersBalance,
  getEndingBankStatementBalance,
} from '../../TrustSetupBalancesStepModal.utils';
import TrustDepositBalancesTable from './components/TrustDepositBalancesTable';
import { FinalizingVerificationStepSchema } from './FinalizingVerificationStep.schema';
import {
  FinalizingVerificationStepFormValuesType,
  FinalizingVerificationStepPropsType,
} from './FinalizingVerificationStep.types';

export default function FinalizingVerificationStep({
  initialValues,
  onFinalizingVerification,
  onChange,
  loading,
}: FinalizingVerificationStepPropsType) {
  const { t } = useIntl();
  const { goToNextStep, goToPrevStep } = useContext(WizardContext);
  const handleOnSubmit = async (
    values: FinalizingVerificationStepFormValuesType
  ) => {
    try {
      await onFinalizingVerification(values);
      goToNextStep();
    } catch (e) {
      traceError(e);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      onChange={onChange}
      validationSchema={FinalizingVerificationStepSchema}
    >
      {({ values: { ledgerBalances, bankStatementEndingBalance } }) => {
        const totalLedgersBalance = getTotalLedgersBalance(ledgerBalances);
        const endingBankStatementBalance = getEndingBankStatementBalance(
          ledgerBalances
        );

        return (
          <>
            <Fieldset
              title={t(
                'modal.trustSetupBalancesStep.finalizingVerificationStep.fieldset.trustAccountInitialDate'
              )}
            >
              <FormGroup>
                <FormGrid>
                  <AmountFormField
                    name="bankStatementEndingBalance"
                    max={999999999.99}
                    label={t(
                      'modal.trustSetupBalancesStep.finalizingVerificationStep.field.bankStatementEndingBalance'
                    )}
                  />
                </FormGrid>
              </FormGroup>
              <Note theme="important" offset={{ top: 30 }}>
                <I18n id="modal.trustSetupBalancesStep.finalizingVerificationStep.note" />
              </Note>
            </Fieldset>
            <TrustDepositBalancesTable
              bankStatementEndingBalance={bankStatementEndingBalance}
              ledgerBalances={ledgerBalances}
              totalLedgersBalance={totalLedgersBalance}
            />
            <ButtonGroup>
              <Button onClick={goToPrevStep} disabled={loading}>
                <I18n id="modal.trustSetupBalancesStep.finalizingVerificationStep.button.back" />
              </Button>
              <Button
                type="submit"
                skin="primary"
                loading={loading}
                disabled={
                  endingBankStatementBalance !== bankStatementEndingBalance
                }
              >
                <I18n id="modal.trustSetupBalancesStep.finalizingVerificationStep.button.finish" />
              </Button>
            </ButtonGroup>
          </>
        );
      }}
    </Form>
  );
}
