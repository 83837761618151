export enum PaymentButtonLanguage {
  EN = 'en',
  ES = 'es',
}

export enum PaymentButtonBackground {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum PaymentButtonColor {
  WHITE = 'white',
  YELLOW = 'yellow',
  DARK = 'dark',
}

export enum PaymentButtonText {
  MAKE_PAYMENT = 'makePayment',
  PAY_INVOICE = 'payInvoice',
  PAY_RETAINER = 'payRetainer',
  PAY_WITH_MONESQ = 'payWithMonesq',
}

export enum PaymentButtonMethods {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  DISCOVER = 'DISCOVER',
  AMEX = 'AMEX',
  ECHECK = 'ECHECK',
  MONESQ = 'MONESQ',
}

export enum PaymentButtonEventTypes {
  DOWNLOAD = 'download image',
  COPY = 'copy widget',
}

export enum PaymentButtonEventVariations {
  ONE = 1,
  TWO,
  THREE,
  FOUR,
  FIVE,
}

export interface PaymentButtonPropsType {
  link: string;
  language: PaymentButtonLanguage;
  background: PaymentButtonBackground;
  color: PaymentButtonColor;
  text: PaymentButtonText;
  paymentMethods?: PaymentButtonMethods[];
  eventButtonVariation: PaymentButtonEventVariations;
}
