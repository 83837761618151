import { SelectFormField, useFormContext } from '@appclose/core';
import { LeadFormValuesType } from 'components/modals/pages/LeadModal/components/LeadForm';

import useLeadSources from 'hooks/useLeadSources';
import { useIntl } from 'i18n';

import { LeadSourceSelectFormFieldPropsType } from './LeadSourceSelectFormField.types';

export default function LeadSourceSelectFormField({
  name = 'leadSource',
  label,
  ...rest
}: LeadSourceSelectFormFieldPropsType) {
  const { t } = useIntl();
  const {
    values: { fromInbox },
  } = useFormContext<LeadFormValuesType>();
  const { leadSourcesOptions } = useLeadSources();

  return (
    <SelectFormField
      name={name}
      label={label || t('field.leadSource.label')}
      options={leadSourcesOptions}
      disabled={fromInbox}
      {...rest}
    />
  );
}
