import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  dateManager,
  Loader,
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from '@appclose/core';

import { I18n } from 'i18n';
import {
  BankAccountClasses,
  Modes,
  PaymentMethodsTypes,
  StripeBankAccountStatus,
} from '__generated__/globalTypes';
import ModeGuard from 'components/common/ModeGuard';
import BankAccountNotLinked, {
  BankAccountNotLinkedTypes,
} from 'components/common/BankAccountNotLinked';
import useBankAccounts from 'hooks/useBankAccounts';

import { SERVER_DATE_FORMAT } from 'constants/date';

import {
  FetchContactQuery,
  FetchContactQueryVariables,
  FetchInvoiceQuery,
  FetchInvoiceQueryVariables,
  FetchMatterQuery,
  FetchMatterQueryVariables,
} from '../../__generated__/ReceivePaymentModal.gql';

import {
  FETCH_CONTACT,
  FETCH_INVOICE,
  FETCH_MATTER,
} from '../../ReceivePaymentModal.gql';
import ToOperatingForm from './components/ToOperatingForm';
import ToTrustForm from './components/ToTrustForm';
import FromTrustToOperatingForm from './components/FromTrustToOperatingForm';
import {
  getInitialContact,
  getInitialInvoices,
  getInitialMatter,
} from '../../ReceivePaymentModa.utils';

import { MainInfoBlockPropsType, ReceivePaymentFormValuesType } from './PaymentInfoFormsBlock.types';

export default function MainInfoBlock({
  invoiceId,
  matterId,
  contactId,
  onSubmit,
  onFormChange,
  onClose,
}: MainInfoBlockPropsType) {
  const [isSubmitting] = useState(false);
  const [invoices, setInvoices] = useState<
    ReceivePaymentFormValuesType['invoices']
  >();
  const [contact, setContact] = useState<
    ReceivePaymentFormValuesType['contact']
  >();
  const [matter, setMatter] = useState<
    ReceivePaymentFormValuesType['matter']
  >();
  const {
    loading: loadingBankAccounts,
    bankAccounts,
  } = useBankAccounts();

  const { loading: loadingContact, data: contactData } = useQuery<
    FetchContactQuery,
    FetchContactQueryVariables
  >(FETCH_CONTACT, {
    variables: { id: contactId as string },
    skip: !contactId || !!invoiceId,
  });
  const { loading: loadingMatter, data: matterData } = useQuery<
    FetchMatterQuery,
    FetchMatterQueryVariables
  >(FETCH_MATTER, {
    variables: { id: matterId as string },
    skip: !matterId || !!invoiceId,
  });
  const { loading: loadingInvoice, data: invoiceData } = useQuery<
    FetchInvoiceQuery,
    FetchInvoiceQueryVariables
  >(FETCH_INVOICE, {
    variables: { id: invoiceId as string },
    skip: !invoiceId,
  });

  const isLoading =
    loadingBankAccounts ||
    loadingInvoice ||
    loadingMatter ||
    loadingContact ||
    isSubmitting;

  useEffect(() => {
    setInvoices(getInitialInvoices(invoiceData));
  }, [invoiceData]);

  useEffect(() => {
    setContact(
      (prev) => prev || getInitialContact(contactData, matterData, invoiceData)
    );
  }, [contactData, matterData, invoiceData]);

  useEffect(() => {
    setMatter((prev) => prev || getInitialMatter(matterData, invoiceData));
  }, [matterData, invoiceData]);

  const initialValues = useMemo(() => {
    return {
      invoices: invoices || getInitialInvoices(invoiceData),
      contact:
        contact || getInitialContact(contactData, matterData, invoiceData),
      matter: matter || getInitialMatter(matterData, invoiceData),
      paymentDate: dateManager()
        .parse()
        .startOf('day')
        .format(SERVER_DATE_FORMAT),
    };
  }, [invoices, contact, matter, contactData, matterData, invoiceData]);

  const invoiceDestinationAccount = invoiceData?.invoice.destinationAccount;
  const trustAccount = bankAccounts?.find((acc) => acc.class === BankAccountClasses.TRUST);
  const operatingAccount = bankAccounts?.find((acc) => acc.class === BankAccountClasses.OPERATING);

  const isOperatingConnected = operatingAccount?.stripeBankAccountStatus === StripeBankAccountStatus.CONNECTED;
  const isTrustConnected = trustAccount?.stripeBankAccountStatus === StripeBankAccountStatus.CONNECTED;

  const trustAccountNotLinkedType = useMemo(() => {
    const { stripeBankAccountStatus } = trustAccount || {};
  
    if (stripeBankAccountStatus === StripeBankAccountStatus.MIGRATION_REQUIRED) {
      return BankAccountNotLinkedTypes.TRUST_ACCOUNT_NEED_RECONNECTION
    } else if (stripeBankAccountStatus === StripeBankAccountStatus.FEE_ACCOUNT_REQUIRED) {
      return BankAccountNotLinkedTypes.TRUST_FEE_ACCOUNT_REQUIRED;
    } else if (stripeBankAccountStatus === StripeBankAccountStatus.FEE_ACCOUNT_PROCESSING) {
      return BankAccountNotLinkedTypes.TRUST_FEE_ACCOUNT_PROCESSING
    }
  
    return BankAccountClasses.TRUST
  }, [trustAccount]);
   

  return isLoading ? (
    <Loader />
  ) : (
    <Tabs
      onSelect={() => setInvoices([])}
      defaultIndex={
        invoiceDestinationAccount === BankAccountClasses.TRUST ? 1 : 0
      }
    >
      <TabList>
        <Tab>
          <I18n id="modal.receivePayment.tab.toOperating" />
        </Tab>
        <Tab>
          <I18n id="modal.receivePayment.tab.toTrust" />
        </Tab>
        <ModeGuard mode={Modes.PLUS}>
          <Tab>
            <I18n id="modal.receivePayment.tab.fromTrust" />
          </Tab>
        </ModeGuard>
      </TabList>
      <TabPanel>
        {isOperatingConnected ? (
          <ToOperatingForm
            initialValues={{
              ...initialValues,
              destinationAccount: BankAccountClasses.OPERATING,
            }}
            onSubmit={onSubmit}
            onCancel={onClose}
            onChange={onFormChange}
            setContact={setContact}
            setMatter={setMatter}
          />
        ) : (
          <BankAccountNotLinked type={BankAccountClasses.OPERATING} />
        )}
      </TabPanel>
      <TabPanel>
        {isTrustConnected ? (
          <ToTrustForm
            initialValues={{
              ...initialValues,
              destinationAccount: BankAccountClasses.TRUST,
            }}
            onSubmit={onSubmit}
            onCancel={onClose}
            onChange={onFormChange}
            setContact={setContact}
            setMatter={setMatter}
          />
        ) : (
          <BankAccountNotLinked type={trustAccountNotLinkedType} />
        )}
      </TabPanel>
      <ModeGuard mode={Modes.PLUS}>
        <TabPanel>
          {isTrustConnected ? (
            <FromTrustToOperatingForm
              initialValues={{
                ...initialValues,
                destinationAccount: BankAccountClasses.OPERATING,
                paymentMethod: PaymentMethodsTypes.TRANSFER_FROM_TRUST,
              }}
              onSubmit={onSubmit}
              onCancel={onClose}
              onChange={onFormChange}
              setContact={setContact}
              setMatter={setMatter}
            />
          ) : (
            <BankAccountNotLinked type={trustAccountNotLinkedType} />
          )}
        </TabPanel>
      </ModeGuard>
    </Tabs>
  );
}