import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { dateManager } from '@appclose/core';

import { track } from 'controllers/analytics';
import { openModal } from 'controllers/modal';
import { EntryTypes, DemoEventNames } from 'constants/analytics';
import { SERVER_DATE_FORMAT } from 'constants/date';
import { DISBURSEMENT_DETAILS_MODAL } from 'constants/modals';
import notification from 'controllers/notification';
import usePrintChecks from 'hooks/usePrintChecks';
import useLastUsage from 'hooks/useLastUsage';
import useCanCreateTrustTransaction from 'hooks/useCanCreateTrustTransaction';
import { useIntl } from 'i18n';

import {
  DisbursementToThirdPartyMutation,
  DisbursementToThirdPartyMutationVariables,
} from './__generated__/DisbursementToThirdParty.gql';
import { DISBURSEMENT_TO_THIRD_PARTY } from './DisbursementToThirdParty.gql';
import DisbursementToThirdPartyForm, {
  DisbursementToThirdPartyFormActionsType,
  DisbursementToThirdPartyFormValuesType,
} from './components/DisbursementToThirdPartyForm';
import { DisbursementToThirdPartyPropsType } from './DisbursementToThirdParty.types';

export default function DisbursementToThirdParty({
  onCancel,
  onClose,
  onChange,
}: DisbursementToThirdPartyPropsType) {
  const { t } = useIntl();
  const { setLastUsage } = useLastUsage('thirdPartyPayee');
  const { openPrintChecks } = usePrintChecks();
  const {
    getCanCreateTrustTransaction,
    openUnableToCreateTrustTransactionPopup,
  } = useCanCreateTrustTransaction('disbursement', onClose);

  const [disbursementToContact] = useMutation<
    DisbursementToThirdPartyMutation,
    DisbursementToThirdPartyMutationVariables
  >(DISBURSEMENT_TO_THIRD_PARTY);

  const handleOnSubmit = useCallback(
    async (
      {
        printMode,
        contact,
        matter,
        thirdPartyPayee,
        ...input
      }: DisbursementToThirdPartyFormValuesType,
      { setSubmitting, setFieldValue }: DisbursementToThirdPartyFormActionsType
    ) => {
      const canCreate = getCanCreateTrustTransaction(input.date);

      if (!canCreate) {
        openUnableToCreateTrustTransactionPopup();

        return;
      }

      try {
        const { data } = await disbursementToContact({
          variables: {
            input: {
              contactId: contact.id,
              matterId: matter?.id,
              thirdPartyPayeeId: thirdPartyPayee?.id,
              ...input,
            },
          },
        });

        notification().entityCreated(
          t('modal.disbursement.notification.thirdParty.created')
        );

        track(DemoEventNames.DEMO_ENTRY_CREATED, {
          entry_type: EntryTypes.TRUST_DISBURSEMENT,
        });

        setLastUsage(thirdPartyPayee?.id);
        onClose();

        if (printMode) {
          openPrintChecks(data?.disbursement.id);
        } else {
          openModal(DISBURSEMENT_DETAILS_MODAL, data?.disbursement);
        }
      } catch (error) {
        setSubmitting(false);
        setFieldValue('printMode', false, true);
      }
    },
    [
      disbursementToContact,
      getCanCreateTrustTransaction,
      onClose,
      openPrintChecks,
      openUnableToCreateTrustTransactionPopup,
      setLastUsage,
      t,
    ]
  );

  const initialValues = {
    amount: 0,
    date: dateManager().parse().startOf('day').format(SERVER_DATE_FORMAT),
  } as DisbursementToThirdPartyFormValuesType;

  return (
    <DisbursementToThirdPartyForm
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      onCancel={onCancel}
      onChange={onChange}
    />
  );
}
