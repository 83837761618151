import React, { useContext } from 'react';
import { Button, ButtonGroup, Note } from '@appclose/ui';
import {
  dateManager,
  DatePickerFormField,
  Form,
  FormGroup,
  Fieldset,
} from '@appclose/core';

import { WizardContext } from 'components/common/Wizard';
import FormGrid from 'components/common/FormGrid';
import { SERVER_DATE_FORMAT } from 'constants/date';
import { I18n, useIntl } from 'i18n';

import { InitialDateStepFormSchema } from './InitialDateStep.schema';
import {
  InitialDateStepFormValuesType,
  InitialDateStepPropsType,
} from './InitialDateStep.types';
import styles from './InitialDateStep.module.scss';

export default function InitialDateStep({
  initialValues,
  onSetStatementDate,
  onCancel,
  onChange,
}: InitialDateStepPropsType) {
  const { t } = useIntl();
  const { goToNextStep } = useContext(WizardContext);

  const handleOnSubmit = (values: InitialDateStepFormValuesType) => {
    onSetStatementDate(values);
    goToNextStep();
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      onChange={onChange}
      validationSchema={InitialDateStepFormSchema}
    >
      {() => (
        <>
          <Fieldset
            title={t(
              'modal.trustSetupBalancesStep.initialDateStep.fieldset.trustAccountInitialDate'
            )}
          >
            <FormGroup>
              <FormGrid>
                <DatePickerFormField
                  name="statementDate"
                  label={t(
                    'modal.trustSetupBalancesStep.initialDateStep.field.startDate'
                  )}
                  outputDateFormat={SERVER_DATE_FORMAT}
                  maxDate={dateManager()
                    .parse()
                    .endOf('day')
                    .subtract(1, 'day')
                    .valueOf()}
                />
              </FormGrid>
            </FormGroup>
            <Note className={styles.note}>
              <I18n id="modal.trustSetupBalancesStep.initialDateStep.pleaseNote" />
              <p className={styles.trust}>
                <I18n id="modal.trustSetupBalancesStep.initialDateStep.pleaseEnterStartDateNote" />
              </p>
              <p className={styles.info}>
                <I18n id="modal.trustSetupBalancesStep.initialDateStep.recommendationNote" />
              </p>
            </Note>
          </Fieldset>
          <ButtonGroup>
            <Button onClick={onCancel}>
              <I18n id="modal.trustSetupBalancesStep.initialDateStep.button.cancel" />
            </Button>
            <Button type="submit" skin="primary">
              <I18n id="modal.trustSetupBalancesStep.initialDateStep.button.continue" />
            </Button>
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}
