import React from 'react';
import { Grid, Flex } from '@appclose/ui';
import {
  MediaQueryMobile,
  MediaQueryDesktop,
  StaticField,
  Amount,
} from '@appclose/core';

import TrustTransactionStatus from 'components/common/TrustTransactionStatus';
import CheckPrintStatus from 'components/common/CheckPrintStatus';
import { useIntl } from 'i18n';

import { PaymentDetailsPropsType } from './PaymentDetails.types';
import styles from './PaymentDetails.module.scss';

export default function PaymentDetails({
  amount,
  status,
  checkPrintStatus,
}: PaymentDetailsPropsType) {
  const { t } = useIntl();

  return (
    <>
      <MediaQueryDesktop>
        <StaticField title={t('modal.disbursementDetails.payment.amount')}>
          <Amount value={amount} />
        </StaticField>
        <StaticField title={t('modal.disbursementDetails.payment.status')}>
          <Flex className={styles.statuses}>
            <TrustTransactionStatus status={status} />
            <CheckPrintStatus status={checkPrintStatus} />
          </Flex>
        </StaticField>
      </MediaQueryDesktop>
      <MediaQueryMobile>
        <Grid align="center" columns="auto auto">
          <StaticField title={t('modal.disbursementDetails.payment.amount')}>
            <Amount value={amount} />
          </StaticField>
          <StaticField title={t('modal.disbursementDetails.payment.status')}>
            <Flex className={styles.statuses}>
              <TrustTransactionStatus status={status} />
              <CheckPrintStatus status={checkPrintStatus} />
            </Flex>
          </StaticField>
        </Grid>
      </MediaQueryMobile>
    </>
  );
}
