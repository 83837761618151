import React, { useCallback } from 'react';
import { Checkbox, ButtonGroup, Button } from '@appclose/ui';
import { Modal } from '@appclose/core';

import { openModal } from 'controllers/modal';
import { DISBURSEMENT_MODAL } from 'constants/modals';
import { setReconciliationWarningStatus } from 'controllers/reconciliation';
import { I18n, useIntl } from 'i18n';

import { ReconciliationWarningModalPropsType } from './ReconciliationWarningModal.types';
import styles from './ReconciliationWarningModal.module.scss';

export default function ReconciliationWarningModal({
  onClose,
}: ReconciliationWarningModalPropsType) {
  const { t } = useIntl();
  const handleOpenDisbursementClick = useCallback(() => {
    onClose();
    openModal(DISBURSEMENT_MODAL);
  }, [onClose]);

  const handleWarningStatusChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setReconciliationWarningStatus(e.target.checked);
    },
    []
  );

  return (
    <Modal
      className={styles.modal}
      title={t('modal.reconciliationWarning.title')}
      onClose={onClose}
    >
      <p>
        <I18n id="modal.reconciliationWarning.content" />
      </p>
      <Checkbox
        className={styles.check}
        defaultChecked={false}
        onChange={handleWarningStatusChange}
        label={t('modal.reconciliationWarning.check')}
      />
      <ButtonGroup className={styles.buttons}>
        <Button skin="primary" onClick={handleOpenDisbursementClick}>
          <I18n id="modal.reconciliationWarning.clearButton" />
        </Button>
      </ButtonGroup>
    </Modal>
  );
}
