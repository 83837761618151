import React from 'react';

import { Modes } from '__generated__/globalTypes';
import { useIntl } from 'i18n';

import { ModeInfoPropsType } from './ModeInfo.types';
import Mode from '../Mode';
import FeatureList from '../FeatureList';

import { MODES_FEATURES } from '../../Modes.constants';

import styles from './ModeInfo.module.scss';

export default function ModeInfo({
  mode,
  onSelect,
}: ModeInfoPropsType) {
  const { t } = useIntl();

  return (
    <>
      {mode === Modes.PLUS ? (
        <Mode
          mode={Modes.PLUS}
          title={t('plan.plus')}
          price={6.59}
          description={t('modes.description.plus')}
          onSelect={onSelect}
        />
      ) : (
        <Mode
          mode={Modes.FREE}
          title={t('plan.free')}
          price={0}
          description={t('modes.description.free')}
          onSelect={onSelect}
        />
      )}
      <div className={styles.features}>
        {MODES_FEATURES(t).map(({ title, points }) =>
          <FeatureList
            title={title}
            features={Object.entries(points).reduce<string[]>(
              (acc, [feature, modes]) =>
                modes.includes(mode) ? [...acc, feature] : acc,
              []
            )}
            key={`${title}`}
          />
        )}
      </div>
    </>
  );
}
