import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { Button, Title6 } from '@appclose/ui';

import styles from './MobileModes.module.scss';
import { MobileModesPropsType } from './MobileModes.types';

export default function MobileModes({
  modes,
  stickyRefs,
}: MobileModesPropsType) {
  const [top, setTop] = useState(0);

  const calculateTop = useCallback(() => {
    setTop(
      stickyRefs?.reduce(
        (acc, ref) => (ref.current ? ref.current.offsetHeight : 0) + acc,
        0
      ) || 0
    );
  }, [stickyRefs]);
  const debouncedCalculateTop = useMemo(() => debounce(calculateTop, 150), [
    calculateTop,
  ]);

  useEffect(() => {
    calculateTop();

    window.addEventListener('resize', debouncedCalculateTop, false);

    return () => {
      window.removeEventListener('resize', debouncedCalculateTop, false);
    };
  }, [calculateTop, debouncedCalculateTop]);

  return (
    <div className={styles.modes} style={{ top }}>
      {modes.map(({ title, onSelect }) => (
        <div key={title}>
          <Title6 offset={{ bottom: 7 }}>{title}</Title6>
          {onSelect && (
            <Button skin="brand" className={styles.button} onClick={onSelect}>
              Select
            </Button>
          )}
        </div>
      ))}
    </div>
  );
}
