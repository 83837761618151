import React from 'react';
import { Button, ButtonGroup, Text3 } from '@appclose/ui';
import { Form, FormGroup, Fieldset } from '@appclose/core';

import CheckTypeSelect from './components/CheckTypeSelect';
import PrintingSampleFieldset from 'components/form/PrintingSampleFieldset';
import { I18n, useIntl } from 'i18n';

import { PrintSettingsFormPropsType } from './PrintSettingsForm.types';

export default function PrintSettingsForm({
  initialValues,
  onOpenSample,
  onOpenFineTuneAlignment,
  onSubmit,
  onCancel,
  onChange,
}: PrintSettingsFormPropsType) {
  const { t } = useIntl();

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} onChange={onChange}>
      {({ isSubmitting, values: { mode }, setFieldValue }) => (
        <>
          <Fieldset title={t('modal.printSettings.form.checkType.title')}>
            <Text3 offset={{ top: 10, bottom: 20 }}>
              <I18n id="modal.printSettings.form.checkType.select" />
            </Text3>
            <FormGroup>
              <CheckTypeSelect
                type={mode}
                onChange={(mode) => setFieldValue('mode', mode)}
              />
            </FormGroup>
          </Fieldset>
          <PrintingSampleFieldset
            onOpenSample={() => mode && onOpenSample(mode)}
            isOpenSampleDisabled={isSubmitting || !mode}
            onOpenFineTuneAlignment={() => onOpenFineTuneAlignment(mode)}
            isOpenFineTuneAlignmentDisabled={isSubmitting}
          />
          <ButtonGroup>
            <Button onClick={onCancel} disabled={isSubmitting}>
              <I18n id="modal.printSettings.form.back" />
            </Button>
            <Button
              skin="primary"
              type="submit"
              loading={isSubmitting}
              disabled={!mode}
            >
              <I18n id="modal.printSettings.form.submit" />
            </Button>
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}
