import React, { ReactNode } from 'react';
import { Flex } from '@appclose/ui';

import { Modes } from '__generated__/globalTypes';
import { TFuncType, I18n } from 'i18n';

export const MODES_FEATURES = (
  t: TFuncType
): {
  title: ReactNode;
  points: { [key: string]: Modes[] };
}[] => [
  {
    title: t('modes.features.payments.title'),
    points: {
      [t('modes.features.payments.01')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.payments.02')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.payments.03')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.payments.04')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.payments.05')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.payments.06')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.payments.07')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.payments.08')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.payments.09')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.payments.10')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.payments.11')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.payments.12')]: [Modes.PLUS],
      [t('modes.features.payments.13')]: [Modes.PLUS],
      [t('modes.features.payments.14')]: [Modes.PLUS],
    },
  },
  {
    title: t('modes.features.invoicing.title'),
    points: {
      [t('modes.features.invoicing.01')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.invoicing.02')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.invoicing.03')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.invoicing.04')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.invoicing.05')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.invoicing.06')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.invoicing.07')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.invoicing.08')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.invoicing.09')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.invoicing.10')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.invoicing.11')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.invoicing.12')]: [Modes.PLUS],
      [t('modes.features.invoicing.13')]: [Modes.PLUS],
    },
  },
  {
    title: t('modes.features.dashboard.title'),
    points: {
      [t('modes.features.dashboard.01')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.dashboard.02')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.dashboard.03')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.dashboard.04')]: [Modes.PLUS],
      [t('modes.features.dashboard.05')]: [Modes.PLUS],
      [t('modes.features.dashboard.06')]: [Modes.PLUS],
    },
  },
  {
    title: t('modes.features.contactAndEntityManagement.title'),

    points: {
      [t('modes.features.contactAndEntityManagement.01')]: [
        Modes.FREE,
        Modes.PLUS,
      ],
      [t('modes.features.contactAndEntityManagement.02')]: [
        Modes.FREE,
        Modes.PLUS,
      ],
      [t('modes.features.contactAndEntityManagement.03')]: [Modes.PLUS],
    },
  },
  {
    title: t('modes.features.matterManagementTools.title'),
    points: {
      [t('modes.features.matterManagementTools.01')]: [Modes.PLUS],
      [t('modes.features.matterManagementTools.02')]: [Modes.PLUS],
      [t('modes.features.matterManagementTools.03')]: [Modes.PLUS],
      [t('modes.features.matterManagementTools.04')]: [Modes.PLUS],
      [t('modes.features.matterManagementTools.05')]: [Modes.PLUS],
      [t('modes.features.matterManagementTools.06')]: [Modes.PLUS],
      [t('modes.features.matterManagementTools.07')]: [Modes.PLUS],
    },
  },
  {
    title: t('modes.features.trustAccounting.title'),
    points: {
      [t('modes.features.trustAccounting.01')]: [Modes.PLUS],
      [t('modes.features.trustAccounting.02')]: [Modes.PLUS],
      [t('modes.features.trustAccounting.03')]: [Modes.PLUS],
      [t('modes.features.trustAccounting.04')]: [Modes.PLUS],
      [t('modes.features.trustAccounting.05')]: [Modes.PLUS],
      [t('modes.features.trustAccounting.06')]: [Modes.PLUS],
    },
  },
  {
    title: (
      <Flex justify="space-between">
        <span>
          <I18n id="modes.features.website.title" />
        </span>
        <span style={{ textTransform: 'lowercase' }}>
          <I18n id="modes.features.website.title.price" />
        </span>
      </Flex>
    ),
    points: {
      [t('modes.features.website.quick')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.website.tell')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.website.convert')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.website.pay')]: [Modes.FREE, Modes.PLUS],
    },
  },
  {
    title: t('modes.features.quickBooksIntegration.title'),
    points: {
      [t('modes.features.quickBooksIntegration.01')]: [Modes.FREE],
      [t('modes.features.quickBooksIntegration.02')]: [Modes.PLUS],
      [t('modes.features.quickBooksIntegration.03')]: [Modes.FREE, Modes.PLUS],
    },
  },
  {
    title: t('modes.features.eSignature.title'),
    points: {
      [t('modes.features.eSignature.01')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.eSignature.02')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.eSignature.03')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.eSignature.04')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.eSignature.05')]: [Modes.FREE, Modes.PLUS],
    },
  },
  {
    title: t('modes.features.calendar.title'),
    points: {
      [t('modes.features.calendar.create')]: [Modes.PLUS],
      [t('modes.features.calendar.blockTime')]: [Modes.PLUS],
      [t('modes.features.calendar.assign')]: [Modes.PLUS],
      [t('modes.features.calendar.recurring')]: [Modes.PLUS],
      [t('modes.features.calendar.filter')]: [Modes.PLUS],
      [t('modes.features.calendar.integration')]: [Modes.PLUS],
    },
  },
  {
    title: t('modes.features.crm.title'),
    points: {
      [t('modes.features.crm.01')]: [Modes.PLUS],
      [t('modes.features.crm.02')]: [Modes.PLUS],
      [t('modes.features.crm.03')]: [Modes.PLUS],
      [t('modes.features.crm.04')]: [Modes.PLUS],
      [t('modes.features.crm.05')]: [Modes.PLUS],
      [t('modes.features.crm.06')]: [Modes.PLUS],
    },
  },
  {
    title: t('modes.features.fileStorage.title'),
    points: {
      [t('modes.features.fileStorage.01')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.fileStorage.02')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.fileStorage.03')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.fileStorage.04')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.fileStorage.05')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.fileStorage.06')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.fileStorage.07')]: [Modes.FREE, Modes.PLUS],
    },
  },
  {
    title: t('modes.features.reporting.title'),
    points: {
      [t('modes.features.reporting.01')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.reporting.02')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.reporting.03')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.reporting.04')]: [Modes.PLUS],
    },
  },
  {
    title: t('modes.features.teamMembers.title'),
    points: {
      [t('modes.features.teamMembers.01')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.teamMembers.02')]: [Modes.FREE, Modes.PLUS],
      [t('modes.features.teamMembers.03')]: [Modes.PLUS],
    },
  },
  {
    title: t('modes.features.security.title'),
    points: {
      [t('modes.features.security.01')]: [Modes.FREE, Modes.PLUS],
    },
  },
  {
    title: t('modes.features.support.title'),
    points: {
      [t('modes.features.support.01')]: [Modes.FREE, Modes.PLUS],
    },
  },
];
