import { gql } from '@apollo/client';

import { TEAM_MEMBER_SELECT_FRAGMENT } from 'components/fields/TeamMemberSelectFormField';
import { EXPENSE_CATEGORY_SELECT_FRAGMENT } from 'components/fields/ExpenseCategorySelectFormField';
import { CONTACT_SELECT_FRAGMENT } from 'components/fields/ContactSelectFormField';
import { MATTER_SELECT_FRAGMENT } from 'components/fields/MatterSelectFormField';

const EXPENSE_MODAL_FRAGMENT = gql`
  fragment ExpenseModal on SubExpenseType {
    id
    category {
      ...ExpenseCategorySelect
    }
    contact {
      ...ContactSelect
    }
    matter {
      ...MatterSelect
    }
    amount
    date
    description
    billable
    billedBy {
      ...TeamMemberSelect
    }
    notes
    status
  }
  ${TEAM_MEMBER_SELECT_FRAGMENT}
  ${EXPENSE_CATEGORY_SELECT_FRAGMENT}
  ${CONTACT_SELECT_FRAGMENT}
  ${MATTER_SELECT_FRAGMENT}
`;

export const CREATE_EXPENSE = gql`
  mutation CreateExpense($input: CreateSubExpenseInput!) {
    createSubExpense(createSubExpenseInput: $input) {
      ...ExpenseModal
    }
  }
  ${EXPENSE_MODAL_FRAGMENT}
`;

export const FETCH_EXPENSE = gql`
  query FetchExpense($id: ID!) {
    expense: getSubExpense(id: $id) {
      ...ExpenseModal
    }
  }
  ${EXPENSE_MODAL_FRAGMENT}
`;

export const UPDATE_EXPENSE = gql`
  mutation UpdateExpense($input: UpdateSubExpenseInput!) {
    updateSubExpense(updateSubExpenseInput: $input) {
      ...ExpenseModal
    }
  }
  ${EXPENSE_MODAL_FRAGMENT}
`;

export const FETCH_CONTACT = gql`
  query FetchContact($id: ID!) {
    contact: getContact(id: $id) {
      ...ContactSelect
    }
  }
  ${CONTACT_SELECT_FRAGMENT}
`;

export const FETCH_MATTER = gql`
  query FetchMatter($id: ID!) {
    matter: getMatter(id: $id) {
      ...MatterSelect
    }
  }
  ${MATTER_SELECT_FRAGMENT}
`;
