import { gql } from '@apollo/client';

import { CONTACT_SELECT_FRAGMENT } from 'components/fields/ContactSelectFormField';
import { MATTER_SELECT_FRAGMENT } from 'components/fields/MatterSelectFormField';
import { TEAM_MEMBER_SELECT_FRAGMENT } from 'components/fields/TeamMemberSelectFormField';

const FLAT_FEE_MODAL_FRAGMENT = gql`
  fragment FlatFeeModal on FlatFeeType {
    id
    amount
    date
    description
    billable
    notes
    status
    contact {
      ...ContactSelect
    }
    matter {
      ...MatterSelect
    }
    billedBy {
      ...TeamMemberSelect
    }
  }
  ${CONTACT_SELECT_FRAGMENT}
  ${MATTER_SELECT_FRAGMENT}
  ${TEAM_MEMBER_SELECT_FRAGMENT}
`;

export const CREATE_FLAT_FEE = gql`
  mutation CreateFlatFee($flatFee: CreateFlatFeeInput!) {
    createFlatFee(createFlatFeeInput: $flatFee) {
      ...FlatFeeModal
    }
  }
  ${FLAT_FEE_MODAL_FRAGMENT}
`;

export const FETCH_FLAT_FEE = gql`
  query FetchFlatFee($id: ID!) {
    flatFee: getFlatFee(id: $id) {
      ...FlatFeeModal
    }
  }
  ${FLAT_FEE_MODAL_FRAGMENT}
`;

export const UPDATE_FLAT_FEE = gql`
  mutation UpdateFlatFee($flatFee: UpdateFlatFeeInput!) {
    updateFlatFee(updateFlatFeeInput: $flatFee) {
      ...FlatFeeModal
    }
  }
  ${FLAT_FEE_MODAL_FRAGMENT}
`;

export const FETCH_CONTACT = gql`
  query FetchContact($id: ID!) {
    contact: getContact(id: $id) {
      ...ContactSelect
    }
  }
  ${CONTACT_SELECT_FRAGMENT}
`;

export const FETCH_MATTER = gql`
  query FetchMatter($id: ID!) {
    matter: getMatter(id: $id) {
      ...MatterSelect
    }
  }
  ${MATTER_SELECT_FRAGMENT}
`;
