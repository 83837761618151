import React, { useMemo } from 'react';
import { Button, ButtonGroup, Flex, RadioGroup, Note } from '@appclose/ui';
import {
  Form,
  RadioFormField,
  SelectFormField,
  Fieldset,
} from '@appclose/core';

import { I18n, useIntl } from 'i18n';

import { PrintingResultsFormSchema } from './PrintingResultsForm.schema';
import {
  PrintingResults,
  PrintingResultsFormPropsType,
} from './PrintingResultsForm.types';
import styles from './PrintingResultsForm.module.scss';

export default function PrintingResultsForm({
  totalChecks,
  onSubmit,
  initialValues,
}: PrintingResultsFormPropsType) {
  const { t } = useIntl();
  const { reprintStartNo } = initialValues;

  const options = useMemo(
    () =>
      [...new Array(totalChecks)].map((_, i) => ({
        value: i + reprintStartNo,
        label: i + reprintStartNo,
      })),
    [reprintStartNo, totalChecks]
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={PrintingResultsFormSchema}
    >
      {({ isSubmitting, values: { printResult } }) => {
        return (
          <>
            <Fieldset
              title={t('modal.printChecksPreview.printingResults.form.title')}
            >
              <RadioGroup>
                <RadioFormField
                  name="printResult"
                  value={PrintingResults.YES}
                  label={t(
                    'modal.printChecksPreview.printingResults.form.printResult.yes'
                  )}
                />
                <RadioFormField
                  name="printResult"
                  value={PrintingResults.SOME}
                  label={
                    <Flex alignItems="baseline">
                      <span>
                        <I18n id="modal.printChecksPreview.printingResults.form.printResult.some" />
                      </span>
                      <SelectFormField
                        fieldClassName={styles.reprintSelectField}
                        name="reprintStartNo"
                        options={options}
                        clearable={false}
                        disabled={printResult !== PrintingResults.SOME}
                      />
                    </Flex>
                  }
                />
                <RadioFormField
                  name="printResult"
                  value={PrintingResults.NO}
                  label={t(
                    'modal.printChecksPreview.printingResults.form.printResult.no'
                  )}
                />
              </RadioGroup>
            </Fieldset>
            <Note theme="important" offset={{ bottom: 60 }}>
              <I18n id="modal.printChecksPreview.printingResults.form.note" />
            </Note>
            <ButtonGroup>
              <Button skin="primary" type="submit" loading={isSubmitting}>
                <I18n id="modal.printChecksPreview.printingResults.form.submit" />
              </Button>
            </ButtonGroup>
          </>
        );
      }}
    </Form>
  );
}
