import { useCallback } from 'react';
import { generatePath } from 'react-router-dom';
import omit from 'lodash/omit';
import { useMutation, useQuery } from '@apollo/client';
import { history, Loader, usePopupModal } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { DemoEventNames, EntryTypes } from 'constants/analytics';
import { CONTACT_OVERVIEW_ROUTE } from 'constants/routes';
import { track } from 'controllers/analytics';
import notification from 'controllers/notification';
import { INITIAL_CONTACT, INITIAL_ENTITY } from 'constants/contacts';
import useCloseConfirm from 'hooks/useCloseConfirm';
import useUpdateFiles from 'hooks/useUpdateFiles';
import { Entities } from 'constants/entities';
import { useIntl } from 'i18n';
import {
  UpdateLeadsStatusMutation,
  UpdateLeadsStatusMutationVariables,
} from 'gql/shared/__generated__/lead.gql';
import { UPDATE_LEADS_STATUS } from 'gql/shared/lead.gql';
import useLeadStatuses from 'hooks/useLeadStatuses';
import { CreateContactInput } from '__generated__/globalTypes';
import LeadMatterPopupModal from 'components/modals/popups/LeadMatterPopupModal';

import {
  CreateContactMutation,
  CreateContactMutationVariables,
  FetchContactQuery,
  FetchContactQueryVariables,
  FetchProfileQuery,
  FetchProfileQueryVariables,
  ImportContactsMutation,
  ImportContactsMutationVariables,
  UpdateContactMutation,
  UpdateContactMutationVariables,
} from './__generated__/ContactModal.gql';
import ContactForm, {
  ContactAndEntityFormActionsType,
  ContactAndEntityFormValuesType,
} from './components/ContactForm';
import {
  CREATE_CONTACT,
  FETCH_CONTACT,
  FETCH_PROFILE,
  IMPORT_CONTACTS,
  UPDATE_CONTACT,
} from './ContactModal.gql';
import {
  ContactModalModalTabs,
  ContactModalPropsType,
} from './ContactModal.types';

export default function ContactModal({
  id,
  lead,
  onClose,
  defaultTab,
  openNew = true,
}: ContactModalPropsType) {
  const { t } = useIntl();
  const statuses = useLeadStatuses();
  const { onUpdateFiles } = useUpdateFiles(Entities.CONTACT);
  const isEdit = !!id;
  const { openPopupModal: openLeadMatterModal } = usePopupModal(
    LeadMatterPopupModal
  );

  const { loading: profileLoading, data: profileData } = useQuery<
    FetchProfileQuery,
    FetchProfileQueryVariables
  >(FETCH_PROFILE);
  const [createContact] = useMutation<
    CreateContactMutation,
    CreateContactMutationVariables
  >(CREATE_CONTACT);
  const [updateContact] = useMutation<
    UpdateContactMutation,
    UpdateContactMutationVariables
  >(UPDATE_CONTACT);
  const { loading: contactLoading, data: contactData } = useQuery<
    FetchContactQuery,
    FetchContactQueryVariables
  >(FETCH_CONTACT, {
    variables: { id: id as string },
    fetchPolicy: 'network-only',
    skip: !isEdit,
  });
  const [importContacts] = useMutation<
    ImportContactsMutation,
    ImportContactsMutationVariables
  >(IMPORT_CONTACTS);

  const [updateLeadsStatus] = useMutation<
    UpdateLeadsStatusMutation,
    UpdateLeadsStatusMutationVariables
  >(UPDATE_LEADS_STATUS, {
    update: (cache, { data: result }) => {
      if (result?.updateLeadsStatus.statusId && lead) {
        const { statusId } = result.updateLeadsStatus;

        cache.modify({
          id: `LeadType:${lead.id}`,
          fields: {
            statusId: () => statusId,
          },
        });
      }
    },
  });

  const { onConfirmClose, onFormChange } = useCloseConfirm({ onClose });

  const handleOnSubmit = useCallback(
    async (
      {
        vCardFiles,
        contact,
        entity,
        selectedTab,
        importSummary,
        files,
      }: ContactAndEntityFormValuesType,
      { setFieldValue }: ContactAndEntityFormActionsType
    ) => {
      if (selectedTab === ContactModalModalTabs.MULTIPLE_IMPORT) {
        if (!importSummary) {
          const { data } = await importContacts({ variables: { vCardFiles } });

          setFieldValue('importSummary', data?.summary);

          notification().info(t('modal.contact.notification.import.success'));
        } else {
          onClose();
        }
      } else {
        const variables =
          selectedTab === ContactModalModalTabs.ENTITY
            ? {
                input: {
                  entity: {
                    ...(isEdit ? { id } : {}),
                    ...omit(entity, ['uploadFiles', 'originatingAttorney']),
                    originatingAttorney: entity.originatingAttorney.id,
                  },
                },
              }
            : {
                input: {
                  contact: {
                    ...(isEdit ? { id } : {}),
                    ...omit(contact, ['uploadFiles', 'originatingAttorney']),
                    originatingAttorney: contact.originatingAttorney.id,
                  },
                },
              };

        let contactId = id;

        if (isEdit) {
          await updateContact({
            variables: variables as UpdateContactMutationVariables,
          });

          notification().entityUpdated(
            t('modal.contact.notification.update.success')
          );
        } else if (lead) {
          const result = await updateLeadsStatus({
            variables: {
              input: {
                leadIds: [lead.id],
                statusId: statuses.find(({ title }) => title === 'Hired')!.id,
                associateWithContactId: contactId,
                createContactInput: variables.input as CreateContactInput,
              },
            },
          });
          contactId = result.data?.updateLeadsStatus.linkedContact?.id;

          notification().entityCreated(
            t('modal.contact.notification.create.success')
          );

          onClose();

          openLeadMatterModal({ contactId });
        } else {
          const result = await createContact({
            variables: variables as CreateContactMutationVariables,
          });
          contactId = result.data?.createContact.id;

          notification().entityCreated(
            t('modal.contact.notification.create.success')
          );

          track(DemoEventNames.DEMO_ENTRY_CREATED, {
            entry_type: EntryTypes.CONTACT,
          });
        }

        if (contactId && files) {
          await onUpdateFiles({ entityId: contactId, ...files });
        }

        onClose();

        if (openNew && !isEdit && contactId) {
          history.push(generatePath(CONTACT_OVERVIEW_ROUTE, { id: contactId }));
        }
      }
    },
    [
      importContacts,
      t,
      onClose,
      isEdit,
      id,
      lead,
      openNew,
      updateContact,
      updateLeadsStatus,
      statuses,
      openLeadMatterModal,
      createContact,
      onUpdateFiles,
    ]
  );

  let initialValues = {
    selectedTab: defaultTab || ContactModalModalTabs.INDIVIDUAL,
    entity: {
      ...INITIAL_ENTITY,
      originatingAttorney: profileData?.getProfile,
    },
    contact: {
      ...INITIAL_CONTACT,
      originatingAttorney: profileData?.getProfile,
    },
  } as ContactAndEntityFormValuesType;

  if (lead?.entity) {
    initialValues = {
      ...initialValues,
      entity: {
        ...initialValues.entity,
        name: lead.entity.entityName,
        billingContacts: lead.entity.contacts as any,
        notes: lead.notes,
        originatingAttorney: lead.originatingAttorney,
        phoneNumbers: lead.phoneNumbers,
        website: lead.entity.website,
      },
    };
  }

  if (lead?.individual) {
    initialValues = {
      ...initialValues,
      contact: {
        ...initialValues.contact,
        firstName: lead.individual.firstName!,
        lastName: lead.individual.lastName!,
        middleName: lead.individual.middleName,
        email: lead.individual.email!,
        notes: lead.notes,
        phoneNumbers: lead.phoneNumbers,
        originatingAttorney: lead.originatingAttorney,
      },
    };
  }

  if (isEdit && !contactLoading && contactData?.getContact) {
    const {
      firstName,
      lastName,
      middleName,
      gender,
      email,
      website,
      billingContacts,
      id,
      addresses,
      phoneNumbers,
      isEntity,
      name,
      ...rest
    } = contactData.getContact;

    if (!isEntity) {
      initialValues = {
        id,
        selectedTab: ContactModalModalTabs.INDIVIDUAL,
        contact: {
          ...initialValues.contact,
          ...rest,
          firstName,
          lastName,
          middleName,
          gender,
          email,
          addresses,
          phoneNumbers,
        },
      } as ContactAndEntityFormValuesType;
    } else {
      initialValues = {
        id,
        selectedTab: ContactModalModalTabs.ENTITY,
        entity: {
          ...initialValues.entity,
          ...rest,
          name,
          website,
          billingContacts,
          addresses,
          phoneNumbers,
        },
      } as ContactAndEntityFormValuesType;
    }
  }

  const title = !isEdit
    ? t('modal.contact.title')
    : !!contactData?.getContact && contactData.getContact.name;

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>{title}</ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {contactLoading || profileLoading ? (
          <Loader />
        ) : (
          <ContactForm
            initialValues={initialValues}
            isEdit={isEdit}
            isLead={!!lead?.id}
            defaultTab={defaultTab}
            onSubmit={handleOnSubmit}
            onCancel={onConfirmClose}
            onChange={onFormChange}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
