import React, { useCallback } from 'react';
import { dateManager } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { SERVER_DATE_FORMAT } from 'constants/date';
import {
  FINE_TUNE_ALIGNMENT_MODAL,
  PRINT_DEPOSITS_PREVIEW_MODAL,
} from 'constants/modals';
import { getSavedModalData, openModal } from 'controllers/modal';
import { FineTuneAlignmentTypes } from 'components/modals/pages/FineTuneAlignmentModal';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { I18n } from 'i18n';

import PrintDepositsForm, {
  PrintDepositsFormValuesType,
} from './components/PrintDepositsForm';
import {
  PrintDepositsModalPropsType,
  PrintDepositsModalSavedData,
} from './PrintDepositsModal.types';

export default function PrintDepositsModal({
  onClose,
  id,
}: PrintDepositsModalPropsType) {
  const { ids, date } = getSavedModalData<PrintDepositsModalSavedData>() || {};

  const handleOnOpenFineTuneAlignment = useCallback(
    (
      ids: PrintDepositsModalSavedData['ids'],
      date: PrintDepositsModalSavedData['date']
    ) =>
      openModal(
        FINE_TUNE_ALIGNMENT_MODAL,
        {
          type: FineTuneAlignmentTypes.DEPOSIT_SLIP,
        },
        {
          ids,
          date,
        }
      ),
    []
  );

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });

  const handleOnSubmit = useCallback(
    ({ deposits, date }: PrintDepositsFormValuesType) => {
      onClose();

      openModal(PRINT_DEPOSITS_PREVIEW_MODAL, {
        depositsIds: deposits.map(({ id }) => id),
        date,
      });
    },
    [onClose]
  );

  const initialValues = {
    preSelectedDepositIds:
      ids || (Array.isArray(id) ? id : ([id].filter(Boolean) as string[])),
    deposits: [],
    date:
      date || dateManager().parse().startOf('day').format(SERVER_DATE_FORMAT),
  };

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.printDeposits.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        <PrintDepositsForm
          initialValues={initialValues}
          onOpenFineTuneAlignment={handleOnOpenFineTuneAlignment}
          onSubmit={handleOnSubmit}
          onCancel={onConfirmClose}
          onChange={onFormChange}
        />
      </ModalPageContent>
    </ModalPage>
  );
}
