import React from 'react';
import { Title1, Title2 } from '@appclose/ui';
import { useIsMobileDevice } from '@appclose/core';

import { ModalPageTitlePropsType } from './ModalPageTitle.types';

export default function ModalPageTitle({
  className,
  children,
}: ModalPageTitlePropsType) {
  const isMobileDevice = useIsMobileDevice();
  const Title = isMobileDevice ? Title2 : Title1;

  return <Title className={className}>{children}</Title>;
}
