import { gql } from '@apollo/client';

import { AVATAR_FRAGMENT } from 'components/common/Avatar';
import { INVOICE_INFO_FRAGMENT } from 'components/common/InvoiceInfo';

export const FETCH_TIME_ENTRY = gql`
  query FetchTimeEntryDetails($id: ID!) {
    timeEntryDetails: getTimeEntry(id: $id) {
      id
      durationSec
      billable
      billingRate
      amount
      status
      activity {
        id
        name
      }
      date
      description
      notes
      contact {
        id
        name
      }
      matter {
        id
        name
      }
      billedBy {
        id
        firstName
        lastName
        picture {
          ...Avatar
        }
      }
      timer {
        id
      }
      invoice {
        ...InvoiceInfo
      }
    }
  }
  ${AVATAR_FRAGMENT}
  ${INVOICE_INFO_FRAGMENT}
`;
