import React from 'react';
import classnames from 'classnames';
import { Caption1 } from '@appclose/ui';

import { ReactComponent as AnchorIcon } from 'assets/anchor.svg';
import { I18n, useIntl } from 'i18n';

import Fee from './components/Fee';
import { FeesPropsType } from './Fees.types';
import paymentMethodsImg from './assets/payment-methods.png';
import styles from './Fees.module.scss';

export default function Fees({ className }: FeesPropsType) {
  const { t } = useIntl();

  return (
    <div className={classnames(styles.fees, className)}>
      <Caption1 weight="bold" className={styles.text}>
        <I18n id="modes.fees.title" />{' '}
        <AnchorIcon width={12} />
      </Caption1>
      <div className={styles.amounts}>
        <Fee 
          title={t('modes.fees.eCheck')} 
          fee={0.8}
          inPercentFee
          note={t('modes.fees.note.cap')}
        />
        <Fee
          title={t('modes.fees.creditAndDebitCards')}
          fee={2.9}
          fixed={0.3}
          inPercentFee
          inPercentFixed
          note={t('modes.fees.note.forManuallyEnteredCards')}
        />
      </div>
      <img
        src={paymentMethodsImg}
        width={182}
        height={38}
        alt={t('modes.fees.paymentMethods')}
        className={styles.image}
      />
    </div>
  );
}
