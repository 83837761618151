import React, { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { downloadFileByBase64, getDataUri } from '@appclose/lib';
import { Button, ButtonGroup } from '@appclose/ui';
import { Loader, Fieldset } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import PdfViewer from 'components/common/PdfViewer';
import notification from 'controllers/notification';
import { I18n, useIntl } from 'i18n';

import {
  FetchCheckSamplePdfQuery,
  FetchCheckSamplePdfQueryVariables,
} from './__generated__/PrintChecksSamplePreviewModal.gql';
import { FETCH_CHECK_SAMPLE_PDF } from './PrintChecksSamplePreviewModal.gql';
import { PrintChecksModalPropsType } from './PrintChecksSamplePreviewModal.types';
import styles from './PrintChecksSamplePreviewModal.module.scss';

export default function PrintChecksSamplePreviewModal({
  mode,
  offset,
  onClose,
}: PrintChecksModalPropsType) {
  const { t } = useIntl();
  const { loading, data } = useQuery<
    FetchCheckSamplePdfQuery,
    FetchCheckSamplePdfQueryVariables
  >(FETCH_CHECK_SAMPLE_PDF, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        mode,
        offset,
      },
    },
  });

  const { data: base64data = '', mimetype = '', name } = data?.sample || {};
  const dataUri = getDataUri(base64data, mimetype);

  const handleOnPrint = useCallback(() => {
    downloadFileByBase64(base64data, mimetype, name);

    notification().info(
      t('modal.printChecksSamplePreview.notification.printed')
    );

    onClose();
  }, [base64data, mimetype, name, onClose, t]);

  return (
    <ModalPage onClose={onClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.printChecksSamplePreview.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Fieldset
              title={t('modal.printChecksSamplePreview.fieldset.title')}
              className={styles.fieldset}
            >
              <PdfViewer file={dataUri} />
            </Fieldset>
            <ButtonGroup>
              <Button onClick={onClose}>
                <I18n id="modal.printChecksSamplePreview.cancel" />
              </Button>
              <Button skin="primary" type="submit" onClick={handleOnPrint}>
                <I18n id="modal.printChecksSamplePreview.submit" />
              </Button>
            </ButtonGroup>
          </>
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
