import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Flex } from '@appclose/ui';
import { Loader } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import Actions, { Action } from 'components/common/Actions';
import { PermissionGuard } from 'components/common/PermissionGuard';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import { TIME_ENTRY_MODAL } from 'constants/modals';
import { openDeleteTimeEntryConfirmAction } from 'store/actions';
import { ExpenseStatuses } from '__generated__/globalTypes';
import { openModal } from 'controllers/modal';
import { I18n } from 'i18n';
import useListFiles from 'hooks/useListFiles';
import { Entities } from 'constants/entities';

import {
  FetchTimeEntryDetailsQuery,
  FetchTimeEntryDetailsQueryVariables,
} from './__generated__/TimeEntryDetailsModal.gql';
import TimeEntryDetails from './components/TimeEntryDetails';
import { FETCH_TIME_ENTRY } from './TimeEntryDetailsModal.gql';
import { TimeEntryDetailsModalPropsType } from './TimeEntryDetailsModal.types';

export default function TimeEntryDetailsModal({
  id,
  onClose,
}: TimeEntryDetailsModalPropsType) {
  const dispatch = useDispatch();
  const { loading, data } = useQuery<
    FetchTimeEntryDetailsQuery,
    FetchTimeEntryDetailsQueryVariables
  >(FETCH_TIME_ENTRY, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
  const timeEntry = data?.timeEntryDetails;
  const { filesLoading, files } = useListFiles({
    entityType: Entities.EXPENSE,
    input: { filter: { entityIds: [id] } },
  });
  const actionsAllowed =
    !filesLoading && timeEntry
      ? [ExpenseStatuses.BILLABLE, ExpenseStatuses.NOT_BILLABLE].includes(
          timeEntry.status
        )
      : false;

  const handleOnOpenEditModal = useCallback(
    () =>
      openModal(TIME_ENTRY_MODAL, {
        timeEntryId: id,
      }),
    [id]
  );

  const handleOnOpenDeleteConfirm = useCallback(
    () =>
      dispatch(
        openDeleteTimeEntryConfirmAction({
          id,
          onSuccess() {
            onClose();
          },
        })
      ),
    [dispatch, id, onClose]
  );

  return (
    <ModalPage onClose={onClose}>
      <ModalPageHeader>
        <Flex alignItems="center" justify="space-between">
          <ModalPageTitle>
            <I18n id="modal.timeEntryDetails.title" />
          </ModalPageTitle>
          {actionsAllowed && (
            <Actions>
              <PermissionGuard
                resource={PermissionResources.TIME_ENTRY}
                action={PermissionActions.UPDATE}
              >
                <Action type="edit" onClick={handleOnOpenEditModal} />
              </PermissionGuard>
              <PermissionGuard
                resource={PermissionResources.TIME_ENTRY}
                action={PermissionActions.DELETE}
              >
                <Action type="delete" onClick={handleOnOpenDeleteConfirm} />
              </PermissionGuard>
            </Actions>
          )}
        </Flex>
      </ModalPageHeader>
      <ModalPageContent>
        {loading || filesLoading ? (
          <Loader />
        ) : (
          timeEntry && (
            <TimeEntryDetails
              timeEntryDetails={timeEntry}
              files={files?.items}
            />
          )
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
