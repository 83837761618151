import React, { useContext } from 'react';
import { Button, ButtonGroup, Note } from '@appclose/ui';
import { Form, Fieldset } from '@appclose/core';

import { WizardContext } from 'components/common/Wizard';
import { I18n, useIntl } from 'i18n';

import LedgerBalancesFormArrayField from './components/LedgerBalancesFormArrayField';
import { ContactsAndMattersStepFormSchema } from './ContactsAndMattersStep.schema';
import {
  ContactsAndMattersStepFormValuesType,
  ContactsAndMattersStepPropsType,
} from './ContactsAndMattersStep.types';

export default function ContactsAndMattersStep({
  initialValues,
  onSetTransactions,
  onChange,
}: ContactsAndMattersStepPropsType) {
  const { t } = useIntl();
  const { goToNextStep, goToPrevStep } = useContext(WizardContext);
  const handleOnSubmit = (values: ContactsAndMattersStepFormValuesType) => {
    onSetTransactions(values);
    goToNextStep();
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      onChange={onChange}
      validationSchema={ContactsAndMattersStepFormSchema}
    >
      {() => (
        <>
          <Fieldset
            title={t(
              'modal.trustSetupBalancesStep.contactsMattersStep.fieldset.contactsMatters'
            )}
          >
            <Note theme="important">
              <I18n id="modal.trustSetupBalancesStep.contactsMattersStep.note" />
            </Note>
          </Fieldset>
          <LedgerBalancesFormArrayField />
          <ButtonGroup>
            <Button onClick={goToPrevStep}>
              <I18n id="modal.trustSetupBalancesStep.contactsMattersStep.button.back" />
            </Button>
            <Button type="submit" skin="primary">
              <I18n id="modal.trustSetupBalancesStep.contactsMattersStep.button.continue" />
            </Button>
          </ButtonGroup>
        </>
      )}
    </Form>
  );
}
