import React, { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, ButtonGroup, Note } from '@appclose/ui';
import { Loader, Fieldset } from '@appclose/core';
import { getDataUri } from '@appclose/lib';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import PdfViewer from 'components/common/PdfViewer';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { I18n, useIntl } from 'i18n';

import {
  FetchDepositSlipPdfQuery,
  FetchDepositSlipPdfQueryVariables,
} from './__generated__/PrintDepositsPreviewModal.gql';
import { FETCH_DEPOSIT_SLIP_PDF } from './PrintDepositsPreviewModal.gql';
import { PrintDepositsPreviewModalPropsType } from './PrintDepositsPreviewModal.types';
import styles from './PrintDepositsPreviewModal.module.scss';

export default function PrintDepositsPreviewModal({
  onClose,
  depositsIds,
  date,
}: PrintDepositsPreviewModalPropsType) {
  const { t } = useIntl();
  const [showPdfLengthWarning, setShowPdfLengthWarning] = useState(false);

  const { loading, data } = useQuery<
    FetchDepositSlipPdfQuery,
    FetchDepositSlipPdfQueryVariables
  >(FETCH_DEPOSIT_SLIP_PDF, {
    variables: {
      depositsIds,
      date,
    },
    fetchPolicy: 'network-only',
  });

  const { url, base64: { data: base64data = '', mimetype = '' } = {} } =
    data?.pdf || {};
  const dataUri = getDataUri(base64data, mimetype);

  const { onConfirmClose } = useCloseConfirm({
    onClose,
    defaultShowConfirm: true,
  });

  const handleOnPrint = useCallback(async () => {
    notification().info(t('modal.printDepositsPreview.notification.info'));
    onClose();
  }, [onClose, t]);

  const onPdfDocumentLoaded = useCallback(
    (count: number) => setShowPdfLengthWarning(count > 1),
    [setShowPdfLengthWarning]
  );

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.printDepositsPreview.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Fieldset
              title={t('modal.printDepositsPreview.fieldset.title')}
              className={styles.fieldset}
            >
              <PdfViewer file={dataUri} onLoadSuccess={onPdfDocumentLoaded} />
              {showPdfLengthWarning && (
                <Note theme="important" offset={{ top: 60 }}>
                  <I18n id="modal.printDepositsPreview.fieldset.notification" />
                </Note>
              )}
            </Fieldset>
            <ButtonGroup>
              <Button onClick={onConfirmClose}>
                <I18n id="modal.printDepositsPreview.cancel" />
              </Button>
              <Button
                skin="primary"
                link={url || ''}
                target="_blank"
                onClick={handleOnPrint}
              >
                <I18n id="modal.printDepositsPreview.print" />
              </Button>
            </ButtonGroup>
          </>
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
