import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { Button, ButtonGroup } from '@appclose/ui';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import useQboIntegration from 'hooks/useQboIntegration';
import { TRUST_ROUTE } from 'constants/routes';
import { I18n } from 'i18n';

import {
  SkipQboIntegrationStepMutation,
  SkipQboIntegrationStepMutationVariables,
} from './__generated__/TrustSetupQboStepModal.gql';
import { SKIP_QBO_INTEGRATION_STEP } from './TrustSetupQboStepModal.gql';
import { TrustSetupQboStepModalPropsType } from './TrustSetupQboStepModal.types';
import styles from './TrustSetupQboStepModal.module.scss';

export default function TrustSetupQboStepModal({
  onClose,
}: TrustSetupQboStepModalPropsType) {
  const { loading, onConnect } = useQboIntegration({
    redirectRoute: TRUST_ROUTE,
  });

  const [skipQboIntegrationStep, { loading: skipLoading }] = useMutation<
    SkipQboIntegrationStepMutation,
    SkipQboIntegrationStepMutationVariables
  >(SKIP_QBO_INTEGRATION_STEP);

  const handleOnConnect = useCallback(() => {
    onConnect();
  }, [onConnect]);

  const handleOnSkip = useCallback(async () => {
    await skipQboIntegrationStep();

    onClose();
  }, [onClose, skipQboIntegrationStep]);

  return (
    <ModalPage onClose={onClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.trustSetupQboStep.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        <p className={styles.integrationInfo}>
          <I18n id="modal.trustSetupQboStep.info" />
        </p>
        <ButtonGroup>
          <Button
            onClick={handleOnConnect}
            skin="primary"
            loading={loading}
            disabled={skipLoading}
          >
            <I18n id="modal.trustSetupQboStep.button.connect" />
          </Button>
          <Button
            onClick={handleOnSkip}
            disabled={loading}
            loading={skipLoading}
          >
            <I18n id="modal.trustSetupQboStep.button.skip" />
          </Button>
        </ButtonGroup>
      </ModalPageContent>
    </ModalPage>
  );
}
