import React, { useCallback, useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { history, Loader } from '@appclose/core';

import { useIntl } from 'i18n';
import { LEAD_ROUTE } from 'constants/routes';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import useUpdateFiles from 'hooks/useUpdateFiles';
import { Entities } from 'constants/entities';
import { INITIAL_ENTITY_LEAD, INITIAL_INDIVIDUAL_LEAD } from 'constants/leads';
import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';

import LeadForm, { LeadFormValuesType } from './components/LeadForm';
import {
  CREATE_LEAD,
  FETCH_LEAD,
  FETCH_PROFILE,
  UPDATE_LEAD,
  FETCH_LEAD_CONTACT,
} from './LeadModal.gql';
import { LeadModalPropsType, LeadModalTabs } from './LeadModal.types';
import {
  CreateLeadMutation,
  CreateLeadMutationVariables,
  FetchLeadQuery,
  FetchLeadQueryVariables,
  FetchProfileQuery,
  FetchProfileQueryVariables,
  UpdateLeadMutation,
  UpdateLeadMutationVariables,
  FetchLeadContactQuery,
  FetchLeadContactQueryVariables,
} from './__generated__/LeadModal.gql';
import {
  getTitle,
  getEditLeadInitialValues,
  getInboxLeadInitialValues,
  getLeadContactInitialValues,
} from './LeadModal.utils';
import {
  DeleteInboxRecordMutation,
  DeleteInboxRecordMutationVariables,
} from 'gql/shared/__generated__/lead.gql';
import { DELETE_INBOX_RECORD } from 'gql/shared/lead.gql';

export default function LeadModal({
  id,
  contactId,
  inboxRecord,
  onClose,
  defaultTab,
  shouldOpenNew = false,
}: LeadModalPropsType) {
  const { t } = useIntl();
  const { onUpdateFiles } = useUpdateFiles(Entities.LEAD);
  const isEdit = !!id;
  const { loading: profileLoading, data: profileData } = useQuery<
    FetchProfileQuery,
    FetchProfileQueryVariables
  >(FETCH_PROFILE);
  const [createLead] = useMutation<
    CreateLeadMutation,
    CreateLeadMutationVariables
  >(CREATE_LEAD);
  const [updateLead] = useMutation<
    UpdateLeadMutation,
    UpdateLeadMutationVariables
  >(UPDATE_LEAD);
  const [deleteInboxRecord] = useMutation<
    DeleteInboxRecordMutation,
    DeleteInboxRecordMutationVariables
  >(DELETE_INBOX_RECORD);
  const { loading: leadLoading, data: leadData } = useQuery<
    FetchLeadQuery,
    FetchLeadQueryVariables
  >(FETCH_LEAD, {
    variables: { id: id as string },
    fetchPolicy: 'network-only',
    skip: !isEdit,
  });
  const { loading: leadContactLoading, data: leadContactData } = useQuery<
    FetchLeadContactQuery,
    FetchLeadContactQueryVariables
  >(FETCH_LEAD_CONTACT, {
    variables: { id: contactId as string },
    fetchPolicy: 'network-only',
    skip: !contactId,
  });

  const { onConfirmClose, onFormChange } = useCloseConfirm({ onClose });

  const handleOnSubmit = useCallback(
    async ({ individual, entity, selectedTab, files }: LeadFormValuesType) => {
      let input;
      const isEntity = selectedTab === LeadModalTabs.ENTITY;

      if (isEntity) {
        const {
          entityName,
          website,
          contacts,
          leadSource,
          originatingAttorney,
          ...common
        } = entity!;

        input = {
          ...common,
          originatingAttorneyId: originatingAttorney.id,
          leadSource,
          entity: {
            entityName,
            website,
            contacts,
          },
        };
      } else {
        const {
          firstName,
          lastName,
          middleName,
          email,
          originatingAttorney,
          leadSource,
          ...common
        } = individual!;

        input = {
          ...common,
          originatingAttorneyId: originatingAttorney.id,
          leadSource,
          individual: {
            firstName,
            lastName,
            middleName,
            email,
          },
        };
      }

      let leadId = id;

      if (isEdit) {
        await updateLead({
          variables: {
            id,
            input,
          } as UpdateLeadMutationVariables,
        });

        notification().entityUpdated(
          t('modal.lead.notification.update.success')
        );
      } else {
        const result = await createLead({
          variables: {
            input: {
              linkedContactId: contactId,
              isEntity,
              ...input,
            },
          } as CreateLeadMutationVariables,
        });
        leadId = result.data?.createLead.id;

        notification().entityCreated(
          t('modal.lead.notification.create.success')
        );
      }

      if (leadId && files) {
        await onUpdateFiles({ entityId: leadId, ...files });
      }

      if (inboxRecord?.id) {
        await deleteInboxRecord({ variables: { id: inboxRecord.id } });
      }

      onClose();

      if (shouldOpenNew && !isEdit && leadId) {
        history.push(generatePath(LEAD_ROUTE, { id: leadId }));
      }
    },
    [
      id,
      isEdit,
      inboxRecord?.id,
      onClose,
      shouldOpenNew,
      updateLead,
      t,
      createLead,
      contactId,
      onUpdateFiles,
      deleteInboxRecord,
    ]
  );

  const initialValues: LeadFormValuesType = useMemo(() => {
    const initial = {
      selectedTab: defaultTab || LeadModalTabs.INDIVIDUAL,
      entity: {
        ...INITIAL_ENTITY_LEAD,
        originatingAttorney: profileData?.getProfile,
      },
      individual: {
        ...INITIAL_INDIVIDUAL_LEAD,
        originatingAttorney: profileData?.getProfile,
      },
      isImportant: false,
      fromInbox: !!inboxRecord,
    } as LeadFormValuesType;

    if (isEdit && leadData?.getLead) {
      return getEditLeadInitialValues(initial, leadData.getLead);
    }

    if (inboxRecord) {
      return getInboxLeadInitialValues(initial, inboxRecord);
    }

    if (leadContactData?.getContact) {
      return getLeadContactInitialValues(initial, leadContactData.getContact);
    }

    return initial;
  }, [
    defaultTab,
    inboxRecord,
    isEdit,
    leadContactData?.getContact,
    leadData?.getLead,
    profileData?.getProfile,
  ]);

  const loading = leadLoading || profileLoading || leadContactLoading;

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        {!loading && (
          <ModalPageTitle>{getTitle(t, leadData?.getLead)}</ModalPageTitle>
        )}
      </ModalPageHeader>
      <ModalPageContent>
        {loading ? (
          <Loader />
        ) : (
          <LeadForm
            initialValues={initialValues}
            isEdit={isEdit}
            defaultTab={initialValues.selectedTab}
            onSubmit={handleOnSubmit}
            onCancel={onConfirmClose}
            onChange={onFormChange}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
