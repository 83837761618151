import React, { useCallback, useMemo } from 'react';
import { Button, ButtonGroup, Offset } from '@appclose/ui';
import {
  Form,
  FormGroup,
  InputFormField,
  Loader,
  RadioFormField,
} from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { I18n, useIntl } from 'i18n';
import notification from 'controllers/notification';
import CalendarSelectFormField from './components/CalendarSelectFormField';

import {
  CalendarSyncFormValuesType,
  CalendarSyncModalPropsType,
  CalendarType,
} from './CalendarSyncModal.types';

import { CalendarSyncModalSchema } from './CalendarSyncModal.schema';

export default function CalendarSyncModal({
  calendarName: propsCalendarName,
  loading,
  calendars,
  onSubmit,
  onClose,
}: CalendarSyncModalPropsType) {
  const { t } = useIntl();
  const { onConfirmClose, onFormChange } = useCloseConfirm({ onClose });

  const initialValues = useMemo<CalendarSyncFormValuesType>(
    () => ({ type: CalendarType.CUSTOM }),
    []
  );

  const handleOnSubmit = useCallback(
    async ({ type, calendarId, calendarName }: CalendarSyncFormValuesType) => {
      await onSubmit(
        type === CalendarType.CUSTOM ? { calendarName } : { calendarId }
      );

      notification().successAction(
        t('modal.calendarSync.notification.synced', {
          calendarName: propsCalendarName,
        })
      );

      onClose();
    },
    [onClose, onSubmit, propsCalendarName, t]
  );

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.calendarSync.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loading ? (
          <Loader />
        ) : (
          <Form<CalendarSyncFormValuesType>
            initialValues={initialValues}
            validationSchema={CalendarSyncModalSchema}
            onChange={onFormChange}
            onSubmit={handleOnSubmit}
          >
            {({ isSubmitting, values: { type } }) => (
              <>
                <Offset bottom="xl">
                  <FormGroup>
                    <RadioFormField
                      label={t('modal.calendarSync.form.createNewCalendar')}
                      value={CalendarType.CUSTOM}
                      name="type"
                    />
                  </FormGroup>
                  <FormGroup>
                    <InputFormField
                      name="calendarName"
                      placeholder={t('modal.calendarSync.form.calendarName')}
                      disabled={type !== CalendarType.CUSTOM}
                    />
                  </FormGroup>
                </Offset>
                <FormGroup>
                  <RadioFormField
                    label={t('modal.calendarSync.form.linkExistingCalendar')}
                    value={CalendarType.EXIST}
                    name="type"
                  />
                </FormGroup>
                <FormGroup>
                  <CalendarSelectFormField
                    name="calendarId"
                    calendars={calendars}
                    placeholder={t('modal.calendarSync.form.googleCalendar')}
                    showPlaceholder="always"
                    disabled={type !== CalendarType.EXIST}
                  />
                </FormGroup>
                <Offset top="xxxl">
                  <ButtonGroup>
                    <Button onClick={onConfirmClose}>
                      <I18n id="modal.calendarSync.form.cancel" />
                    </Button>
                    <Button skin="primary" type="submit" loading={isSubmitting}>
                      <I18n id="modal.calendarSync.form.submit" />
                    </Button>
                  </ButtonGroup>
                </Offset>
              </>
            )}
          </Form>
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
