import { matchPath } from 'react-router-dom';

import { getMode } from 'controllers/mode';

import { TOUR_STEPS_NAME, TourType, StepsOptionsType } from './Tour.types';
import { TOURS } from './Tour.constants';

const TOUR_STEP_ATTRIBUTE_NAME = 'data-tour-step';

export function getTourSteps(
  pathname: string,
  options: StepsOptionsType,
) {
  const mode = getMode();
  const { steps } =
    (mode &&
      Object.entries<TourType>(TOURS[mode]).find(
        ([k, { exact }]) => matchPath(pathname, { path: k, exact }) !== null
      ))?.[1] || {};

  return steps?.(options);
}

export function getTourStepAttribute(stepName: TOUR_STEPS_NAME) {
  return {
    [TOUR_STEP_ATTRIBUTE_NAME]: stepName,
  };
}

export function getTourStepSelector(stepName: TOUR_STEPS_NAME) {
  return `[${TOUR_STEP_ATTRIBUTE_NAME}="${stepName}"]`;
}
