import React, { useCallback } from 'react';

import { ControlsPropsType } from './Controls.types';
import { Button, Grid, Offset, QuantityInput } from '@appclose/ui';
import { I18n } from 'i18n';

export default function Controls({
  offset,
  bound,
  onChange,
}: ControlsPropsType) {
  const handleOnReset = useCallback(() => onChange([0, 0]), [onChange]);

  return (
    <div>
      <Offset bottom="l">
        <Grid columns="repeat(2, auto)" gap={[32, 20]} align="center">
          <span>
            <I18n id="modal.fineTuneAlignment.controls.horizontal" />
          </span>
          <QuantityInput
            value={offset[0]}
            min={bound.left}
            max={bound.right}
            onChange={(v) => onChange([v, offset[1]])}
          />
          <span>
            <I18n id="modal.fineTuneAlignment.controls.vertical" />
          </span>
          <QuantityInput
            value={offset[1]}
            min={bound.top}
            max={bound.bottom}
            onChange={(v) => onChange([offset[0], v])}
          />
        </Grid>
      </Offset>
      <Button onClick={handleOnReset}>
        <I18n id="modal.fineTuneAlignment.controls.reset" />
      </Button>
    </div>
  );
}
