import { gql } from '@apollo/client';

export const CLOSE_MATTER = gql`
  mutation CloseMatter($id: ID!) {
    closeMatter(closeMatterInput: { id: $id }) {
      id
      status
    }
  }
`;
