import { gql } from '@apollo/client';

export const DISBURSEMENT_TO_OPERATING = gql`
  mutation DisbursementToOperating(
    $input: CreateDisbursementToOperatingInput!
  ) {
    disbursement: createDisbursementToOperating(
      createDisbursementInput: $input
    ) {
      id
      type
    }
  }
`;
