import React, { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { useQuery } from '@apollo/client';
import {
  permissionProvider,
  SelectFormField,
  useFormContext,
  usePopupModal,
} from '@appclose/core';

import { OrderTypes, ThirdPartyPayeesStatus } from '__generated__/globalTypes';
import ThirdPartyPayeeModal from 'components/modals/popups/ThirdPartyPayeeModal';
import { PermissionActions, PermissionResources } from 'constants/permissions';
import { EntryTypes, EventNames, SourceTypes } from 'constants/analytics';
import { track } from 'controllers/analytics';
import useLastUsage from 'hooks/useLastUsage';
import { useIntl } from 'i18n';

import { FETCH_THIRD_PARTY_PAYEES } from './ThirdPartyPayeeSelectFormField.gql';
import {
  FetchThirdPartyPayeesQuery,
  FetchThirdPartyPayeesQueryVariables,
} from './__generated__/ThirdPartyPayeeSelectFormField.gql';
import {
  SelectThirdPartyPayeeType,
  ThirdPartyPayeeSelectFormFieldPropsType,
} from './ThirdPartyPayeeSelectFormField.types';

export default function ThirdPartyPayeeSelectFormField({
  name = 'thirdPartyPayee',
  label,
  placeholder,
  showLabel = 'onFocus',
  showPlaceholder = 'always',
  allowAddNew = false,
  onChange,
  ...rest
}: ThirdPartyPayeeSelectFormFieldPropsType) {
  const { t } = useIntl();
  const { lastUsages } = useLastUsage('thirdPartyPayee');
  const [search, setSearch] = useState('');
  const { setFieldValue } = useFormContext<any>();
  const { loading, data, fetchMore, refetch } = useQuery<
    FetchThirdPartyPayeesQuery,
    FetchThirdPartyPayeesQueryVariables
  >(FETCH_THIRD_PARTY_PAYEES, {
    variables: {
      filter: {
        search,
        statuses: [ThirdPartyPayeesStatus.ACTIVE],
      },
      order: {
        forceTopIds: lastUsages,
        payeeName: OrderTypes.ASC,
      },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });
  const thirdPartyPayees = useMemo(
    () => data?.listThirdPartyPayees.items || [],
    [data]
  );

  const withAddNew =
    allowAddNew &&
    permissionProvider().hasPermission(
      PermissionResources.THIRD_PARTY_PAYEES,
      PermissionActions.CREATE
    );

  const { openPopupModal } = usePopupModal(ThirdPartyPayeeModal, (payee) => {
    setFieldValue(name, payee);
    refetch();
  });

  const openAddNewModal = useCallback(() => {
    track(EventNames.ADD_NEW_ENTRY, {
      entry_type: EntryTypes.THIRD_PARTY_PAYEE,
      source_type: SourceTypes.POPUP_MODAL,
    });

    openPopupModal();
  }, [openPopupModal]);

  const onLoadMore = useCallback(async () => {
    const { data } = await fetchMore({
      variables: {
        skip: thirdPartyPayees.length,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => ({
        listThirdPartyPayees: {
          ...previousResult.listThirdPartyPayees,
          items: [
            ...previousResult.listThirdPartyPayees.items,
            ...(fetchMoreResult?.listThirdPartyPayees.items || []),
          ],
        },
      }),
    });

    return thirdPartyPayees.length !== data.listThirdPartyPayees.total;
  }, [thirdPartyPayees.length, fetchMore]);

  const onSearchChange = useMemo(
    () => debounce((value: string) => setSearch(value), 500),
    [setSearch]
  );
  const optionLabelResolver = useCallback(
    ({ payeeName }: SelectThirdPartyPayeeType) => payeeName,
    []
  );
  const selectedOptionsResolver = useCallback(
    (payees: SelectThirdPartyPayeeType[]) =>
      payees.map(({ payeeName }) => payeeName).join(', '),
    []
  );

  return (
    <SelectFormField
      name={name}
      label={
        label === undefined ? t('field.thirdPartyPayeeSelect.label') : label
      }
      placeholder={placeholder || t('field.thirdPartyPayeeSelect.placeholder')}
      showLabel={showLabel}
      showPlaceholder={showPlaceholder}
      onChange={onChange}
      {...rest}
      clearable
      withSearch
      isLoading={loading}
      onSearchChange={onSearchChange}
      options={thirdPartyPayees}
      optionLabelResolver={optionLabelResolver}
      selectedOptionsResolver={selectedOptionsResolver}
      onLoadOptions={onLoadMore}
      onAdd={withAddNew ? openAddNewModal : undefined}
    />
  );
}
