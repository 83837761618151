import { gql } from '@apollo/client';

export const FETCH_LAST_RECONCILIATION = gql`
  query FetchLastReconciliation {
    reconciliation: listReconciliations(
      listReconciliationInput: {
        take: 1
        order: { statementDate: DESC }
        filter: { statuses: [FINISHED] }
      }
    ) {
      items {
        id
        statementDate
      }
    }
  }
`;
