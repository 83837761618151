import React from 'react';
import {
  FormGroup,
  InputFormField,
  UpperFirstInputFormField,
  TextAreaFormField,
  ToggleFormField,
  Fieldset,
} from '@appclose/core';

import { useIntl } from 'i18n';
import FormGrid from 'components/common/FormGrid';
import PhoneFieldset from 'components/form/PhoneFieldset';
import FilesFormField from 'components/fields/FilesFormField';
import LeadSourceSelectFormField from 'components/fields/LeadSourceSelectFormField';
import TeamMemberSelectFormField from 'components/fields/TeamMemberSelectFormField';
import { Entities } from 'constants/entities';

import { IndividualFieldsetPropType } from './IndividualFieldset.types';

import styles from './IndividualFieldset.module.scss';

const IndividualFieldset = ({ id }: IndividualFieldsetPropType) => {
  const { t } = useIntl();

  return (
    <>
      <Fieldset title={t('modal.lead.form.info')}>
        <FormGrid>
          <UpperFirstInputFormField
            name="individual.firstName"
            label={t('modal.lead.form.info.firstName')}
          />
          <UpperFirstInputFormField
            name="individual.middleName"
            label={t('modal.lead.form.info.middleName')}
          />
          <UpperFirstInputFormField
            name="individual.lastName"
            label={t('modal.lead.form.info.lastName')}
          />
          <InputFormField
            name="individual.email"
            label={t('modal.lead.form.info.email')}
          />
        </FormGrid>
      </Fieldset>
      <PhoneFieldset name="individual.phoneNumbers" allowRemoveLast />
      <Fieldset title={t('modal.lead.form.other')}>
        <FormGroup>
          <TextAreaFormField
            name="individual.notes"
            label={t('modal.lead.form.notes')}
          />
        </FormGroup>
        <FormGroup>
          <FormGrid>
            <TeamMemberSelectFormField
              name="individual.originatingAttorney"
              label={t('modal.lead.form.originatingAttorney')}
            />
            <LeadSourceSelectFormField name="individual.leadSource" />
          </FormGrid>
        </FormGroup>
        <FormGroup>
          <FilesFormField entityType={Entities.LEAD} entityId={id} />
        </FormGroup>
        <FormGroup className={styles.toggle}>
          <ToggleFormField
            name="individual.isImportant"
            label={t('modal.lead.form.toggle')}
          />
        </FormGroup>
      </Fieldset>
    </>
  );
};

export default IndividualFieldset;
