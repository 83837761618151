import React, { ChangeEvent, useMemo } from 'react';
import { Checkbox, TableCell, TableHead, TableRow } from '@appclose/ui';
import { useFormContext } from '@appclose/core';

import { I18n } from 'i18n';

import { PrintDepositsFormValuesType } from '../../../../PrintDepositsForm.types';
import { PrintableDepositsFieldTableHeaderPropsType } from './PrintableDepositsFieldTableHeader.types';

export default function PrintableDepositsFieldTableHeader({
  printableDeposits,
}: PrintableDepositsFieldTableHeaderPropsType) {
  const { values, setFieldValue } = useFormContext<
    PrintDepositsFormValuesType
  >();

  const selectedAll = useMemo(
    () =>
      (printableDeposits?.length &&
        printableDeposits.every(({ id }) =>
          values.deposits?.some(({ id: selectedId }) => selectedId === id)
        )) ||
      false,
    [printableDeposits, values.deposits]
  );

  const onSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('deposits', e.target.checked ? printableDeposits : [], true);
  };

  return (
    <TableHead theme="medium">
      <TableRow>
        <TableCell>
          <Checkbox checked={selectedAll} onChange={onSelectAll} />
        </TableCell>
        <TableCell>
          <I18n id="modal.printDeposits.form.deposits.table.type" />
        </TableCell>
        <TableCell>
          <I18n id="modal.printDeposits.form.deposits.table.date" />
        </TableCell>
        <TableCell>
          <I18n id="modal.printDeposits.form.deposits.table.contactMatter" />
        </TableCell>
        <TableCell>
          <I18n id="modal.printDeposits.form.deposits.table.source" />
        </TableCell>
        <TableCell>
          <I18n id="modal.printDeposits.form.deposits.table.paymentMethod" />
        </TableCell>
        <TableCell>
          <I18n id="modal.printDeposits.form.deposits.table.amount" />
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
