import * as Yup from 'yup';
import { EMAIL_VALIDATION_SCHEMA } from '@appclose/core';

import {
  CONTACT_FIRST_NAME_VALIDATION_SCHEMA,
  CONTACT_LAST_NAME_VALIDATION_SCHEMA,
} from 'schemas/validations/contact';

import { ContactFieldsetValuesType } from '../../ContactMatterPopupForm.types';

export const ContactFieldsetSchema = Yup.object().shape<
  ContactFieldsetValuesType
>({
  firstName: CONTACT_FIRST_NAME_VALIDATION_SCHEMA,
  lastName: CONTACT_LAST_NAME_VALIDATION_SCHEMA,
  email: EMAIL_VALIDATION_SCHEMA,
});
