import React from 'react';
import { StaticField, FormGroup, Fieldset } from '@appclose/core';

import FormGrid from 'components/common/FormGrid';
import { getContactName } from 'controllers/contact';
import { useIntl } from 'i18n';

import { ContactMatterDetailsFieldsetPropsType } from './ContactMatterDetailsFieldset.types';

export default function ContactMatterDetailsFieldset({
  contact,
  matter,
}: ContactMatterDetailsFieldsetPropsType) {
  const { t } = useIntl();

  return contact || matter ? (
    <Fieldset title={t('form.contactMatterDetailsFieldset.contactDetails')}>
      <FormGroup>
        <FormGrid>
          {contact && (
            <StaticField title={t('form.contactMatterDetailsFieldset.contact')}>
              {getContactName(contact)}
            </StaticField>
          )}
          {matter && (
            <StaticField title={t('form.contactMatterDetailsFieldset.matter')}>
              {matter.name}
            </StaticField>
          )}
        </FormGrid>
      </FormGroup>
    </Fieldset>
  ) : null;
}
