import React from 'react';

import { useIntl } from 'i18n';

import { LedgerNamePropsType } from './LedgerName.types';
import styles from './LedgerName.module.scss';
import ContactMatter from '../ContactMatter';

export default function LedgerName({ value }: LedgerNamePropsType) {
  const { t } = useIntl();

  if (!value) {
    return null;
  }

  if (
    value.__typename === 'PlainTextType' ||
    value.__typename === 'ThirdPartyPayeeType'
  ) {
    return (
      <p className={styles.name}>
        {value?.name || t('contactMatterName.multiple')}
      </p>
    );
  }

  const contact =
    value.__typename === 'ContactType'
      ? value
      : value.__typename === 'MatterType'
      ? value.contact
      : undefined;

  const matter = value.__typename === 'MatterType' ? value : null;

  return <ContactMatter contact={contact} matter={matter} />;
}
