import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { useIsDesktopDevice, useIsMobileDevice } from '@appclose/core';

export default function useSticky<T extends HTMLElement>({
  targets = [],
  useMobile = true,
  useDesktop = true,
  zIndex = 1,
}: {
  targets?: (HTMLElement | null)[];
  useMobile?: boolean;
  useDesktop?: boolean;
  zIndex?: number;
} = {}) {
  const isMobile = useIsMobileDevice();
  const isDesktop = useIsDesktopDevice();
  const targetRef = useRef<T>(null);
  const [isStuck, setIsStuck] = useState(false);

  const updateOffset = useCallback(() => {
    if ((useMobile && isMobile) || (useDesktop && isDesktop)) {
      const offset = targets.reduce(
        (sum, target) => sum + (target?.offsetHeight || 0),
        0
      );

      if (targetRef.current) {
        const node = (targetRef.current.firstElementChild ||
          targetRef.current) as HTMLElement;

        node.style.marginTop = isStuck ? `${offset}px` : '0px';
      }
    }
  }, [isStuck, targets, useMobile, isMobile, useDesktop, isDesktop]);

  const debouncedUpdateOffset = useMemo(() => debounce(updateOffset, 150), [
    updateOffset,
  ]);

  useEffect(() => {
    if (targetRef.current) {
      const target = targetRef.current;

      target.style.width = '100%';
      target.style.zIndex = String(zIndex);
      target.style.position = 'sticky';
      target.style.top = '-1px';
      target.style.paddingTop = '0.1px';

      const observer = new IntersectionObserver(
        ([e]) => {
          setIsStuck(e.boundingClientRect.top === -1);
        },
        { threshold: [1] }
      );

      observer.observe(target);

      return () => {
        observer.unobserve(target);
      };
    }
  }, [zIndex]);

  useEffect(() => {
    window.addEventListener('resize', debouncedUpdateOffset, false);

    return () => {
      window.removeEventListener('resize', debouncedUpdateOffset, false);
    };
  }, [updateOffset, debouncedUpdateOffset]);

  useEffect(() => {
    updateOffset();
  }, [isDesktop, isMobile, useMobile, useDesktop, updateOffset]);

  return {
    targetRef,
    isStuck,
  };
}
