import { gql } from '@apollo/client';

export const CREATE_RECONCILIATION = gql`
  mutation CreateReconciliation($input: CreateReconciliationInput!) {
    createReconciliation(createReconciliationInput: $input) {
      id
    }
  }
`;

export const FETCH_LAST_FINISHED_RECONCILIATION = gql`
  query FetchLastFinishedReconciliation {
    reconciliation: listReconciliations(
      listReconciliationInput: {
        take: 1
        order: { statementDate: DESC }
        filter: { statuses: [FINISHED] }
      }
    ) {
      items {
        id
        statementDate
      }
    }
  }
`;

export const FETCH_RECONCILIATION = gql`
  query FetchReconciliation($id: ID!) {
    reconciliation: getReconciliation(id: $id) {
      id
      statementBalance
      statementDate
      transactions {
        id
        date
      }
    }
  }
`;

export const UPDATE_RECONCILIATION = gql`
  mutation UpdateReconciliation($input: UpdateReconciliationInput!) {
    updateReconciliation(updateReconciliationInput: $input) {
      id
    }
  }
`;
