import React from 'react';
import { Button, Flex } from '@appclose/ui';
import { StaticField, useFormContext } from '@appclose/core';
import { EditIcon } from '@appclose/icons';

import { openModal } from 'controllers/modal';
import { PRINT_SETTINGS_MODAL } from 'constants/modals';
import usePrintingChecksModes from 'hooks/usePrintingChecksModes';
import { I18n, useIntl } from 'i18n';

import { PrintCheckFormValuesType } from '../../PrintChecksForm.types';

export default function PrintSettings() {
  const { t } = useIntl();
  const { getPrintingChecksModesLabel } = usePrintingChecksModes();
  const { values } = useFormContext<PrintCheckFormValuesType>();
  const { mode } = values;

  return (
    <StaticField title={t('modal.printChecks.form.printSettings.title')}>
      <Flex justify="space-between" alignItems="baseline">
        <span>
          <I18n
            id="modal.printChecks.form.printSettings.info"
            values={{ mode: getPrintingChecksModesLabel(mode) }}
          />
        </span>
        <Button
          skin="shell"
          onClick={() => {
            openModal(PRINT_SETTINGS_MODAL, { openPrintCheck: false }, values);
          }}
        >
          <EditIcon />
        </Button>
      </Flex>
    </StaticField>
  );
}
