import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { dateManager, traceError } from '@appclose/core';

import { track } from 'controllers/analytics';
import { openModal } from 'controllers/modal';
import { EntryTypes, DemoEventNames } from 'constants/analytics';
import { DISBURSEMENT_DETAILS_MODAL } from 'constants/modals';
import { SERVER_DATE_FORMAT } from 'constants/date';
import usePrintChecks from 'hooks/usePrintChecks';
import useCanCreateTrustTransaction from 'hooks/useCanCreateTrustTransaction';
import notification from 'controllers/notification';
import { useIntl } from 'i18n';

import { DisbursementToContactPropsType } from './DisbursementToContact.types';
import { DISBURSEMENT_TO_CONTACT } from './DisbursementToContact.gql';
import {
  DisbursementToContactMutation,
  DisbursementToContactMutationVariables,
} from './__generated__/DisbursementToContact.gql';
import DisbursementToContactForm, {
  DisbursementToContactFormActionsType,
  DisbursementToContactFormValuesType,
} from './components/DisbursementToContactForm';

export default function DisbursementToContact({
  onCancel,
  onClose,
  onChange,
}: DisbursementToContactPropsType) {
  const { t } = useIntl();
  const { openPrintChecks } = usePrintChecks();
  const {
    getCanCreateTrustTransaction,
    openUnableToCreateTrustTransactionPopup,
  } = useCanCreateTrustTransaction('disbursement', onClose);

  const [disbursementToContact] = useMutation<
    DisbursementToContactMutation,
    DisbursementToContactMutationVariables
  >(DISBURSEMENT_TO_CONTACT);

  const handleOnSubmit = useCallback(
    async (
      values: DisbursementToContactFormValuesType,
      { setSubmitting }: DisbursementToContactFormActionsType
    ) => {
      const canCreate = getCanCreateTrustTransaction(values.date);

      if (!canCreate) {
        openUnableToCreateTrustTransactionPopup();

        return;
      }

      try {
        const { printMode, contact, matter, ...props } = values;

        const { data } = await disbursementToContact({
          variables: {
            input: {
              contactId: contact.id,
              matterId: matter?.id,
              ...props,
            },
          },
        });

        notification().entityCreated(
          t('modal.disbursement.notification.contact.created')
        );

        track(DemoEventNames.DEMO_ENTRY_CREATED, {
          entry_type: EntryTypes.TRUST_DISBURSEMENT,
        });

        onClose();

        if (printMode) {
          openPrintChecks(data?.disbursement.id);
        } else {
          openModal(DISBURSEMENT_DETAILS_MODAL, data?.disbursement);
        }
      } catch (e) {
        setSubmitting(false);
        traceError(e);
      }
    },
    [
      disbursementToContact,
      getCanCreateTrustTransaction,
      onClose,
      openPrintChecks,
      openUnableToCreateTrustTransactionPopup,
      t,
    ]
  );

  const initialValues = {
    amount: 0,
    date: dateManager().parse().startOf('day').format(SERVER_DATE_FORMAT),
  } as DisbursementToContactFormValuesType;

  return (
    <DisbursementToContactForm
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      onCancel={onCancel}
      onChange={onChange}
    />
  );
}
