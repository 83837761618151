import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Note } from '@appclose/ui';
import { Loader, Fieldset, traceError } from '@appclose/core';

import { PrintingChecksModes } from '__generated__/globalTypes';
import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import usePrintChecks from 'hooks/usePrintChecks';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { I18n, useIntl } from 'i18n';

import PrintChecksPreview from './components/PrintChecksPreview';
import {
  CancelPrintChecksMutation,
  CancelPrintChecksMutationVariables,
} from './__generated__/PrintChecksPreviewModal.gql';
import { CANCEL_PRINT_CHECKS } from './PrintChecksPreviewModal.gql';
import PrintingResultsForm, {
  PrintingResults,
  PrintingResultsFormActionsType,
  PrintingResultsFormValuesType,
} from './components/PrintingResultsForm';
import { PrintChecksModalPropsType } from './PrintChecksPreviewModal.types';
import styles from './PrintChecksPreviewModal.module.scss';

export default function PrintChecksPreviewModal({
  selectedChecks,
  startCheckNumber,
  onClose,
}: PrintChecksModalPropsType) {
  const { t } = useIntl();
  const [isPrinted, setPrinted] = useState(false);
  const { loadingPrintChecksSettings, mode } = usePrintChecks();
  const [cancelPrintChecks] = useMutation<
    CancelPrintChecksMutation,
    CancelPrintChecksMutationVariables
  >(CANCEL_PRINT_CHECKS);

  const { onConfirmClose } = useCloseConfirm({
    onClose,
    defaultShowConfirm: true,
  });

  const handleOnPrint = useCallback(() => {
    setPrinted(true);
  }, []);

  const handleOnSubmitPrintingResult = useCallback(
    async (
      { printResult, reprintStartNo }: PrintingResultsFormValuesType,
      { setSubmitting }: PrintingResultsFormActionsType
    ) => {
      try {
        if (printResult !== PrintingResults.YES) {
          const checkNumber =
            printResult === PrintingResults.SOME
              ? reprintStartNo
              : startCheckNumber || 0;

          await cancelPrintChecks({ variables: { checkNumber } });
        }

        onClose();
      } catch (e) {
        traceError(e);
        setSubmitting(false);
      }
    },
    [cancelPrintChecks, onClose, startCheckNumber]
  );

  const printingResultsInitialValues: PrintingResultsFormValuesType = {
    printResult: PrintingResults.YES,
    reprintStartNo: startCheckNumber || 0,
  };

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.printChecksPreview.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loadingPrintChecksSettings ? (
          <Loader />
        ) : !isPrinted ? (
          <>
            <Fieldset
              title={t('modal.printChecksPreview.printPreview.title')}
              className={styles.fieldset}
            >
              {mode === PrintingChecksModes.STANDARD && (
                <Note theme="important">
                  <I18n id="modal.printChecksPreview.printPreview.note" />
                </Note>
              )}
            </Fieldset>
            <PrintChecksPreview
              onCancel={onConfirmClose}
              onPrint={handleOnPrint}
              startCheckNumber={startCheckNumber}
              selectedChecks={selectedChecks || []}
            />
          </>
        ) : (
          <PrintingResultsForm
            totalChecks={selectedChecks?.length || 0}
            initialValues={printingResultsInitialValues}
            onSubmit={handleOnSubmitPrintingResult}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
