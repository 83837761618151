import * as Yup from 'yup';
import { PHONE_NUMBER_VALIDATION_SCHEMA } from '@appclose/core';

import { PhoneNumberInput, PhoneTypes } from '__generated__/globalTypes';
import { getI18nErrorMessage } from 'i18n';

export const PHONE_INPUT_SCHEMA = Yup.object().shape<PhoneNumberInput>({
  number: PHONE_NUMBER_VALIDATION_SCHEMA.required(
    getI18nErrorMessage('error.phoneNumbers.number.required')
  ),
  type: Yup.mixed<PhoneTypes>(),
});
