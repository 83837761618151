import React, { useCallback, useContext } from 'react';
import { ShepherdTourContext } from 'react-shepherd';
import { Button } from '@appclose/ui';

import { I18n } from 'i18n';

import styles from './TourButton.module.scss';
import { TourContext } from '../../Tour.context';

export default function TourButton() {
  const { hasTour } = useContext(TourContext);
  const tour = useContext(ShepherdTourContext);

  const handleOnTourStart = useCallback(() => {
    tour?.start();
  }, [tour]);

  return hasTour ? (
    <Button className={styles.button} onClick={handleOnTourStart}>
      <I18n id="tour.tourButton.takeATour" />
    </Button>
  ) : null;
}
