import React, { useCallback, useMemo } from 'react';
import set from 'lodash/set';
import { Button, ButtonGroup } from '@appclose/ui';
import { Tab, TabList, TabPanel, Tabs, useFormContext } from '@appclose/core';

import {
  BankAccountClasses,
  MatterTypes,
  Modes,
  StripeBankAccountStatus,
} from '__generated__/globalTypes';
import { isPlusPlan } from 'controllers/mode';
import ModeGuard from 'components/common/ModeGuard';
import BankAccountNotLinked, {
  BankAccountNotLinkedTypes,
} from 'components/common/BankAccountNotLinked';
import { I18n } from 'i18n';

import { InvoiceFormValuesType } from '../../InvoiceForm.types';
import ContactAndMatterFieldset from './components/ContactAndMatterFieldset';
import ExpensesField from './components/ExpensesField';
import TimeEntriesField from './components/TimeEntriesField';
import FlatFeesField from './components/FlatFeesField';
import { InvoiceFormContentPropsType } from './InvoiceFormContent.types';
import InvoiceAmountFieldset from './components/InvoiceAmountFieldset';
import PaymentDetailsFieldset from './components/PaymentDetailsFieldset';
import InvoiceAdditionalInfoFieldset from './components/InvoiceAdditionalInfoFieldset';

export default function InvoiceFormContent({
  isEdit,
  bankAccounts,
  onCancel,
}: InvoiceFormContentPropsType) {
  const {
    values: {
      id,
      destinationAccount,
      scheduleLoading,
      matters,
      expenses,
      timeEntries,
      flatFees,
    },
    initialValues,
    setValues,
    isSubmitting,
  } = useFormContext<InvoiceFormValuesType>();
  const tabs = useMemo(
    () =>
      isEdit
        ? [destinationAccount]
        : [BankAccountClasses.OPERATING, BankAccountClasses.TRUST],
    [isEdit, destinationAccount]
  );

  const isOperatingAllowed = !isEdit || destinationAccount === BankAccountClasses.OPERATING;
  const isTrustAllowed = !isEdit || destinationAccount === BankAccountClasses.TRUST;
  const isTrustOnboardingCompleted = destinationAccount !== BankAccountClasses.TRUST;
  const account = useMemo(
    () => (bankAccounts || []).find((acc) => acc.class === destinationAccount),
    [bankAccounts, destinationAccount]
  );
  const isAccountConnected = account?.stripeBankAccountStatus === StripeBankAccountStatus.CONNECTED;

  const trustAccountNotLinkedType = useMemo(() => {
    if (!isTrustOnboardingCompleted || !account) {
      if (destinationAccount === BankAccountClasses.TRUST) {
        const { stripeBankAccountStatus } = account || {};

        if (stripeBankAccountStatus === StripeBankAccountStatus.MIGRATION_REQUIRED) {
          return BankAccountNotLinkedTypes.TRUST_ACCOUNT_NEED_RECONNECTION
        } else if (stripeBankAccountStatus === StripeBankAccountStatus.FEE_ACCOUNT_REQUIRED) {
          return BankAccountNotLinkedTypes.TRUST_FEE_ACCOUNT_REQUIRED;
        } else if (stripeBankAccountStatus === StripeBankAccountStatus.FEE_ACCOUNT_PROCESSING) {
          return BankAccountNotLinkedTypes.TRUST_FEE_ACCOUNT_PROCESSING
        }
    
        return BankAccountClasses.TRUST
      }
    
      return destinationAccount;
    }

    return BankAccountNotLinkedTypes.TRUST_ACCOUNT_NOT_COMPLETED;
  }, [isTrustOnboardingCompleted, destinationAccount, account]);


  const commonFormContent = (
    <>
      <InvoiceAmountFieldset />
      <PaymentDetailsFieldset isEdit={isEdit} />
      <InvoiceAdditionalInfoFieldset id={id} />
      <ButtonGroup>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          type="submit"
          skin="primary"
          loading={isSubmitting}
          disabled={scheduleLoading}
        >
          Save & Continue
        </Button>
      </ButtonGroup>
    </>
  );

  const handleOnResetValues = useCallback(() => {
    if (isPlusPlan()) {
      setValues((values) => {
        let newValues = set(values, 'total', 0);
        newValues = set(newValues, 'amount', 0);
        newValues = set(newValues, 'discount', 0);
        newValues = set(newValues, 'percentageDiscount', false);
        newValues = set(newValues, 'timeEntries', []);
        newValues = set(newValues, 'flatFees', []);
        newValues = set(newValues, 'expenses', []);

        return newValues;
      }, true);
    }
  }, [setValues]);

  const handleOnTabSelect = useCallback(
    (index) => {
      setValues(
        (values) => set(values, 'destinationAccount', tabs[index]),
        true
      );

      handleOnResetValues();
    },
    [setValues, handleOnResetValues, tabs]
  );

  const matterIds = useMemo(() => {
    if (!matters?.length) {
      return undefined;
    }

    return matters.map(({ id }) => id);
  }, [matters]);

  const canSelectFlatFees = !!matters?.find(
    (matter) => matter.type !== MatterTypes.FLAT_FEE
  );

  return (
    <Tabs
      selectedIndex={tabs.indexOf(destinationAccount)}
      onSelect={handleOnTabSelect}
    >
      <TabList>
        {isOperatingAllowed && (
          <Tab disabled={isEdit}>
            <I18n id="modal.invoice.form.tab.toOperating" />
          </Tab>
        )}
        {isTrustAllowed && (
          <Tab disabled={isEdit}>
            <I18n id="modal.invoice.form.tab.toTrust" />
          </Tab>
        )}
      </TabList>
      {isOperatingAllowed && (
        <TabPanel>
          {isAccountConnected ? (
            <>
              <ContactAndMatterFieldset
                isEdit={isEdit}
                resetValues={handleOnResetValues}
              />
              <ModeGuard mode={Modes.PLUS}>
                <ExpensesField
                  mattersIds={matterIds}
                  defaultValues={initialValues?.expenses}
                  values={expenses}
                />
                {canSelectFlatFees && (
                  <TimeEntriesField
                    mattersIds={matterIds}
                    defaultValues={initialValues?.timeEntries}
                    values={timeEntries}
                  />
                )}
                <FlatFeesField
                  mattersIds={matterIds}
                  defaultValues={initialValues?.flatFees}
                  values={flatFees}
                />
              </ModeGuard>
              {commonFormContent}
            </>
          ) : (
            <BankAccountNotLinked type={destinationAccount} />
          )}
        </TabPanel>
      )}
      {isTrustAllowed && (
        <TabPanel>
          {isAccountConnected ? (
            <>
              <ContactAndMatterFieldset
                isEdit={isEdit}
                resetValues={handleOnResetValues}
              />
              {commonFormContent}
            </>
          ) : (
            <BankAccountNotLinked type={trustAccountNotLinkedType} />
          )}
        </TabPanel>
      )}
    </Tabs>
  );
}
