import React from 'react';
import { Grid, Text3 } from '@appclose/ui';
import { Fieldset } from '@appclose/core';
import { TickIcon } from '@appclose/icons';

import { Modes } from '__generated__/globalTypes';
import { MobileFeatureListPropsType } from './MobileFeatureList.types';
import styles from './MobileFeatureList.module.scss';

export default function MobileFeatureList({
  title,
  features,
}: MobileFeatureListPropsType) {
  return (
    <Fieldset title={title} className={styles.fieldset}>
      <ul className={styles.list}>
        {features.map(([name, modes]) => (
          <li key={name}>
            <Grid gap={[50]} columns="1fr repeat(2, 40px)">
              <Text3>{name}</Text3>
              <div className={styles.icon}>
                {modes.includes(Modes.FREE) && <TickIcon />}
              </div>
              <div className={styles.icon}>
                {modes.includes(Modes.PLUS) && <TickIcon />}
              </div>
            </Grid>
          </li>
        ))}
      </ul>
    </Fieldset>
  );
}
