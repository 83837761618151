import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { downloadFileByBase64 } from '@appclose/lib';
import { traceError } from '@appclose/core';
import { TrustTransactionTypes } from '__generated__/globalTypes';

import useQboResync from 'hooks/useQboResync';
import useAccessCheck from 'hooks/useAccessCheck';
import {
  QboResyncDepositMutation,
  QboResyncDepositMutationVariables,
  QboResyncDisbursementMutation,
  QboResyncDisbursementMutationVariables
} from 'gql/shared/__generated__/qbo.gql';
import { QBO_RESYNC_DEPOSIT, QBO_RESYNC_DISBURSEMENT } from 'gql/shared/qbo.gql';

import {
  FetchDisbursementPdfQuery,
  FetchDisbursementPdfQueryVariables
} from './__generated__/useDisbursementActions.gql';

import { useDisbursementActionsPropsType } from './useDisbursementActions.types';
import { FETCH_DISBURSEMENT_PDF } from './useDisbursementActions.gql';

export default function useDisbursementActions({
  id,
  type,
}: useDisbursementActionsPropsType) {
  const { withLimitedAccessCheck } = useAccessCheck();

  const [
    fetchDisbursementPdf,
    { loading: downloadDisbursementPdfLoading },
  ] = useLazyQuery<
    FetchDisbursementPdfQuery,
    FetchDisbursementPdfQueryVariables
  >(FETCH_DISBURSEMENT_PDF, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
    onCompleted({ pdf: { data, mimetype, name } }) {
      downloadFileByBase64(data, mimetype, name);
    },
  });

  const [qboResyncDisbursement] = useQboResync<
    QboResyncDisbursementMutation,
    QboResyncDisbursementMutationVariables
    >(QBO_RESYNC_DISBURSEMENT, { entityName: 'Disbursement' });

  const [qboResyncDeposit] = useQboResync<
    QboResyncDepositMutation,
    QboResyncDepositMutationVariables
    >(QBO_RESYNC_DEPOSIT, { entityName: 'Deposit' });

  const qboResync = useCallback(() => {
    if (type === TrustTransactionTypes.DEPOSIT || type === TrustTransactionTypes.DEPOSIT_TRANSFER) {
      qboResyncDeposit({ id });
    } else {
      qboResyncDisbursement({ id });
    }
  }, [id, qboResyncDeposit, qboResyncDisbursement, type]);

  const downloadDisbursementPdf = useCallback(async () => {
    try {
      await fetchDisbursementPdf();
    } catch (e) {
      traceError(e);
    }
  }, [fetchDisbursementPdf]);

  return {
    qboResync: withLimitedAccessCheck(qboResync),
    downloadDisbursementPdf,
    downloadDisbursementPdfLoading,
  };
}
