import { gql } from '@apollo/client';

export const GET_ROUNDING_RULES = gql`
  query getRoundingRules($billedById: ID) {
    getRoundingRules(billedById: $billedById) {
      enabled
      rounding
      divider
    }
  }
`;

export const UPDATE_ROUNDING_RULES = gql`
  mutation updateRoundingRules($input: UpdateRoundingRulesInput!) {
    updateRoundingRules(updateRoundingRulesInput: $input) {
      enabled
      rounding
      divider
    }
  }
`;
