export const BANK_ACCOUNT_AGREEMENT_MODAL = 'BANK_ACCOUNT_AGREEMENT_MODAL';
export const BANK_ACCOUNT_MODAL = 'BANK_ACCOUNT_MODAL';
export const CHANGE_PASSWORD_MODAL = 'CHANGE_PASSWORD_MODAL';
export const CONTACT_MODAL = 'CONTACT_MODAL';
export const LEAD_MODAL = 'LEAD_MODAL';
export const CREDIT_MEMO_DETAILS_MODAL = 'CREDIT_MEMO_DETAILS_MODAL';
export const DEPOSIT_DETAILS_MODAL = 'DEPOSIT_DETAILS_MODAL';
export const DEPOSIT_MODAL = 'DEPOSIT_MODAL';
export const DEPOSIT_WARNING_MODAL = 'DEPOSIT_WARNING_MODAL';
export const DISBURSEMENT_DETAILS_MODAL = 'DISBURSEMENT_DETAILS_MODAL';
export const DISBURSEMENT_MODAL = 'DISBURSEMENT_MODAL';
export const EXPENSE_DETAILS_MODAL = 'EXPENSE_DETAIL_MODAL';
export const EXPENSE_MODAL = 'EXPENSE_MODAL';
export const FINE_TUNE_ALIGNMENT_MODAL = 'FINE_TUNE_ALIGNMENT_MODAL';
export const FIRM_MODAL = 'FIRM_MODAL';
export const FLAT_FEE_DETAILS_MODAL = 'FLAT_FEE_DETAILS_MODAL';
export const FLAT_FEE_MODAL = 'FLAT_FEE_MODAL';
export const GOOGLE_CALENDAR_SYNC_MODAL = 'GOOGLE_CALENDAR_SYNC_MODAL';
export const OUTLOOK_CALENDAR_SYNC_MODAL = 'OUTLOOK_CALENDAR_SYNC_MODAL';
export const GOOGLE_DRIVE_SYNC_OPTIONS_MODAL =
  'GOOGLE_DRIVE_SYNC_OPTIONS_MODAL';
export const INVOICE_CREDIT_MEMO_MODAL = 'INVOICE_CREDIT_MEMO_MODAL';
export const INVOICE_DETAILS_MODAL = 'INVOICE_DETAILS_MODAL';
export const INVOICE_LINK_MODAL = 'INVOICE_LINK_MODAL';
export const INVOICE_MODAL = 'INVOICE_MODAL';
export const INVOICE_REMINDERS_MODAL = 'INVOICE_REMINDERS_MODAL';
export const INVOICE_VOID_MODAL = 'INVOICE_VOID_MODAL';
export const MATTER_MODAL = 'MATTER_MODAL';
export const PAYMENT_BUTTON_MODAL = 'PAYMENT_BUTTON_MODAL';
export const PAYMENT_DETAILS_MODAL = 'PAYMENT_DETAILS_MODAL';
export const PAYMENT_REFUND_DETAILS_MODAL = 'PAYMENT_REFUND_DETAILS_MODAL';
export const PAYMENT_REFUND_MODAL = 'PAYMENT_REFUND_MODAL';
export const PRINT_CHECKS_MODAL = 'PRINT_CHECKS_MODAL';
export const PRINT_CHECKS_PREVIEW_MODAL = 'PRINT_CHECKS_PREVIEW_MODAL';
export const PRINT_CHECKS_SAMPLE_PREVIEW_MODAL =
  'PRINT_CHECKS_SAMPLE_PREVIEW_MODAL';
export const PRINT_DEPOSITS_MODAL = 'PRINT_DEPOSITS_MODAL';
export const PRINT_DEPOSITS_PREVIEW_MODAL = 'PRINT_DEPOSITS_PREVIEW_MODAL';
export const PRINT_DEPOSITS_SAMPLE_PREVIEW_MODAL =
  'PRINT_DEPOSITS_SAMPLE_PREVIEW_MODAL';
export const PRINT_SETTINGS_MODAL = 'PRINT_SETTINGS_MODAL';
export const PRINTING_OPTIONS_MODAL = 'PRINTING_OPTIONS_MODAL';
export const PROFILE_MODAL = 'PROFILE_MODAL';
export const QBO_INTEGRATION_SETUP_MODAL = 'QBO_INTEGRATION_SETUP_MODAL';
export const RECEIVE_PAYMENT_MODAL = 'RECEIVE_PAYMENT_MODAL';
export const RECONCILIATION_DETAILS_MODAL = 'RECONCILIATION_DETAILS_MODAL';
export const RECONCILIATION_MODAL = 'RECONCILIATION_MODAL';
export const RECONCILIATION_WARNING_MODAL = 'RECONCILIATION_WARNING_MODAL';
export const SEND_CREDIT_MEMO_MODAL = 'SEND_CREDIT_MEMO_MODAL';
export const SEND_INVOICE_MODAL = 'SEND_INVOICE_MODAL';
export const SEND_INVOICE_VOID_MODAL = 'SEND_INVOICE_VOID_MODAL';
export const SEND_PAYMENT_RECEIPT_MODAL = 'SEND_PAYMENT_RECEIPT_MODAL';
export const SEND_PAYMENT_REFUND_MODAL = 'SEND_PAYMENT_REFUND_MODAL';
export const SIGNATURE_DOCUMENT_DETAILS_MODAL =
  'SIGNATURE_DOCUMENT_DETAILS_MODAL';
export const SIGNATURE_DOCUMENT_MODAL = 'SIGNATURE_DOCUMENT_MODAL';
export const SIGNATURE_TEMPLATE_DETAILS_MODAL =
  'SIGNATURE_TEMPLATE_DETAILS_MODAL';
export const SIGNATURE_TEMPLATE_MODAL = 'SIGNATURE_TEMPLATE_MODAL';
export const TEAM_MEMBER_MODAL = 'TEAM_MEMBER_MODAL';
export const TIME_ENTRY_DETAILS_MODAL = 'TIME_ENTRY_DETAILS_MODAL';
export const TIME_ENTRY_MODAL = 'TIME_ENTRY_MODAL';
export const TRANSFER_FUNDS_MODAL = 'TRANSFER_FUNDS_MODAL';
export const TRUST_SETUP_BALANCES_STEP_MODAL =
  'TRUST_SETUP_BALANCES_STEP_MODAL';
export const TRUST_SETUP_CONTACTS_MATTERS_STEP_MODAL =
  'TRUST_SETUP_CONTACTS_MATTERS_STEP_MODAL';
export const TRUST_SETUP_QBO_STEP_MODAL = 'TRUST_SETUP_QBO_STEP_MODAL';
export const TRUST_TRANSFER_DETAILS_MODAL = 'TRUST_TRANSFER_DETAILS_MODAL';
export const MODES_COMPARE_MODAL = 'MODES_COMPARE_MODAL';
export const MODE_UPGRADE_MODAL = 'MODE_UPGRADE_MODAL';
export const KYB_MODAL = 'KYB_MODAL';
