import { useMemo } from 'react';
import { Table, TableBody, TableRow, TableCell } from '@appclose/ui';
import { Amount, useIsDesktopDevice, Fieldset, Ellipsis } from '@appclose/core';
import { BillingIcon, TimerIcon, ExpenseIcon } from '@appclose/icons';

import Person from 'components/common/Person';
import DateComponent from 'components/common/Date';
import ExpenseStatus from 'components/common/ExpenseStatus';
import TimeEntryStatus from 'components/common/TimeEntryStatus';
import FlatFeeStatus from 'components/common/FlatFeeStatus';
import { useIntl } from 'i18n';

import {
  InvoiceActivitiesTablePropsType,
  Expense,
  TimeEntry,
  FlatFee,
} from './InvoiceActivitiesTable.types';
import styles from './InvoiceActivitiesTable.module.scss';

function InvoiceActivitiesTable({
  expenses,
  timeEntries,
  flatFees,
}: InvoiceActivitiesTablePropsType) {
  const { t } = useIntl();
  const isDesktop = useIsDesktopDevice();
  const items: Array<Expense | TimeEntry | FlatFee> = useMemo(
    () =>
      [...(expenses || []), ...(timeEntries || []), ...(flatFees || [])]
        .slice()
        .sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        ),
    [expenses, timeEntries, flatFees]
  );

  if (!items.length) {
    return null;
  }

  return (
    <Fieldset title={t('modal.invoiceDetails.info.field.expenses')}>
      <Table className={styles.table}>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.id} withHover={false}>
              <TableCell>
                {item.__typename === 'SubExpenseType' ? (
                  <ExpenseIcon />
                ) : item.__typename === 'TimeEntryType' ? (
                  <TimerIcon />
                ) : (
                  <BillingIcon />
                )}
              </TableCell>
              <TableCell
                noWordBreak
                theme="light"
                maxWidth="0"
                width="40%"
                className={styles.activityCell}
              >
                <Ellipsis className={styles.activity}>
                  {item.__typename === 'SubExpenseType' && item.category?.name}
                  {item.__typename === 'TimeEntryType' && item.activity?.name}
                  {item.__typename === 'FlatFeeType' && item.description}
                </Ellipsis>
                <DateComponent value={item.date} />
              </TableCell>
              {isDesktop && (
                <>
                  <TableCell noWordBreak>
                    {item.billedBy && (
                      <Person
                        firstName={item.billedBy.firstName}
                        lastName={item.billedBy.lastName}
                        icon={item.billedBy.picture}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {item.__typename === 'SubExpenseType' && (
                      <ExpenseStatus status={item.status} />
                    )}
                    {item.__typename === 'TimeEntryType' && (
                      <TimeEntryStatus status={item.status} />
                    )}
                    {item.__typename === 'FlatFeeType' && (
                      <FlatFeeStatus status={item.status} />
                    )}
                  </TableCell>
                </>
              )}
              <TableCell noWordBreak theme="strong" align="right">
                {item.__typename === 'SubExpenseType' && (
                  <Amount value={item.amount} />
                )}
                {item.__typename === 'TimeEntryType' && (
                  <Amount value={item.amount} />
                )}
                {item.__typename === 'FlatFeeType' && (
                  <Amount value={item.amount} />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Fieldset>
  );
}

export default InvoiceActivitiesTable;
