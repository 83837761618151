import { useRouteMatch } from 'react-router-dom';

import { CONTACT_ROUTE, MATTER_ROUTE } from 'constants/routes';

export default function useEntityRouteMatch() {
  const contactMatch = useRouteMatch<{ id?: string }>(CONTACT_ROUTE);
  const contactId = contactMatch?.params?.id;
  const matterMatch = useRouteMatch<{ id?: string }>(MATTER_ROUTE);
  const matterId = matterMatch?.params?.id;

  return { contactId, matterId };
}
