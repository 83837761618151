import { gql } from '@apollo/client';

import { AVATAR_FRAGMENT } from 'components/common/Avatar';

export const EXPENSES_FIELD_FRAGMENT = gql`
  fragment ExpensesField on SubExpenseType {
    id
    category {
      id
      name
    }
    date
    billable
    status
    amount
    billedBy {
      id
      firstName
      lastName
      picture {
        ...Avatar
      }
    }
    matter {
      id
      name
    }
  }
  ${AVATAR_FRAGMENT}
`;

export const FETCH_EXPENSES = gql`
  query FetchExpenses($input: ListSubExpensesInput!) {
    expenses: listSubExpenses(listSubExpensesInput: $input) {
      total
      items {
        ...ExpensesField
      }
    }
  }
  ${EXPENSES_FIELD_FRAGMENT}
`;

export const ON_EXPENSE_CREATE = gql`
  subscription ExpenseCreated(
    $expenseSubscriptionInput: SubExpenseSubscriptionInput!
  ) {
    subExpenseCreated(subExpenseSubscriptionInput: $expenseSubscriptionInput) {
      id
    }
  }
`;

export const ON_EXPENSE_DELETE = gql`
  subscription ExpenseRemoved($expenseIds: [ID!]!) {
    subExpenseRemoved(
      removedEntitySubscriptionInput: { includeIds: $expenseIds }
    ) {
      id
    }
  }
`;

export const ON_EXPENSE_UPDATE = gql`
  subscription ExpenseUpdated($expenseIds: [ID!]!) {
    subExpenseUpdated(
      subExpenseSubscriptionInput: { includeIds: $expenseIds }
    ) {
      ...ExpensesField
    }
  }
  ${EXPENSES_FIELD_FRAGMENT}
`;
