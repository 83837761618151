import { gql } from '@apollo/client';

export const FETCH_DEPOSIT_SLIP_PDF = gql`
  query FetchDepositSlipPdf($depositsIds: [ID!]!, $date: Date!) {
    pdf: getDepositSlipPDF(
      getDepositSlipInput: { depositsIds: $depositsIds, date: $date }
    ) {
      url
      base64 {
        data
        mimetype
      }
    }
  }
`;
