import React from 'react';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import { I18n } from 'i18n';
import usePaymentLink from 'hooks/usePaymentLink';

import { PaymentButtonModalPropsType } from './PaymentButtonModal.types';
import PaymentButtonForm from './components/PaymentButtonForm';
import { Loader } from '@appclose/core';

export default function PaymentButtonModal({
  onClose,
}: PaymentButtonModalPropsType) {
  const { loadingPaymentLink, paymentLink } = usePaymentLink();

  return (
    <ModalPage onClose={onClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.paymentButton.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loadingPaymentLink ? (
          <Loader />
        ) : (
          <PaymentButtonForm paymentLink={paymentLink || ''} />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
