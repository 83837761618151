import React, { memo } from 'react';
import { Amount, FormGroup, StaticField, Fieldset } from '@appclose/core';

import { sanitizeAmount } from 'controllers/amount';
import { formatTimer } from 'controllers/timer';
import { openModal } from 'controllers/modal';
import { INVOICE_DETAILS_MODAL } from 'constants/modals';
import FormGrid from 'components/common/FormGrid/FormGrid';
import Person from 'components/common/Person';
import TimeEntryStatus from 'components/common/TimeEntryStatus';
import Date from 'components/common/Date';
import InvoiceInfo from 'components/common/InvoiceInfo';
import AdditionalInfoDetailsFieldset from 'components/common/AdditionalInfoDetailsFieldset';
import ContactMatterDetailsFieldset from 'components/common/ContactMatterDetailsFieldset';
import { useIntl } from 'i18n';

import { TimeEntryFormPropsType } from './TimeEntryDetails.types';
import styles from './TimeEntryDetails.module.scss';

const TimeEntryDetails = memo(function TimeEntryDetails({
  timeEntryDetails: {
    id,
    activity,
    date,
    durationSec,
    status,
    billedBy,
    billingRate,
    amount,
    contact,
    matter,
    description,
    notes,
    invoice,
  },
  files,
}: TimeEntryFormPropsType) {
  const { t } = useIntl();

  return (
    <>
      {invoice && (
        <FormGroup className={styles.invoice}>
          <StaticField title={t('modal.timeEntryDetails.invoice.title')}>
            <InvoiceInfo
              invoice={invoice}
              onViewButtonClick={() => {
                openModal(INVOICE_DETAILS_MODAL, {
                  id: invoice.id,
                });
              }}
            />
          </StaticField>
        </FormGroup>
      )}
      <Fieldset title={t('modal.timeEntryDetails.details.title')}>
        <FormGroup>
          <FormGrid>
            <StaticField title={t('modal.timeEntryDetails.details.activity')}>
              {activity?.name}
            </StaticField>
          </FormGrid>
        </FormGroup>
        {description && (
          <FormGroup>
            <StaticField
              title={t('modal.timeEntryDetails.details.description')}
            >
              {description}
            </StaticField>
          </FormGroup>
        )}
        <FormGroup>
          <FormGrid>
            <StaticField title={t('modal.timeEntryDetails.details.amount')}>
              <Amount value={sanitizeAmount(amount)} />
            </StaticField>
            <StaticField title={t('modal.timeEntryDetails.details.date')}>
              <Date value={date} />
            </StaticField>
            <StaticField title={t('modal.timeEntryDetails.details.duration')}>
              {formatTimer(durationSec || 0, false)}
            </StaticField>
            <StaticField title={t('modal.timeEntryDetails.details.status')}>
              <TimeEntryStatus status={status} />
            </StaticField>
            <StaticField title={t('modal.timeEntryDetails.details.billedBy')}>
              <Person
                className={styles.person}
                firstName={billedBy.firstName}
                lastName={billedBy.lastName}
                icon={billedBy.picture}
              />
            </StaticField>
            <StaticField
              title={t('modal.timeEntryDetails.details.billingRate')}
            >
              <Amount value={sanitizeAmount(billingRate)} />
            </StaticField>
          </FormGrid>
        </FormGroup>
      </Fieldset>
      <ContactMatterDetailsFieldset matter={matter} contact={contact} />
      <AdditionalInfoDetailsFieldset notes={notes} files={files} />
    </>
  );
});

export default TimeEntryDetails;
