import { useQuery } from '@apollo/client';

import { isDemoBuild } from 'controllers/demo';

import { FETCH_PAYMENT_LINK } from './usePaymentLink.gql';
import {
  FetchPaymentLinkQuery,
  FetchPaymentLinkQueryVariables,
} from './__generated__/usePaymentLink.gql';

export default function usePaymentLink() {
  const isDemoMode = isDemoBuild();

  const { loading, data } = useQuery<
    FetchPaymentLinkQuery,
    FetchPaymentLinkQueryVariables
  >(FETCH_PAYMENT_LINK, {
    skip: isDemoMode,
  });

  return {
    loadingPaymentLink: loading,
    paymentLink: data?.getFirm.paymentLink,
  };
}
