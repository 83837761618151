import { Entities } from 'constants/entities';

import {
  UPDATE_CONTACT_FILES,
  UPDATE_EXPENSE_FILES,
  UPDATE_INVOICE_FILES,
  UPDATE_LEAD_FILES,
  UPDATE_MATTER_FILES,
  UPDATE_PAYMENT_FILES,
  UPDATE_RECONCILIATION_FILES,
  UPDATE_TRUST_TRANSFER_FILES,
  UPDATE_VOID_INVOICE_FILES
} from './useUpdateFiles.gql';

export const Mutations = {
  [Entities.CONTACT]: UPDATE_CONTACT_FILES,
  [Entities.LEAD]: UPDATE_LEAD_FILES,
  [Entities.MATTER]: UPDATE_MATTER_FILES,
  [Entities.INVOICE]: UPDATE_INVOICE_FILES,
  [Entities.EXPENSE]: UPDATE_EXPENSE_FILES,
  [Entities.PAYMENT]: UPDATE_PAYMENT_FILES,
  [Entities.VOID_INVOICE]: UPDATE_VOID_INVOICE_FILES,
  [Entities.TRUST_TRANSFER]: UPDATE_TRUST_TRANSFER_FILES,
  [Entities.RECONCILIATION]: UPDATE_RECONCILIATION_FILES,
};
