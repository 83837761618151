import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { closeModal, getModalData } from 'controllers/modal';
import {
  CONTACT_MODAL,
  INVOICE_DETAILS_MODAL,
  INVOICE_MODAL,
  PROFILE_MODAL,
  SEND_INVOICE_MODAL,
  INVOICE_VOID_MODAL,
  SEND_INVOICE_VOID_MODAL,
  RECEIVE_PAYMENT_MODAL,
  INVOICE_CREDIT_MEMO_MODAL,
  SEND_CREDIT_MEMO_MODAL,
  PAYMENT_REFUND_MODAL,
  PAYMENT_DETAILS_MODAL,
  SEND_PAYMENT_REFUND_MODAL,
  TIME_ENTRY_MODAL,
  TIME_ENTRY_DETAILS_MODAL,
  EXPENSE_MODAL,
  EXPENSE_DETAILS_MODAL,
  FLAT_FEE_MODAL,
  MATTER_MODAL,
  TEAM_MEMBER_MODAL,
  INVOICE_LINK_MODAL,
  CHANGE_PASSWORD_MODAL,
  PAYMENT_REFUND_DETAILS_MODAL,
  SEND_PAYMENT_RECEIPT_MODAL,
  INVOICE_REMINDERS_MODAL,
  FIRM_MODAL,
  BANK_ACCOUNT_MODAL,
  BANK_ACCOUNT_AGREEMENT_MODAL,
  DEPOSIT_MODAL,
  DISBURSEMENT_MODAL,
  DEPOSIT_DETAILS_MODAL,
  DISBURSEMENT_DETAILS_MODAL,
  CREDIT_MEMO_DETAILS_MODAL,
  TRUST_SETUP_QBO_STEP_MODAL,
  QBO_INTEGRATION_SETUP_MODAL,
  RECONCILIATION_MODAL,
  FLAT_FEE_DETAILS_MODAL,
  RECONCILIATION_DETAILS_MODAL,
  TRUST_SETUP_CONTACTS_MATTERS_STEP_MODAL,
  TRUST_SETUP_BALANCES_STEP_MODAL,
  SIGNATURE_TEMPLATE_MODAL,
  SIGNATURE_DOCUMENT_MODAL,
  DEPOSIT_WARNING_MODAL,
  RECONCILIATION_WARNING_MODAL,
  PRINT_CHECKS_MODAL,
  PRINTING_OPTIONS_MODAL,
  PRINT_SETTINGS_MODAL,
  PRINT_CHECKS_PREVIEW_MODAL,
  PRINT_DEPOSITS_MODAL,
  PRINT_DEPOSITS_PREVIEW_MODAL,
  FINE_TUNE_ALIGNMENT_MODAL,
  SIGNATURE_DOCUMENT_DETAILS_MODAL,
  SIGNATURE_TEMPLATE_DETAILS_MODAL,
  PRINT_DEPOSITS_SAMPLE_PREVIEW_MODAL,
  PRINT_CHECKS_SAMPLE_PREVIEW_MODAL,
  TRUST_TRANSFER_DETAILS_MODAL,
  TRANSFER_FUNDS_MODAL,
  PAYMENT_BUTTON_MODAL,
  GOOGLE_CALENDAR_SYNC_MODAL,
  GOOGLE_DRIVE_SYNC_OPTIONS_MODAL,
  OUTLOOK_CALENDAR_SYNC_MODAL,
  LEAD_MODAL,
  MODES_COMPARE_MODAL,
  MODE_UPGRADE_MODAL,
  KYB_MODAL
} from 'constants/modals';
import InvoiceModal from 'components/modals/pages/InvoiceModal';
import InvoiceDetailsModal from 'components/modals/pages/InvoiceDetailsModal';
import ContactModal from 'components/modals/pages/ContactModal';
import ProfileModal from 'components/modals/pages/ProfileModal';
import SendInvoiceModal from 'components/modals/pages/SendInvoiceModal';
import PaymentRefundModal from 'components/modals/pages/PaymentRefundModal';
import SendVoidInvoiceModal from 'components/modals/pages/SendVoidInvoiceModal';
import InvoiceVoidModal from 'components/modals/pages/InvoiceVoidModal';
import ReceivePaymentModal from 'components/modals/pages/ReceivePaymentModal';
import SendRefundModal from 'components/modals/pages/SendRefundModal';
import InvoiceCreditMemoModal from 'components/modals/pages/InvoiceCreditMemoModal';
import SendCreditMemoModal from 'components/modals/pages/SendCreditMemoModal';
import PaymentDetailsModal from 'components/modals/pages/PaymentDetailsModal';
import TimeEntryModal from 'components/modals/pages/TimeEntryModal';
import TimeEntryDetailsModal from 'components/modals/pages/TimeEntryDetailsModal';
import MatterModal from 'components/modals/pages/MatterModal';
import TeamMemberModal from 'components/modals/pages/TeamMemberModal';
import InvoiceLinkModal from 'components/modals/pages/InvoiceLinkModal';
import ChangePasswordModal from 'components/modals/pages/ChangePasswordModal';
import PaymentRefundDetailsModal from 'components/modals/pages/PaymentRefundDetailsModal';
import SendPaymentReceiptModal from 'components/modals/pages/SendPaymentReceiptModal';
import ExpenseModal from 'components/modals/pages/ExpenseModal';
import InvoiceRemindersModal from 'components/modals/pages/InvoiceRemindersModal';
import FirmModal from 'components/modals/pages/FirmModal';
import BankAccountModal from 'components/modals/pages/BankAccountModal';
import BankAccountAgreementModal from 'components/modals/pages/BankAccountAgreementModal';
import ExpenseDetailsModal from 'components/modals/pages/ExpenseDetailsModal';
import DepositModal from 'components/modals/pages/DepositModal';
import DisbursementModal from 'components/modals/pages/DisbursementModal';
import DepositDetailsModal from 'components/modals/pages/DepositDetailsModal';
import DisbursementDetailsModal from 'components/modals/pages/DisbursementDetailsModal';
import CreditMemoDetailsModal from 'components/modals/pages/CreditMemoDetailsModal';
import QboIntegrationSetupModal from 'components/modals/pages/QboIntegrationSetupModal';
import ReconciliationModal from 'components/modals/pages/ReconciliationModal';
import FlatFeeModal from 'components/modals/pages/FlatFeeModal';
import FlatFeeDetailsModal from 'components/modals/pages/FlatFeeDetailsModal';
import ReconciliationDetailsModal from 'components/modals/pages/ReconciliationDetailsModal';
import TrustSetupQboStepModal from 'components/modals/pages/TrustSetupQboStepModal';
import TrustSetupContactsMattersModal from 'components/modals/pages/TrustSetupContactsMattersModal';
import TrustSetupBalancesStepModal from 'components/modals/pages/TrustSetupBalancesStepModal';
import SignatureTemplateModal from 'components/modals/pages/SignatureTemplateModal';
import SignatureDocumentModal from 'components/modals/pages/SignatureDocumentModal';
import DepositWarningModal from 'components/modals/pages/DepositWarningModal';
import ReconciliationWarningModal from 'components/modals/pages/ReconciliationWarningModal';
import PrintChecksModal from 'components/modals/pages/PrintChecksModal';
import PrintingOptionsModal from 'components/modals/pages/PrintingOptionsModal';
import PrintSettingsModal from 'components/modals/pages/PrintSettingsModal';
import PrintChecksPreviewModal from 'components/modals/pages/PrintChecksPreviewModal';
import PrintDepositsModal from 'components/modals/pages/PrintDepositsModal';
import PrintDepositsPreviewModal from 'components/modals/pages/PrintDepositsPreviewModal';
import FineTuneAlignmentModal from 'components/modals/pages/FineTuneAlignmentModal';
import SignatureDocumentDetailsModal from 'components/modals/pages/SignatureDocumentDetailsModal';
import SignatureTemplateDetailsModal from 'components/modals/pages/SignatureTemplateDetailsModal';
import PrintDepositsSamplePreviewModal from 'components/modals/pages/PrintDepositsSamplePreviewModal';
import PrintChecksSamplePreviewModal from 'components/modals/pages/PrintChecksSamplePreviewModal';
import TrustTransferDetailsModal from 'components/modals/pages/TrustTransferDetailsModal';
import TransferFundsModal from 'components/modals/pages/TransferFundsModal';
import PaymentButtonModal from 'components/modals/pages/PaymentButtonModal';
import GoogleCalendarSyncModal from 'components/modals/pages/GoogleCalendarSyncModal';
import GoogleDriveSyncOptionsModal from 'components/modals/pages/GoogleDriveSyncOptionsModal';
import OutlookCalendarSyncModal from 'components/modals/pages/OutlookCalendarSyncModal';
import LeadModal from 'components/modals/pages/LeadModal';
import ModesCompareModal from 'components/modals/pages/ModesCompareModal';
import ModeUpgradeModal from 'components/modals/pages/ModeUpgradeModal';
import KybModal from 'components/modals/pages/KybModal';

export default function ModalProvider() {
  const { search } = useLocation();
  const [modalData, setModalData] = useState<{
    type: string;
    data?: any;
  } | null>(null);

  const handleOnClose = useCallback(() => {
    closeModal();
  }, []);

  const { type: currentModal, data = {} } = modalData || {};
  const modalProps = { ...data, onClose: handleOnClose };

  useEffect(() => {
    const modalData = getModalData();

    if (modalData?.type !== currentModal) {
      setModalData(modalData);
    }
  }, [currentModal, search]);

  switch (currentModal) {
    case CONTACT_MODAL:
      return <ContactModal {...modalProps} />;
    case LEAD_MODAL:
      return <LeadModal {...modalProps} />;
    case INVOICE_MODAL:
      return <InvoiceModal {...modalProps} />;
    case INVOICE_DETAILS_MODAL:
      return <InvoiceDetailsModal {...modalProps} />;
    case INVOICE_VOID_MODAL:
      return <InvoiceVoidModal {...modalProps} />;
    case PROFILE_MODAL:
      return <ProfileModal {...modalProps} />;
    case SEND_INVOICE_MODAL:
      return <SendInvoiceModal {...modalProps} />;
    case SEND_INVOICE_VOID_MODAL:
      return <SendVoidInvoiceModal {...modalProps} />;
    case RECEIVE_PAYMENT_MODAL:
      return <ReceivePaymentModal {...modalProps} />;
    case INVOICE_CREDIT_MEMO_MODAL:
      return <InvoiceCreditMemoModal {...modalProps} />;
    case SEND_CREDIT_MEMO_MODAL:
      return <SendCreditMemoModal {...modalProps} />;
    case PAYMENT_REFUND_MODAL:
      return <PaymentRefundModal {...modalProps} />;
    case PAYMENT_REFUND_DETAILS_MODAL:
      return <PaymentRefundDetailsModal {...modalProps} />;
    case PAYMENT_DETAILS_MODAL:
      return <PaymentDetailsModal {...modalProps} />;
    case SEND_PAYMENT_REFUND_MODAL:
      return <SendRefundModal {...modalProps} />;
    case TIME_ENTRY_MODAL:
      return <TimeEntryModal {...modalProps} />;
    case TIME_ENTRY_DETAILS_MODAL:
      return <TimeEntryDetailsModal {...modalProps} />;
    case MATTER_MODAL:
      return <MatterModal {...modalProps} />;
    case TEAM_MEMBER_MODAL:
      return <TeamMemberModal {...modalProps} />;
    case INVOICE_LINK_MODAL:
      return <InvoiceLinkModal {...modalProps} />;
    case CHANGE_PASSWORD_MODAL:
      return <ChangePasswordModal {...modalProps} />;
    case SEND_PAYMENT_RECEIPT_MODAL:
      return <SendPaymentReceiptModal {...modalProps} />;
    case EXPENSE_MODAL:
      return <ExpenseModal {...modalProps} />;
    case INVOICE_REMINDERS_MODAL:
      return <InvoiceRemindersModal {...modalProps} />;
    case FIRM_MODAL:
      return <FirmModal {...modalProps} />;
    case BANK_ACCOUNT_MODAL:
      return <BankAccountModal {...modalProps} />;
    case BANK_ACCOUNT_AGREEMENT_MODAL:
      return <BankAccountAgreementModal {...modalProps} />;
    case EXPENSE_DETAILS_MODAL:
      return <ExpenseDetailsModal {...modalProps} />;
    case DEPOSIT_MODAL:
      return <DepositModal {...modalProps} />;
    case DISBURSEMENT_MODAL:
      return <DisbursementModal {...modalProps} />;
    case DEPOSIT_DETAILS_MODAL:
      return <DepositDetailsModal {...modalProps} />;
    case DISBURSEMENT_DETAILS_MODAL:
      return <DisbursementDetailsModal {...modalProps} />;
    case CREDIT_MEMO_DETAILS_MODAL:
      return <CreditMemoDetailsModal {...modalProps} />;
    case QBO_INTEGRATION_SETUP_MODAL:
      return <QboIntegrationSetupModal {...modalProps} />;
    case RECONCILIATION_MODAL:
      return <ReconciliationModal {...modalProps} />;
    case FLAT_FEE_MODAL:
      return <FlatFeeModal {...modalProps} />;
    case FLAT_FEE_DETAILS_MODAL:
      return <FlatFeeDetailsModal {...modalProps} />;
    case RECONCILIATION_DETAILS_MODAL:
      return <ReconciliationDetailsModal {...modalProps} />;
    case TRUST_SETUP_QBO_STEP_MODAL:
      return <TrustSetupQboStepModal {...modalProps} />;
    case TRUST_SETUP_CONTACTS_MATTERS_STEP_MODAL:
      return <TrustSetupContactsMattersModal {...modalProps} />;
    case TRUST_SETUP_BALANCES_STEP_MODAL:
      return <TrustSetupBalancesStepModal {...modalProps} />;
    case SIGNATURE_TEMPLATE_MODAL:
      return <SignatureTemplateModal {...modalProps} />;
    case SIGNATURE_DOCUMENT_MODAL:
      return <SignatureDocumentModal {...modalProps} />;
    case SIGNATURE_TEMPLATE_DETAILS_MODAL:
      return <SignatureTemplateDetailsModal {...modalProps} />;
    case DEPOSIT_WARNING_MODAL:
      return <DepositWarningModal {...modalProps} />;
    case RECONCILIATION_WARNING_MODAL:
      return <ReconciliationWarningModal {...modalProps} />;
    case PRINT_CHECKS_MODAL:
      return <PrintChecksModal {...modalProps} />;
    case PRINT_CHECKS_PREVIEW_MODAL:
      return <PrintChecksPreviewModal {...modalProps} />;
    case PRINT_CHECKS_SAMPLE_PREVIEW_MODAL:
      return <PrintChecksSamplePreviewModal {...modalProps} />;
    case PRINTING_OPTIONS_MODAL:
      return <PrintingOptionsModal {...modalProps} />;
    case PRINT_SETTINGS_MODAL:
      return <PrintSettingsModal {...modalProps} />;
    case PRINT_DEPOSITS_MODAL:
      return <PrintDepositsModal {...modalProps} />;
    case PRINT_DEPOSITS_PREVIEW_MODAL:
      return <PrintDepositsPreviewModal {...modalProps} />;
    case PRINT_DEPOSITS_SAMPLE_PREVIEW_MODAL:
      return <PrintDepositsSamplePreviewModal {...modalProps} />;
    case FINE_TUNE_ALIGNMENT_MODAL:
      return <FineTuneAlignmentModal {...modalProps} />;
    case SIGNATURE_DOCUMENT_DETAILS_MODAL:
      return <SignatureDocumentDetailsModal {...modalProps} />;
    case TRUST_TRANSFER_DETAILS_MODAL:
      return <TrustTransferDetailsModal {...modalProps} />;
    case TRANSFER_FUNDS_MODAL:
      return <TransferFundsModal {...modalProps} />;
    case PAYMENT_BUTTON_MODAL:
      return <PaymentButtonModal {...modalProps} />;
    case GOOGLE_CALENDAR_SYNC_MODAL:
      return <GoogleCalendarSyncModal {...modalProps} />;
    case GOOGLE_DRIVE_SYNC_OPTIONS_MODAL:
      return <GoogleDriveSyncOptionsModal {...modalProps} />;
    case OUTLOOK_CALENDAR_SYNC_MODAL:
      return <OutlookCalendarSyncModal {...modalProps} />;
    case MODES_COMPARE_MODAL:
      return <ModesCompareModal {...modalProps} />;
    case MODE_UPGRADE_MODAL:
      return <ModeUpgradeModal {...modalProps} />;
    case KYB_MODAL:
      return <KybModal {...modalProps} />;
    default:
      return null;
  }
}
