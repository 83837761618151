import React, { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import CalendarSyncModal, { CalendarSyncFormValuesType } from 'components/common/CalendarSyncModal';
import { OutlookCalendarSyncModalPropsType } from './OutlookCalendarSyncModal.types';
import { FETCH_OUTLOOK_CALENDARS, SYNC_OUTLOOK_CALENDAR } from './OutlookCalendarSyncModal.gql';
import {
  FetchOutlookCalendarsQuery,
  FetchOutlookCalendarsQueryVariables,
  SyncOutlookCalendarMutation,
  SyncOutlookCalendarMutationVariables,
} from './__generated__/OutlookCalendarSyncModal.gql';

export default function OutlookCalendarSyncModal({
  onClose,
}: OutlookCalendarSyncModalPropsType) {
  const { loading, data } = useQuery<
    FetchOutlookCalendarsQuery,
    FetchOutlookCalendarsQueryVariables
    >(FETCH_OUTLOOK_CALENDARS, {
    fetchPolicy: 'network-only',
  });

  const [syncOutlookCalendar] = useMutation<
    SyncOutlookCalendarMutation,
    SyncOutlookCalendarMutationVariables
  >(SYNC_OUTLOOK_CALENDAR);

  const onSubmit = useCallback(
    async (input: CalendarSyncFormValuesType) => {
      await syncOutlookCalendar({
        variables: {
          input,
        },
      });
    },
    [syncOutlookCalendar]
  );

  return (
    <CalendarSyncModal calendarName="Outlook" loading={loading} calendars={data?.calendars} onSubmit={onSubmit} onClose={onClose} />
  );
}
