import React from 'react';
import ReactDOM from 'react-dom';
import { APP_ROOT_ELEMENT_ID } from '@appclose/core';

import App from 'components/common/App';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById(APP_ROOT_ELEMENT_ID));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
