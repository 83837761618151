import { gql } from '@apollo/client';

export const DISBURSEMENT_TO_THIRD_PARTY = gql`
  mutation DisbursementToThirdParty(
    $input: CreateDisbursementToThirdPartyInput!
  ) {
    disbursement: createDisbursementToThirdParty(
      createDisbursementInput: $input
    ) {
      id
      type
    }
  }
`;
