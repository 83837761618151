import { useIntl } from 'i18n';
import { PrintingChecksModes } from '__generated__/globalTypes';
import { useCallback, useMemo } from 'react';

export default function usePrintingChecksModes() {
  const { t } = useIntl();
  const printingChecksModes: Record<PrintingChecksModes, string> = useMemo(
    () => ({
      [PrintingChecksModes.QBO]: t('enum.printingChecksModes.qbo'),
      [PrintingChecksModes.STANDARD]: t('enum.printingChecksModes.standard'),
      [PrintingChecksModes.VOUCHER]: t('enum.printingChecksModes.voucher'),
    }),
    [t]
  );

  const printingChecksModesOptions: {
    label: string;
    value: PrintingChecksModes;
  }[] = useMemo(
    () =>
      Object.entries(printingChecksModes).map(([value, label]) => ({
        value: value as PrintingChecksModes,
        label,
      })),
    [printingChecksModes]
  );

  const getPrintingChecksModesLabel = useCallback(
    (type?: PrintingChecksModes | null) => {
      if (!type) {
        return '';
      }

      return printingChecksModes[type];
    },
    [printingChecksModes]
  );

  return { printingChecksModesOptions, getPrintingChecksModesLabel };
}
