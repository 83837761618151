import React from 'react';
import { OverlayLoader } from '@appclose/core';

import { I18n } from 'i18n';
import { ModalPage, ModalPageContent, ModalPageHeader, ModalPageTitle } from 'components/common/ModalPage';
import { ModesCompare } from 'components/common/Modes';
import useUpgradeMode from 'hooks/useUpgradeMode';
import { ModesCompareModalPropsType } from './ModesCompareModal.types';

import styles from './ModesCompareModal.module.scss';

export default function ModesCompareModal({
  onClose,
}: ModesCompareModalPropsType) {
  const [upgradeMode, { loading }] = useUpgradeMode({ onCompleted: onClose });

  return (
    <ModalPage className={styles.modal} onClose={onClose}>
      <ModalPageHeader>
        <ModalPageTitle><I18n id="modal.modesCompare.title" /></ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        <OverlayLoader position="sticky" loading={loading}>
          <ModesCompare onSelectPlusMode={upgradeMode} />
        </OverlayLoader>
      </ModalPageContent>
    </ModalPage>
  );
}
