import React, { useMemo } from 'react';
import { SelectFormField } from '@appclose/core';

import useMatterTypes from 'hooks/useMatterTypes';
import { useIntl } from 'i18n';
import { MatterTypes } from '__generated__/globalTypes';

import { MatterTypesSelectFormFieldPropsType } from './MatterTypesSelectFormField.types';

export default function MatterTypesSelectFormField(
  props: MatterTypesSelectFormFieldPropsType
) {
  const { t } = useIntl();
  const { matterTypesOptions } = useMatterTypes();
  // TODO: Remove after the Contingent matter type is implemented
  const options = useMemo(
    () =>
      matterTypesOptions.filter(
        (option) => option.value !== MatterTypes.CONTINGENT
      ),
    [matterTypesOptions]
  );

  return (
    <SelectFormField
      name="type"
      label={t('field.matterTypesSelect.label')}
      clearable={false}
      {...props}
      options={options}
    />
  );
}
