import React from 'react';
import {
  DatePickerFormField,
  FormGroup,
  InputFormField,
  Fieldset,
} from '@appclose/core';

import { SERVER_DATE_FORMAT } from 'constants/date';
import { useIntl } from 'i18n';
import MatterBillingTypeFieldset from 'components/form/MatterBillingTypeFieldset';

import { MatterFieldsetPropsType } from './MatterFieldset.types';

export default function MatterFieldset({
  title,
  type,
}: MatterFieldsetPropsType) {
  const { t } = useIntl();

  return (
    <Fieldset title={title}>
      <FormGroup>
        <InputFormField
          name="matter.name"
          label={t('modal.popup.contactMatter.form.matter.name')}
        />
      </FormGroup>
      <FormGroup>
        <DatePickerFormField
          name="matter.dateOpened"
          label={t('modal.popup.contactMatter.form.matter.dateOpened')}
          outputDateFormat={SERVER_DATE_FORMAT}
          useHumanReadableFormat
        />
      </FormGroup>
      <FormGroup>
        <InputFormField
          name="matter.matterNumber"
          label={t('modal.popup.contactMatter.form.matter.matterNumber')}
          placeholder={t('system.placeholder.matterNumber')}
        />
      </FormGroup>
      <MatterBillingTypeFieldset name="matter" type={type} />
    </Fieldset>
  );
}
