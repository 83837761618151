import { gql } from '@apollo/client';

import { MATTER_SELECT_FRAGMENT } from 'components/fields/MatterSelectFormField';
import { CONTACT_SELECT_FRAGMENT } from '../../fields/ContactSelectFormField';

const TIMER_FRAGMENT = gql`
  fragment TimerFragment on TimerType {
    id
    status
    matter {
      ...MatterSelect
    }
    contact {
      ...ContactSelect
    }
    totalSeconds
    description
  }
  ${MATTER_SELECT_FRAGMENT}
  ${CONTACT_SELECT_FRAGMENT}
`;

export const FETCH_TIMERS = gql`
  query FetchTimers($statuses: [TimerStatusTypes!]) {
    listTimers(timerContactInput: { statuses: $statuses }) {
      ...TimerFragment
    }
  }
  ${TIMER_FRAGMENT}
`;

export const FETCH_MATTER = gql`
  query FetchMatter($id: ID!) {
    getMatter(id: $id) {
      ...MatterSelect
    }
  }
  ${MATTER_SELECT_FRAGMENT}
`;

export const CREATE_TIMER = gql`
  mutation CreateTimer($id: ID, $matterId: ID, $description: String) {
    createTimer(
      createTimerInput: {
        id: $id
        matterId: $matterId
        description: $description
      }
    ) {
      ...TimerFragment
    }
  }
  ${TIMER_FRAGMENT}
`;

export const UPDATE_TIMER_STATUS = gql`
  mutation UpdateTimerStatus($id: ID!, $status: TimerStatusTypes!) {
    updateTimer(updateTimerInput: { id: $id, status: $status }) {
      id
      status
    }
  }
`;

export const UPDATE_TIMER_INFO = gql`
  mutation UpdateTimerInfo(
    $id: ID!
    $description: String
    $matterId: ID
    $contactId: ID
  ) {
    updateTimer(
      updateTimerInput: {
        id: $id
        description: $description
        matterId: $matterId
        contactId: $contactId
      }
    ) {
      id
      description
      matter {
        id
        name
      }
      contact {
        id
        name
      }
    }
  }
`;

export const ON_TIMER_CREATED = gql`
  subscription timerCreated {
    timerCreated(timerSubscriptionInput: {}) {
      ...TimerFragment
    }
  }
  ${TIMER_FRAGMENT}
`;

export const ON_TIMER_UPDATED = gql`
  subscription timerUpdated {
    timerUpdated(timerSubscriptionInput: {}) {
      ...TimerFragment
    }
  }
  ${TIMER_FRAGMENT}
`;
