import React from 'react';
import { useQuery } from '@apollo/client';
import {
  Checkbox,
  Table,
  TableCell,
  TableRow,
  TableHead,
  Offset,
} from '@appclose/ui';
import {
  FormArrayField,
  OverlayLoader,
  useFormContext,
  FieldError,
} from '@appclose/core';

import { I18n } from 'i18n';

import {
  FetchReconciliationTransactionsQuery,
  FetchReconciliationTransactionsQueryVariables,
} from './__generated__/ReconciliationTransactionsTable.gql';
import { FETCH_RECONCILIATION_TRANSACTIONS } from './ReconciliationTransactionsTable.gql';
import TransactionsTableRow from './components/TransactionsTableRow';
import { ReconciliationFormValuesType } from '../../ReconciliationForm.types';
import styles from './ReconciliationTransactionsTable.module.scss';

export default function ReconciliationTransactionsTable() {
  const {
    setFieldValue,
    values: { statementDate, reconciliationId },
    initialValues: { statementDate: initialStatementDate },
  } = useFormContext<ReconciliationFormValuesType>();

  const { loading, data } = useQuery<
    FetchReconciliationTransactionsQuery,
    FetchReconciliationTransactionsQueryVariables
  >(FETCH_RECONCILIATION_TRANSACTIONS, {
    variables: {
      statementDate: statementDate || initialStatementDate,
      reconciliationId,
    },
    fetchPolicy: 'network-only',
  });

  const transactions = data?.transactions || [];
  const hasTransactions = !!transactions.length;

  return (
    <FormArrayField name="transactions">
      {({ push, remove, values }) => {
        const isAllSelected =
          hasTransactions &&
          transactions.every(({ id }) =>
            values?.some(({ id: transactionId }) => id === transactionId)
          );

        return (
          <OverlayLoader loading={loading} className={styles.overlay}>
            <Table className={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      className={styles.checkbox}
                      checked={isAllSelected}
                      onChange={() => {
                        if (isAllSelected) {
                          setFieldValue('transactions', []);
                        } else {
                          setFieldValue('transactions', transactions);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <I18n id="modal.reconciliation.form.transactionsTable.type" />
                  </TableCell>
                  <TableCell>
                    <I18n id="modal.reconciliation.form.transactionsTable.date" />
                  </TableCell>
                  <TableCell>
                    <I18n id="modal.reconciliation.form.transactionsTable.contactMatter" />
                  </TableCell>
                  <TableCell>
                    <I18n id="modal.reconciliation.form.transactionsTable.sourcePayee" />
                  </TableCell>
                  <TableCell>
                    <I18n id="modal.reconciliation.form.transactionsTable.deposit" />
                  </TableCell>
                  <TableCell>
                    <I18n id="modal.reconciliation.form.transactionsTable.disbursement" />
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <>
                {transactions.map((transaction) => {
                  const id = transaction.id;
                  const isSelected = values?.some(
                    ({ id: transactionId }) => id === transactionId
                  );

                  return (
                    <TransactionsTableRow
                      key={id}
                      transaction={transaction}
                      isSelected={isSelected}
                      onClick={() => {
                        if (!isSelected) {
                          push(transaction);
                        } else {
                          const index = values.findIndex(
                            ({ id: transactionId }) => transactionId === id
                          );

                          if (index !== -1) {
                            remove(index);
                          }
                        }
                      }}
                    />
                  );
                })}
              </>
            </Table>
            <Offset top="xxs">
              <FieldError name="transactions" />
            </Offset>
          </OverlayLoader>
        );
      }}
    </FormArrayField>
  );
}
