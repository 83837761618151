import * as Yup from 'yup';

import { SelectContactType } from 'components/fields/ContactSelectFormField';
import { SelectMatterType } from 'components/fields/MatterSelectFormField';
import { SelectTeamMemberType } from 'components/fields/TeamMemberSelectFormField';
import { getI18nErrorMessage } from 'i18n';
import { FILES_SCHEMA } from 'schemas/validations/files';

import { FlatFeeFormValuesType } from './FlatFeeForm.types';

export const FlatFeeFormSchema = Yup.object().shape<FlatFeeFormValuesType>({
  contact: Yup.mixed<SelectContactType>().required(
    getI18nErrorMessage('modal.flatFee.form.error.contact.required')
  ),
  matter: Yup.mixed<SelectMatterType>().required(
    getI18nErrorMessage('modal.flatFee.form.error.matter.required')
  ),
  description: Yup.string()
    .nullable()
    .max(3500, ({ max }) =>
      getI18nErrorMessage('modal.flatFee.form.error.description.max', { max })
    )
    .required(
      getI18nErrorMessage('modal.flatFee.form.error.description.required')
    ),
  amount: Yup.number()
    .moreThan(
      0,
      getI18nErrorMessage('modal.flatFee.form.error.amount.moreThan')
    )
    .required(getI18nErrorMessage('modal.flatFee.form.error.amount.required')),
  date: Yup.string()
    .nullable()
    .required(getI18nErrorMessage('modal.flatFee.form.error.date.required')),
  billable: Yup.boolean().required(
    getI18nErrorMessage('modal.flatFee.form.error.billable.required')
  ),
  billedBy: Yup.mixed<SelectTeamMemberType>().required(
    getI18nErrorMessage('modal.flatFee.form.error.billedBy.required')
  ),
  notes: Yup.string()
    .max(3500, ({ max }) =>
      getI18nErrorMessage('modal.flatFee.form.error.notes.max', { max })
    )
    .nullable(),
  files: FILES_SCHEMA,
});
