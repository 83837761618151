export const DEMO_BUILD = process.env.REACT_APP_DEMO_BUILD;
export const PROD_URL = process.env.REACT_APP_DEMO_REDIRECT_TO_PROD;
export const PUBLIC_URL = process.env.PUBLIC_URL;
export const LANDING_URL = process.env.REACT_APP_REDIRECT_TO_LANDING;
export const APP_PURCHASE_URL = process.env.REACT_APP_DEMO_REDIRECT_TO_PURCHASE;
export const USIO_URL = process.env.REACT_APP_USIO_URL;
export const USIO_ENROLLMENT_URL = process.env.REACT_APP_USIO_ENROLLMENT_URL;
export const HELLOSIGN_CLIENT_ID = process.env.REACT_APP_HELLOSIGN_CLIENT_ID;
export const NOTIFICATION_EMAIL = process.env.REACT_APP_NOTIFICATION_EMAIL;
export const QBO_PRINT_CHECKS_URL = process.env.REACT_APP_QBO_PRINT_CHECKS_URL;
export const RECAPTCHA_URL = process.env.REACT_APP_GOOGLE_RECAPTCHA_URL;
export const RECAPTCHA_SITE_KEY =
  process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
export const GOOGLE_TAG_MANAGER_CONTAINER_ID =
  process.env.REACT_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID;
export const HELP_ROLES_AND_ACCESS_LEVELS_URL =
  process.env.REACT_APP_HELP_ROLES_AND_ACCESS_LEVELS_URL || '#';
export const PROFESSIONAL_SITE_DOMAIN =
  process.env.REACT_APP_PROFESSIONAL_SITE_DOMAIN;
export const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY || '';
export const REACT_APP_BLOCK_SIGN_UP = process.env.REACT_APP_BLOCK_SIGN_UP;
export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY || '';