import React from 'react';
import { Grid } from '@appclose/ui';
import {
  MediaQueryMobile,
  MediaQueryDesktop,
  StaticField,
} from '@appclose/core';

import { PaymentMethodsTypes } from '__generated__/globalTypes';
import usePaymentMethodsTypes from 'hooks/usePaymentMethodsTypes';
import { useIntl } from 'i18n';

import { PaymentMethodDetailsPropsType } from './PaymentMethodDetails.types';

export default function PaymentMethodDetails({
  paymentMethod,
  checkNumber,
}: PaymentMethodDetailsPropsType) {
  const { t } = useIntl();
  const { getPaymentMethodsTypeLabel } = usePaymentMethodsTypes();

  return (
    <>
      <MediaQueryDesktop>
        <StaticField title={t('modal.disbursementDetails.payment.method')}>
          {getPaymentMethodsTypeLabel(paymentMethod)}
        </StaticField>
        {paymentMethod === PaymentMethodsTypes.CHECK &&
        typeof checkNumber === 'number' ? (
          <StaticField
            title={t('modal.disbursementDetails.payment.checkNumber')}
          >
            {checkNumber}
          </StaticField>
        ) : (
          <div />
        )}
      </MediaQueryDesktop>
      <MediaQueryMobile>
        <Grid align="center" columns="auto auto">
          <StaticField title={t('modal.disbursementDetails.payment.method')}>
            {getPaymentMethodsTypeLabel(paymentMethod)}
          </StaticField>
          {paymentMethod === PaymentMethodsTypes.CHECK && checkNumber && (
            <StaticField
              title={t('modal.disbursementDetails.payment.checkNumber')}
            >
              {checkNumber}
            </StaticField>
          )}
        </Grid>
      </MediaQueryMobile>
    </>
  );
}
