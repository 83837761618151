import React from 'react';
import { useQuery } from '@apollo/client';
import { InfiniteScroll, Loader, Fieldset } from '@appclose/core';

import { OrderTypes } from '__generated__/globalTypes';
import { useIntl } from 'i18n';

import {
  FetchContactsWithMattersQuery,
  FetchContactsWithMattersQueryVariables,
} from './__generated__/ContactsMattersList.gql';
import { FETCH_CONTACTS_WITH_MATTERS } from './ContactsMattersList.gql';
import styles from './ContactsMattersList.module.scss';

export default function ContactsMattersList() {
  const { t } = useIntl();
  const { loading, data, fetchMore } = useQuery<
    FetchContactsWithMattersQuery,
    FetchContactsWithMattersQueryVariables
  >(FETCH_CONTACTS_WITH_MATTERS, {
    fetchPolicy: 'network-only',
  });

  const contactsWithMatters = data?.contactsWithMatters.items;

  const onLoadMore = () =>
    fetchMore({
      variables: {
        input: {
          skip: contactsWithMatters?.length || 0,
          order: {
            createdAt: OrderTypes.DESC,
          },
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          contactsWithMatters: {
            ...previousResult.contactsWithMatters,
            items: [
              ...previousResult.contactsWithMatters.items,
              ...(fetchMoreResult?.contactsWithMatters.items || []),
            ],
          },
        };
      },
    });

  return (
    <Fieldset
      title={t(
        'modal.trustSetupContactsMatters.ContactsMattersList.fieldset.listOfAdded'
      )}
    >
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.container}>
          <InfiniteScroll onLoadMore={onLoadMore}>
            <ul className={styles.contactsList}>
              {contactsWithMatters?.map(({ name, id, matters }) => (
                <li key={id}>
                  <p>{name}</p>
                  {!!matters?.length && (
                    <ul className={styles.mattersList}>
                      {matters.map(({ id, name, matterNumber }) => (
                        <li key={id}>
                          <p>
                            {name} {matterNumber}
                          </p>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </InfiniteScroll>
        </div>
      )}
    </Fieldset>
  );
}
