import React, { useCallback } from 'react';
import { Loader, traceError } from '@appclose/core';

import {
  ModalPage,
  ModalPageContent,
  ModalPageHeader,
  ModalPageTitle,
} from 'components/common/ModalPage';
import {
  FINE_TUNE_ALIGNMENT_MODAL,
  PRINT_CHECKS_MODAL,
  PRINT_CHECKS_SAMPLE_PREVIEW_MODAL,
} from 'constants/modals';
import { getSavedModalData, openModal } from 'controllers/modal';
import { PrintingChecksModes } from '__generated__/globalTypes';
import usePrintChecks from 'hooks/usePrintChecks';
import notification from 'controllers/notification';
import useCloseConfirm from 'hooks/useCloseConfirm';
import { I18n, useIntl } from 'i18n';

import PrintSettingsForm, {
  PrintSettingsFormActionsType,
  PrintSettingsFormValuesType,
} from './components/PrintSettingsForm';
import { FineTuneAlignmentTypes } from '../FineTuneAlignmentModal';
import { PrintSettingsModalPropsType } from './PrintSettingsModal.types';

export default function PrintSettingsModal({
  onClose,
  openPrintCheck = true,
  ...restData
}: PrintSettingsModalPropsType) {
  const { t } = useIntl();
  let initialValues = getSavedModalData<PrintSettingsFormValuesType>();

  const {
    loadingPrintChecksSettings,
    mode,
    savePrintingChecksSettings,
  } = usePrintChecks();

  const { onConfirmClose, onFormChange } = useCloseConfirm({
    onClose,
  });

  const handleOnSubmit = useCallback(
    async (
      values: PrintSettingsFormValuesType,
      { setSubmitting }: PrintSettingsFormActionsType
    ) => {
      try {
        await savePrintingChecksSettings(values);

        notification().info(t('modal.printSettings.notification.info'));
        onClose();

        if (openPrintCheck) {
          openModal(PRINT_CHECKS_MODAL, { ...restData });
        }
      } catch (e) {
        traceError(e);
        setSubmitting(false);
      }
    },
    [onClose, openPrintCheck, restData, savePrintingChecksSettings, t]
  );

  const handleOnOpenSample = useCallback((mode: PrintingChecksModes) => {
    openModal(PRINT_CHECKS_SAMPLE_PREVIEW_MODAL, { mode }, { mode });
  }, []);

  const handleOnOpenFineTuneAlignment = useCallback(
    (mode: PrintingChecksModes) => {
      openModal(
        FINE_TUNE_ALIGNMENT_MODAL,
        {
          type: FineTuneAlignmentTypes.CHECK,
          mode,
        },
        { mode }
      );
    },
    []
  );

  if (!initialValues) {
    initialValues = {
      mode: mode || PrintingChecksModes.VOUCHER,
    };
  }

  return (
    <ModalPage onClose={onConfirmClose}>
      <ModalPageHeader>
        <ModalPageTitle>
          <I18n id="modal.printSettings.title" />
        </ModalPageTitle>
      </ModalPageHeader>
      <ModalPageContent>
        {loadingPrintChecksSettings ? (
          <Loader />
        ) : (
          <PrintSettingsForm
            initialValues={initialValues}
            onOpenSample={handleOnOpenSample}
            onOpenFineTuneAlignment={handleOnOpenFineTuneAlignment}
            onSubmit={handleOnSubmit}
            onCancel={onConfirmClose}
            onChange={onFormChange}
          />
        )}
      </ModalPageContent>
    </ModalPage>
  );
}
